/* eslint-disable react-hooks/exhaustive-deps */
import { Box, TextField, Typography, makeStyles } from "@material-ui/core";
import Button from "@components/atoms/Button";
import Loader from "@components/atoms/Loader";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory, useParams } from "react-router-dom";
import { GetAllPatientsAction } from "../Home/actions/HomeActions";
import { GetAllAppointmentsAction } from "../Patient/actions/PatientActions";
import { GetAddonAndOffersData } from "../AddPatient/actions/AddPatientActions";
import { END_POINT } from "network/constants";
import { API_METHODS } from "network/constants";
import { apiResponses } from "network/constants";
import { notyf } from "utils/utility";
import api from "utils/api";
import { Table } from "@components/molecules/Table";

const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: "#FFFFFF",
    width: "auto",
    border: "1px solid #D9D9D9",
  },
  addonBox: {
    paddingTop: "16px",
    backgroundColor: "#FFFFFF",
    paddingLeft: "16px",
    fontSize: "16px",
    width: "auto",
    color: "rgba(0, 0, 0, 0.54)",
  },
  addonLabel: {
    fontSize: "16px",
  },
  textField: {
    margin: "16px",
    width: "100%",
  },
  addon: {
    width: "400px",
  },
  defaultValue: {
    fontSize: "16px",
    fontWeight: "500",
  },
  label: {
    color: "#000",
  },
  list: {
    height: "76px",
    width: "auto",
    border: "1px solid #D9D9D9;",
    display: "flex",
    justifyContent: "space-between",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "16px",
    alignItems: "center",
  },
  remarks: {
    margin: "16px",
    width: "95%",
  },

  card: {
    boxShadow: 1,
    padding: "16px",
    borderRadius: "8px",
    backgroundColor: "#FFFFFF",
    border: "1px solid #D9D9D9",
    marginBottom: "16px",
  },
  tableLabel: {
    fontSize: "16px",
    fontWeight: "normal",
    height: "19px",
    marginBottom: "12px",
  },
  labelHeading: {
    color: "#8C8C8C",
    fontSize: "12px",
    paddingLeft: "16px",
  },
  tableContainer: { padding: "20px" },
  boxBorder: { border: "1px solid #D9D9D9" },
  remarksContainer: { overflow: "scroll", maxHeight: "250px" },
  remarks: {
    margin: "8px 16px 0px 16px",
    padding: "10px",
    border: "1px solid #D9D9D9",
  },
}));

const PatientDetails = (props) => {
  const {
    allPatients,
    allAppointments,
    loading,
    loadingPatient,
    addOnAndOffer,
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const patientId = Number(id);
  const [patients, setPatients] = useState([]);
  const patientData =
    patients.length > 0 && patients.find((patient) => patient.id === patientId);
  const [appointments, setAppointments] = useState([]);
  const [productAddon, setProductAddon] = useState([]);
  const [programAddonHistory, setProgramAddonHistory] = useState(null);
  const remarks = patientData?.remarks
    ? patientData?.remarks
        .split("\n")
        .map((remark) => remark.trim())
        .filter((remark) => remark.length > 0)
        .reverse()
    : null;

  useEffect(() => {
    props.GetAllPatientsAction();
    props.GetAllAppointmentsAction(patientId);
    props.GetAddonAndOffersData();
  }, []);

  useEffect(() => {
    if (allAppointments) {
      setAppointments(allAppointments);
    }
  }, [allAppointments]);

  useEffect(() => {
    if (allPatients) {
      setPatients(allPatients);
    }
  }, [allPatients]);

  const fetchAddOn = (addon) => {
    const productNames = [];
    patientData?.subscriptionAddOnProduct?.forEach((product) => {
      const { addOnProduct } = product;
      const item = addon.find((addons) => {
        return addons.id === addOnProduct;
      });
      if (item) {
        productNames.push(item.productName);
      }
    });

    setProductAddon([...productNames]);
  };

  const getAddonAndProgramDetails = async () => {
    try {
      const res = await api(END_POINT.getAddonAndProgramHistory(id, true), {
        method: API_METHODS.GET,
      });
      if (res.data.status === apiResponses.success) {
        let createProgramTableData = res.data.data.programHistory.map(
          (programH) => {
            return {
              "Program Type": `${programH.programType}`,
              Duration: programH.duration,
              "Program Start Date": programH.programStartDate,
            };
          }
        );

        let createAddOnTableData = res.data.data.addOnProductsHistory.map(
          (addOnProduct) => {
            return {
              "AddOn Type": addOnProduct.addOnType,
              Quantity: addOnProduct.quantity,
              "Purchase Date": addOnProduct.purchaseDate,
            };
          }
        );

        setProgramAddonHistory({
          programHistory: createProgramTableData,
          addOnProductsHistory: createAddOnTableData,
        });
      }
    } catch (e) {
      notyf.error(e.message || "something went wrong");
    }
  };

  useEffect(() => {
    getAddonAndProgramDetails();
  }, []);

  useEffect(() => {
    if (addOnAndOffer) {
      const { products } = addOnAndOffer;
      fetchAddOn(products);
    }
  }, [addOnAndOffer, patientData]);

  return (
    <>
      {(loading || loadingPatient) && (
        <Loader open={true} loaderMessage="Fetching..." />
      )}
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "16px",
        }}
      >
        <Box
          style={{
            margin: "16px 0px",
            backgroundColor: "#5BABCC",
            width: "100px",
            height: "100px",
            borderRadius: "50px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography style={{ fontSize: "74px", color: "#2B377E" }}>
            {patientData && patientData.firstName && patientData.firstName[0]}
          </Typography>
        </Box>
        <Typography style={{ fontSize: "24px", fontWeight: "500" }}>
          {patientData &&
            patientData.firstName &&
            `${patientData.firstName} ${patientData.lastName}`}
        </Typography>
      </Box>
      {patientData && (
        <>
          <div style={{ marginBottom: "16px" }}>
            <Box className={classes.box}>
              <TextField
                label={"Gender"}
                value={patientData.gender}
                className={classes.textField}
                InputProps={{
                  readOnly: true,
                  className: classes.defaultValue,
                }}
              />
            </Box>

            <Box className={classes.box}>
              <TextField
                label={"Age"}
                value={patientData.age}
                className={classes.textField}
                InputProps={{
                  readOnly: true,
                  className: classes.defaultValue,
                }}
              />
            </Box>

            <Box className={classes.box}>
              <TextField
                label={"Registered Mobile Number"}
                value={patientData.countryCode.concat(patientData.mobileNumber)}
                className={classes.textField}
                InputProps={{
                  readOnly: true,
                  className: classes.defaultValue,
                }}
              />
            </Box>

            {patientData.clinics && patientData.clinics[0] && (
              <Box className={classes.box}>
                <TextField
                  label={"Clinic Name"}
                  value={patientData.clinics[0].name}
                  className={classes.textField}
                  InputProps={{
                    readOnly: true,
                    className: classes.defaultValue,
                  }}
                />
              </Box>
            )}

            {patientData.coach && (
              <Box className={classes.box}>
                <TextField
                  label={"Coach Name"}
                  value={
                    patientData.coach?.firstName +
                    " " +
                    patientData.coach?.lastName
                  }
                  className={classes.textField}
                  InputProps={{
                    readOnly: true,
                    className: classes.defaultValue,
                  }}
                />
              </Box>
            )}

            <Box className={classes.box}>
              <TextField
                label={"Doctor Name"}
                value={
                  patientData.doctor?.firstName
                    ? "Dr. " +
                      patientData.doctor?.firstName +
                      " " +
                      (patientData.doctor?.lastName || "")
                    : "N/A"
                }
                className={classes.textField}
                InputProps={{
                  readOnly: true,
                  className: classes.defaultValue,
                }}
              />
            </Box>
            {programAddonHistory?.programHistory.length > 0 && (
              <Box className={classes.box}>
                <p className={classes.labelHeading}>Program History</p>
                <div className={classes.tableContainer}>
                  <Table
                    tableData={programAddonHistory?.programHistory}
                    classes={classes}
                  />
                </div>
              </Box>
            )}

            {programAddonHistory?.addOnProductsHistory.length > 0 && (
              <Box className={classes.box}>
                <p className={classes.labelHeading}>Addon History</p>
                <div className={classes.tableContainer}>
                  <Table
                    tableData={programAddonHistory?.addOnProductsHistory}
                    classes={classes}
                  />
                </div>
              </Box>
            )}

            {remarks?.length > 0 && (
              <Box className={classes.box}>
                <p className={classes.labelHeading}>Remarks</p>
                <div className={classes.remarksContainer}>
                  {remarks.map((remark, index) => (
                    <div className={classes.remarks} key={index}>
                      {`${index + 1}. ${remark}`}
                    </div>
                  ))}
                </div>
              </Box>
            )}
          </div>
        </>
      )}

      <Button
        fullWidth
        inverse
        onClick={() =>
          history.push({
            pathname: `/counselor/edit_patient/${patientId}`,
            state: { data: patientData, isEditable: true },
          })
        }
      >
        Edit Patient Details
      </Button>
    </>
  );
};

function mapStateToProps(state) {
  return {
    loading: state.homeReducers.loading,
    loadingPatient: state.patientReducers.loading,
    allPatients:
      state.homeReducers.allPatients !== null &&
      state.homeReducers.allPatients.data,
    allAppointments:
      state.patientReducers.allAppointments !== null &&
      state.patientReducers.allAppointments.data,
    addOnAndOffer:
      state.addPatientReducers.addOnAndOffer !== null &&
      state.addPatientReducers.addOnAndOffer.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        GetAllPatientsAction,
        GetAllAppointmentsAction,
        GetAddonAndOffersData,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientDetails);
