/**
 @desc
 * Centralized unique actions for Add Patient Module.
 */

import {
  actionCreator,
  checkHttpStatus,
  jsonApiHeader,
  showErrorMessage,
  AddPatientActionTypes,
  GET_ALL_CLINICS,
  POST_ADD_PATIENT,
  UPDATE_PATIENT,
  POST_UPLOAD_CLINICAL_DATA,
  POST_UPLOAD_GOAL_DATA,
  POST_UPLOAD_COACH_ASSESSMENT_DATA,
  GET_DOCTOR_COACH_BY_CLINIC,
  GET_ADDON_AND_OFFERS,
} from "../constants";
import { handleRedirect, TOKEN_EXPIRY_MESSAGE } from "../../../utils/utility";

/**
 * @param  {[object]} postData [The data needed as a payload for the API interaction]
 */
export const PostAddPatientAction = (postData) => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.token;
    dispatch(actionCreator(AddPatientActionTypes.post_AddPatient.REQUEST));
    fetch(`${POST_ADD_PATIENT}`, {
      method: "POST",
      body: JSON.stringify(postData),
      headers: jsonApiHeader(token),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(
            actionCreator(
              AddPatientActionTypes.post_AddPatient.FAILURE,
              response
            )
          );
          showErrorMessage(response.message);
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage(TOKEN_EXPIRY_MESSAGE);
          localStorage.clear();
          dispatch(
            actionCreator(
              AddPatientActionTypes.post_AddPatient.FAILURE,
              response
            )
          );
          window.location.reload();
        } else {
          dispatch(
            actionCreator(
              AddPatientActionTypes.post_AddPatient.SUCCESS,
              response
            )
          );
        }
      })
      .catch(function (error) {
        dispatch(actionCreator(AddPatientActionTypes.post_AddPatient.FAILURE));
        console.error("error post_AddPatient ..", error);
      });
  };
};

export const UpdatePatientAction = (patientId, postData) => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.token;
    dispatch(actionCreator(AddPatientActionTypes.update_Patient.REQUEST));
    fetch(`${UPDATE_PATIENT}/${patientId}`, {
      method: "PATCH",
      body: JSON.stringify(postData),
      headers: jsonApiHeader(token),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(
            actionCreator(
              AddPatientActionTypes.update_Patient.FAILURE,
              response
            )
          );
          showErrorMessage(response.message);
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage(TOKEN_EXPIRY_MESSAGE);
          localStorage.clear();
          dispatch(
            actionCreator(
              AddPatientActionTypes.update_Patient.FAILURE,
              response
            )
          );
          window.location.reload();
        } else {
          setTimeout(() => {
            dispatch(
              actionCreator(
                AddPatientActionTypes.update_Patient.SUCCESS,
                response
              )
            );
          }, 100);
          handleRedirect(`/counselor/patient/${patientId}`, patientId);
        }
      })
      .catch(function (error) {
        dispatch(actionCreator(AddPatientActionTypes.update_Patient.FAILURE));
        console.error("error update_Patient ..", error);
      });
  };
};

export const GetAllClinicsAction = () => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.token;
    dispatch(actionCreator(AddPatientActionTypes.get_AllClinics.REQUEST));
    fetch(`${GET_ALL_CLINICS}`, {
      method: "GET",
      headers: jsonApiHeader(token),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(
            actionCreator(
              AddPatientActionTypes.get_AllClinics.FAILURE,
              response
            )
          );
          showErrorMessage(response.Message);
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage(TOKEN_EXPIRY_MESSAGE);
          localStorage.clear();
          dispatch(
            actionCreator(
              AddPatientActionTypes.get_AllClinics.FAILURE,
              response
            )
          );
          window.location.reload();
        } else {
          dispatch(
            actionCreator(
              AddPatientActionTypes.get_AllClinics.SUCCESS,
              response
            )
          );
        }
      })
      .catch(function (error) {
        dispatch(actionCreator(AddPatientActionTypes.get_AllClinics.FAILURE));
        console.error("error get_AllClinics ..", error);
      });
  };
};

export const GetDoctorAndCoachByClinicAction = (id) => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.token;
    dispatch(
      actionCreator(AddPatientActionTypes.get_DoctorCoachByClinic.REQUEST)
    );
    fetch(`${GET_DOCTOR_COACH_BY_CLINIC}/${id}`, {
      method: "GET",
      headers: jsonApiHeader(token),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(
            actionCreator(
              AddPatientActionTypes.get_DoctorCoachByClinic.FAILURE,
              response
            )
          );
          showErrorMessage(response.Message);
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage(TOKEN_EXPIRY_MESSAGE);
          localStorage.clear();
          dispatch(
            actionCreator(
              AddPatientActionTypes.get_DoctorCoachByClinic.FAILURE,
              response
            )
          );
          window.location.reload();
        } else {
          dispatch(
            actionCreator(
              AddPatientActionTypes.get_DoctorCoachByClinic.SUCCESS,
              response
            )
          );
        }
      })
      .catch(function (error) {
        dispatch(
          actionCreator(AddPatientActionTypes.get_DoctorCoachByClinic.FAILURE)
        );
        console.error("error get_DoctorCoachByClinic ..", error);
      });
  };
};

export const PostUploadClinicalDataAction = (postData) => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.token;
    dispatch(
      actionCreator(AddPatientActionTypes.post_UploadClinicalData.REQUEST)
    );
    fetch(`${POST_UPLOAD_CLINICAL_DATA}`, {
      method: "POST",
      body: postData,
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(
            actionCreator(
              AddPatientActionTypes.post_UploadClinicalData.FAILURE,
              response
            )
          );
          showErrorMessage(response.message);
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage(TOKEN_EXPIRY_MESSAGE);
          localStorage.clear();
          dispatch(
            actionCreator(
              AddPatientActionTypes.post_UploadClinicalData.FAILURE,
              response
            )
          );
          window.location.reload();
        } else {
          dispatch(
            actionCreator(
              AddPatientActionTypes.post_UploadClinicalData.SUCCESS,
              response
            )
          );
        }
      })
      .catch(function (error) {
        dispatch(
          actionCreator(AddPatientActionTypes.post_UploadClinicalData.FAILURE)
        );
        console.error("error post_UploadClinicalData ..", error);
      });
  };
};

export const PostUploadCoachAssessmentDataAction = (postData) => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.token;
    dispatch(
      actionCreator(
        AddPatientActionTypes.post_UploadCoachAssessmentData.REQUEST
      )
    );
    fetch(`${POST_UPLOAD_COACH_ASSESSMENT_DATA}`, {
      method: "POST",
      body: postData,
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    })
      // .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(
            actionCreator(
              AddPatientActionTypes.post_UploadCoachAssessmentData.FAILURE,
              response
            )
          );
          showErrorMessage(response.message);
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage(TOKEN_EXPIRY_MESSAGE);
          localStorage.clear();
          dispatch(
            actionCreator(
              AddPatientActionTypes.post_UploadCoachAssessmentData.FAILURE,
              response
            )
          );
          window.location.reload();
        } else {
          dispatch(
            actionCreator(
              AddPatientActionTypes.post_UploadCoachAssessmentData.SUCCESS,
              response
            )
          );
        }
      })
      .catch(function (error) {
        dispatch(
          actionCreator(
            AddPatientActionTypes.post_UploadCoachAssessmentData.FAILURE
          )
        );
        console.error("error post_UploadCoachAssessmentData ..", error);
      });
  };
};

export const PostUploadGoalDataAction = (postData) => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.token;
    dispatch(actionCreator(AddPatientActionTypes.post_UploadGoalData.REQUEST));
    fetch(`${POST_UPLOAD_GOAL_DATA}`, {
      method: "POST",
      body: postData,
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(
            actionCreator(
              AddPatientActionTypes.post_UploadGoalData.FAILURE,
              response
            )
          );
          showErrorMessage(response.message);
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage(TOKEN_EXPIRY_MESSAGE);
          localStorage.clear();
          dispatch(
            actionCreator(
              AddPatientActionTypes.post_UploadGoalData.FAILURE,
              response
            )
          );
          window.location.reload();
        } else {
          dispatch(
            actionCreator(
              AddPatientActionTypes.post_UploadGoalData.SUCCESS,
              response
            )
          );
        }
      })
      .catch(function (error) {
        dispatch(
          actionCreator(AddPatientActionTypes.post_UploadGoalData.FAILURE)
        );
        console.error("error post_UploadClinicalData ..", error);
      });
  };
};

export const GetAddonAndOffersData = () => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.token;
    dispatch(actionCreator(AddPatientActionTypes.get_AddOnAndOffers.REQUEST));
    fetch(`${GET_ADDON_AND_OFFERS}`, {
      method: "GET",
      headers: jsonApiHeader(token),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(
            actionCreator(
              AddPatientActionTypes.get_AddOnAndOffers.FAILURE,
              response
            )
          );
          showErrorMessage(response.Message);
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage(TOKEN_EXPIRY_MESSAGE);
          localStorage.clear();
          dispatch(
            actionCreator(
              AddPatientActionTypes.get_AddOnAndOffers.FAILURE,
              response
            )
          );
          window.location.reload();
        } else {
          dispatch(
            actionCreator(
              AddPatientActionTypes.get_AddOnAndOffers.SUCCESS,
              response
            )
          );
        }
      })
      .catch(function (error) {
        dispatch(
          actionCreator(AddPatientActionTypes.get_AddOnAndOffers.FAILURE)
        );
        console.error("error get_AddOnAndOffers ..", error);
      });
  };
};
