/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { connect } from "react-redux";
import { Box, Typography, withStyles } from "@material-ui/core";
import { deliveryDetailsStyle } from "assets/jss/pages/components/deliveryDetailsStyle";

import moment from "moment";
import lang from "assets/languages";

const PatientDeliveryDeatils = ({ deliveryData, deliveryId }) => {
  const classes = deliveryDetailsStyle();

  const remarks = deliveryData?.counselorRemarks?.remarks
    ? deliveryData?.counselorRemarks?.remarks
        ?.split("\n")
        ?.map((remark) => remark?.trim())
        ?.filter((remark) => remark?.length > 0)
        ?.reverse()
    : null;

  return (
    <div className={classes.mainContainer}>
      <Box className={classes.boxContainer}>
        <Box className={classes.nameBox}>
          <Typography className={classes.firstNameIcon}>
            {deliveryData?.patient?.firstName?.[0]}
          </Typography>
        </Box>
      </Box>
      <div className={classes.container}>
        <Typography className={classes.fullNameContainer}>
          {`${deliveryData?.patient?.firstName || ""} ${
            deliveryData?.patient?.lastName || ""
          }`}
        </Typography>
        <div className={classes.detailsContainer}>
          <div className={classes.subContainer}>
            <div className={classes.subContainerFirstElement}>
              <Typography>{`${lang.orderId}:`}</Typography>
              <Typography className={classes.dataText}>
                {deliveryData?.order?.id || "--"}
              </Typography>
            </div>
            <div className={classes.subContainerNextElement}>
              <Typography>{`${lang.deliveryId} :`}</Typography>
              <Typography className={classes.dataText}>{deliveryId}</Typography>
            </div>
            <div className={classes.subContainerNextElement}>
              <Typography>{`${lang.mobileNumber}:`}</Typography>
              <Typography className={classes.dataText}>
                {`${deliveryData?.patient?.countryCode || ""}${
                  deliveryData?.patient?.mobileNumber || ""
                }`}
              </Typography>
            </div>
          </div>
          <div className={classes.subContainer}>
            <div className={classes.subContainerFirstElement}>
              <Typography>{`${lang.product}:`}</Typography>
              <Typography className={classes.dataText}>
                {deliveryData?.addOnProduct?.productName}
              </Typography>
            </div>
            <div className={classes.subContainerNextElement}>
              <Typography>{`${lang.qty}:`}</Typography>
              <Typography className={classes.dataText}>
                {deliveryData?.quantity || "--"}
              </Typography>
            </div>
            <div className={classes.subContainerNextElement}>
              <Typography>{`${lang.dataAndTime}:`}</Typography>
              <Typography className={classes.dataText}>
                {moment(deliveryData?.createdAt).format("DD/MM/YYYY")}
              </Typography>
            </div>
          </div>
          <div className={classes.subContainer}>
            <div className={classes.addressContainer}>
              <Typography>{`${lang.address}:`}</Typography>
              <Typography className={classes.dataText}>
                {`${deliveryData?.deliveryAddress?.address1 || ""}, ${
                  deliveryData?.deliveryAddress?.landmark || ""
                }, ${deliveryData?.deliveryAddress?.pincode || ""}`}
              </Typography>
            </div>
            <div className={classes.counselorRemarksContainer}>
              <Typography>{`${lang.counselorRemarks} :`}</Typography>
              <Typography className={classes.dataText}>
                {remarks?.[0]}
              </Typography>
            </div>
          </div>
          <div className={classes.subContainer}>
            <div className={classes.addressContainer}>
              <Typography>{lang.counselorName}</Typography>
              <Typography className={classes.dataText}>
                {deliveryData?.counselorName}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    loading: state.allPatientsReducers.loading,
  };
}

export default connect(mapStateToProps)(withStyles()(PatientDeliveryDeatils));
