import { makeStyles } from "@material-ui/core";
import { graphHeight } from "@pages/Patient/components/CgmProgressDrawer/SummaryModal";

export const useStyles = makeStyles({
  container: {
    padding: "10px 5px",
    borderRadius: "4px",
    border: "1px solid #EEEEEE",
    justifyContent: "flex-start",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    width: "90%",
    height: "45%",
  },
  headingContainer: {
    flexDirection: "row",
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    margin: "10px 0 0 0",
    padding: "0 0 10px 0",
    borderBottom: "1px solid #EEEEEE",
  },
  defaultHeadingText: {
    color: "#27276B",
    fontSize: "12px",
    lineHeight: "15px",
    fontWeight: 600,
  },
  headingTextWithImage: {
    color: "#94294E",
    fontSize: "14px",
    lineHeight: "17px",
    fontWeight: 600,
  },
  statusContainer: {
    padding: "1px 10px",
    borderRadius: "10px",
    margin: "12px 0",
  },
  statusText: {
    fontSize: "10px",
    lineHeight: "12px",
    fontWeight: 500,
  },
  description: {
    fontSize: "10px",
    lineHeight: "12px",
    fontWeight: 600,
    color: "#626262",
    width: "100%",
    flex: 1,
    overflow: "auto",
    textAlign: "center",
  },
  grapTimelyBox: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    height: 1.9 * graphHeight,
    border: "1px solid rgba(39, 39, 107, 0.7)",
    borderRadius: "4px",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "5px 3px",
  },
  graphMaskContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
  },
  timeSpan: {
    fontSize: "10px",
    fontWeight: 600,
    lineHeight: "12px",
    color: "#27276B",
  },
  timeSpanContainer: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    display: "flex",
  },
});
