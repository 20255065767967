import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import Logo from "../../../assets/images/Logo";
import { drawerWidth } from "../../../utils/utility";
import CustomButtons from "@components/atoms/Button";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import { handleLogoutRedirect } from "../../../utils/utility";
import { IconButton } from "@material-ui/core";
import { withRouter } from "react-router";
import lang from "assets/languages";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: "sticky",
    zIndex: 1000,
    top: 0,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    width: "100%",
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  saveButton: {
    marginLeft: "5px",
  },
  saveAndUpdateButtonContainer: {
    display: "flex",
    alignItems: "center",
  },
}));

const MyAppBar = (props) => {
  const classes = useStyles();
  // const history = useHistory();

  const handleDraft = () => {
    props.handleSaveAsDraft();
  };

  const handleSaveAndUpload = () => {
    props?.handleSubmit();
  };

  return (
    <div className={classes.root}>
      <AppBar
        position={
          window.location.pathname.split("/").includes("counselor")
            ? "fixed"
            : "static"
        }
        className={clsx(classes.appBar, {
          [classes.appBarShift]:
            ((props.value === 1 || props.value === 2) &&
              (props.view === "week_plan" ||
                props.view === "learn_more" ||
                props.view === "view_details" ||
                props.view === "task_details" ||
                props.view === "learn_more_details")) ||
            (props.value === 1 &&
              (props.view === "weight_progress" ||
                props.view === "bp_progress" ||
                props.view === "food_progress" ||
                props.view === "step_progress")) ||
            (props.value === 3 && props.appointmentDrawer) ||
            props.value === "create-week-plan",
        })}
      >
        <Toolbar
          style={{
            display: "flex",
            justifyContent: "space-between",
            background: "white",
          }}
        >
          {window.location.pathname.split("/").includes("coach") &&
          window.location.pathname.split("/").length > 3 &&
          !window.location.pathname.split("/").includes("create-week-plan") &&
          !window.location.pathname.split("/").includes("create-report") ? (
            <div
              onClick={props.handleGoBackToAllPatients}
              style={{
                display: "flex",
                cursor: "pointer",
                alignItems: "center",
                color: "#000000",
              }}
            >
              <Typography
                variant="subtitle2"
                style={{ color: "#000000", marginLeft: "5px" }}
              >
                Back to All Patients
              </Typography>
            </div>
          ) : window.location.pathname.split("/").includes("counselor") &&
            (window.location.pathname.split("/").length > 3 ||
              window.location.pathname.split("/").includes("addPatient")) ? (
            <div
              onClick={() => props.history.goBack()}
              style={{
                display: "flex",
                cursor: "pointer",
                alignItems: "center",
                color: "#000000",
              }}
            >
              <KeyboardBackspaceIcon />
            </div>
          ) : window.location.pathname.split("/").length > 3 &&
            !window.location.pathname.split("/").includes("deliveryDetails") ? (
            <CustomButtons onClick={props.handleOpenConfirmDialog} md_secondary>
              Close
            </CustomButtons>
          ) : (
            <div />
          )}
          <div
            style={{
              cursor: "pointer",
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
            }}
            onClick={() =>
              props.history.push(
                window.location.pathname.split("/").includes("counselor")
                  ? "/counselor/patients"
                  : "/coach/patients"
              )
            }
          >
            <Logo width="200px" height="40px" />
          </div>
          {!window.location.pathname.split("/").includes("create-week-plan") &&
          !window.location.pathname.split("/").includes("create-report") &&
          !window.location.pathname.split("/").includes("counselor") ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={handleLogoutRedirect}
            >
              <Typography variant="subtitle2" style={{ color: "#000000" }}>
                Logout
              </Typography>
              <ExitToAppIcon style={{ color: "#8C8C8C", marginLeft: "5px" }} />
            </div>
          ) : (
            !window.location.pathname.split("/").includes("create-week-plan") &&
            !window.location.pathname.split("/").includes("create-report") && (
              <IconButton
                style={{ cursor: "pointer" }}
                onClick={handleLogoutRedirect}
              >
                <PowerSettingsNewIcon style={{ color: "#8C8C8C" }} />
              </IconButton>
            )
          )}
          {window.location.pathname.split("/").includes("create-week-plan") ||
          window.location.pathname.split("/").includes("create-report") ? (
            <div style={useStyles.saveAndUpdateButtonContainer}>
              {window.location.pathname.split("/").includes("create-report") ? (
                <>
                  <CustomButtons
                    md
                    style={{ ...useStyles.saveButton, margin: "0 16px 0 0" }}
                    onClick={props.handleDraft}
                  >
                    {lang.saveAsDraft}
                  </CustomButtons>
                  {/* <CustomButtons
                    md
                    style={useStyles.saveButton}
                    onClick={handleSaveAndUpload}
                  >
                    {lang.saveAndUpload}
                  </CustomButtons> */}
                </>
              ) : (
                <>
                  <CustomButtons
                    md_secondary
                    onClick={handleDraft}
                    disabled="disabled"
                  >
                    {lang.saveAsDraft}
                  </CustomButtons>
                  <CustomButtons
                    md
                    style={useStyles.saveButton}
                    onClick={props.handleSubmit}
                  >
                    {props.isNewWeekPlan || props.isDuplicateWeekPlan
                      ? "Save and Share"
                      : "Update"}
                  </CustomButtons>
                </>
              )}
            </div>
          ) : null}
        </Toolbar>
      </AppBar>

      {/* Necessary to show content below the AppBar */}
      {window.location.pathname.split("/").includes("counselor") && (
        <div className={classes.toolbar} />
      )}
    </div>
  );
};

export default withRouter(MyAppBar);
