import { physicalActivity } from "assets/icons";
import { questionIcon } from "assets/icons";
import { sleepIcon } from "assets/icons";
import { medicationIcon } from "assets/icons";
import { dietIcon } from "assets/icons";

export const getGraphOptions = (
  width,
  height,
  targetzoneData,
  graphData,
  showXAxisTitle = true,
  axisFontSize,
  axisFontWeight
) => {
  const options = {
    zoomEnabled: true,
    width,
    height,
    animationEnabled: true,
    animationDuration: 1000,
    toolTip: {},
    axisX: {
      title: "time",
      gridThickness: 1,
      interval: 3,
      intervalType: "hour",
      valueFormatString: "hh TT",
      ...(axisFontSize ? { labelFontSize: axisFontSize } : {}),
      ...(axisFontWeight ? { labelFontWeight: axisFontWeight } : {}),
    },
    axisY: {
      ...(showXAxisTitle ? { title: "Blood Glucose Data" } : {}),
      interval: 40,
      ...(axisFontSize ? { labelFontSize: axisFontSize } : {}),
      ...(axisFontWeight ? { labelFontWeight: axisFontWeight } : {}),
    },
    data: [
      {
        type: "rangeArea",
        lineThickness: 0,
        color: "#D9D9D9",
        dataPoints: targetzoneData,
      },
      {
        type: "area",
        lineThickness: 1,
        color: "#FAAF23",
        dataPoints: graphData,
        fillOpacity: 0.3,
      },
    ],
  };
  return options;
};

export const getTargetzonePoints = (graphData) => {
  let targetData = graphData.map((e) => {
    return { x: e.x, y: [70, 140], label: "Target Zone" };
  });
  return targetData;
};

export const getIcon = (type) => {
  switch (type) {
    case "diet":
      return dietIcon;
    case "medication":
      return medicationIcon;
    case "physical":
      return physicalActivity;
    case "sleep":
      return sleepIcon;
    case "spike":
      return questionIcon;
    default:
      return null;
  }
};

export const metricIds = Object.freeze({
  percentile10And90: "10th&90thPercentile",
  percentile25And75: "25th&75thPercentile",
  median: "median",
});
