import { drawerWidth } from "../../../utils/utility";

const patientStyle = (theme) => ({
  root: {
    height: "48px",
    borderBottom: "1px solid #ccc",
    "& .MuiTabs-flexContainer": {
      "& .MuiTab-root": {
        minHeight: "48px",
        minWidth: "100px",
      },
      "& .MuiButtonBase-root.Mui-selected": {
        color: "#2B367D",
      },
    },
  },
  mainPanel: {
    padding: "20px 170px",
  },
  mainPanelShift: {
    padding: "20px",
    width: `calc(100% - ${drawerWidth + 40}px)`,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    overflowX: "hidden",
  },
  drawerPaper: {
    width: drawerWidth,
  },
  appBar: {
    boxShadow: "none",
  },
  container: {
    height: "100%",
    border: "1px solid #ccc",
    backgroundColor: "white",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  text: {
    fontSize: "24px",
    fontWeight: 700,
  },

  icon: {
    cursor: "pointer",
  },
  headerContainer2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: "64px",
    maxHeight: "64px",
    borderBottom: "1px solid #ccc",
    padding: "0px 16px",
    position: "sticky",
    zIndex: 1000,
    top: 0,
    backgroundColor: "white",
  },

  icon2: {
    cursor: "pointer",
  },
  container2: {
    display: "flex",
    gap: "18px",
  },
});

export default patientStyle;
