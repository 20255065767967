import React from "react";
import { createStyles, makeStyles } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { useField, useFormikContext } from "formik";

const FormDatePicker = ({ name, maxDate, disabled, ...otherProps }) => {
  const useStyles = makeStyles((theme) =>
    createStyles({
      root: {
        width: "100%",
        height: "45px",
        color: "#000000",
        border: `1px solid #D9D9D9`,
        borderRadius: 0,
        "& .MuiInputBase-root": {
          fontSize: "14px",
          color: disabled ? "EEEEEE" : "#000000",
          "& .MuiInputBase-input": {
            padding: "13px 14px",
          },
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: `0px !important`,
          outline: "0 !important",
        },
        "& .MuiSelect-icon": {
          color: "#595959",
        },
        "& .MuiInputLabel-root": {
          // "&.MuiFormLabel-filled": {
          //   // opacity: 0,
          // },
          color: "#595959",
        },
      },
    })
  );

  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);
  const classes = useStyles();
  const disable = { disabled: disabled ? disabled : null };

  const configDateTimePicker = {
    ...field,
    ...disable,
    ...otherProps,
    format: "DD/MM/yyyy",
    inputVariant: "outlined",
    autoOk: true,
    invalidDateMessage: "", //to be changed later
    disableToolbar: false,
    maxDate: maxDate ? maxDate : undefined,
    fullWidth: true,
    onChange: (value) => {
      setFieldValue(name, value);
      otherProps?.onChange?.(value);
    },
    PopoverProps: {
      anchorOrigin: { horizontal: "right", vertical: "bottom" },
      transformOrigin: { horizontal: "right", vertical: "top" },
    },
  };

  if (meta && meta.touched && meta.error) {
    configDateTimePicker.error = true;
    configDateTimePicker.helperText = meta.error;
  }

  return <DatePicker className={classes.root} {...configDateTimePicker} />;
};

export default FormDatePicker;
