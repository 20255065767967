import { Box, Typography } from "@material-ui/core";
import { useStyles } from "../styles";
import { tirIdealPercentages } from "./constants";
import { StoreProviderService } from "@services/storeProviderService";
import { reportTypesId } from "@pages/CreateReport/constants";

export const RangeItem = ({ tirData }) => {
  const classes = useStyles();

  const {
    createReportReducer: {
      activeFileType,
      patientProgress: { sensor },
    },
  } = StoreProviderService.getState();

  const { key, sevenDay, fourteenDay } = tirData;

  const { min, max } = sensor;
  const { value, comparator, idealValue, getIdealDescription } =
    tirIdealPercentages[key];

  const subHeading = getIdealDescription(min, max);

  const tagName = key.toUpperCase();

  const headingValue =
    activeFileType === reportTypesId.seventhDayReport ? sevenDay : fourteenDay;

  const isInRange =
    comparator === "<" ? headingValue < value : headingValue > value;

  const heading = tirIdealPercentages[key]?.heading;

  return (
    <Box className={classes.tirItemContainer}>
      <Box className={classes.tirTagContainer}>
        <Typography className={classes.tirTag}>{tagName}</Typography>
      </Box>
      <Box className={classes.tirRightContent}>
        <Box className={classes.tirRowItem}>
          <Typography className={classes.tirHeading}>{heading}</Typography>
          <Typography
            className={`${classes.tirHeadingValue} ${
              isInRange ? classes.underTargetRange : classes.aboveTargetRange
            }`}
          >
            {`${headingValue ?? "--"}%`}
          </Typography>
        </Box>
        <Box className={`${classes.tirRowItem} ${classes.bottomUnderline}`}>
          <Typography className={classes.tirSubHeading}>
            {subHeading}
          </Typography>
          <Typography
            className={`${classes.tirSubHeadingValue} ${classes.underTargetRange}`}
          >
            {`${comparator} ${idealValue}%`}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
