import React, { useState, useEffect, useRef } from "react";
import Aux from "@utils/Auxilary";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Switch, withRouter, Redirect, Route } from "react-router-dom";
import { RefreshTokenAction } from "@pages/Authentication/actions/LoginActions";
import AuthLayout from "@templates/Auth/AuthLayout";
import CounsellorLayout from "@templates/Counsellor/CounsellorLayout";
import CoachLayout from "@templates/Coach/CoachLayout";
import Loader from "@components/atoms/Loader";
import AdminLayout from "@templates/Admin/AdminLayout";
import { userRoles } from "@utils/constants";
import OpsAdmin from "@templates/OpsAdmin/OpsAdmin";
import {
  fetchDataWiseCGMData,
  fetchPatientInfoData,
  fetchPatientProgressData,
  get7And14DayGraphData,
} from "@services/createReportServices";
import {
  actionCreator,
  reportTypeForApi,
  reportTypesId,
} from "@pages/CreateReport/constants";
import { setSlectedReportType } from "@pages/CreateReport/actions/CreateReportActions";
import { StoreProviderService } from "@services/storeProviderService";
import { LoginActionTypes } from "@pages/Authentication/constants";
import { CreateAutomatedReport } from "@pages/MetasenseReport/clinicAutomatedReport";

const dispatchActionForReportAutomation = () => {
  const urlParams = new URLSearchParams(window.location.search);

  const reportType = urlParams.get("reportType");
  const reportId = urlParams.get("reportId");
  const sensorId = urlParams.get("sensorId");
  const authToken = urlParams.get("authorization");

  const patientId = window.location.href
    .replace(window.location.origin, "")
    .split("?")[0]
    .split("/")
    .pop();

  if (authToken && reportType && reportId && sensorId && patientId) {
    const dispatch = StoreProviderService.getDispatch();

    localStorage.setItem("access_token", authToken);

    dispatch(
      actionCreator(LoginActionTypes.post_Login.SUCCESS, {
        data: { role: userRoles.automation },
        token: authToken,
      })
    );

    const selectedReportId =
      reportType === reportTypeForApi.seventhDay
        ? reportTypesId.seventhDayReport
        : reportTypesId.fourteenthDayReport;

    dispatch(setSlectedReportType(Number(selectedReportId)));

    get7And14DayGraphData(sensorId, reportType, selectedReportId);

    fetchPatientInfoData(reportId);
    fetchPatientProgressData(reportId);
    fetchDataWiseCGMData(patientId, { sensorId, reportType });
  }
};

function RoutesComponent(props) {
  const [isLoggedIn, setUserIsLoggedIn] = useState(false);
  const { role, loading, token } = props;

  const isComponentMounted = useRef(false);

  useEffect(() => {
    localStorage.getItem("LoggedOut") === "false" &&
      props.RefreshTokenAction(
        localStorage.getItem("access_token"),
        localStorage.getItem("userId")
      );
  }, []);

  useEffect(() => {
    const accessToken = localStorage.getItem("access_token");

    if (!isLoggedIn && accessToken === token) {
      setUserIsLoggedIn(true);
    }
  }, [token, isLoggedIn]);

  if (!isComponentMounted.current) {
    isComponentMounted.current = true;

    dispatchActionForReportAutomation();
  } else {
    isComponentMounted.current = true;
  }

  const redirectRouteHandler = (role) => {
    if (role.toUpperCase() === userRoles.automation) {
      return (
        <Switch>
          <Route
            path="/create-automated-report/:reportId"
            component={CreateAutomatedReport}
          />
          <Route exact path="/">
            <Redirect exact from="/" to="/create-automated-report/:reportId" />
          </Route>
          <Route path="**">
            <Redirect exact from="*" to="/create-automated-report/:reportId" />
          </Route>
        </Switch>
      );
    } else if (
      role.toUpperCase() === userRoles.coach ||
      role === userRoles.fitnessCoach ||
      role === userRoles.emotionalWellnessCoach
    ) {
      return (
        <Switch>
          <Route exact path="/">
            <Redirect exact from="/" to="/coach/patients" />
          </Route>
          <Route path="**">
            <Redirect exact from="*" to="/coach/patients" />
          </Route>
        </Switch>
      );
    } else if (role.toUpperCase() === userRoles.councellor) {
      return (
        <Switch>
          <Route exact path="/">
            <Redirect exact from="/" to="/counselor/patients" />
          </Route>

          <Route path="**">
            <Redirect exact from="*" to="/counselor/patients" />
          </Route>
        </Switch>
      );
    } else if (
      role.toUpperCase() === userRoles.admin ||
      role === userRoles.superAdmin
    ) {
      return (
        <Switch>
          <Route exact path="/">
            <Redirect exact from="/" to="/admin/sensorDeactivation" />
          </Route>

          <Route path="**">
            <Redirect exact from="*" to="/admin/sensorDeactivation" />
          </Route>
        </Switch>
      );
    } else if (role.toUpperCase() === userRoles.inventoryManager) {
      return (
        <Switch>
          <Route exact path="/">
            <Redirect exact from="/" to="/opsAdmin/deliveryDashboard" />
          </Route>

          <Route path="**">
            <Redirect exact from="*" to="/opsAdmin/deliveryDashboard" />
          </Route>
        </Switch>
      );
    } else {
      return;
    }
  };

  let routes = (
    <>
      {isLoggedIn ? (
        <Switch>
          <Route path="/coach" component={CoachLayout} />
          <Route path="/counselor" component={CounsellorLayout} />
          <Route path="/admin" component={AdminLayout} />
          <Route path="/opsAdmin" component={OpsAdmin} />
          {role && redirectRouteHandler(role)}
        </Switch>
      ) : (
        <>
          {loading ? (
            <Loader open={true} loaderMessage="Fetching..." />
          ) : (
            <Switch>
              <Route path="/auth/login-page" component={AuthLayout} />
              <Redirect to="/auth/login-page" />
            </Switch>
          )}
        </>
      )}
    </>
  );

  return <Aux>{routes}</Aux>;
}

function mapStateToProps(state) {
  return {
    loading: state.loginReducer.loading,
    isLoggedInUser: state.loginReducer.isLoggedIn,
    role:
      state.loginReducer.loggedInUserDetails !== null &&
      state.loginReducer.loggedInUserDetails.data.role,
    token:
      state.loginReducer.loggedInUserDetails !== null
        ? state.loginReducer.loggedInUserDetails.token
        : "",
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        RefreshTokenAction,
      },
      dispatch
    ),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RoutesComponent)
);
