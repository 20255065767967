import React from "react";
import { Box, Typography, withStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import doctorInstructionStyle from "assets/jss/pages/doctorInstructionStyle";
import { instructionFor } from "./constants";
import moment from "moment";

const InstructionDrawer = ({ onClose, classes, selectedInstruction }) => {
  const {
    instructionFor: sentTo,
    updatedAt = new Date(),
    instructionText = "",
  } = selectedInstruction;

  const date = moment(updatedAt).format("DD MMM, YYYY");

  return (
    <Box
      sx={{ width: 360 }}
      role="presentation"
      onClick={onClose}
      onKeyDown={onClose}
      className={classes.drawerContainer}
    >
      <Box className={classes.drawerHeading}>
        <Typography className={classes.drawerDocInstruction}>
          {"Doctor’s Instruction"}
        </Typography>
        <CloseIcon fontSize="small" onClick={onClose} />
      </Box>
      <Box className={classes.drawerContent}>
        <Typography className={classes.drawerDocInstruction}>
          {instructionFor[sentTo]}
        </Typography>
        <Typography className={classes.drawerSentDate}>{date}</Typography>
        <Box className={classes.drawerInstruction}>
          <Typography>{instructionText}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default withStyles(doctorInstructionStyle)(InstructionDrawer);
