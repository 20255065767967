import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withStyles, Typography } from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  ReferenceLine,
  Label,
} from "recharts";
import api from "utils/api";
import sleepProgressDrawerStyle from "../../../../assets/jss/pages/components/sleepProgressDrawerStyle";
import { weekDays, monthDays, yearDays } from "../../../../utils/utility";

const fetchData = async (id, metric) => {
  try {
    const { data } = await api(`/v1/health/getSleep/${id}?duration=${metric}`, {
      method: "get",
    });
    return data;
  } catch (e) {
    console.error("fetch user progress", e);
  }
  return {};
};

const fetchChartData = async (type, id) => {
  try {
    const { data } = await api(`/v1/health/charts/${id}?type=${type}`, {
      method: "get",
    });
    return data;
  } catch (e) {
    console.error("fetch user progress", e);
  }
  return {};
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const SleepProgressDrawer = (props) => {
  const [value, setValue] = React.useState(0);
  const { classes, patientId } = props;

  const [dayData, setDayData] = useState({});
  const [weekData, setWeekData] = useState({});
  const [monthData, setMonthData] = useState(props.monthData || {});
  const [yearData, setYearData] = useState({});
  const [details, setDetails] = useState({});
  const [weekDay, setWeekDays] = useState({});
  const [monthDay, setMonthDays] = useState({});
  const [yearDay, setYearDays] = useState({});

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (value === 0 && !yearData?.data) {
      if (!details?.data && !details?.status) {
        fetchChartData("sleep", patientId).then(setDetails);
      }
      fetchData(patientId, "year").then(setYearData);
      setYearDays(yearDays());
    } else if (value === 1 && !monthData?.data) {
      fetchData(patientId, "month").then(setMonthData);
      setMonthDays(monthDays());
    } else if (value === 2 && !weekData?.data) {
      fetchData(patientId, "week").then(setWeekData);
      setWeekDays(weekDays());
    }
  }, [patientId, value, details]);

  return (
    <>
      <div className={classes.headerContainer}>
        <Typography style={{ fontSize: "24px", fontWeight: 500 }}>
          Sleep
        </Typography>
        <CloseIcon className={classes.icon} onClick={props.handleCloseDrawer} />
      </div>
      <Tabs className={classes.root} value={value} onChange={handleChange}>
        <Tab label="Year" {...a11yProps(0)} />
        <Tab label="Month" {...a11yProps(1)} />
        <Tab label="Week" {...a11yProps(2)} />
      </Tabs>

      <TabPanel style={{ overflowX: "hidden" }} value={value} index={0}>
        <Typography style={{ marginLeft: 16 }}>
          {yearData?.average == "NaN"
            ? "No Data"
            : "Avg. " + yearData?.average + " hrs"}
        </Typography>
        <div className={classes.sleep_container}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={300}
              height={144}
              data={yearData.data}
              margin={{
                top: 10,
                right: 20,
                left: 20,
                bottom: 0,
              }}
            >
              <CartesianGrid vertical={false} strokeDashArray="1" />
              <Line dataKey="avg" stroke="#FFFFFF" strokeWidth={2} />
              <YAxis
                axisLine={false}
                tick={{ fontSize: 12 }}
                tickLine={false}
                stroke="#FFFFFF"
                orientation="right"
                width={25}
                domain={[0, 10]}
              />
              <XAxis
                tick={{ fontSize: 12 }}
                height={25}
                stroke="#FFFFFF"
                tickLine={false}
                dataKey="month"
                scale="band"
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
        {details.data?.map((data, index) => {
          if (
            yearDay &&
            moment(data.date).isBetween(
              yearDay.firstDay,
              yearDay.lastDay,
              undefined,
              "[]"
            )
          ) {
            return (
              <div
                className={classes.mainContainer}
                onClick={() =>
                  props.openDrawer(
                    "task_details_graph",
                    "sleep",
                    "Sleep_progress",
                    "",
                    data
                  )
                }
                key={index}
              >
                <div>
                  <Typography className={classes.text}>
                    {moment(data.date).format("DD MMM YYYY")}
                  </Typography>
                  <Typography className={classes.sub_text}>Date</Typography>
                </div>
                <div className={classes.flex}>
                  <Typography className={classes.text}>
                    {data.sleepingHours} hrs
                  </Typography>
                  <ChevronRight className={classes.chevron} />
                </div>
              </div>
            );
          }
        })}
      </TabPanel>
      <TabPanel style={{ overflowX: "hidden" }} value={value} index={1}>
        <Typography style={{ marginLeft: 16 }}>
          {monthData?.average == "NaN"
            ? "No Data"
            : "Avg. " + monthData?.average + " hrs"}
        </Typography>
        <div className={classes.sleep_container}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={300}
              height={144}
              data={
                monthData.data
                  ?.sort(function (a, b) {
                    return a.week - b.week;
                  })
                  ?.map((data) => ({
                    ...data,
                    week: "week " + data.week,
                  })) || []
              }
              margin={{
                top: 10,
                right: 20,
                left: 20,
                bottom: 0,
              }}
            >
              <CartesianGrid vertical={false} strokeDashArray="1" />
              <Line dataKey="avg" stroke="#FFFFFF" strokeWidth={2} />
              <YAxis
                axisLine={false}
                tick={{ fontSize: 12 }}
                tickLine={false}
                stroke="#FFFFFF"
                orientation="right"
                width={25}
                domain={[0, 10]}
              />
              <XAxis
                tick={{ fontSize: 12 }}
                height={25}
                stroke="#FFFFFF"
                tickLine={false}
                dataKey="week"
                scale="band"
              />
              {
                <ReferenceLine y={monthData.goal} stroke="#FDCD23">
                  <Label
                    fontSize="12px"
                    fontWeight={600}
                    fill="#FFFFFF"
                    value={`Goal: ${monthData.goal} hrs`}
                    offset={2}
                    position="insideTopLeft"
                  />
                </ReferenceLine>
              }
            </LineChart>
          </ResponsiveContainer>
        </div>
        {details.data?.map((data, index) => {
          if (
            monthDay &&
            moment(data.date).isBetween(
              monthDay.firstDay,
              monthDay.lastDay,
              undefined,
              "[]"
            )
          ) {
            return (
              <div
                className={classes.mainContainer}
                onClick={() =>
                  props.openDrawer(
                    "task_details_graph",
                    "sleep",
                    "Sleep_progress",
                    "",
                    data
                  )
                }
                key={index}
              >
                <div>
                  <Typography className={classes.text}>
                    {moment(data.date).format("DD MMM YYYY")}
                  </Typography>
                  <Typography className={classes.sub_text}>Date</Typography>
                </div>
                <div className={classes.flex}>
                  <Typography className={classes.text}>
                    {data.sleepingHours} hrs
                  </Typography>
                  <ChevronRight className={classes.chevron} />
                </div>
              </div>
            );
          }
        })}
      </TabPanel>
      <TabPanel style={{ overflowX: "hidden" }} value={value} index={2}>
        <Typography style={{ marginLeft: 16 }}>
          {weekData?.average == "NaN"
            ? "No Data"
            : "Avg. " + weekData?.average + "hrs "}
        </Typography>
        <div className={classes.sleep_container}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={300}
              height={144}
              data={weekData?.data
                ?.sort(function (a, b) {
                  return new Date(a.date) - new Date(b.date);
                })
                .map((data) => ({
                  ...data,
                  date: moment(data.date).format("ddd"),
                }))}
              margin={{
                top: 10,
                right: 20,
                left: 20,
                bottom: 0,
              }}
            >
              <CartesianGrid vertical={false} strokeDashArray="1" />
              <Line dataKey="total" stroke="#FFFFFF" strokeWidth={2} />
              <YAxis
                axisLine={false}
                tick={{ fontSize: 12 }}
                tickLine={false}
                stroke="#FFFFFF"
                orientation="right"
                width={25}
                domain={[0, 10]}
              />
              <XAxis
                tick={{ fontSize: 12 }}
                height={25}
                stroke="#FFFFFF"
                tickLine={false}
                dataKey="date"
                scale="band"
              />
              {
                <ReferenceLine y={weekData.goal} stroke="#FDCD23">
                  <Label
                    fontSize="12px"
                    fontWeight={600}
                    fill="#FFFFFF"
                    value={`Goal: ${weekData.goal} hrs`}
                    offset={2}
                    position="insideTopLeft"
                  />
                </ReferenceLine>
              }
            </LineChart>
          </ResponsiveContainer>
        </div>
        {details.data?.map((data, index) => {
          if (
            weekDay &&
            moment(data.date).isBetween(
              weekDay.firstDay,
              weekDay.lastDay,
              undefined,
              "[]"
            )
          ) {
            return (
              <div
                className={classes.mainContainer}
                onClick={() =>
                  props.openDrawer(
                    "task_details_graph",
                    "sleep",
                    "Sleep_progress",
                    "",
                    data
                  )
                }
                key={index}
              >
                <div>
                  <Typography className={classes.text}>
                    {moment(data.date).format("DD MMM YYYY")}
                  </Typography>
                  <Typography className={classes.sub_text}>Date</Typography>
                </div>
                <div className={classes.flex}>
                  <Typography className={classes.text}>
                    {data.sleepingHours} hrs
                  </Typography>
                  <ChevronRight className={classes.chevron} />
                </div>
              </div>
            );
          }
        })}
      </TabPanel>
    </>
  );
};

export default withStyles(sleepProgressDrawerStyle)(SleepProgressDrawer);
