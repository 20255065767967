import CanvasJSReact from "canvasjs.react";
import { getMetricGrapOption } from "@pages/Patient/components/CgmProgressDrawer/utils";
import { useMemo } from "react";
import { useStyles } from "../style";

const { CanvasJSChart } = CanvasJSReact;

export const CgmGraph = ({
  graphId,
  graphData,
  graphWidth,
  graphHeight,
  showInLegend,
}) => {
  const classes = useStyles();
  const options = useMemo(
    () =>
      graphData
        ? getMetricGrapOption(
            graphData,
            graphWidth,
            graphHeight,
            graphHeight,
            showInLegend
          )
        : null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [graphData, graphWidth]
  );

  return (
    <div style={{ width: graphWidth }} className={classes.graphContainer}>
      {graphData && <CanvasJSChart options={options} graphId={graphId} />}
    </div>
  );
};

export default CgmGraph;
