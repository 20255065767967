/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Typography,
  Grid,
  Drawer,
  withStyles,
  Modal,
  Box,
  Button,
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Loader from "@components/atoms/Loader";
import RichTextEditor from "@components/molecules/RichTextEditor";
import CustomButton from "@components/atoms/Button";
import PatientInfo from "./pages/PatientInfo";
import WeekPlans from "./pages/WeekPlans/WeekPlans";
import MyAppBar from "@components/organisms/AppBar";
import AppointmentDrawer from "./components/AppointmentDrawer/AppointmentDrawer";
import WeekPlanDrawer from "./components/WeekPlanDrawer/WeekPlanDrawer";
import Appointment from "./pages/Appointment/Appointment";
import Documents from "./pages/Documents/Documents";
import Progress from "./pages/Progress";
import WeekPlanDetailsDrawer from "./components/WeekPlanDetailsDrawer/WeekPlanDetailsDrawer";
import LearnMoreDrawer from "./components/LearnMoreDrawer/LearnMoreDrawer";
import TaskDetailsDrawer from "./components/TaskDetailsDrawer/TaskDetailsDrawer";
import patientStyle from "../../assets/jss/pages/patientStyle";
import WeightProgressDrawer from "./components/WeightProgressDrawer/WeightProgressDrawer";
import BloodSugarProgressDrawer from "./components/BloodSugarProgressDrawer/BloodSugarProgressDrawer";
import FoodProgressDrawer from "./components/FoodProgressDrawer/FoodProgressDrawer";
import StepsProgressDrawer from "./components/StepsProgressDrawer/StepsProgressDrawer";

import LearnMoreDetailsDrawer from "./components/LearnMoreDetailsDrawer/LearnMoreDetailsDrawer";
import {
  GetAllAppointmentsAction,
  SaveNoteAction,
  GetAllWeekPlansAction,
  GetModuleDetailsByWeekPlanAction,
  GetTaskDetailsByModuleAction,
  GetTaskDetailsByDataEntryAction,
  DeleteWeekPlanByIdAction,
} from "./actions/PatientActions";
import api from "utils/api";
import RandomBloodSugarProgressDrawer from "./components/RandomBloodSugarProgressDrawer/RandomBloodSugarProgressDrawer";
import PreMealBloodSugarProgressDrawer from "./components/PreMealBloodSugarProgressDrawer/PreMealBloodSugarProgressDrawer";
import PostMealBloodSugarProgressDrawer from "./components/PostMealBloodSugarProgressDrawer/PostMealBloodSugarProgressDrawer";
import SleepProgressDrawer from "./components/SleepProgressDrawer/SleepProgressDrawer";
import PhysicalProgressdrawer from "./components/PhysicalProgressDrawer/PhysicalProgressdrawer";
import Hb1acProgressDrawer from "./components/Hb1acProgressDrawer.js/Hb1acProgressDrawer";
import BpDetails from "./components/DetailsDrawers/BpDetails";
import UpdateWeightDrawer from "./components/UpdateHealthDataDrawers/weight/UpdateWeightDrawer";
import AddHealthData from "./components/AddHealthData/AddHealthData";
import AddBloodSugar from "./components/AddHealthData/BloodSugar/AddBloodSugar";
import AddWeight from "./components/AddHealthData/Weight/AddWeight";
import AddDiet from "./components/AddHealthData/Diet/AddDiet";
import AddPhysicalActivityData from "./components/AddHealthData/PhysicalActivity/AddPhysicalActivityData";
import AddSleep from "./components/AddHealthData/Sleep/AddSleep";
import AddSteps from "./components/AddHealthData/Steps/AddSteps";
import AddHba1c from "./components/AddHealthData/Hba1c/AddHba1c";
import UpdateBloodSugarDrawer from "./components/UpdateHealthDataDrawers/BloodSugar/UpdateBloodSugarDrawer";
import UpdateDietDrawer from "./components/UpdateHealthDataDrawers/Diet/UpdateDietDrawer";
import UpdateStepsDrawer from "./components/UpdateHealthDataDrawers/Steps/UpdateStepsDrawer";
import UpdatePhysicalDrawer from "./components/UpdateHealthDataDrawers/Physical/UpdatePhysicalDrawer";
import UpdateHba1cDrawer from "./components/UpdateHealthDataDrawers/Hba1c/UpdateHba1cDrawer";
import UpdateSleepDrawer from "./components/UpdateHealthDataDrawers/sleep/UpdateSleepDrawer";
import WeekPlanProgress from "./components/WeekPlanProgress/WeekPlanProgress";
import BlogDrawer from "./components/BlogDrawer.js/BlogDrawer";
import VideoDrawer from "./components/VideoDrawer/VideoDrawer";
import CgmProgressDrawer from "./components/CgmProgressDrawer";
import DoctorInstruction from "./pages/DoctorInstruction";
import HealthProfile from "./pages/HealthProfile";
import { notyf } from "@utils/utility";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const fetchUserProgress = async (id, metric, type = null) => {
  try {
    const { data } = await api(
      `/v1/health/${metric}/${id}?duration=month&type=${type}`,
      {
        method: "get",
      }
    );
    return data;
  } catch (e) {
    console.error("fetch user progress", e);
  }
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const Patient = (props) => {
  const {
    classes,
    allAppointments,
    allWeekPlans,
    moduleDetailsByWeekPlan,
    taskDetailsByModule,
    taskDetailsByDataEntry,
    loading,
    noteSuccess,
    updateSuccess,
    allPatients,
  } = props;

  const patientData = props.history.location.state?.data;
  const patientId = patientData?.id;

  const [tabIndex, setTabIndex] = useState(0);
  const [view, setView] = useState("");
  const [taskType, setTaskType] = useState("");

  const [note, setNote] = useState(patientData.notes || "");
  const [progress, setProgress] = useState({});

  //Appointment Data
  const [appointmentId, setCurrentAppointmentId] = useState("");
  const [appointmentData, setAppointmentData] = useState([]);

  //WeekPlan All Modules
  const [weekPlans, setWeekPlans] = useState([]);

  // WeekPlan Progress
  const [activeWeek, setActiveWeek] = useState(null);

  //Current WeekPlan Id and Object
  const [currentWeekPlan, setCurrentWeekPlan] = useState({});

  //Current Module
  const [currentModule, setCurrentModule] = useState("");

  //Current category
  const [category, setCategory] = useState("");

  //Current Blog
  const [currentBlog, setCurrentBlog] = useState({});

  const [taskDetails, setTaskDetails] = useState();

  const [prev, setPrev] = useState("");

  const [metric, setMetric] = useState("");

  const [modal, setModal] = useState(false);

  const [openBlog, setOpenBlog] = useState(null);

  const [selectedWeek, setSelectedWeek] = useState(null);

  const draftedWeekPlan = weekPlans.find(
    (weekPlan) => weekPlan.isDraft === true
  );

  // const fetchWeekProgress = useCallback(async (id) => {
  //   try {
  //     const { data } = await api(`/v1/weekplans/patients/${id}`, {
  //       method: "get",
  //     });

  //   } catch (e) {
  //     console.error("fetch week progress", e);
  //   }
  // }, []);

  useEffect(() => {
    const _activeWeek = weekPlans.find((week) => week.isDraft === false);

    if (_activeWeek) {
      setActiveWeek(_activeWeek);
    }
  }, [weekPlans]);

  useEffect(() => {
    props.GetAllWeekPlansAction(patientId);
    props.GetAllAppointmentsAction(patientId);
  }, []);

  useEffect(() => {
    if (allAppointments) {
      setAppointmentData(allAppointments);
    }
  }, [allAppointments]);

  useEffect(() => {
    if (updateSuccess && updateSuccess.status === "SUCCESS") {
      setView(prev);
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (noteSuccess === "SUCCESS") {
      setModal(false);
    }
  }, [noteSuccess]);

  useEffect(() => {
    if (allPatients) {
      const patientInfo =
        allPatients.length > 0 &&
        allPatients.find((patient) => patient.id === patientId);
      if (patientData.notes !== patientInfo.notes) {
        setNote(patientInfo.notes);
      }
    }
  }, [allPatients]);

  const handleOpen = () => {
    setModal(true);
  };
  const handleClose = () => {
    setModal(false);
  };

  const fetchUserData = () => {
    fetchUserProgress(patientId, "getWeight").then((data) =>
      setProgress((pro) => ({ ...pro, weight: data }))
    );
    fetchUserProgress(patientId, "getBloodSugar", "fasting").then((data) =>
      setProgress((pro) => ({ ...pro, bloodsugar: data }))
    );
    fetchUserProgress(patientId, "getBloodSugar", "random").then((data) =>
      setProgress((pro) => ({ ...pro, bloodsugarRandom: data }))
    );
    fetchUserProgress(patientId, "getBloodSugar", "premeal").then((data) =>
      setProgress((pro) => ({ ...pro, bloodsugarPreMeal: data }))
    );
    fetchUserProgress(patientId, "getBloodSugar", "postmeal").then((data) =>
      setProgress((pro) => ({ ...pro, bloodsugarPostMeal: data }))
    );
    fetchUserProgress(patientId, "getDiet").then((data) =>
      setProgress((pro) => ({ ...pro, diet: data }))
    );
    fetchUserProgress(patientId, "getPhysicalActivity").then((data) =>
      setProgress((pro) => ({ ...pro, physical: data }))
    );
    fetchUserProgress(patientId, "getHba1c_test").then((data) =>
      setProgress((pro) => ({ ...pro, hba1c: data }))
    );
    fetchUserProgress(patientId, "getSleep").then((data) =>
      setProgress((pro) => ({ ...pro, sleep: data }))
    );
    fetchUserProgress(patientId, "getSteps").then((data) =>
      setProgress((pro) => ({ ...pro, steps: data }))
    );
  };

  useEffect(() => {
    fetchUserData();
    // fetchWeekProgress(patientId);
  }, [fetchUserProgress, patientId]);

  useEffect(() => {
    if (allWeekPlans) {
      setWeekPlans(
        allWeekPlans.filter((weekPlan) => patientId === weekPlan.patient)
      );
    }
  }, [allWeekPlans, patientId]);

  const handleChangeNote = (value) => {
    setNote(value);
  };

  const handleSaveNote = () => {
    if (!patientData.coach) {
      notyf.error("You are not authorised !");

      return;
    }

    let postData = {
      patientId: patientId,
      coachId: patientData.coach,
      notes: note,
    };

    props.SaveNoteAction(postData);
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleGoBackToAllPatients = () => {
    props.history.goBack();
  };

  const handleViewWeekPlanDetails = (plan) => {
    props.GetTaskDetailsByModuleAction(plan.id);
    setCurrentModule(plan);
    setView("view_details");
  };

  const handleGoToWeekPlan = (id) => {
    props.GetModuleDetailsByWeekPlanAction(id);
    setCurrentWeekPlan(weekPlans.find(({ id: weekId }) => weekId === id));
    setView("week_plan");
  };

  const handleGoToTaskDetails = (id, type) => {
    props.GetTaskDetailsByDataEntryAction(id, type);
    setTaskType(type);
    setView("task_details");
  };

  const handleGoToLearnMore = (category) => {
    setCategory(category);
    setView("learn_more");
  };

  const handleGoToLearnMoreDetails = (blog) => {
    setCurrentBlog(blog);
    setView("learn_more_details");
  };

  const handleOpenAppointmentDrawer = (id) => {
    setCurrentAppointmentId(id);
    setView("appointment_details");
  };

  const handleOpenWeightProgressDrawer = () => {
    setView("weight_progress");
  };

  const handleOpenBloodSugarProgressDrawer = () => {
    setView("bp_progress");
  };

  const handleWeekNavigate = (week) => {
    setView("WEEKPLAN_PROGRESS");
    setSelectedWeek(week);
  };

  const handleOpenFoodProgressDrawer = () => {
    setView("food_progress");
  };

  const handleOpenStepProgressDrawer = () => {
    setView("step_progress");
  };

  const handleOpenRandomBPSProgressDrawer = () => {
    setView("RBP_progress");
  };

  const handleOpenPreBPSProgressDrawer = () => {
    setView("PreBP_progress");
  };

  const handleOpenPostBPSProgressDrawer = () => {
    setView("PostBP_progress");
  };

  const handleOpenSleepProgressDrawer = () => {
    setView("Sleep_progress");
  };
  const handleOpenPhysicalProgressDrawer = () => {
    setView("Physical_progress");
  };
  const handleOpenHb1acProgressDrawer = () => {
    setView("Hb1ac_progress");
  };

  const handleCgmProgressDrawer = () => {
    setView("CGM_PROGRESS");
  };

  const handleProgress = (value) => {
    setView(value);
  };
  const openBpDetailsDrawer = (value, type, prev, metric, data, post) => {
    setView(value);
    setTaskType(type);
    setTaskDetails(data);
    setPrev(prev);
    setMetric(metric);
    setTabIndex(2);
    if (post) {
      setOpenBlog(post);
    }
  };

  const handleCloseDrawer = () => {
    setView("");
    // setCurrentWeekPlanId("");
    setCurrentAppointmentId("");
    setSelectedWeek(null);
  };

  const handleCloseDetailsDrawer = (value) => {
    setView(value);
    // setCurrentWeekPlanId("");
    fetchUserData();
    setCurrentAppointmentId("");
  };

  const handleDeleteWeekPlan = (id) => {
    props.DeleteWeekPlanByIdAction(id, () => {
      window.location.reload();
    });
  };

  return (
    <>
      {loading && <Loader open={true} loaderMessage="Fetching..." />}
      <MyAppBar
        handleGoBackToAllPatients={handleGoBackToAllPatients}
        value={tabIndex}
        view={view}
      />
      {(tabIndex === 2 || tabIndex === 3) && view === "week_plan" && (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={true}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <WeekPlanDrawer
            moduleDetailsByWeekPlan={moduleDetailsByWeekPlan}
            weekPlans={weekPlans}
            currentWeekPlan={currentWeekPlan}
            currentWeekPlanId={currentWeekPlan.id}
            handleViewWeekPlanDetails={handleViewWeekPlanDetails}
            handleCloseDrawer={handleCloseDrawer}
            openBlog={openBpDetailsDrawer}
          />
        </Drawer>
      )}
      {(tabIndex === 2 || tabIndex === 3) && view === "view_details" && (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={true}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <WeekPlanDetailsDrawer
            handleGoToWeekPlan={() => setView("week_plan")}
            handleGoToLearnMore={handleGoToLearnMore}
            handleCloseDrawer={handleCloseDrawer}
            handleGoToTaskDetails={handleGoToTaskDetails}
            taskDetailsByModule={taskDetailsByModule}
            currentWeekPlan={currentModule}
          />
        </Drawer>
      )}
      {(tabIndex === 2 || tabIndex === 3) && view === "task_details" && (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={true}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <TaskDetailsDrawer
            currentWeekPlan={currentModule}
            handleCloseDrawer={handleCloseDrawer}
            taskType={taskType}
            taskDetailsByDataEntry={taskDetailsByDataEntry}
            type="task"
            handleViewWeekPlanDetails={() => setView("view_details")}
          />
        </Drawer>
      )}
      {(tabIndex === 2 || tabIndex === 3) && view === "task_details_graph" && (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={true}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <TaskDetailsDrawer
            currentWeekPlan={currentModule}
            handleCloseDrawer={handleCloseDrawer}
            taskType={taskType}
            taskDetailsByDataEntry={taskDetails}
            type="graph"
            handleDetails={() => setView(prev)}
            measurement={prev}
            metric={metric}
            openDrawer={openBpDetailsDrawer}
          />
        </Drawer>
      )}
      {tabIndex === 4 && view === "appointment_details" && (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={true}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <AppointmentDrawer
            appointmentId={appointmentId}
            appointmentData={appointmentData}
            handleCloseDrawer={handleCloseDrawer}
          />
        </Drawer>
      )}
      {(tabIndex === 2 || tabIndex === 3) && view === "learn_more" && (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={true}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <LearnMoreDrawer
            handleCloseDrawer={handleCloseDrawer}
            handleGoToWeekPlan={handleGoToWeekPlan}
            handleGoToLearnMoreDetails={handleGoToLearnMoreDetails}
            currentWeekPlan={currentWeekPlan}
            category={category}
          />
        </Drawer>
      )}
      {(tabIndex === 2 || tabIndex === 3) && view === "learn_more_details" && (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={true}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <LearnMoreDetailsDrawer
            handleCloseDrawer={handleCloseDrawer}
            handleGoToLearnMore={handleGoToLearnMore}
            currentBlog={currentBlog}
          />
        </Drawer>
      )}
      {(tabIndex === 2 || tabIndex === 3) && view === "CGM_PROGRESS" && (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={true}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <CgmProgressDrawer
            handleCloseDrawer={handleCloseDrawer}
            patientId={patientId}
            props={props}
          />
        </Drawer>
      )}
      {(tabIndex === 2 || tabIndex === 3) && view === "WEEKPLAN_PROGRESS" && (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={true}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <WeekPlanProgress
            handleCloseDrawer={handleCloseDrawer}
            handleGoToLearnMore={handleGoToLearnMore}
            currentBlog={currentBlog}
            selectedWeek={selectedWeek || activeWeek}
            weekPlans={weekPlans}
            handleWeekNavigate={handleWeekNavigate}
          />
        </Drawer>
      )}
      {(tabIndex === 2 || tabIndex === 3) && view === "BLOG" && (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={true}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <BlogDrawer
            handleCloseDrawer={handleCloseDrawer}
            handleDetails={() => setView(prev)}
            openBlog={openBlog}
          />
        </Drawer>
      )}
      {(tabIndex === 2 || tabIndex === 3) && view === "VIDEO" && (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={true}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <VideoDrawer
            handleCloseDrawer={handleCloseDrawer}
            handleDetails={() => setView(prev)}
            openBlog={openBpDetailsDrawer}
            classes={classes}
          />
        </Drawer>
      )}
      {tabIndex === 2 && view === "weight_progress" && (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={true}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <WeightProgressDrawer
            handleCloseDrawer={handleCloseDrawer}
            monthData={progress.weight}
            patientId={patientId}
            openDrawer={openBpDetailsDrawer}
          />
        </Drawer>
      )}

      {tabIndex === 2 && view === "bp_progress" && (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={true}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <BloodSugarProgressDrawer
            handleCloseDrawer={handleCloseDrawer}
            monthData={progress.bloodsugar}
            openDrawer={openBpDetailsDrawer}
            patientId={patientId}
          />
        </Drawer>
      )}
      {tabIndex === 2 && view === "food_progress" && (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={true}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <FoodProgressDrawer
            handleCloseDrawer={handleCloseDrawer}
            monthData={progress.diet}
            patientId={patientId}
            openDrawer={openBpDetailsDrawer}
          />
        </Drawer>
      )}
      {tabIndex === 2 && view === "step_progress" && (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={true}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <StepsProgressDrawer
            patientId={patientId}
            handleCloseDrawer={handleCloseDrawer}
            monthData={progress.steps}
            openDrawer={openBpDetailsDrawer}
          />
        </Drawer>
      )}
      {tabIndex === 2 && view === "diet_progress" && (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={true}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <FoodProgressDrawer
            patientId={patientId}
            handleCloseDrawer={handleCloseDrawer}
            monthData={progress.diet}
            openDrawer={openBpDetailsDrawer}
          />
        </Drawer>
      )}
      {tabIndex === 2 && view === "RBP_progress" && (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={true}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <RandomBloodSugarProgressDrawer
            patientId={patientId}
            handleCloseDrawer={handleCloseDrawer}
            monthData={progress.bloodsugarRandom}
            openDrawer={openBpDetailsDrawer}
          />
        </Drawer>
      )}
      {tabIndex === 2 && view === "PreBP_progress" && (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={true}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <PreMealBloodSugarProgressDrawer
            patientId={patientId}
            handleCloseDrawer={handleCloseDrawer}
            monthData={progress.bloodsugarPreMeal}
            openDrawer={openBpDetailsDrawer}
          />
        </Drawer>
      )}
      {tabIndex === 2 && view === "PostBP_progress" && (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={true}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <PostMealBloodSugarProgressDrawer
            patientId={patientId}
            handleCloseDrawer={handleCloseDrawer}
            monthData={progress.bloodsugarPostMeal}
            openDrawer={openBpDetailsDrawer}
          />
        </Drawer>
      )}
      {tabIndex === 2 && view === "Sleep_progress" && (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={true}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <SleepProgressDrawer
            patientId={patientId}
            handleCloseDrawer={handleCloseDrawer}
            monthData={progress.sleep}
            openDrawer={openBpDetailsDrawer}
          />
        </Drawer>
      )}
      {tabIndex === 2 && view === "Physical_progress" && (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={true}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <PhysicalProgressdrawer
            patientId={patientId}
            handleCloseDrawer={handleCloseDrawer}
            monthData={progress.physical}
            openDrawer={openBpDetailsDrawer}
          />
        </Drawer>
      )}
      {tabIndex === 2 && view === "Hb1ac_progress" && (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={true}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Hb1acProgressDrawer
            patientId={patientId}
            handleCloseDrawer={handleCloseDrawer}
            monthData={progress.hba1c}
            openDrawer={openBpDetailsDrawer}
          />
        </Drawer>
      )}
      {tabIndex === 2 && view === "bp_details" && (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={true}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <BpDetails handleCloseDrawer={handleCloseDetailsDrawer} />
        </Drawer>
      )}
      {tabIndex === 2 && view === "update_weight" && (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={true}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <UpdateWeightDrawer
            handleDetails={() => setView(prev)}
            id={patientId}
            data={taskDetails}
            handleCloseDrawer={handleCloseDetailsDrawer}
          />
        </Drawer>
      )}
      {tabIndex === 2 && view === "update_bloodsugar" && (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={true}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <UpdateBloodSugarDrawer
            handleDetails={() => setView(prev)}
            id={patientId}
            data={taskDetails}
            handleCloseDrawer={handleCloseDetailsDrawer}
          />
        </Drawer>
      )}
      {tabIndex === 2 && view === "update_diet" && (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={true}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <UpdateDietDrawer
            handleDetails={() => setView(prev)}
            id={patientId}
            data={taskDetails}
            handleCloseDrawer={handleCloseDetailsDrawer}
          />
        </Drawer>
      )}
      {tabIndex === 2 && view === "update_steps" && (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={true}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <UpdateStepsDrawer
            handleDetails={() => setView(prev)}
            id={patientId}
            data={taskDetails}
            handleCloseDrawer={handleCloseDetailsDrawer}
          />
        </Drawer>
      )}
      {tabIndex === 2 && view === "update_physical" && (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={true}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <UpdatePhysicalDrawer
            handleDetails={() => setView(prev)}
            id={patientId}
            data={taskDetails}
            handleCloseDrawer={handleCloseDetailsDrawer}
          />
        </Drawer>
      )}
      {tabIndex === 2 && view === "update_hba1c" && (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={true}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <UpdateHba1cDrawer
            handleDetails={() => setView(prev)}
            id={patientId}
            data={taskDetails}
            handleCloseDrawer={handleCloseDetailsDrawer}
          />
        </Drawer>
      )}
      {tabIndex === 2 && view === "update_sleep" && (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={true}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <UpdateSleepDrawer
            handleDetails={() => setView(prev)}
            id={patientId}
            data={taskDetails}
            handleCloseDrawer={handleCloseDetailsDrawer}
          />
        </Drawer>
      )}
      {tabIndex === 2 && view === "Add_Data" && (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={true}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <AddHealthData
            openDrawer={openBpDetailsDrawer}
            handleCloseDrawer={handleCloseDetailsDrawer}
            handleDetails={() => setView(prev)}
          />
        </Drawer>
      )}
      {tabIndex === 2 && view === "addHealthData/bloodsugar" && (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={true}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <AddBloodSugar
            openDrawer={openBpDetailsDrawer}
            handleCloseDrawer={handleCloseDetailsDrawer}
            handleDetails={() => setView(prev)}
          />
        </Drawer>
      )}
      {tabIndex === 2 && view === "addHealthData/weight" && (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={true}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <AddWeight
            openDrawer={openBpDetailsDrawer}
            handleCloseDrawer={handleCloseDetailsDrawer}
            handleDetails={() => setView(prev)}
          />
        </Drawer>
      )}
      {tabIndex === 2 && view === "addHealthData/diet" && (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={true}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <AddDiet
            openDrawer={openBpDetailsDrawer}
            handleCloseDrawer={handleCloseDetailsDrawer}
            handleDetails={() => setView(prev)}
          />
        </Drawer>
      )}
      {tabIndex === 2 && view === "addHealthData/physical" && (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={true}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <AddPhysicalActivityData
            openDrawer={openBpDetailsDrawer}
            handleCloseDrawer={handleCloseDetailsDrawer}
            handleDetails={() => setView(prev)}
          />
        </Drawer>
      )}
      {tabIndex === 2 && view === "addHealthData/sleep" && (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={true}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <AddSleep
            openDrawer={openBpDetailsDrawer}
            handleCloseDrawer={handleCloseDetailsDrawer}
            handleDetails={() => setView(prev)}
          />
        </Drawer>
      )}
      {tabIndex === 2 && view === "addHealthData/steps" && (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={true}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <AddSteps
            openDrawer={openBpDetailsDrawer}
            handleCloseDrawer={handleCloseDetailsDrawer}
            handleDetails={() => setView(prev)}
          />
        </Drawer>
      )}
      {tabIndex === 2 && view === "addHealthData/hba1c" && (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={true}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <AddHba1c
            openDrawer={openBpDetailsDrawer}
            handleCloseDrawer={handleCloseDetailsDrawer}
            handleDetails={() => setView(prev)}
          />
        </Drawer>
      )}
      <div
        className={clsx(classes.mainPanel, {
          [classes.mainPanelShift]:
            ((tabIndex === 3 || tabIndex === 2) &&
              [
                "week_plan",
                "learn_more",
                "view_details",
                "task_details",
                "learn_more_details",
                "CGM_PROGRESS",
                "WEEKPLAN_PROGRESS",
                "BLOG",
                "VIDEO",
              ].includes(view)) ||
            (tabIndex === 2 &&
              [
                "weight_progress",
                "bp_progress",
                "food_progress",
                "step_progress",
                "Sleep_progress",
                "Hb1ac_progress",
                "Physical_progress",
                "RBP_progress",
                "PreBP_progress",
                "diet_progress",
                "PostBP_progress",
                "bp_details",
                "task_details_graph",
                "update_weight",
                "update_bloodsugar",
                "update_diet",
                "update_steps",
                "update_physical",
                "update_hba1c",
                "update_sleep",
                "Add_Data",
                "addHealthData/bloodsugar",
                "addHealthData/weight",
                "addHealthData/diet",
                "addHealthData/physical",
                "addHealthData/sleep",
                "addHealthData/steps",
                "addHealthData/hba1c",
              ].includes(view)) ||
            (tabIndex === 4 && view === "appointment_details"),
        })}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={classes.headerContainer}>
              <Typography className={classes.text}>
                {patientData.firstName + " " + patientData.lastName}
              </Typography>
              <div
                style={{
                  justifyContent: "space-between",
                  width: "300px",
                  display: "flex",
                }}
              >
                <CustomButton
                  md
                  onClick={() =>
                    openBpDetailsDrawer(
                      "Add_Data",
                      "weight",
                      "update_weight",
                      ""
                    )
                  }
                >
                  Add Data
                </CustomButton>
                <CustomButton
                  md
                  onClick={() =>
                    props.history.push({
                      pathname: `/coach/create-week-plan/${patientId}`,
                      state: {
                        patientId: patientId,
                        draftedWeekPlan,
                        isNewWeekPlan: true,
                      },
                    })
                  }
                >
                  Create WeekPlan
                </CustomButton>
              </div>
            </div>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <RichTextEditor
                  note={note}
                  handleChangeNote={handleChangeNote}
                  handleSaveNote={handleOpen}
                />
              </Grid>
              <Grid item xs={8}>
                <div className={classes.container}>
                  <AppBar
                    className={classes.appBar}
                    position="static"
                    color="transparent"
                  >
                    <Tabs
                      className={classes.root}
                      value={tabIndex}
                      onChange={handleTabChange}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="scrollable"
                      scrollButtons="auto"
                    >
                      <Tab label="Patient Info" {...a11yProps(0)} />
                      <Tab label="Patient’s Health Profile" {...a11yProps(1)} />
                      <Tab label="Progress" {...a11yProps(2)} />
                      <Tab label="Week Plans" {...a11yProps(3)} />
                      <Tab label="Appointments" {...a11yProps(4)} />
                      <Tab label="Documents" {...a11yProps(5)} />
                      <Tab label="Doctor Instruction" {...a11yProps(6)} />
                    </Tabs>
                  </AppBar>

                  <TabPanel value={tabIndex} index={0}>
                    <PatientInfo patientData={patientData} />
                  </TabPanel>
                  <TabPanel value={tabIndex} index={1}>
                    <HealthProfile patientId={patientId} />
                  </TabPanel>
                  <TabPanel value={tabIndex} index={2}>
                    <Progress
                      isCGMSensorAvailable={patientData?.CGMSensorAvailable}
                      view={view}
                      handleGoToWeekPlan={handleGoToWeekPlan}
                      handleProgress={handleProgress}
                      handleOpenWeightProgressDrawer={
                        handleOpenWeightProgressDrawer
                      }
                      handleOpenBloodSugarProgressDrawer={
                        handleOpenBloodSugarProgressDrawer
                      }
                      handleOpenFoodProgressDrawer={
                        handleOpenFoodProgressDrawer
                      }
                      handleOpenStepProgressDrawer={
                        handleOpenStepProgressDrawer
                      }
                      handleOpenRandomBPSProgressDrawer={
                        handleOpenRandomBPSProgressDrawer
                      }
                      handleOpenPreBPSProgressDrawer={
                        handleOpenPreBPSProgressDrawer
                      }
                      handleOpenPostBPSProgressDrawer={
                        handleOpenPostBPSProgressDrawer
                      }
                      handleOpenSleepProgressDrawer={
                        handleOpenSleepProgressDrawer
                      }
                      handleOpenPhysicalProgressDrawer={
                        handleOpenPhysicalProgressDrawer
                      }
                      handleOpenHb1acProgressDrawer={
                        handleOpenHb1acProgressDrawer
                      }
                      handleCgmProgressDrawer={handleCgmProgressDrawer}
                      weekProgress={weekPlans.find((weekPlan) => {
                        return weekPlan.isDraft === false;
                      })}
                      progress={progress}
                    />
                  </TabPanel>
                  <TabPanel value={tabIndex} index={3}>
                    <WeekPlans
                      handleGoToWeekPlan={handleGoToWeekPlan}
                      weekPlans={weekPlans}
                      patientId={patientId}
                      currentWeekPlanId={currentWeekPlan?.id}
                      handleDeleteWeekPlan={handleDeleteWeekPlan}
                    />
                  </TabPanel>
                  <TabPanel value={tabIndex} index={4}>
                    <Appointment
                      appointmentData={appointmentData}
                      appointmentId={appointmentId}
                      handleOpenAppointmentDrawer={handleOpenAppointmentDrawer}
                    />
                  </TabPanel>
                  <TabPanel value={tabIndex} index={5}>
                    <Documents patientId={patientId} />
                  </TabPanel>
                  <TabPanel value={tabIndex} index={6}>
                    <DoctorInstruction patientId={patientId} />
                  </TabPanel>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div>
        <Modal open={modal} onClose={handleClose}>
          <Box sx={style}>
            <Typography>Are you sure?</Typography>
            <div style={{ marginTop: "10px" }}>
              <Button
                onClick={handleSaveNote}
                variant="contained"
                style={{ background: "green", color: "white" }}
              >
                Yes
              </Button>
              <Button
                onClick={handleClose}
                variant="contained"
                style={{
                  background: "red",
                  marginLeft: "10px",
                  color: "white",
                }}
              >
                No
              </Button>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    loading: state.patientReducers.loading,
    allAppointments:
      state.patientReducers.allAppointments !== null &&
      state.patientReducers.allAppointments.data,
    noteSuccess: state.patientReducers.noteSuccess,
    allWeekPlans:
      state.patientReducers.allWeekPlans !== null &&
      state.patientReducers.allWeekPlans.data,
    updateSuccess: state.patientReducers.updateSuccess,
    moduleDetailsByWeekPlan:
      state.patientReducers.moduleDetailsByWeekPlan !== null &&
      state.patientReducers.moduleDetailsByWeekPlan.data,
    taskDetailsByModule:
      state.patientReducers.taskDetailsByModule !== null &&
      state.patientReducers.taskDetailsByModule.data,
    taskDetailsByDataEntry:
      state.patientReducers.taskDetailsByDataEntry !== null &&
      state.patientReducers.taskDetailsByDataEntry.data,
    allPatients:
      state.allPatientsReducers.allPatients !== null &&
      state.allPatientsReducers.allPatients.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        GetAllAppointmentsAction,
        SaveNoteAction,
        GetAllWeekPlansAction,
        GetModuleDetailsByWeekPlanAction,
        GetTaskDetailsByModuleAction,
        GetTaskDetailsByDataEntryAction,
        DeleteWeekPlanByIdAction,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(patientStyle)(Patient));
