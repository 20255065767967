export const generatePDFContent = (deliveryList) => {
  const fetchPageContent = (delivery) => {
    return `<div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
      <div style="border: 2px solid black; padding: 20px; width: 600px;">
        <table style="width: 100%;">
          <tbody>
            <tr>
              <td>
              <p style="margin: 0; font-size: 25px;">To:</p>
              </td>
              <td style="word-break: break-word;">
                <p style="margin: 0;font-size:25px;">${
                  delivery?.patient?.firstName || ""
                } ${delivery?.patient?.lastName || ""} </p>
              </td>
            </tr>
            <tr>
              <td style="white-space: nowrap;">
                <p style="margin: 0;font-size: 25px;">Phone number:</p>
              </td>
              <td style="word-break: break-word;">
                <p style="margin: 0;font-size: 25px;">${
                  delivery?.patient?.mobileNumber
                }</p>
              </td>
            </tr>
            <tr>
              <td>
                <p style="margin: 0;font-size: 25px;">Address:</p>
              </td>
              <td style="word-break: break-word;">
                <p style="margin: 0;font-size: 25px;">${`${
                  delivery?.deliveryAddress?.[0]?.address1 || ""
                }, ${delivery?.deliveryAddress?.[0]?.landmark || ""}, ${
                  delivery?.deliveryAddress?.[0]?.pincode || ""
                }`}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div style="border: 2px solid black; padding: 20px; width: 250px;margin-top:40px">
        <div style="display: flex;">
          <div style="text-align: right; padding-right: 10px;">
            <p style="margin: 0;font-size: 25px;">From</p>
          </div>
          <div>
            <p style="margin: 0;font-size: 25px;">OneCare</p>
            <p style="margin: 0;font-size: 25px;">8310547492</p>
          </div>
        </div>
      </div>
      <div style="border: 2px solid black; padding: 10px; width: 140px;margin-top:40px;justify-content: center; align-items: center;">
      <div>
      <p>${delivery?.addOnProduct?.productName}</p>
      <p style="margin: 0;font-size: 25px;">QTY: ${delivery?.quantity}</p>
    </div>
      </div>
    </div>
    </div>`;
  };

  let pagesArray = deliveryList.map((delivery, index) => {
    const pageContent = fetchPageContent(delivery);
    return `<div class="page">${pageContent}</div>`;
  });

  const content = `
    <html>
        <style>
          .page {
            page-break-after: always;
          }
        </style>

      <body>
        <div style="padding: 10px;">
          ${pagesArray.join("")}
        </div>
      </body>
    </html>`;

  return content;
};
