//OPS ADMIN
import OpsAdminDashboard from "pages/OpsAdminDashboard";
import DeliveryDetails from "pages/OpsAdminDashboard/components/DeliveryDetails";

export const initRoutes = [];

export const opsAdminRoutes = [
  {
    path: "/deliveryDashboard",
    name: "Delivery",
    layout: "/opsAdmin",
    component: OpsAdminDashboard,
  },
  {
    path: "/deliveryDetails/:id",
    name: "PatientDetails",
    layout: "/opsAdmin",
    component: DeliveryDetails,
  },
];
