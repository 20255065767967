/* eslint-disable react-hooks/exhaustive-deps */
import { Box, TextField, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { Table } from "@components/molecules/Table";
import Loader from "@components/atoms/Loader";
import { basicPatientDetails } from "assets/jss/pages/components/basicPatientDetailStyle";

const PatientBasicDetails = ({ patientBasicDetails, loading }) => {
  const classes = basicPatientDetails();

  return (
    <>
      {loading && <Loader open={true} loaderMessage="Fetching..." />}
      <Box className={classes.boxContainer}>
        <Box className={classes.nameBox}>
          <Typography className={classes.firstNameIcon}>
            {patientBasicDetails &&
              patientBasicDetails.firstName &&
              patientBasicDetails.firstName[0]}
          </Typography>
        </Box>
        <Typography className={classes.fullNameContainer}>
          {patientBasicDetails &&
            patientBasicDetails.firstName &&
            `${patientBasicDetails.firstName} ${
              patientBasicDetails.lastName ? patientBasicDetails.lastName : ""
            }`}
        </Typography>
      </Box>
      {patientBasicDetails && (
        <>
          <div className={classes.marginBottom16}>
            <Box className={classes.box}>
              <TextField
                label={"Patient ID"}
                value={patientBasicDetails.id}
                className={classes.textField}
                InputProps={{
                  readOnly: true,
                  className: classes.defaultValue,
                }}
              />
            </Box>
            <Box className={classes.box}>
              <TextField
                label={"Gender"}
                value={
                  patientBasicDetails.gender ? patientBasicDetails.gender : "--"
                }
                className={classes.textField}
                InputProps={{
                  readOnly: true,
                  className: classes.defaultValue,
                }}
              />
            </Box>

            <Box className={classes.box}>
              <TextField
                label={"Age"}
                value={patientBasicDetails.age ? patientBasicDetails.age : "--"}
                className={classes.textField}
                InputProps={{
                  readOnly: true,
                  className: classes.defaultValue,
                }}
              />
            </Box>

            <Box className={classes.box}>
              <TextField
                label={"Registered Mobile Number"}
                value={patientBasicDetails.countryCode.concat(
                  patientBasicDetails.mobileNumber
                )}
                className={classes.textField}
                InputProps={{
                  readOnly: true,
                  className: classes.defaultValue,
                }}
              />
            </Box>

            {patientBasicDetails.clinics && patientBasicDetails.clinics[0] && (
              <Box className={classes.box}>
                <TextField
                  label={"Clinic Name"}
                  value={patientBasicDetails.clinics[0].name}
                  className={classes.textField}
                  InputProps={{
                    readOnly: true,
                    className: classes.defaultValue,
                  }}
                />
              </Box>
            )}

            {patientBasicDetails.coach && (
              <Box className={classes.box}>
                <TextField
                  label={"Coach Name"}
                  value={
                    patientBasicDetails.coach?.firstName +
                    " " +
                    patientBasicDetails.coach?.lastName
                  }
                  className={classes.textField}
                  InputProps={{
                    readOnly: true,
                    className: classes.defaultValue,
                  }}
                />
              </Box>
            )}

            <Box className={classes.box}>
              <TextField
                label={"Doctor Name"}
                value={
                  patientBasicDetails.doctor?.firstName
                    ? "Dr. " +
                      patientBasicDetails.doctor?.firstName +
                      " " +
                      (patientBasicDetails.doctor?.lastName || "")
                    : "N/A"
                }
                className={classes.textField}
                InputProps={{
                  readOnly: true,
                  className: classes.defaultValue,
                }}
              />
            </Box>
            {patientBasicDetails?.programHistory.length > 0 && (
              <Box className={classes.box}>
                <p className={classes.labelHeading}>Program History</p>
                <div className={classes.tableContainer}>
                  <Table
                    tableData={patientBasicDetails?.programHistory}
                    classes={classes}
                  />
                </div>
              </Box>
            )}

            {patientBasicDetails?.addOnProductsHistory.length > 0 && (
              <Box className={classes.box}>
                <p className={classes.labelHeading}>Addon History</p>
                <div className={classes.tableContainer}>
                  <Table
                    tableData={patientBasicDetails?.addOnProductsHistory}
                    classes={classes}
                  />
                </div>
              </Box>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default connect()(PatientBasicDetails);
