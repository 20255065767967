/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Box, TextField, Typography, makeStyles } from "@material-ui/core";
import Loader from "@components/atoms/Loader";
import Button from "@components/atoms/Button";
import moment from "moment";

const useStyles = makeStyles(() => ({
  title: {
    fontSize: "24px",
    fontWeight: "500",
    marginBottom: "24px",
    marginLeft: "16px",
  },
  box: {
    backgroundColor: "#FFFFFF",
    width: "auto",
    border: "1px solid #D9D9D9",
  },
  textField: {
    margin: "16px",
    width: "300px",
  },
  defaultValue: {
    fontSize: "16px",
    fontWeight: "500",
  },
  label: {
    color: "#000",
  },
  done: {
    color: "#389E0D",
  },
  n_done: {
    color: "#FF5522",
  },
}));

const ConsultationType = [
  {
    value: "firstconsultation",
    label: "First Consultation",
  },
  {
    label: "Scheduled Follow Up",
    value: "scheduledfollowup",
  },
  {
    label: "Emergency Follow Up",
    value: "emergencyfollowup",
  },
];
const ViewAppointment = (props) => {
  const { history, loading, loadingPatient } = props;
  const classes = useStyles();

  const appointment =
    props.history.location.state && props.history.location.state.data;

  return (
    <>
      {(loading || loadingPatient) && (
        <Loader open={true} loaderMessage="Fetching..." />
      )}
      <Typography className={classes.title}>Appointment</Typography>
      <div style={{ marginBottom: "16px" }}>
        {appointment && (
          <>
            <Box className={classes.box}>
              <TextField
                label={"Appointment with"}
                defaultValue={
                  appointment?.doctor
                    ? `Dr. ${appointment?.doctor?.firstName} ${
                        appointment?.doctor?.lastName || ""
                      } [Doctor]`
                    : `${appointment?.coach?.firstName} ${
                        appointment?.coach?.lastName || ""
                      } [Coach]`
                }
                className={classes.textField}
                InputProps={{
                  readOnly: true,
                  className: [classes.defaultValue],
                }}
                InputLabelProps={{
                  color: "#000",
                }}
              />
            </Box>

            <Box className={classes.box}>
              <TextField
                label={"Appointment date"}
                defaultValue={moment(appointment.appointmentDate).format(
                  "ddd, DD MMMM"
                )}
                className={classes.textField}
                InputProps={{
                  readOnly: true,
                  className: [classes.defaultValue],
                }}
                InputLabelProps={{
                  color: "#000",
                }}
              />
            </Box>

            <Box className={classes.box}>
              <TextField
                label={"Appointment time"}
                defaultValue={moment(appointment.appointmentDate).format(
                  "hh:mm A"
                )}
                className={classes.textField}
                InputProps={{
                  readOnly: true,
                  className: [classes.defaultValue],
                }}
                InputLabelProps={{
                  color: "#000",
                }}
              />
            </Box>

            <Box className={classes.box}>
              <TextField
                label={"Appointment type"}
                defaultValue={
                  appointment.appointmentType === "online" ? "Online" : "Clinic"
                }
                className={classes.textField}
                InputProps={{
                  readOnly: true,
                  className: [classes.defaultValue],
                }}
                InputLabelProps={{
                  color: "#000",
                }}
              />
            </Box>

            {/* consultationType */}
            <Box className={classes.box}>
              <TextField
                label={"Consultation type"}
                defaultValue={
                  ConsultationType.find(
                    ({ value }) => value === appointment.consultationType
                  )?.label
                }
                className={classes.textField}
                InputProps={{
                  readOnly: true,
                  className: [classes.defaultValue],
                }}
                InputLabelProps={{
                  color: "#000",
                }}
              />
            </Box>

            <Box className={classes.box}>
              <TextField
                label={"Appointment reason"}
                defaultValue={appointment.purpose}
                className={classes.textField}
                InputProps={{
                  readOnly: true,
                  className: [classes.defaultValue],
                }}
                InputLabelProps={{
                  color: "#000",
                }}
              />
            </Box>

            <Box className={classes.box}>
              <TextField
                label={"Appointment status"}
                defaultValue={
                  appointment.appointmentStatus === "booked"
                    ? "Confirmed"
                    : "Pending"
                }
                className={classes.textField}
                InputProps={{
                  readOnly: true,
                  className: [
                    classes.defaultValue,
                    appointment.appointmentStatus === "booked"
                      ? classes.done
                      : appointment.appointmentStatus === "reviewed"
                      ? classes.n_done
                      : "",
                  ],
                }}
                InputLabelProps={{
                  color: "#000",
                }}
              />
            </Box>
          </>
        )}
      </div>

      <Button
        onClick={() => {
          history.push({
            pathname: `/counselor/edit_appointment/${appointment.id}`,
            state: { data: appointment },
          });
        }}
        inverse
        fullWidth
      >
        Edit Appointment Details
      </Button>
    </>
  );
};

export default ViewAppointment;
