import { Typography } from "@material-ui/core";
import React from "react";

export const SelectedActivityCard = ({ category, title, nonEditable }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        alignItems: "start",
        border: "1px solid #ccc",
        padding: "16px",
        backgroundColor: "white",
        width: "564px",
        marginTop: ".5rem",
      }}
    >
      <Typography
        style={{
          fontSize: "12px",
          color: "#8C8C8C",
        }}
      >
        {category}
      </Typography>

      <Typography
        style={{
          fontSize: "14px",
          fontWeight: 700,
          width: "500px",
          marginTop: "12px",
          color: nonEditable ? "#C6C6C6" : "",
        }}
      >
        {title}
      </Typography>
    </div>
  );
};
