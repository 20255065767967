import adminTabStyle from "assets/jss/pages/adminTabStyles";
import TabPanel from "pages/CreateReport/components/TabPanel";
import { useState } from "react";
import { connect } from "react-redux";
import { adminTabIndexes } from "./constants";
import DiscountUpdate from "./DiscountUpdate";
import SenosorDeactivation from "./SenosorDeactivation";
const { Tabs, Tab } = require("@material-ui/core");

const AdminTabs = (props) => {
  const classes = adminTabStyle();
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, tabNumber) => {
    setTabValue(tabNumber);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  return (
    <>
      <div className={classes.tabContainer}>
        <Tabs
          className={classes.root}
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab
            label="Sensor Deactivation"
            {...a11yProps(adminTabIndexes.sensorDeactivation)}
          />
          <Tab
            label="Discount Update"
            {...a11yProps(adminTabIndexes.discountUpdate)}
          />
        </Tabs>
        <TabPanel value={tabValue} index={adminTabIndexes.sensorDeactivation}>
          <SenosorDeactivation />
        </TabPanel>
        <TabPanel value={tabValue} index={adminTabIndexes.discountUpdate}>
          <DiscountUpdate />
        </TabPanel>
      </div>
    </>
  );
};

export default connect()(AdminTabs);
