const appointmentDrawerStyle = theme => ({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "64px",
    borderBottom: "1px solid #ccc",
    padding: "0px 16px",
    position: "sticky",
    zIndex: 1000,
    top: 0,
    backgroundColor: "white"
  },
  subHeaderContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "60px",
    borderBottom: "1px solid #ccc",
    padding: "0px 16px"
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    borderBottom: "1px solid #ccc",
    padding: "16px"
  },
  headerText: {
    fontSize: "24px",
    fontWeight: 500
  },
  text: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#8C8C8C"
  },
  sub_text: {
    fontSize: "16px",
    fontWeight: 500
  },
  sub_text1: {
    fontSize: "16px",
    fontWeight: 700,
    textTransform: "capitalize",
    color: "#389E0D"
  },
  icon: {
    cursor: "pointer"
  },
})

export default appointmentDrawerStyle;