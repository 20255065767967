import DashboardIcon from "@material-ui/icons/Dashboard";
import AdminTabs from "pages/AdminTabs";

export const initRoutes = [{}];

export const adminRoutes = [
  {
    path: "/sensorDeactivation",
    name: "Sensor Deactivation",
    icon: DashboardIcon,
    layout: "/admin",
    component: AdminTabs,
  },
];
