import React from "react";
import { ChevronRight } from "@material-ui/icons";
import { Divider, Typography } from "@material-ui/core";

import Card from "@components/atoms/Card";

const Cards = ({
  data,
  setExerciseDrawer,
  activitiesAdded,
  onAdd,
  onRemove,
  activities,
  isNewWeekPlan,
  isDuplicateWeekPlan,
}) => {
  if (activities === "excercise") {
    //By editing all fields in exercise data then new exercise will be created. so to show new exercise we are merging
    let exercises = new Set(data.map((uniqueId) => uniqueId.videoURL));
    data = [
      ...data,
      ...activitiesAdded.filter(
        (uniqueId) => !exercises.has(uniqueId.videoURL)
      ),
    ].map(({ ...rest }) => ({
      ...rest,
      title: rest.videoName,
      isActive: true,
    }));
  }

  return (
    <>
      {data.map((activity) => {
        const disable =
          !isDuplicateWeekPlan &&
          activitiesAdded?.some(({ nonEditable, activityId }) => {
            if (+activityId === +activity.activityId && nonEditable)
              return nonEditable;
          });
        return (
          <div style={{ padding: "0px 16px" }} key={activity.id}>
            <Card
              style={{
                marginTop: "1rem",
                borderRadius: "4px",
                border: "1px solid #D9D9D9",
                padding: "0px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                  background: disable ? "lightgrey" : "",
                }}
                onClick={disable ? null : () => setExerciseDrawer(activity)}
              >
                <div style={{ padding: "16px" }}>
                  <Typography
                    style={{
                      fontWeight: "400",
                      fontFamily: "Roboto",
                      color: "#8C8C8C",
                      fontSize: "12px",
                      lineHeight: "16px",
                    }}
                  >
                    {activity.category}
                  </Typography>
                  <Typography
                    style={{
                      paddingTop: "12px",
                      fontWeight: "700",
                      letterSpacing: "-0.24px",
                      fontFamily: "Roboto",
                      color: "#262626",
                      fontSize: "14px",
                      lineHeight: "19px",
                      maxWidth: "25rem",
                    }}
                  >
                    {activity.title}
                  </Typography>
                </div>
                <ChevronRight style={{ color: "#2B377E" }} />
              </div>
              <Divider />
              <div
                style={{
                  display: "flex",
                  padding: "12px",
                  background: disable ? "lightgrey" : "",
                }}
              >
                {activitiesAdded?.some(
                  ({ activityId, strapiBlogId, videoURL, isActive }) => {
                    if (isActive === false) {
                      return false;
                    }

                    return activities === "excercise"
                      ? videoURL === activity.videoURL //for exercises new,edit and duplicate week plan
                      : activities === "learn"
                      ? isNewWeekPlan
                        ? Number(activityId) === Number(activity.id) //for new weekplan
                        : strapiBlogId
                        ? Number(strapiBlogId) === Number(activity.id) //for edit weekplan - pre-existing data
                        : Number(activityId) === Number(activity.id) //for edit weekplan - adding new data
                      : Number(activityId) === Number(activity.activityId); //for start,keep,stop new,edit and duplicate week plan
                  }
                ) ? (
                  <>
                    <Typography
                      style={{
                        fontSize: "14px",
                        color: "#389E0D",
                        cursor: "pointer",
                      }}
                    >
                      Added
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "14px",
                        color: disable ? "#C6C6C6" : "#FF5522",
                        cursor: "pointer",
                        fontWeight: 700,
                        marginLeft: "0.5rem",
                      }}
                      onClick={disable ? null : () => onRemove?.(activity)}
                    >
                      Remove
                    </Typography>
                  </>
                ) : (
                  <Typography
                    style={{
                      fontSize: "14px",
                      color: "#389E0D",
                      fontWeight: 700,
                      cursor: "pointer",
                    }}
                    onClick={() => onAdd?.(activity)}
                  >
                    ADD
                  </Typography>
                )}
              </div>
            </Card>
          </div>
        );
      })}
    </>
  );
};

export default Cards;
