import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  isActive: {
    fill: "#23AFD7",
  },
});

function NextChevron(props) {
  const classes = useStyles(props);

  return (
    <svg width="1em" height="1em" viewBox="0 0 28 28" fill="none" {...props}>
      <path
        d="M14 28c7.732 0 14-6.268 14-14S21.732 0 14 0 0 6.268 0 14s6.268 14 14 14zM11.623 8.3a1.076 1.076 0 111.523-1.523l6.461 6.462a1.077 1.077 0 010 1.522l-6.461 6.462a1.077 1.077 0 01-1.523-1.523l5.7-5.7-5.7-5.7z"
        fill="#B3B7B8"
        className={props.isActive ? classes.isActive : ""}
      />
    </svg>
  );
}

const MemoNextChevron = React.memo(NextChevron);
export default MemoNextChevron;
