import React from "react";

import "./Table.css";
import { Pagination } from "@mui/material";

const Table = ({
  columns,
  data,
  handleViewDetails,
  onPagination,
  totalPages,
  selectedPage,
}) => {
  return (
    <div className="data-table-container">
      <table className="data-table">
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th key={index}>{column.title}</th>
            ))}
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.id}>
              {columns.map((column, index) => (
                <td key={index}>
                  {column.render ? column.render(item) : item[column.field]}
                </td>
              ))}
              <td
                onClick={() => handleViewDetails(item.id, item)}
                className="action"
              >
                View Details
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {!!totalPages && (
        <Pagination
          count={totalPages}
          className="table-pagination"
          page={selectedPage}
          onChange={onPagination}
          size="large"
        />
      )}
    </div>
  );
};

export default Table;
