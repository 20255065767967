/**
 @desc
 * Centralized unique action types for Login actions and reducers.
 */

import {
  actionCreator,
  API_URL,
  checkHttpStatus,
  createRequestActionTypes,
  jsonApiHeader,
  showSuccessMessage,
  showErrorMessage,
} from "../../../utils/utility";

export {
  jsonApiHeader,
  actionCreator,
  checkHttpStatus,
  showSuccessMessage,
  showErrorMessage,
};

export const GET_ALL_CLINICS = `${API_URL}/v1/clinics?skip=0&sortBy=createdAt:desc`;
export const POST_ADD_PATIENT = `${API_URL}/v1/patients/create`;
export const UPDATE_PATIENT = `${API_URL}/v1/patients`;
export const POST_UPLOAD_CLINICAL_DATA = `${API_URL}/v1/patients/upload/clinical`;
export const POST_UPLOAD_GOAL_DATA = `${API_URL}/v1/patients/upload/goalData`;
export const POST_UPLOAD_COACH_ASSESSMENT_DATA = `${API_URL}/v1/patients/upload/assessment`;
export const GET_DOCTOR_COACH_BY_CLINIC = `${API_URL}/v1/clinics/users`;
export const GET_ADDON_AND_OFFERS = `${API_URL}/v1/patients/products/offers`;

export const AddPatientActionTypes = {
  get_AllClinics: createRequestActionTypes("GET_ALL_CLINICS"),
  post_AddPatient: createRequestActionTypes("POST_ADD_PATIENT"),
  update_Patient: createRequestActionTypes("UPDATE_PATIENT"),
  post_UploadClinicalData: createRequestActionTypes(
    "POST_UPLOAD_CLINICAL_DATA"
  ),
  post_UploadGoalData: createRequestActionTypes("POST_UPLOAD_GOAL_DATA"),
  post_UploadCoachAssessmentData: createRequestActionTypes(
    "POST_UPLOAD_COACH_ASSESSMENT_DATA"
  ),
  get_DoctorCoachByClinic: createRequestActionTypes(
    "GET_DOCTOR_COACH_BY_CLINIC"
  ),
  get_AddOnAndOffers: createRequestActionTypes("GET_ADDON_AND_OFFERS"),
};

export const programMetaDetaKeys = {
  startHbA1c: "Start HBA1C",
  targetHbA1c: "Target HBA1C",
};

export const addressErrors = {
  invalidPincode: "Add a valid Pincode",
  pincodeIsReq: "Pincode is required",
  addressIsReq: "Address is required",
  cityIsReq: "City is required",
  stateIsReq: "State is required",
};

export const postalPincodeApiKeys = {
  postOffice: "PostOffice",
  district: "District",
  state: "State",
};

export const patientFormFields = {
  city: "city",
  state: "state",
  landmark: "landmark",
  address: "address",
  pincode: "pincode",
  hba1cReading: "hba1cReading",
  tsh: "tsh",
  paymentReferenceNumber: "paymentReferenceNumber",
  deliveryType: "Delivery type",
  deliveryMode: "deliveryMode",
  eta: "ETA",
};

export const assureProgramId = 7;

export const maxPincodeLength = 6;

export const alphaNumbericWithSpaceRegex = /^[a-zA-Z0-9 ]+$/;
export const minNameStringLength = 2;
export const minLastNameStringLength = 1;

export const diagnosisLabels = Object.freeze({
  diabetes: "Diabetes",
  thyroid: "Thyroid",
  typeOneDiabetes: "Type 1 Diabetes",
  typeTwoDiabetes: "Type 2 Diabetes",
});

export const addPatientForms = {
  createPatientOrder: "createPatientOrder",
};

export const etaTableHeading = [
  "Delivery Mode",
  "Pincode",
  "Expected Delivery",
];

export const DELIVERY_MODES = {
  clinic: "Clinic",
  standard: "Standard",
  priority: "Priority",
};

export const customDiscountOptionValue = "discountValue";
export const customDisctounOptiomLabel = "Custom discount";
