import { API_METHODS } from "network/constants";
import { apiResponses } from "network/constants";
import { END_POINT } from "network/constants";
import api from "utils/api";

export const fetchPatientDynamicLink = async (patientId) => {
  try {
    const { data } = await api(END_POINT.getPatientDynamicLink(patientId), {
      method: API_METHODS.GET,
    });
    if (data?.data?.shortLink && data?.status === apiResponses.success)
      return data.data.shortLink;
    else {
      return "";
    }
  } catch (e) {
    console.error("Failed to fetch patient dynamic link", e);
  }
};
