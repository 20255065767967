import { API_URL } from "../utils/constants";
const defaultCountryCode = "91";
export const END_POINT = {
  uploadFile: `${API_URL}/v1/patients/uploadS3FileByUser`,
  getReports: (id, reportType) => {
    return reportType
      ? `${API_URL}/v1/patients/reports/${id}?reportType=${reportType}`
      : `${API_URL}/v1/patients/reports/${id}`;
  },
  getPatientAllReports: (id) =>
    `${API_URL}/v1/coach/report/getReportList/${id}`,
  postReports: (id) => `${API_URL}/v1/patients/upload/report/${id}`,
  uploadReport: () => `${API_URL}/v1/coach/report/upload`,
  getCgmData: (id) => `${API_URL}/v1/patients/glucoseData/${id}`,
  getMetricSummary: (id, date) =>
    `${API_URL}/v1/CGM/CGMSessionMetrics/${id}?date=${date}`,
  postSelectedAddons: (id) => `${API_URL}/v2/patients/createPatientOrder/${id}`,
  getPatientDynamicLink: (patientId) => `/v1/patients/dynamicLink/${patientId}`,
  getActivityList: (patientId, date) =>
    `/v1/health/getActivityList/${patientId}?date=${date}&prioritySorted=true&timeSorted=true`,
  getAddonAndProgramHistory: (id, addOnProducts) =>
    `${API_URL}/v1/patients/patientOrdersHistory/${id}?addOnProductsHistory=${addOnProducts}`,
  saveReportData: `${API_URL}/v1/coach/report/save`,
  getSensorSummaryGraphByGraphType: (id, reportType) =>
    `v1/CGM/sensorSummaryGraphForCoach/${id}?graphType=${reportType}`,
  getSensorSummaryGraph: (id) => `v1/CGM/sensorSummaryForCoach/${id}`,
  getAllAssesments: `/v1/coach/reportContent`,
  getPatientSensorList: (patientId) => `v1/CGM/sensorList/${patientId}`,
  getReportSubTypeOptions: (tableType, subType, status, dayPart) =>
    `/v1/coach/reportContent?type=${tableType}&subType=${subType}&status=${status}&dayPart=${dayPart}`,
  getPatientProgress: (reportId) =>
    `/v1/coach/report/getPatientProgress/${reportId}`,
  creatSensorReport: `/v2/coach/createReport`,
  getReportSummary: (reportId) => `/v1/coach/report/getSummary/${reportId}`,
  getDailyPatterData: (reportId) =>
    `/v1/coach/report/getDailyPattern/${reportId}`,
  getPatientInfo: (reportId) => `/v1/coach/report/getPatientInfo/${reportId}`,
  getPatientBasicDetails: (patientId) =>
    `/v1/admin/patientDetails/${patientId}`,
  deactivateSensor: (patientId) => `/v1/admin/deactivateCGMSensor/${patientId}`,
  getOverallSummary: (id) => `v1/health/getOverallSummaryForCoach/${id}`,
  getSensorSummaryGraphForCoach: (id) =>
    `v1/CGM/sensorSummaryGraphForCoach/${id}`,
  getSensorSummaryForCoach: (id) => `v1/CGM/sensorSummaryForCoach/${id}`,
  getSensorList: (id) => `v1/CGM/sensorList/${id}`,
  addDiscount: `/v1/admin/discount`,
  removeDiscount: (id) => `/v1/admin/discount/${id}`,
  fetchDiscounts: `/v1/admin/discount`,
  getPatientData: (searchBy, searchValue, countryCode = defaultCountryCode) =>
    `/v1/patients/getPatientDetails?searchBy=${searchBy}&searchValue=${searchValue}&countryCode=+${countryCode}`,
  getStateAndCityByPincode: (pincode) =>
    `https://api.postalpincode.in/pincode/${pincode}`,
  getClinicUsers: (clinicId) => `/v1/clinics/users/${clinicId}`,
  createPatient: `/v2/patients/create`,
  getPatientHealthProfile: (patientId) =>
    `/v1/patients/patientHealthProfile/${patientId}`,
  updatePatientDetails: (patientId) => `/v2/patients/update/${patientId}`,
  addPatientAddOnProduct: (patientId) =>
    `/v1/admin/patient/${patientId}/addPatientAddOnProduct`,
  getFinalAmount: `/v1/patients/invoiceTotalAmount`,
  getDiagnosisList: "/v1/patients/diagnosis",
  getDeliveryDetailsById: (deliveryId) => `v1/deliveries/${deliveryId}`,
  getDropDownOptions: (updateDeliveryDetails) =>
    `/v1/users/dropDownOptions?formName=${updateDeliveryDetails}`,
  updateDeliveryDetails: (deliveryId) => `/v1/deliveries/${deliveryId}`,
  getDeliveries: (skip, limit, deliveryStatus, deliveryMode, mobileNumber) => {
    const queryParams = [`skip=${skip * limit}`, `limit=${limit}`];

    if (deliveryMode) {
      queryParams.push(`deliveryMode=${deliveryMode}`);
    }

    if (mobileNumber) {
      queryParams.push(`mobileNumber=${mobileNumber}`);
    }

    if (deliveryStatus) {
      queryParams.push(`deliveryStatus=${deliveryStatus}`);
    }

    const queryString = queryParams.join("&");

    return `/v1/deliveries?${queryString}`;
  },
  getDeliveriesByMobileNumber: (mobileNumber) =>
    `/v1/deliveries?mobileNumber=${mobileNumber}`,
  checkDeliveryETA: (pincode, deliveryModeId) =>
    `/v1/deliveries/checkETA?pincode=${pincode}&deliveryModeId=${deliveryModeId}`,
  getDeliveryModes: (createPatientOrder) =>
    `/v1/users/dropDownOptions?formName=${createPatientOrder}`,
  getProgramBucketDetails: `v1/patients/getProgramDetails`,
  getDietEssentialOptions: (optionType) =>
    `v1/users/dropDownOptions?formName=${optionType}`,
};

export const API_METHODS = {
  GET: "get",
  POST: "post",
  DELETE: "delete",
  PUT: "put",
  PATCH: "patch",
};

export const apiResponses = {
  success: "SUCCESS",
  failure: "FAILURE",
  expired: "EXPIRED",
  request: "REQUEST",
};

export const apiStatusCode = {
  200: 200,
};
