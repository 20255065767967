import { dailyPatternTypes } from "pages/CreateReport/constants";
import { metasenseTableHeading } from "../constant";
import { useStyles } from "../style";
import { Table } from "../table";

const DailyPattenTable = ({ tableType, data, tableTitle }) => {
  const classes = useStyles();

  return (
    <div className={classes.dailyPattenTableContainer}>
      {data[tableType]?.data.length > 0 && (
        <div className={classes.blockStyle}>
          <Table
            data={data[tableType]?.data}
            headingDetails={metasenseTableHeading}
            isMultipleHeading={true}
            tableType={dailyPatternTypes[tableType]}
            tableTitle={tableTitle}
          />
        </div>
      )}
    </div>
  );
};

export default DailyPattenTable;
