import { Box, Typography } from "@material-ui/core";
import React from "react";
import { useStyles } from "../styles";

export const ValueIndicator = ({ style, value, metric, color }) => {
  const classes = useStyles();

  return (
    <Box style={style} className={classes.valueIndicatorContainer}>
      <Typography
        className={classes.valueIndicator}
        style={{ color }}
      >{`${value} ${metric}`}</Typography>
      <Box
        style={{
          width: 1,
          height: 13,
          backgroundColor: color,
        }}
      />
    </Box>
  );
};
