/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { bindActionCreators } from "redux";
import { Close } from "@material-ui/icons";
import { Typography, withStyles } from "@material-ui/core";

import Loader from "@components/atoms/Loader";
import Table from "@components/organisms/Table";
import MyAppBar from "@components/organisms/AppBar";

import {
  GetAllPatientsAction,
  setSelectedPatientAction,
} from "@pages/AllPatients/actions/AllPatientsActions";
import allPatientsStyle from "@assets/jss/pages/allPatientsStyle";
import { Search } from "@assets/icons";
import lang from "@assets/languages";
import { userRoles } from "@utils/constants";
import AllPatientTable from "./components/allPatientTable";
import { containsOnlyNumbers } from "@utils/utility";

function getNumberWithOrdinal(n) {
  var s = ["th", "st", "nd", "rd"],
    v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

const columns = [
  {
    title: "Patient Name",
    field: "name",
    width: "22%",
    filterPlaceholder: "Search",
    render: (patientData) =>
      `${patientData.firstName} ${patientData.lastName || ""}`,
  },
  {
    title: "Mobile Number",
    field: "mobileNumber",
    width: "10%",
    filterPlaceholder: "Search",
    render: (patientData) =>
      patientData.countryCode.concat(patientData.mobileNumber),
  },
  {
    title: "Doctor Name",
    field: "firstName",
    width: "22%",
    filterPlaceholder: "Search",
    render: (patientData) =>
      patientData.doctor
        ? `Dr. ${patientData.doctor.firstName} ${
            patientData.doctor.lastName || ""
          }`
        : "N/A",
  },
  {
    title: "WeekPlan",
    // field: "email",
    width: "22%",
    filterPlaceholder: "Search",
    render: (patientData) => {
      if (!patientData.weekplans) {
        return "N/A";
      }

      const weekDate =
        patientData.weekplans.findIndex(
          (weekPlan) =>
            weekPlan.isDraft === false &&
            moment().isBetween(
              moment(weekPlan.from),
              moment(weekPlan.to),
              "days",
              "[]"
            )
        ) + 1;
      if (weekDate) {
        return `${getNumberWithOrdinal(weekDate)} week`;
      }
    },
  },
  {
    title: "Phase",
    field: "phase",
    width: "15%",
    filterPlaceholder: "Search",
    render: (patientData) => {
      const _activeWeek = patientData?.weekplans?.find(
        (week) =>
          week.isDraft === false &&
          moment().isBetween(moment(week.from), moment(week.to), "days", "[]")
      );
      if (_activeWeek) {
        return _activeWeek.phase;
      }
      return "N/A";
    },
  },
  {
    title: "Subscription Validity",
    field: "subscriptionValidity",
    width: "22%",
    filterPlaceholder: "Search",
    render: (rowData) =>
      rowData.subscriptionValidity
        ? moment(rowData.subscriptionValidity).format("Do MMM YYYY")
        : "N/A",
  },
  {
    title: "Program Type",
    field: "programType",
    width: "15%",
    filterPlaceholder: "Search",
    render: (patientData) => patientData?.programType || "N/A",
  },
  {
    title: lang.metasense,
    field: lang.metasense.toLowerCase(),
    width: "15%",
    filterPlaceholder: "Search",
    render: (patientData) => {
      return patientData.CGMSensorAvailable ? "Yes" : "No";
    },
  },
];

const pageLimit = 10;

const AllPatients = (props) => {
  const {
    classes,
    allPatients,
    loading,
    GetAllPatientsAction,
    totalCount,
    setSelectedPatientAction,
    role,
  } = props;

  const searchText = useRef("");
  const nextOffset = useRef(0);
  const selectedPage = useRef(1);
  const searchBy = useRef("name");
  const searchInputRef = useRef();

  const [data, setData] = useState([]);

  const totalPages = totalCount ? Math.ceil(totalCount / pageLimit) : 0;

  const searchDebounce = useRef(0);

  const { push } = useHistory();

  const isFitnessOrWellnessCoach =
    role === userRoles.fitnessCoach ||
    role === userRoles.emotionalWellnessCoach;

  useEffect(() => {
    if (isFitnessOrWellnessCoach) {
      return;
    }

    if (allPatients) {
      setData(allPatients);
    }
  }, [allPatients]);

  const onSearchUpdate = () => {
    setData(() =>
      searchText.current
        ? allPatients?.filter((item) =>
            columns
              .map(({ field }) => field)
              .some((column) =>
                item[column]
                  ?.toLowerCase()
                  ?.includes(searchText.current.toLowerCase())
              )
          ) || []
        : allPatients || []
    );
  };

  const fetchAllPatient = () => {
    GetAllPatientsAction({
      offset: nextOffset.current,
      limit: pageLimit,
      search: searchText.current,
      role,
      searchBy: searchBy.current,
    });
  };

  useEffect(() => {
    fetchAllPatient();
  }, []);

  const handleViewDetails = (id, rowData) => {
    setSelectedPatientAction(rowData);

    push({
      pathname: `/coach/patients/${id}`,
      state: { data: rowData },
    });
  };

  const onSearch = (event) => {
    searchText.current = event.target.value;

    if (containsOnlyNumbers(event.target.value)) {
      searchBy.current = "mobileNumber";
    } else {
      searchBy.current = "name";
    }

    if (!isFitnessOrWellnessCoach) {
      onSearchUpdate();

      return;
    }

    if (searchDebounce.current) {
      clearInterval(searchDebounce.current);
    }

    searchDebounce.current = setTimeout(() => {
      nextOffset.current = 0;
      selectedPage.current = 1;

      fetchAllPatient();
    }, 300);
  };

  const onSearchCancel = () => {
    searchText.current = "";
    searchInputRef.current.value = "";

    if (!isFitnessOrWellnessCoach) {
      onSearchUpdate();

      return;
    }

    nextOffset.current = 0;
    selectedPage.current = 1;

    fetchAllPatient();
  };

  const onPagination = (event, newPage) => {
    if (selectedPage.current === newPage) {
      return;
    }

    selectedPage.current = newPage;
    nextOffset.current = (newPage - 1) * pageLimit;

    fetchAllPatient();
  };

  return (
    <>
      {loading && <Loader open={true} loaderMessage="Fetching..." />}
      <MyAppBar />
      <div className={classes.root}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: "24px",
              fontFamily: "Roboto",
              fontWeight: 700,
              margin: "20px 0",
            }}
          >
            All Patients
          </div>
          <div style={{ width: "250px", position: "relative" }}>
            <input
              ref={searchInputRef}
              placeholder="Search"
              onChange={onSearch}
              style={{
                width: "calc(100% - 25px)",
                padding: "12px",
                borderRadius: "4px",
                height: "min-content",
                border: "1px solid #D9D9D9",
              }}
            />
            {searchText.current ? (
              <div
                style={{
                  top: "10px",
                  right: "5px",
                  color: "#D9D9D9",
                  cursor: "pointer",
                  position: "absolute",
                }}
                onClick={onSearchCancel}
              >
                <Close />
              </div>
            ) : (
              <img
                style={{ position: "absolute", right: 10, top: 15 }}
                src={Search}
              />
            )}
          </div>
        </div>
        {allPatients && allPatients.length ? (
          isFitnessOrWellnessCoach ? (
            <Table
              columns={columns}
              data={allPatients}
              handleViewDetails={handleViewDetails}
              totalPages={totalPages}
              selectedPage={selectedPage.current}
              onPagination={onPagination}
            />
          ) : (
            <AllPatientTable
              columns={columns}
              data={data}
              handleViewDetails={handleViewDetails}
              search={searchText.current}
            />
          )
        ) : (
          <Typography style={{ fontSize: "16px", fontWeight: 500 }}>
            No Data Found
          </Typography>
        )}
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    loading: state.allPatientsReducers.loading,
    allPatients: state.allPatientsReducers?.allPatients?.data,
    totalCount: state.allPatientsReducers?.allPatients?.count,
    role:
      state.loginReducer.loggedInUserDetails !== null &&
      state.loginReducer.loggedInUserDetails.data.role,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        GetAllPatientsAction,
        setSelectedPatientAction,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(allPatientsStyle)(AllPatients));
