import { Box } from "@material-ui/core";
import { getScoreMetricesConfig } from "./contants";
import { ScoreMetric } from "./scoreMetric";

export const ScoreMetrics = ({ aiqr, gvp }) => {
  const metricData = getScoreMetricesConfig(parseInt(aiqr), parseInt(gvp));

  return (
    <Box>
      {metricData.map((scoreData) => (
        <ScoreMetric {...scoreData} />
      ))}
    </Box>
  );
};
