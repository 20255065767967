import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  tableHeadingTitle: {
    fontSize: "16px",
    fontWeight: 700,
    margin: "24px 0",
  },
  checkboxHeading: {
    fontSize: "16px",
    fontWeight: 700,
    margin: "24px 0",
  },
  container: {
    margin: "0 0 24px 0",
    width: "800px",
  },
  table: {
    border: "1px solid #D9D9D9",
    borderSpacing: "0",
    borderRight: "0px",
    borderBottom: "0px",
    textAlign: "center",
    tableLayout: "fixed",
    width: "100%",
    overflow: "scroll",
    backgroundColor: "white",
  },
  tableHeading: {
    borderBottom: "1px solid #D9D9D9",
    paddingLeft: "12px",
    paddingTop: "9px",
    paddingBottom: "9px",
    borderRight: "1px solid #D9D9D9",
    textAlign: "left",
  },
  tableRow: {
    borderBottom: "1px solid #D9D9D9",
    borderRight: "1px solid #D9D9D9",
    whiteSpace: "normal",
    height: "40px",
    justifyContent: "left",
    paddingLeft: "10px",
  },
  tableContainer: {
    maxHeight: "auto",
    width: "100%",
    marginTop: "20px",
  },
  tableCell: {
    overflowWrap: "break-word",
    padding: "0.2rem",
  },
  rowOnly: {
    backgroundColor: "white",
    paddingLeft: "12px",
    paddingTop: "9px",
    paddingBottom: "9px",
    fontWeight: 700,
    borderTop: "1px solid #D9D9D9",
    borderRight: "1px solid #D9D9D9",
    borderLeft: "1px solid #D9D9D9",
  },
  nonEditableRow: {
    paddingLeft: "12px",
    textAlign: "left",
    color: "#262626",
    fontWeight: 400,
    fontSize: "14px",
  },
  wrapText: {
    whiteSpace: "normal",
    wordWrap: "break-word",
    width: "100px",
    multiline: 3,
  },
  textDiv: {
    textAlign: "left",
  },
  textField: {
    width: "100%",
  },
}));
