import React from "react";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ component: Component, user, ...rest }) => {
  let redirectPath = "/admin/sensorDeactivation";
  // if (
  //     //Private roles go here
  //     user.toUpperCase() === "ADMIN"
  // ) {
  //     redirectPath = '/admin/Dashboard';
  // }
  return (
    <div>
      <Route
        {...rest}
        render={(props) =>
          user ? (
            <Component user={user} {...props} />
          ) : (
            <Redirect to={{ pathname: redirectPath }} />
          )
        }
      />
    </div>
  );
};

export default PrivateRoute;
