const bloodSugarProgressDrawerStyle = (theme) => ({
  root: {
    height: "25px",
    width: "308px",
    margin: "16px",
    "& .MuiTabs-flexContainer": {
      border: "1px solid #ccc",
      borderRadius: "4px",
      color: "#000000",
      "& .MuiTab-root": {
        minHeight: "25px",
        minWidth: "33.33%",
      },
      "& .MuiButtonBase-root": {
        textTransform: "capitalize",
      },
      "& .MuiButtonBase-root.Mui-selected": {
        background: "white",
        border: "1px solid #5BABCC",
        color: "#000000",
      },
      "& .MuiButtonBase-root:nth-of-type(1).Mui-selected": {
        borderTopLeftRadius: "4px",
        borderBottomLeftRadius: "4px",
      },
      "& .MuiButtonBase-root:nth-of-type().Mui-selected": {
        borderRadius: "0px",
      },
      "& .MuiButtonBase-root:nth-last-of-type(1).Mui-selected": {
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
      },
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
  },
  bp_container: {
    height: "144px",
    backgroundColor: "#A070CC",
    padding: "5px",
    margin: "16px",
    borderRadius: "8px",
    marginTop: "0.5rem",
  },
  sleep_container: {
    height: "144px",
    backgroundColor: "#3EB9EE",
    padding: "5px",
    borderRadius: "8px",
    marginTop: "0.5rem",
    margin: "16px",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: "64px",
    maxHeight: "64px",
    borderBottom: "1px solid #ccc",
    padding: "0px 16px",
  },
  icon: {
    cursor: "pointer",
  },
  mainContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
    borderTop: "1px solid #ccc",
    borderBottom: "1px solid #ccc",
  },
  subContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
    borderBottom: "1px solid #ccc",
  },
  text: {
    fontSize: "16px",
    fontWeight: 500,
  },
  sub_text: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#8C8C8C",
  },
  chevron: {
    color: "#B3B3B3",
  },
  flex: {
    display: "flex",
  },
});

export default bloodSugarProgressDrawerStyle;
