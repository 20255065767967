import React, { useEffect, useState } from "react";
import { Divider, Typography, withStyles } from "@material-ui/core";
import patientInfoStyle from "../../../../assets/jss/pages/patientInfoStyle";
import moment from "moment";
import lang from "assets/languages";
import { fetchPatientDynamicLink } from "services/patientService";

const PatientInfo = ({ patientData, ...props }) => {
  const { classes } = props;
  const [dynamicLink, setDynamicLink] = useState("");

  useEffect(() => {
    if (patientData?.CGMSensorAvailable) {
      fetchPatientDynamicLink(patientData?.id).then((link) => {
        setDynamicLink(link);
      });
    }
  }, []);

  return (
    <>
      <div className={classes.topContainer}>
        <div className={classes.container}>
          <Typography className={classes.text}>Patient Name</Typography>
          <Typography className={classes.sub_text}>
            {patientData?.firstName + " " + (patientData?.lastName || "")}
          </Typography>
        </div>
        <Divider />
        <div className={classes.container}>
          <Typography className={classes.text}>Gender</Typography>
          <Typography className={classes.sub_text}>
            {patientData?.gender}
          </Typography>
        </div>
        <Divider />
        <div className={classes.container}>
          <Typography className={classes.text}>Age</Typography>
          <Typography className={classes.sub_text}>
            {patientData?.age}
          </Typography>
        </div>
        <Divider />
        <div className={classes.container}>
          <Typography className={classes.text}>
            Registered Mobile Number
          </Typography>
          <Typography className={classes.sub_text}>
            {patientData?.countryCode?.concat(patientData.mobileNumber)}
          </Typography>
        </div>
        <Divider />
        <div className={classes.container}>
          <Typography className={classes.text}>Program Type</Typography>
          <Typography className={classes.sub_text}>
            {patientData?.programType}
          </Typography>
        </div>
        <Divider />
        <div className={classes.container}>
          <Typography className={classes.text}>Email</Typography>
          <Typography className={classes.sub_text}>
            {patientData?.email}
          </Typography>
        </div>
        <Divider />
        <div className={classes.container}>
          <Typography className={classes.text}>Remarks</Typography>
          <Typography className={classes.sub_text}>
            {patientData?.remarks ? patientData.remarks : ""}
          </Typography>
        </div>
        <Divider />
        <div className={classes.container}>
          <Typography className={classes.text}>Doctor Name</Typography>
          <Typography className={classes.sub_text}>
            {patientData?.doctor
              ? `${patientData?.doctor?.firstName} ${
                  patientData?.doctor?.lastName || ""
                }`
              : "N/A"}
          </Typography>
        </div>
        <Divider />
        <div className={classes.container}>
          <Typography className={classes.text}>Clinic Name</Typography>
          <Typography className={classes.sub_text}>
            {patientData?.clinics?.[0]?.name
              ? `${patientData?.clinics[0]?.name}, ${patientData?.clinics[0]?.city}`
              : "N/A"}
          </Typography>
        </div>
        <Divider />
        <div className={classes.container}>
          <Typography className={classes.text}>Subscription Status</Typography>
          {patientData?.subscriptionStatus?.toLowerCase() === "active" ? (
            <Typography className={classes.active_text}>Active</Typography>
          ) : (
            <Typography className={classes.pending_text}>Pending</Typography>
          )}
        </div>
        <Divider />
        <div className={classes.container}>
          <Typography className={classes.text}>
            Subscription Validity
          </Typography>
          <Typography className={classes.sub_text}>
            {patientData?.subscriptionStatus?.toLowerCase() === "pending"
              ? "N/A"
              : patientData.subscriptionValidity
              ? moment(patientData.subscriptionValidity).format("DD MMM YYYY")
              : "N/A"}
          </Typography>
        </div>
        <Divider />
        <div className={classes.container}>
          <Typography className={classes.text}>{lang.doctorCgmLink}</Typography>
          <Typography className={classes.sub_text}>{dynamicLink}</Typography>
        </div>
      </div>
    </>
  );
};

export default withStyles(patientInfoStyle)(PatientInfo);
