import lang from "assets/languages";
import moment from "moment";
import { metricUnit } from "utils/constants";

import { preferredRangeForStats } from "utils/constants";
import { metricIds } from "./constant";
import { graphHeight } from "./SummaryModal";
import { graphYIntervals } from "@pages/MetasenseReport/clinicAutomatedReport/constants";

const getChartFormattedData = (metricData, maxValue) => {
  let updatedMetricData = { ...metricData };

  const isMedianData = metricData.id === metricIds.median;

  const updatedData = updatedMetricData.data.map((data) => {
    if (isMedianData) {
      maxValue.value = Math.max(
        maxValue.value,
        data.bloodGlucose ? parseInt(data.bloodGlucose) : 0
      );
    }

    return {
      x: new Date(moment(data.time, "HH:mm:ss").format()),
      y: isMedianData
        ? parseInt(data.bloodGlucose)
        : data.bloodGlucose.map((glucoseValue) => {
            maxValue.value = Math.max(
              maxValue.value,
              glucoseValue ? parseInt(glucoseValue) : 0
            );

            return parseInt(glucoseValue);
          }),
    };
  });

  updatedMetricData = { ...updatedMetricData, data: updatedData };

  return updatedMetricData;
};

export const getMetricGrapOption = (
  metricData,
  width = 800,
  height = graphHeight,
  animationEnabled = true,
  showInLegend = true
) => {
  if (!metricData) {
    return {};
  }

  const maxValue = { value: 0 };

  let percentile10and90Data = metricData.find(
    (data) => data.id === metricIds.percentile10And90
  );

  if (percentile10and90Data) {
    percentile10and90Data = getChartFormattedData(
      percentile10and90Data,
      maxValue
    );
  }

  let percentile25and75Data = metricData.find(
    (data) => data.id === metricIds.percentile25And75
  );

  if (percentile25and75Data) {
    percentile25and75Data = getChartFormattedData(
      percentile25and75Data,
      maxValue
    );
  }

  let medianData = metricData.find((data) => data.id === metricIds.median);

  if (medianData) {
    medianData = getChartFormattedData(medianData, maxValue);
  }

  const options = {
    zoomEnabled: true,
    width,
    height,
    animationEnabled,
    animationDuration: 1000,
    axisX: {
      title: "Time",
      gridThickness: 1,
      interval: 3,
      intervalType: "hour",
      valueFormatString: "hh TT",
    },
    axisY: {
      title: "Blood Glucose",
      interval: graphYIntervals,
      maximum: Math.max(maxValue.value, 140) + graphYIntervals,
      minimum: 0,
    },
    data: [
      {
        type: "rangeArea",
        lineThickness: 1,
        color: "#2DBE41",
        dataPoints: percentile10and90Data.data.map((e) => {
          return { x: e.x, y: [70, 140] };
        }),
        fillOpacity: 0.1,
        markerType: "none",
      },
      percentile10and90Data && {
        type: "rangeSplineArea",
        color: "#DFE3EC",
        showInLegend: showInLegend,
        legendText: percentile10and90Data.label,
        lineThickness: 0,
        dataPoints: percentile10and90Data.data,
        fillOpacity: 1,
      },
      percentile25and75Data && {
        type: "rangeSplineArea",
        color: "#A5B5D1",
        showInLegend: showInLegend,
        legendText: percentile25and75Data.label,
        lineThickness: 0,
        dataPoints: percentile25and75Data.data,
        fillOpacity: 1,
      },
      medianData && {
        type: "spline",
        color: "#20538A",
        showInLegend: showInLegend,
        dataPoints: medianData.data,
        lineThickness: 2,
        legendText: medianData.label,
        fillOpacity: 1,
      },
    ],
  };

  return options;
};

const getSensorLifeSpanInDays = ({ sensor }) => {
  if (!sensor) return 0;

  const sensorActivationDateInUTC = moment(sensor?.sensorActivationDateInUTC);
  const sensorExpirationDateInUTC = moment(sensor?.sensorExpirationDateInUTC);
  const timeline = Math.abs(
    +sensorExpirationDateInUTC.diff(sensorActivationDateInUTC, "days")
  );

  return timeline;
};

export const getAverageGlucoseGraphOptions = (props) => {
  const { list, selectedSensor } = props;

  const defaultData = new Array(
    getSensorLifeSpanInDays({ sensor: selectedSensor })
  );
  defaultData.fill({ date: null, label: null, y: 0 });

  const arr = list || defaultData;
  const defaultGlucoseReading = 0;

  const options = {
    zoomEnabled: true,
    width: 720,
    height: 220,
    dataPointWidth: 25,
    toolTip: {
      shared: true,
      cornerRadius: 8,
      borderColor: "#D9D9D9",
      backgroundColor: "#FFFFFF",
      borderThickness: 0,
      fontWeight: "normal",
      fontColor: "#262626",
      contentFormatter: function (e) {
        const [chartData] = e?.entries;
        let str = "";
        let temp = `
          <div style="padding: 4px 8px">
            <b>${lang.avgGlucose}</b>
            </br>
            <b>${chartData?.dataPoint.y}</b> <span style="color:#8C8C8C;font-size: 12px">${metricUnit.glucose}<span>
            </br>
            <span style="color:#8C8C8C; font-size:12px">${chartData?.dataPoint.date}</span>
          </div>`;
        str = str.concat(temp);
        return str;
      },
    },
    axisX: {
      interval: 1,
      gridColor: "#F0F0F0",
    },
    axisY: {
      interval: 50,
      gridColor: "#F0F0F0",
    },
    data: [
      {
        type: "column",
        name: lang.glucoseReading,
        showInLegend: true,
        color: "#FFFFFF",
        dataPoints: arr?.map((item, index) => {
          // To add skeleton labels and data, if no data is returned from API
          if (!item?.date) {
            const date = moment(selectedSensor?.sensorActivationDateInUTC).add(
              "days",
              index
            );

            return {
              y: defaultGlucoseReading,
              label: date.format("DD/MM").toString(),
              date: date.format("DD MMM").toString(),
            };
          }

          const glucoseReading = +item?.averageGlucose || defaultGlucoseReading;

          return {
            y: glucoseReading,
            label: moment(item?.date).format("DD/MM").toString(),
            date: moment(item?.date).format("DD MMM").toString(),
            color:
              glucoseReading > preferredRangeForStats?.glucose?.lowerLimit &&
              glucoseReading < preferredRangeForStats?.glucose?.upperLimit
                ? "#2DBE41"
                : "#F0647D",
          };
        }),
      },
      {
        type: "rangeArea",
        fillOpacity: 0.1,
        markerType: "none",
        color: "#2DBE41",
        lineThickness: 0.5,
        showInLegend: false,
        dataPoints: arr?.map((item) => {
          return {
            y: [
              preferredRangeForStats?.glucose?.lowerLimit,
              preferredRangeForStats?.glucose?.upperLimit,
            ],
          };
        }),
      },
    ],
  };

  return options;
};

export const getTimeInRangeChartOptions = (props) => {
  const { list, selectedSensor } = props;

  const yAxisIntervalSize = 6;
  const yAxisMax = 24;
  const dataPointWidth = 25;

  const defaultData = new Array(
    getSensorLifeSpanInDays({ sensor: selectedSensor })
  );
  defaultData.fill({ date: null, label: null, y: 0 });

  const arr = list || defaultData;

  const options = {
    zoomEnabled: true,
    width: 720,
    height: 204,
    axisX: {
      interval: 1,
      gridColor: "#F0F0F0",
    },
    axisY: {
      gridColor: "#F0F0F0",
      maximum: yAxisMax,
      interval: yAxisIntervalSize,
    },
    dataPointWidth,
    toolTip: {
      shared: true,
      cornerRadius: 8,
      borderColor: "#D9D9D9",
      backgroundColor: "#FFFFFF",
      borderThickness: 0,
      fontWeight: "normal",
      fontColor: "#262626",
      contentFormatter: function (e) {
        const [chartData] = e?.entries;
        let str = "";

        let temp = `
          <div style="padding: 4px 8px">
            <b>${lang.timeInRange}</b>
            </br>
            <b>${chartData?.dataPoint.y}</b> <span style="color:#8C8C8C;font-size: 12px">${metricUnit.tir}<span>
            </br>
            <span style="color:#8C8C8C; font-size:12px">${chartData?.dataPoint?.date}</span>
          </div>`;
        str = str.concat(temp);
        return str;
      },
    },
    data: [
      {
        type: "stackedColumn",
        showInLegend: true,
        color: "#2DBE41",
        name: lang.timeInRange,
        dataPoints: arr?.map((item, index) => {
          // To add skeleton labels and data, if no data is returned from API
          if (!item?.date) {
            const date = moment(selectedSensor?.sensorActivationDateInUTC).add(
              "days",
              index
            );

            return {
              y: 0,
              label: date.format("DD/MM").toString(),
              date: date.format("DD MMM").toString(),
            };
          }
          return {
            y: item?.TIRInHours || 0,
            label: moment(item?.date).format("DD/MM").toString(),
            date: moment(item?.date).format("DD MMM").toString(),
          };
        }),
      },
      {
        type: "stackedColumn",
        showInLegend: false,
        name: "Q2",
        color: "#F0647D",
        dataPoints: arr?.map((item, index) => {
          // To add skeleton labels and data, if no data is returned from API
          if (!item?.date) {
            const date = moment(selectedSensor?.sensorActivationDateInUTC).add(
              "days",
              index
            );

            return {
              y: 0,
              label: date.format("DD/MM").toString(),
              date: date.format("DD MMM").toString(),
            };
          }
          return {
            y: item?.timeLapsed - item?.TIRInHours || 0,
            label: moment(item?.date).format("DD/MM").toString(),
            date: moment(item?.date).format("DD MMM").toString(),
          };
        }),
      },
      {
        type: "rangeArea",
        fillOpacity: 0.1,
        markerType: "none",
        color: "#2DBE41",
        lineThickness: 0.5,
        showInLegend: false,
        dataPoints: arr.map((item) => {
          return {
            y: [
              preferredRangeForStats?.timeInRage?.lowerLimit,
              preferredRangeForStats?.timeInRage?.upperLimit,
            ],
          };
        }),
      },
    ],
  };
  return options;
};

export const getStepsChartOptions = (props) => {
  const { list, selectedSensor } = props;

  const yAxisIntervalSize = 2500;
  const yAxisMax = 10000;
  const dataPointWidth = 25;

  const defaultData = new Array(
    getSensorLifeSpanInDays({ sensor: selectedSensor })
  );
  defaultData.fill({ date: null, label: null, y: 0 });

  const arr = list || defaultData;

  const options = {
    zoomEnabled: true,
    width: 720,
    height: 204,
    axisX: {
      interval: 1,
      gridColor: "#F0F0F0",
    },
    axisY: {
      gridColor: "#F0F0F0",
      maximum: yAxisMax,
      interval: yAxisIntervalSize,
    },
    dataPointWidth,
    toolTip: {
      shared: true,
      cornerRadius: 8,
      borderColor: "#D9D9D9",
      backgroundColor: "#FFFFFF",
      borderThickness: 0,
      fontWeight: "normal",
      fontColor: "#262626",
      contentFormatter: function (e) {
        const [chartData] = e?.entries;
        let str = "";

        let temp = `
          <div style="padding: 4px 8px">
            <b>${lang.avgSteps}</b>
            </br>
            <b>${chartData?.dataPoint.y}</b> <span style="color:#8C8C8C;font-size: 12px">${metricUnit.steps}<span>
            </br>
            <span style="color:#8C8C8C; font-size:12px">${chartData?.dataPoint?.date}</span>
          </div>`;
        str = str.concat(temp);
        return str;
      },
    },
    data: [
      {
        type: "column",
        showInLegend: true,
        color: "#FAAF23",
        name: lang.steps,
        dataPoints: arr?.map((item, index) => {
          // To add skeleton labels and data, if no data is returned from API
          if (!item?.date) {
            const date = moment(selectedSensor?.sensorActivationDateInUTC).add(
              "days",
              index
            );

            return {
              y: 0,
              label: date.format("DD/MM").toString(),
              date: date.format("DD MMM").toString(),
            };
          }

          return {
            y: item?.steps || 0,
            label: moment(item?.date).format("DD/MM").toString(),
            date: moment(item?.date).format("DD MMM").toString(),
          };
        }),
      },
    ],
  };
  return options;
};
