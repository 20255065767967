import React from "react";
import { Typography, withStyles } from "@material-ui/core";
import { Form, Formik } from "formik";
import hbA1c_icon from "../../../../../assets/icons/hbA1c.svg";
import Button from "@components/atoms/Button";
import FormTextInput from "@components/molecules/FormTextInput/FormTextInput";
import moment from "moment";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
//import { PostCreateHbA1cDataAction } from "../../actions/AddHealthDataActions";
import { showErrorMessage } from "../../../../../utils/utility";
import * as Yup from "yup";
import Loader from "@components/atoms/Loader";
import FormDatePicker from "@components/molecules/FormDatePicker";
import FormTimePicker from "@components/molecules/FormTimePicker";
import taskDetailsDrawerStyle from "../../../../../assets/jss/pages/components/taskDetailsDrawerStyle";
import CloseIcon from "@material-ui/icons/Close";
import api from "utils/api";
import { uploadFile } from "../../../../../services/fileService";

const HbA1cSchema = Yup.object().shape({
  value: Yup.number()
    .positive()
    .max(100)
    .required("Required")
    .test("is-decimal", "Invalid decimal", (value) =>
      (value + "").match(/^(\d{0,5}\.\d{1,2}|\d{1,5})$/)
    ),
});

const AddHba1c = (props) => {
  const { loading, classes } = props;
  const taskId = "";
  const module = "";
  const hiddenFileInput = React.useRef(null);
  const [images, setImages] = React.useState([]);
  const [time, setTime] = React.useState();

  const { id } = useParams();

  const onTime = (value) => {
    setTime(value);
  };
  const AddHbA1cData = (values) => {
    if (taskId) {
      let postData = {
        taskId: taskId,
        date: values.date,
        time: values.time,
        value: values.value,
      };
      if (images.length > 0) {
        postData.photo = images;
      }

      props.PostCreateHbA1cDataAction(postData, module);
    } else {
      let postData = {
        date: values.date,
        time: values.time,
        value: values.value,
      };

      if (images.length > 0) {
        postData.photo = images;
      }

      api(`/v1/health/createHealthData/${id}?type=hba1c`, {
        method: "post",
        data: postData,
      })
        .then(() => props.handleCloseDrawer())
        .catch(console.error);
    }
    setImages([]);
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleUpload = async (event) => {
    const fileUploaded = [...event.target.files];

    if (fileUploaded.length > 5) {
      showErrorMessage("Cannot select more than 5 images");
    } else {
      fileUploaded.forEach((file) => {
        uploadFile(file)
          .then((data) => {
            setImages((prevProps) => [...prevProps, data]);
          })
          .catch((err) => console.error(err));
      });
    }
  };

  return (
    <>
      {loading && <Loader open={true} loaderMessage="Fetching..." />}
      <div className={classes.headerContainer}>
        <Typography
          className={classes.headerText}
          onClick={props.handleDetails}
        >
          Back
        </Typography>
        <CloseIcon className={classes.icon} onClick={props.handleCloseDrawer} />
      </div>
      <Formik
        initialValues={{
          date: moment().format("YYYY-MM-DD"),
          time: moment().format("hh:mm A"),
          value: "",
        }}
        validationSchema={HbA1cSchema}
        onSubmit={(values, { resetForm }) => {
          AddHbA1cData(values);
          setTimeout(() => {
            props.handleDetails();
            resetForm();
          }, 100);
        }}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form>
              <div>
                <div
                  style={{
                    height: "180px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    borderBottom: "1px solid #ccc",
                    backgroundColor: "#FAFAFA",
                  }}
                >
                  <img
                    src={hbA1c_icon}
                    height="75px"
                    width="75px"
                    alt="weight"
                  />
                  <Typography style={{ fontSize: "24px", fontWeight: 500 }}>
                    HbA1c
                  </Typography>
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    justifyContent: "space-between",
                    padding: "16px",
                    backgroundColor: "white",
                    borderBottom: "1px solid #ccc",
                    alignItems: "center",
                  }}
                >
                  <Typography style={{ fontSize: "16px", fontWeight: 500 }}>
                    Date
                  </Typography>
                  <FormDatePicker
                    name="date"
                    onChange={(value) =>
                      setFieldValue("date", moment(value).format("YYYY-MM-DD"))
                    }
                    size="small"
                  />
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    justifyContent: "space-between",
                    padding: "16px",
                    backgroundColor: "white",
                    borderBottom: "1px solid #ccc",
                    alignItems: "center",
                  }}
                >
                  <Typography style={{ fontSize: "16px", fontWeight: 500 }}>
                    Time
                  </Typography>
                  <FormTimePicker
                    name="time1"
                    onChange={(value) =>
                      setFieldValue("time", moment(value).format("hh:mm A"))
                    }
                    onTime={onTime}
                    type="reminder"
                    value={`2022-01-01T${time}+05:30`}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "16px",
                    backgroundColor: "white",
                    borderBottom: "1px solid #ccc",
                  }}
                >
                  <Typography style={{ fontSize: "16px", fontWeight: 500 }}>
                    Value (in %)
                  </Typography>
                  <FormTextInput
                    type="decimal"
                    name="value"
                    placeholder="Enter value"
                    size="small"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "1rem",
                    padding: "16px",
                    borderBottom: "1px solid #ccc",
                    borderTop: "1px solid #ccc",
                    alignItems: "center",
                    backgroundColor: "white",
                  }}
                  onClick={handleClick}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Typography
                      style={{
                        fontSize: "16px",
                        color: "#5BABCC",
                        fontWeight: 500,
                      }}
                    >
                      Add Photo
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "16px",
                        color: "#D9D9D9",
                        fontWeight: 500,
                        marginLeft: "0.5rem",
                      }}
                    >
                      (Optional)
                    </Typography>
                  </div>
                </div>
                <input
                  type="file"
                  ref={hiddenFileInput}
                  multiple="multiple"
                  accept="image/png, image/jpeg, image/svg"
                  onChange={handleUpload}
                  style={{ display: "none" }}
                />
                <div
                  style={{ display: "flex", padding: "16px", overflow: "auto" }}
                >
                  {images.length > 0 &&
                    images.map((image, index) => {
                      return (
                        <a
                          style={{ textDecoration: "none" }}
                          href={image}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={image}
                            style={{ margin: "0px 5px" }}
                            key={index}
                            alt="img"
                            height="50px"
                            width="50px"
                          />
                        </a>
                      );
                    })}
                </div>
                <div style={{ padding: "16px" }}>
                  <Button type="submit" fullWidth lg>
                    Add data
                  </Button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default withStyles(taskDetailsDrawerStyle)(AddHba1c);
