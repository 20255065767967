// "healthyHabitsRiskScore":{
//          "insight":"",
//          "progress":"",
//          "advice":""
//       },
//       "glycemicScore":{
//          "value":"20",
//          "insight":"",
//          "progress":"",
//          "advice":""
//       },
//       "distressScore":{
//          "value":"20",
//          "insight":"",
//          "progress":"",
//          "advice":""
//       },
//       "healthyHabitRoadmap":{
//          "medicalIntervention":true,
//          "healthProfile":false,
//          "lifeStyleManagement":false
//       }

// {
//          "rowCellKey": '',
//          "rowCellValue": '',
//          "placeholder": '',
//          "isRequired": true/false,
//          "textColor": ''
//       }

//   {
//    "rowName":"sgdsg",
//    "rowKey":"gxgus",
//    "tablCells":[
//       {
//          "rowCellKey": '',
//          "rowCellValue": '',
//          "placeholder": '',
//          "isRequired": true/false,
//          "textColor": ''
//          "editable": true/false
//       },
//    ]
// }

export const summaryTableRowKeys = {
  healthyHabitsRiskScore: "healthyHabitsRiskScore",
  glycemicScore: "glycemicScore",
  distressScore: "distressScore",
};

export const healthHabitsKey = "healthyHabitRoadmap";

export const summaryTablColumnKeys = {
  value: "value",
  insight: "insight",
  progress: "progress",
  advice: "advice",
};

const keyBasedHeading = {
  [summaryTableRowKeys.healthyHabitsRiskScore]: "Healthy Habits Risk Score",
  [summaryTableRowKeys.glycemicScore]: "Glycemic Score",
  [summaryTableRowKeys.distressScore]: "Distress Score",
};

const summaryScores = {
  [summaryTableRowKeys.distressScore]: {
    goodMin: 0,
    goodMax: 12,
    averageMax: 36,
  },
  [summaryTableRowKeys.glycemicScore]: {
    goodMin: 90,
    averageMin: 70,
  },
  [summaryTableRowKeys.healthyHabitsRiskScore]: {
    goodMin: 0,
    goodMax: 22,
    averageMax: 35,
  },
};

export const healthHabitsKeys = {
  medicalIntervention: "medicalIntervention",
  healthProfile: "healthProfile",
  lifeStyleManagement: "lifeStyleManagement",
};

const healthHabitsKeyBasedHeadings = {
  [healthHabitsKeys.medicalIntervention]:
    "You need medical intervention, medicine support to maintain the good BGL, kindly consult the doctor regularly",
  [healthHabitsKeys.healthProfile]:
    "Your health profile looks good, continue the following the healthy lifestyle routine, no need of lifestyle consultation required",
  [healthHabitsKeys.lifeStyleManagement]:
    "You need a long term lifestyle management program along with the Medicines, to bring down the BGL to normal levels",
};

export const getValueColor = (rowKey, rowCellValue) => {
  switch (rowKey) {
    case summaryTableRowKeys.distressScore:
      if (
        rowCellValue <= summaryScores[summaryTableRowKeys.distressScore].goodMax
      ) {
        return "#2DBE41";
      } else if (
        rowCellValue >
          summaryScores[summaryTableRowKeys.distressScore].goodMax &&
        rowCellValue <
          summaryScores[summaryTableRowKeys.distressScore].averageMax
      ) {
        return "#FAAF23";
      } else {
        return "#F0647D";
      }
    case summaryTableRowKeys.glycemicScore:
      if (
        rowCellValue >= summaryScores[summaryTableRowKeys.glycemicScore].goodMin
      ) {
        return "#2DBE41";
      } else if (
        rowCellValue >=
          summaryScores[summaryTableRowKeys.glycemicScore].averageMin &&
        rowCellValue < summaryScores[summaryTableRowKeys.glycemicScore].goodMin
      ) {
        return "#FAAF23";
      } else {
        return "#F0647D";
      }
    case summaryTableRowKeys.healthyHabitsRiskScore:
      if (
        rowCellValue <=
        summaryScores[summaryTableRowKeys.healthyHabitsRiskScore].goodMax
      ) {
        return "#2DBE41";
      } else if (
        rowCellValue >
          summaryScores[summaryTableRowKeys.healthyHabitsRiskScore].goodMax &&
        rowCellValue <=
          summaryScores[summaryTableRowKeys.healthyHabitsRiskScore].averageMax
      ) {
        return "#FAAF23";
      } else {
        return "#F0647D";
      }
    default:
      return "#262626";
  }
};

export const getSummaryTableData = (summaryData) => {
  const tableRowData = [];

  if (!summaryData) {
    return summaryData;
  }

  for (const [rowKey, rowValue] of Object.entries(summaryData)) {
    if (!Object.keys(summaryTableRowKeys).includes(rowKey)) {
      continue;
    }

    const tableRow = { rowKey: "", rowName: "", tablCells: [] };

    const rowHeading = keyBasedHeading[rowKey];

    tableRow.rowKey = rowKey;
    tableRow.rowName = rowHeading;

    for (const [rowCellKey, rowCellValue] of Object.entries(rowValue)) {
      tableRow.tablCells.push({
        rowCellKey: rowCellKey,
        rowCellValue: rowCellValue,
        isRequired: rowCellKey === summaryTablColumnKeys.insight,
        editable: rowCellKey !== summaryTablColumnKeys.value,
        ...(rowCellKey === summaryTablColumnKeys.value
          ? { textColor: getValueColor(rowKey, rowCellValue) }
          : {}),
      });
    }

    tableRowData.push(tableRow);
  }

  return tableRowData;
};

export const getCheckboxOptions = (summaryData, showDisable = true) => {
  const { healthyHabitRoadmap } = summaryData;

  const options = [];

  for (const [key, value] of Object.entries(healthyHabitRoadmap)) {
    options.push({
      key,
      value,
      label: healthHabitsKeyBasedHeadings[key],
      disabled: showDisable
        ? key === healthHabitsKeys.medicalIntervention ||
          key === healthHabitsKeys.lifeStyleManagement
        : false,
    });
  }

  return options;
};
