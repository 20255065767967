import api from "../utils/api";
import { notyf } from "../utils/utility";
import { END_POINT, API_METHODS, apiResponses } from "../network/constants";

export const uploadFile = async (file) => {
  const formData = new FormData();

  formData.append("file", file);

  try {
    const { data } = await api(END_POINT.uploadFile, {
      method: API_METHODS.POST,
      data: formData,
    });

    if (data.status === apiResponses.success) {
      notyf.success(` successfully.`);
    } else {
      notyf.error(data.message);
    }
    return data?.data;
  } catch (e) {
    notyf.error(e.message);
  }
};

export const postReportsApi = async (reports, id) => {
  try {
    const response = await api(END_POINT.postReports(id), {
      method: API_METHODS.POST,
      data: reports,
    });

    if (response?.data?.status === apiResponses.failure) {
      return null;
    }

    return response?.data?.data;
  } catch (e) {
    notyf.error(e.message);
  }
};

export const uploadReportsAPI = async (reports) => {
  try {
    const response = await api(END_POINT.uploadReport(), {
      method: API_METHODS.POST,
      data: reports,
    });

    if (response?.data?.status === apiResponses.failure) {
      return null;
    }

    return response?.data?.data;
  } catch (e) {
    notyf.error(e.message);
    throw e;
  }
};

export const fetchReportsApi = async (id, reportType = null) => {
  try {
    const response = await api(END_POINT.getReports(id, reportType), {
      method: API_METHODS.GET,
    });
    if (response?.data?.status === apiResponses.failure) {
      return null;
    }

    return response?.data?.data;
  } catch (e) {
    notyf.error(e.message);
  }
};

export const fetchPaitentAllReports = async (id) => {
  try {
    const response = await api(END_POINT.getPatientAllReports(id), {
      method: API_METHODS.GET,
    });
    if (response?.data?.status === apiResponses.failure) {
      return null;
    }

    return response?.data?.data;
  } catch (e) {
    notyf.error(e.message);
  }
};
