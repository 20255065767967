export const getChangedBasicDetails = (prevData, currentData) => {
  let output = {};

  let newDiagnosisIds = currentData?.diagnosis?.map((diagnosis) => {
    return diagnosis?.id;
  });

  let oldDiagnosisIds = prevData?.diagnosis?.map((diagnosis) => {
    return diagnosis?.id;
  });

  if (prevData?.firstName !== currentData?.firstName) {
    output.firstName = currentData?.firstName;
  }
  if (prevData?.lastName !== currentData?.lastName) {
    output.lastName = currentData?.lastName;
  }
  if (prevData?.age !== currentData?.age) {
    output.age = currentData?.age;
  }
  if (prevData?.gender !== currentData?.gender) {
    output.gender = currentData?.gender;
  }

  if (prevData?.clinics[0]?.id !== currentData?.clinicId) {
    output.clinics = [
      {
        id: currentData?.clinicId,
      },
    ];
  }
  if (prevData?.doctor?.id !== currentData?.doctor) {
    output.doctor = currentData?.doctor;
  }

  if (prevData?.coach?.id !== Number(currentData?.coach)) {
    output.coach = Number(currentData?.coach) > 0 ? currentData?.coach : null;
  }

  if (oldDiagnosisIds?.sort()?.join("") !== newDiagnosisIds?.sort()?.join("")) {
    output.diagnosis = newDiagnosisIds;
  }

  return output;
};

export const additionalDetailsComparator = (prevData, currentData) => {};
