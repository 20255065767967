import React from "react";
import { Divider, Typography, withStyles } from "@material-ui/core";
import CustomButtons from "@components/atoms/Button";
import confirmDialogStyle from "assets/jss/pages/components/confirmDialogStyle";
import lang from "assets/languages";

const ConfirmDialog = ({
  classes,
  handleGoBackToAllPatients,
  handleCloseConfirmDialog,
}) => {
  return (
    <div className={classes.mainContainer}>
      <div className={classes.dialogTextBox}>
        <Typography className={classes.dialogText}>
          {lang.closeConfirmationMessage}
        </Typography>
      </div>
      <Divider />
      <div className={classes.buttonsBox}>
        <CustomButtons md_border onClick={handleCloseConfirmDialog}>
          {lang.cancel}
        </CustomButtons>
        <CustomButtons
          md
          onClick={handleGoBackToAllPatients}
          className={classes.buttonYes}
        >
          {lang.yes}
        </CustomButtons>
      </div>
    </div>
  );
};

export default withStyles(confirmDialogStyle)(ConfirmDialog);
