import {
  setDayWiseCGMData,
  setGraphData,
  updatePatientInfo,
  updateReportPatientProgress,
  updateReportSummary,
} from "@pages/CreateReport/actions/CreateReportActions";
import lang from "@assets/languages";
import { API_METHODS } from "@network/constants";
import { END_POINT } from "@network/constants";
import { apiResponses } from "network/constants";
import { reportTypeForApi } from "@pages/CreateReport/constants";
import api from "@utils/api";
import { notyf } from "@utils/utility";
import { StoreProviderService } from "@services/storeProviderService";
import moment from "moment";

export const saveReportData = async (reportBody) => {
  try {
    const { data } = await api(END_POINT.saveReportData, {
      method: API_METHODS.POST,
      data: reportBody,
    });
    if (data.status === apiResponses.success) {
      notyf.success(
        reportBody.reportType === reportTypeForApi.seventhDay
          ? lang.seventhDayReportDataSavedSuccessFully
          : lang.fourteenthDayReportDataSavedSuccessFully
      );
    } else {
      notyf.error(data.message);
    }
    return data?.data;
  } catch (e) {
    notyf.error(e.message);
  }
};

export const get7And14DayGraphData = async (sesorId, reportType, reportId) => {
  const dispatch = StoreProviderService.getDispatch();
  try {
    const {
      data: { data: metricGraphData, status: metricGraphStatus },
    } = await api(
      END_POINT.getSensorSummaryGraphByGraphType(sesorId, reportType),
      {
        method: API_METHODS.GET,
      }
    );

    if (metricGraphStatus === apiResponses.success) {
      dispatch(
        setGraphData({
          reportType: reportId,
          metricData: metricGraphData.metricData,
          insights: metricGraphData.insight,
          maxGlucoseValue: metricGraphData.high,
          minGlucoseValue: metricGraphData.low,
        })
      );
    } else {
      notyf.error("failed to fetch metric data and summary");
    }
  } catch (e) {
    notyf.error(e, "failed to fetch metric data and summary");
  }
};

export const fetchPatientInfoData = async (reportId) => {
  const dispatch = StoreProviderService.getDispatch();

  try {
    const {
      data: { data, status },
    } = await api(END_POINT.getPatientInfo(reportId), {
      method: API_METHODS.GET,
    });

    if (status === apiResponses.success) {
      dispatch(updatePatientInfo(data));
    }
  } catch (e) {
    notyf.error("failed to fetch patient info data");
  }
};

export const fetchPatientProgressData = async (reportId) => {
  const dispatch = StoreProviderService.getDispatch();

  try {
    const {
      data: { data, status },
    } = await api(END_POINT.getPatientProgress(reportId), {
      method: API_METHODS.GET,
    });

    if (status === apiResponses.success) {
      dispatch(updateReportPatientProgress(data));
    }
  } catch (e) {
    notyf.error("failed to fetch progress data");
  }
};

export const fetchReportSummary = async (reportId) => {
  const dispatch = StoreProviderService.getDispatch();

  try {
    const {
      data: { data, status },
    } = await api(END_POINT.getReportSummary(reportId), {
      method: API_METHODS.get,
    });

    if (status === "SUCCESS") {
      dispatch(updateReportSummary(data));
    }
  } catch (e) {
    notyf.error("Failed to fetch summary data");
  }
};

export const fetchDataWiseCGMData = async (patientId, params = {}) => {
  const dispatch = StoreProviderService.getDispatch();

  try {
    const {
      data: { data, status },
    } = await api(END_POINT.getCgmData(patientId), {
      method: "get",
      params,
    });

    if (status === "SUCCESS") {
      let updatedData = Object.entries(data).map((cgmData) => {
        const date = cgmData[0];
        const bloodGlucoseData = cgmData[1];
        let newData = bloodGlucoseData.map((glucoseData) => {
          return {
            x: new Date(glucoseData?.time),
            y: glucoseData?.bloodGlucose
              ? parseInt(glucoseData?.bloodGlucose)
              : null,
            label: moment(glucoseData?.time).format("hh:mm A"),
          };
        });

        newData = [date, newData];
        return newData;
      });

      dispatch(setDayWiseCGMData(updatedData));
    }
  } catch (e) {
    notyf.error("Failed to fetch summary data");
  }
};
