const learnMoreDrawerStyle = theme => ({
  slide: {
    width: "86%",
    display: "flex",
    justifyContent: "center",
    margin: "20px",
    border: "1px solid #ccc",
    cursor: "pointer"
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: "64px",
    maxHeight: "64px",
    borderBottom: "1px solid #ccc",
    padding: "0px 16px",
    position: "sticky",
    zIndex: 1000,
    top: 0,
    backgroundColor: "white"
  },
  emptyContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "16px",
  },
  icon: {
    cursor: "pointer"
  },
  headerText: {
    fontSize: "16px",
    fontWeight: 500,
    color: "#2B377E",
    cursor: "pointer"
  },
  imageContainer: {
    height: "200px",
    width: "100%",
    backgroundColor: "#C4C4C4"
  },
  text: {
    fontSize: "16px",
    fontWeight: 500,
    marginTop: "1rem"
  },
  sub_text: {
    fontSize: "14px",
    marginTop: "0.5rem"
  },
  padded: {
    padding: "12px"
  }
})

export default learnMoreDrawerStyle;