import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() =>
  createStyles({
    list: {
      fontSize: "14px",
      background: "white",
      color: "#000000",
      "& li:hover": {
        background: "whitesmoke",
      },
      "& li.Mui-selected": {
        background: "whitesmoke",
        "&:hover": {
          background: "whitesmoke",
        },
      },
      //disabled classes
      "& li.Mui-disabled": {
        background: "grey",
      },
    },
    root: {
      minWidth: "50%",
      width: "150px",
      height: "45px",
      color: "#000000",
      border: `1px solid #D9D9D9`,
      borderRadius: 0,
      fontSize: "14px",
      "& .MuiInputBase-root": {
        fontSize: "14px",
        color: "#000000",
      },
      "& .MuiSelect-select": {
        padding: "13px 14px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: `0px !important`,
        outline: "0 !important",
      },
      "& .MuiSelect-icon": {
        color: "#595959",
      },
      "& .MuiInputLabel-root": {
        // "&.MuiFormLabel-filled": {
        //   // opacity: 0,
        // },
        color: "#595959",
      },
    },
    emptyItem: {
      display: "none",
    },
  })
);
