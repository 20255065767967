/**
 @desc
 * Centralized unique actions for Patient Module.
 */

import {
  actionCreator,
  checkHttpStatus,
  jsonApiHeader,
  showErrorMessage,
  CreateWeekPlanActionTypes,
  GET_ALL_LEVELS,
  GET_ALL_CATEGORIES,
  GET_ALL_MODULES,
  POST_CREATE_WEEKPLAN,
  PATCH_UPDATE_WEEKPLAN,
  GET_MASTER_TASKS_BY_MODULE,
} from "../constants";
import { TOKEN_EXPIRY_MESSAGE } from "../../../utils/utility";

export const GetAllLevelsAction = () => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.token;
    dispatch(actionCreator(CreateWeekPlanActionTypes.get_AllLevels.REQUEST));
    fetch(`${GET_ALL_LEVELS}`, {
      method: "GET",
      headers: jsonApiHeader(token),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(
            actionCreator(
              CreateWeekPlanActionTypes.get_AllLevels.FAILURE,
              response
            )
          );
          showErrorMessage(response.Message);
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage(TOKEN_EXPIRY_MESSAGE);
          localStorage.clear();
          dispatch(
            actionCreator(
              CreateWeekPlanActionTypes.get_AllLevels.FAILURE,
              response
            )
          );
          window.location.reload();
        } else {
          dispatch(
            actionCreator(
              CreateWeekPlanActionTypes.get_AllLevels.SUCCESS,
              response
            )
          );
        }
      })
      .catch(function (error) {
        dispatch(
          actionCreator(CreateWeekPlanActionTypes.get_AllLevels.FAILURE)
        );
        console.log("error get_AllLevels ..", error);
      });
  };
};

export const GetAllCategoriesAction = () => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.token;
    dispatch(
      actionCreator(CreateWeekPlanActionTypes.get_AllCategories.REQUEST)
    );
    fetch(`${GET_ALL_CATEGORIES}`, {
      method: "GET",
      headers: jsonApiHeader(token),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(
            actionCreator(
              CreateWeekPlanActionTypes.get_AllCategories.FAILURE,
              response
            )
          );
          showErrorMessage(response.Message);
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage(TOKEN_EXPIRY_MESSAGE);
          localStorage.clear();
          dispatch(
            actionCreator(
              CreateWeekPlanActionTypes.get_AllCategories.FAILURE,
              response
            )
          );
          window.location.reload();
        } else {
          dispatch(
            actionCreator(
              CreateWeekPlanActionTypes.get_AllCategories.SUCCESS,
              response
            )
          );
        }
      })
      .catch(function (error) {
        dispatch(
          actionCreator(CreateWeekPlanActionTypes.get_AllCategories.FAILURE)
        );
        console.log("error get_AllCategories ..", error);
      });
  };
};

export const GetAllModulesAction = () => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.token;
    dispatch(actionCreator(CreateWeekPlanActionTypes.get_AllModules.REQUEST));
    fetch(`${GET_ALL_MODULES}`, {
      method: "GET",
      headers: jsonApiHeader(token),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(
            actionCreator(
              CreateWeekPlanActionTypes.get_AllModules.FAILURE,
              response
            )
          );
          showErrorMessage(response.Message);
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage(TOKEN_EXPIRY_MESSAGE);
          localStorage.clear();
          dispatch(
            actionCreator(
              CreateWeekPlanActionTypes.get_AllModules.FAILURE,
              response
            )
          );
          window.location.reload();
        } else {
          dispatch(
            actionCreator(
              CreateWeekPlanActionTypes.get_AllModules.SUCCESS,
              response
            )
          );
        }
      })
      .catch(function (error) {
        dispatch(
          actionCreator(CreateWeekPlanActionTypes.get_AllModules.FAILURE)
        );
        console.log("error get_AllModules ..", error);
      });
  };
};

export const PostCreateWeekPlanAction = (postData, callback = () => {}) => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.token;
    dispatch(
      actionCreator(CreateWeekPlanActionTypes.post_CreateWeekPlan.REQUEST)
    );
    fetch(`${POST_CREATE_WEEKPLAN}`, {
      method: "POST",
      body: JSON.stringify(postData),
      headers: jsonApiHeader(token),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(
            actionCreator(
              CreateWeekPlanActionTypes.post_CreateWeekPlan.FAILURE,
              response
            )
          );
          showErrorMessage(response.message);
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage(TOKEN_EXPIRY_MESSAGE);
          localStorage.clear();
          dispatch(
            actionCreator(
              CreateWeekPlanActionTypes.post_CreateWeekPlan.FAILURE,
              response
            )
          );
          window.location.reload();
        } else {
          dispatch(
            actionCreator(
              CreateWeekPlanActionTypes.post_CreateWeekPlan.SUCCESS,
              response
            )
          );
        }
        callback();
      })
      .catch(function (error) {
        dispatch(
          actionCreator(CreateWeekPlanActionTypes.post_CreateWeekPlan.FAILURE)
        );
        console.log("error post_CreateWeekPlan ..", error);
      });
  };
};

export const PatchUpdateWeekPlanAction = (postData, callback = () => {}) => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.token;
    dispatch(
      actionCreator(CreateWeekPlanActionTypes.patch_UpdateWeekPlan.REQUEST)
    );
    fetch(`${PATCH_UPDATE_WEEKPLAN}`, {
      method: "PUT",
      body: JSON.stringify(postData),
      headers: jsonApiHeader(token),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(
            actionCreator(
              CreateWeekPlanActionTypes.patch_UpdateWeekPlan.FAILURE,
              response
            )
          );
          showErrorMessage(response.message);
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage(TOKEN_EXPIRY_MESSAGE);
          localStorage.clear();
          dispatch(
            actionCreator(
              CreateWeekPlanActionTypes.patch_UpdateWeekPlan.FAILURE,
              response
            )
          );
          window.location.reload();
        } else {
          dispatch(
            actionCreator(
              CreateWeekPlanActionTypes.patch_UpdateWeekPlan.SUCCESS,
              response
            )
          );
        }
        callback();
      })
      .catch(function (error) {
        dispatch(
          actionCreator(CreateWeekPlanActionTypes.patch_UpdateWeekPlan.FAILURE)
        );
        console.log("error patch_UpdateWeekPlan ..", error);
      });
  };
};

export const GetMasterTasksByModuleAction = (id) => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.token;
    dispatch(
      actionCreator(CreateWeekPlanActionTypes.get_MasterTasksByModule.REQUEST)
    );
    fetch(`${GET_MASTER_TASKS_BY_MODULE}/${id}`, {
      method: "GET",
      headers: jsonApiHeader(token),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(
            actionCreator(
              CreateWeekPlanActionTypes.get_MasterTasksByModule.FAILURE,
              response
            )
          );
          showErrorMessage(response.Message);
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage(TOKEN_EXPIRY_MESSAGE);
          localStorage.clear();
          dispatch(
            actionCreator(
              CreateWeekPlanActionTypes.get_MasterTasksByModule.FAILURE,
              response
            )
          );
          window.location.reload();
        } else {
          dispatch(
            actionCreator(
              CreateWeekPlanActionTypes.get_MasterTasksByModule.SUCCESS,
              response
            )
          );
        }
      })
      .catch(function (error) {
        dispatch(
          actionCreator(
            CreateWeekPlanActionTypes.get_MasterTasksByModule.FAILURE
          )
        );
        console.log("error get_MasterTasksByModule ..", error);
      });
  };
};
