import { useEffect, useState } from "react";
import api from "utils/api";
import { API_URL } from "utils/constants";

export function useFetchProgressCard(weekId) {
  const [cardData, setCardData] = useState(null);

  useEffect(() => {
    async function fetch() {
      try {
        if (!weekId) {
          return null;
        }
        const { data } = await api(
          `${API_URL}/v1/weekplans/progress/${weekId}`,
          {
            method: "get",
          }
        );
        if (data) {
          setCardData(data);
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetch();
  }, [weekId]);

  return [cardData];
}
