/**
 @desc
 * Centralized unique action types for Login actions and reducers.
 */


import {
  actionCreator,
  API_URL,
  checkHttpStatus,
  createRequestActionTypes,
  jsonApiHeader,
  showSuccessMessage,
  showErrorMessage,
} from '../../../utils/utility';

export {
  jsonApiHeader,
  actionCreator,
  checkHttpStatus,
  showSuccessMessage,
  showErrorMessage,
};

export const GET_ALL_APPOINTMENTS = `${API_URL}/v1/patients/appointments`;
export const POST_SAVE_NOTE = `${API_URL}/v1/patients/notes`;
export const GET_ALL_WEEKPLANS = `${API_URL}/v1/weekplans/patients`;
export const GET_MODULE_DETAILS_BY_WEEKPLAN = `${API_URL}/v1/weekplans/modules`;
export const GET_TASK_DETAILS_BY_MODULE = `${API_URL}/v1/weekplans/modules/tasks`;
export const GET_TASK_DETAILS_BY_DATA_ENTRY = `${API_URL}/v1/weekplans/modules/tasks`;
export const DELETE_WEEKPLAN_BY_ID = `${API_URL}/v1/weekplans`;

export const UPDATE_WEIGHT_DATA = `${API_URL}/v1/health/updateHealthData`;

export const PatientActionTypes = {
  get_AllAppointments: createRequestActionTypes('GET_ALL_APPOINTMENTS'),
  post_SaveNote: createRequestActionTypes('POST_SAVE_NOTE'),
  get_AllWeekplans: createRequestActionTypes('GET_ALL_WEEKPLANS'),
  get_ModuleDetailsByWeekPlan: createRequestActionTypes('GET_MODULE_DETAILS_BY_WEEKPLAN'),
  get_TaskDetailsByModule: createRequestActionTypes('GET_TASK_DETAILS_BY_MODULE'),
  get_TaskDetailsByDataEntry: createRequestActionTypes('GET_TASK_DETAILS_BY_DATA_ENTRY'),
  delete_WeekPlanById: createRequestActionTypes('DELETE_WEEKPLAN_BY_ID'),
  update_Weight: createRequestActionTypes('UPDATE_WEIGHT'),
};