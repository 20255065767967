import { Box, Typography } from "@material-ui/core";
import React from "react";

const MetricComponent = ({ classes, metric, number, subText }) => {
  return (
    <>
      <Box className={classes.metricContainer}>
        <Box className={classes.barSubContainer}>
          <Typography className={classes.subTextMetric}>{metric}</Typography>
          <Typography className={classes.subTextMetric}>{number}</Typography>
        </Box>
        {Boolean(subText) && (
          <Typography className={classes.subTextMetric2}>{subText}</Typography>
        )}
      </Box>
      <Box className={classes.borderBottom2} />
    </>
  );
};
export default MetricComponent;
