/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { FormHelperText, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { Form, Formik } from "formik";
import Button from "@components/atoms/Button";
import lang from "assets/languages";
import { upsellingStyle } from "assets/jss/pages/components/upsellingStyle";
import { API_METHODS } from "network/constants";
import api from "utils/api";
import { END_POINT } from "network/constants";
import { apiResponses } from "network/constants";
import { notyf } from "utils/utility";
import {
  apiQueryParams,
  defaultCountryCode,
  upsellingFormFields,
} from "./constants";
import Loader from "@components/atoms/Loader";
import PhoneInput from "react-phone-input-2";
import { isValidPhoneNumber } from "libphonenumber-js";

const initialValues = {
  [upsellingFormFields.patientID]: "",
  [upsellingFormFields.mobileNumber]: "",
};
const UpsellingTab = (props) => {
  const classes = upsellingStyle();
  const { history } = props?.props;
  const [showError, setShowError] = useState({
    patientNotFoundError: "",
    invalidPhoneNumberError: false,
  });
  const [loading, setLoading] = useState(false);
  const [mobileNumberObj, setMobileNumberObj] = useState({
    countryCode: defaultCountryCode,
    mobileNumber: "",
  });

  const handlePhoneNumber = (mobileNumber, data) => {
    if (showError?.invalidPhoneNumberError || showError?.patientNotFoundError) {
      setShowError({
        invalidPhoneNumberError: false,
        patientNotFoundError: "",
      });
    }
    setMobileNumberObj({
      ...mobileNumberObj,
      mobileNumber: mobileNumber,
      countryCode: data?.dialCode,
    });
  };

  const handleSearch = async () => {
    if (!isValidPhoneNumber(`+${mobileNumberObj.mobileNumber}`)) {
      setShowError({ ...showError, invalidPhoneNumberError: true });
      return;
    }
    let mobileNumber = mobileNumberObj?.mobileNumber?.slice(
      mobileNumberObj?.countryCode?.length
    );
    setLoading(true);
    try {
      const res = await api(
        END_POINT.getPatientData(
          apiQueryParams.mobileNumber,
          mobileNumber,
          mobileNumberObj.countryCode
        ),
        {
          method: API_METHODS.GET,
        }
      );
      if (res.data.status === apiResponses.success) {
        setLoading(false);
        let patientData = res?.data?.data;
        let isWhatsappPatient = patientData?.isWhatsappPatient;
        let data = isWhatsappPatient ? null : res?.data?.data;
        if (!isWhatsappPatient) {
          history.push({
            pathname: `/counselor/edit_patient/${res?.data?.data?.id}`,
            state: {
              data: data,
              isEditable: patientData?.isEditable,
              mobileNumber: mobileNumberObj.mobileNumber,
              countryCode: mobileNumberObj.countryCode,
            },
          });
        } else {
          history.push({
            pathname: "/counselor/addPatient",
            state: {
              data: data,
              whatsAppPatientId: patientData?.id,
              mobileNumber: mobileNumberObj.mobileNumber,
              countryCode: mobileNumberObj.countryCode,
              isEditable: true,
            },
          });
        }

        notyf.success("Patient data fetched successfully");
      } else {
        setLoading(false);
        setShowError({
          ...showError,
          patientNotFoundError: res?.data?.message,
        });
      }
    } catch (e) {
      setLoading(false);
      setShowError({
        ...showError,
        patientNotFoundError: e?.message,
      });
    }
  };

  const handleGoToAddPatient = () => {
    history.push({
      pathname: "/counselor/addPatient",
      state: {
        mobileNumber: mobileNumberObj.mobileNumber,
        countryCode: mobileNumberObj.countryCode,
        isEditable: true,
      },
    });
  };

  return (
    <>
      {loading && <Loader open={true} loaderMessage="Fetching..." />}
      <div className={classes.container}>
        <Formik initialValues={initialValues}>
          {({ values, setFieldValue }) => {
            return (
              <Form>
                <PhoneInput
                  country={"in"}
                  inputStyle={{ width: "100%" }}
                  name="mobileNumber"
                  value={mobileNumberObj.mobileNumber}
                  onChange={handlePhoneNumber}
                  inputProps={{
                    name: "mobileNumber",
                    required: true,
                    autoFocus: true,
                  }}
                  isValid={(value, country) => {
                    return true;
                  }}
                />
                {showError?.invalidPhoneNumberError && (
                  <FormHelperText className={classes.phoneErrorText}>
                    {lang.phoneNumberIsNotValid}
                  </FormHelperText>
                )}

                <div>
                  <Button
                    className={classes.searchBtn}
                    onClick={() => {
                      handleSearch(values);
                    }}
                  >
                    {lang.search}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
        {showError?.patientNotFoundError && (
          <>
            <Typography className={classes.resultLabel}>
              {lang.result?.toUpperCase()}
            </Typography>
            <Typography className={classes.errorText}>
              {showError?.patientNotFoundError}
            </Typography>
            <Button
              md
              fullWidth
              className={classes.button}
              onClick={handleGoToAddPatient}
            >
              {lang.addPatient?.toUpperCase()}
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export default connect()(UpsellingTab);
