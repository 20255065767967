import React from "react";
import moment from "moment";

import Dropdown from "@components/molecules/Dropdown";
import { Grid } from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

import lang from "assets/languages";

const Header = (props) => {
  const { sensors, totalSensors, setSensorSummaryState, classes, handleClose } =
    props;

  const handleOnOption = (sensor) => {
    setSensorSummaryState((prevState) => ({
      ...prevState,
      selectedSensor: sensor,
    }));
  };

  const getOptions = () => {
    return sensors.map((sensor, index) => {
      const { id, sensorActivationDateInUTC, sensorExpirationDateInUTC } =
        sensor;

      const timeline = `${moment(sensorActivationDateInUTC).format(
        "DD MMM YYYY"
      )} - ${moment(sensorExpirationDateInUTC).format("DD MMM YYYY")}`;
      const value = `Sensor ${totalSensors - index} : ${timeline}`;

      return {
        ...sensor,
        key: id,
        value,
      };
    });
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <p className={classes?.headingText}>{lang.overallSummary}</p>
        </Grid>
        <Grid item xs={8} className={classes?.sensorDropdownContainer}>
          {sensors && (
            <Dropdown options={getOptions()} onOption={handleOnOption} />
          )}
        </Grid>
        <Grid item xs={2} className={classes?.closeIconContainer}>
          <CloseIcon
            className={classes?.closeIconStyles}
            onClick={handleClose}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Header;
