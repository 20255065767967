import { Box, Typography } from "@material-ui/core";
import React from "react";
import { useStyles } from "../styles";
import { Goal } from "./goal";
import { ValueIndicator } from "./valueIndicator";

const renderIndicator = (min, max, value, metric, color) => {
  if (!value) {
    return null;
  }

  if ((value >= min && value <= max) || (max === undefined && value >= min)) {
    return (
      <ValueIndicator
        style={{
          position: "absolute",
          left: "30%",
          top: -25,
        }}
        value={value}
        metric={metric}
        color={color}
      />
    );
  }

  return null;
};

export const ScoreMetric = ({
  heading,
  description,
  metric,
  lowRange,
  mediumRange,
  highRange,
  value,
}) => {
  const classes = useStyles();

  const renderRangeChart = () => {
    return (
      <Box className={classes.scoreMetricContainer}>
        <Goal
          className={`${classes.goalConatiner}`}
          styles={{ marginTop: "7px", borderRadius: "2px" }}
        >
          <Box
            className={`${classes.scoreRange}`}
            style={{ backgroundColor: "#14B8A6" }}
          >
            {renderIndicator(
              lowRange.min,
              lowRange.max,
              value,
              metric,
              "#14B8A6"
            )}
            <Typography className={classes.scoreMetricValue}>
              {`${lowRange.min}-${lowRange.max} ${metric}`}
            </Typography>
          </Box>
        </Goal>
        <Box width={"6px"} />
        <Box
          style={{ backgroundColor: "#FFBD00" }}
          className={`${classes.scoreRange}`}
        >
          {renderIndicator(
            mediumRange.min,
            mediumRange.max,
            value,
            metric,
            "#FFBD00"
          )}
          <Typography className={classes.scoreMetricValue}>
            {`${mediumRange.min}-${mediumRange.max} ${metric}`}
          </Typography>
        </Box>
        <Box width={"6px"} />
        <Box
          style={{ backgroundColor: "#FF5A5F" }}
          className={`${classes.scoreRange}`}
        >
          {renderIndicator(
            highRange.min,
            highRange.max,
            value,
            metric,
            "#FF5A5F"
          )}
          <Typography className={classes.scoreMetricValue}>
            {`> ${highRange.min} ${metric}`}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Box className={classes.scoreContainer}>
      <Box className={classes.scoreHeadingContainer}>
        <Typography className={classes.scoreHeading}>{heading}</Typography>
        <Typography className={classes.scoreDescription}>
          {description}
        </Typography>
      </Box>
      {renderRangeChart()}
    </Box>
  );
};
