import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  container: {
    padding: "50px",
  },
  buttonsBox: {
    textAlign: "right",
  },
  emoji: {
    fontSize: "40px"
  },
  text: {
    fontWeight: 400,
    fontSize: "24px",
    padding: "20px"
  }
}));

export default useStyles;