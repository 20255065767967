import { CreateWeekPlanActionTypes } from "../constants";

const initialState = {
  loading: false,
  allLevels: null,
  allCategories: null,
  allModules: null,
  createWeekPlan: null,
  updatedWeekPlan: null,
  masterTasksByModule: null,
};

const createWeekPlanReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case CreateWeekPlanActionTypes.get_AllLevels.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CreateWeekPlanActionTypes.get_AllLevels.SUCCESS:
      return {
        ...state,
        loading: false,
        allLevels: payload,
      };
    case CreateWeekPlanActionTypes.get_AllLevels.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CreateWeekPlanActionTypes.get_AllCategories.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CreateWeekPlanActionTypes.get_AllCategories.SUCCESS:
      return {
        ...state,
        loading: false,
        allCategories: payload,
      };
    case CreateWeekPlanActionTypes.get_AllCategories.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CreateWeekPlanActionTypes.get_AllModules.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CreateWeekPlanActionTypes.get_AllModules.SUCCESS:
      return {
        ...state,
        loading: false,
        allModules: payload,
      };
    case CreateWeekPlanActionTypes.get_AllModules.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CreateWeekPlanActionTypes.post_CreateWeekPlan.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CreateWeekPlanActionTypes.post_CreateWeekPlan.SUCCESS:
      return {
        ...state,
        loading: false,
        createWeekPlan: payload,
      };
    case CreateWeekPlanActionTypes.post_CreateWeekPlan.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CreateWeekPlanActionTypes.patch_UpdateWeekPlan.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CreateWeekPlanActionTypes.patch_UpdateWeekPlan.SUCCESS:
      return {
        ...state,
        loading: false,
        updatedWeekPlan: payload,
      };
    case CreateWeekPlanActionTypes.patch_UpdateWeekPlan.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CreateWeekPlanActionTypes.get_MasterTasksByModule.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CreateWeekPlanActionTypes.get_MasterTasksByModule.SUCCESS:
      return {
        ...state,
        loading: false,
        masterTasksByModule: payload,
      };
    case CreateWeekPlanActionTypes.get_MasterTasksByModule.FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default createWeekPlanReducers;
