/**
 @description
 * This file contains generic functions. 
 * They are here in utility because we are using them across the application.
 */
import history from "./history";
import { Notyf } from "notyf";
import "notyf/notyf.min.css";
import { setToken } from "./api";
import moment from "moment";
import { apiResponses, apiStatusCode } from "../network/constants";
export { API_URL } from "../utils/constants";

export const notyf = new Notyf({
  duration: 1000,
  position: {
    x: "right",
    y: "bottom",
  },
  types: [
    {
      type: "error",
      background: "indianred",
      duration: 3000,
      dismissible: true,
    },
  ],
});

export let STRAPI_API_URL = "https://strapi.onecare.co.in";

export const TOKEN_EXPIRY_MESSAGE = "Session has expired. Login again!";

export const drawerWidth = 360;

export const isMobile = () => window.innerWidth <= 600;

export function actionCreator(actionType, data) {
  return {
    type: actionType,
    payload: data,
  };
}

export function createRequestActionTypes(base) {
  return [
    apiResponses.request,
    apiResponses.success,
    apiResponses.failure,
  ].reduce((requestTypes, type) => {
    requestTypes[type] = `${base}_${type}`;
    return requestTypes;
  }, {});
}

export function handleLoginRedirect(response) {
  localStorage.setItem("LoggedOut", false);
  setToken(response.token);
  localStorage.setItem("userId", response.data.id);
}

export function handleLogoutRedirect() {
  localStorage.clear();
  localStorage.setItem("LoggedOut", true);
  showSuccessMessage("Logged out successfully!");
  window.location.pathname = "/auth/login-page";
}

export function handleRedirect(pathname, id) {
  history.push({
    pathname: pathname,
    state: {
      data: id,
    },
  });
}

export function checkHttpStatus(response) {
  if (response.status >= 200 && response.status < 204) {
    return response.json();
  } else if (response.status === 204) {
    return true;
  } else if (response.status >= 400 && response.status < 500) {
    return response.json();
  } else {
    var error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
}

export const jsonApiHeader = (accessToken) => {
  return {
    "Content-Type": "application/json",
    Authorization: accessToken ? `Bearer ${accessToken}` : "",
  };
};

export const showSuccessMessage = (message) => {
  notyf.success(message);
};

export const showErrorMessage = (message) => {
  notyf.error(message);
};

export const weekDays = () => {
  const days = {
    firstDay: "",
    lastDay: "",
  };
  days.firstDay = moment().weekday(1).format("YYYY-MM-DD");
  days.lastDay = moment().weekday(7).format("YYYY-MM-DD");
  return days;
};

export const monthDays = () => {
  const days = {
    firstDay: "",
    lastDay: "",
  };
  days.firstDay = moment().startOf("month").format("YYYY-MM-DD");
  days.lastDay = moment().endOf("month").format("YYYY-MM-DD");
  return days;
};

export const yearDays = () => {
  const days = {
    firstDay: "",
    lastDay: "",
  };
  days.firstDay = moment()
    .subtract(12, "months")
    .startOf("month")
    .format("YYYY-MM-DD");
  days.lastDay = moment().endOf("month").format("YYYY-MM-DD");
  return days;
};

export const getFileNameFromURL = (url) => {
  if (url) {
    const fileName = url.split("/").pop().replace(/[%\d]/g, "");
    return fileName;
  } else {
    return;
  }
};

export const regexObj = {
  onlyIntergers: /^[0-9\b]+$/,
};

export const isSuccess = (res) => {
  return res?.status === apiStatusCode[200];
};

export function base64toFile(base64String, filename, contentType) {
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: contentType });
  return new File([blob], filename, { type: contentType });
}

export function containsOnlyNumbers(str) {
  return /^[0-9]+$/.test(str);
}
