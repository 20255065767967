import React from "react";
import { Grid, Hidden, Typography, withStyles } from "@material-ui/core";
import Card from "@components/atoms/Card";
import CustomButton from "@components/atoms/Button";
import Logo from "../../assets/images/Logo";
import FormTextInput from "@components/molecules/FormTextInput/FormTextInput";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { LoginUserAction } from "./actions/LoginActions";
import { bindActionCreators } from "redux";
import authLayoutStyle from "../../assets/jss/layouts/authLayoutStyle";

const AuthenticationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().required("Required"),
});

const Authentication = (props) => {
  const { classes } = props;

  const handleLogin = (values) => {
    props.LoginUserAction(values);
  };

  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      validationSchema={AuthenticationSchema}
      onSubmit={handleLogin}
    >
      {() => {
        return (
          <Form className={classes.form}>
            <Hidden xsDown>
              <div className={classes.mainContainer}>
                <Card className={classes.card}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <div className={classes.logoContainer}>
                        <Logo width="200px" height="40px" />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2">
                        Enter your registered Email ID and Password:
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <FormTextInput
                        type="text"
                        name="email"
                        placeholder="Email ID"
                        typeOf="auth"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormTextInput
                        type="password"
                        name="password"
                        placeholder="Password"
                        typeOf="auth"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CustomButton
                        type="submit"
                        fullWidth
                        lg
                        className={classes.button}
                      >
                        Login
                      </CustomButton>
                    </Grid>
                  </Grid>
                </Card>
              </div>
            </Hidden>
            <Hidden smUp>
              <div className={classes.container}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <div className={classes.logo_container}>
                      <Logo width="200px" height="40px" />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">
                      Enter your registered Email ID and Password:
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormTextInput
                      type="text"
                      name="email"
                      placeholder="Email ID"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormTextInput
                      type="password"
                      name="password"
                      placeholder="Password"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomButton
                      type="submit"
                      fullWidth
                      lg
                      className={classes.button_mobile}
                    >
                      Login
                    </CustomButton>
                  </Grid>
                </Grid>
              </div>
            </Hidden>
          </Form>
        );
      }}
    </Formik>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        LoginUserAction,
      },
      dispatch
    ),
  };
}

export default connect(
  null,
  mapDispatchToProps
)(withStyles(authLayoutStyle)(Authentication));
