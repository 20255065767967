import { Grid, Typography } from "@material-ui/core";
import lang from "assets/languages";
import { CustomReportTable } from "@components/molecules/CustomReportTable";
import {
  getValueColor,
  summaryTableRowKeys,
} from "pages/CreateReport/components/SummaryTab/helpers";
import { useMemo } from "react";
import { connect } from "react-redux";
import { storeAndProgressTableKeys } from "../constant";
import {
  getPatientProgressTableData,
  getPatientTIRTableData,
  getWellnessTableData,
} from "../helper";
import { useStyles } from "../style";

const ProgressTable = (props) => {
  const classes = useStyles();
  const {
    patientProgress,
    summary: {
      glycemicScore: { value: glycemicValue },
      distressScore: { value: distressScore },
    },
    showPatientScore = true,
    showPatienProgressHeading = true,
  } = props;

  const scoreTableRowData = useMemo(
    () =>
      getPatientTIRTableData(
        patientProgress[storeAndProgressTableKeys.timeInRange],
        storeAndProgressTableKeys.timeInRange
      ),
    [patientProgress]
  );

  const wellnessTableRowData = useMemo(
    () =>
      getWellnessTableData(
        patientProgress[storeAndProgressTableKeys.distressScore],
        storeAndProgressTableKeys.distressScore
      ),
    [patientProgress]
  );

  const progressTableRowData = useMemo(
    () =>
      getPatientProgressTableData({
        [storeAndProgressTableKeys.timeInRange]:
          patientProgress[storeAndProgressTableKeys.timeInRange],
        [storeAndProgressTableKeys.averageGlucose]:
          patientProgress[storeAndProgressTableKeys.averageGlucose],
      }),
    [patientProgress]
  );

  return (
    <div>
      {showPatientScore && (
        <>
          <Typography className={classes.patientTableHeading}>
            {lang.patientScore}
          </Typography>
          <Grid container spacing={0}>
            <Grid
              item
              xs={wellnessTableRowData ? 6 : 12}
              md={wellnessTableRowData ? 6 : 12}
            >
              {scoreTableRowData && (
                <CustomReportTable
                  customHeading
                  tableColumnHeadings={[
                    <Typography className={classes.patientScoreHeading}>
                      {`${lang.glycemicScoreTIR}`}
                      <Typography
                        component={"span"}
                        style={{
                          marginLeft: "4px",
                          color: getValueColor(
                            summaryTableRowKeys.glycemicScore,
                            glycemicValue
                          ),
                        }}
                      >
                        {`${glycemicValue ? Math.round(glycemicValue) : "--"}%`}
                      </Typography>
                    </Typography>,
                  ]}
                  rowHeadingStyles={{
                    backgroundColor: "#D8D3E7",
                  }}
                  rowNameTextStyle={{ fontWeight: "700" }}
                  rowsData={scoreTableRowData}
                  colspan={2}
                />
              )}
            </Grid>
            {wellnessTableRowData && (
              <Grid item xs={6} md={6}>
                <CustomReportTable
                  customHeading
                  tableColumnHeadings={[
                    <Typography className={classes.patientScoreHeading}>
                      {`${lang.wellnessScoreGV}`}
                      <Typography
                        component={"span"}
                        style={{
                          marginLeft: "4px",
                          color: getValueColor(
                            summaryTableRowKeys.distressScore,
                            distressScore
                          ),
                        }}
                      >
                        {`${distressScore ? Math.round(distressScore) : "--"}%`}
                      </Typography>
                    </Typography>,
                  ]}
                  rowHeadingStyles={{
                    backgroundColor: "#D8D3E7",
                  }}
                  rowNameTextStyle={{ fontWeight: "700" }}
                  rowsData={wellnessTableRowData}
                  colspan={2}
                />
              </Grid>
            )}
          </Grid>
        </>
      )}
      {progressTableRowData && (
        <>
          {showPatienProgressHeading && (
            <Typography className={classes.patientTableHeading}>
              {lang.patientProgress}
            </Typography>
          )}
          <CustomReportTable
            tableColumnHeadings={[
              " ",
              "7th day",
              "14th day",
              "OneCare Interpretation",
            ]}
            tableHeadingStyles={{ backgroundColor: "#D8D3E7" }}
            rowHeadingStyles={{ backgroundColor: "#D8D3E7" }}
            rowNameTextStyle={{ fontWeight: "700" }}
            rowsData={progressTableRowData}
          />
        </>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    fourteenthDayReport: state.createReportReducer.fourteenthDayReport,
    seventhDayReportData: state.createReportReducer.seventhDayReportData,
    activeFileType: state.createReportReducer.activeFileType,
    patientProgress: state.createReportReducer.patientProgress,
    summary: state.createReportReducer.summary,
  };
}
export default connect(mapStateToProps)(ProgressTable);
