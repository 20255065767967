import React from "react";
import { createStyles, makeStyles } from "@material-ui/core";
import { TimePicker } from "@material-ui/pickers";
import { useField, useFormikContext } from "formik";
import { useState } from "react";

const FormTimePicker = ({ name, onTime, type,...otherProps }) => {
  const useStyles = makeStyles((theme) =>
    createStyles({
      root: {
        width: "100%",
        height: "45px",
        color: "#000000",
        border: `1px solid #D9D9D9`,
        borderRadius: 0,
        "& .MuiInputBase-root": {
          fontSize: "14px",
          color: "#000000",
          "& .MuiInputBase-input": {
            padding: "13px 14px",
          },
        },
        "& .MuiOutlinedInput-input": {
          padding: "0",
        },
        "& .MuiFormHelperText-contained": {
          marginLeft: "0px",
          marginRight: "0px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: `0px !important`,
          outline: "0 !important",
        },
        "& .MuiSelect-icon": {
          display: "none",
        },
        "& .MuiInputLabel-root": {
          // "&.MuiFormLabel-filled": {
          //   // opacity: 0,
          // },
          color: "#595959",
        },
      },
    })
  );

  const { setFieldValue } = useFormikContext();
  const [time, setTime] = useState(otherProps.value)
  const [field, meta] = useField(name);
  const classes = useStyles();

  const configDateTimePicker = {
    ...field,
    value: time,
    format: "hh:mm A",
    inputVariant: "outlined",
    autoOk: true,
    invalidDateMessage: "", //to be changed later
    disableToolbar: false,
    fullWidth: true,
    onChange: (value) => { if(type === "reminder"){
      setTime(value)
      onTime(value)}
      else{setFieldValue(name, value)}},
    // PopoverProps: {
    //   anchorOrigin: { horizontal: "right", vertical: "bottom" },
    //   transformOrigin: { horizontal: "right", vertical: "top" },
    // },
  };

  if (meta && meta.touched && meta.error) {
    configDateTimePicker.error = true;
    configDateTimePicker.helperText = meta.error;
  }

  return <TimePicker className={classes.root} {...configDateTimePicker} />;
};

export default FormTimePicker;
