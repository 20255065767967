const reportModalStyle = (theme) => ({
  uploadReports: {
    marginTop: "16px",
    padding: "0",
    width: "144px",
    height: "40px",
    marginRight: "16px",
  },
  uploadLabel: {
    width: "100%",
    cursor: "pointer",
  },
  icon: {
    color: "#BFBFBF",
    fontSize: "24px",
  },
  modalReportsSubheading: {
    marginTop: "16px",
    fontSize: "16px",
    fontWeight: 700,
  },
  reportsTable: {
    height: "400px",
  },
  reportsHeading: {
    display: "flex",
    padding: "16px",
    alignItems: "center",
    background: "#FAFAFA",
    marginTop: "8px",
  },
  reportsHeadingText: {
    fontSize: "14px",
    color: "#000000",
  },
  updatedAt: {
    marginLeft: "250px",
  },
  noReportsText: {
    color: "#595959",
    fontWeight: 400,
    fontSize: "14px",
    marginTop: "8px",
  },
  reportsModal: { width: "100%" },
  fileName: {
    flex: 0.35,
  },
  titleContainer: {
    padding: "16px",
    background: "#FAFAFA",
    margin: "8px 0px",
    fontSize: "16px"
  }
});

export default reportModalStyle;
