import { makeStyles } from "@material-ui/core";
import { graphHeight } from "@pages/Patient/components/CgmProgressDrawer/SummaryModal";

export const useStyles = makeStyles({
  rangeContainer: {
    borderRadius: "1px",
    width: "11px",
  },
  legendContainer: {
    justifyContent: "center",
    alignItems: "center",
    marginRight: "5px",
    height: graphHeight,
    display: "flex",
    flexDirection: "column",
  },
});
