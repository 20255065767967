import { useEffect, useRef, useState } from "react";
import CanvasJSReact from "../../../../canvasjs.react";
import { getGraphOptions, getIcon, getTargetzonePoints } from "./constant";
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Chart = ({
  graphData,
  width,
  height,
  goToActivity,
  graphActivities,
  axisFontSize,
  axisFontWeight,
  showXAxisTitle,
}) => {
  const [targetzoneData, setTargetzoneData] = useState([]);

  const myRef = useRef();

  const addMarkerImages = () => {
    let customMarkerNode = [];
    const chart = myRef.current.chart;

    let imageDiv = document.getElementsByClassName("imageContainer");
    for (let i = 0; i < imageDiv.length; i++) {
      imageDiv[i].innerHTML = "";
      imageDiv[i].removeAttribute("style");
    }

    let graphDataLastTime = new Date(graphData[0].x)?.getTime();
    let graphDataStartTime = new Date(
      graphData[graphData.length - 1]?.x
    ).getTime();
    //we don't give 24 hours data that's why we are activities inside graph data
    let validActivities = [];

    for (let i = 0; i < graphActivities?.length; i++) {
      let activityTime = new Date(graphActivities[i].startTime).getTime();
      if (
        graphDataLastTime < activityTime ||
        graphDataStartTime > activityTime
      ) {
        continue;
      }
      validActivities.push(graphActivities[i]);
      let div = document.createElement("div");
      div.onclick = () => onActivityClick(graphActivities[i]);
      let imageNode = document.createElement("IMG");
      imageNode.style.width = "16px";
      imageNode.style.height = "22px";
      imageNode.src = getIcon(graphActivities[i].type);
      div.style.position = "absolute";
      div.style.display = "block";
      div.style.background = "white";
      div.style.border = " 2px solid #D9D9D9";
      div.style.borderRadius = "100%";
      div.style.display = "flex";
      div.style.justifyContent = "center";
      div.style.alignItems = "center";
      div.style.alignContent = "center";
      div.style.width = div.style.height = "32px";
      div.append(imageNode);
      div.className = "imageContainer";
      chart.container.childNodes[0].append(div);
      customMarkerNode.push(div);
    }
    if (customMarkerNode.length > 0) {
      positionMarkerImages(customMarkerNode, validActivities);
    }
  };

  const positionMarkerImages = (customMarkerNode, validActivities) => {
    const chart = myRef.current.chart;
    customMarkerNode?.length > 0 &&
      customMarkerNode.map((imageNode, index) => {
        let date = new Date(validActivities[index].startTime);
        let pixelX = chart.axisX[0].convertValueToPixel(date);
        let pixelY = chart.axisY[0].convertValueToPixel(null);
        imageNode.style.top = pixelY - 20 + "px";
        imageNode.style.left = pixelX - 20 + "px";
      });
  };

  useEffect(() => {
    setTargetzoneData(getTargetzonePoints(graphData));
    addMarkerImages();
  }, [graphData, graphActivities]);

  function onActivityClick(e) {
    goToActivity && goToActivity(e.id);
  }

  return (
    <div>
      <CanvasJSChart
        options={getGraphOptions(
          width,
          height,
          targetzoneData,
          graphData,
          showXAxisTitle,
          axisFontSize,
          axisFontWeight
        )}
        ref={myRef}
      />
    </div>
  );
};

export default Chart;
