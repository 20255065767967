const ActivityListStyle = (theme) => ({
  container: {
    display: "flex",
    borderLeft: "1px solid #D9D9D9",
    marginLeft: "18px",
  },
  image: {
    marginTop: "8px",
    objectFit: "cover",
  },
  dateTimeContainer: { marginLeft: "18px" },
  dateTime: {
    marginLeft: "7px",
    color: "#595959",
    fontSize: "16px",
    verticalAlign: "middle",
  },
  description: {
    marginTop: "8px",
    fontSize: "16px",
    color: "#262626",
  },
  verticalTimeline: {
    borderLeft: "2px solid #2B377E",
    marginLeft: "26px",
    paddingLeft: "13px",
    paddingBottom: "10px",
  },
  icon: {
    color: "#BFBFBF",
    fontSize: "24px",
  },
  noImagePlaceholder: {
    marginTop: "8px",
    objectFit: "cover",
    width: "180px",
    height: "120px",
    background: "#D9D9D9",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#8C8C8C",
  },
  activityContainer: {
    overflow: "scroll",
    height: "500px",
    overflowX: "hidden",
    marginTop: "25px",
  },
  activityList: {
    marginBottom: "16px",
  },
  imageStyle: { width: "16px", height: "22px", verticalAlign: "middle" },
  activityListHeading: {
    fontSize: "24px",
    fontWeight: 700,
    marginLeft: "21px",
  },
  headingContainer: { display: "flex", justifyContent: "space-between" },
  listWidth: { width: "98%" },
  foodLog: {
    marginTop: "8px",
  },
});

export default ActivityListStyle;
