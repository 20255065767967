import { Typography } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Checkbox, Pagination } from "@mui/material";
import history from "utils/history";
import moment from "moment";
import api from "utils/api";
import { API_METHODS } from "network/constants";
import Loader from "@components/atoms/Loader";
import lang from "assets/languages";
import { columnHeading } from "pages/OpsAdminDashboard/constants";
import { END_POINT } from "network/constants";
import { deliveryListLimit } from "pages/OpsAdminDashboard/constants";
import { deliveryStatusTabsBYNumber } from "pages/OpsAdminDashboard/constants";
import { getDeliveryListStyles } from "./deliveryListStyle";
import {
  deliveryStatusTabs,
  deliveryTypePriority,
} from "@pages/OpsAdminDashboard/constants";
import { apiResponses } from "@network/constants";
import { notyf } from "@utils/utility";
import { isValidPhoneNumber } from "libphonenumber-js";

const intialDeliveryConfig = {
  deliveriesList: [],
  totalPages: 0,
};

const DeliveryList = ({
  tabValue,
  selectedDeliveries,
  setSelectedDeliveries,
  searchState,
  setSearchState,
}) => {
  const classes = getDeliveryListStyles();
  const [loading, setLoading] = useState(false);

  const handleSelectedDeliveries = (isChecked, deliveryDetails) => {
    let isDeliveryAlreadyPresent = selectedDeliveries.filter((delivery) => {
      return delivery?.id === deliveryDetails?.id;
    })?.length;

    if (isChecked) {
      if (!isDeliveryAlreadyPresent) {
        setSelectedDeliveries([...selectedDeliveries, deliveryDetails]);
      }
    } else {
      if (isDeliveryAlreadyPresent) {
        let updatedList = selectedDeliveries.filter((del) => {
          return del?.id !== deliveryDetails?.id;
        });

        setSelectedDeliveries(updatedList);
      }
    }
  };

  const renderColumnHeading = () => {
    return columnHeading.map((data) => {
      return (
        <th key={data} className={classes.header}>
          {data}
        </th>
      );
    });
  };

  const [deliveriesConfig, setDeliveriesConfig] =
    useState(intialDeliveryConfig);
  const [page, setPage] = useState(1);

  const { deliveriesList, totalPages } = deliveriesConfig;

  const renderRow = useCallback(() => {
    return deliveriesList?.map((delivery, index) => {
      let patientName = `${delivery?.patient?.firstName || ""} ${
        delivery?.patient?.lastName || ""
      }`;

      patientName =
        patientName?.length > 15
          ? patientName.substring(0, 12) + "..."
          : patientName;

      const handleTabClick = () => {
        history.push({
          pathname: `/opsAdmin/deliveryDetails/${delivery?.id}`,
          state: {
            deliveryId: delivery?.id,
          },
        });
      };
      return (
        <tr key={index} className={classes.rowContainer}>
          <td>
            <Checkbox
              onClick={(event) => {
                handleSelectedDeliveries(event.target.checked, delivery);
              }}
            />
          </td>
          <td className={classes.tableData} onClick={handleTabClick}>
            <div className={classes.justifyContentCenter}>
              <Typography
                className={
                  delivery?.deliveryMode?.name === deliveryTypePriority
                    ? `${classes.mainText} ${classes.darkOrange}`
                    : `${classes.mainText} ${classes.lightOrangeColor}`
                }
              >
                {delivery?.deliveryMode?.name || "--"}
              </Typography>
              <Typography className={classes.subText}>
                {delivery?.ETA || "--"}
              </Typography>
            </div>
          </td>
          <td className={classes.tableData} onClick={handleTabClick}>
            <div className={classes.justifyContentCenter}>
              <Typography className={classes.textAlignLeft}>
                {patientName}
              </Typography>
              <Typography className={classes.subText}>
                {delivery?.patient?.mobileNumber}
              </Typography>
            </div>
          </td>
          <td className={classes.tableData} onClick={handleTabClick}>
            <div className={classes.justifyContentCenter}>
              <Typography className={classes.mainText}>
                {`${delivery?.orderConfirmedBy?.firstName || ""} ${
                  delivery?.orderConfirmedBy?.lastName || ""
                }`}
              </Typography>
            </div>
          </td>
          <td
            className={classes.deliveryStatusTableData}
            onClick={handleTabClick}
          >
            <Typography
              className={`${classes.mainText} ${classes.deliveryStatusText}`}
            >
              {delivery?.status || "--"}
            </Typography>
          </td>
          <td className={classes.tableData} onClick={handleTabClick}>
            <div className={classes.justifyContentCenter}>
              <Typography
                className={`${classes.mainText} ${classes.lightOrangeColor}`}
              >
                {delivery?.addOnProduct?.productName || "--"}
              </Typography>
              <Typography
                className={classes.subText}
              >{`${lang.qty}: ${delivery?.quantity}`}</Typography>
            </div>
          </td>
          <td className={classes.tableData} onClick={handleTabClick}>
            <div className={classes.justifyContentCenter}>
              <Typography className={classes.textAlignLeft}>
                {delivery?.pincode || ""}
              </Typography>
            </div>
          </td>
          <td className={classes.tableData} onClick={handleTabClick}>
            <div className={classes.justifyContentCenter}>
              <Typography className={classes.mainText}>
                {moment(delivery?.createdAt).format("DD/MM/YYYY")}
              </Typography>
              <Typography className={classes.subText}>
                {moment(delivery?.createdAt).format("h:mm:ss a")}
              </Typography>
            </div>
          </td>
          <td className={classes.tableData} onClick={handleTabClick}>
            <div className={classes.justifyContentCenter}>
              <Typography className={classes.textAlignLeft}>
                {delivery?.deliveryVendor?.name || "--"}
              </Typography>
              <Typography
                className={
                  delivery?.trackingId
                    ? classes.trackingId
                    : classes.withoutTrackingId
                }
              >
                {delivery?.trackingId ? delivery?.trackingId : lang.notUpdated}
              </Typography>
            </div>
          </td>
        </tr>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabValue, deliveriesList, selectedDeliveries]);

  useEffect(() => {
    setPage(1);
  }, [tabValue]);

  const fetchAllDeliveries = useCallback(
    async (isFilterByMobileNumber) => {
      try {
        if (isFilterByMobileNumber) {
          if (!isValidPhoneNumber(`+${searchState.mobileNumber}`)) {
            notyf.error("Invalid Mobile Number");
            setSearchState({ ...searchState, searchDelivery: false });
            return;
          }
        }
        let mobileNumber = searchState?.mobileNumber?.slice(
          searchState?.countryCode?.length
        );
        setLoading(true);
        const res = await api(
          END_POINT.getDeliveries(
            page - 1,
            deliveryListLimit,
            deliveryStatusTabsBYNumber[tabValue],
            tabValue === deliveryStatusTabs.priority
              ? deliveryTypePriority
              : null,
            isFilterByMobileNumber ? mobileNumber : null
          ),
          {
            method: API_METHODS.GET,
          }
        );
        setLoading(false);
        if (res.data.status === apiResponses.success) {
          if (!res?.data?.data?.length) {
            notyf.error("No results found");
            setDeliveriesConfig(intialDeliveryConfig);
          } else {
            setDeliveriesConfig({
              deliveriesList: res?.data?.data,
              totalPages:
                res?.data?.count > 0
                  ? Math.ceil(res?.data?.count / deliveryListLimit)
                  : res?.data?.count,
            });
            notyf.success("Succefully fetched deliveries");
          }
        } else {
          notyf.error(res.data?.message);
          setDeliveriesConfig({ ...intialDeliveryConfig });
        }
      } catch (e) {
        setLoading(false);
        console.error("failed to fetch deliveries", e);
      }
    },
    [page, tabValue, searchState?.searchDelivery]
  );

  useEffect(() => {
    if (searchState?.searchDelivery) {
      setPage(1);
    }
    fetchAllDeliveries(searchState?.searchDelivery);
  }, [page, tabValue, searchState?.searchDelivery]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <>
      {loading && <Loader open={true} loaderMessage="Fetching..." />}
      <div className={classes.mainContainer}>
        <table className={classes.tableContainer}>
          <thead className={classes.tableHeader}>
            <tr>{renderColumnHeading()}</tr>
          </thead>
          <tbody className={{}}>{renderRow()}</tbody>
        </table>
      </div>
      <div className={classes.paginationContainer}>
        <Pagination
          className={classes.pagination}
          page={page}
          count={totalPages}
          onChange={handleChange}
        />
      </div>
    </>
  );
};

export default connect()(DeliveryList);
