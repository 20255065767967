const { makeStyles } = require("@material-ui/core");

export const deliveryDetailsStyle = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    maxWidth: "1200px",
    margin: "auto",
  },
  boxContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "8px",
  },
  nameBox: {
    margin: "16px 0px",
    backgroundColor: "#5BABCC",
    width: "150px",
    height: "150px",
    borderRadius: "75px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  firstNameIcon: {
    fontSize: "74px",
    color: "#2B377E",
  },
  fullNameContainer: {
    fontSize: "32px",
    fontWeight: "500",
    marginLeft: "20px",
  },
  container: { marginTop: "5px" },
  detailsContainer: { width: "100%", marginLeft: "20px" },
  subContainer: {
    display: "flex",
    width: "100%",
    marginLeft: "10px",
    padding: "5px",
  },
  subContainerFirstElement: {
    display: "flex",
    width: "220px",
    justifyContent: "space-between",
  },
  subContainerNextElement: {
    display: "flex",
    marginLeft: "10px",
    width: "250px",
    justifyContent: "space-between",
  },
  dataText: {
    marginLeft: "10px",
    fontSize: "14px",
    color: "#5A5A5A",
    alignContent: "center",
    alignItems: "center",
  },
  addressContainer: {
    display: "flex",
    width: "380px",
    alignContent: "center",
    alignItems: "center",
  },
  counselorRemarksContainer: {
    display: "flex",
    width: "380px",
    alignContent: "center",
    alignItems: "center",
    marginLeft: "20px",
  },
  deliveryFormContainer: {
    alignSelf: "center",
    margin: "auto",
    width: "800px",
    justifyContent: "center",
  },
  formTitleContainer: {
    fontSize: "25px",
    fontWeight: "500",
    paddingTop: "5px",
    borderTop: "2px solid #EEEEEE",
  },
  card: {
    boxShadow: 1,
    padding: "8px",
    borderRadius: "8px",
    backgroundColor: "#FFFFFF",
    border: "1px solid #D9D9D9",
    marginBottom: "100px",
    width: "500px",
    marginLeft: "30px",
  },
  box1: {
    fontSize: "16px",
    padding: "5px",
  },
  remarksContainer: {
    overflow: "scroll",
    maxHeight: "100px",
  },
  remarksRow: {
    marginBottom: "8px",
    padding: "10px",
    border: "1px solid #D9D9D9",
  },
  fieldContainer: {
    display: "flex",
    marginTop: "12px",
  },
  fieldSubContainer: {
    display: "flex",
    alignItems: "center",
    width: "340px",
    border: "1px solid #dee1e3",
    borderRadius: "8px",
  },
  leftContainer: {
    height: "50px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    backgroundColor: "#F9FAFB",
    width: "150px",
    borderRight: "1px solid #dee1e3",
    borderRadius: "8px",
  },
  deliveryModeDropDownContainer: {
    height: "50px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    backgroundColor: "white",
    width: "240px",
    borderRadius: "0px 8px 8px 0px",
    boxSizing: "border-box",
    padding: "5px",
    color: "#6B7280",
    fontSize: "14px",
  },
  containerLabel: { color: "#6B7280", fontSize: "14px" },
  datePickerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    height: "50px",
    borderRadius: "0px 8px 8px 0px",
  },
  marginLeftEighty: { marginLeft: "80px" },
  datePicker: {
    textAlign: "center",
    alignItems: "center",
    alignContent: "center",
    width: "150px",
    border: "none",
  },
  topLabels: {
    padding: "2px 2px 5px 0px",
    fontWeight: "600",
    fontSize: "14px",
  },
  editableLeftContainer: {
    height: "50px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    backgroundColor: "white",
    width: "200px",
    borderRight: "1px solid #dee1e3",
    borderRadius: "8px",
    boxSizing: "border-box",
    padding: "5px",
  },
  border: {
    border: "1px solid #dee1e3",
  },
  trackingTextInputContainer: {
    backgroundColor: "white",
    width: "220px",
    borderRadius: "0px 8px 8px 0px",
  },
  trackingTextInput: {
    height: "50px",
    width: "600px",
    maxWidth: "100%",
    maxHeight: "80px",
    border: "none",
    whiteSpace: "normal",
    paddingLeft: "10px",
  },
  marginLeftFifty: {
    marginLeft: "50px",
  },
  remarksInputContainer: {
    display: "flex",
    alignItems: "center",
    width: "720px",
    border: "1px solid #dee1e3",
    marginBottom: "10px",
    borderRadius: "8px",
    marginTop: "20px",
  },
  remarksTextInputContainer: {
    backgroundColor: "white",
    width: "600px",
    height: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "0px 8px 8px 0px",
    padding: "5px",
  },
  remarksTextInput: {
    height: "50px",
    width: "600px",
    maxWidth: "100%",
    maxHeight: "80px",
    border: "none",
    whiteSpace: "normal",
  },
  bottomContainer: { display: "flex", marginTop: "20px" },
  buttonContainer: {
    marginLeft: "20px",
    marginTop: "80px",
  },
  marginLeftTwenty: { marginLeft: "20px" },
  dropdown: {
    color: "#6B7280",
    fontSize: "14px",
  },
}));
