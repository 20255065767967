import React from "react";
import { Box, Grid, Modal, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import CustomButtons from "@components/atoms/Button";
import Line from "@components/atoms/Line";

import useStyles from "./errorInfoModalStyles";
import ErrorItem from "./ErrorItem";

import lang from "assets/languages";

const ErrorInfoModal = (props) => {
  const { showModal, closeModal, data } = props;

  const classes = useStyles();

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box className={classes?.errorModal}>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <Typography className={classes.heading}>
              {lang.mandatoryItems}
            </Typography>
          </Grid>

          <Grid item xs={3} className={classes?.closeIconContainer}>
            <CloseIcon
              className={classes?.closeIconStyles}
              onClick={closeModal}
            />
          </Grid>
        </Grid>
        <Line />
        <div className={classes.container}>
          {data?.map((item, index) => {
            const { heading, errors } = item;

            return (
              <ErrorItem
                key={`ErrorItem-${index}`}
                heading={heading}
                errors={errors}
              />
            );
          })}
        </div>
        <Line />
        <div className={classes?.footer}>
          <CustomButtons md onClick={closeModal}>
            {lang.ok}
          </CustomButtons>
        </div>
      </Box>
    </Modal>
  );
};

const arePropsEqual = (prevProps, nextProps) => {
  return prevProps.showModal === nextProps.showModal;
};

const MemoizedErrorInfoModal = React.memo(ErrorInfoModal, arePropsEqual);

export default MemoizedErrorInfoModal;
