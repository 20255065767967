import React from "react";
import { Box } from "@material-ui/core";
import { InsightCard } from "./insightCard";
import { useStyles } from "./styles";

export const GraphInsightMask = ({ data, styles = {}, grpahWidth }) => {
  const classes = useStyles();

  return (
    <Box
      style={{ ...styles, width: grpahWidth }}
      className={classes.graphMaskContainer}
    >
      {data.map((timeData) => {
        return (
          <Box key={timeData.key} className={classes.grapTimelyBox}>
            <InsightCard {...timeData} />
          </Box>
        );
      })}
    </Box>
  );
};
