import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import reduxThunk from "redux-thunk";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import RootReducer from "./reducers";
import Routes from "@routers/routesComponent";
import history from "@utils/history";
import theme from "@theme/theme";
import "./assets/styles/styles.scss";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import {
  createStateSyncMiddleware,
  initMessageListener,
} from "redux-state-sync";
import { StoreProviderService } from "@services/storeProviderService";

const reduxStateSyncConfig = {};

const store = createStore(
  RootReducer,
  applyMiddleware(reduxThunk, createStateSyncMiddleware(reduxStateSyncConfig))
);

StoreProviderService.init(store);
initMessageListener(store);

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Provider store={store}>
        <ToastContainer />
        <Router history={history}>
          <Routes />
        </Router>
      </Provider>
    </MuiPickersUtilsProvider>
  </MuiThemeProvider>,
  document.getElementById("root")
);
