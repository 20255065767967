import React from "react";
import {
  Typography,
  LinearProgress,
  withStyles,
  Grid,
  Box,
} from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";
import clsx from "clsx";
import progressStyle from "../../../../assets/jss/pages/progressStyle";

import starstruck_icon from "../../../../assets/icons/starstruck.svg";
import smilingIcon from "../../../../assets/icons/smiling.svg";
import smileIcon from "../../../../assets/icons/smile.svg";
import pending_icon from "../../../../assets/icons/pending.svg";
import completed_icon from "../../../../assets/icons/completed.svg";

//WeekPlan Card Icons
import diabetes_learn from "../../../../assets/icons/diabetes_learn.svg";
import glucometer_icon from "../../../../assets/icons/diabetes_task.svg";
import diabetes_challenge from "../../../../assets/icons/diabetes_challenge.svg";
import diabetes_management_learn from "../../../../assets/icons/diabetes_management_learn.svg";
import diabetes_management_task from "../../../../assets/icons/diabetes_management_task.svg";
import diabetes_management_challenge from "../../../../assets/icons/diabetes_management_challenge.svg";
import { Icon1, Icon2, Icon3 } from "../../../../assets/icons";
import lifestyle_task from "../../../../assets/icons/Man In Lotus Position.svg";
import lifestyle_challenge from "../../../../assets/icons/Man In Lotus Position(1).svg";
import sleep_learn from "../../../../assets/icons/sleep_learn.svg";
import sleep_task from "../../../../assets/icons/sleep_task.svg";
import sleep_challenge from "../../../../assets/icons/sleep_challenge.svg";
import progress_learn from "../../../../assets/icons/progress_learn.svg";
import progress_task from "../../../../assets/icons/progress_task.svg";
import progress_challenge from "../../../../assets/icons/progress_challenge.svg";
import exercise_learn from "../../../../assets/icons/exercise_learn.svg";
import exercise_task from "../../../../assets/icons/exercise_task.svg";
import exercise_challenge from "../../../../assets/icons/exercise_challenge.svg";
import food_learn from "../../../../assets/icons/food_learn.svg";
import food_task from "../../../../assets/icons/food_task.svg";
import food_challenge from "../../../../assets/icons/food_challenge.svg";
import ChevRight from "../../../../assets/icons/chev-right.svg";
import { useFetchProgressCard } from "./useFetchProgressCard";
import {
  LineChart,
  BarChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  // Tooltip,
  // Legend,
  ResponsiveContainer,
  ReferenceLine,
  Label,
  CartesianGrid,
} from "recharts";
import moment from "moment";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 16,
    borderRadius: 8,
  },
  colorPrimary: {
    backgroundColor: "#D9D9D9",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#389E0D",
  },
}))(LinearProgress);

const Progress = (props) => {
  const { classes, weekProgress, progress, view, isCGMSensorAvailable } = props;
  const [progressCard] = useFetchProgressCard(weekProgress?.id);

  return (
    <>
      <div className={classes.topContainer}>
        {isCGMSensorAvailable && (
          <Box
            className={classes.containerBox}
            onClick={props.handleCgmProgressDrawer}
            style={{
              backgroundColor: view === "CGM_PROGRESS" ? "#F4FBFD" : "white",
            }}
          >
            <Box>
              <Typography className={classes.weekPlanText}>
                CGM Progress
              </Typography>
            </Box>
            <Box className={classes.textContainer}>
              <img src={ChevRight} height="25px" width="7px" />
            </Box>
          </Box>
        )}
        <Box className={classes.borderBottom} />

        {weekProgress && (
          <>
            {" "}
            <Box
              className={classes.containerBox}
              onClick={() => props.handleProgress("WEEKPLAN_PROGRESS")}
              style={{
                backgroundColor:
                  view === "WEEKPLAN_PROGRESS" ? "#F4FBFD" : "white",
              }}
            >
              <Box>
                <Typography className={classes.weekPlanText}>
                  WeekPlan Progress
                </Typography>
                <Typography className={classes.weekPlanSubText}>
                  {weekProgress?.phase ?? ""}
                </Typography>
              </Box>
              <Box className={classes.textContainer}>
                <Typography className={classes.chevText}>
                  {progressCard?.totalWeekplanScore}/
                  {progressCard?.maximumWeekplanPoints}
                </Typography>
                <img src={ChevRight} height="25px" width="7px" />
              </Box>
            </Box>
            <Box className={classes.borderBottom} />
          </>
        )}

        <Box
          className={classes.containerBox}
          onClick={props.handleOpenBloodSugarProgressDrawer}
          style={{
            backgroundColor: view === "bp_progress" ? "#F4FBFD" : "white",
          }}
        >
          <Box>
            <Typography className={classes.weekPlanText}>
              Fasting Blood Sugar Level
            </Typography>
            <Typography className={classes.weekPlanSubText}>
              Weekly Trend
            </Typography>
          </Box>
          <Box className={classes.textContainer}>
            {/* <Typography className={classes.chevText}>Avg. 99 mg/dL</Typography> */}
            <img src={ChevRight} height="25px" width="7px" />
          </Box>
        </Box>
        <Box className={classes.borderBottom} />

        <Box
          className={classes.containerBox}
          onClick={props.handleOpenPostBPSProgressDrawer}
          style={{
            backgroundColor: view === "PostBP_progress" ? "#F4FBFD" : "white",
          }}
        >
          <Box>
            <Typography className={classes.weekPlanText}>
              Post meal Blood Sugar Level
            </Typography>
            <Typography className={classes.weekPlanSubText}>
              Weekly Trend
            </Typography>
          </Box>
          <Box className={classes.textContainer}>
            {/* <Typography className={classes.chevText}>Avg. 105 mg/dL</Typography> */}
            <img src={ChevRight} height="25px" width="7px" />
          </Box>
        </Box>
        <Box className={classes.borderBottom} />

        <Box
          className={classes.containerBox}
          onClick={props.handleOpenWeightProgressDrawer}
          style={{
            backgroundColor: view === "weight_progress" ? "#F4FBFD" : "white",
          }}
        >
          <Box>
            <Typography className={classes.weekPlanText}>Weight</Typography>
            <Typography className={classes.weekPlanSubText}>
              Weekly Trend
            </Typography>
          </Box>
          <Box className={classes.textContainer}>
            {/* <Typography className={classes.chevText}>Avg. 70 Kg</Typography> */}
            <img src={ChevRight} height="25px" width="7px" />
          </Box>
        </Box>
        <Box className={classes.borderBottom} />

        <Box
          className={classes.containerBox}
          onClick={props.handleOpenHb1acProgressDrawer}
          style={{
            backgroundColor: view === "Hb1ac_progress" ? "#F4FBFD" : "white",
          }}
        >
          <Box>
            <Typography className={classes.weekPlanText}>HbA1C</Typography>
            <Typography className={classes.weekPlanSubText}>
              Weekly Trend
            </Typography>
          </Box>
          <Box className={classes.textContainer}>
            {/* <Typography className={classes.chevText}>Avg. 5%</Typography> */}
            <img src={ChevRight} height="25px" width="7px" />
          </Box>
        </Box>
        <Box className={classes.borderBottom} />

        <Box
          style={{
            backgroundColor: view === "food_progress" ? "#F4FBFD" : "white",
          }}
          className={classes.containerBox}
          onClick={props.handleOpenFoodProgressDrawer}
        >
          <Box>
            <Typography className={classes.weekPlanText}>
              Diet taken in this week plan
            </Typography>
            <Typography className={classes.weekPlanSubText}>
              Photo timeline
            </Typography>
          </Box>
          <Box className={classes.textContainer}>
            <Typography className={classes.chevText}></Typography>
            <img src={ChevRight} height="25px" width="7px" />
          </Box>
        </Box>
        <Box className={classes.borderBottom} />

        <Box
          className={classes.containerBox}
          onClick={props.handleOpenPhysicalProgressDrawer}
          style={{
            backgroundColor: view === "Physical_progress" ? "#F4FBFD" : "white",
          }}
        >
          <Box>
            <Typography className={classes.weekPlanText}>
              Physical Activity
            </Typography>
            <Typography className={classes.weekPlanSubText}>
              Weekly Trend
            </Typography>
          </Box>
          <Box className={classes.textContainer}>
            {/* <Typography className={classes.chevText}>
              Avg. 2 sessions
            </Typography> */}
            <img src={ChevRight} height="25px" width="7px" />
          </Box>
        </Box>
        <Box className={classes.borderBottom} />

        <Box
          className={classes.containerBox}
          onClick={props.handleOpenSleepProgressDrawer}
          style={{
            backgroundColor: view === "Sleep_progress" ? "#F4FBFD" : "white",
          }}
        >
          <Box>
            <Typography className={classes.weekPlanText}>Sleep</Typography>
            <Typography className={classes.weekPlanSubText}>
              Weekly Trend
            </Typography>
          </Box>
          <Box className={classes.textContainer}>
            {/* <Typography className={classes.chevText}>Avg. 8 hrs</Typography> */}
            <img src={ChevRight} height="25px" width="7px" />
          </Box>
        </Box>
        <Box className={classes.borderBottom} />

        <Box
          className={classes.containerBox}
          onClick={props.handleOpenStepProgressDrawer}
          style={{
            backgroundColor: view === "step_progress" ? "#F4FBFD" : "white",
          }}
        >
          <Box>
            <Typography className={classes.weekPlanText}>Steps</Typography>
            <Typography className={classes.weekPlanSubText}>
              Weekly Trend
            </Typography>
          </Box>
          <Box className={classes.textContainer}>
            {/* <Typography className={classes.chevText}>Avg. 8793</Typography> */}
            <img src={ChevRight} height="25px" width="7px" />
          </Box>
        </Box>
        <Box className={classes.borderBottom} />
        {/* {weekProgress ? (
          <div className={classes.subContainer}>
            <div style={{ position: "relative" }}>
              <div
                className={classes.headerContainer}
                onClick={() => props.handleGoToWeekPlan(weekProgress.id)}
              >
                <div>
                  <Typography className={classes.text}>
                    {weekProgress.totalTasksDone} tasks done for this week
                  </Typography>
                  <Typography className={classes.text1}>
                    {`${moment(weekProgress.from).format(
                      "ddd DD MMM"
                    )} - ${moment(weekProgress.to).format("ddd DD MMM")}`}
                  </Typography>
                </div>
                <ChevronRight className={classes.icon} />
              </div>
              {(weekProgress?.totalTasksDone / weekProgress.totalTasks) * 100 >
                75 && (
                <img
                  src={starstruck_icon}
                  alt="star"
                  height="35px"
                  width="35px"
                  style={{
                    position: "absolute",
                    right: 0,
                    bottom: -10,
                    zIndex: 1,
                  }}
                />
              )}
              {(weekProgress?.totalTasksDone / weekProgress.totalTasks) * 100 >
                50 &&
                (weekProgress?.totalTasksDone / weekProgress.totalTasks) *
                  100 <=
                  75 && (
                  <img
                    src={smilingIcon}
                    alt="star"
                    height="35px"
                    width="35px"
                    style={{
                      position: "absolute",
                      right: 0,
                      bottom: -10,
                      zIndex: 1,
                    }}
                  />
                )}
              {(weekProgress?.totalTasksDone / weekProgress.totalTasks) * 100 >
                0 &&
                (weekProgress?.totalTasksDone / weekProgress.totalTasks) *
                  100 <=
                  50 && (
                  <img
                    src={smileIcon}
                    alt="star"
                    height="35px"
                    width="35px"
                    style={{
                      position: "absolute",
                      right: 0,
                      bottom: -10,
                      zIndex: 1,
                    }}
                  />
                )}
              <BorderLinearProgress
                variant="determinate"
                value={
                  (weekProgress.totalTasksDone / weekProgress.totalTasks) * 100
                }
              />
            </div>
            <div
              className={classes.sub_container}
              style={{
                height: "170px",
                overflowY:
                  weekProgress.moduleType.length > 8 ? "scroll" : "hidden",
                overflowX: "hidden",
                padding: "5px 0",
              }}
            >
              <Grid container spacing={1}>
                {weekProgress?.moduleType?.length > 0 &&
                  weekProgress?.moduleType?.map((module, index) => {
                    return (
                      <Grid item xs={3} key={index}>
                        <div
                          className={clsx(classes.container1, {
                            [classes.pending]: module.pending,
                            [classes.completed]: !module.pending,
                          })}
                          style={{
                            marginBottom: "15px",
                          }}
                        >
                          {module.pending ? (
                            <img
                              height="15px"
                              width="15px"
                              src={pending_icon}
                              alt="salad"
                              style={{
                                position: "absolute",
                                display: "flex",
                                bottom: 0,
                                right: 0,
                                margin: "-3.3px",
                              }}
                            />
                          ) : !module.pending ? (
                            <img
                              height="15px"
                              width="15px"
                              src={completed_icon}
                              alt="salad"
                              style={{
                                position: "absolute",
                                display: "flex",
                                bottom: 0,
                                right: 0,
                                margin: "-3.3px",
                              }}
                            />
                          ) : (
                            ""
                          )}
                          {module.moduleType === "Learn" &&
                            module.moduleCategory === "Diabetes Education" && (
                              <img
                                height="46px"
                                width="50px"
                                src={diabetes_learn}
                                alt="salad"
                              />
                            )}
                          {module.moduleType === "Log" &&
                            module.moduleCategory === "Diabetes Education" && (
                              <img
                                height="46px"
                                width="50px"
                                src={glucometer_icon}
                                alt="salad"
                              />
                            )}
                          {module.moduleType === "To Do" &&
                            module.moduleCategory === "Diabetes Education" && (
                              <img
                                height="46px"
                                width="50px"
                                src={diabetes_challenge}
                                alt="salad"
                              />
                            )}
                          {module.moduleType === "Learn" &&
                            module.moduleCategory === "Diabetes Management" && (
                              <img
                                height="46px"
                                width="50px"
                                src={diabetes_management_learn}
                                alt="salad"
                              />
                            )}
                          {module.moduleType === "Log" &&
                            module.moduleCategory === "Diabetes Management" && (
                              <img
                                height="46px"
                                width="50px"
                                src={diabetes_management_task}
                                alt="salad"
                              />
                            )}
                          {module.moduleType === "To Do" &&
                            module.moduleCategory === "Diabetes Management" && (
                              <img
                                height="46px"
                                width="50px"
                                src={diabetes_management_challenge}
                                alt="salad"
                              />
                            )}
                          {module.moduleType === "Learn" &&
                            module.moduleCategory === "Lifesyle Management" && (
                              <img
                                height="46px"
                                width="50px"
                                src={Icon1}
                                alt="salad"
                              />
                            )}
                          {module.moduleType === "Log" &&
                            module.moduleCategory === "Lifesyle Management" && (
                              <img
                                height="46px"
                                width="50px"
                                src={Icon2}
                                alt="salad"
                              />
                            )}
                          {module.moduleType === "To Do" &&
                            module.moduleCategory === "Lifesyle Management" && (
                              <img
                                height="46px"
                                width="50px"
                                src={Icon3}
                                alt="salad"
                              />
                            )}
                          {module.moduleType === "Learn" &&
                            module.moduleCategory === "Behaviour Change" && (
                              <img
                                height="46px"
                                width="50px"
                                src={Icon1}
                                alt="salad"
                              />
                            )}
                          {module.moduleType === "Log" &&
                            module.moduleCategory === "Behaviour Change" && (
                              <img
                                height="46px"
                                width="50px"
                                src={Icon2}
                                alt="salad"
                              />
                            )}
                          {module.moduleType === "To Do" &&
                            module.moduleCategory === "Behaviour Change" && (
                              <img
                                height="46px"
                                width="50px"
                                src={Icon3}
                                alt="salad"
                              />
                            )}
                          {module.moduleType === "Learn" &&
                            module.moduleCategory === "Sleep Management" && (
                              <img
                                height="46px"
                                width="50px"
                                src={sleep_learn}
                                alt="salad"
                              />
                            )}
                          {module.moduleType === "Log" &&
                            module.moduleCategory === "Sleep Management" && (
                              <img
                                height="46px"
                                width="50px"
                                src={sleep_task}
                                alt="salad"
                              />
                            )}
                          {module.moduleType === "To Do" &&
                            module.moduleCategory === "Sleep Management" && (
                              <img
                                height="46px"
                                width="50px"
                                src={sleep_challenge}
                                alt="salad"
                              />
                            )}
                          {module.moduleType === "Learn" &&
                            module.moduleCategory === "Progress Management" && (
                              <img
                                height="46px"
                                width="50px"
                                src={progress_learn}
                                alt="salad"
                              />
                            )}
                          {module.moduleType === "Log" &&
                            module.moduleCategory === "Progress Management" && (
                              <img
                                height="46px"
                                width="50px"
                                src={progress_task}
                                alt="salad"
                              />
                            )}
                          {module.moduleType === "To Do" &&
                            module.moduleCategory === "Progress Management" && (
                              <img
                                height="46px"
                                width="50px"
                                src={progress_challenge}
                                alt="salad"
                              />
                            )}
                          {module.moduleType === "Learn" &&
                            module.moduleCategory === "Exercise" && (
                              <img
                                height="46px"
                                width="50px"
                                src={exercise_learn}
                                alt="salad"
                              />
                            )}
                          {module.moduleType === "Log" &&
                            module.moduleCategory === "Exercise" && (
                              <img
                                height="46px"
                                width="50px"
                                src={exercise_task}
                                alt="salad"
                              />
                            )}
                          {module.moduleType === "To Do" &&
                            module.moduleCategory === "Exercise" && (
                              <img
                                height="46px"
                                width="50px"
                                src={exercise_challenge}
                                alt="salad"
                              />
                            )}
                          {module.moduleType === "Learn" &&
                            module.moduleCategory === "Food Education" && (
                              <img
                                height="46px"
                                width="50px"
                                src={food_learn}
                                alt="salad"
                              />
                            )}
                          {module.moduleType === "Log" &&
                            module.moduleCategory === "Food Education" && (
                              <img
                                height="46px"
                                width="50px"
                                src={food_task}
                                alt="salad"
                              />
                            )}
                          {module.moduleType === "To Do" &&
                            module.moduleCategory === "Food Education" && (
                              <img
                                height="46px"
                                width="50px"
                                src={food_challenge}
                                alt="salad"
                              />
                            )}
                        </div>
                      </Grid>
                    );
                  })}
              </Grid>
            </div>
          </div>
        ) : (
          <Typography>No data for current week</Typography>
        )}
        {progress.weight && (
          <div className={classes.chart_container}>
            <div
              className={classes.chart_sub_container}
              onClick={props.handleOpenWeightProgressDrawer}
            >
              <Typography className={classes.text}>Weight</Typography>
              <ChevronRight className={classes.icon} />
            </div>
            <div className={classes.chart_sub_container1}>
              <Typography className={classes.sub_text}>
                {progress.weight.average
                  ? `${
                      progress.weight.average == "NaN"
                        ? "No Data"
                        : "Avg." + progress.weight.average + " kg"
                    }`
                  : "No Data"}
              </Typography>
              <Typography className={classes.sub_text1}>
                Last 30 days
              </Typography>
            </div>
            <div className={classes.weight_container}>
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  width={300}
                  height={144}
                  data={progress.weight.chartData
                    .sort(function (a, b) {
                      return a.week - b.week;
                    })
                    .map((data) => ({
                      ...data,
                      week: `week ${data.week}`,
                    }))}
                  margin={{
                    top: 10,
                    right: 20,
                    left: 20,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid vertical={false} strokeDashArray="1" />
                  <Line
                    isAnimationActive={false}
                    dataKey="avg"
                    stroke="#FFFFFF"
                    strokeWidth={2}
                  />
                  <YAxis
                    axisLine={false}
                    tick={{ fontSize: 12 }}
                    tickLine={false}
                    stroke="#FFFFFF"
                    orientation="right"
                    width={25}
                    domain={[0, 110]}
                  />
                  <XAxis
                    tick={{ fontSize: 12 }}
                    height={25}
                    stroke="#FFFFFF"
                    tickLine={false}
                    dataKey="week"
                    scale="band"
                  />
                  {
                    <ReferenceLine y={progress.weight.goal} stroke="#FDCD23">
                      <Label
                        fontSize="12px"
                        fontWeight={600}
                        fill="#FFFFFF"
                        value={`Goal: ${progress.weight.goal} Kg`}
                        offset={2}
                        position="insideTopLeft"
                      />
                    </ReferenceLine>
                  }
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        )}
        {progress.bloodsugar && (
          <div className={classes.chart_container}>
            <div
              className={classes.chart_sub_container}
              onClick={props.handleOpenBloodSugarProgressDrawer}
            >
              <Typography className={classes.text}>
                Fasting Blood Sugar
              </Typography>
              <ChevronRight className={classes.icon} />
            </div>
            <div className={classes.chart_sub_container1}>
              <Typography className={classes.sub_text}>
                {progress.bloodsugar.average
                  ? `${
                      progress.bloodsugar.average == "NaN"
                        ? "No Data"
                        : "Avg. " + progress.bloodsugar.average + " mg/dL"
                    }`
                  : "No Data"}
              </Typography>
              <Typography className={classes.sub_text1}>
                Last 30 days
              </Typography>
            </div>
            <div className={classes.bp_container}>
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  width={300}
                  height={144}
                  data={progress.bloodsugar.chartData
                    .sort(function (a, b) {
                      return a.week - b.week;
                    })
                    .map((data) => ({
                      ...data,
                      week: `week ${data.week}`,
                    }))}
                  margin={{
                    top: 10,
                    right: 20,
                    left: 20,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid vertical={false} strokeDashArray="0" />
                  <Line dataKey="avg" stroke="#FFFFFF" strokeWidth={2} />
                  <YAxis
                    axisLine={false}
                    tick={{ fontSize: 12 }}
                    tickLine={false}
                    stroke="#FFFFFF"
                    orientation="right"
                    width={25}
                    domain={[0, 150]}
                  />
                  <XAxis
                    tick={{ fontSize: 12 }}
                    height={25}
                    stroke="#FFFFFF"
                    tickLine={false}
                    dataKey="week"
                    scale="band"
                  />
                  <ReferenceLine y={progress.bloodsugar.goal} stroke="#FDCD23">
                    <Label
                      fontSize="12px"
                      fontWeight={600}
                      fill="#FFFFFF"
                      value={`Goal: ${progress.bloodsugar.goal} mg/dl`}
                      offset={2}
                      position="insideTopLeft"
                    />
                  </ReferenceLine>
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        )}
        {progress.bloodsugarRandom && (
          <div className={classes.chart_container}>
            <div
              className={classes.chart_sub_container}
              onClick={props.handleOpenRandomBPSProgressDrawer}
            >
              <Typography className={classes.text}>
                Random Blood Sugar
              </Typography>
              <ChevronRight className={classes.icon} />
            </div>
            <div className={classes.chart_sub_container1}>
              <Typography className={classes.sub_text}>
                {progress.bloodsugarRandom.average
                  ? `${
                      progress.bloodsugarRandom.average == "NaN"
                        ? "No Data"
                        : "Avg. " + progress.bloodsugarRandom.average + " mg/dL"
                    }`
                  : "No Data"}
              </Typography>
              <Typography className={classes.sub_text1}>
                Last 30 days
              </Typography>
            </div>
            <div className={classes.bp_container}>
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  width={300}
                  height={144}
                  data={progress.bloodsugarRandom.chartData
                    .sort(function (a, b) {
                      return a.week - b.week;
                    })
                    .map((data) => ({
                      ...data,
                      week: `week ${data.week}`,
                    }))}
                  margin={{
                    top: 10,
                    right: 20,
                    left: 20,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid vertical={false} strokeDashArray="0" />
                  <Line dataKey="avg" stroke="#FFFFFF" strokeWidth={2} />
                  <YAxis
                    axisLine={false}
                    tick={{ fontSize: 12 }}
                    tickLine={false}
                    stroke="#FFFFFF"
                    orientation="right"
                    width={25}
                    domain={[0, 240]}
                  />
                  <XAxis
                    tick={{ fontSize: 12 }}
                    height={25}
                    stroke="#FFFFFF"
                    tickLine={false}
                    dataKey="week"
                    scale="band"
                  />
                  <ReferenceLine
                    y={progress.bloodsugarRandom.goal}
                    stroke="#FDCD23"
                  >
                    <Label
                      fontSize="12px"
                      fontWeight={600}
                      fill="#FFFFFF"
                      value={`Goal: ${progress.bloodsugarRandom.goal} mg/dl`}
                      offset={2}
                      position="insideTopLeft"
                    />
                  </ReferenceLine>
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        )}
        progress.bloodsugarPreMeal && (
          <div className={classes.chart_container}>
            <div
              className={classes.chart_sub_container}
              onClick={props.handleOpenPreBPSProgressDrawer}
            >
              <Typography className={classes.text}>
                Pre Meal Blood Sugar
              </Typography>
              <ChevronRight className={classes.icon} />
            </div>
            <div className={classes.chart_sub_container1}>
              <Typography className={classes.sub_text}>
               
                  {progress.bloodsugarPreMeal.average
                 ? `${progress.bloodsugarPreMeal.average == 'NaN'? 'No Data':'Avg. ' + progress.bloodsugarPreMeal.average + ' mg/dL'}`
                 : "No Data"}
              </Typography>
              <Typography className={classes.sub_text1}>
                Last 30 days
              </Typography>
            </div>
            <div className={classes.bp_container}>
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  width={300}
                  height={144}
                  data={progress.bloodsugarPreMeal.data.sort(function (a, b) {
                    return a.week - b.week;
                  }).map((data) => ({
                    ...data,
                    week: `week ${data.week}`,
                  }))}
                  margin={{
                    top: 10,
                    right: 20,
                    left: 20,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid vertical={false} strokeDashArray="0" />
                  <Line dataKey="avg" stroke="#FFFFFF" strokeWidth={2} />
                  <YAxis
                    axisLine={false}
                    tick={{ fontSize: 12 }}
                    tickLine={false}
                    stroke="#FFFFFF"
                    orientation="right"
                    width={25}
                    domain={[0, 240]}
                  />
                  <XAxis
                    tick={{ fontSize: 12 }}
                    height={25}
                    stroke="#FFFFFF"
                    tickLine={false}
                    dataKey="week"
                    scale="band"
                  />
                  <ReferenceLine y={progress.bloodsugarPreMeal.goal} stroke="#FDCD23">
                    <Label
                      fontSize="12px"
                      fontWeight={600}
                      fill="#FFFFFF"
                      value={`Goal: ${progress.bloodsugarPreMeal.goal} mg/dl`}
                      offset={2}
                      position="insideTopLeft"
                    />
                  </ReferenceLine>
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
                )
        {progress.bloodsugarPostMeal && (
          <div className={classes.chart_container}>
            <div
              className={classes.chart_sub_container}
              onClick={props.handleOpenPostBPSProgressDrawer}
            >
              <Typography className={classes.text}>
                Post Meal Blood Sugar
              </Typography>
              <ChevronRight className={classes.icon} />
            </div>
            <div className={classes.chart_sub_container1}>
              <Typography className={classes.sub_text}>
                {progress.bloodsugarPostMeal.average
                  ? `${
                      progress.bloodsugarPostMeal.average == "NaN"
                        ? "No Data"
                        : "Avg. " +
                          progress.bloodsugarPostMeal.average +
                          " mg/dL"
                    }`
                  : "No Data"}
              </Typography>
              <Typography className={classes.sub_text1}>
                Last 30 days
              </Typography>
            </div>
            <div className={classes.bp_container}>
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  width={300}
                  height={144}
                  data={progress.bloodsugarPostMeal.chartData
                    .sort(function (a, b) {
                      return a.week - b.week;
                    })
                    .map((data) => ({
                      ...data,
                      week: `week ${data.week}`,
                    }))}
                  margin={{
                    top: 10,
                    right: 20,
                    left: 20,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid vertical={false} strokeDashArray="0" />
                  <Line dataKey="avg" stroke="#FFFFFF" strokeWidth={1} />
                  <YAxis
                    axisLine={false}
                    tick={{ fontSize: 12 }}
                    tickLine={false}
                    stroke="#FFFFFF"
                    orientation="right"
                    width={25}
                    domain={[0, 240]}
                  />
                  <XAxis
                    tick={{ fontSize: 12 }}
                    height={25}
                    stroke="#FFFFFF"
                    tickLine={false}
                    dataKey="week"
                    scale="band"
                  />
                  <ReferenceLine
                    y={progress.bloodsugarPostMeal.goal}
                    stroke="#FDCD23"
                  >
                    <Label
                      fontSize="12px"
                      fontWeight={600}
                      fill="#FFFFFF"
                      value={`Goal: ${progress.bloodsugarPostMeal.goal} mg/dl`}
                      offset={2}
                      position="insideTopLeft"
                    />
                  </ReferenceLine>
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        )}
        {progress.diet && (
          <div className={classes.chart_container}>
            <div
              className={classes.chart_sub_container}
              onClick={props.handleOpenFoodProgressDrawer}
            >
              <Typography className={classes.text}>Food</Typography>
              <ChevronRight className={classes.icon} />
            </div>
            <div className={classes.chart_sub_container1}>
              <Typography className={classes.sub_text}>
                {progress.diet.avg
                  ? `${
                      progress.diet.avg == "NaN"
                        ? "No Data"
                        : "Avg. " + progress.diet.avg + " meals per day"
                    }`
                  : "No Data"}
              </Typography>
              <Typography className={classes.sub_text1}>
                Last 30 days
              </Typography>
            </div>
            <div className={classes.food_container}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  width={300}
                  height={144}
                  data={progress.diet.chartData
                    .sort(function (a, b) {
                      return a.week - b.week;
                    })
                    .map((data) => ({
                      ...data,
                      week: `week ${data.week}`,
                    }))}
                  margin={{
                    top: 10,
                    right: 20,
                    left: 20,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid vertical={false} strokeDasharray="0" />
                  <XAxis
                    tick={{ fontSize: 12 }}
                    height={25}
                    stroke="#FFFFFF"
                    tickLine={false}
                    dataKey="week"
                  />
                  <YAxis
                    axisLine={false}
                    tick={{ fontSize: 12 }}
                    tickLine={false}
                    stroke="#FFFFFF"
                    orientation="right"
                    width={25}
                    domain={[0, 90]}
                  />
                  <Bar barSize={7} dataKey="avg" fill="#fff" />
                  <ReferenceLine y={progress.diet.goal} stroke="#FDCD23">
                    <Label
                      fontSize="12px"
                      fontWeight={600}
                      fill="#FFFFFF"
                      value={`Goal: ${progress.diet.goal} meals`}
                      offset={2}
                      position="insideTopLeft"
                    />
                  </ReferenceLine>
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        )}

        {progress.steps && (
          <div className={classes.chart_container}>
            <div
              className={classes.chart_sub_container}
              onClick={props.handleOpenStepProgressDrawer}
            >
              <Typography className={classes.text}>Steps</Typography>
              <ChevronRight className={classes.icon} />
            </div>
            <div className={classes.chart_sub_container1}>
              <Typography className={classes.sub_text}>
                {progress.steps.average
                  ? `${
                      progress.steps.average == "NaN"
                        ? "No Data"
                        : "Avg. " + progress.steps.average + " steps per day"
                    }`
                  : "No Data"}
              </Typography>
              <Typography className={classes.sub_text1}>
                Last 30 days
              </Typography>
            </div>
            <div className={classes.steps_container}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  width={320}
                  height={144}
                  data={progress.steps.chartData
                    .sort(function (a, b) {
                      return a.week - b.week;
                    })
                    .map((data) => ({
                      ...data,
                      week: `week ${data.week}`,
                    }))}
                  margin={{
                    top: 10,
                    right: 20,
                    left: 20,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid vertical={false} strokeDasharray="0" />
                  <XAxis
                    tick={{ fontSize: 12 }}
                    height={25}
                    stroke="#FFFFFF"
                    tickLine={false}
                    dataKey="week"
                  />
                  <YAxis
                    axisLine={false}
                    tick={{ fontSize: 12 }}
                    tickLine={false}
                    stroke="#FFFFFF"
                    orientation="right"
                    width={25}
                    domain={[0, 9000]}
                  />
                  <Bar barSize={7} dataKey="avg" fill="#fff" />
                  <ReferenceLine y={progress.steps.goal} stroke="#FDCD23">
                    <Label
                      fontSize="12px"
                      fontWeight={600}
                      fill="#FFFFFF"
                      value={`Goal: ${progress.steps.goal} steps`}
                      offset={2}
                      position="insideTopLeft"
                    />
                  </ReferenceLine>
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        )}
        {progress.physical && (
          <div className={classes.chart_container}>
            <div
              className={classes.chart_sub_container}
              onClick={props.handleOpenPhysicalProgressDrawer}
            >
              <Typography className={classes.text}>
                Physical Activity
              </Typography>
              <ChevronRight className={classes.icon} />
            </div>
            <div className={classes.chart_sub_container1}>
              <Typography className={classes.sub_text}>
                {progress.physical.average
                  ? `${
                      progress.physical.average == "NaN"
                        ? "No Data"
                        : "Avg. " + progress.physical.average + " cals per day"
                    }`
                  : "No Data"}
              </Typography>
              <Typography className={classes.sub_text1}>
                Last 30 days
              </Typography>
            </div>
            <div className={classes.physical_container}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  width={320}
                  height={144}
                  data={progress.physical.chartData
                    .sort(function (a, b) {
                      return a.week - b.week;
                    })
                    .map((data) => ({
                      ...data,
                      week: `week ${data.week}`,
                    }))}
                  margin={{
                    top: 10,
                    right: 20,
                    left: 20,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid vertical={false} strokeDasharray="0" />
                  <XAxis
                    tick={{ fontSize: 12 }}
                    height={25}
                    stroke="#FFFFFF"
                    tickLine={false}
                    dataKey="week"
                  />
                  <YAxis
                    axisLine={false}
                    tick={{ fontSize: 12 }}
                    tickLine={false}
                    stroke="#FFFFFF"
                    orientation="right"
                    width={25}
                    domain={[0, 550]}
                  />
                  <Bar barSize={7} dataKey="avg" fill="#fff" />
                  <ReferenceLine y={progress.physical.goal} stroke="#FDCD23">
                    <Label
                      fontSize="12px"
                      fontWeight={600}
                      fill="#FFFFFF"
                      value={`Goal: ${progress.physical.goal} cals`}
                      offset={2}
                      position="insideTopLeft"
                    />
                  </ReferenceLine>
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        )}
        {progress.hba1c && (
          <div className={classes.chart_container}>
            <div
              className={classes.chart_sub_container}
              onClick={props.handleOpenHb1acProgressDrawer}
            >
              <Typography className={classes.text}>HBA1C</Typography>
              <ChevronRight className={classes.icon} />
            </div>
            <div className={classes.chart_sub_container1}>
              <Typography className={classes.sub_text}>
                {progress.hba1c.average
                  ? `${
                      progress.hba1c.average == "NaN"
                        ? "No Data"
                        : "Avg." + progress.hba1c.average + "%"
                    }`
                  : "No Data"}
              </Typography>
              <Typography className={classes.sub_text1}>
                Last 30 days
              </Typography>
            </div>
            <div className={classes.hba1c_container}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  width={320}
                  height={144}
                  data={progress.hba1c.chartData
                    .sort(function (a, b) {
                      return a.week - b.week;
                    })
                    .map((data) => ({
                      ...data,
                      week: `week ${data.week}`,
                    }))}
                  margin={{
                    top: 10,
                    right: 20,
                    left: 20,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid vertical={false} strokeDasharray="0" />
                  <XAxis
                    tick={{ fontSize: 12 }}
                    height={25}
                    stroke="#FFFFFF"
                    tickLine={false}
                    dataKey="week"
                  />
                  <YAxis
                    axisLine={false}
                    tick={{ fontSize: 12 }}
                    tickLine={false}
                    stroke="#FFFFFF"
                    orientation="right"
                    width={25}
                    domain={[0, 13]}
                  />
                  <Bar barSize={7} dataKey="avg" fill="#fff" />
                  <ReferenceLine y={progress.hba1c.goal} stroke="#FDCD23">
                    <Label
                      fontSize="12px"
                      fontWeight={600}
                      fill="#FFFFFF"
                      value={`Goal: ${progress.hba1c.goal} %`}
                      offset={2}
                      position="insideTopLeft"
                    />
                  </ReferenceLine>
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        )}
        {progress.sleep && (
          <div className={classes.chart_container}>
            <div
              className={classes.chart_sub_container}
              onClick={props.handleOpenSleepProgressDrawer}
            >
              <Typography className={classes.text}>Sleep</Typography>
              <ChevronRight className={classes.icon} />
            </div>
            <div className={classes.chart_sub_container1}>
              <Typography className={classes.sub_text}>
                {progress.sleep.average
                  ? `${
                      progress.sleep.average == "NaN"
                        ? "No Data"
                        : "Avg. " + progress.sleep.average + " hrs"
                    }`
                  : "No Data"}
              </Typography>
              <Typography className={classes.sub_text1}>
                Last 30 days
              </Typography>
            </div>
            <div className={classes.sleep_container}>
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  width={300}
                  height={144}
                  data={progress.sleep.chartData.map((data) => ({
                    ...data,
                    week: `week ${data.week}`,
                  }))}
                  margin={{
                    top: 10,
                    right: 20,
                    left: 20,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid vertical={false} strokeDashArray="0" />
                  <Line dataKey="avg" stroke="#FFFFFF" strokeWidth={2} />
                  <YAxis
                    axisLine={false}
                    tick={{ fontSize: 12 }}
                    tickLine={false}
                    stroke="#FFFFFF"
                    orientation="right"
                    width={25}
                    domain={[0, 10]}
                  />
                  <XAxis
                    tick={{ fontSize: 12 }}
                    height={25}
                    stroke="#FFFFFF"
                    tickLine={false}
                    dataKey="week"
                    scale="band"
                  />
                  {
                    <ReferenceLine y={progress.sleep.goal} stroke="#FDCD23">
                      <Label
                        fontSize="12px"
                        fontWeight={600}
                        fill="#FFFFFF"
                        value={`Goal: ${progress.sleep.goal} hrs`}
                        offset={2}
                        position="insideTopLeft"
                      />
                    </ReferenceLine>
                  }
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        )} */}
      </div>
    </>
  );
};

export default withStyles(progressStyle)(Progress);
