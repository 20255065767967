/**
 @desc
 * Centralized unique actions for Home Module.
 */

import {
  actionCreator,
  checkHttpStatus,
  jsonApiHeader,
  showErrorMessage,
  HomeActionTypes,
  GET_ALL_PATIENTS,
  POST_UPLOAD_APPOINTMENTS,
} from "../constants";
import { notyf, TOKEN_EXPIRY_MESSAGE } from "../../../utils/utility";

/**
 * @param  {[object]} postData [The data needed as a payload for the API interaction]
 */

export const GetAllPatientsAction = () => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.token;
    dispatch(actionCreator(HomeActionTypes.get_AllPatients.REQUEST));
    fetch(`${GET_ALL_PATIENTS}`, {
      method: "GET",
      headers: jsonApiHeader(token),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(
            actionCreator(HomeActionTypes.get_AllPatients.FAILURE, response)
          );
          showErrorMessage(response.Message);
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage(TOKEN_EXPIRY_MESSAGE);
          localStorage.clear();
          dispatch(
            actionCreator(HomeActionTypes.get_AllPatients.FAILURE, response)
          );
          window.location.reload();
        } else {
          dispatch(
            actionCreator(HomeActionTypes.get_AllPatients.SUCCESS, response)
          );
        }
      })
      .catch(function (error) {
        dispatch(actionCreator(HomeActionTypes.get_AllPatients.FAILURE));
        console.log("error get_AllPatients ..", error);
      });
  };
};

export const PostUploadAppointmentsAction = (postData) => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.token;
    dispatch(actionCreator(HomeActionTypes.post_UploadAppointments.REQUEST));
    fetch(`${POST_UPLOAD_APPOINTMENTS}`, {
      method: "POST",
      body: postData,
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    })
      // .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(
            actionCreator(
              HomeActionTypes.post_UploadAppointments.FAILURE,
              response
            )
          );
          showErrorMessage(response.message);
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage(TOKEN_EXPIRY_MESSAGE);
          localStorage.clear();
          dispatch(
            actionCreator(
              HomeActionTypes.post_UploadAppointments.FAILURE,
              response
            )
          );
          window.location.reload();
        } else {
          dispatch(
            actionCreator(
              HomeActionTypes.post_UploadAppointments.SUCCESS,
              response
            )
          );
          notyf.success("Appointments updated successfully.");
        }
      })
      .catch(function (error) {
        dispatch(
          actionCreator(HomeActionTypes.post_UploadAppointments.FAILURE)
        );
        console.log("error post_UploadAppointments ..", error);
      });
  };
};
