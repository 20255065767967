const basicDetailsConfirmPopupStyle = (theme) => ({
  mainContainer: { width: "320px" },
  dialogTextBox: { padding: "20px", display: "flex", justifyContent: "center" },
  dialogText: { fontSize: "16px", fontWeight: 500 },
  buttonsBox: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "20px 20px",
  },
  buttonYes: {
    marginLeft: "10px",
  },
  dropDownContainer: {
    padding: "20px",
    margin: "auto",
  },
  dropDown: {
    border: "1px solid black",
    marginLeft: "2px",
  },
  textContainer: { padding: "0px 20px" },
});

export default basicDetailsConfirmPopupStyle;
