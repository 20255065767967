import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles, Typography } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import moment from "moment";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  ReferenceLine,
  Label
} from "recharts";
import bloodSugarProgressDrawerStyle from "../../../../assets/jss/pages/components/bloodSugarProgressDrawerStyle";
import api from "utils/api";

const fetchData = async (id, metric) => {
  try {
    const { data } = await api(
      `/v1/health/getBloodSugar/${id}?duration=${metric}&type=premeal`,
      {
        method: "get",
      }
    );
    return data;
  } catch (e) {
    console.error("fetch user progress", e);
  }
  return {};
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const PreMealBloodSugarProgressDrawer = (props) => {
  const [value, setValue] = useState(0);
  const { classes, patientId } = props;

  const [dayData, setDayData] = useState({});
  const [weekData, setWeekData] = useState({});
  const [monthData, setMonthData] = useState(props.monthData || {});

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (value === 0 && !dayData?.data) {
      fetchData(patientId, "day").then(setDayData);
    } else if (value === 1 && !weekData?.data) {
      fetchData(patientId, "week").then(setWeekData);
    } else if (value === 2 && !monthData?.data) {
      fetchData(patientId, "month").then(setMonthData);
    }
  }, [dayData, weekData, monthData, patientId, value]);


  return (
    <>
       <div className={classes.headerContainer}>
        <Typography style={{ fontSize: "24px", fontWeight: 500 }}>
          Pre Meal Blood Sugar
        </Typography>
        <CloseIcon className={classes.icon} onClick={props.handleCloseDrawer} />
      </div>
      <Tabs className={classes.root} value={value} onChange={handleChange}>
        <Tab label="Day" {...a11yProps(0)} />
        <Tab label="Week" {...a11yProps(1)} />
        <Tab label="Month" {...a11yProps(2)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Typography style={{ marginLeft: 16 }}>
           {dayData?.average == 'NaN' ? 'No Data' : 'Avg. '+ dayData?.average + ' mg/dL'}
        </Typography>
        <div className={classes.bp_container}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={300}
              height={144}
              data={dayData?.data?.sort(function (a, b) {
                return a.time.localeCompare(b.time);
            }).map((data) => ({
                ...data,
                time: moment("2021-01-01T" + data.time).format("hh:mm A"),
              }))}
              margin={{
                top: 10,
                right: 20,
                left: 20,
                bottom: 0,
              }}
            >
              <CartesianGrid vertical={false} strokeDashArray="1" />
              <Line dataKey="measurement" stroke="#FFFFFF" strokeWidth={2} />
              <YAxis
                axisLine={false}
                tick={{ fontSize: 12 }}
                tickLine={false}
                stroke="#FFFFFF"
                orientation="right"
                width={25}
                domain={[0, 240]}
                // ticks={[60, 90, 120, 150]}
              />
              <XAxis
                tick={{ fontSize: 12 }}
                height={25}
                stroke="#FFFFFF"
                tickLine={false}
                dataKey="time"
                scale="band"
              />
              {<ReferenceLine y={dayData.goal} stroke="#FDCD23">
                <Label
                  fontSize="12px"
                  fontWeight={600}
                  fill="#FFFFFF"
                  value={`Goal: ${dayData.goal} mg/Dl`}
                  offset={2}
                  position="insideTopLeft"
                />
              </ReferenceLine>}
            </LineChart>
          </ResponsiveContainer>
        </div>
        {dayData.data?.map((data, index) => (
          <div className={classes.mainContainer} onClick={() =>
            props.openDrawer(
              "task_details_graph",
              "bloodsugar",
              "PreBP_progress",
              "Pre Meal",
              data
            )
          }  key={index}>
            <div>
              <Typography className={classes.text}>
                {moment("2021-01-01T" + data.time).format("hh:mm A")}
              </Typography>
              <Typography className={classes.sub_text}>Time</Typography>
            </div>
            <div className={classes.flex}>
              <Typography className={classes.text}>{data.measurement} mg/dL</Typography>
              <ChevronRight className={classes.chevron} />
            </div>
          </div>
        ))}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Typography style={{ marginLeft: 16 }}>
          {weekData?.average == 'NaN' || 'undefined' ? 'No Data'  : 'Avg. '+ weekData?.average + ' mg/dL'}
        </Typography>
        <div className={classes.bp_container}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={300}
              height={144}
              data={weekData?.data?.sort(function(a,b){
                return new Date(a.date) - new Date(b.date);
              }).map((data) => ({
                ...data,
                date: moment(data.date).format("ddd"),
              }))}
              margin={{
                top: 10,
                right: 20,
                left: 20,
                bottom: 0,
              }}
            >
              <CartesianGrid vertical={false} strokeDashArray="1" />
              <Line dataKey="avg" stroke="#FFFFFF" strokeWidth={2} />
              <YAxis
                axisLine={false}
                tick={{ fontSize: 12 }}
                tickLine={false}
                stroke="#FFFFFF"
                orientation="right"
                width={25}
                domain={[0, 240]}
              />
              <XAxis
                tick={{ fontSize: 12 }}
                height={25}
                stroke="#FFFFFF"
                tickLine={false}
                dataKey="date"
                scale="band"

              />
              {<ReferenceLine y={weekData.goal} stroke="#FDCD23">
                <Label
                  fontSize="12px"
                  fontWeight={600}
                  fill="#FFFFFF"
                  value={`Goal: ${weekData.goal} mg/Dl`}
                  offset={2}
                  position="insideTopLeft"
                />
              </ReferenceLine>}
            </LineChart>
          </ResponsiveContainer>
        </div>
        {weekData.data?.map((data, index) => (
          <div className={classes.mainContainer} onClick={() =>
            props.openDrawer(
              "task_details_graph",
              "bloodsugar",
              "PreBP_progress",
              "Pre Meal",
              data
            )
          } key={index}>
            <div>
              <Typography className={classes.text}>
                {moment(data.date).format("DD MMM YYYY")}
              </Typography>
              <Typography className={classes.sub_text}>Date</Typography>
            </div>
            <div className={classes.flex}>
              <Typography className={classes.text}>{data.avg} mg/dL</Typography>
              <ChevronRight className={classes.chevron} />
            </div>
          </div>
        ))}
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Typography style={{ marginLeft: 16 }}>
          {monthData?.average == 'NaN' ? 'No Data' : 'Avg. '+ monthData?.average + ' mg/dL'}
        </Typography>
        <div className={classes.bp_container}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={300}
              height={144}
              data={monthData.data.sort(function (a, b) {
                return a.week - b.week;
              }).map((data) => ({
                ...data,
                week: "week " + data.week,
              }))}
              margin={{
                top: 10,
                right: 20,
                left: 20,
                bottom: 0,
              }}
            >
              <CartesianGrid vertical={false} strokeDashArray="1" />
              <Line dataKey="avg" stroke="#FFFFFF" strokeWidth={2} />
              <YAxis
                axisLine={false}
                tick={{ fontSize: 12 }}
                tickLine={false}
                stroke="#FFFFFF"
                orientation="right"
                width={25}
                domain={[0,240]}
              />
              <XAxis
                tick={{ fontSize: 12 }}
                height={25}
                stroke="#FFFFFF"
                tickLine={false}
                dataKey="week"
                scale="band"
              />
             {<ReferenceLine y={monthData.goal} stroke="#FDCD23">
                <Label
                  fontSize="12px"
                  fontWeight={600}
                  fill="#FFFFFF"
                  value={`Goal: ${monthData.goal} mg/Dl`}
                  offset={2}
                  position="insideTopLeft"
                />
              </ReferenceLine>}
            </LineChart>
          </ResponsiveContainer>
        </div>
        {monthData.data?.map((data, index) => (
          <div className={classes.mainContainer} onClick={() =>
            props.openDrawer(
              "task_details_graph",
              "bloodsugar",
              "PreBP_progress",
              "Pre Meal",
              data
            )
          } key={index}>
            <div>
              <Typography className={classes.text}>Week {data.week}</Typography>
              <Typography className={classes.sub_text}>Week</Typography>
            </div>
            <div className={classes.flex}>
              <Typography className={classes.text}>{data.avg} mg/dL</Typography>
              <ChevronRight className={classes.chevron} />
            </div>
          </div>
        ))}
      </TabPanel>
    </>
  )
}

export default withStyles(bloodSugarProgressDrawerStyle)(PreMealBloodSugarProgressDrawer);
