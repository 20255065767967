import moment from "moment";

export const changedDeliveryInformation = (prevValues, currentValues) => {
  let outputObj = {};
  const prevETA = moment(prevValues?.ETA, "YYYY-MM-DD");
  const currentETA = moment(currentValues?.ETA, "DD/MM/YYYY");

  if (!prevETA.isSame(currentETA)) {
    outputObj.ETA = currentETA.format("YYYY-MM-DD");
  }

  if (prevValues?.deliveryMode?.name !== currentValues?.deliveryMode?.name) {
    outputObj.deliveryMode = currentValues?.deliveryMode?.id;
  }

  if (
    prevValues?.deliveryVendor?.name !== currentValues?.deliveryVendor?.name
  ) {
    outputObj.deliveryVendor = currentValues?.deliveryVendor?.id;
  }

  if (currentValues?.remarks) {
    outputObj.remarks = currentValues.remarks;
  }

  if (prevValues.status !== currentValues?.status?.name) {
    outputObj.status = currentValues?.status?.name;
  }

  if (prevValues?.trackingId !== currentValues?.trackingId) {
    outputObj.trackingId = currentValues.trackingId;
  }
  return outputObj;
};
