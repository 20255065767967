import { Box, Modal, Typography, withStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import cgmProgressDrawerStyle from "../../../../assets/jss/pages/components/cgmProgressDrawerStyle";
import CustomButton from "@components/atoms/Button";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import api from "utils/api";
import Chart from "./CgmChart";
import { END_POINT } from "network/constants";
import lang from "assets/languages";
import MetricTable from "./TableRowsColumns";
import { notyf } from "utils/utility";
import { apiResponses } from "network/constants";
import ActivityList from "./activityList";
import ReportsModal from "./reportsModal";
import SummaryModal from "./SummaryModal";
import Loader from "@components/atoms/Loader";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "78%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
};

const CgmProgressDrawer = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [showReportsModal, setShowReportsModal] = useState(false);
  const [graphData, setGraphData] = useState([]);
  const { classes, patientId } = props;
  const [modalData, setModalData] = useState([]);
  const [selectedButton, setSelectedButton] = useState(0);
  const handleClose = () => {
    setShowModal(false);
  };
  const [graphSummary, showGraphSummary] = useState(false);
  const [metricSummaryDate, setMetricSummaryDate] = useState(null);
  const [metricSummary, setmetricSummary] = useState(null);
  const [activities, setActivities] = useState([]);
  const [graphActivities, setGraphActivities] = useState([]);
  const [loading, setLoading] = useState(false);

  const [showSummaryModal, setShowSummaryModal] = useState(false);

  const indexSelected = useRef();

  const fetchCGMData = async () => {
    setLoading(true);

    try {
      const res = await api(END_POINT.getCgmData(patientId), {
        method: "get",
      });
      if (res?.data?.data) {
        setMetricSummaryDate(Object.keys(res?.data?.data)[0]);
        let updatedData = Object.entries(res?.data?.data).map((cgmData) => {
          const date = cgmData[0];
          const bloodGlucoseData = cgmData[1];
          let newData = bloodGlucoseData.map((glucoseData) => {
            return {
              x: new Date(glucoseData?.time),
              y: glucoseData?.bloodGlucose
                ? parseInt(glucoseData?.bloodGlucose)
                : null,
              label: moment(glucoseData?.time).format("hh:mm A"),
            };
          });
          newData = [date, newData];
          return newData;
        });
        setGraphData(updatedData);
      }

      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error("fetch graph data", e);
    }
    return {};
  };

  const getActivityList = async (date, index) => {
    try {
      const { data } = await api(END_POINT.getActivityList(patientId, date), {
        method: "get",
      });
      if (index === indexSelected.current) {
        setActivities(data?.data?.timeSorted);
        setGraphActivities(data?.data?.prioritySorted);
      }
      return data;
    } catch (e) {
      console.error("fetch user activity", e);
    }
    return {};
  };

  const fetchMetricSummary = async (date) => {
    setLoading(true);

    try {
      const res = await api(END_POINT.getMetricSummary(patientId, date), {
        method: "get",
      });
      if (res?.data?.status === apiResponses.success) {
        setmetricSummary(res.data.data);
      } else {
        notyf.error("Failed to fetch metric summary");
        console.error("fetch metric summary data", res?.data);
      }

      setLoading(false);
    } catch (e) {
      setLoading(false);
      notyf.error("Failed to fetch metric summary");
      console.error("fetch metric summary data", e);
    }
  };

  useEffect(() => {
    fetchCGMData();
  }, []);

  useEffect(() => {
    if (metricSummaryDate) {
      fetchMetricSummary(
        moment(metricSummaryDate, "D MMM, YYYY").format("YYYY-MM-DD")
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metricSummaryDate]);

  const handleModalData = (index) => {
    indexSelected.current = index;
    if (graphSummary) {
      setSelectedButton(index);
      setMetricSummaryDate(graphData?.[index]?.[1]?.[0]?.x);
      setModalData(graphData?.[index]?.[1]);
    } else {
      setSelectedButton(index);
      setModalData(graphData?.[index]?.[1]);
    }
    getActivityList(
      moment(graphData?.[index]?.[1]?.[0]?.x, "D MMM, YYYY").format(
        "YYYY-MM-DD"
      ),
      index
    );
  };

  const handleShowModal = (index) => {
    handleModalData(index);
    setShowModal(true);
  };

  const isScroll =
    graphData.length > 6
      ? { style: { width: 800 + (graphData.length - 6) * 127 } }
      : { style: { width: 800 } };

  const isSelected = (index) => {
    if (index === selectedButton) {
      return classes.selectedTimeline;
    } else {
      return classes.notSelectedTimeline;
    }
  };

  const goToActivity = (id) => {
    const releventDiv = document.getElementById(id);
    releventDiv && releventDiv.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      {loading && <Loader open={true} loaderMessage="Fetching..." />}
      {graphData?.length > 0 ? (
        <>
          <div className={classes.headerContainer}>
            <Typography className={classes.trends}>{lang.trends}</Typography>
            <CloseIcon
              className={classes.icon}
              onClick={props.handleCloseDrawer}
            />
          </div>
          <div className={classes.container}>
            <Typography classes={classes.cgmSubheading}>{lang.cgm}</Typography>
            {graphData[0].length > 0 && (
              <Typography className={classes.lastUpdateStatus}>
                {`${lang.lastUpdatedOn} : ${graphData[0]?.[0]}   ${graphData[0]?.[1]?.[0]?.label}`}
              </Typography>
            )}
            <CustomButton
              className={classes.viewTimelineButton}
              onClick={() => {
                handleShowModal(0);
              }}
            >
              {lang.viewTimeLine}
            </CustomButton>
            <CustomButton
              className={classes.viewTimelineButton}
              onClick={() => {
                setShowReportsModal(true);
              }}
            >
              {lang.viewReports}
            </CustomButton>
            <CustomButton
              className={classes.viewTimelineButton}
              onClick={() => {
                setShowSummaryModal(true);
              }}
            >
              {lang.viewSummary}
            </CustomButton>
          </div>
          <div>
            {graphData.map((data, i) => (
              <div key={i} onClick={() => handleShowModal(i)}>
                <Typography className={classes.trendsDate}>
                  {data && data[0] ? data[0] : "--"}
                </Typography>
                <Chart
                  graphData={data[1] ? data[1] : "--"}
                  height={230}
                  width={360}
                />
              </div>
            ))}
          </div>
          <div>
            <Modal open={showModal} onClose={handleClose}>
              <Box sx={modalStyle}>
                <div className={classes.graphModal}>
                  <div className={classes.modelHeader}>
                    <Typography className={classes.modelHeading}>
                      {lang.cgm}
                    </Typography>
                    <div className={classes.modelHeaderTabs}>
                      <div
                        className={
                          (classes.heading, classes.modelHeaderGraphTab)
                        }
                      >
                        <button
                          className={
                            graphSummary
                              ? classes.notSelectedTimeline
                              : classes.selectedTimeline
                          }
                          onClick={() => {
                            showGraphSummary(false);
                          }}
                        >
                          {lang.graph}
                        </button>
                      </div>
                      <div className={classes.heading}>
                        <button
                          className={
                            graphSummary
                              ? classes.selectedTimeline
                              : classes.notSelectedTimeline
                          }
                          onClick={() => {
                            if (!modalData.length) {
                              return;
                            }
                            setMetricSummaryDate(modalData[0]?.x);
                            showGraphSummary(true);
                          }}
                        >
                          {lang.summary}
                        </button>
                      </div>
                    </div>
                  </div>
                  {graphSummary ? (
                    <div className={classes.graphContainer}>
                      <div className={classes.datesContainer}>
                        <div className={classes.dates} {...isScroll}>
                          {graphData.map((data, i) => (
                            <button
                              className={isSelected(i)}
                              onClick={() => handleModalData(i)}
                            >
                              {data[0] ? data[0] : "--"}
                            </button>
                          ))}
                        </div>
                      </div>
                      <div>{MetricTable(metricSummary)}</div>
                    </div>
                  ) : (
                    <>
                      <div className={classes.datesContainer}>
                        <div className={classes.dates} {...isScroll}>
                          {graphData.map((data, i) => (
                            <button
                              className={isSelected(i)}
                              onClick={() => handleModalData(i)}
                            >
                              {data[0] ? data[0] : "--"}
                            </button>
                          ))}
                        </div>
                      </div>
                      <div>
                        <Chart
                          graphData={modalData}
                          goToActivity={goToActivity}
                          graphActivities={graphActivities}
                        />
                      </div>
                    </>
                  )}
                </div>
                <div className={classes.activityContainer}>
                  <ActivityList
                    handleClose={handleClose}
                    activities={activities}
                  />
                </div>
              </Box>
            </Modal>
            <ReportsModal
              showReportsModal={showReportsModal}
              handleClose={() => {
                setShowReportsModal(false);
              }}
              patientId={patientId}
              props={props}
            />
            <SummaryModal
              showModal={showSummaryModal}
              closeModal={() => setShowSummaryModal(false)}
              patientId={patientId}
            />
          </div>
        </>
      ) : (
        <Typography className={classes.noData}>No Data Available</Typography>
      )}
    </>
  );
};

export default withStyles(cgmProgressDrawerStyle)(CgmProgressDrawer);
