/**
 @desc
 * Centralized unique actions for All Patients Module.
 */
import { CreateReportActions, reportTypesId } from "../constants";

export const updateReport = (data) => {
  return (dispatch) => {
    dispatch({ type: CreateReportActions.update_report, payload: data });
  };
};

export const updateReportSummary = (data) => {
  return (dispatch) => {
    dispatch({
      type: CreateReportActions.update_report_summary,
      payload: data,
    });
  };
};

export const updatePatientInfo = (patientInfo) => {
  return (dispatch) => {
    dispatch({
      type: CreateReportActions.update_patient_info,
      payload: patientInfo,
    });
  };
};

export const updateReportPatientProgress = (data) => {
  return (dispatch) => {
    dispatch({
      type: CreateReportActions.update_report_progress,
      payload: data,
    });
  };
};

export const setGraphData = ({
  reportType,
  metricData,
  insights,
  maxGlucoseValue,
  minGlucoseValue,
}) => {
  return (dispatch) => {
    const payload =
      reportType === reportTypesId.fourteenthDayReport
        ? { fourteenthDayGraphData: metricData }
        : { seventhDayGraphData: metricData };

    dispatch({
      type: CreateReportActions.set_graph_data,
      payload: { ...payload, insights, maxGlucoseValue, minGlucoseValue },
    });
  };
};

export const setReportId = (reportId) => {
  return (dispatch) => {
    dispatch({
      type: CreateReportActions.set_active_report_id,
      payload: reportId,
    });
  };
};

export const setReportName = (reportName) => {
  return (dispatch) => {
    dispatch({
      type: CreateReportActions.set_active_report_name,
      payload: reportName,
    });
  };
};

export const setSlectedReportType = (reporType) => {
  return (dispatch) => {
    dispatch({
      type: CreateReportActions.set_fileTypeSelected,
      payload: reporType,
    });
  };
};

export const setDayWiseCGMData = (dayWiseCGMData) => {
  return (dispatch) => {
    dispatch({
      type: CreateReportActions.set_day_wise_CGM_data,
      payload: dayWiseCGMData,
    });
  };
};
