import { saveReportData } from "@services/createReportServices";
import {
  healthHabitsKey,
  healthHabitsKeys,
} from "./components/SummaryTab/helpers";
import { reportTypesId, reportTypeForApi } from "./constants";

export const reportFormattedData = (data, type, patientId, selectedSensor) => {
  if (!data || !data?.[type] || !patientId || !selectedSensor?.id) return null;

  let outputArray = [];

  data?.[type]?.data?.map((row) => {
    for (let key in row) {
      let tableRow = {};
      if (row[key]?.customMessage) {
        tableRow = {
          ...tableRow,
          message: row[key]?.customMessage,
          messageId: row[key]?.messageId,
          isCustom: true,
          status: row[key]?.type?.toLowerCase(),
          ...(row[key]?.subType && { subType: row[key]?.subType }),
          ...(row[key]?.tableType && { type: row[key]?.tableType }),
          ...(row[key]?.dayPart && { dayPart: row[key]?.dayPart }),
        };
        outputArray.push(tableRow);
      } else if (row[key]?.text) {
        tableRow = {
          ...tableRow,
          status: row[key]?.type?.toLowerCase(),
          message: row[key]?.text,
          messageId: row[key]?.messageId,
          ...(row[key]?.subType && { subType: row[key]?.subType }),
          ...(row[key]?.tableType && { type: row[key]?.tableType }),
          ...(row[key]?.dayPart && { dayPart: row[key]?.dayPart }),
        };
        outputArray.push(tableRow);
      } else if (row[key]?.type) {
        tableRow = {
          ...tableRow,
          status: row[key]?.type?.toLowerCase(),
          messageId: null,
          ...(row[key]?.subType && { subType: row[key]?.subType }),
          ...(row[key]?.tableType && { type: row[key]?.tableType }),
          ...(row[key]?.dayPart && { dayPart: row[key]?.dayPart }),
        };
        outputArray.push(tableRow);
      }
    }
  });

  return outputArray;
};

export const getCommonCoachReportPayload = ({
  activeFileType,
  tableTypes,
  patientId,
  selectedSensor,
  createReportReduxState,
  reportId,
  reportName,
}) => {
  const { fourteenthDayReport, seventhDayReport } = createReportReduxState;

  let res = {};

  if (activeFileType === reportTypesId.seventhDayReport) {
    let insightOutputData = reportFormattedData(
      seventhDayReport,
      tableTypes.insight,
      patientId,
      selectedSensor
    );
    let interventionOutputData = reportFormattedData(
      seventhDayReport,
      tableTypes.intervention,
      patientId,
      selectedSensor
    );

    let dailyPattern = [
      ...(insightOutputData || []),
      ...(interventionOutputData || []),
    ];

    const newPatientInfo = {};

    Object.keys(createReportReduxState?.patientInfo || {}).forEach((key) => {
      if (key === requiredFieldsMapKey) return;
      newPatientInfo[key] = createReportReduxState?.patientInfo?.[key];
    });

    res = {
      ...res,
      reportId,
      fileName: reportName,
      data: {
        patientInfo: newPatientInfo,
        dailyPattern,
        patientProgress: {}, // patientProgress tab is not enabled for 7th day report
        summary: {}, // summary tab is not enabled for 7th day report
      },
    };
    return res;
  }

  if (activeFileType === reportTypesId.fourteenthDayReport) {
    let dailyPatternData = reportFormattedData(
      fourteenthDayReport,
      tableTypes.insight,
      patientId,
      selectedSensor
    );

    const newPatientInfo = {};
    Object.keys(createReportReduxState?.patientInfo || {}).forEach((key) => {
      if (key === requiredFieldsMapKey) return;
      newPatientInfo[key] = createReportReduxState?.patientInfo?.[key];
    });

    const newPatientProgress = {};
    Object.keys(createReportReduxState?.patientProgress || {}).forEach(
      (key) => {
        if (key === requiredFieldsMapKey) return;
        newPatientProgress[key] =
          createReportReduxState?.patientProgress?.[key];
      }
    );

    const newSummary = {};
    Object.keys(createReportReduxState?.summary || {}).forEach((key) => {
      if (key === requiredFieldsMapKey) return;
      newSummary[key] = createReportReduxState?.patientInfo?.[key];
    });

    res = {
      ...res,
      reportId,
      fileName: reportName,
      data: {
        patientInfo: newPatientInfo,
        dailyPattern: dailyPatternData,
        patientProgress: newPatientProgress,
        summary: newSummary,
      },
    };

    return res;
  }

  return res;
};

export const saveReportDataByReportType = (
  activeFileType,
  tableTypes,
  patientId,
  selectedSensor,
  createReportReduxState,
  reportId,
  reportName
) => {
  const { fourteenthDayReport, seventhDayReportData } = createReportReduxState;

  if (activeFileType === reportTypesId.seventhDayReport) {
    let insightOutputData = reportFormattedData(
      seventhDayReportData,
      tableTypes.insight,
      patientId,
      selectedSensor
    );
    let interventionOutputData = reportFormattedData(
      seventhDayReportData,
      tableTypes.intervention,
      patientId,
      selectedSensor
    );

    if (insightOutputData || interventionOutputData) {
      saveReportData({
        reportId,
        fileName: reportName,
        data: {
          patientInfo: createReportReduxState?.patientInfo,
          dailyPattern: [...insightOutputData, ...interventionOutputData],
        },
        reportType: reportTypeForApi.seventhDay,
      });
    }
  } else {
    let dailyPatternData = reportFormattedData(
      fourteenthDayReport,
      tableTypes.insight,
      patientId,
      selectedSensor
    );

    saveReportData({
      reportId,
      fileName: reportName,
      data: {
        patientInfo: createReportReduxState?.patientInfo,
        dailyPattern: dailyPatternData,
        patientProgress: createReportReduxState?.patientProgress,
        summary: createReportReduxState?.summary,
      },
      reportType: reportTypeForApi.overall,
    });
  }
};

export const getMaxCharLength = (data) => {
  let maxLength = 0;
  for (let i = 0; i <= data.length; i++) {
    const elem = data[i];

    if (!elem.message && !elem.customMessage) {
      return;
    }

    maxLength = Math.max(
      maxLength,
      elem.message ? elem.message.length : 0,
      elem.customMessage ? elem.customMessage.length : 0
    );
  }

  return maxLength;
};

export const getPatientInfoErrorObj = (patientInfo) => {
  let errorObject = {
    heading: "Patient Info",
    errors: [],
  };

  const requiredKeys = patientInfo[requiredFieldsMapKey];

  Object.keys(patientInfo)?.forEach((patientInfoKey) => {
    if (patientInfoKey === requiredFieldsMapKey) return;

    if (patientInfoKey === "goal") {
      let goals = patientInfo.goal;
      if (!goals?.length) {
        errorObject.errors.push(requiredKeys[patientInfoKey].errorText);
      }
      return;
    }

    if (requiredKeys[patientInfoKey] && !patientInfo?.[patientInfoKey]) {
      errorObject.errors.push(requiredKeys[patientInfoKey]?.errorText);
    }
  });

  return errorObject;
};

export const getSeventhDayReportErrorObj = (seventhDayReportData) => {
  let errorObject = {
    heading: "Daily Pattern, 7th Day Report",
    errors: [],
  };

  let insightData = seventhDayReportData.insight.data;
  let insightRequiredFieldObj =
    seventhDayReportData.requiredFieldsMap?.insight || {};

  insightData.forEach((insight) => {
    const currentRow = insight?.rowName;
    const requiredFieldItems = insightRequiredFieldObj[currentRow];

    if (!requiredFieldItems) return;

    const keysToBeChecked = Object.keys(requiredFieldItems);

    keysToBeChecked.forEach((key) => {
      if (insight?.[key]?.type) return;
      requiredFieldItems[key]?.errorText &&
        errorObject.errors.push(requiredFieldItems[key]?.errorText);
    });
  });

  // intervention
  let interventionData = seventhDayReportData.intervention.data;
  let interventionRequiredFieldObj =
    seventhDayReportData.requiredFieldsMap?.intervention || {};

  interventionData.forEach((intervention) => {
    const currentRow = intervention?.rowName;
    const requiredFieldItems = interventionRequiredFieldObj[currentRow];

    if (!requiredFieldItems) return;

    const keysToBeChecked = Object.keys(requiredFieldItems);

    keysToBeChecked.forEach((key) => {
      if (intervention?.[key]?.type) return;
      requiredFieldItems[key]?.errorText &&
        errorObject.errors.push(requiredFieldItems[key]?.errorText);
    });
  });

  return errorObject;
};

export const getFourteenthDayReportErrorObj = (fourteenthDayReport) => {
  let errorObject = {
    heading: "Daily Pattern, 14th Day Report",
    errors: [],
  };

  let insightData = fourteenthDayReport.insight.data;
  let insightRequiredFieldObj =
    fourteenthDayReport[requiredFieldsMapKey]?.insight || {};

  // validation for insight
  insightData.forEach((insight) => {
    const currentRow = insight?.rowName;
    const requiredFieldItems = insightRequiredFieldObj[currentRow];

    if (!requiredFieldItems) return;

    const keysToBeChecked = Object.keys(requiredFieldItems);

    keysToBeChecked.forEach((key) => {
      if (insight?.[key]?.type) return;
      requiredFieldItems[key]?.errorText &&
        errorObject.errors.push(requiredFieldItems[key]?.errorText);
    });
  });

  // validation for `intervention` not there as of now.
  return errorObject;
};

export const getDailyPatternErrorObj = ({ context, data }) => {
  let errorObject = {
    heading: "",
    errors: [],
  };

  const { fourteenthDayReport, seventhDayReport } = data;

  switch (context) {
    case reportTypesId.seventhDayReport: {
      errorObject = getSeventhDayReportErrorObj(seventhDayReport);
      break;
    }

    case reportTypesId.fourteenthDayReport: {
      errorObject = getFourteenthDayReportErrorObj(fourteenthDayReport);
      break;
    }
  }

  return errorObject;
};

export const getPatientProgressErrorObj = (patientProgress, context) => {
  let errorObject = {
    heading: "Patient Progress",
    errors: [],
  };

  // As of 23 FEB, 2023
  // Patient Progress Tab is enabled only for 14 Day Report Only.
  if (context === reportTypesId.seventhDayReport) {
    return errorObject;
  }

  const requiredItems = patientProgress?.[requiredFieldsMapKey];
  const requiredKeyList = Object.keys(requiredItems || {});

  requiredKeyList?.forEach((key) => {
    const requiredItemValue = requiredItems[key];
    Object.keys(requiredItemValue)?.forEach((requiredItemValueKey) => {
      if (!(requiredItemValueKey in patientProgress?.[key])) return;
      if (patientProgress[key][requiredItemValueKey]) return;
      errorObject.errors.push(
        requiredItemValue[requiredItemValueKey]?.errorText
      );
    });
  });

  return errorObject;
};

export const requiredFieldsMapKey = "requiredFieldsMap";

export const getPatientSummaryErrorObj = (summary, context) => {
  const errorConfig = { heading: "Patient Summary", errors: [] };

  // As of 23 FEB, 2023
  // `Summary` Tab is enabled only for 14 Day Report Only.
  if (context === reportTypesId.seventhDayReport) {
    return errorConfig;
  }

  const requiredFieldsMap = summary[requiredFieldsMapKey];

  for (const [parnetKey, parentValue] of Object.entries(requiredFieldsMap)) {
    const summaryColumnState = summary[parnetKey];

    if (parnetKey === healthHabitsKey) {
      if (
        !summaryColumnState[healthHabitsKeys.healthProfile] &&
        !summaryColumnState[healthHabitsKeys.lifeStyleManagement]
      ) {
        errorConfig.errors.push(requiredFieldsMap[healthHabitsKey]?.errorText);
      }
    } else {
      for (const [childKey, childValue] of Object.entries(parentValue)) {
        if (!summaryColumnState[childKey]) {
          errorConfig.errors.push(parentValue[childKey]?.errorText);
        }
      }
    }
  }

  return errorConfig;
};

export const clearRequiredFieldState = ({
  fourteenthDayReport,
  seventhDayReportData,
  summary,
  patientProgress,
  patientInfo,
}) => {
  const dataForSave = JSON.parse(
    JSON.stringify({
      fourteenthDayReport,
      seventhDayReportData,
      summary,
      patientProgress,
      patientInfo,
    })
  );

  const dataForSaveValues = Object.values(dataForSave);

  for (const key in dataForSaveValues) {
    if (dataForSaveValues[key][requiredFieldsMapKey]) {
      delete dataForSaveValues[key][requiredFieldsMapKey];
    }
  }

  return dataForSave;
};
export const maxHba1climit = 20;

export const maxPatientHeight = 300;

export const maxPatientWeight = 300;

export const maxPatientAge = 100;

const getReportMetaDataErrorObj = (reportData) => {
  const { reportName } = reportData;

  let errorObject = {
    heading: "Report Info",
    errors: [],
  };

  if (!reportName) {
    errorObject.errors.push("Missing File Name");
  }

  return errorObject;
};

export const validateCreateReportFields = ({ context, data }) => {
  const { summary, patientProgress, patientInfo, reportName } = data;

  const result = {
    hasErr: false,
    errors: [],
  };

  const hasError = (errObj) => !!errObj?.errors?.length;

  const reportData = {
    reportName,
  };
  const reportMetaDataErrorObj = getReportMetaDataErrorObj(reportData);
  if (hasError(reportMetaDataErrorObj)) {
    result.hasErr = true;
    result.errors.push(reportMetaDataErrorObj);
  }

  const patientInfoErrorObj = getPatientInfoErrorObj(patientInfo);
  if (hasError(patientInfoErrorObj)) {
    result.hasErr = true;
    result.errors.push(patientInfoErrorObj);
  }

  const dailyPatternErrorObj = getDailyPatternErrorObj({ context, data });
  if (hasError(dailyPatternErrorObj)) {
    result.hasErr = true;
    result.errors.push(dailyPatternErrorObj);
  }

  const summaryErrorObj = getPatientSummaryErrorObj(summary, context);
  if (hasError(summaryErrorObj)) {
    result.hasErr = true;
    result.errors.push(summaryErrorObj);
  }

  const patientProgressErrorObj = getPatientProgressErrorObj(
    patientProgress,
    context
  );
  if (hasError(patientProgressErrorObj)) {
    result.hasErr = true;
    result.errors.push(patientProgressErrorObj);
  }

  return result;
};
