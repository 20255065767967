import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { Formik } from "formik";
import uniqBy from "lodash/uniqBy";
import { Close } from "@material-ui/icons";
import { Typography } from "@material-ui/core";

import api from "utils/api";
import { Search } from "assets/icons";
import CustomButtons from "@components/atoms/Button";
import FormSelectField from "@components/molecules/FormSelectField";

import Cards from "../Cards/Cards";
import ModuleDrawer from "../ModuleDrawer/ModuleDrawer";

const buttonStyle = {
  color: "#8C8C8C",
  marginTop: "8px",
  marginRight: "8px",
  minWidth: "unset",
  padding: "6px 5px",
  textTransform: "uppercase",
  border: "1px solid #2B377E",
};

const AVAILABLE_SKS = ["start", "keep", "stop", "learn", "excercise"];

const ModuleNewLibraryDrawer = ({
  onSave,
  selectedActivities,
  onRemove,
  isNewWeekPlan,
  isDuplicateWeekPlan,
}) => {
  const [active, setActive] = useState(0);
  const [search, setSearch] = useState("");
  const [authors, setAuthors] = useState([]);
  const [activities, setActivities] = useState([]);
  const [excercises, setExcercises] = useState([]);
  const [exercisedrawer, setExerciseDrawer] = useState(0);
  const [selectedActivity, setSelectedActivity] = useState(null); //  activity | null
  const [learnModuleCategories, setLearnModuleCategories] = useState([]);

  useEffect(() => {
    api(`/v1/activities`, {
      method: "GET",
    })
      .then(({ data }) => {
        const startKeepStopActivities =
          data &&
          data?.data.length > 0 &&
          data?.data.map(
            ({ createdAt, createdBy, updatedAt, updatedBy, ...rest }) => {
              return rest;
            }
          );
        setActivities(startKeepStopActivities);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    api(`/v1/exercises`, {
      method: "GET",
    })
      .then(({ data }) => {
        let exerciseData = data && data?.data.length > 0 && data?.data;
        exerciseData = exerciseData?.map(
          ({ createdAt, createdBy, updatedAt, updatedBy, ...rest }) => ({
            ...rest,
            title: rest.videoName,
          })
        );
        setExcercises(exerciseData);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    axios(`https://strapi.onecare.co.in/categories`, {
      method: "GET",
    })
      .then(({ data }) => {
        setLearnModuleCategories(data);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    axios(`https://strapi.onecare.co.in/authors`, {
      method: "GET",
    })
      .then(({ data }) => {
        setAuthors(data);
      })
      .catch(console.error);
  }, []);

  /**
   * set selected activity to null if sidebar details drawer is closed
   */
  useEffect(() => {
    if (!exercisedrawer) {
      setSelectedActivity(null);
    }
  }, [exercisedrawer]);

  const getAuthor = useCallback(
    (id) => authors.find((author) => author.id === id),
    [authors]
  );
  return (
    <>
      {exercisedrawer > 0 && (
        <ModuleDrawer
          activity={{
            ...selectedActivity,
            authorName: getAuthor(selectedActivity.author)?.name,
            authorAvatar: getAuthor(selectedActivity.author)?.avatar?.url,
          }}
          heading={AVAILABLE_SKS[active]}
          setExerciseDrawer={setExerciseDrawer}
          isStartVisible={exercisedrawer === 1}
          isLearnVisible={exercisedrawer === 2}
          isExerciseVisible={exercisedrawer === 3}
          onSave={(activity) => {
            onSave(activity, AVAILABLE_SKS[active]);
            setExerciseDrawer(0);
          }}
        />
      )}
      <Formik
        initialValues={{
          when: "",
          time: "",
          level: "",
          category: "",
          youtubeUrl: "",
        }}
      >
        {({ values }) => {
          return (
            <div style={{ paddingBottom: "50px" }}>
              {/* library */}
              <div
                style={{
                  top: 0,
                  zIndex: 1,
                  display: "flex",
                  minHeight: "64px",
                  maxHeight: "64px",
                  position: "sticky",
                  padding: "0px 24px",
                  alignItems: "center",
                  backgroundColor: "white",
                  borderBottom: "1px solid #ccc",
                  justifyContent: "space-between",
                }}
              >
                <Typography style={{ fontSize: "16px", fontWeight: 500 }}>
                  Library
                </Typography>
              </div>

              {/* tabs */}
              <div style={{ padding: "16px" }}>
                <flex>
                  {AVAILABLE_SKS.map((sks, index) => (
                    <CustomButtons
                      key={sks}
                      md_border
                      style={{
                        ...buttonStyle,
                        backgroundColor:
                          active === index ? "#2B377E" : undefined,
                        color: active === index ? "white" : undefined,
                      }}
                      onClick={() => setActive(index)}
                    >
                      {sks}
                    </CustomButtons>
                  ))}
                </flex>
              </div>

              {active === 3 && (
                <>
                  <div
                    style={{
                      height: "40px",
                      borderRadius: "4px",
                      background: "#FFFFFF",
                      padding: "0px 16px 16px 16px",
                    }}
                  >
                    <FormSelectField
                      typeOf="auth"
                      type="patient"
                      name="category"
                      label="All Categories"
                      placeholder="All Categories"
                      value={values.category ?? ""}
                      options={learnModuleCategories?.map((category) => ({
                        label: category.name,
                        value: category.id,
                      }))}
                    />
                  </div>
                </>
              )}

              {/* search */}
              <div
                style={{
                  width: "293px",
                  position: "relative",
                  paddingLeft: "16px",
                }}
              >
                <input
                  placeholder="Search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  style={{
                    outline: "none",
                    fontSize: "14px",
                    borderRadius: "4px",
                    height: "min-content",
                    width: "calc(100% - 25px)",
                    border: "1px solid #D9D9D9",
                    padding: "12px 12px 12px 28px",
                  }}
                />
                {!search && (
                  <img
                    alt=""
                    src={Search}
                    style={{ position: "absolute", left: 24, top: 15 }}
                  />
                )}
                {search && (
                  <div
                    style={{
                      top: "10px",
                      left: "18px",
                      color: "#D9D9D9",
                      cursor: "pointer",
                      position: "absolute",
                    }}
                    onClick={() => setSearch("")}
                  >
                    <Close />
                  </div>
                )}
              </div>
              {
                active < 3 && (
                  <Cards
                    onAdd={(activity) => {
                      return onSave(
                        { ...activity, activityId: activity.activityId },
                        AVAILABLE_SKS[active]
                      );
                    }}
                    onRemove={(activity) =>
                      onRemove(activity, AVAILABLE_SKS[active])
                    }
                    activitiesAdded={
                      selectedActivities[AVAILABLE_SKS[active]] || []
                    }
                    setExerciseDrawer={(activity) => {
                      setExerciseDrawer(1);
                      setSelectedActivity(activity);
                    }}
                    data={activities.filter(
                      (activity) =>
                        activity.SKS === AVAILABLE_SKS[active] &&
                        activity.title
                          .toLowerCase()
                          .includes(search.toLowerCase())
                    )}
                    activities={AVAILABLE_SKS[active]}
                    isNewWeekPlan={isNewWeekPlan}
                    isDuplicateWeekPlan={isDuplicateWeekPlan}
                  />
                )
                // <Cards
                //   data={exerciseData}
                //   visibleOnExerciseBtn
                //   setExerciseDrawer={setExerciseDrawer}
                // />,
              }
              {active === 3 && (
                <Cards
                  onAdd={(activity) =>
                    onSave(
                      {
                        ...activity,
                        activityId: activity.id,
                        authorName: getAuthor(activity.author)?.name,
                        authorAvatar: getAuthor(activity.author)?.avatar?.url,
                      },
                      AVAILABLE_SKS[active]
                    )
                  }
                  onRemove={(activity) =>
                    onRemove(activity, AVAILABLE_SKS[active])
                  }
                  activitiesAdded={
                    selectedActivities[AVAILABLE_SKS[active]] || []
                  }
                  data={(
                    (!values.category
                      ? uniqBy(
                          learnModuleCategories.flatMap(
                            (category) => category.blogs
                          ),
                          "id"
                        )
                      : learnModuleCategories.find(
                          ({ id }) => id === values.category
                        )?.blogs) || []
                  )
                    .map((a) => ({
                      ...a,
                      category: "",
                      strapiCategory: learnModuleCategories.find(
                        ({ id }) => id === a.category
                      )?.name,
                    }))
                    .filter((learn) =>
                      learn.title.toLowerCase().includes(search.toLowerCase())
                    )}
                  setExerciseDrawer={(blog) => {
                    setSelectedActivity(blog);
                    setExerciseDrawer(2);
                  }}
                  activities={AVAILABLE_SKS[active]}
                  isNewWeekPlan={isNewWeekPlan}
                  isDuplicateWeekPlan={isDuplicateWeekPlan}
                />
              )}
              {active === 4 && (
                <Cards
                  onAdd={(activity) =>
                    onSave(
                      {
                        ...activity,
                        activityId: activity.id,
                      },
                      AVAILABLE_SKS[active]
                    )
                  }
                  onRemove={(activity) =>
                    onRemove(activity, AVAILABLE_SKS[active])
                  }
                  activitiesAdded={
                    selectedActivities[AVAILABLE_SKS[active]] || []
                  }
                  data={excercises.filter((excercise) =>
                    excercise.title.toLowerCase().includes(search.toLowerCase())
                  )}
                  setExerciseDrawer={(blog) => {
                    setSelectedActivity(blog);
                    setExerciseDrawer(3);
                  }}
                  activities={AVAILABLE_SKS[active]}
                  isNewWeekPlan={isNewWeekPlan}
                  isDuplicateWeekPlan={isDuplicateWeekPlan}
                />
              )}
            </div>
          );
        }}
      </Formik>
    </>
  );
};

export default ModuleNewLibraryDrawer;
