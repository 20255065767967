import React from "react";
import { Box, Typography, withStyles } from "@material-ui/core";
import doctorInstructionStyle from "assets/jss/pages/doctorInstructionStyle";

const Instruction = ({
  id,
  index,
  sentDate,
  instruction,
  classes,
  onPress,
  selectedInstruction,
}) => {
  const instructionStyle = {
    ...(id === selectedInstruction?.id ? { backgroundColor: "#F4FBFD" } : {}),
    ...(index === 0 ? {} : { borderTop: "0px" }),
  };

  return (
    <Box
      onClick={onPress}
      className={classes.instructionContainer}
      style={instructionStyle}
    >
      <Box className={classes.sentFor}>
        <Typography noWrap={true} className={classes.sentDate}>
          {sentDate}
        </Typography>
        <Typography noWrap={true} className={classes.instruction}>
          {instruction}
        </Typography>
      </Box>
      <Typography className={classes.viewDetails} onClick={onPress}>
        {"View Detail"}
      </Typography>
    </Box>
  );
};

export default withStyles(doctorInstructionStyle)(Instruction);
