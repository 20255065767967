/**
 @desc
 * Centralized unique actions for Login Module.
 */

import {
  actionCreator,
  checkHttpStatus,
  jsonApiHeader,
  showErrorMessage,
  LoginActionTypes,
  POST_LOGIN_API,
  POST_REFRESH_TOKEN_API,
} from "../constants";
import {
  handleLoginRedirect,
  handleLogoutRedirect,
  showSuccessMessage,
  TOKEN_EXPIRY_MESSAGE,
} from "../../../utils/utility";
import { userRoles } from "utils/constants";

/**
 * @param  {[object]} postData [The data needed as a payload for the API interaction]
 */
export const LoginUserAction = (postData) => {
  return (dispatch) => {
    dispatch(actionCreator(LoginActionTypes.post_Login.REQUEST));
    fetch(`${POST_LOGIN_API}`, {
      method: "POST",
      body: JSON.stringify(postData),
      headers: jsonApiHeader(),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status === "FAILURE") {
          dispatch(
            actionCreator(LoginActionTypes.post_Login.FAILURE, response)
          );
          showErrorMessage(response.message);
        } else {
          handleLoginRedirect(response);
          setTimeout(() => {
            dispatch(
              actionCreator(LoginActionTypes.post_Login.SUCCESS, response)
            );
          }, 100);
          showSuccessMessage("Logged in successfully!");
        }
      })
      .catch(function (error) {
        dispatch(actionCreator(LoginActionTypes.post_Login.FAILURE));
        console.log("error post_Login ..", error);
      });
  };
};

/**
 * @note [token is going in the header]
 * @param  {} requestToken [Token needed as a payload for the API interaction]
 */
export const RefreshTokenAction = (requestToken, userId) => {
  return (dispatch) => {
    let postData = {
      userId: userId,
    };
    dispatch(actionCreator(LoginActionTypes.post_RefreshTokenApi.REQUEST));
    fetch(`${POST_REFRESH_TOKEN_API}`, {
      method: "POST",
      body: JSON.stringify(postData),
      headers: jsonApiHeader(requestToken),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(
            actionCreator(
              LoginActionTypes.post_RefreshTokenApi.FAILURE,
              response
            )
          );
        } else if (response.status.toUpperCase() === "EXPIRED") {
          if (
            localStorage.getItem("LoggedOut") &&
            localStorage.getItem("LoggedOut") === "true"
          ) {
            handleLogoutRedirect(response);
            dispatch(
              actionCreator(
                LoginActionTypes.post_RefreshTokenApi.FAILURE,
                response
              )
            );
          } else {
            showErrorMessage(TOKEN_EXPIRY_MESSAGE);
            localStorage.clear();
            dispatch(
              actionCreator(
                LoginActionTypes.post_RefreshTokenApi.FAILURE,
                response
              )
            );
          }
        } else {
          dispatch(
            actionCreator(
              LoginActionTypes.post_RefreshTokenApi.SUCCESS,
              response
            )
          );
        }
      })
      .catch(function (error) {
        dispatch(actionCreator(LoginActionTypes.post_RefreshTokenApi.FAILURE));
        console.log("error post_RefreshTokenApi ..", error);
      });
  };
};
