import { AllPatientsActionTypes } from "../constants";

const initialState = {
  loading: false,
  allPatients: null,
  selectedPatient: null,
};

const allPatientsReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case AllPatientsActionTypes.get_AllPatients.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case AllPatientsActionTypes.get_AllPatients.SUCCESS:
      return {
        ...state,
        loading: false,
        allPatients: payload,
      };
    case AllPatientsActionTypes.get_AllPatients.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case AllPatientsActionTypes.setSelectedPatient:
      return {
        ...state,
        selectedPatient: payload,
      };
    default:
      return state;
  }
};

export default allPatientsReducers;
