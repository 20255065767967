const dailyPatternTabStyle = () => ({
  container: {
    margin: "0 0 24px 0",
  },
  summaryGraphCard: {
    width: "100%",
    height: "275px",
    backgroundColor: "white",
    marginTop: "17px",
    marginBottom: "17px",
  },
  graphContainer: {
    width: "60%",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
  },
  tabHeadingText: {
    fontSize: "16px",
    fontWeight: 700,
    color: "#262626",
    marginTop: "16px",
  },
  radioLableText: {
    fontSize: "14px",
    fontWeight: 400,
  },
  dropDownContainer: {
    display: "flex",
    height: "40px",
    backgroundColor: "white",
    width: "765px",
    border: "1px solid #D9D9D9",
    marginTop: "16px",
  },
  tableContainer: {
    marginBottom: "17px",
  },
});

export default dailyPatternTabStyle;
