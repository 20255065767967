/**
 @desc
 * Centralized unique actions for Edit Appointment Module.
 */

import {
  actionCreator,
  checkHttpStatus,
  jsonApiHeader,
  showErrorMessage,
  EditAppointmentActionTypes,
  UPDATE_APPOINTMENT,
} from "../constants";
import { TOKEN_EXPIRY_MESSAGE } from "../../../utils/utility";

/**
 * @param  {[object]} postData [The data needed as a payload for the API interaction]
 */

export const UpdateAppointmentAction = (postData, appointmentId, patientId) => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.token;
    dispatch(
      actionCreator(EditAppointmentActionTypes.update_Appointment.REQUEST)
    );
    fetch(`${UPDATE_APPOINTMENT}/${appointmentId}`, {
      method: "PATCH",
      body: JSON.stringify(postData),
      headers: jsonApiHeader(token),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(
            actionCreator(
              EditAppointmentActionTypes.update_Appointment.FAILURE,
              response
            )
          );
          showErrorMessage(response.message);
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage(TOKEN_EXPIRY_MESSAGE);
          localStorage.clear();
          dispatch(
            actionCreator(
              EditAppointmentActionTypes.update_Appointment.FAILURE,
              response
            )
          );
          window.location.reload();
        } else {
          setTimeout(() => {
            dispatch(
              actionCreator(
                EditAppointmentActionTypes.update_Appointment.SUCCESS,
                response
              )
            );
          }, 100);
        }
      })
      .catch(function (error) {
        dispatch(
          actionCreator(EditAppointmentActionTypes.update_Appointment.FAILURE)
        );
      });
  };
};
