const { makeStyles } = require("@material-ui/core");

export const getDeliveryListStyles = makeStyles(() => ({
  mainContainer: {
    width: "1100px",
    margin: "50px auto auto auto",
    alignSelf: "center",
    height: "450px",
  },
  tableContainer: { width: "100%", borderCollapse: "collapse" },
  tableHeader: { borderBottom: "1px solid #E5E7EB" },
  paginationContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    width: "1100px",
    margin: "auto auto auto auto",
    padding: 16,
  },
  rowContainer: {
    cursor: "pointer",
    height: "50px",
    margin: "15px",
  },
  tableData: {
    justifyContent: "left",
    textAlign: "left",
    padding: "5px",
    height: "30px",
  },
  deliveryStatus: {
    color: "#14804A",
    backgroundColor: "#E1FCEF",
    padding: "1px, 10px, 1px, 10px",
    borderRadius: "10px",
  },
  justifyContentCenter: {
    justifyContent: "center",
  },
  textAlignLeft: {
    textAlign: "left",
    fontSize: "14px",
  },
  deliveryStatusTableData: {
    justifyContent: "center",
    textAlign: "center",
    fontSize: "10px",
  },
  addOnProductText: {
    color: "#BFA100",
    textAlign: "left",
  },
  trackingId: {
    textAlign: "left",
    color: "black",
    fontSize: "13px",
  },
  withoutTrackingId: {
    textAlign: "left",
    color: "#FF5A5F",
    fontSize: "13px",
  },
  subText: {
    fontSize: "13px",
    textAlign: "left",
    color: "#5A5A5A",
  },
  mainText: {
    fontSize: "15px",
    textAlign: "left",
  },
  deliveryStatusText: {
    fontSize: "14px",
    textAlign: "left",
  },

  textAlignCenter: { textAlign: "center" },
  lightOrangeColor: { color: "#BFA100" },
  darkOrange: { color: "#FF825A" },
  header: {
    textAlign: "left",
    padding: "8px",
    fontWeight: "bold",
    fontSize: "14px",
  },
  pagination: {
    width: "auto",
    alignSelf: "left",
    flexGrow: 1,
  },
}));
