import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { idealGraph, healthRiskBar, header } from "@assets/icons";
import lang from "@assets/languages";
import { graphWidth, tableTypes } from "@pages/CreateReport/constants";
import { reportTypesId } from "@pages/CreateReport/constants";
import CgmGraph from "@pages/MetasenseReport/Components/cgmGraph";
import DailyPattenTable from "./Components/dailyPattenTable";
import { GlycemicAndDistressTable } from "@pages/MetasenseReport/Components/glycemicAndDistressTable";
import ProgressTable from "@pages/MetasenseReport/Components/progressTable";
import { GraphHeading } from "@pages/MetasenseReport/Components/reportHeading";
import { useStyles } from "@pages/MetasenseReport/style";
import { getHealthyHabitsRiskScoreStyle } from "@pages/MetasenseReport/helper";
import ReportPage1 from "./clinicAutomatedReport/reportPage1";
import ReportPage2 from "./clinicAutomatedReport/reportPage2";
import { Box } from "@material-ui/core";

const MetasenseReportTemplate = (props) => {
  const {
    fourteenthDayReport,
    seventhDayReportData,
    activeFileType,
    summary: {
      healthyHabitsRiskScore: { value: healthyHabitsValue },
    },
    seventhDayGraphData,
    fourteenthDayGraphData,
    isAutomatedReport = false,
    fixedCgmGraphWidth,
  } = props;

  const classes = useStyles();
  const graphWidthRef = useRef();
  const [cgmGraphParentWidth, setCGMGraphParentWidth] = useState(
    window.screen.width
  );

  useEffect(() => {
    if (fixedCgmGraphWidth) {
      return;
    }

    const width = graphWidthRef?.current?.clientWidth;

    if (width) {
      setCGMGraphParentWidth(width);
    }
  }, []);

  const cgmGraphWidth = fixedCgmGraphWidth || cgmGraphParentWidth * 0.9;

  const idealPlottedGraph = (
    <div>
      <GraphHeading headingText={lang.nonDiabeticHealthyReport} />
      <div className={classes.glycemicAndDistressContainer}>
        <img className={classes.idealGraphImage} src={idealGraph} alt="" />
      </div>
    </div>
  );

  const medicalInsight = (
    <div>
      <GraphHeading
        headingText={lang.medicalInterventionsWillBeTakenCareByTheDoctor}
      />
      <div className={classes.medicalInsightContainer}>
        {lang.medicalInsight}
      </div>
    </div>
  );

  const renderSeventhDayGraph = (id) => (
    <CgmGraph
      graphId={`seventhDayGraph - ${id}`}
      graphData={seventhDayGraphData}
      graphWidth={cgmGraphWidth}
    />
  );

  const renderFourteenthDayGraph = (id) => (
    <CgmGraph
      graphId={`fourteenthDayGraph - ${id}`}
      graphData={fourteenthDayGraphData}
      graphWidth={cgmGraphWidth}
    />
  );

  const graphComparison = (
    <>
      <GraphHeading headingText={lang.yourReport} />
      {activeFileType === reportTypesId.seventhDayReport
        ? renderSeventhDayGraph("compare")
        : renderFourteenthDayGraph("compare")}
      {idealPlottedGraph}
    </>
  );

  return (
    <div className={classes.reportContainer}>
      <div className={classes.pagepageBreakStyle}>
        <ReportPage1 fixedCgmGraphWidth={fixedCgmGraphWidth} />
      </div>
      <div className={classes.pagepageBreakStyle}>
        <ReportPage2 />
      </div>
      {!isAutomatedReport && (
        <>
          {activeFileType === reportTypesId.fourteenthDayReport && (
            <div ref={graphWidthRef} className={classes.pagepageBreakStyle}>
              <img src={header} alt="" className={classes.reportHeader} />
              <Box className={classes.reportPageContent}>
                <div className={classes.healthRiskBarContainer}>
                  <div className={classes.healthRiskBarImageContainer}>
                    <img
                      className={classes.healthRiskBarImage}
                      src={healthRiskBar}
                      alt=""
                    />
                  </div>
                  <div className={classes.healthRiskBarValue}>
                    <div className={classes.healthRiskBarValueHeadingFont}>
                      Health Risk Score
                    </div>
                    {healthyHabitsValue && (
                      <div
                        className={classes.healthRiskBarValueFont}
                        style={getHealthyHabitsRiskScoreStyle(
                          healthyHabitsValue
                        )}
                      >
                        {healthyHabitsValue} mg/dl
                      </div>
                    )}
                  </div>
                </div>
                {graphComparison}
              </Box>
            </div>
          )}
          {activeFileType === reportTypesId.fourteenthDayReport && (
            <div className={classes.pagepageBreakStyle}>
              <img src={header} alt="" className={classes.reportHeader} />
              <Box className={classes.reportPageContent}>
                <GlycemicAndDistressTable />
                <div className={classes.glycemicTableContainer}>
                  <ProgressTable />
                </div>
              </Box>
            </div>
          )}
          {activeFileType === reportTypesId.fourteenthDayReport && (
            <div className={classes.pagepageBreakStyle}>
              <img src={header} alt="" className={classes.reportHeader} />
              <Box className={classes.reportPageContent}>
                <GraphHeading headingText={"0 - 14 days summary"} />
                {renderFourteenthDayGraph("individual")}
                <DailyPattenTable
                  tableType={tableTypes.insight}
                  data={fourteenthDayReport}
                  tableTitle={"14th Day Insight"}
                />
              </Box>
            </div>
          )}
          <div className={classes.pagepageBreakStyle}>
            <img src={header} alt="" className={classes.reportHeader} />
            <Box className={classes.reportPageContent}>
              <GraphHeading headingText={"0 - 7 days summary"} />
              {renderSeventhDayGraph("individual")}
              <DailyPattenTable
                tableType={tableTypes.insight}
                data={seventhDayReportData}
                tableTitle={"7th Day Insight"}
              />
            </Box>
          </div>
          <div>
            <img src={header} alt="" className={classes.reportHeader} />
            <Box className={classes.reportPageContent}>
              <DailyPattenTable
                tableType={tableTypes.intervention}
                data={seventhDayReportData}
                tableTitle={"7th Day Intervention"}
              />
              {activeFileType === reportTypesId.seventhDayReport &&
                medicalInsight}
            </Box>
          </div>
        </>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    fourteenthDayReport: state.createReportReducer.fourteenthDayReport,
    seventhDayReportData: state.createReportReducer.seventhDayReportData,
    activeFileType: state.createReportReducer.activeFileType,
    patientProgress: state.createReportReducer.patientProgress,
    summary: state.createReportReducer.summary,
    seventhDayGraphData: state.createReportReducer.seventhDayGraphData,
    fourteenthDayGraphData: state.createReportReducer.fourteenthDayGraphData,
  };
}
export default connect(mapStateToProps)(MetasenseReportTemplate);
