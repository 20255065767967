import React from "react";
import CustomButtons from "@components/atoms/Button";
import { Typography, makeStyles } from "@material-ui/core";
import { PDF_FILE_TYPE } from "@utils/constants";

const useStyles = makeStyles(() => ({
  viewContainer: {
    fontSize: "14px",
    paddingTop: "15px",
    marginLeft: "1rem",
    color: "#595959",
    display: "block",
  },
  viewText: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#2B377E",
    cursor: "pointer",
    textDecoration: "none",
  },
}));

export const UploadPlan = ({
  heading,
  onUpload,
  onRemove,
  pdfLink,
  pdfName,
}) => {
  const classes = useStyles();

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "1rem",
        }}
      >
        <Typography
          style={{
            fontSize: "14px",
            fontWeight: 700,
            width: "564px",
          }}
        >
          {heading}
        </Typography>
      </div>
      {pdfLink ? (
        <div
          style={{
            display: "flex",
            alignItems: "start",
            width: "564px",
            marginTop: ".5rem",
          }}
        >
          <Typography>
            <CustomButtons
              md_border
              style={{
                padding: "25px 17px",
                backgroundColor: "#fff",
                fontWeight: 700,
              }}
            >
              ✔ File uploaded
            </CustomButtons>
          </Typography>
          <Typography
            style={{
              fontSize: "14px",
              paddingTop: "15px",
              marginLeft: "1rem",
              color: "#595959",
              display: "block",
            }}
          >
            {pdfName}
          </Typography>
          <Typography className={classes.viewContainer}>
            <a
              href={pdfLink}
              target="_blank"
              rel="noreferrer"
              className={classes.viewText}
            >
              View
            </a>
          </Typography>
          <div onClick={onRemove}>
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: 700,
                color: "#F0647D",
                paddingTop: "15px",
                marginLeft: "1rem",
                cursor: "pointer",
                display: "block",
              }}
            >
              Remove
            </Typography>
          </div>
        </div>
      ) : (
        <label
          style={{
            fontWeight: 700,
            padding: "17px",
            fontSize: "14px",
            color: "#2B377E",
            cursor: "pointer",
            marginTop: "10px",
            marginBottom: "20px",
            alignItems: "center",
            display: "inline-grid",
            backgroundColor: "#fff",
            border: "1px solid #D9D9D9",
          }}
        >
          <input
            hidden
            type="file"
            accept={PDF_FILE_TYPE}
            onChange={onUpload}
          />
          UPLOAD PDF
        </label>
      )}
    </>
  );
};
