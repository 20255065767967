import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles, Typography } from "@material-ui/core";
import { STRAPI_API_URL } from "../../../../utils/utility";
import Loader from "@components/atoms/Loader";
import BrokenImageSharpIcon from "@material-ui/icons/BrokenImageSharp";
import moment from "moment";
import learnMoreDrawerStyle from "../../../../assets/jss/pages/components/learnMoreDrawerStyle";

const LearnMoreDrawer = (props) => {
  const { classes, currentWeekPlan, category } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [blogs, setBlogs] = useState([]);

  const filteredBlogs =
    blogs?.length > 0 &&
    category &&
    blogs?.filter((blog) => blog?.category?.name === category);

  useEffect(() => {
    fetchBlogs();
  }, []);

  const fetchBlogs = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`${STRAPI_API_URL}/blogs`);
      const json = await response.json();
      setBlogs(json);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    }
  };

  let settings = {
    dots: true,
    infinite: true,
    arrows: false,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: classes.slide,
  };

  return (
    <>
      {isLoading && <Loader open={true} loaderMessage="Fetching..." />}
      <div className={classes.headerContainer}>
        <Typography
          className={classes.headerText}
          onClick={() => props.handleGoToWeekPlan(currentWeekPlan.id)}
        >
          Back to WeekPlans
        </Typography>
        <CloseIcon className={classes.icon} onClick={props.handleCloseDrawer} />
      </div>
      {!filteredBlogs && (
        <div className={classes.emptyContainer}>
          <Typography
            style={{
              color: "#BFBFBF",
              fontSize: "16px",
              fontWeight: 500,
            }}
          >
            No data to show
          </Typography>
        </div>
      )}
      {filteredBlogs?.length > 0 && (
        <Slider {...settings}>
          {filteredBlogs?.map((item, index) => {
            return (
              <div
                key={index}
                onClick={() => props.handleGoToLearnMoreDetails(item)}
              >
                {item.banner?.url && (
                  <img
                    src={item.banner?.url}
                    alt="img"
                    height="200px"
                    width="100%"
                  />
                )}
                {!item.banner?.url && (
                  <div
                    style={{
                      height: "200px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "grey",
                    }}
                  >
                    <BrokenImageSharpIcon />
                    <Typography
                      style={{
                        color: "#fff",
                        fontSize: "12px",
                        fontWeight: 500,
                      }}
                    >
                      No image available
                    </Typography>
                  </div>
                )}
                <div className={classes.padded}>
                  <Typography className={classes.text}>{item.title}</Typography>
                  <Typography style={{ fontSize: "12px", color: "#8C8C8C" }}>
                    Published on{" "}
                    {moment(item.published_at).format("Do MMMM, YYYY")}
                  </Typography>
                  <Typography className={classes.sub_text}>
                    {item.description}
                  </Typography>
                </div>
              </div>
            );
          })}
        </Slider>
      )}
    </>
  );
};

export default withStyles(learnMoreDrawerStyle)(LearnMoreDrawer);
