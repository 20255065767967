const weekPlanDrawerStyle = (theme) => ({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: "64px",
    maxHeight: "64px",
    borderBottom: "1px solid #ccc",
    padding: "0px 16px",
    position: "sticky",
    zIndex: 1000,
    top: 0,
    backgroundColor: "white",
  },
  subHeaderContainer: {
    display: "flex",
    justifyContent: "center",
    background: "#F7F8FC",
    flexDirection: "column",
    padding: "16px 16px 0px 16px",
  },
  container: {
    display: "flex",
    padding: "16px",
    alignItems: "center",
    background: "#F7F8FC",
  },
  headerText: {
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "Roboto",
  },
  subHeaderText: {
    fontSize: "24px",
    fontWeight: 500,
  },
  sub_text: {
    fontSize: "14px",
  },
  sub_text1: {
    fontSize: "12px",
    textTransform: "uppercase",
  },
  icon: {
    cursor: "pointer",
  },
  container: {
    padding: "16px",
    background: "#FAFAFA",
    overflowX: "hidden",
  },
  heading: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "24px",
    color: "#262626",
    whiteSpace: "nowrap",
  },
  subHeading: {
    marginTop: "8px",
    fontWeight: "normal",
    fontSize: "14px",
    color: "#262626",
  },
  box: {
    width: "90%",
    borderRadius: "2px",
    padding: "16px",
  },
  boxContainer: {
    marginTop: "16px",
    display: "grid",
    gridGap: "8px",
  },
  content: {
    marginTop: "8px",
    marginLeft: "-20px",
    marginBottom: "0px",
  },
  contentText: {
    fonStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    color: "white",
    lineHeight: "140%",
    letterSpacing: "0.3px",
  },
  subHeaderText1: {
    fontWeight: "bold",
    fontSize: "12px",
    marginTop: "16px",
  },
  dietWrapper: {
    display: "flex",
    width: "100%",
    marginLeft: "0px",
    backgroundColor: "white",
    borderRadius: "2px",
    height: "120px",
    marginTop: "16px",
  },
  dietSubWrapper: {
    width: "100%",
    backgroundColor: "white",
    padding: "16px",
  },
  textDiet: {
    fontWeight: "bold",
    fontSize: "16px",
    color: "#595959",
  },
  blogCardContainer: {
    width: "200px",
    height: "229px",
    borderRadius: "2px",
    marginTop: "16px",
  },
  blogSubContainer: {
    backgroundColor: "white",
    width: "200px",
    height: "120px",
  },
  blogText: {
    color: "#595959",
    fontWeight: "normal",
    fontSize: "14px",
    paddingLeft: "16px",
    paddingTop: "8px",
  },
  blogRead: {
    color: "#2B377E",
    fontSize: "14px",
    fontWeight: "bold",
    padding: "16px",
  },
  slide: {
    "& .slick-track": {
      margin: "0px",
    },
    "& .slick-prev": {
      zIndex: "1",
      left: "1rem",
      bottom: "0",
      top: "auto",
    },
    "& .slick-next": {
      zIndex: "1",
      right: "1rem",
      bottom: "0",
      top: "auto",
    },
    "& .slick-prev:before, & .slick-next:before": {
      color: "#06c",
    },
  },
  dosContainer: {
    width: "90%",
    backgroundColor: "white",
    height: "24px",
    marginTop: "16px",
    display: "flex",
    padding: "16px",
    borderRadius: "2px",
  },
  dosContainerText: {
    color: "#595959",
    fontSize: "16px",
    fontWeight: "bold",
  },
});

export default weekPlanDrawerStyle;
