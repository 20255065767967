import React from "react";
import { format } from "date-fns";
import Markdown from "markdown-to-jsx";
import { Formik, Field, Form } from "formik";
import { Box, Drawer, Typography, makeStyles, Card } from "@material-ui/core";

import Flex from "@components/atoms/Flex";
import { drawerWidth } from "utils/utility";
import avatar from "assets/images/avatar-one.png";
import FormSelectField from "@components/molecules/FormSelectField";

import { ModuleDrawerHeader } from "./ModuleDrawerHeader";
import { IMAGE_FILE_TYPE } from "utils/constants";
import { uploadFile } from "../../../../services/fileService";
import FormTextInput from "@components/molecules/FormTextInput/FormTextInput";

const options = [
  { value: "Habit Check", label: "Habit Check" },
  { value: "Nutrition Check", label: "Nutrition Check" },
  { value: "Exercise Check", label: "Exercise Check" },
];

// common styles
const useStyles = makeStyles(() => ({
  title: {
    fontWeight: "500",
    fontSize: "16px",
    marginBottom: "12px",
  },
  inputField: {
    width: "100%",
    border: "none",
    outline: "none",
    padding: "12px",
  },
  fieldBox: {
    display: "flex",
    height: "60px",
    width: "100%",
    border: "1px solid #DADADA",
    borderRadius: "4px",
    alignItems: "center",
  },
  heading: {
    fontFamily: "Roboto",
    fontWeight: "500",
    fontSize: "16px",
    textTransform: "uppercase",
  },
  details: {
    fontFamily: "Roboto",
    fontWeight: "600",
    fontSize: "16px",
  },
  content: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "510,",
  },
  date: {
    fontSize: "12px",
    color: "#595959",
    fontWeight: "510",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  card: {
    padding: "16px",
    width: "100%",
  },
  multilineField: {
    display: "flex",
    width: "100%",
    border: "1px solid #DADADA",
    borderRadius: "4px",
    alignItems: "center",
  },
}));

const ModuleDrawer = ({
  onSave,
  heading,
  activity,
  isLearnVisible,
  isStartVisible,
  isExerciseVisible,
  setExerciseDrawer,
}) => {
  const classes = useStyles();

  return (
    <>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={true}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {/* learn drawer content */}
        {isLearnVisible && (
          <>
            <ModuleDrawerHeader
              heading={heading}
              classes={classes}
              onSave={() => onSave({ ...activity, activityId: activity.id })}
              onBack={() => setExerciseDrawer(0)}
            />
            {activity.banner?.url && (
              <Box>
                <img
                  width={360}
                  height={200}
                  src={activity.banner?.url}
                  alt=""
                />
              </Box>
            )}
            <Box style={{ padding: "16px" }}>
              <Typography
                style={{
                  fontSize: "1.8rem",
                  fontWeight: "500",
                  color: "black",
                  lineHeight: "35px",
                }}
              >
                {activity.title}
              </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                padding: "0px 16px",
                alignItems: "center",
              }}
            >
              <Box>
                <img
                  width={40}
                  height={40}
                  style={{ borderRadius: "50%" }}
                  src={activity.authorAvatar || avatar}
                  alt=""
                />
              </Box>
              <Box style={{ marginLeft: "1.2rem" }}>
                <Typography className={classes.content}>
                  {activity.authorName}
                </Typography>
              </Box>
              <Box style={{ marginLeft: "auto" }}>
                <Typography className={classes.date}>
                  {format(new Date(activity.updated_at), "MM/dd/yyyy")}
                </Typography>
              </Box>
            </Box>
            <Box style={{ padding: "16px" }}>
              <Typography className={classes.content}>
                <Markdown
                  options={{
                    overrides: {
                      img: {
                        props: {
                          width: "100%",
                        },
                      },
                    },
                  }}
                >
                  {activity.content}
                </Markdown>
              </Typography>
            </Box>
          </>
        )}
        {/* exercise drawer content */}
        {isExerciseVisible && (
          <Formik
            initialValues={{
              activityId: activity.id,
              videoURL: activity.videoURL,
              videoName: activity.videoName,
              duration: activity.duration,
              videoSchedule: activity.videoSchedule,
              videoThumbnail: activity.videoThumbnail,
            }}
            onSubmit={onSave}
          >
            {({ values, setFieldValue, handleSubmit }) => {
              const handleVideoThumbnail = async (event) => {
                const files = event?.target?.files;
                const hasFiles = files && files.length > 0 ? files[0] : null;

                if (!hasFiles) {
                  return;
                }
                const fileURL = await uploadFile(hasFiles);

                setFieldValue("videoThumbnail", fileURL);
              };
              return (
                <Form>
                  <ModuleDrawerHeader
                    heading={heading}
                    classes={classes}
                    onSave={handleSubmit}
                    onBack={() => setExerciseDrawer(0)}
                  />
                  <div style={{ padding: "16px" }}>
                    <div style={{ paddingBottom: "16px" }}>
                      <Typography className={classes.title}>
                        Youtube Link
                      </Typography>
                      <div className={classes.fieldBox}>
                        <Field
                          type="text"
                          name="videoURL"
                          className={classes.inputField}
                        />
                      </div>
                    </div>
                    <div style={{ paddingBottom: "16px" }}>
                      <Typography className={classes.title}>
                        Video Name
                      </Typography>
                      <div className={classes.fieldBox}>
                        <Field
                          type="text"
                          name="videoName"
                          className={classes.inputField}
                        />
                      </div>
                    </div>
                    <div style={{ paddingBottom: "16px" }}>
                      <Typography className={classes.title}>When</Typography>
                      <div className={classes.multilineField}>
                        <Card className={classes.card}>
                          <FormTextInput
                            variant="standard"
                            name="videoSchedule"
                            type="text"
                            placeholder=""
                            typeOf="auth"
                            multiline={true}
                          />
                        </Card>
                      </div>
                    </div>

                    <div style={{ paddingBottom: "16px" }}>
                      <Typography className={classes.title}>
                        How much time
                      </Typography>
                      <div className={classes.multilineField}>
                        <Card className={classes.card}>
                          <FormTextInput
                            variant="standard"
                            name="duration"
                            type="text"
                            placeholder=""
                            typeOf="auth"
                            multiline={true}
                          />
                        </Card>
                      </div>
                    </div>
                    <div style={{ paddingBottom: "16px" }}>
                      <Typography className={classes.title}>Image</Typography>
                      <Flex alignItems="center">
                        {values.videoThumbnail && (
                          <Flex
                            maxHeight={100}
                            maxWidth={100}
                            marginRight="10px"
                          >
                            <img
                              src={values.videoThumbnail}
                              alt=""
                              width="100%"
                              height="100%"
                            />
                          </Flex>
                        )}
                        <label
                          style={{
                            fontWeight: 700,
                            padding: "17px",
                            fontSize: "14px",
                            color: "#2B377E",
                            cursor: "pointer",
                            alignItems: "center",
                            display: "inline-grid",
                            backgroundColor: "#fff",
                            border: "1px solid #D9D9D9",
                          }}
                        >
                          <input
                            hidden
                            type="file"
                            accept={IMAGE_FILE_TYPE}
                            onChange={handleVideoThumbnail}
                          />
                          UPLOAD IMAGE
                        </label>
                        {values.videoThumbnail && (
                          <div
                            onClick={() => {
                              setFieldValue("videoThumbnail", "");
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "14px",
                                fontWeight: 700,
                                color: "#F0647D",
                                // paddingTop: "15px",
                                marginLeft: "1rem",
                                cursor: "pointer",
                                display: "block",
                              }}
                            >
                              Remove
                            </Typography>
                          </div>
                        )}
                      </Flex>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
        {/* start drawer content */}
        {isStartVisible && (
          <Formik
            initialValues={{
              title: activity.title,
              activityId: activity.activityId,
              category: activity.category,
            }}
            onSubmit={onSave}
          >
            {({ values, setFieldValue, handleSubmit }) => (
              <Form>
                <ModuleDrawerHeader
                  heading={heading}
                  classes={classes}
                  onSave={handleSubmit}
                  onBack={() => setExerciseDrawer(0)}
                />
                <Box padding="16px">
                  <div
                    style={{
                      height: "40px",
                      background: "#FFFFFF",
                      borderRadius: "4px",
                    }}
                  >
                    <FormSelectField
                      name="category"
                      label="All Categories"
                      // value={values.options}
                      options={options}
                      typeOf="auth"
                      type="patient"
                    />
                  </div>
                  <textarea
                    style={{
                      width: "calc(100% - 24px)",
                      padding: "12px",
                      marginTop: "20px",
                      resize: "vertical",
                    }}
                    name="title"
                    value={values.title}
                    onChange={(e) => setFieldValue("title", e.target.value)}
                  />
                </Box>
              </Form>
            )}
          </Formik>
        )}
      </Drawer>
    </>
  );
};

export default ModuleDrawer;
