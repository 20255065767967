import AddPatient from "pages/AddPatient";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PatientDetails from "@pages/PatientDetails";
import ViewAppointment from "@pages/ViewAppointment";
import EditAppointment from "@pages/EditAppointment";
import Home from "@pages/Home";

export const initRoutes = [{}];

export const counsellorRoutes = [
  {
    path: "/patients",
    name: "Patients",
    icon: DashboardIcon,
    layout: "/counselor",
    component: Home,
  },
  {
    path: "/addPatient",
    name: "AddPatient",
    icon: DashboardIcon,
    layout: "/counselor",
    component: AddPatient,
  },
  {
    path: "/uploadDetails",
    name: "UploadDetails",
    icon: DashboardIcon,
    layout: "/counselor",
    component: AddPatient,
  },
  {
    path: "/patient/:id",
    name: "PatientDetails",
    icon: DashboardIcon,
    layout: "/counselor",
    component: PatientDetails,
  },
  {
    path: "/appointment/:id",
    name: "AppointmentDetails",
    icon: DashboardIcon,
    layout: "/counselor",
    component: ViewAppointment,
  },
  {
    path: "/edit_appointment/:id",
    name: "EditAppointmentDetails",
    icon: DashboardIcon,
    layout: "/counselor",
    component: EditAppointment,
  },
  {
    path: "/edit_patient/:id",
    name: "EditPatientDetails",
    icon: DashboardIcon,
    layout: "/counselor",
    component: AddPatient,
  },
];
