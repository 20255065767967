const confirmDialogStyle = (theme) => ({
  mainContainer: { width: "400px" },
  dialogTextBox: { padding: "20px", display: "flex", justifyContent: "center" },
  dialogText: { fontSize: "16px", fontWeight: 500 },
  buttonsBox: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px 20px",
  },
  buttonYes: {
    marginLeft: "10px",
  },
});

export default confirmDialogStyle;
