import lang from "assets/languages";
import React, { useEffect, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { Box, Grid, Modal, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { PDF_FILE_TYPE } from "utils/constants";
import CustomButton from "@components/atoms/Button";
import { uploadFile } from "services/fileService";
import { postReportsApi } from "services/fileService";
import Loader from "@components/atoms/Loader";
import reportModalStyle from "assets/jss/pages/reportsModalStyle";
import ReportsList from "./reportsList";
import { fetchPaitentAllReports } from "services/fileService";
import { notyf } from "utils/utility";
import { ReportType } from "utils/constants";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "800px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
};

const ReportsModal = ({
  classes,
  showReportsModal,
  handleClose,
  patientId,
  props,
  hideCreateCTA,
  scrollToBottom = false,
}) => {
  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState([]);

  useEffect(() => {
    getReports();
  }, []);

  const onChange = async (event) => {
    setLoading(true);
    const files = event?.target?.files;
    const hasFiles = files && files.length > 0 ? files[0] : null;
    if (!hasFiles) {
      setLoading(false);
      notyf.error(lang.pleaseSelectAFileToUpload);
      return;
    }
    onFileUpload(hasFiles);
  };

  const getReports = async () => {
    const allReports = await fetchPaitentAllReports(patientId);

    if (!allReports || !allReports.length) {
      setReports([]);
      return;
    }
    setReports(allReports);
  };

  const onFileUpload = async (selectedFile) => {
    setLoading(true);
    if (!selectedFile) {
      setLoading(false);
      return;
    }
    const fileURL = await uploadFile(selectedFile);
    setLoading(false);
    if (fileURL === undefined) {
      return;
    }

    const fileDetails = {
      reportType: ReportType.coachMetasense,
      fileURL: fileURL,
      fileName: selectedFile.name,
      uploadDate: new Date(),
    };

    await postReportsApi(fileDetails, patientId);
    getReports();
  };

  const onFileUpdate = async (selectedFile, key, reportId) => {
    setLoading(true);
    const fileURL = await uploadFile(selectedFile);
    setLoading(false);

    if (fileURL === undefined) {
      return;
    }

    const fileDetails = {
      id: reportId,
      reportType: key,
      fileURL: fileURL,
      fileName: selectedFile.name,
    };

    await postReportsApi(fileDetails, patientId);
    getReports();
  };

  return (
    <>
      {loading && <Loader open={true} loaderMessage="loading..." />}
      <Modal open={showReportsModal} onClose={handleClose}>
        <Box sx={modalStyle}>
          <>
            <div className={classes.reportsModal}>
              <CustomButton className={classes.uploadReports}>
                <label className={classes.uploadLabel}>
                  <input
                    hidden
                    type="file"
                    accept={PDF_FILE_TYPE}
                    onChange={onChange}
                  />
                  {lang.upload}
                </label>
              </CustomButton>
              {!hideCreateCTA && (
                <CustomButton
                  className={classes.uploadReports}
                  onClick={() =>
                    props.props.history.push({
                      pathname: `/coach/create-report/${patientId}`,
                      state: {
                        patientId: patientId,
                      },
                    })
                  }
                >
                  {lang.create}
                </CustomButton>
              )}
              <div>
                <Typography className={classes.modalReportsSubheading}>
                  {`${lang.reportsUploaded}:`}
                </Typography>
                {!reports.length ? (
                  <Typography className={classes.noReportsText}>
                    {lang.noReportsUploaded}
                  </Typography>
                ) : (
                  <Grid
                    container
                    spacing={2}
                    className={classes.titleContainer}
                  >
                    <Grid item xs={5}>
                      <Typography>{lang.fileName}</Typography>
                    </Grid>

                    <Grid item xs={5}>
                      <div>{lang.uploadedAt}</div>
                    </Grid>
                  </Grid>
                )}
              </div>
              <div className={classes.reportsTable}>
                <ReportsList
                  patientId={patientId}
                  reports={reports}
                  onFileUpdate={onFileUpdate}
                  scrollToBottom={scrollToBottom}
                />
              </div>
            </div>
            <CloseIcon onClick={handleClose} className={classes.icon} />
          </>
        </Box>
      </Modal>
    </>
  );
};
export default withStyles(reportModalStyle)(ReportsModal);
