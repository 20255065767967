import React from "react";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import { useField, useFormikContext } from "formik";

const GreenRadio = withStyles({
  root: {
    color: "#8c8c8c",
    "&$checked": {
      color: "#389E0D",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const useStyles = makeStyles({
  root: {
    "& .MuiFormControlLabel-label": {
      fontSize: "14px",
      color: "#262626",
    },
  },
  error: {
    color: "#ff5522",
    "& .MuiRadio-root": {
      color: "#ff5522",
    },
    "& .MuiFormControlLabel-label": {
      fontSize: "14px",
    },
  },
  helperText: {
    color: "red",
  },
});

const FormRadioButtonGroup = ({ name, options, ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);
  const classes = useStyles();

  const handleChange = (evt) => {
    const { value } = evt.target;
    setFieldValue(name, value);
  };

  const configRadioGRoup = {
    ...field,
    onChange: handleChange,
  };

  const configFormControl = {};

  return (
    <FormControl component="fieldset" {...configFormControl}>
      <RadioGroup
        className={
          meta && meta.touched && meta.error ? classes.error : classes.root
        }
        column="true"
        {...configRadioGRoup}
      >
        {options.map((option, index) => {
          return (
            <FormControlLabel
              key={index}
              value={option.value || option}
              control={<GreenRadio />}
              label={option.label || option}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

export default FormRadioButtonGroup;
