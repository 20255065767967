import lang from "assets/languages";
import { distressScoreGoal } from "../constant";
import { useStyles } from "../style";

export const GlycemicAndDistressTable = () => {
  const classes = useStyles();
  return (
    <div className={classes.glycemicTableContainer}>
      <div className={classes.glycemicContainer}>
        <div>
          <span>Correlation of </span>
          <span className={classes.boldFontWeight}>{lang.healthRiskScore}</span>
          <span> with </span>
          <span className={classes.boldFontWeight}>{lang.glycemicScore}</span>
          <span> and </span>
          <span className={classes.boldFontWeight}>{lang.distressScore}</span>
        </div>
      </div>
      <table className={classes.glycemicTable}>
        <thead>
          <tr>
            <th className={classes.glycemicTableCell}>
              Glycemic score (TIR, Time in target range)
            </th>
            <th className={classes.glycemicTableCell}>
              Distress score (GV, Glycemic Variability)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={classes.tableCellStyle}>
              <span className={classes.boldFontWeight}>TIR: </span>
              <span>
                It is defined as the time spent in the target range between 70
                and 140 mg/dL. Regular consumption of High GI foods and no
                physical activity leads to a low TIR.
              </span>
            </td>
            <td className={classes.tableCellStyle}>
              <span className={classes.boldFontWeight}>GV: </span>
              It refers to swings in the blood glucose levels, including
              hypoglycemic events and postprandial spikes, as well as blood
              glucose fluctuations that occur at the same time on different
              days. Stress, anxiety, poor medication adherence and erratic sleep
              cycle increases the GV
            </td>
          </tr>
          <tr>
            <td className={classes.tableCellStyle}>
              <span className={classes.boldFontWeight}>Goal:</span> Achieving
              90% TIR
            </td>
            <td className={classes.tableCellStyle}>
              <span className={classes.boldFontWeight}>Goal: </span>
              {distressScoreGoal}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
