import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";

import { Box, Grid, Modal, Typography, withStyles } from "@material-ui/core";

import getSensorSummaryGraphForCoach from "../actions/getSensorSummaryGraphForCoach";
import getSensorSummaryForCoach from "../actions/getSensorSummaryForCoach";
import getOverallSummaryForCoach from "../actions/getOverallSummaryForCoach";

import api from "utils/api";
import { getMetricGrapOption } from "utils/summaryGraphUtils";
import { isSuccess } from "utils/utility";

import Header from "./Header";
import LeftPane from "./LeftPane";

import lang from "assets/languages";
import { apiResponses, END_POINT } from "network/constants";

import cgmProgressDrawerStyle from "assets/jss/pages/components/cgmProgressDrawerStyle";

import CanvasJSReact from "../../../../../canvasjs.react";
const { CanvasJSChart } = CanvasJSReact;

export const graphWidth = "380";
export const graphHeight = "200";

const SummaryModal = ({ classes, showModal, closeModal, patientId }) => {
  const [sensorSummaryState, setSensorSummaryState] = useState({
    metricData: null,
    summary: null,
    sensors: null,
    selectedSensor: null,
    overallSummary: null,
  });

  const { metricData, summary, selectedSensor, sensors, overallSummary } =
    sensorSummaryState;

  const renderStat = (heading, value) => {
    return (
      <Box className={classes.statsContainer}>
        <Typography className={classes.statsHeading}>{heading}</Typography>
        <Typography className={classes.statsValue}>{value}</Typography>
      </Box>
    );
  };

  useEffect(() => {
    if (!showModal) return;

    const getSensorList = async () => {
      try {
        const {
          data: { data: sensorList, status },
        } = await api(END_POINT.getSensorList(patientId), {
          method: "get",
        });

        if (status === apiResponses.success) {
          setSensorSummaryState((prevState) => ({
            ...prevState,
            sensors: sensorList,
            selectedSensor:
              sensorList && sensorList.length > 0 ? sensorList[0] : null,
          }));
        } else {
          console.error("failed to fetch sensors");
        }
      } catch (e) {
        console.error(e, "failed to fetch sensors");
      }
    };

    getSensorList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  useEffect(() => {
    if (!selectedSensor) {
      return;
    }

    const sensorId = selectedSensor?.id;

    const getGraphAndSummary = () => {
      const promiseList = [
        getSensorSummaryGraphForCoach({ id: sensorId }),
        getSensorSummaryForCoach({ id: sensorId }),
        getOverallSummaryForCoach({
          sensor: selectedSensor,
          patientId: patientId,
        }),
      ];

      Promise.allSettled(promiseList)
        .then((res) => {
          let data = {};

          const [
            sensorSummaryGraphForCoachResponse,
            sensorSummaryForCoachResponse,
            overallSummaryForCoachResponse,
          ] = res;

          if (
            sensorSummaryGraphForCoachResponse?.status === "fulfilled" &&
            isSuccess(sensorSummaryGraphForCoachResponse?.value)
          ) {
            data = {
              ...data,
              metricData:
                sensorSummaryGraphForCoachResponse?.value?.data?.data
                  ?.metricData,
            };
          }

          if (
            sensorSummaryForCoachResponse?.status === "fulfilled" &&
            isSuccess(sensorSummaryForCoachResponse?.value)
          ) {
            data = {
              ...data,
              summary: sensorSummaryForCoachResponse?.value?.data?.data,
            };
          }

          if (
            overallSummaryForCoachResponse?.status === "fulfilled" &&
            isSuccess(overallSummaryForCoachResponse?.value)
          ) {
            data = {
              ...data,
              overallSummary: overallSummaryForCoachResponse?.value?.data || {},
            };
          }

          setSensorSummaryState((prevState) => ({
            ...prevState,
            ...data,
          }));
        })
        .catch((err) => {
          console.log("err", err);
          // Log to error service
        });
    };

    getGraphAndSummary();
  }, [selectedSensor?.id]);

  const options = useMemo(
    () => getMetricGrapOption(metricData, graphWidth, graphHeight),
    [metricData]
  );

  const totalSensors = sensors ? sensors.length : 0;

  const { sensorActivationDateInUTC, sensorExpirationDateInUTC } =
    selectedSensor || {};

  const sensorTimeline = `${moment(sensorActivationDateInUTC).format(
    "DD MMM"
  )} - ${moment(sensorExpirationDateInUTC).format("DD MMM")}`;

  // Container PROPS for `dailyPattern` CanvasJSChart.
  const dailyPatternChartContainerStyles = {
    padding: "28px 12px",
    marginBottom: "24px",
    background: "white",
  };

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box className={classes?.summaryModal}>
        <Header
          sensors={sensors}
          totalSensors={totalSensors}
          setSensorSummaryState={setSensorSummaryState}
          classes={classes}
          handleClose={closeModal}
        />
        <Grid container columnspacing={{ xs: 1, sm: 1, md: 1 }}>
          <Grid item xs={8} className={classes?.scroll}>
            <LeftPane
              classes={classes}
              sensorTimeline={sensorTimeline}
              overallSummary={overallSummary}
              sensorId={selectedSensor?.sensorId}
              selectedSensor={selectedSensor}
            />
          </Grid>

          <Grid item xs={4}>
            <Typography className={classes.metricGraphHeading}>
              {lang.dailyPattern}
            </Typography>
            {metricData && (
              <CanvasJSChart
                options={options}
                containerProps={dailyPatternChartContainerStyles}
              />
            )}
            {summary && (
              <Box>
                <Typography className={classes.metricGraphHeading}>
                  {lang.stats}
                </Typography>
                <Box className={classes.allStats}>
                  {renderStat(
                    lang.averageGlucose,
                    summary.averageGlucose ? summary.averageGlucose : "--"
                  )}
                  <hr className={classes.statsBreakLine} />
                  {renderStat(
                    lang.timeInRange,
                    summary.timeInRange && !isNaN(summary.timeInRange)
                      ? `${summary.timeInRange} %`
                      : "--"
                  )}
                  <hr className={classes.statsBreakLine} />
                  {renderStat(
                    lang.timeAboveRange,
                    summary.timeAboveRange && !isNaN(summary.timeAboveRange)
                      ? `${summary.timeAboveRange} %`
                      : "--"
                  )}
                  <hr className={classes.statsBreakLine} />
                  {renderStat(
                    lang.timeBelowRange,
                    summary.timeBelowRange && !isNaN(summary.timeBelowRange)
                      ? `${summary.timeBelowRange} %`
                      : "--"
                  )}
                  <hr className={classes.statsBreakLine} />
                  {renderStat(
                    lang.averageInterquartileRange,
                    summary.averageInterquartileRange &&
                      !isNaN(summary.averageInterquartileRange)
                      ? `${summary.averageInterquartileRange} mg/dL`
                      : "--"
                  )}
                  <hr className={classes.statsBreakLine} />
                  {renderStat(
                    lang.glycemicVariabilityPercentage,
                    summary.GlycemicVeribilityPercentage &&
                      !isNaN(summary.GlycemicVeribilityPercentage)
                      ? `${summary.GlycemicVeribilityPercentage}%`
                      : "--"
                  )}
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default withStyles(cgmProgressDrawerStyle)(SummaryModal);
