import { Typography, withStyles } from "@material-ui/core";
import lang from "assets/languages";
import { reportType } from "pages/CreateReport/constants";
import { dailyPatternTypes } from "pages/CreateReport/constants";
import { rowHeadingInsightText } from "pages/CreateReport/constants";
import { interventionRowHeadingObj } from "pages/CreateReport/constants";
import { seventhDayInterventionColHeading } from "pages/CreateReport/constants";
import { rowHeadingInterventionText } from "pages/CreateReport/constants";
import { seventhDayInsightColHeading } from "pages/CreateReport/constants";
import { insightRowHeadingObj } from "pages/CreateReport/constants";
import { reportTypesId } from "pages/CreateReport/constants";
import React, { useEffect, useMemo, useState } from "react";
import CanvasJSReact from "canvasjs.react";
import { useSelector } from "react-redux";
import { ReportTable } from "../ReportTable";
import { fourteenthDayInsightColHeading } from "pages/CreateReport/constants";
import dailyPatternTabStyle from "./dailyPatternStyles";
import { END_POINT } from "network/constants";
import api from "utils/api";
import { API_METHODS } from "network/constants";
import { notyf } from "utils/utility";
import { apiResponses } from "network/constants";
import { getMetricGrapOption } from "utils/summaryGraphUtils";
import { graphWidth } from "pages/CreateReport/constants";
import { graphHeight } from "pages/CreateReport/constants";
const { CanvasJSChart } = CanvasJSReact;

const DailyPattern = ({ classes, activeFileType }) => {
  const {
    createReportReducer: {
      reportId,
      seventhDayGraphData,
      fourteenthDayGraphData,
    },
  } = useSelector((state) => state);

  const metricData =
    activeFileType === reportTypesId.seventhDayReport
      ? seventhDayGraphData
      : fourteenthDayGraphData;

  const options = useMemo(
    () => getMetricGrapOption(metricData, graphWidth, graphHeight),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeFileType]
  );

  const [assessmentData, setAssesmentData] = useState({
    insights: null,
    interventions: null,
  });

  useEffect(() => {
    const getAssesmentOpitons = async () => {
      try {
        const {
          data: { data, status },
        } = await api(END_POINT.getAllAssesments, {
          method: API_METHODS.get,
        });

        if (status === apiResponses.success) {
          setAssesmentData({
            ...assessmentData,
            insights: data?.insights ? data.insights : null,
            interventions: data?.interventions ? data.interventions : null,
          });
        } else {
          notyf.error("failed to fetch subtypes");
        }
      } catch (e) {
        notyf.error("failed to fetch subtypes");
      }
    };

    if (reportId !== null && reportId !== undefined && reportId > -1) {
      getAssesmentOpitons();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportId]);

  return (
    <div className={classes.container}>
      {activeFileType === reportTypesId.seventhDayReport ? (
        <div>
          <Typography className={classes.tabHeadingText}>
            {`${
              activeFileType === reportTypesId.seventhDayReport
                ? "0 - 7"
                : "0 - 14"
            } ${lang.daysPattern}`}
          </Typography>
          <div className={classes.summaryGraphCard}>
            <div className={classes.graphContainer}>
              {options && <CanvasJSChart options={options} />}
            </div>
          </div>
          <div className={classes.tableContainer}>
            <ReportTable
              tableName={reportType.seventhDayReportData}
              tableType={dailyPatternTypes.insight}
              rowHeadingKeys={insightRowHeadingObj}
              rowHeadingTitles={rowHeadingInsightText}
              columnHeading={seventhDayInsightColHeading}
              assessmentData={assessmentData?.insights}
            />
          </div>
          <div className={classes.tableContainer}>
            <ReportTable
              tableName={reportType.seventhDayReportData}
              tableType={dailyPatternTypes.intervention}
              rowHeadingKeys={interventionRowHeadingObj}
              rowHeadingTitles={rowHeadingInterventionText}
              columnHeading={seventhDayInterventionColHeading}
              assessmentData={assessmentData?.interventions}
            />
          </div>
        </div>
      ) : (
        <div>
          <Typography className={classes.tabHeadingText}>
            {`0 - 14 ${lang.daysPattern}`}
          </Typography>
          <div className={classes.summaryGraphCard}>
            <div className={classes.graphContainer}>
              {metricData && <CanvasJSChart options={options} />}
            </div>
          </div>
          <div className={classes.tableContainer}>
            <ReportTable
              tableName={reportType.fourteenthDayReport}
              tableType={dailyPatternTypes.insight}
              rowHeadingKeys={insightRowHeadingObj}
              rowHeadingTitles={rowHeadingInsightText}
              columnHeading={fourteenthDayInsightColHeading}
              assessmentData={assessmentData?.insights}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const dailyPatternTab = React.memo(
  withStyles(dailyPatternTabStyle)(DailyPattern)
);
export { dailyPatternTab as DailyPattern };
