import { Typography, Divider } from "@material-ui/core";
import lang from "assets/languages";
import moment from "moment";
import { useHistory } from "react-router-dom";
import {
  PDF_FILE_TYPE,
  ReportTitles,
  ReportType,
} from "../../../utils/constants";
import { fileStatus } from "./constant";

import { Grid } from "@material-ui/core";

const fileCardStyles = () => ({
  displayflex: { display: "flex" },
  draftContainer: {
    color: "#FAAF23",
    border: "2px solid #FAAF23",
    padding: "4px 8px 4px 8px",
    borderRadius: "2px",
    fontSize: "12px",
  },
  fileName: { marginTop: "6px", marginLeft: "8px" },
  uploadContainer: {
    color: "#2DBE41",
    border: "2px solid #2DBE41",
    padding: "4px",
    borderRadius: "2px",
    fontSize: "12px",
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  updateTextStyleWithoutBorder: {
    color: "#389E0D",
    border: "",
    display: "",
    borderRadius: "",
  },
  updateTextStyleWithBorder: {
    color: "#262626",
    border: "1px solid #2B377E",
    display: "inline-grid",
    borderRadius: "4px",
  },
});

export const FileCard = ({ showReportType = true, patientId, ...props }) => {
  const styles = fileCardStyles();
  const { index, report, onChange, listOfReports, classes } = props;
  const { fileName, uploadDate, reportType, fileURL } = report;
  const hasFileName = fileName && fileName?.length > 0;
  const reportStatus = report?.reportStatus;
  const history = useHistory();
  const isNonCategorizedReport = reportType !== ReportType.random;

  const showReportTypeName = isNonCategorizedReport && showReportType;

  return (
    <>
      {!reportType?.fileURL && (
        <>
          <div className={classes.container}>
            {/* {isNonCategorizedReport && showReportType && (
              <Typography className={(classes.text, classes.title)}>
                {ReportTitles[reportType]}
              </Typography>
            )} */}

            <Grid container spacing={2}>
              {showReportTypeName && (
                <Grid item xs={4}>
                  <Typography className={(classes.text, classes.title)}>
                    {ReportTitles[reportType]}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={showReportTypeName ? 3 : 5}>
                <Typography className={classes.subText}>
                  {reportStatus === fileStatus.draft && (
                    <div style={styles.displayflex}>
                      <div style={styles.draftContainer}>{lang.draft}</div>
                      <div style={styles.fileName}>{fileName}</div>
                    </div>
                  )}
                  {reportStatus === fileStatus.published && (
                    <div style={styles.displayflex}>
                      <div style={styles.uploadContainer}>{lang.uploaded}</div>
                      <div style={styles.fileName}>{fileName}</div>
                    </div>
                  )}
                  {reportStatus !== fileStatus.published &&
                    reportStatus !== fileStatus.draft &&
                    fileName}
                </Typography>
              </Grid>

              <Grid item xs={showReportTypeName ? 2 : 3}>
                <Typography className={classes.subText}>
                  {reportStatus === fileStatus.draft
                    ? "--"
                    : moment(uploadDate).format("DD MMM YYYY")}
                </Typography>
              </Grid>

              <Grid item xs={showReportTypeName ? 3 : 4}>
                <div className={classes.viewUpdateText}>
                  <div>
                    {reportStatus === fileStatus.draft && (
                      <Typography
                        onClick={() => {
                          // const reportTypeId =
                          //   reportType === reportTypeForApi.seventhDay
                          //     ? reportTypesId.seventhDayReport
                          //     : reportTypesId.fourteenthDayReport;

                          // dispatch(
                          //   actionCreator(
                          //     CreateReportActions.set_fileTypeSelected,
                          //     reportTypeId
                          //   )
                          // );

                          history.push({
                            pathname: `/coach/create-report/${patientId}`,
                            state: {
                              patientId: patientId,
                              sensorId: report?.sensorId,
                              reportId: report?.id,
                            },
                          });
                        }}
                        className={classes.viewUpdateContainer}
                      >
                        <div className={classes.viewText}>Complete Report</div>
                      </Typography>
                    )}
                    {fileURL && (
                      <Typography className={classes.viewUpdateContainer}>
                        <a
                          href={fileURL}
                          target="_blank"
                          rel="noreferrer"
                          className={classes.viewText}
                        >
                          View
                        </a>
                      </Typography>
                    )}
                  </div>
                  {isNonCategorizedReport && !reportStatus && (
                    <div>
                      <Typography className={classes.viewUpdateContainer}>
                        <label
                          className={classes.updateText}
                          style={
                            hasFileName
                              ? styles.updateTextStyleWithoutBorder
                              : styles.updateTextStyleWithBorder
                          }
                        >
                          <input
                            hidden
                            type="file"
                            accept={PDF_FILE_TYPE}
                            onChange={(event) => onChange(event, reportType)}
                          />
                          {hasFileName ? lang.update : lang.upload}
                        </label>
                      </Typography>
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
          {listOfReports.length - 1 !== index && <Divider />}
        </>
      )}
    </>
  );
};
