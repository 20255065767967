import React from "react";
import moment from "moment";
import { Typography, withStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import appointmentDrawerStyle from "../../../../assets/jss/pages/components/appointmentDrawerStyle";

const ConsultationType = [
  {
    value: "firstconsultation",
    label: "First Consultation",
  },
  {
    label: "Scheduled Follow Up",
    value: "scheduledfollowup",
  },
  {
    label: "Emergency Follow Up",
    value: "emergencyfollowup",
  },
];

const AppointmentDrawer = ({ appointmentId, appointmentData, ...props }) => {
  const { classes } = props;
  const data = appointmentData.find((element) => element.id === appointmentId);

  return (
    <>
      <div className={classes.headerContainer}>
        <Typography className={classes.sub_text}>Details</Typography>
        <CloseIcon className={classes.icon} onClick={props.handleCloseDrawer} />
      </div>
      <div className={classes.subHeaderContainer}>
        <Typography className={classes.headerText}>Appointment</Typography>
      </div>
      <div className={classes.container}>
        <Typography className={classes.text}>Appointment With</Typography>
        <Typography className={classes.sub_text}>
          {data.doctor !== null
            ? `${
                data.doctor.firstName + " " + data.doctor.lastName + " [Doctor]"
              }`
            : data.coach !== null
            ? `${data.coach.firstName + " " + data.coach.lastName + " [Coach]"}`
            : "N/A"}
        </Typography>
      </div>
      <div className={classes.container}>
        <Typography className={classes.text}>Appointment Date</Typography>
        <Typography className={classes.sub_text}>
          {data.appointmentDate
            ? moment(data.appointmentDate).format("dddd, MMMM Do YYYY")
            : "N/A"}
        </Typography>
      </div>
      <div className={classes.container}>
        <Typography className={classes.text}>Appointment Time</Typography>
        <Typography className={classes.sub_text}>
          {data.appointmentDate
            ? moment(data.appointmentDate).format("kk:mm")
            : "N/A"}
        </Typography>
      </div>
      <div className={classes.container}>
        <Typography className={classes.text}>Appointment Type</Typography>
        <Typography
          className={classes.sub_text}
          style={{ textTransform: "capitalize" }}
        >
          {data.appointmentType}
        </Typography>
      </div>
      <div className={classes.container}>
        <Typography className={classes.text}>Consultation Type</Typography>
        <Typography className={classes.sub_text}>
          {
            ConsultationType.find(
              ({ value }) => value === data.consultationType
            )?.label
          }
        </Typography>
      </div>
      <div className={classes.container}>
        <Typography className={classes.text}>Appointment Reason</Typography>
        <Typography className={classes.sub_text}>{data.purpose}</Typography>
      </div>
      <div className={classes.container}>
        <Typography className={classes.text}>Appointment Status</Typography>
        <Typography
          className={classes.sub_text1}
          color={data.appointmentStatus === "booked" ? "#389E0D" : "#ff5522"}
        >
          {data.appointmentStatus === "booked" ? "Confirmed" : "Pending"}
        </Typography>
      </div>
    </>
  );
};

export default withStyles(appointmentDrawerStyle)(AppointmentDrawer);
