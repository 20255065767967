import { Box, Typography } from "@material-ui/core";
import React from "react";
import { useStyles } from "./styles";
import { doubleArrowLine } from "@assets/icons";
import { toTitleCase } from "@utils/stringUtils";

const statusBasedColors = {
  good: { background: "#E1FCEF", text: "#14804A" },
  bad: { background: "#FAF0F3", text: "#D12953" },
  normal: { background: "#FCF2E6", text: "#AA5B00" },
  average: { background: "#FCF2E6", text: "#AA5B00" },
};

export const InsightCard = ({
  styles = {},
  pictureSrc,
  heading,
  state,
  comment,
  timeSpan,
}) => {
  const classes = useStyles();

  const { background, text } = state ? statusBasedColors[state] : {};

  return (
    <Box style={styles} className={classes.container}>
      {timeSpan ? (
        <Box className={classes.timeSpanContainer}>
          <img width={"100%"} src={doubleArrowLine} alt="doubleArrowLine" />
          <Typography className={classes.timeSpan}>{timeSpan}</Typography>
        </Box>
      ) : (
        <Box style={{ marginTop: "20px" }} />
      )}
      <Box className={classes.headingContainer}>
        {!!pictureSrc && (
          <img
            width={32}
            height={16}
            style={{ borderRadius: 77, marginRight: "3px" }}
            src={pictureSrc}
            alt={pictureSrc}
            loading="lazy"
          />
        )}
        <Typography
          className={
            pictureSrc
              ? classes.defaultHeadingText
              : classes.headingTextWithImage
          }
        >
          {heading}
        </Typography>
      </Box>
      {state && (
        <Box
          style={{ backgroundColor: background }}
          className={classes.statusContainer}
        >
          <Typography className={classes.statusText} style={{ color: text }}>
            {toTitleCase(state)}
          </Typography>
        </Box>
      )}
      {comment && typeof comment === "string" && (
        <Typography className={classes.description}>{comment}</Typography>
      )}
    </Box>
  );
};
