import { dailyPatternTypes } from "pages/CreateReport/constants";
import { toTitleCase } from "utils/stringUtils";
import { getTextStyle } from "./helper";
import { useStyles } from "./style";

export const Table = ({
  data,
  headingDetails,
  isMultipleHeading = false,
  tableTitle,
  tableType,
}) => {
  const tableData = data.filter((e) => Object.keys(e).length > 0);
  const classes = useStyles();
  const tableHeadingKeys = Object.keys(tableData[0]);

  const renderHeading = () => {
    return tableHeadingKeys.map((tableHeadingKey) => {
      if (isMultipleHeading) {
        let tableRowData = headingDetails[tableHeadingKey];
        return (
          <th className={classes.columnStyle} key={tableHeadingKey}>
            {tableRowData?.imageText && <div>{tableRowData?.imageText}</div>}
            {tableRowData?.heading && <div>{tableRowData.heading}</div>}
            {tableRowData?.otherInfo && (
              <div className={classes.columnInfo}>
                {tableRowData?.otherInfo}
              </div>
            )}
            {!tableRowData?.heading && tableTitle && <div>{tableTitle}</div>}
          </th>
        );
      } else
        return (
          <th className={classes.tableHeading} key={tableHeadingKey}>
            {tableHeadingKey}
          </th>
        );
    });
  };
  const renderRow = () => {
    return tableData.map((tableRow, index) => {
      return (
        <tr key={index}>
          {tableHeadingKeys.map((tableHeadingKey, index) => {
            return (
              <td
                className={index === 0 ? classes.rowHeading : classes.rowStyle}
                key={index}
              >
                {dailyPatternTypes.intervention !== tableType &&
                  tableRow[tableHeadingKey]?.type && (
                    <div
                      style={getTextStyle(tableRow[tableHeadingKey]?.type)}
                      className={classes.tableCell}
                    >
                      {tableRow[tableHeadingKey]?.type &&
                        toTitleCase(tableRow[tableHeadingKey]?.type)}
                    </div>
                  )}
                {tableRow[tableHeadingKey]?.text && (
                  <div
                    style={getTextStyle(tableRow[tableHeadingKey]?.text)}
                    className={classes.tableCell}
                  >
                    {tableRow[tableHeadingKey]?.text &&
                      tableRow[tableHeadingKey]?.text}
                  </div>
                )}
                {tableRow[tableHeadingKey]?.customMessage && (
                  <div
                    style={getTextStyle(
                      tableRow[tableHeadingKey]?.customMessage
                    )}
                    className={classes.tableCell}
                  >
                    {tableRow[tableHeadingKey]?.customMessage &&
                      tableRow[tableHeadingKey]?.customMessage}
                  </div>
                )}
                {!tableRow[tableHeadingKey]?.text &&
                  !tableRow[tableHeadingKey]?.type && (
                    <div className={classes.tableCell}>
                      {tableRow[tableHeadingKey] &&
                      typeof tableRow[tableHeadingKey] === "string"
                        ? tableRow[tableHeadingKey]
                        : "-"}
                    </div>
                  )}
              </td>
            );
          })}
        </tr>
      );
    });
  };

  return (
    <div>
      <table className={classes.table}>
        <thead>
          <tr>{renderHeading()}</tr>
        </thead>
        <tbody>{renderRow()}</tbody>
      </table>
    </div>
  );
};
