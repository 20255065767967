const accordionContainerStyle = () => ({
  accordionBar: { height: "60px" },
  accordionBarContent: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  },
  accordionTitle: {
    fontSize: "14px",
    fontWeight: "bold",
  },
});

export default accordionContainerStyle;
