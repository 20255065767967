import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useStyles } from "./style";

export const DateSelector = ({ onDateChange, selectedDate }) => {
  const classes = useStyles();
  return (
    <DatePicker
      selected={selectedDate}
      onChange={(date) => {
        onDateChange(date);
      }}
      dateFormat="dd/MM/yyyy"
      className={classes.datePicker}
    />
  );
};
