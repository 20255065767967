import {
  patientGVRowKeys,
  patientProgressRowKeys,
  patientTIRRowKeys,
  storeAndProgressTableKeys,
} from "../../MetasenseReport/constant";
import {
  CreateReportActions,
  innerRowIndexing,
  insightRowHeadingObj,
  interventionRowHeadingObj,
  dailyPatternTypes,
  reportMessageTypes,
  reportType,
  reportTypesId,
  rowNames,
} from "../constants";
import {
  healthHabitsKey,
  healthHabitsKeys,
  summaryTablColumnKeys,
  summaryTableRowKeys,
} from "../components/SummaryTab/helpers";
import { requiredFieldsMapKey } from "../utils";

const initialState = {
  fourteenthDayReport: {
    insight: {
      data: [
        {
          rowName: rowNames.data,
          nocturnal: { type: "", text: "", messageId: null },
          morning: {
            type: "",
            message: "",
            messageId: 4,
            isCustom: false,
          },
          afternoon: null,
          evening: null,
          overall: null,
        },
        {
          rowName: rowNames.diet,
          nocturnal: null,
          morning: null,
          afternoon: null,
          evening: null,
          overall: null,
        },
        {
          rowName: rowNames.sleep,
          nocturnal: null,
          morning: null,
          afternoon: null,
          evening: null,
          overall: null,
        },
        {
          rowName: rowNames.physicalActivity,
          nocturnal: null,
          morning: null,
          afternoon: null,
          evening: null,
          overall: null,
        },
        {
          rowName: rowNames.stress,
          nocturnal: null,
          morning: null,
          afternoon: null,
          evening: null,
          overall: null,
        },
      ],
      maxCharLength: 0,
    },
    [requiredFieldsMapKey]: {
      insight: {
        [rowNames.data]: {
          nocturnal: {
            errorText: `Missing "nocturnal" data in ${rowNames.data} segment for Insight`,
          },
          morning: {
            errorText: `Missing "morning" data in ${rowNames.data} segment for Insight`,
          },
          afternoon: {
            errorText: `Missing "nocturnal" data in ${rowNames.data} segment for Insight`,
          },
          evening: {
            errorText: `Missing "afternoon" data in ${rowNames.data} segment for Insight`,
          },
          overall: {
            errorText: `Missing "overall" data in ${rowNames.data} segment for Insight`,
          },
        },
        [rowNames.diet]: {
          overall: {
            errorText: `Missing "overall" data in ${rowNames.diet} segment for Insight`,
          },
        },
        [rowNames.sleep]: {
          overall: {
            errorText: `Missing "overall" data in ${rowNames.sleep} segment for Insight`,
          },
        },
        [rowNames.physicalActivity]: {
          overall: {
            errorText: `Missing "overall" data in ${rowNames.physicalActivity} segment for Insight`,
          },
        },
        [rowNames.stress]: {
          overall: {
            errorText: `Missing "overall" data in ${rowNames.stress} segment for Insight`,
          },
        },
      },
    },
  },
  seventhDayReportData: {
    insight: {
      data: [
        {
          rowName: rowNames.data,
          nocturnal: null,
          morning: null,
          afternoon: null,
          evening: null,
          overall: null,
        },
        {
          rowName: rowNames.diet,
          nocturnal: null,
          morning: null,
          afternoon: null,
          evening: null,
          overall: null,
        },
        {
          rowName: rowNames.sleep,
          nocturnal: null,
          morning: null,
          afternoon: null,
          evening: null,
          overall: null,
        },
        {
          rowName: rowNames.physicalActivity,
          nocturnal: null,
          morning: null,
          afternoon: null,
          evening: null,
          overall: null,
        },
        {
          rowName: rowNames.stress,
          nocturnal: null,
          morning: null,
          afternoon: null,
          evening: null,
          overall: null,
        },
      ],
      maxCharLength: 0,
    },
    intervention: {
      data: [
        {
          rowName: rowNames.diet,
          nocturnal: null,
          morning: null,
          afternoon: null,
          evening: null,
          overall: null,
        },
        {
          rowName: rowNames.sleep,
          nocturnal: null,
          morning: null,
          afternoon: null,
          evening: null,
          overall: null,
        },
        {
          rowName: rowNames.physicalActivity,
          nocturnal: null,
          morning: null,
          afternoon: null,
          evening: null,
          overall: null,
        },
        {
          rowName: rowNames.stress,
          nocturnal: null,
          morning: null,
          afternoon: null,
          evening: null,
          overall: null,
        },
      ],
      maxCharLength: 0,
    },
    [requiredFieldsMapKey]: {
      insight: {
        [rowNames.data]: {
          nocturnal: {
            errorText: `Missing "nocturnal" data in ${rowNames.data}`,
          },
          morning: { errorText: `Missing "morning" data in ${rowNames.data}` },
          afternoon: {
            errorText: `Missing "afternoon" data in ${rowNames.data}`,
          },
          evening: { errorText: `Missing "evening" data in ${rowNames.data}` },
          overall: { errorText: `Missing "overall" data in ${rowNames.data}` },
        },
        [rowNames.diet]: {
          overall: {
            errorText: `Missing "overall" data in ${rowNames.diet} insight`,
          },
        },
        [rowNames.sleep]: {
          overall: {
            errorText: `Missing "overall" data in ${rowNames.sleep} insight`,
          },
        },
        [rowNames.physicalActivity]: {
          overall: {
            errorText: `Missing "overall" data in ${rowNames.physicalActivity} insight`,
          },
        },
        [rowNames.stress]: {
          overall: {
            errorText: `Missing "overall" data in ${rowNames.stress} insight`,
          },
        },
      },
      intervention: {
        [rowNames.diet]: {
          overall: {
            errorText: `Missing "overall" data in ${rowNames.diet} intervention`,
          },
        },
        [rowNames.sleep]: {
          overall: {
            errorText: `Missing "overall" data in ${rowNames.sleep} intervention`,
          },
        },
        [rowNames.physicalActivity]: {
          overall: {
            errorText: `Missing "overall" data in ${rowNames.physicalActivity} intervention`,
          },
        },
        [rowNames.stress]: {
          overall: {
            errorText: `Missing "overall" data in ${rowNames.stress} intervention`,
          },
        },
      },
    },
  },
  summary: {
    [summaryTableRowKeys.healthyHabitsRiskScore]: {
      [summaryTablColumnKeys.value]: "",
      [summaryTablColumnKeys.insight]: "",
      [summaryTablColumnKeys.progress]: "",
      [summaryTablColumnKeys.advice]: "",
    },
    [summaryTableRowKeys.glycemicScore]: {
      [summaryTablColumnKeys.value]: "",
      [summaryTablColumnKeys.insight]: "",
      [summaryTablColumnKeys.progress]: "",
      [summaryTablColumnKeys.advice]: "",
    },
    [summaryTableRowKeys.distressScore]: {
      [summaryTablColumnKeys.value]: "",
      [summaryTablColumnKeys.insight]: "",
      [summaryTablColumnKeys.progress]: "",
      [summaryTablColumnKeys.advice]: "",
    },
    [healthHabitsKey]: {
      [healthHabitsKeys.medicalIntervention]: true,
      [healthHabitsKeys.healthProfile]: false,
      [healthHabitsKeys.lifeStyleManagement]: false,
    },
    [requiredFieldsMapKey]: {
      [summaryTableRowKeys.healthyHabitsRiskScore]: {
        [summaryTablColumnKeys.insight]: {
          errorText: "Missing healthy habits risk score insight",
        },
      },
      [summaryTableRowKeys.glycemicScore]: {
        [summaryTablColumnKeys.insight]: {
          errorText: "Missing glycemic score insight",
        },
      },
      [summaryTableRowKeys.distressScore]: {
        [summaryTablColumnKeys.insight]: {
          errorText: "Missing distress score insight",
        },
      },
      [healthHabitsKey]: {
        errorText: "Please select one healthy habit from the check box",
      },
    },
  },
  patientProgress: {
    [storeAndProgressTableKeys.timeInRange]: {
      [patientProgressRowKeys.seventhDay]: "",
      [patientProgressRowKeys.fourteenthDay]: "",
      [patientProgressRowKeys.interpretation]: "",
      [patientTIRRowKeys.diet]: "",
      [patientTIRRowKeys.exercise]: "",
    },
    [storeAndProgressTableKeys.distressScore]: {
      [patientGVRowKeys.stress]: "",
      [patientGVRowKeys.sleep]: "",
    },
    [storeAndProgressTableKeys.averageGlucose]: {
      [patientProgressRowKeys.seventhDay]: "",
      [patientProgressRowKeys.fourteenthDay]: "",
      [patientProgressRowKeys.averageGlucose]: "",
    },
    [requiredFieldsMapKey]: {
      [storeAndProgressTableKeys.timeInRange]: {
        [patientProgressRowKeys.interpretation]: {
          errorText: `Missing "interpretation" for Time In Range`,
        },
      },
      [storeAndProgressTableKeys.averageGlucose]: {
        [patientProgressRowKeys.interpretation]: {
          errorText: `Missing "interpretation" for Average Glucose Value`,
        },
      },
    },
  },
  patientInfo: {
    patientName: "",
    age: "",
    gender: "",
    doctorName: "",
    clinicName: "",
    coachName: "Test Coach",
    bmi: "",
    height: 0,
    weight: 0,
    hba1c: 0,
    goal: [],
    [requiredFieldsMapKey]: {
      age: {
        errorText: `Missing patient's "age"`,
      },
      gender: {
        errorText: `Missing patient's "gender"`,
      },
      height: {
        errorText: `Missing patient's "height"`,
      },
      weight: {
        errorText: `Missing patient's "weight"`,
      },
      hba1c: {
        errorText: `Missing patient's "hba1c" result`,
      },
      goal: {
        errorText: `Missing patient's "goal"`,
      },
    },
  },
  reportId: null,
  reportName: "",
  activeFileType: reportTypesId.seventhDayReport,
  seventhDayGraphData: null,
  fourteenthDayGraphData: null,
  dayWiseCGMData: null,
  insights: null,
  maxGlucoseValue: 0,
  minGlucoseValue: 0,
};

// {heading: '', errors: ['', '']} => [{}, {}]

const columnIndexing = {
  0: "nocturnal",
  1: "morning",
  2: "afternoon",
  3: "evening",
  4: "overall",
};

function createReportReducer(state = initialState, action) {
  switch (action.type) {
    case CreateReportActions.update_report: {
      const {
        rowIndex,
        columnIndex,
        data,
        tableName,
        tableType,
        rowInnerIndex,
        messageId,
        maxCharLength,
      } = action.payload;

      let subType =
        tableType === dailyPatternTypes.insight
          ? insightRowHeadingObj[rowIndex]
          : interventionRowHeadingObj[rowIndex];

      //for setting intervention assessment option based on the insight assessment opiton selected
      let interventionAssessment;
      if (
        rowIndex > 0 &&
        tableName === reportType.seventhDayReportData &&
        rowInnerIndex === reportMessageTypes.assessment
      ) {
        let interVentionNewData = [
          ...state?.[tableName]?.[dailyPatternTypes.intervention]?.data,
        ];
        if (rowInnerIndex === reportMessageTypes.assessment) {
          delete interVentionNewData[rowIndex - 1][columnIndexing[columnIndex]]
            ?.customMessage;
          delete interVentionNewData[rowIndex - 1][columnIndexing[columnIndex]]
            ?.text;
          delete interVentionNewData[rowIndex - 1][columnIndexing[columnIndex]]
            ?.messageId;
        }

        interVentionNewData[rowIndex - 1][columnIndexing[columnIndex]] = {
          ...interVentionNewData[rowIndex - 1][columnIndexing[columnIndex]],
          [innerRowIndexing[rowInnerIndex]]: data,
          subType,
          tableType: dailyPatternTypes.intervention,
          dayPart: columnIndexing[columnIndex],
        };
        interventionAssessment = interVentionNewData;
      }

      const newData = [...state?.[tableName]?.[tableType]?.data];
      if (rowInnerIndex === reportMessageTypes.assessment) {
        delete newData[rowIndex][columnIndexing[columnIndex]]?.customMessage;
        delete newData[rowIndex][columnIndexing[columnIndex]]?.text;
        delete newData[rowIndex][columnIndexing[columnIndex]]?.messageId;
      }

      if (
        newData[rowIndex][columnIndexing[columnIndex]]?.customMessage &&
        rowInnerIndex === reportMessageTypes.message
      ) {
        delete newData[rowIndex][columnIndexing[columnIndex]]?.customMessage;
      } else if (
        newData[rowIndex][columnIndexing[columnIndex]]?.text &&
        rowInnerIndex === reportMessageTypes.customMessage
      ) {
        delete newData[rowIndex][columnIndexing[columnIndex]]?.text;
      }
      newData[rowIndex][columnIndexing[columnIndex]] = {
        ...newData[rowIndex][columnIndexing[columnIndex]],
        [innerRowIndexing[rowInnerIndex]]: data,
        ...(messageId &&
          rowInnerIndex !== reportMessageTypes.assessment && {
            messageId: messageId,
          }),
        subType,
        tableType: tableType,
        dayPart: columnIndexing[columnIndex],
      };
      const updatedState = {
        ...state,
        [tableName]: {
          ...state[tableName],
          [tableType]: {
            ...state[tableName][tableType],
            data: newData,
            maxCharLength: maxCharLength ? maxCharLength : 0,
          },
          ...(interventionAssessment && {
            [dailyPatternTypes.intervention]: {
              ...state[tableName][dailyPatternTypes.intervention],
              data: interventionAssessment,
            },
          }),
        },
      };
      return updatedState;
    }
    case CreateReportActions.set_report_data: {
      const insight = action.payload.insight;
      const intervention = action.payload.intervention;
      const report = action.payload.reportType;

      const reportData = {
        ...(state[report].insight && {
          insight: { data: insight || state[report].insight },
        }),
        ...(state[report].intervention && {
          intervention: {
            data: intervention || state[report].intervention,
          },
        }),
      };

      const updatedState = {
        ...state,
        [report]: { ...state[report], ...reportData },
      };
      return updatedState;
    }
    case CreateReportActions.set_fileTypeSelected:
      return { ...state, activeFileType: action.payload };
    case CreateReportActions.update_report_summary:
      return {
        ...state,
        summary: {
          ...state.summary,
          ...action.payload,
          [healthHabitsKey]: {
            ...state.summary[healthHabitsKey],
            ...action.payload[healthHabitsKey],
          },
        },
      };
    case CreateReportActions.update_report_progress:
      return {
        ...state,
        patientProgress: { ...state.patientProgress, ...action.payload },
      };
    case CreateReportActions.get_report_progress_draft.SUCCESS:
      return {
        ...state,
        patientProgress: { ...state.patientProgress, ...action.payload },
      };
    case CreateReportActions.set_graph_data:
      return {
        ...state,
        ...action?.payload,
      };
    case CreateReportActions.set_active_report_id:
      const reportId = action?.payload;
      return {
        ...state,
        ...{ ...(reportId && { reportId: reportId }) },
      };
    case CreateReportActions.set_active_report_name:
      return {
        ...state,
        reportName: action?.payload,
      };
    case CreateReportActions.update_patient_info: {
      let patientInfo = action?.payload;
      return {
        ...state,
        patientInfo: { ...state.patientInfo, ...patientInfo },
      };
    }
    case CreateReportActions.set_day_wise_CGM_data: {
      return {
        ...state,
        dayWiseCGMData: action?.payload,
      };
    }
    case CreateReportActions.get_patient_info_draft.SUCCESS: {
      let patietnInfo = action?.payload?.data;
      return {
        ...state,
        patientInfo: patietnInfo,
      };
    }
    default:
      return state;
  }
}

export { createReportReducer };
