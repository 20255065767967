export { API_URL } from "../config/env.json";
export { ASSURE_PROGRAM_ID, PROD } from "../config/env.json";

export const EXCEL_FILE_TYPE =
  ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel";

export const PDF_FILE_TYPE = ".pdf, application/pdf, .txt";
export const IMAGE_FILE_TYPE = ".jpeg, .png, .jpg";

export const USER_ROLE = {
  DOCTOR: "doctor",
  COACH: "coach",
  COUNSELOR: "counselor",
};

export const ReportType = {
  CGM1: "CGM1",
  CGM2: "CGM2",
  CGMFinal: "CGMFinal",
  CGMProReport1: "CGMProReport1",
  CGMProReportFinal: "CGMProReportFinal",
  CGMProCSV1: "CGMProCSV1",
  CGMProCSVFinal: "CGMProCSVFinal",
  coachassessment: "coachassessment",
  fitnessPass: "fitnessPASS",
  coachMetasense: "coachmetasense",
  clinicalassessment: "clinicalassessment",
  prescription: "prescription",
  prescription2: "prescription2",
  prescription3: "prescription3",
  goaldata: "goaldata",
  random: "random",
  emotionalWellness: "emotionalwellness",
};

export const ReportTitles = {
  [ReportType.CGM1]: "CGM 1 Report ",
  [ReportType.CGM2]: "CGM 2 Report",
  [ReportType.CGMFinal]: "CGM Final Report",
  [ReportType.CGMProReport1]: "CGM Pro Report",
  [ReportType.CGMProReportFinal]: "CGM Pro Final Report",
  [ReportType.CGMProCSV1]: "CGM Pro CSV Report",
  [ReportType.CGMProCSVFinal]: "CGM Pro CSV Final Report",
  [ReportType.coachassessment]: "Health Coach PASS Report",
  [ReportType.fitnessPass]: "Fitness PASS Report",
  [ReportType.clinicalassessment]: "Clinical Assessment Data",
  [ReportType.prescription]: "Prescription Data",
  [ReportType.prescription2]: "Prescription 2",
  [ReportType.prescription3]: "Prescription 3",
  [ReportType.goaldata]: "Goal Data",
  [ReportType.random]: "Reports",
  [ReportType.emotionalWellness]: "Emotional Wellness Report",
};

export const reportSequenceOrders = [
  {
    reportType: ReportType.goaldata,
  },
  {
    reportType: ReportType.coachassessment,
  },
  {
    reportType: ReportType.fitnessPass,
  },
  {
    reportType: ReportType.clinicalassessment,
  },
  {
    reportType: ReportType.prescription,
  },
  {
    reportType: ReportType.prescription2,
  },
  {
    reportType: ReportType.prescription3,
  },
  {
    reportType: ReportType.CGM1,
  },
  {
    reportType: ReportType.CGM2,
  },
  {
    reportType: ReportType.CGMFinal,
  },
  {
    reportType: ReportType.CGMProReport1,
  },
  {
    reportType: ReportType.CGMProReportFinal,
  },
  {
    reportType: ReportType.CGMProCSV1,
  },
  {
    reportType: ReportType.CGMProCSVFinal,
  },
  {
    reportType: ReportType.emotionalWellness,
  },
];

export const userRoles = {
  admin: "ADMIN",
  superAdmin: "superadmin",
  coach: "COACH",
  fitnessCoach: "fitnesscoach",
  emotionalWellnessCoach: "emotionalwellnesscoach",
  councellor: "COUNSELOR",
  opsAdmin: "OPSADMIN",
  inventoryManager: "INVENTORYMANAGER",
  automation: "AUTOMATION",
};

export const preferredRangeForStats = {
  glucose: {
    upperLimit: 140,
    lowerLimit: 70,
  },
  timeInRage: {
    upperLimit: 24,
    lowerLimit: 16,
  },
};

export const metricUnit = {
  glucose: "mg/dL",
  tir: "hours",
  steps: "steps",
};
