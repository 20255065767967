import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import React, { useMemo, useState } from "react";

import "./Table.css";

const PAGE_SIZE = 10;

const AllPatientTable = (props) => {
  const [page, setPage] = useState(1);
  const { columns, data, handleViewDetails, search } = props;

  const filteredData = useMemo(
    () =>
      !search ? data.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE) : data,
    [page, data]
  );

  return (
    <div className="data-table-container">
      <table className="data-table">
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th key={index}>{column.title}</th>
            ))}
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((item) => (
            <tr key={item.id}>
              {columns.map((column, index) => (
                <td key={index}>
                  {column.render ? column.render(item) : item[column.field]}
                </td>
              ))}
              <td
                onClick={() => handleViewDetails(item.id, item)}
                className="action"
              >
                View Details
              </td>
            </tr>
          ))}
          <tr>
            <td colSpan={columns.length + 1}>
              <div
                style={{
                  color: "#8C8C8C",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                {(page - 1) * PAGE_SIZE + 1}-
                {Math.min(data.length, page * PAGE_SIZE)} of {data.length}
                &nbsp;&nbsp;&nbsp;
                <div
                  className="pagination-icon"
                  onClick={() => setPage(page < 2 ? 1 : page - 1)}
                >
                  <ChevronLeft />
                </div>
                <div
                  className="pagination-icon"
                  onClick={() =>
                    setPage(
                      Math.ceil(data.length / PAGE_SIZE) === page
                        ? Math.ceil(data.length / PAGE_SIZE)
                        : page + 1
                    )
                  }
                >
                  <ChevronRight />
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default AllPatientTable;
