const taskDetailsDrawerStyle = (theme) => ({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    minHeight: "64px",
    maxHeight: "64px",
    alignItems: "center",
    borderBottom: "1px solid #ccc",
    padding: "0px 16px",
    position: "sticky",
    zIndex: 1000,
    top: 0,
    backgroundColor: "white",
  },
  imgContainer: {
    height: "250px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: "#F7F8FC",
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    padding: "16px",
    borderBottom: "1px solid #ccc",
  },
  bottomContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    borderTop: "1px solid #ccc",
    borderBottom: "1px solid #ccc",
    marginTop: "0.5rem",
  },
  emptyContainer: {
    height: "50px",
    width: "50px",
    borderRadius: "5px",
    overflow: "hidden",
    margin: "5px",
    marginLeft: "0px",
  },
  headerText: {
    fontSize: "16px",
    fontWeight: 500,
    color: "#2B377E",
    cursor: "pointer",
  },
  text: {
    fontSize: "24px",
    fontWeight: 500,
  },
  sub_text: {
    fontSize: "16px",
    fontWeight: 500,
  },
  icon: {
    cursor: "pointer",
  },
});

export default taskDetailsDrawerStyle;
