import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import lang from "assets/languages";
import Loader from "@components/atoms/Loader";
import { END_POINT } from "network/constants";
import { API_METHODS } from "network/constants";
import { apiResponses } from "network/constants";
import { updateReport } from "pages/CreateReport/actions/CreateReportActions";
import { reportMessageTypes } from "pages/CreateReport/constants";
import { assessmentKeys } from "pages/CreateReport/constants";
import { customMessageText } from "pages/CreateReport/constants";
import { columnHeadingWithoutTableName } from "pages/CreateReport/constants";
import { getMaxCharLength } from "pages/CreateReport/utils";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import api from "utils/api";
import { toTitleCase } from "utils/stringUtils";
import { notyf } from "utils/utility";
import { makeStyles } from "@material-ui/core/styles";
import { maxLineChars } from "pages/CreateReport/constants";
import { dailyPatternTypes } from "pages/CreateReport/constants";

const useStyles = makeStyles({
  wrapText: {
    whiteSpace: "normal",
    wordWrap: "break-word",
    width: "150px",
  },
});

export const SelectMolecules = ({
  classes,
  columnName,
  rowIndex,
  columnIndex,
  tableName,
  tableType,
  rowHeadings,
  assessmentData,
}) => {
  const isMounted = useRef(false);
  const muiClasses = useStyles();
  const tableData = useSelector(
    (state) => state.createReportReducer[tableName][tableType]?.data
  );
  let cell =
    tableData?.[rowIndex]?.[columnHeadingWithoutTableName[columnIndex]];

  let typeValue = cell && cell?.type ? cell.type : "";
  let textValue = cell && cell?.text ? cell.text : "";
  let customMessage = cell && cell?.customMessage ? cell.customMessage : "";

  const [fieldValues, setFieldValues] = useState({
    textField: textValue,
    typeField: typeValue,
    customInput: customMessage,
    selectedId: null,
  });

  const [selectSubOptions, setSelectSubOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleCustomInputChange = (event) => {
    setFieldValues({ ...fieldValues, customInput: event.target.value });
    let maxCharLength = Math.max(
      getMaxCharLength(tableData),
      event.target?.value?.length
    );
    dispatch(
      updateReport({
        data: event.target.value,
        tableName,
        columnName,
        columnIndex,
        rowIndex: rowIndex,
        rowInnerIndex: reportMessageTypes.customMessage,
        tableType,
        messageId: fieldValues?.selectedId,
        maxCharLength,
      })
    );
  };

  const dispatch = useDispatch();

  const handleMainInputChange = (val) => {
    setFieldValues({
      ...fieldValues,
      textField: "",
      customInput: "",
      typeField: val.target.value,
    });

    dispatch(
      updateReport({
        data: val.target.value,
        tableName: tableName,
        columnName: columnName,
        columnIndex: columnIndex,
        rowIndex: rowIndex,
        rowInnerIndex: reportMessageTypes.assessment,
        tableType,
      })
    );
  };

  const handleSubTypeChange = (fieldEvent) => {
    const subTypeValue = fieldEvent.target.value?.message;

    if (subTypeValue === customMessageText) {
      setFieldValues({
        ...fieldValues,
        selectedId: fieldEvent.target.value?.messageId,
        textField: fieldEvent.target.value?.message,
      });
      return;
    }
    setFieldValues({
      ...fieldValues,
      textField: fieldEvent.target.value?.message,
      customInput: null,
    });
    let maxCharLength = Math.max(
      getMaxCharLength(tableData),
      subTypeValue.length
    );

    dispatch(
      updateReport({
        data: subTypeValue,
        tableName,
        columnName,
        columnIndex,
        rowIndex: rowIndex,
        rowInnerIndex: reportMessageTypes.message,
        tableType,
        messageId: fieldEvent.target.value?.messageId,
        maxCharLength,
      })
    );
  };

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    setFieldValues({
      ...fieldValues,
      textField:
        cell && cell?.text
          ? tableData[rowIndex][columnHeadingWithoutTableName[columnIndex]].text
          : "",
      customInput:
        cell && cell?.customMessage
          ? tableData[rowIndex][columnHeadingWithoutTableName[columnIndex]]
              .customMessage
          : "",
      typeField:
        cell && cell?.type
          ? tableData[rowIndex][columnHeadingWithoutTableName[columnIndex]].type
          : "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textValue, typeValue, customMessage]);

  useEffect(() => {
    const getAssessmentSubTypeOptions = async () => {
      try {
        setLoading(true);
        const {
          data: { data, status },
        } = await api(
          END_POINT.getReportSubTypeOptions(
            tableType,
            rowHeadings?.[rowIndex],
            fieldValues?.typeField,
            columnHeadingWithoutTableName?.[columnIndex]
          ),
          {
            method: API_METHODS.get,
          }
        );

        if (status === apiResponses.success) {
          data ? setSelectSubOptions(data) : setSelectSubOptions([]);
        } else {
          notyf.error("failed to fetch subtypes");
        }
        setLoading(false);
      } catch (e) {
        notyf.error(e, "failed to fetch subtypes");
        setLoading(false);
      }
    };
    if (fieldValues?.typeField) {
      getAssessmentSubTypeOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldValues?.typeField, assessmentData]);
  //api call will be taking place here to set the selectSubOptions

  return (
    <>
      {loading && <Loader />}
      <div className={classes.tableCell}>
        <div>
          <FormControl fullWidth>
            <InputLabel id="select-label">{lang.select}</InputLabel>
            <Select
              labelId="select-label"
              renderValue={() => {
                return toTitleCase(fieldValues?.typeField);
              }}
              value={toTitleCase(fieldValues?.typeField)}
              onChange={handleMainInputChange}
              label="Select"
              style={
                fieldValues?.typeField === assessmentKeys.good
                  ? styles.good
                  : fieldValues?.typeField === assessmentKeys.average
                  ? styles.average
                  : styles.bad
              }
              disabled={dailyPatternTypes.intervention === tableType}
            >
              {assessmentData &&
                assessmentData.map((option, rowIndex) => {
                  return (
                    <MenuItem
                      key={rowIndex}
                      value={option}
                      style={
                        option === assessmentKeys.good
                          ? styles.good
                          : option === assessmentKeys.average
                          ? styles.average
                          : styles.bad
                      }
                    >
                      {toTitleCase(option)}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </div>
      </div>
      <div className={classes.bottomSelect}>
        <div>
          <FormControl fullWidth>
            <InputLabel id="select-label">Select</InputLabel>
            <Select
              labelId="select-label"
              value={
                fieldValues?.customInput
                  ? customMessageText
                  : fieldValues?.textField
              }
              onChange={handleSubTypeChange}
              label="Select"
              displayEmpty={true}
              defaultValue={textValue}
              renderValue={() => {
                const selectedValue = toTitleCase(
                  fieldValues?.customInput
                    ? customMessageText
                    : fieldValues?.textField
                );
                return (
                  <div
                    className={
                      selectedValue.length > maxLineChars
                        ? muiClasses.wrapText
                        : ""
                    }
                  >
                    {selectedValue}
                  </div>
                );
              }}
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
              }}
              disabled={fieldValues?.typeField ? false : true}
            >
              {selectSubOptions.map((subOption, rowIndex) => {
                return (
                  <MenuItem key={subOption.messageId} value={subOption}>
                    {subOption.message}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        {(fieldValues?.textField === customMessageText ||
          fieldValues?.customInput?.length > 0) && (
          <TextField
            onChange={handleCustomInputChange}
            value={fieldValues?.customInput}
            placeholder="Enter Custom Input"
            multiline
            rows={6}
            variant="outlined"
            inputProps={{ maxLength: 500 }}
            disabled={fieldValues?.typeField ? false : true}
          />
        )}
      </div>
    </>
  );
};

const styles = {
  good: {
    color: "green",
    width: "180px",
    alignSelf: "center",
  },
  average: {
    color: "black",
    width: "180px",
  },
  bad: {
    color: "red",
    width: "180px",
  },
};
