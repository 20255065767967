let store;

class StoreProviderService {
  init(reduxStore) {
    store = reduxStore;
  }

  getStore() {
    return store;
  }

  getState() {
    return this.getStore()?.getState();
  }

  getDispatch() {
    return store.dispatch;
  }
}

const storeProviderService = new StoreProviderService();
export {storeProviderService as StoreProviderService};
