import { Box, makeStyles } from "@material-ui/core";
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { ArrowForwardIos } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  list: {
    height: "76px",
    border: "1px solid #D9D9D9;",
    backgroundColor: "#fff",
  },
  title: {
    fontSize: "16px",
    fontWeight: "400",
    fontStyle: "normal",
    fontFamily: "Roboto",
  },
  box: {
    height: "16px",
    borderRadius: "8px",
    marginRight: "8px",
    textAlign: "center",
  },
  status: {
    color: "#FFFFFF",
    height: "14px",
    fontSize: "12px",
    fontWeight: "700",
    padding: "0px 10px",
  },
  active: {
    backgroundColor: "#389E0D",
  },
  expired: {
    backgroundColor: "#FF5522",
  },
  avatar: {
    width: "44px",
    height: "44px",
    borderRadius: "20px",
    backgroundColor: "#5BABCC",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    height: "18px",
  },
}));

export default function ProfileList({
  id,
  patient_name,
  patient_contact,
  ...props
}) {
  const classes = useStyles();
  const { history } = props;

  const handleClick = (id) => {
    history.push({ pathname: `patient/${id}`, state: { data: id } });
  };

  return (
    <ListItem className={classes.list} button onClick={() => handleClick(id)}>
      <ListItemAvatar>
        <Box className={classes.avatar}>
          <Typography style={{ color: "#2B377E", fontWeight: "700" }}>
            {patient_name[0]}
          </Typography>
        </Box>
      </ListItemAvatar>
      <ListItemText
        id={"Label-1"}
        primary={patient_name}
        secondary={patient_contact}
        className={classes.title}
      />
      <ArrowForwardIos className={classes.icon} color="disabled" />
    </ListItem>
  );
}
