import React from "react";
import { Box, Typography, withStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import MyAccordion from "../Accordion/Accordion";
import moment from "moment";
import weekPlanDrawerStyle from "../../../../assets/jss/pages/components/weekPlanDrawerStyle";
import { BoxComponent } from "@components/molecules/BoxComponent";
import { startIcon } from "assets/icons";
import { keepIcon } from "assets/icons";
import { stopIcon } from "assets/icons";
import { DietPlan } from "@components/molecules/DietPlan";
import DietImage from "../../../../assets/icons/diet-image.svg";
import Download from "../../../../assets/icons/download.svg";
import { BlogCard } from "@components/molecules/BlogCard";
import BlogDummy from "../../../../assets/images/blog-card.png";
import Slider from "react-slick";
import { VideoCard } from "@components/molecules/VideoCard";

const WeekPlanDrawer = (props) => {
  const { classes, currentWeekPlan } = props;
  let settings = {
    infinite: false,
    arrows: true,
    adaptiveHeight: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    className: classes.slide,
  };

  const keepSteps =
    currentWeekPlan?.activityType
      ?.filter((activity) => activity?.activityType === "keep")
      .map((activity) => ({
        id: activity.id,
        text: activity?.activityTitle,
      })) ?? [];

  const stopSteps =
    currentWeekPlan?.activityType
      ?.filter((activity) => activity?.activityType === "stop")
      .map((activity) => ({
        id: activity.id,
        text: activity?.activityTitle,
      })) ?? [];

  return (
    <>
      <div className={classes.headerContainer}>
        <Typography className={classes.headerText}>Details</Typography>
        <CloseIcon className={classes.icon} onClick={props.handleCloseDrawer} />
      </div>
      <div className={classes.container}>
        <Typography className={classes.heading}>
          {currentWeekPlan?.phase}
        </Typography>
        <Typography className={classes.subHeading}>
          {"From "}
          {moment(new Date(currentWeekPlan?.from)).format("ddd DD MMM, YYYY")}
          {" to "}
          {moment(new Date(currentWeekPlan?.to)).format("ddd DD MMM, YYYY")}
        </Typography>
        <div className={classes.boxContainer}>
          <BoxComponent
            classes={classes}
            bg="rgba(0, 165, 145, 0.75)"
            icon={startIcon}
            data={currentWeekPlan?.activityType
              ?.filter((activity) => activity?.activityType === "start")
              .map((activity) => ({
                id: activity.id,
                text: activity?.activityTitle,
              }))}
          />
          {Boolean(keepSteps?.length) && (
            <BoxComponent
              classes={classes}
              bg="rgba(35, 175, 215, 0.75)"
              icon={keepIcon}
              data={keepSteps}
            />
          )}
          {Boolean(stopSteps.length) && (
            <BoxComponent
              classes={classes}
              bg="rgba(240, 100, 125, 0.75)"
              icon={stopIcon}
              data={stopSteps}
            />
          )}
        </div>
        <Typography className={classes.subHeaderText1}>DIET</Typography>
        <DietPlan
          classes={classes}
          src={DietImage}
          srcDownload={Download}
          downloadLink={currentWeekPlan?.dietPDFLink}
        />
        <Typography className={classes.subHeaderText1}>LEARN</Typography>
        <Slider {...settings}>
          {currentWeekPlan?.blog?.map((article) => (
            <BlogCard
              classes={classes}
              article={article}
              title={article?.title}
              thumbnail={article?.smallImageURL}
              openBlog={props.openBlog}
              key={article.id}
            />
          ))}
        </Slider>
        <Typography className={classes.subHeaderText1}>EXERCISE</Typography>
        <Slider {...settings}>
          {currentWeekPlan?.exercise?.map(
            ({
              id,
              duration,
              videoSchedule: schedule,
              videoURL: videoLink,
              videoName: title,
              videoThumbnail: thumbnail,
            }) => (
              <VideoCard
                classes={classes}
                title={title}
                schedule={schedule}
                duration={duration}
                videoLink={videoLink}
                thumbnail={thumbnail}
                key={id}
              />
            )
          )}
        </Slider>

        {Boolean(currentWeekPlan?.basicRulesPDFLink) ? (
          <a
            style={{ textDecoration: "none" }}
            href={currentWeekPlan?.basicRulesPDFLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Box className={classes.dosContainer}>
              <img width="20px" height="20px" src={Download} />
              <Typography className={classes.dosContainerText}>
                &nbsp; Basic Do’s and Don’ts
              </Typography>
            </Box>
          </a>
        ) : (
          <Box
            className={classes.dosContainer}
            style={{ cursor: "default", opacity: 0.75 }}
          >
            <img width="20px" height="20px" src={Download} />
            <Typography className={classes.dosContainerText}>
              &nbsp; Basic Do’s and Don’ts (Unavailable!)
            </Typography>
          </Box>
        )}
      </div>

      {/* <div className={classes.subHeaderContainer}>
        <Typography className={classes.subHeaderText}>Week Plan</Typography>
        <Typography className={classes.sub_text}>
          From {moment(fromDate).format("ddd DD MMM, YYYY")} to{" "}
          {moment(toDate).format("ddd DD MMM, YYYY")}
        </Typography>
      </div>

      {moduleDetailsByWeekPlan.length > 0 &&
        moduleDetailsByWeekPlan.map((plan, index) => {
          return (
            <div key={index}>
              <div className={classes.container}>
                <Typography className={classes.sub_text1}>
                  {plan.moduleInfo.type}
                </Typography>
              </div>
              <MyAccordion
                plan={plan}
                title={plan.moduleInfo.title}
                description={plan.moduleInfo.snippet}
                handleViewWeekPlanDetails={() =>
                  props.handleViewWeekPlanDetails(plan)
                }
                moduleDone={plan.moduleDone}
                // planId={plan.id}
              />
            </div>
          );
        })} */}
    </>
  );
};

export default withStyles(weekPlanDrawerStyle)(WeekPlanDrawer);
