import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withStyles, Typography } from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  ReferenceLine,
  Label,
} from "recharts";
import foodProgressDrawerStyle from "../../../../assets/jss/pages/components/foodProgressDrawerStyle";
import api from "utils/api";
import moment from "moment";
import { weekDays, monthDays, yearDays } from "../../../../utils/utility";
import dietTimelineOne from "../../../../assets/images/diet-timeline-one.png";
import dietTimelineTwo from "../../../../assets/images/diet-timeline-two.png";
import dietTimelineThree from "../../../../assets/images/diet-timeline-three.png";
import dietTimelineFour from "../../../../assets/images/diet-timeline-four.png";
import { toTitleCase } from "../../../../utils/stringUtils";
import { styles } from "styled-system";

const fetchData = async (id, metric) => {
  try {
    const { data } = await api(`/v1/health/getDiet/${id}?duration=${metric}`, {
      method: "get",
    });
    return data;
  } catch (e) {
    console.error("fetch user progress", e);
  }
  return {};
};

const fetchChartData = async (type, id) => {
  try {
    const { data } = await api(`/v1/health/charts/${id}?type=${type}`, {
      method: "get",
    });
    return data;
  } catch (e) {
    console.error("fetch user progress", e);
  }
  return {};
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const FoodProgressDrawer = (props) => {
  const [value, setValue] = React.useState(0);
  const { classes, patientId } = props;

  const [dayData, setDayData] = useState([]);
  const [weekData, setWeekData] = useState([]);
  const [monthData, setMonthData] = useState(props.monthData || []);
  const [yearData, setYearData] = useState({});
  const [details, setDetails] = useState({});
  const [weekDay, setWeekDays] = useState({});
  const [monthDay, setMonthDays] = useState({});
  const [yearDay, setYearDays] = useState({});

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (value === 0 && !dayData?.data) {
      if (!details?.data && !details?.status) {
        fetchChartData("diet", patientId).then(setDetails);
      }
      fetchData(patientId, "day").then(setDayData);
    } else if (value === 1 && !weekData?.data) {
      fetchData(patientId, "week").then(setWeekData);
      setWeekDays(weekDays());
    } else if (value === 2 && !monthData?.data) {
      fetchData(patientId, "month").then(setMonthData);
      setMonthDays(monthDays());
    } else if (value === 3 && !yearData?.data) {
      fetchData(patientId, "year").then(setYearData);
      setYearDays(yearDays());
    }
  }, [patientId, value, details]);

  return (
    <>
      <div className={classes.headerContainer}>
        {/* style={{ display: "flex", overflow: "hidden" }} */}
        <Typography style={{ fontSize: "16px", fontWeight: 500 }}>
          Diet timeline
        </Typography>
        <CloseIcon className={classes.icon} onClick={props.handleCloseDrawer} />
      </div>
      <div className={classes.verticalTimeline}>
        {/* overflowX: "scroll", */}
        {details &&
          details?.data?.map((diet, index) => {
            const dietDateTime = diet.date + " " + diet.time;

            return (
              <div>
                <Typography
                  className={classes.verticalTimelineAndCircle}
                ></Typography>
                <Typography className={classes.dietDateTime}>
                  {moment(dietDateTime, "YYYY-MM-DD HH:mm:ss").format(
                    "DD MMM YYYY, h:mm a"
                  )}
                  {`  ${diet?.dietType ? toTitleCase(diet?.dietType) : ""}`}
                </Typography>
                <Typography className={classes.dietDescription}>
                  {diet?.dietDescription}
                </Typography>
                {diet?.photo?.length > 0 ? (
                  diet?.photo?.map((photo, index) => {
                    return (
                      <div className={classes.imageContainer}>
                        <a
                          style={{ textDecoration: "none" }}
                          href={photo}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={photo}
                            className={classes.image}
                            key={index}
                            alt="Diet Photo not uploaded"
                            width={180}
                            height={120}
                          />
                        </a>
                      </div>
                    );
                  })
                ) : (
                  <img
                    src={"photo"}
                    className={classes.noImage}
                    key={index}
                    alt="Diet Photo not uploaded"
                    width={180}
                    height={120}
                  />
                )}
              </div>
            );
          })}
      </div>
    </>
  );
};

export default withStyles(foodProgressDrawerStyle)(FoodProgressDrawer);
