import { Box } from "@material-ui/core";
import React from "react";
import { useStyles } from "./styles";

export const TargetRangeLegends = ({
  yAxisPixel,
  minTargetValue,
  maxTargetValue,
  maxGlucosePlotted,
  styles = {},
}) => {
  const classes = useStyles();

  return (
    <Box style={styles} className={classes.legendContainer}>
      <Box
        style={{ backgroundColor: "#FFBD00" }}
        className={classes.rangeContainer}
        height={(maxGlucosePlotted - maxTargetValue) * yAxisPixel}
      />
      <Box
        style={{ backgroundColor: "#14B8A6" }}
        className={classes.rangeContainer}
        height={(maxTargetValue - minTargetValue) * yAxisPixel}
      />
      <Box
        style={{ backgroundColor: "#FF5A5F" }}
        className={classes.rangeContainer}
        height={minTargetValue * yAxisPixel}
      />
    </Box>
  );
};
