import React, { useCallback, useMemo } from "react";
import { Box, LinearProgress, Typography, withStyles } from "@material-ui/core";
import weekPlanProgressStyle from "../../../../assets/jss/pages/components/weekPlanProgressStyle";
import CloseIcon from "@material-ui/icons/Close";
import MetricComponent from "@components/molecules/MetricComponent";
import moment from "moment";
import { useFetchProgressCard } from "pages/Patient/pages/Progress/useFetchProgressCard";
import { Activities } from "./Activities";
import MemoNextChevron from "assets/ReactSVG/Next";
import MemoPrevious from "assets/ReactSVG/Previous";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 12,
    borderRadius: 20,
  },
  colorPrimary: {
    backgroundColor: "#EFEFEF",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#00A591",
  },
}))(LinearProgress);

const WeekPlanProgress = ({
  classes,
  handleCloseDrawer,
  selectedWeek,
  weekPlans,
  handleWeekNavigate,
}) => {
  const [progressCard] = useFetchProgressCard(selectedWeek?.id);

  const bslMetric = progressCard?.healthDataScoresBreakDown.find(
    (dataItem) => dataItem.type === "bsl"
  );

  const weightMetric = progressCard?.healthDataScoresBreakDown.find(
    (dataItem) => dataItem.type === "weight"
  );

  const sortedWeekPlans = weekPlans.sort((a, b) => {
    const isAfter = moment(a.to).isAfter(b.to, "days");
    return isAfter ? 1 : -1;
  });
  const selectedWeekIndex = sortedWeekPlans.findIndex(
    (week) => week.id === selectedWeek?.id
  );

  const canMoveNext = useMemo(() => {
    const nextWeek = sortedWeekPlans[selectedWeekIndex + 1];
    if (nextWeek && moment(nextWeek.from).isBefore(moment())) {
      return true;
    }
    return false;
  }, [weekPlans, selectedWeekIndex]);

  const canMovePrev = useMemo(() => {
    const prevWeek = sortedWeekPlans[selectedWeekIndex - 1];
    if (prevWeek && moment(prevWeek.to).isBefore(moment())) {
      return true;
    }
    return false;
  }, [weekPlans, selectedWeekIndex]);

  const navigateWeek = useCallback(
    (toNext) => {
      const chosenWeek = toNext
        ? sortedWeekPlans[selectedWeekIndex + 1]
        : sortedWeekPlans[selectedWeekIndex - 1];
      if (chosenWeek) {
        handleWeekNavigate(chosenWeek);
      }
    },
    [weekPlans, selectedWeekIndex]
  );

  return (
    <>
      <div className={classes.headerContainer}>
        <Typography style={{ fontSize: "16px", fontWeight: 500 }}>
          WeekPlan Progress
        </Typography>
        <CloseIcon className={classes.icon} onClick={handleCloseDrawer} />
      </div>
      <div className={classes.container}>
        <Box className={classes.mainCard}>
          <Box className={classes.dateContainer}>
            <MemoPrevious
              isActive={canMovePrev}
              onClick={() => {
                if (canMovePrev) {
                  navigateWeek(false);
                }
              }}
            />
            <Typography className={classes.dateText}>
              {selectedWeek?.from
                ? moment(new Date(selectedWeek?.from)).format("DD MMM")
                : "N/A"}
              {" - "}
              {selectedWeek?.to
                ? moment(selectedWeek?.to).format("DD MMM")
                : "N/A"}
            </Typography>
            <MemoNextChevron
              isActive={canMoveNext}
              onClick={() => {
                if (canMoveNext) {
                  navigateWeek(true);
                }
              }}
            />
          </Box>
          <Box className={classes.borderBottom} />

          <Box className={classes.barContainer}>
            <Box className={classes.barSubContainer}>
              <Typography className={classes.barText}>
                {selectedWeek?.phase}
              </Typography>
              <Typography className={classes.barText2}>
                {progressCard?.totalWeekplanScore}/
                {progressCard?.maximumWeekplanPoints}
              </Typography>
            </Box>
            <Box className={classes.ProgressBar}>
              <BorderLinearProgress
                variant="determinate"
                value={
                  (progressCard?.totalWeekplanScore /
                    progressCard?.maximumWeekplanPoints) *
                  100
                }
              />
            </Box>
            {/* <Typography className={classes.barText3}>
              {selectedWeek?.daysRemaining || 0} {" more "}
              {selectedWeek?.daysRemaining > 1 ? "days" : "day"}
              {" to complete the week challenge."}
            </Typography> */}
          </Box>
          <Box className={classes.borderBottom} />

          <MetricComponent
            classes={classes}
            metric="Consultation"
            number={`${
              progressCard?.healthDataScoresBreakDown.find(
                (dataItem) => dataItem.type === "consultation"
              )?.score ?? "0"
            }/55`}
          />
          <MetricComponent
            classes={classes}
            metric="Blood Sugar Level"
            number={`${bslMetric?.score ?? ""}/50`}
            subText={
              Boolean(bslMetric?.value && bslMetric?.date)
                ? `${bslMetric?.value || "N/A"} kg added on ${moment(
                    new Date(bslMetric?.date)
                  ).format("Do MMM")}`
                : undefined
            }
          />
          <MetricComponent
            classes={classes}
            metric="Weight"
            number={`${weightMetric?.score ?? ""}/50`}
            subText={
              Boolean(weightMetric?.value && weightMetric?.date)
                ? `${weightMetric?.value || "N/A"} kg added on ${moment(
                    new Date(weightMetric?.date)
                  ).format("Do MMM")}`
                : undefined
            }
          />
          <Activities
            classes={classes}
            progressCard={progressCard}
            activeWeek={selectedWeek}
          />
        </Box>
      </div>
    </>
  );
};
export default withStyles(weekPlanProgressStyle)(WeekPlanProgress);
