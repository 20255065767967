import React from "react";
import { cgmGraphLegends } from "./constant";
import { Box, Typography } from "@material-ui/core";
import { useStyles } from "./styles";

export const CGMGraphLegends = () => {
  const classes = useStyles();

  return (
    <Box>
      {cgmGraphLegends.map((graphLegend) => {
        const { color, name } = graphLegend;

        return (
          <Box className={classes.legendContainer}>
            <Box
              style={{ backgroundColor: color }}
              className={classes.legendMarker}
            />
            <Typography className={classes.legendName}>{name}</Typography>
          </Box>
        );
      })}
    </Box>
  );
};
