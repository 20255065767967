import moment from "moment";

import {
  END_POINT,
  API_METHODS
} from "network/constants";

import api from "utils/api";

const getOverallSummaryForCoach = async (props) => {
  try {
    const {
      sensor,
      patientId
    } = props;

    const {
      sensorActivationDateInUTC,
      sensorExpirationDateInUTC
    } = sensor || {};

    const url = END_POINT.getOverallSummary(patientId);
    const options = {
      params: {
        "from": moment(sensorActivationDateInUTC).format("YYYY-MM-DD"),
        "to": moment(sensorExpirationDateInUTC).format("YYYY-MM-DD"),
        "DD&averageGlucoseSummary": true,
        "TIRSummary": true
      },
      method: API_METHODS.GET
    }

    const response = await api(url, options);
    return response;
  }
  catch (err) {
    // Do nothing
  }
}

export default getOverallSummaryForCoach;
