import React, { useState } from "react";
const {
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} = require("@material-ui/core");

const CheckBoxList = ({
  numColumns = 1,
  onCheckBoxChange,
  checkboxOptions, // [{key, value, label: '', disabled: true/false}]
  helperText = "",
  type = "multi",
}) => {
  let defaultOptions = {};

  checkboxOptions.forEach((option) => {
    defaultOptions = {
      ...defaultOptions,
      [option.key]: option.value,
    };
  });

  const [state, setState] = useState(defaultOptions);

  const handleChange = (event) => {
    if (!onCheckBoxChange) {
      return;
    }
    if (type === "multi") {
      const updatedCheckboxState = {
        ...state,
        [event.target.name]: event.target.checked,
      };

      setState(updatedCheckboxState);
      onCheckBoxChange(updatedCheckboxState);
    } else {
      let updatedState = {};

      Object.keys(state).forEach((key) => {
        const selectedOption = checkboxOptions.find(
          (option) => option?.key === key
        );

        if (selectedOption?.disabled) {
          updatedState = { ...updatedState, [key]: state[key] };
        } else {
          updatedState = { ...updatedState, [key]: false };
        }
      });

      const updatedCheckboxState = {
        ...updatedState,
        [event.target.name]: event.target.checked,
      };

      setState(updatedCheckboxState);
      onCheckBoxChange(updatedCheckboxState);
    }
  };

  return (
    <FormControl sx={{ m: numColumns }} component="fieldset" variant="standard">
      <FormGroup>
        {checkboxOptions.map((option) => {
          return (
            <FormControlLabel
              key={option.key}
              control={
                <Checkbox
                  checked={option.value}
                  disabled={option.disabled}
                  onChange={handleChange}
                  name={option.key}
                  style={{
                    padding: "0 4px 4px 0",
                  }}
                />
              }
              label={
                <Typography style={{ fontSize: "12px", color: "#8C8C8C" }}>
                  {option.label}
                </Typography>
              }
            />
          );
        })}
      </FormGroup>
    </FormControl>
  );
};

const checkBoxList = React.memo(CheckBoxList);
export { checkBoxList as CheckBoxList };
