/* eslint-disable array-callback-return */
import React, { useMemo } from "react";
import moment from "moment";
import clsx from "clsx";
import { Typography, Divider, withStyles } from "@material-ui/core";
import appointmentStyle from "../../../../assets/jss/pages/appointmentStyle";

const Appointment = ({ appointmentData, appointmentId, ...props }) => {
  const { classes } = props;

  const upcomingAppointments = useMemo(
    () =>
      appointmentData.filter(
        (appointment) => appointment.appointmentStatus === "booked"
      ),
    [appointmentData]
  );

  const pastAppointments = useMemo(
    () =>
      appointmentData.filter(
        (appointment) =>
          appointment.appointmentStatus === "reviewed" ||
          appointment.appointmentStatus === "cancelled"
      ),
    [appointmentData]
  );

  return (
    <>
      <div className={classes.topContainer}>
        <Typography className={classes.text}>Upcoming Appointments</Typography>
      </div>
      <div className={classes.container}>
        {upcomingAppointments.length > 0 ? (
          upcomingAppointments.map((appointment, index, arr) => {
            return (
              <React.Fragment key={index}>
                <div
                  className={clsx(classes.mainContainer, {
                    [classes.activeContainer]: appointmentId === appointment.id,
                  })}
                >
                  <div>
                    <Typography className={classes.text}>
                      Appointment with{" "}
                      {appointment.doctor === null ? "Coach" : "Doctor"}
                    </Typography>
                    <Typography className={classes.sub_text}>
                      {appointment.appointmentDate
                        ? moment(appointment.appointmentDate).format(
                            "dddd, MMMM Do h:mm A"
                          )
                        : "N/A"}
                    </Typography>
                  </div>
                  <Typography
                    className={classes.sub_text1}
                    onClick={() =>
                      props.handleOpenAppointmentDrawer(appointment.id)
                    }
                  >
                    VIEW
                  </Typography>
                </div>
                {index !== arr.length - 1 && <Divider />}
              </React.Fragment>
            );
          })
        ) : (
          <Typography
            style={{
              fontSize: "12px",
              padding: "10px 20px",
            }}
          >
            No Appointments Found
          </Typography>
        )}
      </div>

      <div className={classes.completed_appointments}>
        <Typography className={classes.text}>Completed Appointments</Typography>
      </div>
      <div className={classes.subContainer}>
        {pastAppointments.length > 0 ? (
          pastAppointments.map((appointment, index, arr) => {
            return (
              <React.Fragment key={index}>
                <div className={classes.mainContainer}>
                  <div>
                    <Typography className={classes.text}>
                      Appointment with{" "}
                      {appointment.doctor === null ? "Coach" : "Doctor"}
                    </Typography>
                    <Typography className={classes.sub_text}>
                      {appointment.appointmentDate
                        ? moment(appointment.appointmentDate).format(
                            "dddd, MMMM Do h:mm A"
                          )
                        : "N/A"}
                    </Typography>
                  </div>
                  {appointment.appointmentStatus === "cancelled" ? (
                    <Typography className={classes.cancelled_text}>
                      Cancelled
                    </Typography>
                  ) : (
                    <Typography className={classes.completed_text}>
                      Reviewed
                    </Typography>
                  )}
                </div>

                {index !== arr.length - 1 && <Divider />}
              </React.Fragment>
            );
          })
        ) : (
          <Typography
            style={{
              fontSize: "12px",
              padding: "10px 20px",
            }}
          >
            No Appointments Found
          </Typography>
        )}
      </div>
    </>
  );
};

export default withStyles(appointmentStyle)(Appointment);
