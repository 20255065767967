/* eslint-disable react-hooks/exhaustive-deps */
import Button from "@components/atoms/Button";
import React, { useEffect, useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { Form, Formik } from "formik";
import Loader from "@components/atoms/Loader";
import FormTextInput from "@components/molecules/FormTextInput/FormTextInput";
import ProfileList from "@components/molecules/ProfileList";
import { useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  GetAllPatientsAction,
  PostUploadAppointmentsAction,
} from "./actions/HomeActions";
import lang from "assets/languages";

const useStyles = makeStyles((theme) => ({
  addPatientButtonContainer: {
    marginBottom: "16px",
    width: "100%",
    marginTop: "20px",
  },
  uploadAppointmentButton: { marginBottom: "20px" },
  button: {
    marginBottom: "10px",
  },
  selectField: {
    color: "#000",
  },
  search: {
    color: "#8C8C8C",
    background: "#fff",
    fontSize: "14px",
    fontWeight: "500px",
    borderRadius: "20px",
    marginBottom: "16px",
    width: "100%",
    "& .MuiOutlinedInput-root": {
      borderRadius: "20px",
    },
  },
  upload: {
    display: "none",
  },
  message: {
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "center",
    margin: "auto",
  },
  label: {
    fontSize: "16px",
    marginBottom: "10px",
    fontWeight: "bold",
  },
}));

const MyOnboardingTab = (props) => {
  const { allPatients, loading } = props;
  const { history } = props?.props;
  const classes = useStyles();
  const [patients, setPatients] = useState([]);
  const [search, setSearch] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    props.GetAllPatientsAction();
  }, []);

  useEffect(() => {
    if (allPatients) {
      setPatients(allPatients);
    }
  }, [allPatients]);

  useEffect(() => {
    if (patients.length <= 0) {
      setMessage("No results found");
    } else {
      setMessage("");
    }
  }, [patients]);

  const handleChange = (value) => {
    setSearch(value);
    const updatedPatients = allPatients.filter(
      (patients) =>
        (patients?.firstName &&
          patients?.firstName.toLowerCase().includes(value?.toLowerCase())) ||
        (patients?.lastName &&
          patients?.lastName.toLowerCase().includes(value?.toLowerCase())) ||
        (patients?.firstName &&
          patients?.lastName &&
          (
            patients?.firstName.toLowerCase() +
            " " +
            patients?.lastName.toLowerCase()
          ).includes(value.toLowerCase()))
    );
    setPatients(updatedPatients);
  };

  return (
    <>
      {loading && <Loader open={true} loaderMessage="Fetching..." />}
      <div className={classes.addPatientButtonContainer}>
        <Typography className={classes.label}>
          {lang.yourOnboardingListSearchLable}
        </Typography>
        <Formik initialValues={{ search: "" }}>
          {() => {
            return (
              <Form>
                <FormTextInput
                  className={classes.search}
                  name={"search"}
                  placeholder="Patient Name"
                  autoComplete="off"
                  typeOf="auth"
                  value={search}
                  onChange={(event) => handleChange(event.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <IconButton>
                          {search === "" ? (
                            <SearchIcon />
                          ) : (
                            <CloseIcon
                              onClick={() => {
                                setSearch("");
                                setPatients(allPatients);
                              }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Form>
            );
          }}
        </Formik>
      </div>

      {patients.map((patient) => (
        <ProfileList
          {...props.props}
          key={patient.id}
          id={patient.id}
          patient_name={`${patient.firstName} ${patient.lastName}`}
          patient_contact={patient.countryCode.concat(patient.mobileNumber)}
        />
      ))}
      {message !== "" && (
        <Typography className={classes.message}>{message}</Typography>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    loading: state.homeReducers.loading,
    allPatients:
      state.homeReducers.allPatients !== null &&
      state.homeReducers.allPatients.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        GetAllPatientsAction,
        PostUploadAppointmentsAction,
      },
      dispatch
    ),
  };
}

export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(MyOnboardingTab)
);
