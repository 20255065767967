import React from 'react';
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import 'react-quill/dist/quill.snow.css'

const RichTextEditor = ({ note, handleChangeNote, handleSaveNote }) => {

  return (
    <div style={{ background: "white" }}>
      <EditorToolbar
        handleSaveNote={handleSaveNote} />
      <ReactQuill
        value={note}
        modules={modules}
        formats={formats}
        onChange={handleChangeNote} />
    </div>
  )
}

export default RichTextEditor;
