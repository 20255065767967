/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import MyAppBar from "@components/organisms/AppBar";
import PatientDeliveryDeatils from "./PatientDeliveryDeatils";
import DeliveryInformationForm from "./DeliveryInformationForm";
import api from "utils/api";
import { END_POINT } from "network/constants";
import { API_METHODS } from "network/constants";
import { apiResponses } from "network/constants";
import { notyf } from "utils/utility";
import Loader from "@components/atoms/Loader";
import { useParams } from "react-router-dom";
import { updateDeliveryDetailsForm } from "pages/OpsAdminDashboard/constants";

const DeliveryDetails = (props) => {
  const [deliveryDetails, setDeliveryDetails] = useState({
    deliveryData: null,
    deliveryDropDownsData: null,
  });

  const { id } = useParams();

  const [loading, setLoading] = useState(false);

  //api to fetch delivery data
  const fetchDeliveryData = async () => {
    setLoading(true);
    try {
      const data = await api(END_POINT.getDeliveryDetailsById(id), {
        method: API_METHODS.get,
      });
      setLoading(false);
      if (data?.data?.status === apiResponses.success) {
        setDeliveryDetails((prev) => {
          return { ...prev, deliveryData: data?.data?.data };
        });
      } else {
        notyf.error("failed to daily Pattern data");
      }
    } catch (e) {
      setLoading(false);
      notyf.error("failed to daily pattern data");
    }
  };

  const fetchDropDownData = async () => {
    try {
      setLoading(true);
      const data = await api(
        END_POINT.getDropDownOptions(updateDeliveryDetailsForm),
        {
          method: API_METHODS.get,
        }
      );
      setLoading(false);
      if (data?.data?.status === apiResponses.success) {
        setDeliveryDetails((prev) => {
          return { ...prev, deliveryDropDownsData: data?.data?.data };
        });
      } else {
        notyf.error("failed to daily Pattern data");
      }
    } catch (e) {
      setLoading(false);
      notyf.error("failed to daily pattern data");
    }
  };

  useEffect(() => {
    fetchDeliveryData();
    fetchDropDownData();
  }, []);

  return (
    <>
      {loading && <Loader open={true} loaderMessage="Fetching..." />}
      <MyAppBar />
      <div>
        <PatientDeliveryDeatils
          deliveryData={deliveryDetails?.deliveryData}
          deliveryId={Number(id)}
        />
        <DeliveryInformationForm
          deliveryData={deliveryDetails?.deliveryData}
          deliveryDetailsDropDowns={deliveryDetails?.deliveryDropDownsData}
          deliveryId={Number(id)}
        />
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    loading: state.allPatientsReducers.loading,
  };
}

export default connect(mapStateToProps)(withStyles()(DeliveryDetails));
