export const deliveryStatusTabs = {
  all: 0,
  orderReceived: 2,
  orderConfirmed: 3,
  packed: 4,
  dispatched: 5,
  delayed: 6,
  priority: 1,
  cancelled: 7,
};

export const deliveryStatus = {
  dispatched: "Dispatched",
  delayed: "Delayed",
  confirmed: "Order Confirmed",
  received: "Order Received",
  packed: "Packed",
  delivered: "Delivered",
  cancelled: "Order Cancelled",
};

export const deliveryStatusTabsBYNumber = {
  0: "",
  2: deliveryStatus.received,
  3: deliveryStatus.confirmed,
  4: deliveryStatus.packed,
  5: deliveryStatus.dispatched,
  6: deliveryStatus.delayed,
  7: deliveryStatus.cancelled,
};

export const deliveryStatusOrder = {
  [deliveryStatus.received]: 1,
  [deliveryStatus.confirmed]: 2,
  [deliveryStatus.packed]: 3,
  [deliveryStatus.dispatched]: 4,
  [deliveryStatus.delayed]: 5,
  [deliveryStatus.delivered]: 6,
  [deliveryStatus.cancelled]: 7,
};

export const deliveryFormFields = {
  deliveryMode: "deliveryMode",
  ETA: "ETA",
  deliveryVendor: "deliveryVendor",
  trackingId: "trackingId",
  status: "status",
  remarks: "remarks",
};

export const columnHeading = [
  "",
  "Delivery type & date",
  "Customer Details",
  "Order Confirmed By",
  "Status",
  "Pro. & Qty",
  "Pincode",
  "Date & Time of submission",
  "WHO and POD No.",
];

export const updateDeliveryDetailsForm = "updateDeliveryDetails";

export const deliveryListLimit = 6;

export const deliveryTypePriority = "Priority";
