import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  title: {
    fontSize: "24px",
    fontWeight: "500",
    marginBottom: "24px",
  },
  subTitle: {
    fontSize: "16px",
    color: "#8C8C8C",
    fontWeight: "700",
    marginBottom: "16px",
  },
  card: {
    boxShadow: 1,
    padding: "16px",
    borderRadius: "8px",
    backgroundColor: "#FFFFFF",
    border: "1px solid #D9D9D9",
    marginBottom: "16px",
  },
  label: {
    fontSize: "16px",
    fontWeight: "normal",
    height: "19px",
    marginBottom: "12px",
  },
  required: {
    color: "#FF5522",
    fontWeight: "bold",
  },
  input: {
    width: "100%",
    border: "none",
    borderBottom: "1px solid #BFBFBF",
  },
  error: {
    border: "1px solid #FF5522",
  },
  errors: {
    marginBottom: "24px",
    borderRadius: "8px",
    border: "1px solid #FF5522",
    backgroundColor: "#FFFFFF",
    color: "#FF5522",
    paddingTop: "16px",
  },
  ul: {
    marginTop: "8px",
  },
  remarks: {
    width: "99%",
    border: "1px solid #D9D9D9",
  },
  addonBox: {
    paddingTop: "16px",
    backgroundColor: "#FFFFFF",
    paddingLeft: "16px",
    fontSize: "16px",
    width: "auto",
    color: "rgba(0, 0, 0, 0.54)",
    marginBottom: "16px",
    border: "1px solid #D9D9D9",
  },
  addon: {
    width: "400px",
  },
  submitButton: {
    marginBottom: "20px",
  },
  dialogBoxContainer: {
    width: "100%",
  },
  dialogBoxHeading: {
    padding: "20px",
    display: "flex",
    justifyContent: "center",
  },
  dialogBoxText: {
    fontSize: "16px",
    fontWeight: 500,
  },
  addonList: {
    padding: "0px 20px 20px",
  },
  yesCancelContainer: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px 20px",
  },
  box1: {
    fontSize: "16px",
    padding: "5px",
  },
  box: {
    fontSize: "16px",
    fontWeight: 500,
    border: "1px solid #D9D9D9",
    padding: "5px",
  },
  errorText: {
    color: "#FF5522",
  },
  addOnContainer: { display: "flex", height: "45px" },
  selectAddOnComponent: { width: "80%" },
  quantityContainer: {
    border: "1px solid #c3c3c3",
    borderLeft: "0px",
    width: "20%",
    fontSize: "14px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
  },
  quantityHeading: {
    border: "1px solid #c3c3c3",
    borderLeft: "0px",
    width: "20%",
    fontSize: "14px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
  },
  selectedAddOnTable: {
    width: "80%",
    height: "45px",
    border: "1px solid #c3c3c3",
    borderTop: "0px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  selectedAddOnName: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: "16px",
    paddingRight: "16px",
  },
  crossIcon: {
    width: 15,
    height: 15,
    marginRight: "20px",
  },
  addonIncrement: {
    display: "flex",
    flexDirection: "column",
    marginRight: "10px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  quantityPixel: { fontSize: "16px" },
  addonName: {
    display: "flex",
    justifyContent: "space-between",
  },
  labelHeading: {
    fontSize: "16px",
    fontWeight: "normal",
    marginBottom: "12px",
    height: "19px",
  },
  flex: {
    display: "flex",
  },
  finalAmount: {
    fontWeight: 500,
    fontSize: "16px",
    padding: "5px",
  },
  titleHeading: { margin: "0px 16px" },
  targetHba1c: { marginTop: "20px" },
  paymentStatus: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  paymentStatusTitle: { display: "flex", width: "40%" },
  startDateContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  startDate: { display: "flex", width: "40%" },
  calenderImage: {
    width: "20px",
    height: "20px",
    marginLeft: "-25px",
  },
  remarksContainer: {
    overflow: "scroll",
    maxHeight: "250px",
    marginTop: "10px",
  },
  remarksRow: {
    marginBottom: "8px",
    padding: "10px",
    border: "1px solid #D9D9D9",
  },
  phoneInputStyle: { width: "100%" },
  cardOrFlex: {
    boxShadow: 1,
    padding: "16px",
    borderRadius: "8px",
    backgroundColor: "#FFFFFF",
    border: "1px solid #D9D9D9",
    marginBottom: "16px",
    display: "flex",
  },
  dialogBoxButton: {
    marginRight: "30px",
  },
  dialogBoxContent: { maxHeight: "350px", overflow: "scroll" },
  addressBtn: {
    marginLeft: 30,
  },
  mainDialogBoxContainer: {
    width: "100%",
  },
  sectionsContainer: { width: "100%" },
  subContainer: { marginBottom: "15px" },
  inputLabel: { marginLeft: "5px" },
  bottomLabel: { padding: "5px" },
  accordinError: {
    color: "#FF5522",
    padding: "5px",
  },
  etaTable: {
    width: "100%",
    justifyContent: "center",
    textAlign: "center",
    borderCollapse: "collapse",
  },
  etaTableData: {
    textAlign: "center",
    border: "1px solid black",
    padding: "10px",
    height: "40px",
    width: "30%",
  },
  deliveryModeContainer: {
    height: "50px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    backgroundColor: "white",
    borderRadius: "0px 8px 8px 0px",
    flexWrap: "wrap",
  },
  etaContainer: {
    display: "flex",
    paddingTop: "5px",
    marginTop: "10px",
  },
  etaLabel: {
    marginRight: "4px",
  },
  deliveryModeCardHeading: {
    marginBottom: "10px",
  },
}));
