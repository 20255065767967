const reportListStyle = (theme) => ({
  topContainer: {
    maxHeight: "430px",
    overflow: "auto",
  },
  container: {
    // display: "grid",
    display: "flex",
    justifyContent: "space-between",
    padding: "16px",
    alignItems: "center",
    // gridTemplateColumns: "180px auto 130px",
  },
  text: {
    fontSize: "14px",
    fontWeight: 500,
  },
  subText: {
    fontSize: "14px",
    color: "#595959",
  },
  viewText: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#2B377E",
    cursor: "pointer",
    textDecoration: "none",
    marginLeft: "1rem",
  },
  updateText: {
    fontWeight: 500,
    padding: "8px 12px",
    fontSize: "14px",
    cursor: "pointer",
    backgroundColor: "#fff",
  },
  title: {
    flex: 0.35,
  },
  viewUpdateText: {
    display: "flex",
    marginLeft: "0.3rem",
    justifyContent: "end"
  },
  viewUpdateContainer: {
    fontSize: "14px",
  },
});

export default reportListStyle;
