import React from "react";
import Markdown from "markdown-to-jsx";
import moment from "moment";
import { Box, Typography, withStyles } from "@material-ui/core";
import blogStyle from "../../../../assets/jss/pages/components/blogStyle";
import CloseIcon from "@material-ui/icons/Close";
import Back from "../../../../assets/icons/back-arrow.svg";
import Avatar from "../../../../assets/images/avatar.png";

const BlogDrawer = ({
  classes,
  handleCloseDrawer,
  handleDetails,
  openBlog,
}) => {
  return (
    <>
      <div className={classes.headerContainer}>
        <Box className={classes.container}>
          <img
            className={classes.icon}
            type="button"
            onClick={handleDetails}
            src={Back}
          />
          <Typography style={{ fontSize: "16px", fontWeight: 500 }}>
            Details
          </Typography>
        </Box>

        <CloseIcon className={classes.icon} onClick={handleCloseDrawer} />
      </div>
      <Box className={classes.wrapper}>
        <img src={openBlog?.smallImageURL} alt="" width="100%" height="200px" />
        <Typography className={classes.text}>{openBlog?.title}</Typography>
        <Box className={classes.avatarWrapper}>
          <Box className={classes.avatarContainer}>
            <img src={Avatar} alt="" />
            <Typography className={classes.author}>
              {openBlog?.authorName}
            </Typography>
          </Box>
          <Typography className={classes.date}>
            {openBlog?.date
              ? moment(new Date(openBlog?.date)).format("DD/MM/YYYY")
              : ""}
          </Typography>
        </Box>
        <Typography className={classes.para}>
          <Markdown
            options={{
              overrides: {
                img: {
                  props: {
                    height: 200,
                    width: "100%",
                  },
                },
              },
            }}
          >
            {openBlog?.content}
          </Markdown>
        </Typography>
      </Box>
    </>
  );
};
export default withStyles(blogStyle)(BlogDrawer);
