import React, { useEffect, useState } from "react";
import { Divider, Typography, withStyles } from "@material-ui/core";
import lang from "assets/languages";
import healthProfileStyle from "assets/jss/pages/healthProfileStyle";
import Loader from "@components/atoms/Loader";
import api from "utils/api";
import { END_POINT } from "network/constants";
import { API_METHODS } from "network/constants";
import { notyf } from "utils/utility";
import { apiResponses } from "network/constants";

const HealthProfile = ({ patientId, ...props }) => {
  const { classes } = props;
  const [healthProfileData, setHealthProfileData] = useState({});
  const [loading, setLoading] = useState(false);

  const getHealthProfileFieldValues = (array) => {
    if (!array?.length) {
      return "";
    }
    let newArray = array.map((item) => {
      return item?.name;
    });
    return newArray.join(", ");
  };

  useEffect(() => {
    if (!patientId) {
      return;
    }
    const fetchHealthProfile = async (id) => {
      setLoading(true);
      try {
        const { data } = await api(END_POINT.getPatientHealthProfile(id), {
          method: API_METHODS.GET,
        });
        if (data?.status === apiResponses.success) {
          let responseData = data?.data;
          let formattedData = {
            goals: getHealthProfileFieldValues(responseData?.goals),
            medicalConditions: getHealthProfileFieldValues(
              responseData?.medicalConditions
            ),
            medicineForms: getHealthProfileFieldValues(
              responseData?.medicineForms
            ),
            mealPreferences: getHealthProfileFieldValues(
              responseData?.mealPreferences
            ),
            prescriptions: responseData?.reports,
            alcoholIntake: responseData?.alcoholIntake?.name,
            hba1c: responseData?.hba1c?.value,
            weight: responseData?.weight?.weight,
            height: responseData?.height,
          };
          setLoading(false);
          setHealthProfileData({ ...formattedData });
        } else {
          setLoading(false);
          notyf.error("Failed to fetch health Profile");
        }

        return data;
      } catch (e) {
        setLoading(false);
        notyf.error(e.message);
      }
    };
    fetchHealthProfile(patientId);
  }, []);

  let bmi = "--";
  if (healthProfileData?.height > 0 && Number(healthProfileData?.weight) > 0) {
    bmi = Math.floor(
      (healthProfileData?.weight * 100 * 100) /
        (healthProfileData?.height * healthProfileData?.height)
    );
    bmi = bmi || "--";
  }

  return (
    <>
      {loading && <Loader open={true} loaderMessage="Fetching..." />}
      <div className={classes.topContainer}>
        <div className={classes.container}>
          <Typography className={classes.text}>{lang.goals}</Typography>
          <Typography className={classes.sub_text}>
            {healthProfileData?.goals}
          </Typography>
        </div>
        <Divider />
        <div className={classes.container}>
          <Typography className={classes.text}>
            {`${lang.presentWeight} ( ${lang.kg} )`}
          </Typography>
          <Typography className={classes.sub_text}>
            {healthProfileData?.weight}
          </Typography>
        </div>
        <Divider />
        <div className={classes.container}>
          <Typography
            className={classes.text}
          >{`${lang.height} ( ${lang.cm} )`}</Typography>
          <Typography className={classes.sub_text}>
            {healthProfileData?.height}
          </Typography>
        </div>
        <Divider />
        <div className={classes.container}>
          <Typography
            className={classes.text}
          >{`${lang.bmi} ( kg/m2 )`}</Typography>
          <Typography className={classes.sub_text}>{bmi}</Typography>
        </div>
        <Divider />
        <div className={classes.container}>
          <Typography className={classes.text}>{lang.presentHBA1c}</Typography>
          <Typography className={classes.sub_text}>
            {healthProfileData?.hba1c}
          </Typography>
        </div>
        <Divider />
        <div className={classes.container}>
          <Typography className={classes.text}>
            {lang.medicalCondition}
          </Typography>
          <Typography className={classes.sub_text}>
            {healthProfileData?.medicalConditions}
          </Typography>
        </div>
        <Divider />
        <div className={classes.container}>
          <Typography className={classes.text}>{lang.medicineForms}</Typography>
          <Typography className={classes.sub_text}>
            {healthProfileData?.medicineForms}
          </Typography>
        </div>
        <Divider />
        <div className={classes.container}>
          <Typography className={classes.text}>{lang.prescription}</Typography>
          <Typography className={classes.sub_text}>
            {healthProfileData?.prescriptions?.map((report) => {
              return (
                <>
                  <div className={classes.reportsContainer}>
                    <Typography className={classes.reportLabel}>
                      {report?.fileName}
                    </Typography>
                    <Typography>
                      <a
                        href={report?.fileURL}
                        target="_blank"
                        rel="noreferrer"
                        className={classes.viewButton}
                      >
                        {lang.view}
                      </a>
                    </Typography>
                  </div>
                </>
              );
            })}
          </Typography>
        </div>
        <Divider />
        <div className={classes.container}>
          <Typography className={classes.text}>
            {lang.mealPreference}
          </Typography>
          <Typography className={classes.sub_text}>
            {healthProfileData?.mealPreferences}
          </Typography>
        </div>
        <Divider />
        <div className={classes.container}>
          <Typography className={classes.text}>{lang.alcoholIntake}</Typography>
          <Typography className={classes.sub_text}>
            {healthProfileData?.alcoholIntake}
          </Typography>
        </div>
      </div>
    </>
  );
};

export default withStyles(healthProfileStyle)(HealthProfile);
