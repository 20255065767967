import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  errorModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "700px",
    maxHeight: "800px",
    overflow: "scroll",
    background: "#F7F8FC",
    boxShadow: 24,
    padding: "16px",
  },
  heading: {
    fontSize: "28px",
    fontWeight: 500,
    paddingBottom: "12px"
  },
  container: {
    overflowY: "scroll",
    maxHeight: "500px"
  },
  closeIconContainer: {
    textAlign: "right"
  },
  errorItemContainer: {
    marginBottom: "16px"
  },
  errorItemHeading: {
    fontSize: "24px",
    fontWeight: 400,
    padding: "12px 0px 8px 0px"
  },
  errorItemText: {
    fontSize: "16px",
    color: "#FF0000"
  },
  errorListContainer: {
    border: "1px solid #BFBFBF",
    padding: "12px",
    marginRight: "12px"
  },
  closeIconStyles: {
    color: "#BFBFBF",
    height: "30px",
    width: "30px",
    cursor: "pointer"
  },
  footer: {
    paddingTop: "20px",
    textAlign: "right"
  }
}));

export default useStyles;