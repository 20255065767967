/**
 @desc
 * Centralized unique action types for Login actions and reducers.
 */
import { PROD } from "utils/constants";

export const adminTabIndexes = {
  sensorDeactivation: 0,
  discountUpdate: 1,
};

export const discountTableColumns = {
  id: "id",
  amount: "amount",
  delete: "delete",
};
export const addOnProductNames = {
  libreOneSelfReading: "Libre 1 - Self reading",
  libreProSelfReading: "Libre Pro - Self reading",
  dietEssentialsStandard: "Diet essentials - Standard",
  dietEssentialsPhase1: "Diet essentials - Phase1",
  dietEssentialsPhase2: "Diet essentials - Phase2",
  dietEssentialsPhase3: "Diet essentials - Phase3",
};

export const prodAddonIds = {
  [addOnProductNames.libreProSelfReading]: 7,
  [addOnProductNames.libreOneSelfReading]: 6,
  [addOnProductNames.dietEssentialsStandard]: 15,
  [addOnProductNames.dietEssentialsPhase1]: 16,
  [addOnProductNames.dietEssentialsPhase2]: 17,
  [addOnProductNames.dietEssentialsPhase3]: 18,
};
export const devAddonIds = {
  [addOnProductNames.libreProSelfReading]: 6,
  [addOnProductNames.libreOneSelfReading]: 7,
  [addOnProductNames.dietEssentialsStandard]: 8,
  [addOnProductNames.dietEssentialsPhase1]: 9,
  [addOnProductNames.dietEssentialsPhase2]: 10,
  [addOnProductNames.dietEssentialsPhase3]: 11,
};

export const deliveryRequiredAddonIds = Object.values(
  PROD ? prodAddonIds : devAddonIds
);

export const addOnProductIds = {
  [addOnProductNames.libreOneSelfReading]: PROD
    ? prodAddonIds[addOnProductNames.libreOneSelfReading]
    : devAddonIds[addOnProductNames.libreOneSelfReading],
  [addOnProductNames.libreProSelfReading]: PROD
    ? prodAddonIds[addOnProductNames.libreProSelfReading]
    : devAddonIds[addOnProductNames.libreProSelfReading],
};

export const metaBaseSensorDetailsLink =
  "https://metabase.onecare.co.in/question#eyJkYXRhc2V0X3F1ZXJ5Ijp7ImRhdGFiYXNlIjo0LCJxdWVyeSI6eyJzb3VyY2UtdGFibGUiOjg2fSwidHlwZSI6InF1ZXJ5In0sImRpc3BsYXkiOiJ0YWJsZSIsInZpc3VhbGl6YXRpb25fc2V0dGluZ3MiOnt9fQ==";
