import React from "react";
import { Box, Typography } from "@material-ui/core";

const ActivityComponent = ({ classes, text, number, bg, borderBottom }) => {
  return (
    <>
      <Box className={classes.activityWrapper}>
        <Box className={classes.activityContainer}>
          <Box className={classes.dot} style={{ backgroundColor: bg }} />
          <Typography className={classes.activitySubText}>{text}</Typography>
        </Box>
        <Typography className={classes.activityNumberText}>{number}</Typography>
      </Box>

      {borderBottom && <Box className={classes.borderBottom2} />}
    </>
  );
};
export default ActivityComponent;
