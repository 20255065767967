import React from "react";
import { Typography, withStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import taskDetailsDrawerStyle from "../../../../assets/jss/pages/components/taskDetailsDrawerStyle";
import moment from "moment";

import exercise_icon from "../../../../assets/icons/exercise.svg";
import glucometer_icon from "../../../../assets/icons/glucometer.svg";
import weight_icon from "../../../../assets/icons/bmi.svg";
import sleep_icon from "../../../../assets/icons/sleep.svg";
import walking_icon from "../../../../assets/icons/walking.svg";
import diet_icon from "../../../../assets/icons/diet.svg";
import hba1c_icon from "../../../../assets/icons/hbA1c.svg";
import Button from "@components/atoms/Button";

const TASK_TYPES = {
  nodataentry: { name: "No Data Entry" },
  bloodsugar: {
    name: "Blood Sugar",
    icon: glucometer_icon,
    extraValues: [
      {
        label: "Measurement Type",
        value: "measurementType",
        options: {
          fasting: "Fasting",
          premeal: "PreMeal",
          postmeal: "PostMeal",
          random: "Random",
        },
      },
      {
        label: "Value (mg/dL)",
        value: "value",
      },
    ],
  },
  hba1c: {
    name: "Hba1c",
    icon: hba1c_icon,
    extraValues: [{ label: "Value (in %)", value: "value" }],
  },
  physical: {
    name: "Physical Activity",
    icon: exercise_icon,
    extraValues: [
      {
        value: "activityType",
        label: "Activity Type",
        options: {
          good: "Good",
          bad: "Bad",
          average: "Average",
        },
      },
      { value: "duration", label: "Activity Duration" },
      {
        value: "activityIntensity",
        label: "Activity Intensity",
        options: {
          high: "High",
          medium: "Medium",
          low: "Low",
        },
      },
      { value: "caloriesBurnt", label: "Calories Burnt" },
    ],
  },
  sleep: {
    name: "Sleep",
    icon: sleep_icon,
    extraValues: [
      { label: "Sleeping hours", value: "sleepingHours" },
      {
        label: "Sleeping quality",
        value: "sleepingQuality",
        options: {
          good: "Good",
          poor: "Poor",
          average: "Average",
        },
      },
    ],
  },
  steps: {
    name: "Steps",
    icon: walking_icon,
    extraValues: [{ label: "No. of steps", value: "noOfSteps" }],
  },
  weight: {
    name: "Weight",
    icon: weight_icon,
    extraValues: [{ label: "Weight (in Kg)", value: "weight" }],
  },
  diet: {
    name: "Diet",
    icon: diet_icon,
    extraValues: [
      { value: "dietType", label: "Diet Type" },
      { value: "carbs", label: "Carbs Servings" },
      { value: "fat", label: "Fiber Servings" },
      { value: "protein", label: "Protien Servings" },
    ],
  },
};

const TaskDetailsDrawer = ({
  handleViewWeekPlanDetails,
  handleCloseDrawer,
  handleDetails,
  ...props
}) => {
  const { classes, taskDetailsByDataEntry, taskType, type, metric } = props;

  return (
    <>
      <div className={classes.headerContainer}>
        <Typography
          className={classes.headerText}
          onClick={type === "graph" ? handleDetails : handleViewWeekPlanDetails}
        >
          {type === "graph" ? "Back to graph" : "Back to tasks"}
        </Typography>
        <CloseIcon className={classes.icon} onClick={handleCloseDrawer} />
      </div>
      <div className={classes.imgContainer}>
        <img
          src={TASK_TYPES[taskType === "step" ? "steps" : taskType].icon}
          alt="salad"
          height="100px"
          width="100px"
        />
        <Typography className={classes.text}>
          {TASK_TYPES[taskType === "step" ? "steps" : taskType].name}
        </Typography>
      </div>
      {type === "task" ? null : (
        <div className={classes.container}>
          <Typography className={classes.sub_text}>
            {taskDetailsByDataEntry.date ? "Date" : null}
          </Typography>
          <Typography className={classes.sub_text}>
            {taskDetailsByDataEntry.date}
          </Typography>
        </div>
      )}
      {type === "task" ? null : (
        <div className={classes.container}>
          <Typography className={classes.sub_text}>
            {taskDetailsByDataEntry.time
              ? "Time"
              : taskDetailsByDataEntry.date
              ? "Date"
              : "Week"}
          </Typography>
          <Typography className={classes.sub_text}>
            {taskDetailsByDataEntry.time ||
              taskDetailsByDataEntry.date ||
              taskDetailsByDataEntry.week}
          </Typography>
        </div>
      )}

      {type === "graph" &&
      (metric === "fasting" ||
        metric === "Random" ||
        metric === "Pre Meal" ||
        metric === "Post Meal" ||
        taskType === "diet" ||
        taskType === "step" ||
        taskType === "physical" ||
        taskType === "hba1c" ||
        taskType === "sleep" ||
        taskType === "weight") ? null : (
        <div className={classes.container}>
          <Typography className={classes.sub_text}>Time</Typography>
          <Typography className={classes.sub_text}>
            {taskDetailsByDataEntry
              ? moment(
                  taskDetailsByDataEntry.date +
                    "T" +
                    taskDetailsByDataEntry.time
                ).format("hh:mm A")
              : "N/A"}
          </Typography>
        </div>
      )}
      {taskDetailsByDataEntry &&
        type === "graph" &&
        (metric === "fasting" ||
          metric === "Random" ||
          metric === "Pre Meal" ||
          metric === "Post Meal") && (
          <>
            <div className={classes.container}>
              <Typography className={classes.sub_text}>
                Measurement type
              </Typography>
              <Typography className={classes.sub_text}>{metric}</Typography>
            </div>

            <div className={classes.container}>
              <Typography className={classes.sub_text}>
                Value (mg/dL)
              </Typography>
              <Typography className={classes.sub_text}>
                {taskDetailsByDataEntry.value}
              </Typography>
            </div>
          </>
        )}

      {taskType === "diet" ? (
        <>
          <div className={classes.container}>
            <Typography className={classes.sub_text}>Meal Type</Typography>
            <Typography className={classes.sub_text}>
              {taskDetailsByDataEntry.dietType}
            </Typography>
          </div>
          <div className={classes.container}>
            <Typography className={classes.sub_text}>Protein</Typography>
            <Typography className={classes.sub_text}>
              {taskDetailsByDataEntry.protein}
            </Typography>
          </div>
          <div className={classes.container}>
            <Typography className={classes.sub_text}>Carbs</Typography>
            <Typography className={classes.sub_text}>
              {taskDetailsByDataEntry.carbs}
            </Typography>
          </div>
          <div className={classes.container}>
            <Typography className={classes.sub_text}>Fats</Typography>
            <Typography className={classes.sub_text}>
              {taskDetailsByDataEntry.fat}
            </Typography>
          </div>
        </>
      ) : null}

      {taskType === "step" ? (
        <>
          <div className={classes.container}>
            <Typography className={classes.sub_text}>Steps</Typography>
            <Typography className={classes.sub_text}>
              {taskDetailsByDataEntry.total ||
                taskDetailsByDataEntry.avg ||
                taskDetailsByDataEntry.noOfSteps}
            </Typography>
          </div>
        </>
      ) : null}

      {taskType === "physical" ? (
        <>
          <div className={classes.container}>
            <Typography className={classes.sub_text}>Calories Burnt</Typography>
            <Typography className={classes.sub_text}>
              {taskDetailsByDataEntry.caloriesBurnt}
            </Typography>
          </div>
          <div className={classes.container}>
            <Typography className={classes.sub_text}>Intensity</Typography>
            <Typography className={classes.sub_text}>
              {taskDetailsByDataEntry.activityIntensity}{" "}
            </Typography>
          </div>
          <div className={classes.container}>
            <Typography className={classes.sub_text}>Type</Typography>
            <Typography className={classes.sub_text}>
              {taskDetailsByDataEntry.activityType}{" "}
            </Typography>
          </div>
          <div className={classes.container}>
            <Typography className={classes.sub_text}>Duration(Mins)</Typography>
            <Typography className={classes.sub_text}>
              {taskDetailsByDataEntry.duration}{" "}
            </Typography>
          </div>
        </>
      ) : null}

      {type === "graph" && taskType === "hba1c" ? (
        <>
          <div className={classes.container}>
            <Typography className={classes.sub_text}>HBA1C</Typography>
            <Typography className={classes.sub_text}>
              {taskDetailsByDataEntry.value} %
            </Typography>
          </div>
        </>
      ) : null}

      {type === "graph" && taskType === "sleep" ? (
        <>
          <div className={classes.container}>
            <Typography className={classes.sub_text}>Sleep</Typography>
            <Typography className={classes.sub_text}>
              {taskDetailsByDataEntry.sleepingHours} hrs
            </Typography>
          </div>

          <div className={classes.container}>
            <Typography className={classes.sub_text}>Sleep Quality</Typography>
            <Typography className={classes.sub_text}>
              {taskDetailsByDataEntry.sleepingQuality}
            </Typography>
          </div>
        </>
      ) : null}

      {taskType === "weight" ? (
        <>
          <div className={classes.container}>
            <Typography className={classes.sub_text}>Weight</Typography>
            <Typography className={classes.sub_text}>
              {taskDetailsByDataEntry.avg || taskDetailsByDataEntry.weight} kg
            </Typography>
          </div>
        </>
      ) : null}
      <Button
        color="primary"
        onClick={() =>
          props.openDrawer(
            `update_${taskType === "step" ? "steps" : taskType}`,
            "",
            `${
              taskType !== "bloodsugar"
                ? [
                    taskType === "physical"
                      ? "Physical"
                      : [
                          taskType === "hba1c"
                            ? "Hb1ac"
                            : [taskType === "sleep" ? "Sleep" : taskType],
                        ],
                  ]
                : metric === "fasting"
                ? "bp"
                : metric === "Random"
                ? "RBP"
                : "PostBP"
            }_progress`,
            "",
            taskDetailsByDataEntry
          )
        }
      >
        Edit
      </Button>

      {taskDetailsByDataEntry &&
        type === "task" &&
        TASK_TYPES[taskType]?.extraValues?.map((extra, index) => (
          <div className={classes.container} key={index}>
            <Typography className={classes.sub_text}>{extra.label}</Typography>
            <Typography className={classes.sub_text}>
              {extra.options
                ? extra.options[taskDetailsByDataEntry[extra.value]]
                : taskDetailsByDataEntry[extra.value]}
            </Typography>
          </div>
        ))}
      <div className={classes.bottomContainer}>
        <Typography className={classes.sub_text}>Photo</Typography>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {taskDetailsByDataEntry?.photo
            ? taskDetailsByDataEntry?.photo?.map((item, index) => (
                <div className={classes.emptyContainer}>
                  {
                    <a
                      style={{ textDecoration: "none" }}
                      href={item}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={item}
                        width="100%"
                        height="100%"
                        alt={
                          taskDetailsByDataEntry.measurementType +
                          " " +
                          (index + 1)
                        }
                      />
                    </a>
                  }
                </div>
              ))
            : ""}
        </div>
      </div>
    </>
  );
};

export default withStyles(taskDetailsDrawerStyle)(TaskDetailsDrawer);
