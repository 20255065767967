import React, { useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core";
import { FileCard } from "@components/molecules/FileCard";
import reportListStyle from "assets/jss/pages/reportListStyle";
import { notyf } from "utils/utility";
import lang from "assets/languages";

const ReportsList = ({ reports, onFileUpdate, patientId, ...props }) => {
  const { classes, scrollToBottom } = props;

  const listRef = useRef(null);

  useEffect(() => {
    // Scroll to Bottom, if many reports have been uploaded.
    if (scrollToBottom) {
      if (listRef.current) {
        listRef.current.scroll({
          top: listRef.current.scrollHeight,
          behavior: "smooth",
        });
      }
    }
  }, []);

  const onChange = (event, key, report) => {
    const files = event?.target?.files;
    const hasFiles = files && files.length > 0 ? files[0] : null;
    if (!hasFiles) {
      notyf.error(lang.pleaseSelectAFileToUpload);
      return;
    }
    onFileUpdate(hasFiles, key, report?.id);
  };

  return (
    <>
      <div className={classes.topContainer} ref={listRef}>
        {reports &&
          reports?.length > 0 &&
          reports?.map((report, index) => {
            return (
              <FileCard
                report={report}
                id={report.id}
                key={index}
                index={index}
                patientId={patientId}
                onChange={(event, index) => {
                  onChange(event, index, report);
                }}
                listOfReports={reports}
                classes={classes}
                showReportType={false}
              />
            );
          })}
      </div>
    </>
  );
};

export default withStyles(reportListStyle)(ReportsList);
