const accordionStyle = theme => ({
  root: {
    width: '100%',
    "& .MuiAccordion-root": {
      borderRadius: "0px",
      boxShadow: "none",
      borderTop: "1px solid #ccc",
      borderBottom: "1px solid #ccc",
      "&.Mui-expanded": {
        margin: 0
      }
    }
  },
  title: {
    fontSize: "16px",
    fontWeight: 500,
  },
  content:{
    fontSize: "12px"
  },
  container:{
    display: "flex", 
    alignItems: "center", 
    borderBottom: "1px solid #ccc", 
    padding: "16px"
  },
  subContainer:{
    display: "flex", 
    alignItems: "center", 
    marginLeft: "1.5rem"
  },
  text:{
    fontSize: "14px", 
    color: "#5BABCC", 
    cursor: "pointer"
  },
  sub_text:{
    fontSize: "14px", 
    marginLeft: "5px", 
    color: "#389E0D"
  },
  sub_text1:{
    fontSize: "14px", 
    marginLeft: "5px", 
    color: "#FF5522"
  },
  icon:{
    color: "#FF5522"
  }
});

export default accordionStyle;