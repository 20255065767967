import { Typography, withStyles } from "@material-ui/core";
import lang from "assets/languages";
import { CheckBoxList } from "@components/molecules/checkBoxList";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomReportTable } from "@components/molecules/CustomReportTable";
import { updateReportSummary } from "../../actions/CreateReportActions";
import {
  getCheckboxOptions,
  getSummaryTableData,
  healthHabitsKey,
} from "./helpers";
import sumaryTabStyle from "./styles";

const SummaryTab = ({ classes }) => {
  const {
    createReportReducer: { summary },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const onTableCellChange = ({ rowKey, rowCellKey, rowCellValue }) => {
    let updatedSummary = { ...summary };

    if (!rowKey) {
      return;
    }

    updatedSummary = {
      ...updatedSummary,
      [rowKey]: { ...updatedSummary[rowKey], [rowCellKey]: rowCellValue },
    };

    dispatch(updateReportSummary(updatedSummary));
  };

  const onCheckboxes = (checkBoxState) => {
    let updatedSummary = { ...summary };

    updatedSummary = {
      ...updatedSummary,
      [healthHabitsKey]: checkBoxState,
    };

    dispatch(updateReportSummary(updatedSummary));
  };

  const summaryTableRowData = useMemo(
    () => getSummaryTableData(summary),
    [summary]
  );

  return (
    <div className={classes.container}>
      <Typography className={classes.tableHeading}>
        {lang.executiveSummary}
      </Typography>
      <CustomReportTable
        onTableCellChange={onTableCellChange}
        tableColumnHeadings={[
          "Metric",
          "Value",
          "Insight",
          "Progress",
          "Advice",
        ]}
        rowsData={summaryTableRowData}
      />
      <Typography className={classes.checkboxHeading}>
        {lang.healthyHabitRoadmap}
      </Typography>
      <CheckBoxList
        type="single"
        onCheckBoxChange={onCheckboxes}
        checkboxOptions={getCheckboxOptions(summary)}
        helperText="Please check atleast one option from above"
      />
    </div>
  );
};

const summaryTab = React.memo(withStyles(sumaryTabStyle)(SummaryTab));
export { summaryTab as SummaryTab };
