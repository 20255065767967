import {
  dayParts,
  insightRowIndex,
  interventionRowIndex,
  rowNames,
  dailyPatternTypes,
  reportType,
  insightRowHeadingObj,
  interventionRowHeadingObj,
  reportTypesId,
  insightLength,
  interventionLength,
  initialReportData,
} from "./constants";
import { getMaxCharLength } from "./utils";

export const storePrefilledData = (data, fileType) => {
  const insight = data?.insight;
  const intervention = data?.intervention;
  const payload = {
    ...(insight && {
      insight: getDataFormat(insight, dailyPatternTypes.insight, insightLength),
    }),
    ...(intervention && {
      intervention: getDataFormat(
        intervention,
        dailyPatternTypes.intervention,
        interventionLength
      ),
    }),
    reportType: getReportType(fileType),
  };
  payload.maxCharLengthForInsight = getMaxCharLength(payload.insight);
  payload.maxCharLengthForIntervention = getMaxCharLength(payload.intervention);
  return payload;
};

const getDataFormat = (tableData, tableType, maxLength) => {
  const updatedData = [];

  for (const row in tableData) {
    for (const subRow in tableData[row]) {
      let rowIndex = updatedData.findIndex(
        (dayInfo) => dayInfo.rowName === subRow
      );
      if (rowIndex === -1) {
        rowIndex = updatedData.length;
        updatedData.push({ rowName: subRow });
      }

      updatedData[rowIndex][row] = {
        ...(tableData[row][subRow].status && {
          type: tableData[row][subRow].status,
        }),
        ...(tableData[row][subRow]?.isCustom && {
          customMessage: tableData[row][subRow].message,
        }),
        ...(tableData[row][subRow]?.message &&
          tableData[row][subRow]?.isCustom === false && {
            text: tableData[row][subRow]?.message,
          }),
        ...(tableData[row][subRow]?.messageId && {
          messageId: tableData[row][subRow].messageId,
        }),
        subType: subRow,
        dayPart: row,
        tableType: tableType,
      };
    }
  }

  for (const outputRow of updatedData) {
    for (const dayPart of dayParts) {
      if (!outputRow[dayPart]) {
        outputRow[dayPart] = null;
      }
    }
  }
  let finalData = [];
  updatedData.forEach((dayInfo) => {
    let dataIndex = getRowIndex(tableType, dayInfo.rowName);

    if (dataIndex !== undefined && dataIndex > -1) {
      finalData[dataIndex] = {
        ...dayInfo,
        rowName: rowNames[[dayInfo.rowName]],
      };
      finalData[dataIndex] = {
        ...dayInfo,
        rowName: rowNames[[dayInfo.rowName]],
      };
    }
  });
  for (let i = 0; i < maxLength; i++) {
    if (finalData[i]) {
      finalData[i] = getSortedReportData(finalData[i]);
    } else {
      finalData[i] =
        tableType === dailyPatternTypes.insight
          ? { ...initialReportData, rowName: rowNames[insightRowHeadingObj[i]] }
          : {
              ...initialReportData,
              rowName: rowNames[interventionRowHeadingObj[i]],
            };
    }
  }

  return finalData;
};

const getRowIndex = (type, rowName) => {
  if (type === dailyPatternTypes.insight) {
    return insightRowIndex[rowName];
  } else {
    return interventionRowIndex[rowName];
  }
};
const getReportType = (fileType) => {
  if (fileType === reportTypesId.fourteenthDayReport) {
    return reportType.fourteenthDayReport;
  } else {
    return reportType.seventhDayReportData;
  }
};

const getSortedReportData = (dayData) => {
  const initialReportDataKeys = Object.keys(initialReportData);
  let updatedDayData = {};
  initialReportDataKeys.forEach(function (key) {
    updatedDayData[key] = dayData[key];
  });
  return updatedDayData;
};
