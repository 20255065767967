import React from "react";
import Authentication from "@pages/Authentication";

const AuthLayout = (props) => {
  return (
    <>
      <Authentication />
    </>
  );
};

export default AuthLayout;
