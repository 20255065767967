const weekPlanStyle = theme => ({
  topContainer: {
    margin: "10px",
    border: "1px solid #ccc",
    maxHeight: "400px",
    overflow: "auto"
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    padding: "16px",
    alignItems: "center"
  },
  activeContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "16px",
    alignItems: "center",
    backgroundColor: "#E6F7FF"
  },
  text: {
    fontSize: "16px",
    fontWeight: 500,
    width: "30%"
  },
  sub_text: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#8C8C8C",
    width: "35%"
  },
  sub_text1: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#2B377E",
    cursor: "pointer",
    width: "15%"
  },
  sub_text2: {
    fontSize: "14px",
    fontWeight: 500,
    color: "red",
    cursor: "pointer",
    width: "10%"
  }
})

export default weekPlanStyle;