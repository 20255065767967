import moment from "moment";
import { metricIds } from "pages/Patient/components/CgmProgressDrawer/constant";

const getChartFormattedData = (metricData, maxValue) => {
  let updatedMetricData = { ...metricData };

  const isMedianData = metricData.id === metricIds.median;

  const updatedData = updatedMetricData.data.map((data) => {
    if (isMedianData) {
      maxValue.value = Math.max(
        maxValue.value,
        data.bloodGlucose ? parseInt(data.bloodGlucose) : 0
      );
    }

    return {
      x: new Date(moment(data.time, "HH:mm:ss").format()),
      y: isMedianData
        ? parseInt(data.bloodGlucose)
        : data.bloodGlucose.map((glucoseValue) => {
            maxValue.value = Math.max(
              maxValue.value,
              glucoseValue ? parseInt(glucoseValue) : 0
            );

            return parseInt(glucoseValue);
          }),
    };
  });

  updatedMetricData = { ...updatedMetricData, data: updatedData };

  return updatedMetricData;
};

export const getMetricGrapOption = (metricData, width = 800, height = 235) => {
  if (!metricData) {
    return {};
  }

  const maxValue = { value: 0 };

  let percentile10and90Data = metricData.find(
    (data) => data.id === metricIds.percentile10And90
  );

  if (percentile10and90Data) {
    percentile10and90Data = getChartFormattedData(
      percentile10and90Data,
      maxValue
    );
  }

  let percentile25and75Data = metricData.find(
    (data) => data.id === metricIds.percentile25And75
  );

  if (percentile25and75Data) {
    percentile25and75Data = getChartFormattedData(
      percentile25and75Data,
      maxValue
    );
  }

  let medianData = metricData.find((data) => data.id === metricIds.median);

  if (medianData) {
    medianData = getChartFormattedData(medianData, maxValue);
  }

  const options = {
    zoomEnabled: true,
    width,
    height,
    animationEnabled: true,
    animationDuration: 1000,
    axisX: {
      title: "Time",
      gridThickness: 1,
      interval: 3,
      intervalType: "hour",
      valueFormatString: "hh TT",
    },
    axisY: {
      title: "Blood Glucose",
      interval: 40,
      maximum: Math.max(maxValue.value, 140) + 40,
    },
    legend: {
			maxWidth: 150,
		},
    data: [
      {
        type: "rangeArea",
        lineThickness: 1,
        color: "#2DBE41",
        dataPoints: percentile10and90Data.data.map((e) => {
          return { x: e.x, y: [70, 140] };
        }),
        fillOpacity: 0.1,
        markerType: "none",
      },
      percentile10and90Data && {
        type: "rangeSplineArea",
        color: "#DFE3EC",
        showInLegend: true,
        legendText: percentile10and90Data.label,
        lineThickness: 0,
        dataPoints: percentile10and90Data.data,
        fillOpacity: 1,
      },
      percentile25and75Data && {
        type: "rangeSplineArea",
        color: "#A5B5D1",
        showInLegend: true,
        legendText: percentile25and75Data.label,
        lineThickness: 0,
        dataPoints: percentile25and75Data.data,
        fillOpacity: 1,
      },
      medianData && {
        type: "spline",
        color: "#20538A",
        showInLegend: true,
        dataPoints: medianData.data,
        lineThickness: 2,
        legendText: medianData.label,
        fillOpacity: 1,
      },
    ],
  };

  return options;
};
