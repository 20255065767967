import React from "react";
import { Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import PrivateRoute from "../../routers/PrivateRoutes/PrivateRoute";
import { userRoles } from "utils/constants";
import { opsAdminRoutes } from "routers/RolebasedRoutes/opsAdminRoutes";
import { initRoutes } from "routers/RolebasedRoutes/opsAdminRoutes";

const OpsAdmin = () => {
  const role = useSelector(
    (state) => state.loginReducer.loggedInUserDetails.data.role
  );

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.views) {
        return getRoutes(prop.views);
      }
      if (prop.subViews) {
        return getRoutes(prop.subViews);
      }
      if (prop.layout === "/opsAdmin") {
        return (
          <PrivateRoute
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            user={role}
          />
        );
      } else {
        return null;
      }
    });
  };

  const defineRole = (role) => {
    if (role.toUpperCase() === userRoles.inventoryManager) {
      return getRoutes(opsAdminRoutes);
    } else {
      return;
    }
  };

  const defineInitRoutes = (role) => {
    if (role.toUpperCase() === userRoles.inventoryManager) {
      return getRoutes(initRoutes);
    } else {
      return;
    }
  };

  return (
    <>
      <main>
        <Switch>
          {defineInitRoutes(role)}
          {defineRole(role)}
        </Switch>
      </main>
    </>
  );
};

export default OpsAdmin;
