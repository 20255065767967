import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { ExpandMore } from "@material-ui/icons";
import { TextField } from "@material-ui/core";
import { dropDownOptions } from "./constants";

const Dropdown = ({
  options,
  onOption,
  onCustomMessage = () => {},
  defaultIndex = 0,
  defaultTheme = true,
  placeholder = "select",
  isCustom = false,
}) => {
  const [openDropdown, setOpenDropdown] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedOption, setSelectedOption] = React.useState(
    options[defaultIndex]
  );
  const [customInput, setCustomInput] = React.useState(null);

  const handleMenuItemClick = (option) => {
    setSelectedOption(option);
    onOption(option);
    setOpenDropdown(false);
  };

  const handleToggle = () => {
    setOpenDropdown((prevOpen) => !prevOpen);
  };

  const handleCustomInputChange = (e) => {
    setCustomInput(e.target.value);
    onCustomMessage(e.target.value);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenDropdown(false);
  };

  function getStyles(placement = "bottom") {
    return {
      buttonGroup: {
        alignSelf: "center",
        backgroundColor: "white",
        height: "40px",
        border: "none",
        boxShadow: "none",
      },
      toggleButtonDefaultTheme: {
        backgroundColor: "white",
        color: "black",
        fontSize: "14px",
        fontWeight: 400,
      },
      slectOption: {
        backgroundColor: "white",
        color: "black",
        borderRight: "none",
        width: "500px",
        textAlign: "left",
        justifyContent: "left",
        fontSize: "14px",
        fontWeight: 400,
      },
      listDefaultTheme: {
        transformOrigin:
          placement === "bottom" ? "center top" : "center bottom",
      },
      listTheme: {
        transformOrigin:
          placement === "bottom" ? "center top" : "center bottom",
        backgroundColor: "white",
        width: "500px",
      },
      expandMore: {
        fontWight: 400,
      },
      customInput: {
        backgroundColor: "white",
        marginTop: "10px",
        marginBottom: "10px",
      },
    };
  }

  const defaultStyles = getStyles();

  return (
    <React.Fragment>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
        style={defaultStyles.buttonGroup}
      >
        <Button
          onClick={handleToggle}
          style={!defaultTheme ? defaultStyles.slectOption : {}}
        >
          {selectedOption?.value ? selectedOption?.value : placeholder}
        </Button>
        <Button
          size="small"
          aria-controls={openDropdown ? "split-button-menu" : undefined}
          aria-expanded={openDropdown ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          style={!defaultTheme ? defaultStyles.toggleButtonDefaultTheme : {}}
          onClick={handleToggle}
        >
          <ExpandMore style={defaultStyles.expandMore} />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={openDropdown}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={
              defaultTheme
                ? getStyles(placement).listDefaultTheme
                : getStyles(placement).listTheme
            }
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option) => (
                    <MenuItem
                      key={option.key}
                      selected={selectedOption.key === option.key}
                      onClick={() => handleMenuItemClick(option)}
                    >
                      {option.value}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      {selectedOption?.key === dropDownOptions.others && (
        <TextField
          onChange={handleCustomInputChange}
          value={customInput}
          placeholder="Enter Custom Input"
          multiline
          rows={2}
          variant="outlined"
          fullWidth
          style={defaultStyles.customInput}
          inputProps={{ maxLength: 500 }}
        />
      )}
    </React.Fragment>
  );
};

export default Dropdown;
