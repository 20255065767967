import { combineReducers } from "redux";
import loginReducer from "pages/Authentication/reducers/LoginReducers";
import allPatientsReducers from "pages/AllPatients/reducers/AllPatientsReducers";
import patientReducers from "pages/Patient/reducers/PatientsReducers";
import createWeekPlanReducers from "pages/CreateWeekPlan/reducers/CreateWeekPlanReducers";
import addPatientReducers from "pages/AddPatient/reducers/AddPatientReducers";
import homeReducers from "pages/Home/reducers/HomeReducers";
import editAppointmentReducers from "pages/EditAppointment/reducers/EditAppointmentReducers";
import { createReportReducer } from "pages/CreateReport/reducers/CreateReportReducer";

export default combineReducers({
  loginReducer: loginReducer,
  allPatientsReducers: allPatientsReducers,
  patientReducers: patientReducers,
  createWeekPlanReducers: createWeekPlanReducers,
  addPatientReducers: addPatientReducers,
  homeReducers: homeReducers,
  editAppointmentReducers: editAppointmentReducers,
  createReportReducer: createReportReducer,
});
