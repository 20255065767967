/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FormHelperText, Tab, Tabs, withStyles } from "@material-ui/core";
import MyAppBar from "@components/organisms/AppBar";
import DeliveryList from "./components/DeliveryList";
import opsAdminStyle from "assets/jss/pages/opsAdminStyle";
import { deliveryStatusTabs } from "./constants";
import { generatePDFContent } from "./components/helpers";
import Button from "@components/atoms/Button";
import lang from "@assets/languages";
import { defaultCountryCode, upsellingFormFields } from "@pages/Home/constants";
import { Form, Formik } from "formik";
import PhoneInput from "react-phone-input-2";

const initialValues = {
  [upsellingFormFields.patientID]: "",
  [upsellingFormFields.mobileNumber]: "",
};

const OpsAdminDashboard = (props) => {
  const { classes } = props;
  const [tabValue, setTabValue] = useState(0);
  const [selectedDeliveries, setSelectedDeliveries] = useState([]);

  const [searchState, setSearchState] = useState({
    countryCode: defaultCountryCode,
    mobileNumber: "",
    searchDelivery: false,
    invalidPhoneNumberError: false,
  });

  const handleTabChange = (event, tabNumber) => {
    setTabValue(tabNumber);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handlePrint = () => {
    const printWindow = window.open("", "_blank");

    const content = generatePDFContent(selectedDeliveries);
    printWindow.document.open();
    printWindow.document.write(content);
    printWindow.document.close();

    printWindow.onload = () => {
      printWindow.print();
    };
  };

  const handlePhoneNumber = (mobileNumber, data) => {
    setSearchState({
      ...searchState,
      mobileNumber: mobileNumber,
      countryCode: data?.dialCode,
    });
  };

  const handleDeliverySearch = () => {
    setSearchState({
      ...searchState,
      searchDelivery: searchState?.mobileNumber,
    });
  };

  return (
    <>
      <MyAppBar />
      <div className={classes.mainContainer}>
        <div className={classes.topContainer}>
          <div>
            <Formik initialValues={initialValues}>
              {({ values, setFieldValue }) => {
                return (
                  <Form className={classes.formContainer}>
                    <div className={classes.searchContainer}>
                      <PhoneInput
                        country={"in"}
                        inputStyle={{ width: "100%" }}
                        name="mobileNumber"
                        value={searchState.mobileNumber}
                        onChange={handlePhoneNumber}
                        inputProps={{
                          name: "mobileNumber",
                          required: true,
                          autoFocus: true,
                        }}
                        onEnterKeyPress={handleDeliverySearch}
                      />
                      {searchState?.invalidPhoneNumberError && (
                        <FormHelperText className={classes.phoneErrorText}>
                          {lang.phoneNumberIsNotValid}
                        </FormHelperText>
                      )}
                    </div>
                    <div>
                      <Button
                        className={classes.searchBtn}
                        onClick={handleDeliverySearch}
                      >
                        {lang.search}
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
          <div>
            <Button onClick={handlePrint}>{lang.print}</Button>
          </div>
        </div>
        <div>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="All" {...a11yProps(deliveryStatusTabs.orderReceived)} />
            <Tab label="Priority" {...a11yProps(deliveryStatusTabs.priority)} />
            <Tab
              label="Order Received"
              {...a11yProps(deliveryStatusTabs.orderReceived)}
            />
            <Tab
              label="Order Confirmed"
              {...a11yProps(deliveryStatusTabs.orderConfirmed)}
            />
            <Tab label="Packed" {...a11yProps(deliveryStatusTabs.packed)} />
            <Tab
              label="Dispatched"
              {...a11yProps(deliveryStatusTabs.dispatched)}
            />
            <Tab label="Delayed" {...a11yProps(deliveryStatusTabs.delayed)} />
            <Tab
              label="Cancelled"
              {...a11yProps(deliveryStatusTabs.cancelled)}
            />
          </Tabs>
        </div>
      </div>
      <DeliveryList
        tabValue={tabValue}
        selectedDeliveries={selectedDeliveries}
        setSelectedDeliveries={setSelectedDeliveries}
        setSearchState={setSearchState}
        searchState={searchState}
      />
    </>
  );
};

function mapStateToProps(state) {
  return {
    loading: state.allPatientsReducers.loading,
  };
}

export default connect(mapStateToProps)(
  withStyles(opsAdminStyle)(OpsAdminDashboard)
);
