import {
  dietIcon,
  physicalActivity,
  sleepIcon,
  stressIcon,
} from "../../assets/icons";

const tableHeadingKeys = {
  nocturnal: "nocturnal",
  morning: "morning",
  afternoon: "afternoon",
  evening: "evening",
  overall: "overall",
};

export const metasenseTableHeading = {
  "7thDayObservation": {
    heading: "7th Day Observation",
  },
  "7thDayIntervention": {
    heading: "7th Day Intervention",
  },
  "14thDayInsight": {
    heading: "14th Day Insightn",
  },
  [tableHeadingKeys.nocturnal]: {
    heading: "Nocturnal",
    imageText: "🌌",
    otherInfo: "00:00 - 05:59",
  },
  [tableHeadingKeys.morning]: {
    heading: "Morning",
    imageText: "🌅",
    otherInfo: "06:00 - 11:59",
  },
  [tableHeadingKeys.afternoon]: {
    heading: "Afternoon",
    imageText: "🌞",
    otherInfo: "12:00 - 17:59",
  },
  [tableHeadingKeys.evening]: {
    heading: "Evening",
    imageText: "🌄",
    otherInfo: "18:00 - 23:59",
  },
  [tableHeadingKeys.overall]: {
    heading: "Overall",
  },
};

export const maxTableCharLength = 1000;

export const healthRiskScoreNote = "*should be < 22";
export const glycemicScoreNote = "*should be > 90%";
export const distressScoreNote = "*should be < 12%";
export const distressScoreGoal = "Reduction in variability < 12%";
export const storeAndProgressTableKeys = {
  timeInRange: "timeInRange",
  distressScore: "distressScore",
  averageGlucose: "averageGlucose",
};

export const patientTIRRowKeys = {
  diet: "diet",
  exercise: "exercise",
};

export const TIRTableKeyBasedHeading = {
  [patientTIRRowKeys.diet]: "diet",
  [patientTIRRowKeys.exercise]: "exercise",
};

export const TIRTableKeyBasedImage = {
  [patientTIRRowKeys.diet]: dietIcon,
  [patientTIRRowKeys.exercise]: physicalActivity,
};

//GV table
export const patientGVRowKeys = {
  stress: "stress",
  sleep: "sleep",
};

export const GVTableKeyBasedHeading = {
  [patientGVRowKeys.stress]: "stress",
  [patientGVRowKeys.sleep]: "sleep",
};

export const GVTableKeyBasedImage = {
  [patientGVRowKeys.stress]: stressIcon,
  [patientGVRowKeys.sleep]: sleepIcon,
};

//patient progress table
export const patientProgressRowKeys = {
  seventhDay: "seventhDay",
  fourteenthDay: "fourteenthDay",
  interpretation: "interpretation",
};

export const progressTableKeyBasedHeading = {
  [storeAndProgressTableKeys.timeInRange]: "Glycemic score (TIR)",
  [storeAndProgressTableKeys.averageGlucose]: "Average Glucose",
};

export const summaryTableHeadings = [
  "Metric",
  "Value",
  "Insights",
  "Progress",
  "Advice",
];
