import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  isActive: {
    fill: "#23AFD7",
  },
});

function Previous(props) {
  const classes = useStyles(props);

  return (
    <svg width="1em" height="1em" viewBox="0 0 28 28" fill="none" {...props}>
      <path
        d="M14 0C6.268 0 0 6.268 0 14s6.268 14 14 14 14-6.268 14-14S21.732 0 14 0zm2.377 19.7a1.077 1.077 0 11-1.523 1.523L8.393 14.76a1.077 1.077 0 010-1.522l6.461-6.462A1.077 1.077 0 0116.377 8.3l-5.7 5.7 5.7 5.7z"
        fill="#B3B7B8"
        className={props.isActive ? classes.isActive : ""}
      />
    </svg>
  );
}

const MemoPrevious = React.memo(Previous);
export default MemoPrevious;
