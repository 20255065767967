import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// material-ui components
import { withStyles, Button } from "@material-ui/core";
import buttonStyle from "../../../assets/jss/components/buttonStyle";

const CustomButton = React.forwardRef((props, ref) => {
  const {
    classes,
    children,
    className,
    fullWidth,
    md,
    lg,
    md_secondary,
    md_border,
    inverse,
    disabled,
    ...rest
  } = props;
  const btnClasses = classNames({
    [classes.button]: true,
    [classes.fullWidth]: fullWidth,
    [classes.medium]: md,
    [classes.large]: lg,
    [classes.medium_secondary]: md_secondary,
    [classes.medium_border]: md_border,
    [classes.medium_inverse]: inverse,
    [className]: className,
  });
  return (
    <Button {...rest} className={btnClasses} disabled={disabled}>
      {children}
    </Button>
  );
});

CustomButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  fullWidth: PropTypes.bool,
  md: PropTypes.bool,
  lg: PropTypes.bool,
  md_border: PropTypes.bool,
  md_secondary: PropTypes.bool,
  inverse: PropTypes.bool,
};

export default withStyles(buttonStyle)(CustomButton);
