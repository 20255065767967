import React from 'react'

const SectionInfo = (props) => {
  const {
    title = "",
    data = "",
    timeline = "",
    classes
  } = props;

  const {
    val,
    unit
  } = data || {};

  return (
    <div className={classes?.sectionInfoContainer}>
      <div className={classes?.sectionInfoTitle}>{title}</div>
      <div>
        <span className={classes?.sectionInfoDataValue}>{val}</span>
        &nbsp;<span className={classes?.sectionInfoDataUnit}>{unit}</span>
      </div>
      <div className={classes?.sectionInfoTimeline}>{timeline}</div>
    </div>
  )
}
export default SectionInfo
