import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  tirListHeading: {
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "18px",
    marginBottom: "2px",
    color: "#27276B",
  },
  tirTagContainer: {
    padding: "14px 4px",
    width: "23px",
    borderRadius: "2px",
    backgroundColor: "#27276B",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 5px 0 0",
  },
  tirTag: {
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "15px",
    color: "white",
    transform: "rotate(-90deg)",
  },
  tirItemContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: "10px",
  },
  tirRowItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  tirHeading: {
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "15px",
    margin: "0 4px 0 0",
    width: "77px",
    textOverflow: "ellipsis",
  },
  tirHeadingValue: {
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "17px",
  },
  bottomUnderline: {
    padding: "0 0 2px 0",
    borderBottom: "1px dashed #A8A8A8",
  },
  tirSubHeadingContainer: {
    marginTop: "4px",
  },
  tirSubHeading: {
    fontWeight: 500,
    fontSize: "10px",
    lineHeight: "12px",
    color: "#626262",
    width: "77px",
    textOverflow: "ellipsis",
  },
  tirSubHeadingValue: {
    fontWeight: 500,
    fontSize: "10px",
    lineHeight: "12px",
  },
  underTargetRange: {
    color: "#14B8A6",
  },
  aboveTargetRange: {
    color: "#FF5A5F",
  },
  tirRightContent: {
    marginLeft: "5px",
    flex: 1,
  },
  averageGlucoseValue: {
    color: "#27276B",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "15px",
  },
  scoreHeading: {
    color: "#27276B",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "16px",
  },
  scoreDescription: {
    color: "#626262",
    fontSize: "10px",
    fontWeight: 600,
    lineHeight: "12px",
    width: "70px",
  },
  scoreHeadingContainer: {
    alignItems: "flex-start",
    marginRight: "14px",
  },
  scoreContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "24px",
    flex: 1,
  },
  scoreMetricContainer: {
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
    display: "flex",
  },
  scoreRange: {
    borderRadius: "2px",
    backgroundColor: "white",
    position: "relative",
    textAlign: "center",
    flex: 1,
    padding: "2px 0",
  },
  goalConatiner: {
    borderRadius: "2px",
    backgroundColor: "white",
    position: "relative",
    textAlign: "center",
    flex: 1,
  },
  scoreMetricValue: {
    fontSize: "10px",
    lineHeight: "14px",
    fontWeight: 600,
    color: "#FFFFFF",
  },
  valueIndicator: {
    fontSize: "14px",
    lineHeight: "15px",
    fontWeight: 600,
  },
  valueIndicatorContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));
