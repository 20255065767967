import {
  Dialog,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  Typography,
  withStyles,
} from "@material-ui/core";
import Loader from "@components/atoms/Loader";
import SaveAndUploadLoader from "@components/atoms/Loader";
import { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import ConfirmDialog from "./components/ConfirmDialog";
import MyAppBar from "@components/organisms/AppBar";
import createReportsStyle from "assets/jss/pages/createReportsStyle";
import lang from "assets/languages";
import TabPanel from "./components/TabPanel";
import api from "utils/api";
import { apiResponses } from "network/constants";
import Dropdown from "@components/molecules/Dropdown";
import moment from "moment";

import {
  saveReportDataByReportType,
  clearRequiredFieldState,
  validateCreateReportFields,
  getCommonCoachReportPayload,
} from "./utils";

import {
  actionCreator,
  CreateReportActions,
  reportTypesId,
  reportTabIndxes,
  reportTypeForApi,
  tableTypes,
  graphWidth,
} from "./constants";

import MetasenseReportTemplate from "@pages/MetasenseReport/MetasenseReportTemplate";
import { notyf, base64toFile } from "@utils/utility";
import MetasenseReportPreview from "@pages/MetasenseReport";
import { END_POINT } from "@network/constants";
import { API_METHODS } from "@network/constants";
import { SummaryTab } from "./components/SummaryTab";
import { PatientProgress } from "./components/PatientProgressTab";
import {
  setReportId,
  setReportName,
  setSlectedReportType,
  updatePatientInfo,
} from "./actions/CreateReportActions";
import { DailyPattern } from "./components/DailyPatternTab";
import PatientInfo from "./components/PatientInfoTab";
import { storePrefilledData } from "./helper";

import html2pdf from "html2pdf.js";

import { uploadFile, uploadReportsAPI } from "services/fileService";

import ErrorInfoModal from "./components/ErrorInfoModal";
import PreviewFirstConfirmDialog from "./components/PreviewFirstConfirmDialog";
// import ReportsModal from "pages/Patient/components/CgmProgressDrawer/reportsModal"
import SuccessDialog from "./components/SuccessDialog";
import Important from "@components/atoms/Important";
import {
  fetchDataWiseCGMData,
  fetchPatientInfoData,
  fetchPatientProgressData,
  fetchReportSummary,
  get7And14DayGraphData,
} from "@services/createReportServices";

const CreateReport = (props) => {
  const {
    loading,
    history,
    classes,
    activeFileType,
    reportId,
    patientInfo,
    reportName,
    createReportReduxState,
  } = props;

  const componentRef = useRef(null);

  const handleGeneratePDF = () => {
    const options = {
      margin: [0, 0, 0, 0],
      image: { type: "jpeg", quality: 1 },
      jsPDF: { format: "a4", orientation: "portrait" },
    };

    return html2pdf().set(options).from(componentRef.current).outputPdf();
  };

  const patientId = history.location.state && history.location.state.patientId;
  const sensorId = history.location.state && history.location.state.sensorId;

  const [openDilogBox, setOpenDilogBox] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [sensorSummaryState, setSensorSummaryState] = useState({
    sensors: null,
    selectedSensor: null,
  });

  const [isPreviewRequestDialogOpen, setIsPreviewRequestDialogOpen] =
    useState(false);
  const [isSavingAndUploading, setIsSavingAndUploading] = useState(false);
  const [errorInfo, setErrorInfo] = useState({
    isModalOpen: false,
    errors: [],
  });
  // const [showReportsModal, setShowReportsModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const isMounted = useRef(false);
  const [dateOptions, setDateOptions] = useState(null);
  const dispatch = useDispatch();

  const { selectedSensor, sensors } = sensorSummaryState;
  const timeoutId = useRef(null);

  useEffect(() => {
    const getSensorList = async () => {
      try {
        const {
          data: { data: sensorList, status },
        } = await api(END_POINT.getPatientSensorList(patientId), {
          method: API_METHODS.get,
        });

        if (status === apiResponses.success) {
          //if case to handle the create button view and else to rest other
          if (sensorList && sensorList.length > 0) {
            let selectedSensor = sensorId
              ? sensorList.find((sensor) => sensor.id === sensorId) ||
                sensorList[0]
              : sensorList[0];

            setSensorSummaryState((prevState) => ({
              ...prevState,
              sensors: sensorList,
              selectedSensor: selectedSensor,
            }));

            dispatch(
              updatePatientInfo({
                ...patientInfo,
                sensorActivationDate: selectedSensor?.sensorActivationDate,
              })
            );
          } else {
            setSensorSummaryState((prevState) => ({
              ...prevState,
              sensors: sensorList,
              selectedSensor: null,
            }));
          }
        } else {
          notyf.error("failed to fetch sensors");
        }
      } catch (e) {
        notyf.error("failed to fetch sensors");
      }
    };
    getSensorList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sensors) {
      let options = sensors.map((sensor) => {
        const { id, sensorActivationDateInUTC, sensorExpirationDateInUTC } =
          sensor;

        return {
          ...sensor,
          key: id,
          value: `${moment(sensorActivationDateInUTC).format(
            "Do MMMM"
          )} - ${moment(sensorExpirationDateInUTC).format("Do MMMM")}`,
        };
      });
      setDateOptions(options);
    }
  }, [sensors]);

  useEffect(() => {
    if (!selectedSensor) {
      return;
    }
    get7And14DayGraphData(
      selectedSensor?.id,
      reportTypeForApi.overall,
      reportTypesId.fourteenthDayReport
    );
    get7And14DayGraphData(
      selectedSensor?.id,
      reportTypeForApi.seventhDay,
      reportTypesId.seventhDayReport
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSensor?.id]);

  const saveReportByReportType = () => {
    if (!reportId) {
      return;
    }

    saveReportDataByReportType(
      activeFileType,
      tableTypes,
      patientId,
      selectedSensor,
      clearRequiredFieldState(createReportReduxState),
      reportId,
      reportName
    );
  };

  const handleSave = () => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    timeoutId.current = setTimeout(() => {
      saveReportByReportType();
    }, 3000);
  };

  useEffect(() => {
    if (isMounted.current) {
      handleSave();
    } else {
      isMounted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createReportReduxState, tabValue, selectedSensor?.id]);

  useEffect(() => {
    let reportType =
      activeFileType === reportTypesId.fourteenthDayReport
        ? reportTypeForApi.overall
        : reportTypeForApi.seventhDay;

    const createReport = async () => {
      try {
        const {
          data: { data },
        } = await api(END_POINT.creatSensorReport, {
          method: API_METHODS.POST,
          data: {
            patientId: patientId,
            sensorId: selectedSensor?.id,
            reportType: reportType,
          },
        });

        if (data?.id) {
          dispatch(setReportId(data?.id));
          dispatch(setReportName(data?.fileName));
        }
      } catch (e) {
        notyf.error("Failed to create report");
      }
    };

    if (selectedSensor?.id) {
      createReport();

      fetchDataWiseCGMData(patientId, {
        sensorId: selectedSensor?.id,
        reportType,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSensor?.id, activeFileType]);

  useEffect(() => {
    if (reportId !== null && reportId !== undefined && reportId > -1) {
      fetchPatientInfoData(reportId);

      const fetchDailyPatternData = async () => {
        try {
          const data = await api(END_POINT.getDailyPatterData(reportId), {
            method: API_METHODS.get,
          });
          if (data?.data?.status === apiResponses.success) {
            const prefilledDataUpdate = storePrefilledData(
              data.data.data,
              activeFileType
            );

            dispatch(
              actionCreator(
                CreateReportActions.set_report_data,
                prefilledDataUpdate
              )
            );
          } else {
            notyf.error("failed to daily Pattern data");
          }
        } catch (e) {
          notyf.error("failed to daily pattern data");
        }
      };

      fetchPatientInfoData(reportId);
      fetchDailyPatternData();
      fetchPatientProgressData(reportId);
      if (activeFileType === reportTypesId.fourteenthDayReport) {
        fetchReportSummary(reportId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportId]);

  const handleCloseConfirmDialog = () => {
    setOpenDilogBox(false);
  };
  const handleGoBackToAllPatients = () => {
    dispatch(setSlectedReportType(reportTypesId.seventhDayReport));

    history.goBack();
  };
  const handleOpenConfirmDialog = () => {
    setOpenDilogBox(true);
  };

  const printIframe = () => {
    // const id = "metasensePreview";
    // const iframe = document.frames
    //   ? document.frames[id]
    //   : document.getElementById(id);
    // const iframeWindow = iframe?.contentWindow || iframe;
    // iframe.focus();
    // iframeWindow.print();
    // return false;
    const options = {
      margin: [0, 0, 0, 0],
      image: { type: "jpeg", quality: 1 },
      filename: reportName,
      jsPDF: { format: "a4", orientation: "portrait" },
    };
    const elem = document.getElementById("metasenseReport");

    if (!elem) {
      alert("preview the report before download.");

      return;
    }

    return html2pdf().set(options).from(elem).save();
  };

  const handleTabChange = (event, tabNumber) => {
    if (tabNumber === reportTabIndxes.download) {
      printIframe();
      return;
    }
    setTabValue(tabNumber);
  };

  const onSaveAndDraft = () => {
    saveReportByReportType();
  };

  const onSaveAndUpload = () => {
    if (isSavingAndUploading) return;

    const { hasErr, errors } = validateCreateReportFields({
      context: activeFileType,
      data: {
        fourteenthDayReport: props?.fourteenthDayReport,
        seventhDayReport: props?.seventhDayReport,
        patientInfo: props?.patientInfo,
        patientProgress: props?.patientProgress,
        summary: props?.summary,
        reportName,
      },
    });

    if (hasErr) {
      setErrorInfo((prevState) => ({
        ...prevState,
        isModalOpen: true,
        errors,
      }));
      return;
    }

    if (tabValue !== reportTabIndxes.preview) {
      setIsPreviewRequestDialogOpen(true);
      return;
    }

    setIsSavingAndUploading(true);

    handleGeneratePDF()
      .then(async (data) => {
        const base64Data = btoa(data);
        const fileName = reportName || new Date().getMilliseconds();
        const binaryData = base64toFile(
          base64Data,
          fileName + ".pdf",
          "application/pdf"
        );
        const fileURL = await uploadFile(binaryData);

        const fileDetails = {
          fileURL: fileURL,
          ...getCommonCoachReportPayload({
            activeFileType,
            tableTypes,
            patientId,
            selectedSensor,
            createReportReduxState,
            reportId,
            reportName: fileName,
          }),
        };

        try {
          await uploadReportsAPI(fileDetails);
          setIsSavingAndUploading(false);
          setShowSuccessModal(true);
        } catch (err) {
          setIsSavingAndUploading(false);
        }
      })
      .catch((err) => {
        console.warn(err);
        notyf.error("Something went wrong, Please try again !");
        setIsSavingAndUploading(false);
      });
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const selectedDateIndex = sensors
    ? sensors.findIndex((sensor) => sensor.id === sensorId)
    : 0;

  return (
    <>
      {loading && <Loader openDilogBox={true} loaderMessage="Fetching..." />}
      {openDilogBox && (
        <Dialog open={openDilogBox} onClose={handleCloseConfirmDialog}>
          <ConfirmDialog
            handleGoBackToAllPatients={handleGoBackToAllPatients}
            handleCloseConfirmDialog={handleCloseConfirmDialog}
          />
        </Dialog>
      )}
      <MyAppBar
        handleOpenConfirmDialog={handleOpenConfirmDialog}
        handleSubmit={onSaveAndUpload}
        handleDraft={onSaveAndDraft}
        value={"create-report"}
      />
      <div className={classes.mainContainer}>
        <div className={classes.fileNameBox}>
          <Typography className={classes.subHeadingText}>
            {lang.fileName}
            <Important />
          </Typography>
          <input
            placeholder="Type filename here..."
            className={classes.fileInputBox}
            value={reportName}
            onChange={(input) => {
              dispatch(setReportName(input?.target?.value));
            }}
          />
        </div>
        <Typography className={classes.fileType}>{lang.fileType}</Typography>
        <FormControl className={classes.fileTypeForm}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onChange={(event) => {
              const fileType = +event.currentTarget.value;
              setTabValue(reportTabIndxes.patientInfo);

              saveReportByReportType();

              dispatch(setSlectedReportType(Number(fileType)));
            }}
          >
            <FormControlLabel
              value={reportTypesId.seventhDayReport}
              control={
                <Radio
                  checked={activeFileType === reportTypesId.seventhDayReport}
                  size="small"
                />
              }
              classes={{ label: classes.radioLableText }}
              label="7th day report"
            />
            <FormControlLabel
              value={reportTypesId.fourteenthDayReport}
              control={
                <Radio
                  checked={activeFileType === reportTypesId.fourteenthDayReport}
                  size="small"
                />
              }
              classes={{ label: classes.radioLableText }}
              label="14th day report"
            />
          </RadioGroup>
        </FormControl>
        <div className={classes.dropDownContainer}>
          <div className={classes.dateContainer}>
            <Typography>{lang.date}</Typography>
          </div>
          <div>
            {dateOptions && (
              <Dropdown
                options={dateOptions}
                onOption={(sensor) => {
                  setTabValue(reportTabIndxes.patientInfo);

                  dispatch(
                    updatePatientInfo({ ...patientInfo, date: sensor?.value })
                  );
                  setSensorSummaryState((prevState) => ({
                    ...prevState,
                    selectedSensor: sensor,
                  }));
                }}
                defaultIndex={selectedDateIndex >= 0 ? selectedDateIndex : 0}
                classes={classes}
                defaultTheme={false}
              />
            )}
          </div>
        </div>

        <Grid item xs={12} className={classes.mainGrid}>
          <div className={classes.tabContainer}>
            <Tabs
              className={classes.root}
              value={tabValue}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab
                label="Patient Info"
                {...a11yProps(reportTabIndxes.patientInfo)}
              />
              <Tab
                label="Daily Pattern"
                {...a11yProps(reportTabIndxes.dailyPattern)}
              />
              <Tab
                label="Patient Progress"
                disabled={activeFileType === reportTypesId.seventhDayReport}
                {...a11yProps(reportTabIndxes.patientProgress)}
              />
              <Tab
                label="Summary"
                disabled={activeFileType === reportTypesId.seventhDayReport}
                {...a11yProps(reportTabIndxes.summary)}
              />
              <Tab label="Preview" {...a11yProps(reportTabIndxes.preview)} />
              <Tab
                label={<MetasenseReportPreview />}
                {...a11yProps(reportTabIndxes.download)}
              />
            </Tabs>
            <TabPanel value={tabValue} index={reportTabIndxes.patientInfo}>
              <PatientInfo reportId={reportId} />
            </TabPanel>
            <TabPanel value={tabValue} index={reportTabIndxes.dailyPattern}>
              <DailyPattern
                activeFileType={activeFileType}
                sensorId={selectedSensor?.id}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={reportTabIndxes.patientProgress}>
              <PatientProgress />
            </TabPanel>
            <TabPanel value={tabValue} index={reportTabIndxes.preview}>
              <div className={classes.reportPreviewContainer}>
                <div className={classes.report}>
                  <div
                    id="metasenseReport"
                    ref={componentRef}
                    style={{ backgroundColor: "red" }}
                  >
                    <MetasenseReportTemplate fixedCgmGraphWidth={graphWidth} />
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel value={tabValue} index={reportTabIndxes.summary}>
              <SummaryTab />
            </TabPanel>
          </div>
        </Grid>
      </div>

      <ErrorInfoModal
        key={`ErrorInfoModal-${errorInfo?.isModalOpen}`}
        showModal={errorInfo?.isModalOpen}
        closeModal={() => setErrorInfo(false)}
        data={errorInfo?.errors}
      />
      <PreviewFirstConfirmDialog
        key={`PreviewFirstConfirmDialog-${isPreviewRequestDialogOpen}`}
        isOpen={isPreviewRequestDialogOpen}
        handleCloseConfirmDialog={() => {
          setIsPreviewRequestDialogOpen(false);
          handleTabChange(null, reportTabIndxes.preview);
        }}
        classes={classes}
      />
      <SaveAndUploadLoader
        key={`SaveAndUploadLoader-${isSavingAndUploading}`}
        open={isSavingAndUploading}
        loaderMessage="Saving & Uploading"
      />
      {/**
       * If:
       *  - Requirement is show `ReportsModal` post successful save n upload.
       *
       * <ReportsModal
       *  showReportsModal={showReportsModal}
       *  handleClose={() => { setShowReportsModal(false); }}
       *  patientData={patientData}
       *  hideCreateCTA={true}
       * />
       *
       */}
      <SuccessDialog
        key={`SuccessDialog-${showSuccessModal}`}
        isOpen={showSuccessModal}
        handleCloseConfirmDialog={() => window.history.back()}
        classes={classes}
      />
    </>
  );
};

function mapStateToProps(state) {
  return {
    fourteenthDayReport: state.createReportReducer.fourteenthDayReport,
    seventhDayReport: state.createReportReducer.seventhDayReportData,
    activeFileType: state.createReportReducer.activeFileType,
    patientInfo: state.createReportReducer.patientInfo,
    reportName: state.createReportReducer.reportName,
    createReportReduxState: state.createReportReducer,
    reportId: state.createReportReducer.reportId,
    patientProgress: state.createReportReducer.patientProgress,
    summary: state.createReportReducer.summary,
  };
}
export default connect(mapStateToProps)(
  withStyles(createReportsStyle)(CreateReport)
);
