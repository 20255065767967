import { Box, Typography } from "@material-ui/core";
import React from "react";
import { useStyles } from "./styles";

export const Goal = ({ className = "", children, styles }) => {
  const clasess = useStyles();

  return (
    <Box style={styles} className={`${clasess.goalContainer} ${className}`}>
      {children}
      <Typography className={clasess.goalText}>{"Goal"}</Typography>
    </Box>
  );
};
