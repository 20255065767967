const opsAdminStyle = () => ({
  mainContainer: {
    width: "1100px",
    margin: "auto",
    marginTop: "20px",
    padding: "10px",
  },
  topContainer: {
    width: "1100px",
    justifyContent: "space-between",
    display: "flex",
    alignContent: "center",
    alignItems: "center",
  },
  formContainer: {
    display: "flex",
    alignItems: "center",
  },
  searchContainer: { width: "500px", marginRight: "10px" },
  mainGrid: {
    width: "1166px",
  },
  headingText: {
    fontSize: "20px",
    fontWeight: 700,
  },
  tabContainer: {
    height: "48px",
    border: "1px solid #ccc",
    backgroundColor: "red",
    alignItems: "center",
    margin: "auto",
  },
  root: {
    height: "100%",
    borderBottom: "1px solid #ccc",
    "& .MuiTabs-flexContainer": {
      "& .MuiTab-root": {
        minHeight: "45px",
        minWidth: "100px",
      },
      "& .MuiButtonBase-root.Mui-selected": {
        color: "#2B367D",
      },
    },
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  text: {
    fontSize: "24px",
    fontWeight: 700,
  },
  subHeadingText: {
    fontSize: "12px",
    fontWeight: 700,
  },
  fileNameBox: {
    marginBottom: "16px",
  },
  fileType: {
    fontSize: "12px",
    fontWeight: 700,
    color: "#595959",
  },
  fileInputBox: {
    height: "40px",
    width: "400px",
    border: "1px solid #D9D9D9",
    padding: "9px",
    fontSize: "14px",
    marginTop: "4px",
  },
  checked: {
    color: "blue",
  },
  radioLableText: {
    fontSize: "14px",
    fontWeight: 400,
  },
  dropDownContainer: {
    display: "flex",
    height: "40px",
    backgroundColor: "white",
    width: "765px",
    border: "1px solid #D9D9D9",
    marginTop: "16px",
  },
  dateContainer: {
    width: "243px",
    padding: "9px 0px 9px 12px",
    borderRight: "1px solid #D9D9D9",
  },
  dropDownButtonGroup: {
    alignSelf: "center",
    backgroundColor: "white",
    height: "45px",
    border: "none",
    boxShadow: "none",
  },
  dropDownDateButton: {
    backgroundColor: "red",
    color: "black",
    borderRight: "none",
    width: "500px",
    textAlign: "left",
    justifyContent: "left",
  },
  dropDownToggle: {
    backgroundColor: "white",
    color: "black",
  },
  tableContainer: {
    marginBottom: "17px",
  },
  reportPreviewContainer: {
    background: "#595959",
    display: "flex",
    justifyContent: "center",
    overflow: "scroll",
    height: "600px",
  },
  report: {
    width: "80%",
    margin: "50px",
  },
  dropDownSlectOption: {
    backgroundColor: "white",
    color: "black",
    borderRight: "none",
    width: "500px",
    textAlign: "left",
    justifyContent: "left",
    fontSize: "14px",
    fontWeight: 400,
  },
  toggleButtonDefaultTheme: {
    backgroundColor: "white",
    color: "black",
    fontSize: "14px",
    fontWeight: 400,
  },
  dropDownListTheme: {
    backgroundColor: "white",
    width: "500px",
  },
});

export default opsAdminStyle;
