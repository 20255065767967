const { makeStyles } = require("@material-ui/core");

export const useStyles = makeStyles({
  goalContainer: {
    padding: "0 0 9px 0",
    border: "1px solid #14B8A6",
    position: "relative",
  },
  goalText: {
    position: "absolute",
    textAlign: "center",
    alignSelf: "center",
    bottom: "-5px",
    left: "35%",
    padding: "0 3px",
    fontSize: "8px",
    lineHeight: "10px",
    fontWeight: 800,
    color: "#14B8A6",
    backgroundColor: "white",
  },
});
