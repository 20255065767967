/**
 @desc
 * Centralized unique action types for Login actions and reducers.
 */


import {
  actionCreator,
  API_URL,
  checkHttpStatus,
  createRequestActionTypes,
  jsonApiHeader,
  showSuccessMessage,
  showErrorMessage,
} from '../../../utils/utility';

export {
  jsonApiHeader,
  actionCreator,
  checkHttpStatus,
  showSuccessMessage,
  showErrorMessage,
};

export const GET_ALL_LEVELS = `${API_URL}/v1/modules/levels`;
export const GET_ALL_CATEGORIES = `${API_URL}/v1/modules/categories`;
export const GET_ALL_MODULES = `${API_URL}/v1/modules`;
export const POST_CREATE_WEEKPLAN = `${API_URL}/v1/weekplans`;
export const PATCH_UPDATE_WEEKPLAN = `${API_URL}/v1/weekplans`;
export const GET_MASTER_TASKS_BY_MODULE = `${API_URL}/v1/modules/tasks`;

export const CreateWeekPlanActionTypes = {
  get_AllLevels: createRequestActionTypes('GET_ALL_LEVELS'),
  get_AllCategories: createRequestActionTypes('GET_ALL_CATEGORIES'),
  get_AllModules: createRequestActionTypes('GET_ALL_MODULES'),
  post_CreateWeekPlan: createRequestActionTypes('POST_CREATE_WEEKPLAN'),
  patch_UpdateWeekPlan: createRequestActionTypes('UPDATE_WEEKPLAN'),
  get_MasterTasksByModule: createRequestActionTypes('GET_MASTER_TASKS_BY_MODULE'),
};