/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Dialog,
  MenuItem,
  Select,
  Typography,
  withStyles,
} from "@material-ui/core";
import { deliveryDetailsStyle } from "assets/jss/pages/components/deliveryDetailsStyle";
import { Form, Formik } from "formik";
import Button from "@components/atoms/Button";
import FormDatePicker from "@components/molecules/FormDatePicker";
import { datePickerIcon } from "assets/icons";
import ConfirmDialog from "../ConfirmDialog";
import FormTextInput from "@components/molecules/FormTextInput/FormTextInput";
import lang from "assets/languages";
import moment from "moment";
import history from "utils/history";
import Card from "@components/atoms/Card";
import { deliveryStatusOrder } from "pages/OpsAdminDashboard/constants";
import api from "utils/api";
import { END_POINT } from "network/constants";
import { API_METHODS } from "network/constants";
import { apiResponses } from "network/constants";
import { notyf } from "utils/utility";
import Loader from "@components/atoms/Loader";
import { changedDeliveryInformation } from "pages/OpsAdminDashboard/helpers";
import { deliveryFormFields } from "pages/OpsAdminDashboard/constants";

const DeliveryInformationForm = ({
  deliveryData,
  deliveryDetailsDropDowns,
  deliveryId,
}) => {
  const classes = deliveryDetailsStyle();
  const [loading, setLoading] = useState(false);

  const saveDeliveryInformation = async (values) => {
    let payload = changedDeliveryInformation(deliveryData, values);
    try {
      setLoading(true);
      const data = await api(END_POINT.updateDeliveryDetails(deliveryId), {
        method: API_METHODS.PATCH,
        data: payload,
      });
      setLoading(false);
      if (data?.data?.status === apiResponses.success) {
        history.goBack();
      } else {
        notyf.error("Something went wrong");
      }
    } catch (e) {
      setLoading(false);
      notyf.error("Something wernt wrong");
    }
  };

  let initialValues = {
    [deliveryFormFields.deliveryMode]: deliveryData?.deliveryMode || null,
    [deliveryFormFields.ETA]: deliveryData?.ETA
      ? moment(deliveryData?.ETA)
      : "",
    [deliveryFormFields.deliveryVendor]: deliveryData?.deliveryVendor || null,
    [deliveryFormFields.trackingId]: deliveryData?.trackingId || "",
    [deliveryFormFields.status]: deliveryData?.status
      ? { name: deliveryData?.status } || null
      : null,
    [deliveryFormFields.remarks]: "",
  };

  const [confirmationPopupState, setConfirmationPopupState] = useState({
    openCancelConfirmationPopup: false,
    openSaveConfirmationPopup: false,
  });

  return (
    <>
      {loading && <Loader open={true} loaderMessage="Fetching..." />}
      <div className={classes.deliveryFormContainer}>
        <Typography className={classes.formTitleContainer}>
          {lang.deliveryInformation}
        </Typography>
        <div>
          <Formik
            initialValues={initialValues}
            onSubmit={saveDeliveryInformation}
            enableReinitialize
          >
            {(formProps) => {
              const { setFieldValue, values } = formProps;

              return (
                <Form>
                  <>
                    <div className={classes.fieldContainer}>
                      <div className={classes.fieldSubContainer}>
                        <div className={classes.leftContainer}>
                          <Typography className={classes.containerLabel}>
                            {lang.deliveryMode}
                          </Typography>
                        </div>
                        <div className={classes.deliveryModeDropDownContainer}>
                          <Select
                            fullWidth
                            labelId="select-label"
                            placeholder="Select"
                            displayEmpty
                            value={
                              values?.[deliveryFormFields.deliveryMode]?.name
                            }
                            renderValue={() => {
                              return (
                                values?.[deliveryFormFields.deliveryMode]
                                  ?.name || lang.select
                              );
                            }}
                            onChange={(event) => {
                              setFieldValue(
                                deliveryFormFields?.[
                                  deliveryFormFields.deliveryMode
                                ],
                                event.target.value
                              );
                            }}
                            key={
                              values?.[deliveryFormFields.deliveryMode]?.name
                            }
                          >
                            <MenuItem value="">{lang.select}</MenuItem>
                            {deliveryDetailsDropDowns?.deliveryModes?.map(
                              (mode) => {
                                return (
                                  <MenuItem value={mode} key={mode.id}>
                                    {mode.name}
                                  </MenuItem>
                                );
                              }
                            )}
                          </Select>
                        </div>
                      </div>
                      <div
                        className={[
                          classes.fieldSubContainer,
                          classes.marginLeftEighty,
                        ].join(" ")}
                      >
                        <div className={classes.leftContainer}>
                          <Typography className={classes.containerLabel}>
                            {lang.expectedDelivery}
                          </Typography>
                        </div>
                        <div className={classes.datePickerContainer}>
                          <img src={datePickerIcon} alt="star" />
                          <FormDatePicker
                            style={{
                              textAlign: "center",
                              alignItems: "center",
                              alignContent: "center",
                              width: "150px",
                              border: "none",
                            }}
                            name={deliveryFormFields.ETA}
                            minDate={new Date()}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={classes.fieldContainer}>
                      <div>
                        <Typography className={classes.topLabels}>
                          {`${lang.trackingId} :`}
                        </Typography>
                        <div className={classes.fieldSubContainer}>
                          <div className={classes.editableLeftContainer}>
                            <Select
                              fullWidth
                              value={
                                values?.[deliveryFormFields.deliveryVendor]
                                  ?.name
                              }
                              onChange={(event) => {
                                setFieldValue(
                                  deliveryFormFields.deliveryVendor,
                                  event.target?.value
                                );
                              }}
                              renderValue={() => {
                                return (
                                  values?.[deliveryFormFields.deliveryVendor]
                                    ?.name || lang.select
                                );
                              }}
                              className={classes.dropdown}
                              label="Select"
                              placeholder="Select"
                              displayEmpty
                            >
                              <MenuItem value="">{lang.select}</MenuItem>
                              {deliveryDetailsDropDowns?.deliveryVendors?.map(
                                (mode) => {
                                  return (
                                    <MenuItem value={mode} key={mode?.id}>
                                      {mode?.name}
                                    </MenuItem>
                                  );
                                }
                              )}
                            </Select>
                          </div>
                          <div className={classes.trackingTextInputContainer}>
                            <FormTextInput
                              variant="standard"
                              name={deliveryFormFields.trackingId}
                              multiline
                              placeholder="Enter Tracking Id"
                              typeOf="auth"
                              className={classes.trackingTextInput}
                              InputProps={{
                                disableUnderline: true,
                                style: {
                                  height: "50px",
                                  border: "none",
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className={classes.marginLeftFifty}>
                        <Typography className={classes.topLabels}>
                          {`${lang.packagingStatus} :`}
                        </Typography>
                        <div>
                          <div
                            className={[
                              classes.editableLeftContainer,
                              classes.border,
                            ].join(" ")}
                          >
                            <Select
                              fullWidth
                              value={values?.[deliveryFormFields.status]?.name}
                              onChange={(event) => {
                                setFieldValue(
                                  deliveryFormFields.status,
                                  event.target?.value
                                );
                              }}
                              renderValue={() => {
                                return (
                                  values?.[deliveryFormFields.status]?.name ||
                                  lang.select
                                );
                              }}
                              className={classes.dropdown}
                              label="Select"
                              placeholder="Select"
                              displayEmpty
                            >
                              <MenuItem value="">{lang.select}</MenuItem>
                              {deliveryDetailsDropDowns?.packagingStatus?.map(
                                (mode) => {
                                  let disable = false;
                                  let selectedStatus =
                                    deliveryData?.[deliveryFormFields.status];

                                  disable =
                                    deliveryStatusOrder[selectedStatus] >=
                                    deliveryStatusOrder[mode.name];

                                  return (
                                    <MenuItem
                                      value={mode}
                                      key={mode?.id}
                                      disabled={disable}
                                    >
                                      {mode?.name}
                                    </MenuItem>
                                  );
                                }
                              )}
                            </Select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={classes.remarksInputContainer}>
                      <div className={classes.leftContainer}>
                        <Typography className={classes.containerLabel}>
                          {lang.remarks}
                        </Typography>
                      </div>
                      <div className={classes.remarksTextInputContainer}>
                        <FormTextInput
                          variant="standard"
                          name={deliveryFormFields.remarks}
                          type="text"
                          multiline
                          placeholder="Enter remarks"
                          typeOf="auth"
                          className={classes.remarksTextInput}
                          InputProps={{
                            disableUnderline: true,
                            style: {
                              height: "50px",
                              border: "none",
                            },
                          }}
                        />
                      </div>
                    </div>
                  </>
                  <div className={classes.bottomContainer}>
                    <div className={classes.buttonContainer}>
                      <Button
                        onClick={() => {
                          setConfirmationPopupState({
                            ...confirmationPopupState,
                            openCancelConfirmationPopup: true,
                          });
                        }}
                      >
                        {lang.cancel?.toUpperCase()}
                      </Button>
                      <Button
                        className={classes.marginLeftTwenty}
                        onClick={() => {
                          setConfirmationPopupState({
                            ...confirmationPopupState,
                            openSaveConfirmationPopup: true,
                          });
                        }}
                      >
                        {lang.saveChanges}
                      </Button>
                    </div>
                    {!!deliveryData?.deliveryRemarks?.length && (
                      <Card className={classes.card}>
                        <Typography className={classes.box1}>
                          {lang.previousRemarks}
                        </Typography>
                        <div className={classes.remarksContainer}>
                          {deliveryData?.deliveryRemarks?.map(
                            (remark, index) => (
                              <div className={classes.remarksRow}>
                                {`${index + 1}. ${remark?.remark}`}
                              </div>
                            )
                          )}
                        </div>
                      </Card>
                    )}
                  </div>

                  {confirmationPopupState?.openCancelConfirmationPopup && (
                    <Dialog
                      open={confirmationPopupState?.openCancelConfirmationPopup}
                      onClose={() => {}}
                    >
                      <ConfirmDialog
                        handleCloseConfirmDialog={() => {
                          setConfirmationPopupState({
                            ...confirmationPopupState,
                            openCancelConfirmationPopup: false,
                          });
                        }}
                        handleGoBackToAllDeliveries={() => {
                          history.goBack();
                        }}
                        confirmationMessage={lang.closeConfirmationMessage}
                      />
                    </Dialog>
                  )}
                  {confirmationPopupState?.openSaveConfirmationPopup && (
                    <Dialog
                      open={confirmationPopupState?.openSaveConfirmationPopup}
                      onClose={() => {}}
                    >
                      <ConfirmDialog
                        handleCloseConfirmDialog={() => {
                          setConfirmationPopupState({
                            ...confirmationPopupState,
                            openSaveConfirmationPopup: false,
                          });
                        }}
                        handleGoBackToAllDeliveries={() => {
                          saveDeliveryInformation(values);
                        }}
                        confirmationMessage={
                          lang.deliveryUpdateConfirmationMessage
                        }
                      />
                    </Dialog>
                  )}
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    loading: state.allPatientsReducers.loading,
  };
}

export default connect(mapStateToProps)(withStyles()(DeliveryInformationForm));
