import moment from "moment";
import { reportTypesId } from "pages/CreateReport/constants";
import { connect } from "react-redux";
import { addDaysToDate } from "utils/dateUtils";
import { useStyles } from "../style";
import { Box } from "@material-ui/core";

const PatientInfoTable = (props) => {
  const clasess = useStyles();

  const { patientInfo, patientProgressData } = props;

  const sensorActivationDate = patientProgressData?.sensor?.activationDate;

  let date = "";

  if (sensorActivationDate) {
    if (props.activeFileType === reportTypesId.seventhDayReport) {
      date = `${moment(sensorActivationDate).format("Do MMM")} - ${moment(
        addDaysToDate(new Date(sensorActivationDate), 7)
      ).format("Do MMM")}`;
    } else {
      date = `${moment(sensorActivationDate).format("Do MMM")} - ${moment(
        addDaysToDate(new Date(sensorActivationDate), 14)
      ).format("Do MMM")}`;
    }
  }

  return (
    <Box className={clasess.patientInfoTable}>
      <table style={{ width: "100%" }}>
        <tbody>
          <tr>
            <td className={clasess.patientInfoTableCell}>
              <div className={clasess.patientInfoHeading}>
                <div className={clasess.patientInfoCategory}>Patient Name:</div>
                <div>{patientInfo.patientName}</div>
              </div>
            </td>
            <td className={clasess.patientInfoTableCell}>
              <div className={clasess.patientInfoHeading}>
                <div className={clasess.patientInfoCategory}>Age:</div>
                <div>{patientInfo.age}</div>
              </div>
            </td>
          </tr>
          <tr>
            <td className={clasess.patientInfoTableCell}>
              <div className={clasess.patientInfoHeading}>
                <div className={clasess.patientInfoCategory}>Coach Name:</div>
                <div>{patientInfo.coachName}</div>
              </div>
            </td>
            <td className={clasess.patientInfoTableCell}>
              <div className={clasess.patientInfoHeading}>
                <div className={clasess.patientInfoCategory}>BMI:</div>
                <div>{` ${patientInfo.bmi ?? "--"} kg/m2`}</div>
              </div>
            </td>
          </tr>
          <tr>
            <td className={clasess.patientInfoTableCell}>
              <div className={clasess.patientInfoHeading}>
                <div className={clasess.patientInfoCategory}>Doctor Name:</div>
                <div>{patientInfo.doctorName}</div>
              </div>
            </td>
            <td className={clasess.patientInfoTableCell}>
              <div className={clasess.patientInfoHeading}>
                <div className={clasess.patientInfoCategory}>HbA1c:</div>
                <div>{` ${patientInfo.hba1c ?? "--"} %`}</div>
              </div>
            </td>
          </tr>
          <tr>
            <td className={clasess.patientInfoTableCell}>
              <div className={clasess.patientInfoHeading}>
                <div className={clasess.patientInfoCategory}>Clinic Name:</div>
                <div>{patientInfo.clinicName}</div>
              </div>
            </td>
            <td className={clasess.patientInfoTableCell}>
              <div className={clasess.patientInfoHeading}>
                <div className={clasess.patientInfoCategory}>Dt. Period:</div>
                <div>{date}</div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    patientInfo: state.createReportReducer.patientInfo,
    patientProgressData: state.createReportReducer.patientProgress,
    activeFileType: state.createReportReducer.activeFileType,
  };
}
export default connect(mapStateToProps)(PatientInfoTable);
