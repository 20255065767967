import React from "react";
import { Box, Divider, Typography } from "@material-ui/core";

import Flex from "@components/atoms/Flex";
import Back from "assets/icons/back-arrow.svg";
import CustomButtons from "@components/atoms/Button";

export const ModuleDrawerHeader = ({ heading, onBack, classes, onSave }) => {
  return (
    <>
      <Flex padding="12px 16px" alignItems="center">
        <Box style={{ marginRight: "1.4rem" }} onClick={onBack}>
          <img width={15} height={12} src={Back} alt="arrow-icon" />
        </Box>

        <Typography className={classes.details}>Details</Typography>
        <Box style={{ marginLeft: "auto" }}>
          <CustomButtons md style={{ fontSize: "14px" }} onClick={onSave}>
            Save &amp; Add
          </CustomButtons>
        </Box>
      </Flex>
      <Divider />
      <div
        style={{
          padding: "16px 15px",
        }}
      >
        <Typography className={classes.heading}>{heading}</Typography>
      </div>
      <Divider />
    </>
  );
};
