const { makeStyles } = require("@material-ui/core");

export const discountUpdateStyle = makeStyles((theme) => ({
  headingText: {
    fontSize: "18px",
    fontWeight: 500,
    marginBottom: "10px",
    marginTop: "30px",
  },
  submit: {
    marginTop: "16px",
    width: "150px",
  },
}));
