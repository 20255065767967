
import React from "react";

const MetasenseReportPreview = () => {

  return (
    <>
      <div>
        <iframe
          title="Metasense Report"
          id="metasensePreview"
          src="/coach/metasenseReport"
          style={{ WebkitPrintColorAdjust: "exact", display: "none" }}
        />
        Download
      </div>
    </>
  );
};

export default MetasenseReportPreview;
