const reportTableStyle = () => ({
  table: {
    border: "1px solid #c3c3c3",
    borderSpacing: "0px",
    borderRight: "0px",
    borderBottom: "0px",
    textAlign: "center",
    tableLayout: "fixed",
    width: "100%",
    overflow: "scroll",
    backgroundColor: "white",
    borderCollapse: "collapse",
  },
  tableHeading: {
    borderBottom: "1px solid #c3c3c3",
    padding: "0px",
    borderRight: "1px solid #c3c3c3",
  },
  tableRow: {
    borderBottom: "1px solid #c3c3c3",
    borderRight: "1px solid #c3c3c3",
    whiteSpace: "normal",
  },
  tableContainer: {
    maxHeight: "auto",
    width: "100%",
  },
  tableCell: {
    overflowWrap: "break-word",
    padding: "0.2rem",
  },
  bottomSelect: {
    borderTop: "1px solid #c3c3c3",
    overflowWrap: "break-word",
    padding: "0.2rem",
  },
  rowImage: {
    width: "24px",
    height: "32px",
    marginRight: "8px",
  },
  tableDataText: {
    overflowWrap: "break-word",
  },
});

export default reportTableStyle;
