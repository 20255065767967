import { Box, Typography } from "@material-ui/core";
import React from "react";

export const DietPlan = ({ classes, src, srcDownload, downloadLink }) => {
  return (
    <>
      <Box className={classes.dietWrapper}>
        <img src={src} />
        {Boolean(downloadLink) ? (
          <a
            style={{ textDecoration: "none" }}
            href={downloadLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Box className={classes.dietSubWrapper}>
              <img src={srcDownload} />
              <Typography className={classes.textDiet}>
                Download to view Diet Plan
              </Typography>
            </Box>
          </a>
        ) : (
          <Box
            className={classes.dietSubWrapper}
            style={{ cursor: "default", opacity: 0.75 }}
          >
            <img src={srcDownload} />
            <Typography className={classes.textDiet}>
              Download to view Diet Plan (Unavailable!)
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};
