const { makeStyles } = require("@material-ui/core");

export const homeStyles = makeStyles((theme) => ({
  title: {
    fontSize: "24px",
    fontWeight: "500",
    marginBottom: "8px",
  },
  tabContainer: {
    height: "48px",
    alignItems: "center",
    marginTop: "10px",
    width: "100%",
  },
  radioLableText: {
    color: "#595959",
  },
  root: {
    height: "100%",
    borderBottom: "1px solid #ccc",
    "& .MuiTabs-flexContainer": {
      "& .MuiTab-root": {
        minHeight: "45px",
        minWidth: "100px",
      },
      "& .MuiButtonBase-root.Mui-selected": {
        color: "#2B367D",
      },
    },
  },
}));
