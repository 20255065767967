import { useStyles } from "./style";

export const Table = ({ tableData }) => {
  const classes = useStyles();
  const column = Object.keys(tableData[0]);

  const renderHeading = () => {
    return column.map((data) => {
      return (
        <th className={classes.tableHeading} key={data}>
          {data}
        </th>
      );
    });
  };

  const renderRow = () => {
    return tableData.map((data, index) => {
      return (
        <tr key={index}>
          {column.map((columnName) => {
            return (
              <td className={classes.tableRow} key={data[columnName]}>
                <div className={classes.tableCell}>{data[columnName]}</div>
              </td>
            );
          })}
        </tr>
      );
    });
  };

  return (
    <div className={classes.tableContainer}>
      <table className={classes.table}>
        <thead>
          <tr>{renderHeading()}</tr>
        </thead>
        <tbody>{renderRow()}</tbody>
      </table>
    </div>
  );
};
