/**
 @desc
 * Centralized unique action types for Login actions and reducers.
 */

import {
  actionCreator,
  checkHttpStatus,
  createRequestActionTypes,
  jsonApiHeader,
  showSuccessMessage,
  showErrorMessage,
} from "../../../utils/utility";

export {
  jsonApiHeader,
  actionCreator,
  checkHttpStatus,
  showSuccessMessage,
  showErrorMessage,
};

export const CreateReportActions = {
  get_AllPatients: createRequestActionTypes("GET_ALL_PATIENTS"),
  update_report: "UPDATE_REPORTS",
  update_report_summary: "UPDATE_REPORT_SUMMARY",
  set_graph_data: "SET_GRAPH_DATA",
  set_active_report_id: "SET_ACTIVE_REPORT_ID",
  set_active_report_name: "SET_ACTIVE_REPORT_NAME",
  update_report_progress: "UPDATE_REPORT_progress",
  get_report_progress_draft: createRequestActionTypes(
    "GET_REPORT_PROGRESS_DRAFT"
  ),
  post_report_data: createRequestActionTypes("POST_REPORT_DATA"),
  get_report_data: createRequestActionTypes("GET_REPORT_DATA"),
  set_report_data: "SET_REPORT_DATA",
  set_fileTypeSelected: "SET_FILETYPESELECTED",
  update_patient_info: "UPDTAE_PATIENT_INFO",
  get_patient_info_draft: createRequestActionTypes("GET_PATIENT_INFO_DRAFT"),
  set_day_wise_CGM_data: "SET_DAY_WISE_CGM_DATA",
};

export const columnHeadingWithoutTableName = [
  "nocturnal",
  "morning",
  "afternoon",
  "evening",
  "overall",
];

export const dayParts = [
  "morning",
  "afternoon",
  "evening",
  "nocturnal",
  "overall",
];

export const rowNames = {
  diet: "Diet",
  sleep: "Sleep",
  data: "Data Insight",
  physicalActivity: "Physical Activity",
  stress: "Stress",
};
export const insightRowIndex = {
  data: 0,
  diet: 1,
  sleep: 2,
  physicalActivity: 3,
  stress: 4,
};
export const interventionRowIndex = {
  diet: 0,
  sleep: 1,
  physicalActivity: 2,
  stress: 3,
};

export const dailyPatternTypes = {
  insight: "insight",
  intervention: "intervention",
};
export const reportType = {
  fourteenthDayReport: "fourteenthDayReport",
  seventhDayReportData: "seventhDayReportData",
};
export const insightRowHeadingObj = {
  0: "data",
  1: "diet",
  2: "sleep",
  3: "physicalActivity",
  4: "stress",
};

export const interventionRowHeadingObj = {
  0: "diet",
  1: "sleep",
  2: "physicalActivity",
  3: "stress",
};

export const rowHeadingInsightText = [
  "Data Insight",
  "Diet Insight",
  "Sleep Insight",
  "Physical Activity Insight",
  "Stress Insight",
];

export const rowHeadingInterventionText = [
  "Diet Intervention",
  "Sleep Intervention",
  "Physical Activity Intervention",
  "Stress Intervention",
];

export const reportTypeForApi = {
  seventhDay: "seventhDay",
  overall: "overall",
};

export const seventhDayInterventionColHeading = [
  "7th Day Intervention",
  "Nocturnal (00:00 - 06:00)",
  "Morning (06:01 - 12:00)",
  "Afternoon (12:01 - 18:00)",
  "Evening (18:01 - 23:59)",
  "Overall",
];

export const seventhDayInsightColHeading = [
  "7th Day Insight",
  "Nocturnal (00:00 - 06:00)",
  "Morning (06:01 - 12:00)",
  "Afternoon (12:01 - 18:00)",
  "Evening (18:01 - 23:59)",
  "Overall",
];

export const fourteenthDayInsightColHeading = [
  "14th Day Insight",
  "Nocturnal (00:00 - 06:00)",
  "Morning (06:01 - 12:00)",
  "Afternoon (12:01 - 18:00)",
  "Evening (18:01 - 23:59)",
  "Overall",
];

export const reportTypesId = {
  seventhDayReport: 7,
  fourteenthDayReport: 14,
};

export const innerRowIndexing = {
  1: "type",
  2: "text",
  3: "customMessage",
};

export const tableTypes = {
  intervention: "intervention",
  insight: "insight",
};

export const graphWidth = 750;
export const graphHeight = 235;

export const assessmentKeys = {
  good: "good",
  average: "average",
  bad: "bad",
};

export const reportMessageTypes = {
  assessment: 1,
  message: 2,
  customMessage: 3,
};

export const reportTabIndxes = {
  patientInfo: 0,
  dailyPattern: 1,
  patientProgress: 2,
  summary: 3,
  preview: 4,
  download: 5,
};
export const insightLength = 5;
export const maxLineChars = 20;

export const interventionLength = 4;

export const customMessageText = "Custom message";

export const initialReportData = {
  rowName: null,
  nocturnal: null,
  morning: null,
  afternoon: null,
  evening: null,
  overall: null,
};

export const goals = [
  "Weight loss",
  "Control Blood Sugar",
  "Medicine reduction",
];
