import React from "react";
import { Divider, Typography, withStyles } from "@material-ui/core";
import lang from "assets/languages";
import Button from "@components/atoms/Button";
import basicDetailsConfirmPopupStyle from "./basicDetailsConfirmPopupStyle";
import { showErrorMessage } from "utils/utility";

const BasicDetailsConfirmPopup = ({
  classes,
  handleYesConfirmDialog,
  handleCloseConfirmDialog,
  clinicsData,
  doctors,
  values,
  patientName,
}) => {
  let clinicName = clinicsData?.filter((clinic) => {
    return clinic.value === values?.clinicId;
  });

  let doctorName = doctors?.filter((doctor) => {
    return doctor.value === values?.doctor;
  });

  if (!doctorName) {
    showErrorMessage("Please add doctor");
    return;
  }

  return (
    <div className={classes.mainContainer}>
      <div className={classes.dialogTextBox}>
        <Typography>{lang.basicDetailsConfirmationMessage}</Typography>
      </div>
      <div className={classes.textContainer}>
        <Typography className={classes.dialogText}>
          {`${lang.patientName} - ${patientName}`}
        </Typography>
        <Typography className={classes.dialogText}>
          {`${lang.clinicName}- ${clinicName[0]?.label}`}
        </Typography>
        <Typography className={classes.dialogText}>
          {`${lang.doctorName} - ${doctorName[0]?.label}`}
        </Typography>
      </div>

      <Divider />
      <div className={classes.buttonsBox}>
        <Button md_border onClick={handleCloseConfirmDialog}>
          {lang.no}
        </Button>
        <Button
          md
          onClick={handleYesConfirmDialog}
          className={classes.buttonYes}
        >
          {lang.yes}
        </Button>
      </div>
    </div>
  );
};

export default withStyles(basicDetailsConfirmPopupStyle)(
  BasicDetailsConfirmPopup
);
