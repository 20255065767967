import { Box, Typography } from "@material-ui/core";
import React from "react";
import Play from "../../../assets/icons/play-icon.svg";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  videoContainer: {
    padding: "0.5rem",
    cursor: "pointer",
  },
  videoSubContainer: {
    padding: "1rem 0.5rem",
  },
  videoText: {
    fontWeight: "bold",
    fontSize: "14px",
    color: "#595959",
  },
  videoSubText: {
    fontWeight: "normal",
    fontSize: "12px",
    color: "#595959",
    marginTop: "0.5rem",
  },
  thumbnail: {
    position: "relative",
    overflow: "hidden",
    height: "6rem",
    "& img": {
      backgroundColor: "#000",
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    "&::after": {
      content: "''",
      position: "absolute",
      inset: 0,
      backgroundImage: `url(${Play})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "auto",
      backgroundPosition: "center",
      zIndex: 1,
    },
  },
});

export const VideoCard = ({
  classes,
  videoLink,
  thumbnail,
  title,
  schedule,
  duration,
}) => {
  const styles = useStyles();
  return (
    <>
      <Box
        className={styles.videoContainer}
        onClick={() => window.open(videoLink, "_blank")}
      >
        <div className={styles.thumbnail}>
          <img src={thumbnail} alt="" loading="lazy" />
        </div>
        <Box className={styles.videoSubContainer}>
          <Typography className={styles.videoText}>{title}</Typography>
          <Typography className={styles.videoSubText}>{schedule}</Typography>
          <Typography className={styles.videoSubText}>{duration}</Typography>
        </Box>
      </Box>
    </>
  );
};
