import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  table: {
    border: "1px solid #c3c3c3",
    borderSpacing: "0",
    borderRight: "0px",
    borderBottom: "0px",
    textAlign: "center",
    tableLayout: "fixed",
    width: "100%",
    overflow: "scroll",
    backgroundColor: "white",
  },
  tableHeading: {
    borderBottom: "1px solid #c3c3c3",
    padding: "0.5rem",
    borderRight: "1px solid #c3c3c3",
  },
  tableRow: {
    borderBottom: "1px solid #c3c3c3",
    borderRight: "1px solid #c3c3c3",
    whiteSpace: "normal",
  },
  tableContainer: {
    maxHeight: "auto",
    width: "100%",
  },
  tableCell: {
    overflowWrap: "break-word",
    padding: "0.2rem",
  },
  bottomSelect: {
    borderTop: "1px solid #c3c3c3",
    overflowWrap: "break-word",
    padding: "0.2rem",
  },
}));
