import React from "react";
import { Box, Typography, withStyles } from "@material-ui/core";
import blogStyle from "../../../../assets/jss/pages/components/blogStyle";
import CloseIcon from "@material-ui/icons/Close";
import Back from "../../../../assets/icons/back-arrow.svg";
import Blog from "../../../../assets/images/blog-card.png";
import Avatar from "../../../../assets/images/avatar.png";
import ReactPlayer from "react-player";

const VideoDrawer = ({
  classes,
  handleCloseDrawer,
  handleDetails,
  openBlog,
}) => {
  return (
    <>
      <div className={classes.headerContainer2}>
        <Box className={classes.container2}>
          <img
            className={classes.icon2}
            type="button"
            onClick={handleDetails}
            src={Back}
          />
          <Typography style={{ fontSize: "16px", fontWeight: 500 }}>
            Details
          </Typography>
        </Box>

        <CloseIcon className={classes.icon} onClick={handleCloseDrawer} />
      </div>
      <ReactPlayer
        width="100%"
        height="200px"
        url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
        playing={true}
      />
    </>
  );
};
export default VideoDrawer;
