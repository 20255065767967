import React from "react";
import { useField } from "formik";
import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
} from "@material-ui/core";
// import ExpandMoreSharpIcon from "@material-ui/icons/ExpandMoreSharp";

import { useStyles } from "./FormSelectField.style";

const FormSelectField = ({
  name,
  options,
  label,
  placeholder,
  type,
  typeOf,
  disabled,
  ...otherProps
}) => {
  const classes = useStyles();
  const [field, meta, { setValue }] = useField(name);
  const disable = { disabled: disabled ? disabled : null };
  const configSelect = {
    ...field,
    ...otherProps,
    label,
    variant: "outlined",
    fullWidth: true,
    displayEmpty: true,
    type,
    typeOf,
    onChange: (e) => {
      const { value } = e.target;
      setValue(value === "" ? null : value);
      if (otherProps.onChange) {
        otherProps.onChange(value === "" ? null : value);
      }
    },
  };

  if (meta && meta.touched && meta.error) {
    configSelect.error = true;
    configSelect.helperText = meta.error;
  }
  return (
    <>
      <FormControl error={configSelect.error} fullWidth>
        <Select
          {...field}
          {...configSelect}
          className={classes.root}
          {...disable}
          style={{ width: typeOf === "auth" ? "100%" : "150px" }}
        >
          <MenuItem value="">
            <em>{placeholder || label || ""}</em>
          </MenuItem>

          {type === "patient"
            ? Array.isArray(options) &&
              options.map((item, pos) => {
                return (
                  <MenuItem key={pos} value={item.value || item}>
                    {item.label || item?.name || item.productName || item}
                  </MenuItem>
                );
              })
            : Object.keys(options).map((item, pos) => {
                return (
                  <MenuItem key={pos} value={item}>
                    {options[item]}
                  </MenuItem>
                );
              })}
        </Select>
        <FormHelperText>{configSelect.helperText}</FormHelperText>
      </FormControl>
    </>
  );
};

export default FormSelectField;
