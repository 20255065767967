import { AddPatientActionTypes } from "../constants";

const initialState = {
  loading: false,
  addPatient: null,
  allClinics: null,
  updatedPatient: null,
  doctorCoachDetailsByClinic: null,
  addOnAndOffer: null,
};

const addPatientReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case AddPatientActionTypes.post_AddPatient.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case AddPatientActionTypes.post_AddPatient.SUCCESS:
      return {
        ...state,
        loading: false,
        addPatient: payload,
      };
    case AddPatientActionTypes.post_AddPatient.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case AddPatientActionTypes.get_AllClinics.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case AddPatientActionTypes.get_AllClinics.SUCCESS:
      return {
        ...state,
        loading: false,
        allClinics: payload,
      };
    case AddPatientActionTypes.get_AllClinics.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case AddPatientActionTypes.update_Patient.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case AddPatientActionTypes.update_Patient.SUCCESS:
      return {
        ...state,
        loading: false,
        updatedPatient: payload,
      };
    case AddPatientActionTypes.update_Patient.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case AddPatientActionTypes.post_UploadClinicalData.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case AddPatientActionTypes.post_UploadClinicalData.SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case AddPatientActionTypes.post_UploadClinicalData.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case AddPatientActionTypes.post_UploadCoachAssessmentData.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case AddPatientActionTypes.post_UploadCoachAssessmentData.SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case AddPatientActionTypes.post_UploadCoachAssessmentData.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case AddPatientActionTypes.get_DoctorCoachByClinic.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case AddPatientActionTypes.get_DoctorCoachByClinic.SUCCESS:
      return {
        ...state,
        loading: false,
        doctorCoachDetailsByClinic: payload,
      };
    case AddPatientActionTypes.get_DoctorCoachByClinic.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case AddPatientActionTypes.post_UploadGoalData.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case AddPatientActionTypes.post_UploadGoalData.SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case AddPatientActionTypes.post_UploadGoalData.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case AddPatientActionTypes.get_AddOnAndOffers.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case AddPatientActionTypes.get_AddOnAndOffers.SUCCESS:
      return {
        ...state,
        loading: false,
        addOnAndOffer: payload,
      };
    case AddPatientActionTypes.get_AddOnAndOffers.FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export default addPatientReducers;
