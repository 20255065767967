const authLayoutStyle = theme => ({
  form: {
    height: '100%',
    overflow: "hidden"
  },
  mainContainer: {
    height: '100vh',
    display: 'flex',
    overflow: "auto",
    justifyContent: "center",
    alignItems: "center"
  },
  card: {
    width: "450px",
    height: "500px"
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '50px'
  },
  button: {
    marginTop: '40px'
  },
  container: {
    height: '100%',
    padding: "12px",
    display: 'flex',
    justifyContent: "center",
    alignItems: "start"
  },
  logo_container: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '30px'
  },
  button_mobile: {
    marginTop: '20px'
  }
})

export default authLayoutStyle;