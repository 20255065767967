import { EditAppointmentActionTypes } from "../constants";

const initialState = {
  loading: false,
  updatedAppointment: null,
};

const editAppointmentReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case EditAppointmentActionTypes.update_Appointment.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EditAppointmentActionTypes.update_Appointment.SUCCESS:
      return {
        ...state,
        loading: false,
        updatedAppointment: payload,
      };
    case EditAppointmentActionTypes.update_Appointment.FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default editAppointmentReducers;
