import { Typography, withStyles } from "@material-ui/core";
import React from "react";
import plusIcon from "../../../../assets/icons/plus.svg";
import exercise_icon from "../../../../assets/icons/exercise.svg";
import glucometer_icon from "../../../../assets/icons/glucometer.svg";
import weight_icon from "../../../../assets/icons/bmi.svg";
import sleep_icon from "../../../../assets/icons/sleep.svg";
import walking_icon from "../../../../assets/icons/walking.svg";
import diet_icon from "../../../../assets/icons/diet.svg";
import hba1c_icon from "../../../../assets/icons/hbA1c.svg";

import CloseIcon from "@material-ui/icons/Close";
import weightProgressDrawerStyle from "assets/jss/pages/components/weightProgressDrawerStyle";

const dataTypes = [
  {
    id: 1,
    name: "Blood Sugar",
    path: "addHealthData/bloodsugar",
    icon: glucometer_icon,
  },
  {
    id: 2,
    name: "Weight",
    path: "addHealthData/weight",
    icon: weight_icon,
  },
  {
    id: 3,
    name: "Diet",
    path: "addHealthData/diet",
    icon: diet_icon,
  },
  {
    id: 4,
    name: "Physical Activity",
    path: "addHealthData/physical",
    icon: exercise_icon,
  },
  {
    id: 5,
    name: "Sleep",
    path: "addHealthData/sleep",
    icon: sleep_icon,
  },
  {
    id: 6,
    name: "Steps",
    path: "addHealthData/steps",
    icon: walking_icon,
  },
  {
    id: 7,
    name: "HbA1c Test",
    path: "addHealthData/hba1c",
    icon: hba1c_icon,
  },
];

const AddHealthData = (props) => {
  const { classes } = props;
  return (
    <>
      <div className={classes?.headerContainer}>
        <Typography
          className={classes?.headerText}
          onClick={props.handleDetails}
        >
          Health Data
        </Typography>
        <CloseIcon
          className={classes?.icon}
          onClick={props.handleCloseDrawer}
        />
      </div>
      <div style={{ margin: "8px 16px", borderTop: "1px solid #ccc" }}>
        {dataTypes.length > 0 &&
          dataTypes.map((data) => {
            return (
              <div
                key={data.id}
                style={{
                  padding: "10px 0px",
                  borderBottom: "1px solid #ccc",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                onClick={() => props.openDrawer(data.path, "","Add_Data","" )}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img src={data.icon} alt={`${data.name}`} />
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: 500,
                      color: "#000000",
                      marginLeft: "1rem",
                    }}
                  >
                    {data.name}
                  </Typography>
                </div>
                <img src={plusIcon} alt="plusIcon" />
              </div>
            );
          })}
      </div>
    </>
  );
};

export default withStyles(weightProgressDrawerStyle)(AddHealthData);
