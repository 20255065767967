import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  withStyles,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { checkedIconFilled } from "assets/icons";
import { useEffect, useState } from "react";
import accordionContainerStyle from "./accordionContainerStyle";

const AccordionContainer = ({
  children,
  title,
  classes,
  expandAccordion = false,
  showCompletionIcon = false,
}) => {
  const [expand, setExpand] = useState(false);
  const toggleAcordion = () => {
    setExpand((prev) => !prev);
  };

  useEffect(() => {
    setExpand(expandAccordion);
  }, [expandAccordion]);

  return (
    <>
      <Accordion expanded={expand}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.accordionBar}
          onClick={toggleAcordion}
        >
          <div></div>
          <div className={classes.accordionBarContent}>
            <div>
              <Typography className={classes.accordionTitle}>
                {title.toUpperCase()}
              </Typography>
            </div>
            {showCompletionIcon && (
              <div>
                <img src={checkedIconFilled} alt="star" color="white" />
              </div>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </>
  );
};

export default withStyles(accordionContainerStyle)(AccordionContainer);
