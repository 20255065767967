const doctorInstructionStyle = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    maxHeight: "400px",
    minHeight: "400px",
    padding: 16,
    position: "relative",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    overflowX: "hidden",
    marginBottom: 16,
  },
  instructionContainer: {
    flex: 1,
    display: "flex",
    width: "95%",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 16,
    border: "1px solid #D9D9D9",
    marginHorizontal: 16,
  },
  sentFor: {
    width: "70%",
  },
  sentDate: {
    color: "#262626",
    fontWeight: 500,
    fontSize: "12px",
  },
  instruction: {
    color: "#8C8C8C",
    fontWeight: 500,
    fontSize: "14px",
  },
  viewDetails: {
    color: "#2B377E",
    fontWeight: 500,
    fontSize: "14px",
  },
  pagination: {
    position: "absolute",
    bottom: 0,
    width: "auto",
    alignSelf: "center",
  },
  drawerContainer: {
    backgroundColor: "#FFFFF",
  },
  drawerHeading: {
    padding: "15px 22px",
    display: "flex",
    backgroundColor: "#FAFAFA",
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottom: "1px solid #D9D9D9",
  },
  drawerContent: {
    padding: 16,
  },
  drawerInstruction: {
    marginTop: 16,
    padding: 8,
    color: "#595959",
    border: "1px solid #D9D9D9",
    borderRadius: "5px",
    fontWeight: 500,
    fontSize: "14px",
  },
  drawerDocInstruction: {
    color: "#262626",
    fontWeight: 500,
    fontSize: "16px",
  },
  drawerSentDate: {
    color: "#8C8C8C",
    fontWeight: 500,
    fontSize: "14px",
  },
});

export default doctorInstructionStyle;
