import React from "react";
// Undo and redo functions for Custom Toolbar

// Modules object for setting up the Quill editor
export const modules = {
  toolbar: {
    container: "#toolbar"
  }
};

// Formats objects for setting up the Quill editor
export const formats = ["bold", "italic", "underline", "strike", "list"];

// Quill Toolbar component
export const EditorToolbar = ({ handleSaveNote }) => (
  <div id="toolbar">
    <span className="ql-formats">
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      <button className="ql-strike" />
      <span className="ql-formats">
        <button className="ql-list" value="ordered" />
      </span>
    </span>
    <button
      onClick={handleSaveNote}
      style={{
        width: "18%",
        background: "#2B367D",
        borderRadius: "4px",
        textTransform: "none",
        color: "white",
        "&:hover": {
          background: "#2B367D"
        },
      }}>Save</button>
  </div>
);

export default EditorToolbar;
