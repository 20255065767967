import React from "react";
// import clsx from "clsx";
// import MySidebar from "@components/organisms/Sidebar/Sidebar";
import { Switch } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { counsellorRoutes } from "../../routers/RolebasedRoutes/counsellorRoutes";
import { initRoutes } from "../../routers/RolebasedRoutes/counsellorRoutes";
import PrivateRoute from "../../routers/PrivateRoutes/PrivateRoute";
import counsellorLayoutStyle from "assets/jss/material-dashboard-pro-react/layouts/counsellorLayoutStyle";
import MyAppBar from "@components/organisms/AppBar";
import { userRoles } from "utils/constants";

const CounsellorLayout = () => {
  const role = useSelector(
    (state) => state.loginReducer.loggedInUserDetails.data.role
  );

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.views) {
        return getRoutes(prop.views);
      }
      if (prop.subViews) {
        return getRoutes(prop.subViews);
      }
      if (prop.layout === "/counselor") {
        return (
          <PrivateRoute
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            user={role}
          />
        );
      } else {
        return null;
      }
    });
  };

  const defineRole = (role) => {
    if (role.toUpperCase() === userRoles.councellor) {
      return getRoutes(counsellorRoutes);
    } else {
      return;
    }
  };

  const defineInitRoutes = (role) => {
    if (role.toUpperCase() === userRoles.councellor) {
      return getRoutes(initRoutes);
    } else {
      return;
    }
  };

  return (
    <>
      <MyAppBar />
      <main style={{ maxWidth: "600px", margin: "16px auto" }}>
        <Switch>
          {defineInitRoutes(role)}
          {defineRole(role)}
        </Switch>
      </main>
    </>
  );
};

export default withStyles(counsellorLayoutStyle)(CounsellorLayout);
