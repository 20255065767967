import lang from "assets/languages";

export const createMetricRows = (patientMetricSummary) => {
  function createRow(name, nocturnal, morning, afternoon, evening, overall) {
    return { name, nocturnal, morning, afternoon, evening, overall };
  }

  const nocturnal = patientMetricSummary?.Nocturnal;
  const morning = patientMetricSummary?.Morning;
  const afternoon = patientMetricSummary?.Afternoon;
  const evening = patientMetricSummary?.Evening;
  const overall = patientMetricSummary?.Overall;

  const rows = [
    createRow(
      lang.averageGlucose,
      nocturnal?.averageBloodGlucose
        ? nocturnal?.averageBloodGlucose + ` ${lang.mgdl}`
        : "-",
      morning?.averageBloodGlucose
        ? morning?.averageBloodGlucose + ` ${lang.mgdl}`
        : "-",
      afternoon?.averageBloodGlucose
        ? afternoon?.averageBloodGlucose + ` ${lang.mgdl}`
        : "-",
      evening?.averageBloodGlucose
        ? evening?.averageBloodGlucose + ` ${lang.mgdl}`
        : "-",
      overall?.averageBloodGlucose
        ? overall?.averageBloodGlucose + ` ${lang.mgdl}`
        : "-"
    ),
    createRow(
      lang.timeInRange,
      nocturnal?.TIRInPercentage ? nocturnal?.TIRInPercentage + " %" : "-",
      morning?.TIRInPercentage ? morning?.TIRInPercentage + " %" : "-",
      afternoon?.TIRInPercentage ? afternoon?.TIRInPercentage + " %" : "-",
      evening?.TIRInPercentage ? evening?.TIRInPercentage + " %" : "-",
      overall?.TIRInPercentage ? overall?.TIRInPercentage + " %" : "-"
    ),
    createRow(
      lang.timeAboveRange,
      nocturnal?.TARInPercentage ? nocturnal?.TARInPercentage + " %" : "-",
      morning?.TARInPercentage ? morning?.TARInPercentage + " %" : "-",
      afternoon?.TARInPercentage ? afternoon?.TARInPercentage + " %" : "-",
      evening?.TARInPercentage ? evening?.TARInPercentage + " %" : "-",
      overall?.TARInPercentage ? overall?.TARInPercentage + " %" : "-"
    ),
    createRow(
      lang.timeBelowRange,
      nocturnal?.TBRInPercentage ? nocturnal?.TBRInPercentage + " %" : "-",
      morning?.TBRInPercentage ? morning?.TBRInPercentage + " %" : "-",
      afternoon?.TBRInPercentage ? afternoon?.TBRInPercentage + " %" : "-",
      evening?.TBRInPercentage ? evening?.TBRInPercentage + " %" : "-",
      overall?.TBRInPercentage ? overall?.TBRInPercentage + " %" : "-"
    ),
  ];

  return rows;
};
