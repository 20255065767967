import React from "react";
import { Divider, Typography } from "@material-ui/core";
import CustomButtons from "@components/atoms/Button";

const ConfirmDialog = ({
  handleGoBackToAllPatients,
  handleCloseConfirmDialog,
}) => {
  return (
    <div style={{ width: "400px" }}>
      <div
        style={{ padding: "20px", display: "flex", justifyContent: "center" }}
      >
        <Typography style={{ fontSize: "16px", fontWeight: 500 }}>
          All your unsaved changes will be lost. Are you sure you want to leave
          this page?
        </Typography>
      </div>
      <Divider />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "10px 20px",
        }}
      >
        <CustomButtons md_border onClick={handleCloseConfirmDialog}>
          Cancel
        </CustomButtons>
        <CustomButtons
          md
          style={{ marginLeft: "0.5rem" }}
          onClick={handleGoBackToAllPatients}
        >
          Yes
        </CustomButtons>
      </div>
    </div>
  );
};

export default ConfirmDialog;
