const foodProgressDrawerStyle = (theme) => ({
  root: {
    height: "25px",
    width: "308px",
    margin: "16px",
    marginBottom: "0",
    "& .MuiTabs-flexContainer": {
      border: "1px solid #ccc",
      borderRadius: "4px",
      color: "#000000",
      "& .MuiTab-root": {
        minHeight: "25px",
        minWidth: "25%",
      },
      "& .MuiButtonBase-root": {
        textTransform: "capitalize",
      },
      "& .MuiButtonBase-root.Mui-selected": {
        background: "white",
        border: "1px solid #5BABCC",
        color: "#000000",
      },
      "& .MuiButtonBase-root:nth-of-type(1).Mui-selected": {
        borderTopLeftRadius: "4px",
        borderBottomLeftRadius: "4px",
      },
      "& .MuiButtonBase-root:nth-of-type().Mui-selected": {
        borderRadius: "0px",
      },
      "& .MuiButtonBase-root:nth-last-of-type(1).Mui-selected": {
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
      },
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
  },
  container: {
    height: "100%",
    padding: "16px",
  },
  food_container: {
    height: "144px",
    backgroundColor: "#389E0D",
    padding: "5px",
    borderRadius: "8px",
    marginTop: "0.5rem",
    margin: "16px",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: "64px",
    maxHeight: "64px",
    borderBottom: "1px solid #ccc",
    padding: "0px 16px",
    position: "sticky",
    zIndex: 1000,
    top: 0,
    backgroundColor: "white",
  },
  icon: {
    cursor: "pointer",
  },
  mainContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
    borderTop: "1px solid #ccc",
    borderBottom: "1px solid #ccc",
  },
  subContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
    borderBottom: "1px solid #ccc",
  },
  text: {
    fontSize: "16px",
    fontWeight: 500,
  },
  sub_text: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#8C8C8C",
  },
  chevron: {
    color: "#B3B3B3",
  },
  flex: {
    display: "flex",
  },
  noImage: {
    margin: "10px 5px",
    objectFit: "contain",
    color: "#595959",
    fontSize: "14px",
  },
  image: {
    margin: "0px 5px",
    objectFit: "cover",
  },
  imageContainer: {
    marginTop: "8px",
    marginLeft: "12px",
  },
  dietDateTime: {
    marginLeft: "12px",
    width: "180px",
    color: "#595959",
    fontSize: "14px",
    marginTop: "16px",
  },
  dietDescription: {
    marginLeft: "12px",
    fontSize: "13px",
    width: "310px",
    color: "#595959",
  },
  verticalTimelineAndCircle: {
    width: "12px",
    height: "12px",
    border: "4px solid #2B377E",
    borderRadius: "100%",
    backgroundColor: "#FAFAFA",
    position: "absolute",
    left: "8px",
  },
  verticalTimeline: {
    borderLeft: "4px solid #2B377E",
    marginLeft: "16px",
    backgroundColor: "#FAFAFA",
  },
});

export default foodProgressDrawerStyle;
