import React from "react";
import {
  withStyles,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import { toTitleCase } from "../../../../utils/stringUtils";
import ActivityListStyle from "../../../../assets/jss/pages/components/activityListStyle";
import lang from "../../../../assets/languages";
import { getIcon } from "./constant";

const ActivityList = (props) => {
  const { classes, handleClose, activities } = props;

  const renderDietFoodLog = (dietFoodLog) => {
    if (!dietFoodLog || (dietFoodLog && dietFoodLog.length <= 0)) {
      return null;
    }

    return (
      <List className={classes.foodLog} subheader={"Food Items:"}>
        {dietFoodLog.map((foodLog) => {
          const {
            name,
            quantity,
            weight,
            carbs,
            fat,
            protein,
            calories,
            fibre,
          } = foodLog;

          const foodDescription = `${
            quantity ? `quantity: ${Number(quantity).toFixed(0)}` : ""
          }, ${weight ? `weight: ${Number(weight).toFixed(1)}` : ""}, ${
            carbs ? `carbs: ${Number(carbs).toFixed(1)}` : ""
          }, ${fat ? `fat: ${Number(fat).toFixed(1)}` : ""}, ${
            protein ? `protein: ${Number(protein).toFixed(1)}` : ""
          }, ${calories ? `calories: ${Number(calories).toFixed(1)}` : ""}, ${
            fibre ? `fibre: ${Number(fibre).toFixed(1)}` : ""
          }`;

          return (
            <ListItem>
              <ListItemText primary={name} secondary={foodDescription} />
            </ListItem>
          );
        })}
      </List>
    );
  };

  const renderActivityDiscription = (activity) => {
    const type = activity?.type;
    let heading = null;
    let subHeading = null;
    switch (type) {
      case "diet":
        heading =
          activity?.dietType && toTitleCase(`Diet Type: ${activity?.dietType}`);
        subHeading =
          activity?.dietDescription &&
          `Description: ${activity?.dietDescription}`;
        break;
      case "medication":
        heading = activity?.medicineName && toTitleCase(activity?.medicineName);
        break;
      case "physical":
        heading = activity?.activityType && toTitleCase(activity?.activityType);
        subHeading = `Duration : ${activity?.duration} minutes`;
        break;
      case "sleep":
        heading = `${activity?.sleepingHours} Hours`;
        subHeading = `Sleeping Quality : ${activity?.sleepingQuality}`;
        break;
      default:
        break;
    }

    return (
      <div>
        {heading && (
          <Typography className={classes.description}>
            {`${toTitleCase(heading)}`}
          </Typography>
        )}
        {subHeading && (
          <Typography className={classes.description}>{subHeading}</Typography>
        )}
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.listWidth}>
        <div className={classes.headingContainer}>
          <Typography className={classes.activityListHeading}>
            {lang.activityDetails}
          </Typography>
          <CloseIcon onClick={handleClose} className={classes.icon} />
        </div>
        <div className={classes.activityContainer}>
          {activities &&
            activities?.map((activity) => {
              return (
                <div
                  key={activity.id}
                  id={activity.id}
                  className={classes.activityList}
                >
                  <div className={classes.dateTimeContainer}>
                    <img
                      src={getIcon(activity.type)}
                      alt=""
                      className={classes.imageStyle}
                    />
                    <span className={classes.dateTime}>{` ${moment(
                      activity.startTime
                    ).format("h:mm a")}`}</span>
                  </div>
                  {activity?.type !== "spike" && (
                    <div className={classes.verticalTimeline}>
                      {renderActivityDiscription(activity)}
                      {activity?.dietFoodLog &&
                        renderDietFoodLog(activity?.dietFoodLog)}
                      {activity?.photo?.length > 0 ? (
                        activity?.photo?.map((photo, index) => {
                          return (
                            <div>
                              <a
                                style={{ textDecoration: "none" }}
                                href={photo}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={photo}
                                  className={classes.image}
                                  key={index}
                                  alt=""
                                  width={180}
                                  height={120}
                                />
                              </a>
                            </div>
                          );
                        })
                      ) : (
                        <div className={classes.noImagePlaceholder}>
                          {lang.noImageAvailable}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default withStyles(ActivityListStyle)(ActivityList);
