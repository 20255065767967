import React from "react";
import { Dialog, Divider, Typography } from "@material-ui/core";

import lang from "assets/languages";
import useStyles from "./previewDialogStyles";
import CustomButtons from "@components/atoms/Button";

const PreviewFirstConfirmDialog = ({ isOpen, handleCloseConfirmDialog }) => {
  const classes = useStyles();

  return (
    <Dialog open={isOpen} onClose={handleCloseConfirmDialog}>
      <div className={classes.container}>
        <div className={classes.dialogTextBox}>
          <Typography className={classes.text}>
            {lang.previewRequest}
          </Typography>
        </div>
        <Divider />
        <br />
        <br />
        <div className={classes.buttonsBox}>
          <CustomButtons md lg onClick={handleCloseConfirmDialog}>
            {lang.ok}
          </CustomButtons>
        </div>
      </div>
    </Dialog>
  );
};

export default PreviewFirstConfirmDialog;
