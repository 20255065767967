const appointmentStyle = theme => ({
  topContainer: {
    margin: "10px"
  },
  container: {
    margin: "10px",
    border: "1px solid #ccc",
    width: "70%",
    maxHeight: "144px",
    overflow: "auto"
  },
  mainContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "16px",
    alignItems: "center"
  },
  activeContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "16px",
    alignItems: "center",
    backgroundColor: "#E6F7FF"
  },
  text: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#8C8C8C"
  },
  sub_text: {
    fontSize: "14px"
  },
  sub_text1: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#2B377E",
    cursor: "pointer"
  },
  completed_text: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#389E0D"
  },
  cancelled_text: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#FF5522"
  },
  subContainer: {
    margin: "10px",
    border: "1px solid #ccc",
    width: "70%",
    maxHeight: "144px",
    overflow: "auto"
  },
  completed_appointments: {
    margin: "15px 10px 10px 10px"
  }
})

export default appointmentStyle;