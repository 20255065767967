import React from "react";
import { Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import { coachRoutes } from "@routers/RolebasedRoutes/coachRoutes";
import { initRoutes } from "@routers/RolebasedRoutes/coachRoutes";
import PrivateRoute from "@routers/PrivateRoutes/PrivateRoute";
import { userRoles } from "@utils/constants";

const CoachLayout = () => {
  const role = useSelector(
    (state) => state.loginReducer?.loggedInUserDetails?.data?.role
  );

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.views) {
        return getRoutes(prop.views);
      }
      if (prop.subViews) {
        return getRoutes(prop.subViews);
      }
      if (prop.layout === "/coach") {
        return (
          <PrivateRoute
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            user={role}
          />
        );
      } else {
        return null;
      }
    });
  };

  const defineRole = (role) => {
    if (
      role.toUpperCase() === userRoles.coach ||
      role === userRoles.fitnessCoach ||
      role === userRoles.emotionalWellnessCoach
    ) {
      return getRoutes(coachRoutes);
    } else {
      return;
    }
  };

  const defineInitRoutes = (role) => {
    if (
      role.toUpperCase() === userRoles.coach ||
      role === userRoles.fitnessCoach ||
      role === userRoles.emotionalWellnessCoach
    ) {
      return getRoutes(initRoutes);
    } else {
      return;
    }
  };

  if (!role) {
    return;
  }

  return (
    <>
      <main>
        <Switch>
          {defineInitRoutes(role)}
          {defineRole(role)}
        </Switch>
      </main>
    </>
  );
};

export default CoachLayout;
