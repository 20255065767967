const cgmProgressDrawerStyle = (theme) => ({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: "64px",
    maxHeight: "64px",
    borderBottom: "1px solid #ccc",
    padding: "0px 16px",
    position: "sticky",
    zIndex: 1000,
    top: 0,
    backgroundColor: "white",
  },
  container: {
    margin: "16px",
  },
  viewTimelineButton: {
    marginTop: "8px",
    marginRight: "16px",
  },
  viewReportsButton: {
    marginTop: "8px",
    marginLeft: "10px",
  },
  uploadReports: {
    marginTop: "16px",
    padding: "0",
    width: "144px",
    height: "40px",
  },
  uploadLabel: {
    width: "100%",
    cursor: "pointer",
  },
  icon: {
    color: "#BFBFBF",
    fontSize: "24px",
  },
  dates: {
    display: "flex",
    marginTop: "25px",
    marginBottom: "58px",
    gap: "10px",
  },
  datesContainer: {
    width: "99%",
    overflowX: "scroll",
    height: "90px",
    overflowY: "hidden",
  },
  timelineButton: {
    background: "white",
    borderRadius: "4px",
    marginBottom: "10px",
    fontWeight: 600,
    fontSize: "16px",
    height: "40px",
    marginRight: "12px",
  },
  notSelectedTimeline: {
    background: "white",
    borderRadius: "4px",
    marginBottom: "10px",
    fontWeight: 600,
    fontSize: "16px",
    height: "40px",
    width: "115px",
    color: "#595959",
    border: "1px solid #BFBFBF",
  },
  selectedTimeline: {
    background: "#2B377E",
    borderRadius: "4px",
    marginBottom: "10px",
    fontWeight: 600,
    fontSize: "16px",
    height: "40px",
    width: "115px",
    color: "white",
  },
  graphContainer: {
    height: "480px",
    width: "100%",
    overflowX: "hidden",
  },
  trends: {
    fontSize: "16px",
    fontWeight: 500,
  },
  cgm: {
    fontSize: "24px",
    fontWeight: 500,
  },
  lastUpdateStatus: {
    fontSize: "12px",
    fontWeight: 400,
  },
  trendsDate: {
    fontSize: "16px",
    fontWeight: 500,
    margin: "16px",
  },
  modelHeader: {
    display: "flex",
  },
  modelHeading: {
    fontSize: "24px",
    fontWeight: 700,
  },
  modelHeaderTabs: {
    display: "flex",
    marginLeft: "252px",
  },
  modelHeaderGraphTab: {
    marginRight: "10px",
  },
  modalReportsSubheading: {
    marginTop: "16px",
    fontSize: "16px",
    fontWeight: 700,
  },
  reportsTable: {
    height: "400px",
  },
  reportsHeading: {
    display: "flex",
    padding: "16px",
    alignItems: "center",
    background: "#FAFAFA",
    marginTop: "8px",
  },
  reportsHeadingText: {
    fontSize: "14px",
    color: "#000000",
  },
  updatedAt: {
    marginLeft: "250px",
  },
  noReportsText: {
    color: "#595959",
    fontWeight: 400,
    fontSize: "14px",
    marginTop: "8px",
  },
  activityContainer: { width: "30%" },
  graphModal: { width: "70%" },
  reportsModal: { width: "100%" },

  graphImageContainer: {
    position: "absolute",
  },
  metricGraphHeading: {
    fontSize: "20px",
    fontWeight: 400,
    marginBottom: "12px",
  },
  statsHeading: {
    fontSize: "16px",
    fontWeight: 200,
    color: "#595959",
  },
  statsValue: {
    fontSize: "16px",
    fontWeight: 500,
    color: "#262626",
  },
  statsContainer: {
    padding: "12px 0px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#FFFFFF",
  },
  allStats: {
    padding: "0px 12px",
    background: "#FFFFFF",
  },
  dropDownButtonGroup: {
    alignSelf: "center",
  },
  statsBreakLine: {
    border: "0px",
    borderTop: "1px solid #D9D9D9",
    padding: "0px",
    margin: "0px",
    opacity: 0.5,
  },
  closeIconStyles: {
    color: "#BFBFBF",
    height: "30px",
    width: "30px",
    cursor: "pointer",
  },
  headingText: {
    color: "#000000",
    fontWeight: "500",
    fontSize: "24px",
  },
  sensorDropdownContainer: {
    display: "flex",
    alignItems: "center",
  },
  closeIconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },
  sectionWrapper: {
    padding: "16px",
    background: "#FFFFFF",
    width: "720px",
    marginBottom: "20px",
  },
  sectionInfoContainer: {
    padding: "16px",
  },
  sectionInfoTitle: {
    color: "#262626",
    fontSize: "14px",
    fontWeight: "bold",
    marginBottom: "6px",
    letterSpacing: "0.12px",
  },
  sectionInfoDataValue: {
    color: "#262626",
    fontSize: "16px",
    fontWeight: "bold",
  },
  sectionInfoDataUnit: {
    color: "#8C8C8C",
    fontSize: "12px",
  },
  sectionInfoTimeline: {
    color: "#8C8C8C",
    fontSize: "12px",
    marginTop: "6px",
  },
  summaryModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "1200px",
    maxHeight: "80%",
    overflow: "scroll",
    background: "#F7F8FC",
    boxShadow: 24,
    padding: "16px",
  },
  scroll: {
    overflow: "scroll",
  },
  noData: {
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
});

export default cgmProgressDrawerStyle;
