/**
 @description
 * Generic Loader which will be used through out the application.
 @param {[string]} loaderMessage [A message needed to be passed as a prop]
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = theme => ({
  progress: {
    margin: theme.spacing(4),
    justifyContent: "center",
    alignItem: "center"
  }
});

class Loader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open
    }
  }

  render() {
    const { classes, loaderMessage } = this.props;
    const { open } = this.state;
    return (
      <Dialog
        fullScreen={false}
        open={open}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent id="loader">
          <CircularProgress className={classes.progress} />
          <Typography variant="overline" display="block" gutterBottom>
            {loaderMessage}
          </Typography>
        </DialogContent>
      </Dialog>
    );
  }
}

export default connect(
  null,
  null
)(withStyles(styles)(Loader));
