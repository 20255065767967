export const deactivationReasons = [
  { value: "", key: "none" },
  { value: "Sensor not working", key: "sensorNotWorking" },
  { value: "Sensor fell down", key: "sensorFellDown" },
  {
    value: "Sensor stopped working",
    key: "sensorStopWorking",
  },
  { value: "Others", key: "others" },
];

export const defaultSearchValue = "";
