import {
  Card,
  FormHelperText,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { useStyles } from "../../styles";
import {
  DELIVERY_MODES,
  etaTableHeading,
  patientFormFields,
} from "@pages/AddPatient/constants";
import lang from "@assets/languages";
import moment from "moment";
import Button from "@components/atoms/Button";
import { notyf } from "@utils/utility";

export const DeliveryModeCard = ({
  addOnId,
  productName,
  values,
  errors,
  handleDeliveryModeChange,
  deliveryModes,
  sectionFormState,
  setSectionFormState,
  handleCheckETA,
  setFieldValue,
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <Typography
        className={classes.deliveryModeCardHeading}
      >{`Add on name: ${productName}`}</Typography>
      <table className={classes.etaTable}>
        <thead>
          <tr>
            {etaTableHeading.map((heading) => {
              return <th className={classes.etaTableData}>{heading}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={classes.etaTableData}>
              <div className={classes.deliveryModeContainer}>
                <Select
                  fullWidth
                  labelId="select-label"
                  placeholder="Select"
                  displayEmpty
                  value={
                    values?.[patientFormFields.deliveryMode]?.[addOnId]?.name
                  }
                  renderValue={() => {
                    return (
                      values?.[patientFormFields.deliveryMode]?.[addOnId]
                        ?.name || lang.select
                    );
                  }}
                  onChange={handleDeliveryModeChange}
                  key={values?.[patientFormFields.deliveryMode]?.[addOnId]?.id}
                >
                  <MenuItem value="">{lang.select}</MenuItem>
                  {deliveryModes?.map((mode) => {
                    return (
                      <MenuItem value={mode} key={mode.id}>
                        {mode.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
            </td>
            <td className={classes.etaTableData}>
              {sectionFormState?.address?.pincode || ""}
            </td>
            <td className={classes.etaTableData}>
              <Button
                onClick={() => {
                  setSectionFormState((prevState) => ({
                    ...prevState,
                    showETAError: {
                      ...prevState.showETAError,
                      [addOnId]: false,
                    },
                  }));

                  if (!values?.[patientFormFields.deliveryMode]?.[addOnId]) {
                    notyf.error("please select a delivery mode");
                    return;
                  }
                  handleCheckETA(
                    setFieldValue,
                    values?.[patientFormFields.deliveryMode]?.[addOnId]?.id,
                    addOnId
                  );
                }}
                disabled={
                  values?.[patientFormFields.deliveryMode]?.[addOnId]?.name ===
                  DELIVERY_MODES.clinic
                }
              >
                {lang.check}
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
      {sectionFormState[patientFormFields.eta]?.[addOnId] && (
        <div className={classes.etaContainer}>
          <Typography
            className={classes.etaLabel}
          >{`${lang.expectedDelivery} :`}</Typography>
          <Typography className={classes.label}>
            {` ${moment(
              sectionFormState?.[patientFormFields.eta]?.[addOnId]
            ).format("DD/MM/YYYY")}`}
          </Typography>
        </div>
      )}

      {errors?.[patientFormFields.deliveryMode]?.[addOnId] && (
        <FormHelperText className={classes.errorText}>
          {errors?.[patientFormFields.deliveryMode][addOnId]}
        </FormHelperText>
      )}
      {sectionFormState.showETAError[addOnId] &&
        !errors?.[patientFormFields.deliveryMode]?.[addOnId] && (
          <FormHelperText className={classes.errorText}>
            {lang.pleaseCheckETA}
          </FormHelperText>
        )}
    </Card>
  );
};
