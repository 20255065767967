const weekPlanDetailsDrawerStyle = theme => ({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: "64px",
    maxHeight: "64px",
    borderBottom: "1px solid #ccc",
    padding: "0px 16px",
    position: "sticky",
    zIndex: 1000,
    top: 0,
    backgroundColor: "white"
  },
  subContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "16px 16px 0 16px"
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    borderBottom: "1px solid #ccc",
    padding: "16px 16px 8px 16px"
  },
  taskContainer: {
    display: "flex",
    padding: "16px",
    borderBottom: "1px solid #ccc",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer"
  },
  taskSubContainer: {
    display: "flex",
    alignItems: "center"
  },
  content: {
    marginLeft: "15px"
  },
  bottomContainer: {
    display: "flex",
    flexDirection: "column"
  },
  headerText: {
    fontSize: "16px",
    fontWeight: 500,
    color: "#2B377E",
    cursor: "pointer"
  },
  text: {
    fontSize: "12px",
    color: "#8C8C8C",
    textTransform: "uppercase"
  },
  description: {
    fontSize: "24px",
    fontWeight: 500,
    marginTop: "0.5rem"
  },
  sub_text: {
    fontSize: "14px",
    marginTop: "0.5rem"
  },
  sub_text1: {
    fontSize: "16px",
    fontWeight: 500,
    color: "#5BABCC",
    marginTop: "1rem",
    cursor: "pointer"
  },
  sub_text2: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#8C8C8C"
  },
  sub_text3: {
    fontSize: "16px",
    fontWeight: 500
  },
  sub_text4: {
    fontSize: "12px",
    color: "#B3B3B3"
  }
})

export default weekPlanDetailsDrawerStyle;