import React, { useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import { useDispatch, useSelector } from "react-redux";
import { updatePatientInfo } from "pages/CreateReport/actions/CreateReportActions";
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@material-ui/core";
import { useStyles } from "./patientInfoStyles";
import { notyf } from "utils/utility";
import lang from "assets/languages";
import { goals } from "pages/CreateReport/constants";
import { toTitleCase } from "utils/stringUtils";
import { regexObj } from "utils/utility";
import { maxHba1climit } from "pages/CreateReport/utils";
import { maxPatientHeight } from "pages/CreateReport/utils";
import { maxPatientAge } from "pages/CreateReport/utils";

const units = {
  weight: "kg",
  height: "cm",
  hba1c: "%",
};

const patientInfoKeyNames = {
  weight: "weight",
  height: "height",
  hba1c: "hba1c",
  age: "age",
};

export default function PatientInfo({ reportId }) {
  let classes = useStyles();
  const dispatch = useDispatch();
  const patientInfo = useSelector(
    (state) => state.createReportReducer.patientInfo
  );
  let height = patientInfo.height;
  let weight = patientInfo.weight;

  const handleGoalChange = (event) => {
    const {
      target: { value },
    } = event;

    let newGoals = typeof value === "string" ? value.split(",") : value;

    dispatch(updatePatientInfo({ ...patientInfo, goal: newGoals }));
  };

  useEffect(() => {
    let bmi = "";
    if (height !== 0 && !!height) {
      bmi = Math.floor((weight * 100 * 100) / (height * height));
      bmi = bmi || 0;
    }

    dispatch(updatePatientInfo({ ...patientInfo, bmi: bmi }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height, weight]);

  const renderNonEditableRow = (keyName, heading) => {
    let isBmi = keyName === "bmi";
    let fieldValue = patientInfo?.[keyName];
    return (
      <TableRow>
        <TableCell
          component="th"
          scope="row"
          className={classes.tableCellHeading}
        >
          {heading}
        </TableCell>
        <TableCell
          align="right"
          className={classes.editable}
          style={{
            width: "519px",
            justifyContent: "left",
            textAlign: "left",
            padding: "10px",
          }}
        >
          {isBmi ? `${patientInfo?.[keyName]} kg/m2` : fieldValue || ""}
        </TableCell>
      </TableRow>
    );
  };

  const renderEditableRow = (keyName, heading) => {
    const handleFieldChange = (event) => {
      if (
        (keyName === patientInfoKeyNames.age ||
          keyName === patientInfoKeyNames.height ||
          keyName === patientInfoKeyNames.weight) &&
        !regexObj.onlyIntergers.test(event.target.value) &&
        event.target.value !== ""
      ) {
        notyf.error(`${keyName} value cannot be decimal`);
        return;
      }
      if (
        keyName === patientInfoKeyNames.hba1c &&
        event.target.value > maxHba1climit
      ) {
        notyf.error(`HBA1C value can't be more than ${maxHba1climit}`);

        return;
      }

      if (
        (keyName === patientInfoKeyNames.height ||
          keyName === patientInfoKeyNames.weight) &&
        event.target.value > maxPatientHeight
      ) {
        notyf.error(`${keyName} value can't be more than ${maxPatientHeight}`);

        return;
      }

      if (
        keyName === patientInfoKeyNames.age &&
        event.target.value > maxPatientAge
      ) {
        notyf.error(`age can't be more than ${maxPatientAge}`);

        return;
      }

      let value = event.target.value;

      if (
        keyName === patientInfoKeyNames.weight ||
        keyName === patientInfoKeyNames.height
      ) {
        value = Number(value);
      }

      dispatch(updatePatientInfo({ ...patientInfo, [keyName]: value }));
    };

    return (
      <TableRow>
        <TableCell
          component="th"
          scope="row"
          className={classes.tableCellHeading}
        >
          {heading}
        </TableCell>

        {keyName !== "gender" ? (
          <TableCell align="left" className={classes.editable}>
            <TextField
              onChange={handleFieldChange}
              value={patientInfo?.[keyName] || ""}
              placeholder={`Enter ${heading}`}
              className={classes.editableFields}
            />
            {(keyName === patientInfoKeyNames.height ||
              keyName === patientInfoKeyNames.weight ||
              keyName === patientInfoKeyNames.hba1c) && (
              <TextField
                value={units[keyName]}
                className={classes.tableUnitsField}
              />
            )}
          </TableCell>
        ) : (
          <TableCell className={classes.multiSelectField}>
            <FormControl fullWidth>
              <InputLabel id="select-label">{lang.gender}</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={toTitleCase(patientInfo?.[keyName]) || ""}
                renderValue={() => {
                  return toTitleCase(patientInfo?.[keyName]);
                }}
                label="Gender"
                onChange={handleFieldChange}
              >
                <MenuItem value="male">{lang.male}</MenuItem>
                <MenuItem value="female">{lang.female}</MenuItem>
                <MenuItem value="female">{lang.other}</MenuItem>
              </Select>
            </FormControl>
          </TableCell>
        )}
      </TableRow>
    );
  };

  return (
    <div className={classes.container}>
      <Typography className={classes.tableHeadingTitle}>
        {lang.patientInfo}
      </Typography>
      <div style={{ marginTop: "8px" }}>
        <TableContainer style={{}}>
          <Table className={classes.table} aria-label="simple table">
            <TableBody>
              {renderNonEditableRow("patientName", "Patient Name")}
              {renderEditableRow("age", "Age")}
              {renderEditableRow("gender", "Gender")}
              {renderNonEditableRow("doctorName", "Doctor Name")}
              {renderNonEditableRow("clinicName", "Clinic Name")}
              {renderNonEditableRow("coachName", "Coach Name")}
              {renderNonEditableRow("bmi", "BMI")}
              {renderEditableRow("height", "Height")}
              {renderEditableRow("weight", "Weight")}
              {renderEditableRow("hba1c", "HBA1C")}
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.tableCellHeading}
                >
                  {lang.goals}
                </TableCell>
                <TableCell align="right" className={classes.multiSelectField}>
                  <div>
                    <FormControl fullWidth>
                      <InputLabel
                        id="demo-multiple-name-label"
                        style={{ marginLeft: "5px" }}
                      >
                        {lang.select}
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        value={patientInfo?.goal || []}
                        rowsMax={4}
                        multiline
                        renderValue={() => {
                          return patientInfo?.goal;
                        }}
                        onChange={handleGoalChange}
                        input={<OutlinedInput label="Name" />}
                        MenuProps={{
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                        }}
                      >
                        {goals.map((name) => (
                          <MenuItem key={name} value={name}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}
