import axiosAPI from "axios";
import { API_URL } from "utils/constants";

export const setToken = (param) => {
  localStorage.setItem("access_token", param);
};

const axios = axiosAPI.create({
  baseURL: API_URL,
  headers: {
    "Content-type": "application/json",
  },
});

let retry = 3;
const api = async (url, options) => {
  const accessToken = localStorage.getItem("access_token");

  try {
    const res = await axios(url, {
      ...options,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-type": "application/json",
        ...options.headers,
      },
    });
    if (!res.data.status || res.data.status === "SUCCESS") {
      return res;
    } else if (res.data.status === "EXPIRED" && retry > 0) {
      retry--;
      // eslint-disable-next-line
      throw { status: "EXPIRED" };
    } else {
      // eslint-disable-next-line
      throw { message: res.data.message };
    }
  } catch (e) {
    if (e.status === "EXPIRED") {
      if (localStorage.getItem("access_token") !== null) {
        localStorage.removeItem("access_token");
        const {
          data: { data },
        } = await api("/v1/auth/refresh", {
          method: "post",
        });

        setToken(data.token);
      }
    }

    throw new Error(e.message);
  }
};

// if (error.status === 'EXPIRED') {
//   if (hydrate(AUTH_KEYS.AUTH_TOKEN_COOKIE, 'cookies') !== null) {
//     removeCookie(AUTH_KEYS.AUTH_TOKEN_COOKIE);
//     removeCookie(AUTH_KEYS.REFRESH_TOKEN_COOKIE);
//     const {
//       data: { data },
//     } = await api('/user/refresh-token', {
//       method: 'patch',
//       headers: { authorization: `Bearer ${hydrate(USER_TOKEN, 'cookies')}` },
//       data: {
//         refresh_token: hydrate(AUTH_KEYS.REFRESH_TOKEN_COOKIE, 'cookies'),
//       },
//     });
//     saveCookie(AUTH_TOKEN_COOKIE, data.auth_token);
//     saveCookie(USER_TOKEN, data.access_token);
//   }
//   // throw new PermissionException(error?.response?.data?.error_message);
// }
export default api;
