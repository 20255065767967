/**
 @desc
 * Centralized unique action types for Login actions and reducers.
 */


import {
  actionCreator,
  API_URL,
  checkHttpStatus,
  createRequestActionTypes,
  jsonApiHeader,
  showSuccessMessage,
  showErrorMessage,
} from '../../../utils/utility';

export {
  jsonApiHeader,
  actionCreator,
  checkHttpStatus,
  showSuccessMessage,
  showErrorMessage,
};


export const UPDATE_APPOINTMENT = `${API_URL}/v1/patients/appointments`;


export const EditAppointmentActionTypes = {
  update_Appointment: createRequestActionTypes('UPDATE_APPOINTMENT'),
};