/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Dialog, Typography } from "@material-ui/core";
import { Form, Formik } from "formik";
import Loader from "@components/atoms/Loader";
import FormTextInput from "@components/molecules/FormTextInput/FormTextInput";
import { connect } from "react-redux";
import PatientBasicDetails from "./components/PatientBasicDetails";
import { END_POINT } from "network/constants";
import api from "utils/api";
import { API_METHODS } from "network/constants";
import { apiResponses } from "network/constants";
import Button from "@components/atoms/Button";
import { notyf } from "utils/utility";
import ConfirmPopup from "./components/ConfirmPopup";
import lang from "assets/languages";
import { sensorDeactivationStyle } from "assets/jss/pages/sensorDeactivationStyle";
import NewAddonDialog from "./components/NewAddonDialog";
import { metaBaseSensorDetailsLink } from "../constants";
import { DELIVERY_MODES } from "@pages/AddPatient/constants";
import moment from "moment";

const initialValues = { search: "" };

const SensorDeactivation = () => {
  const classes = sensorDeactivationStyle();
  const [search, setSearch] = useState("");
  const [patientId, setPatientId] = useState(null);

  const handleChange = (value) => {
    setPatientId(null);
    if (isNaN(value)) {
      return;
    }
    setSearch(value);
  };

  const [patientBasicDetails, setPatientBasicDetails] = useState(null);
  const [deactivationReason, setDeactivationReason] = useState(null);
  const [dialogState, setDialogState] = useState({
    openDeactivationPopup: false,
    openAddonDialog: false,
    showMetabaseLink: false,
  });
  const [loading, setLoading] = useState(false);

  const handleCloseConfirmDialog = () => {
    setDialogState({ ...dialogState, openDeactivationPopup: false });
  };

  useEffect(() => {
    if (patientId) {
      fetchPatientBasicData();
    } else {
      setPatientBasicDetails(null);
    }
  }, [patientId]);

  const fetchPatientBasicData = async () => {
    setLoading(true);
    try {
      const res = await api(
        END_POINT.getPatientBasicDetails(Number(patientId)),
        {
          method: API_METHODS.GET,
        }
      );
      if (res.data.status === apiResponses.success) {
        setPatientBasicDetails(res.data?.data);
      } else {
        notyf.error(res.data.message);
      }
      setLoading(false);
    } catch (e) {
      notyf.error(e.message);
      setLoading(false);
    }
  };

  const handleDeactivation = async () => {
    setLoading(true);
    try {
      const res = await api(END_POINT.deactivateSensor(patientId), {
        method: API_METHODS.POST,
        data: {
          deactivationReason,
          sensorID: patientBasicDetails?.activeSensorID,
        },
      });
      if (res.data.status === apiResponses.success) {
        notyf.success("Sensor deactivated successfully");
        fetchPatientBasicData();
      }
      setLoading(false);
    } catch (e) {
      notyf.error(e.message);
      setLoading(false);
    }
  };

  const handleYesConfirmDialog = async () => {
    if (!deactivationReason?.length) {
      notyf.error("Please select a reason to deactivate the sensor");
      return;
    }

    handleDeactivation();
    setDialogState({ ...dialogState, openDeactivationPopup: false });
  };

  const handleCancelAddonDialog = () => {
    setDialogState({ ...dialogState, openAddonDialog: false });
  };

  const handleSaveAddon = async (addOnId, deliveryDetails) => {
    setLoading(true);
    try {
      const isClinicDeliveryMode =
        deliveryDetails?.deliveryMode?.name === DELIVERY_MODES.clinic;

      const res = await api(END_POINT.addPatientAddOnProduct(patientId), {
        method: API_METHODS.POST,
        data: {
          products: [
            {
              id: addOnId,
              quantity: 1,
              ETA: isClinicDeliveryMode
                ? moment().format("YYYY-MM-DD")
                : deliveryDetails?.deliveryETA,
              deliveryAddress: { ...patientBasicDetails?.patientAddress },
              deliveryModeId: deliveryDetails?.deliveryMode?.id,
            },
          ],
        },
      });
      if (res.data.status === apiResponses.success) {
        notyf.success("Sensor Added Successfully");
      }
      setLoading(false);
    } catch (e) {
      notyf.error(e.message);
      setLoading(false);
    }
    setDialogState({ ...dialogState, openAddonDialog: false });
    setLoading(false);
  };

  const handleAddCgmProduct = () => {
    setDialogState({ ...dialogState, openAddonDialog: true });
  };

  const handleDeativateBtn = () => {
    setDialogState({ ...dialogState, openDeactivationPopup: true });
  };

  return (
    <>
      {loading && <Loader open={true} loaderMessage="Fetching..." />}
      {dialogState?.openDeactivationPopup && (
        <Dialog
          open={dialogState?.openDeactivationPopup}
          onClose={handleCloseConfirmDialog}
        >
          <ConfirmPopup
            handleYesConfirmDialog={handleYesConfirmDialog}
            handleCloseConfirmDialog={handleCloseConfirmDialog}
            firstName={patientBasicDetails?.firstName}
            lastName={patientBasicDetails?.lastName}
            setDeactivationReason={setDeactivationReason}
          />
        </Dialog>
      )}
      <div className={classes.addPatientButtonContainer}>
        <Typography className={classes.title}>{lang.searchPatient}</Typography>

        <Formik initialValues={initialValues}>
          {() => {
            return (
              <Form>
                <FormTextInput
                  className={classes.search}
                  name={"search"}
                  placeholder="Enter Patient ID"
                  autoComplete="off"
                  typeOf="auth"
                  value={search}
                  onChange={(event) => handleChange(event.target.value)}
                />
                <Button
                  className={classes.searchBtn}
                  onClick={() => {
                    setPatientId(search);
                  }}
                >
                  {lang.search}
                </Button>
              </Form>
            );
          }}
        </Formik>
      </div>
      {search && patientBasicDetails && (
        <>
          <PatientBasicDetails
            patientBasicDetails={patientBasicDetails}
            loading={loading}
          />
          <div>
            <Button
              disabled={!patientBasicDetails.sensorActive}
              onClick={handleDeativateBtn}
              className={classes.deactivationBtn}
            >
              {lang.deactivate}
            </Button>
            <Button
              onClick={handleAddCgmProduct}
              disabled={!patientBasicDetails?.patientAddress?.id}
              className={classes.addNewAddon}
            >
              {lang.addCGMProduct}
            </Button>
          </div>
          <div className={classes.linkContainer}>
            <Typography>
              {`${lang.note}: ${lang.anyQureiesReferThis}  `}
              <a
                href={metaBaseSensorDetailsLink}
                target="_blank"
                rel="noreferrer"
              >
                {lang.metabaseDashboard}
              </a>
            </Typography>
          </div>

          <Dialog open={dialogState?.openAddonDialog}>
            <NewAddonDialog
              classes={classes}
              patientBasicDetails={patientBasicDetails}
              handleSaveAddon={handleSaveAddon}
              handleCancelAddonDialog={handleCancelAddonDialog}
            />
          </Dialog>
        </>
      )}
    </>
  );
};

export default connect()(SensorDeactivation);
