import React, { useEffect, useState } from "react";
import { Box, Drawer, Typography, withStyles } from "@material-ui/core";
import moment from "moment";
import doctorInstructionStyle from "assets/jss/pages/doctorInstructionStyle";
import { Pagination } from "@mui/material";
import api from "utils/api";
import Instruction from "./instruction";
import { instructionFor } from "./constants";
import InstructionDrawer from "./instructionDrawer";

const limit = 5;

const DoctorInstruction = ({ patientId, classes }) => {
  const [page, setPage] = useState(1);
  const [instructionConfig, setInstuctionConfig] = useState({
    instructions: [],
    totalPages: 0,
    selectedInstruction: null,
    showDrawer: false,
  });

  const { instructions, totalPages, selectedInstruction, showDrawer } =
    instructionConfig;

  const fetchInstructions = async () => {
    try {
      const {
        data: { data },
      } = await api(
        `/v1/instruction/getCoachInstructions/${patientId}?offset=${
          (page - 1) * limit
        }&limit=${limit}`,
        {
          method: "get",
        }
      );

      const { coachInstruction, totalCount } = data;

      setInstuctionConfig({
        instructions: coachInstruction,
        totalPages: totalCount > 0 ? Math.ceil(totalCount / limit) : totalCount,
      });
    } catch (e) {
      console.error("failed to fetch instructions", e);
    }
  };

  useEffect(() => {
    fetchInstructions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const onInstructionDetails = (instruction) => {
    setInstuctionConfig((prevState) => ({
      ...prevState,
      selectedInstruction: instruction,
      showDrawer: true,
    }));
  };

  const onCloseDrawer = () => {
    setInstuctionConfig((prevState) => ({
      ...prevState,
      showDrawer: false,
    }));
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.content}>
        {instructions && instructions.length > 0 ? (
          instructions.map((instructionData, index) => {
            const {
              instructionFor: sentTo,
              updatedAt = new Date(),
              instructionText = "",
              id,
            } = instructionData;

            const date = moment(updatedAt).format("DD MMM, YYYY");

            return (
              <Instruction
                key={id}
                id={id}
                index={index}
                selectedInstruction={selectedInstruction}
                sentDate={`${instructionFor[sentTo]} • ${date}`}
                instruction={instructionText}
                onPress={() => onInstructionDetails(instructionData)}
              />
            );
          })
        ) : (
          <Typography>{"No Instructions Available"}</Typography>
        )}
      </Box>
      <Drawer anchor={"right"} open={showDrawer} onClose={onCloseDrawer}>
        <InstructionDrawer
          onClose={onCloseDrawer}
          selectedInstruction={selectedInstruction}
        />
      </Drawer>
      {!!totalPages && (
        <Pagination
          className={classes.pagination}
          count={totalPages}
          onChange={handleChange}
        />
      )}
    </Box>
  );
};

export default withStyles(doctorInstructionStyle)(DoctorInstruction);
