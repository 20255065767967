/**
 @desc
 * Centralized unique action types for Login actions and reducers.
 */

import {
  actionCreator,
  API_URL,
  checkHttpStatus,
  createRequestActionTypes,
  jsonApiHeader,
  showSuccessMessage,
  showErrorMessage,
} from "../../../utils/utility";

export {
  jsonApiHeader,
  actionCreator,
  checkHttpStatus,
  showSuccessMessage,
  showErrorMessage,
};

export const GET_ALL_PATIENTS = `${API_URL}/v1/patients/counselor`;
export const POST_UPLOAD_APPOINTMENTS = `${API_URL}/v1/patients/upload/appointments`;

export const HomeActionTypes = {
  get_AllPatients: createRequestActionTypes("GET_ALL_PATIENTS"),
  post_UploadAppointments: createRequestActionTypes("POST_UPLOAD_APPOINTMENTS"),
  setSearchType: createRequestActionTypes("SET_SEARCH_TYPE"),
};

export const homeTabIndexes = {
  myOnboardings: 0,
  upselling: 1,
};

export const upsellingFormFields = {
  patientID: "patientID",
  mobileNumber: "mobileNumber",
};

export const apiQueryParams = {
  id: "id",
  mobileNumber: "mobileNumber",
};

export const homeTabLabels = {
  myOnboardings: "My Onboardings",
  upselling: "Upselling",
};

export const searchStrings = {
  phoneNumber: "phoneNumber",
  patientName: "patientName",
};

export const defaultCountryCode = "91";
