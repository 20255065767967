const progressStyle = (theme) => ({
  topContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxHeight: "400px",
    overflow: "auto",
    overflowX: "hidden",
  },
  subContainer: {
    width: "323px",
    alignItems: "center",
    padding: "10px",
    backgroundColor: "#E6F7FF",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "12px",
    cursor: "pointer",
  },
  sub_container: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",
  },
  container: {
    display: "flex",
    alignItems: "center",
    height: "56px",
    width: "56px",
    padding: "10px",
    borderRadius: "8px",
    backgroundColor: "white",
  },
  container1: {
    display: "flex",
    position: "relative",
    alignItems: "center",
    height: "56px",
    width: "56px",
    padding: "5px",
    borderRadius: "8px",
    border: "1px solid #D9D9D9",
  },
  sub_container1: {
    display: "flex",
    alignItems: "center",
    height: "56px",
    width: "56px",
    padding: "10px",
    marginTop: "10px",
    borderRadius: "8px",
    backgroundColor: "white",
  },
  chart_container: {
    width: "323px",
    padding: "10px",
    marginTop: "10px",
    boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
  },
  chart_sub_container: {
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  weight_container: {
    height: "144px",
    backgroundColor: "#EE3E54",
    padding: "5px",
    borderRadius: "8px",
    marginTop: "0.5rem",
  },
  bp_container: {
    height: "144px",
    backgroundColor: "#A070CC",
    padding: "5px",
    borderRadius: "8px",
    marginTop: "0.5rem",
  },
  food_container: {
    height: "144px",
    backgroundColor: "#389E0D",
    padding: "5px",
    borderRadius: "8px",
    marginTop: "0.5rem",
  },
  steps_container: {
    height: "144px",
    backgroundColor: "#6C6ADB",
    padding: "5px",
    borderRadius: "8px",
    marginTop: "0.5rem",
  },
  physical_container: {
    height: "144px",
    backgroundColor: "#0071DA",
    padding: "5px",
    borderRadius: "8px",
    marginTop: "0.5rem",
  },
  hba1c_container: {
    height: "144px",
    backgroundColor: "#20E200",
    padding: "5px",
    borderRadius: "8px",
    marginTop: "0.5rem",
  },
  sleep_container: {
    height: "144px",
    backgroundColor: "#3EB9EE",
    padding: "5px",
    borderRadius: "8px",
    marginTop: "0.5rem",
  },
  chart_sub_container1: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "0.5rem",
  },
  text: {
    fontSize: "16px",
    fontWeight: 500,
  },
  text2: {
    fontSize: "14px",
    color: "#595959",
  },
  sub_text: {
    fontSize: "14px",
    fontWeight: 500,
  },
  sub_text1: {
    fontSize: "14px",
    color: "#8C8C8C",
  },
  icon: {
    color: "#B3B3B3",
  },
  pending: {
    display: "flex",
    alignItems: "center",
    height: "56px",
    width: "56px",
    padding: "5px",
    borderRadius: "8px",
  },
  completed: {
    display: "flex",
    alignItems: "center",
    height: "56px",
    width: "56px",
    padding: "5px",
    borderRadius: "8px",
    border: "1px solid green",
  },
  containerBox: {
    width: "96%",
    padding: "16px",
    display: "flex",
    justifyContent: "space-between",
    height: "35px",
    cursor: "pointer",
  },
  weekPlanText: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "19px",
    color: "#262626",
  },
  weekPlanSubText: {
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "14px",
    color: "#8C8C8C",
    marginTop: "8px",
  },
  textContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "auto",
    gap: "16px",
  },
  borderBottom: {
    borderBottom: "1px solid #D9D9D9",

    width: "100%",
    marginLeft: "32px",
  },
  chevText: {
    fontWeight: "normal",
    fontSize: "16px",
    color: "#262626",
    width: "100%",
    whiteSpace: "nowrap",
  },
});

export default progressStyle;
