import React, { useState } from "react";
import { Typography } from "@material-ui/core";
import CustomButton from "@components/atoms/Button";
import { FieldArray, Formik } from "formik";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import FormRadioButtonGroup from "@components/molecules/FormRadioButtonGroup";
import FormSelectField from "@components/molecules/FormSelectField";
import moment from "moment";
import * as Yup from "yup";
import FormDatePicker from "@components/molecules/FormDatePicker";
import FormTimePicker from "@components/molecules/FormTimePicker";

const ReminderSchema = Yup.object().shape(
  {
    reminder: Yup.string().required("Required"),
    reminderRepeat: Yup.string().when(["reminder"], {
      is: (reminder) => reminder === "required",
      then: Yup.string().required("Required"),
    }),
    reminderEndRepeat: Yup.string().when(["reminder"], {
      is: (reminder) => reminder === "required",
      then: Yup.string().required("Required"),
    }),
    reminderTime: Yup.string().when(["reminder"], {
      is: (reminder) => reminder === "required",
      then: Yup.string().required("Required"),
    }),
  },
  ["reminder"]
);

const taskEntryData = [
  { value: "nodataentry", label: "No Data Entry" },
  { value: "bloodsugar", label: "Blood Sugar" },
  { value: "hba1c", label: "Hba1c" },
  { value: "physical", label: "Physical Activity" },
  { value: "sleep", label: "Sleep" },
  { value: "steps", label: "Steps" },
  { value: "weight", label: "Weight" },
  { value: "diet", label: "Diet" },
];

const reminderRepeatData = [
  { value: "daily", label: "Daily" },
  { value: "weekly", label: "Weekly" },
  { value: "monthly", label: "Monthly" },
];

const CreateNewModuleDrawer = (props) => {
  const [isReadMore, setIsReadMore] = useState(true);
  const { moduleData, taskDetailsByModule, endDate, masterTasks } = props;

  const initialValues = {
    id: moduleData.id,
    type: moduleData.type,
    level: moduleData.level,
    category: moduleData.category,
    title: moduleData.title,
    reminder: moduleData.reminder === true ? "required" : "notRequired",
    reminderTime: moduleData.reminderTime
      ? moment(moduleData.reminderTime, "HH:mm:ss").format("hh:mm A")
      : "",
    reminderRepeat: moduleData.reminderRepeat ? moduleData.reminderRepeat : "",
    reminderEndRepeat: moduleData.reminderEndRepeat
      ? moduleData.reminderEndRepeat
      : endDate,
    tasks:
      moduleData?.tasks?.length > 0
        ? moduleData?.tasks.map((detail) => {
            return {
              task: detail.task,
              taskDataEntry: detail.taskDataEntry,
            };
          })
        : taskDetailsByModule?.length > 0
        ? taskDetailsByModule?.map((detail) => {
            return {
              task: detail.task,
              taskDataEntry: detail.taskDataEntry,
            };
          })
        : masterTasks?.length > 0
        ? masterTasks.map((detail) => {
            return {
              task: detail.task,
              taskDataEntry: detail.taskDataEntry,
            };
          })
        : [
            {
              task: "",
              taskDataEntry: "",
            },
          ],
  };

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={props.handleAddCustomWeekPlanModule}
        validationSchema={ReminderSchema}
        enableReinitialize
      >
        {({ values, handleSubmit, setFieldValue }) => {
          return (
            <>
              <div
                style={{
                  display: "flex",
                  position: "sticky",
                  zIndex: 1000,
                  top: 0,
                  background: "white",
                  justifyContent: "space-between",
                  alignItems: "center",
                  maxHeight: "64px",
                  minHeight: "64px",
                  borderBottom: "1px solid #ccc",
                  padding: "0px 12px",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <KeyboardBackspaceIcon
                    style={{ cursor: "pointer" }}
                    onClick={props.handleGoToModuleLibrary}
                  />
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontWeight: 500,
                      marginLeft: "0.5rem",
                    }}
                  >
                    Details
                  </Typography>
                </div>
                <CustomButton md onClick={handleSubmit}>
                  Save and Add
                </CustomButton>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "16px",
                  borderBottom: "1px solid #ccc",
                }}
              >
                <Typography style={{ fontSize: "16px", fontWeight: 500 }}>
                  {moduleData.title || "N/A"}
                  START
                </Typography>
                <Typography
                  style={{
                    fontSize: "12px",
                    fontWeight: 500,
                    color: "#8C8C8C",
                  }}
                >
                  {moduleData.type || "N/A"} • {moduleData.category || "N/A"} •
                  {moduleData.level || "N/A"}
                </Typography>
              </div>
              <div style={{ padding: "16px", background: "#F7F8FC" }}>
                <Typography style={{ fontSize: "14px", fontWeight: 500 }}>
                  Description
                </Typography>
                <div
                  style={{
                    background: "white",
                    padding: "8px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    margin: "0.5rem 0rem",
                  }}
                >
                  <Typography style={{ fontSize: "14px" }}>
                    {moduleData.snippet
                      ? isReadMore
                        ? moduleData.snippet?.slice(0, 150)
                        : moduleData.snippet
                      : "N/A"}
                    {moduleData.snippet && moduleData.snippet.length > 150 && (
                      <span
                        onClick={toggleReadMore}
                        style={{
                          color: "#2B377E",
                          cursor: "pointer",
                          fontWeight: 700,
                        }}
                      >
                        {isReadMore ? "...Read more" : " Show less"}
                      </span>
                    )}
                  </Typography>
                </div>
                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: 500,
                    marginTop: "20px",
                  }}
                >
                  Reminder
                </Typography>
                <div
                  style={{
                    background: "white",
                    padding: "8px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    margin: "0.5rem 0rem",
                  }}
                >
                  <FormRadioButtonGroup
                    name="reminder"
                    options={[
                      { value: "notRequired", label: "Not Required" },
                      { value: "required", label: "Required" },
                    ]}
                    value={values.reminder}
                  />
                  {values.reminder === "required" && (
                    <>
                      <div
                        style={{
                          padding: "10px 0px",
                        }}
                      >
                        <Typography
                          style={{ fontSize: "12px", fontWeight: 500 }}
                        >
                          Time
                        </Typography>
                        <FormTimePicker
                          name="reminderTime"
                          value={`2018-01-01T${moduleData.reminderTime}+05:30`}
                          style={{
                            marginTop: "0.5rem",
                            background: "white",
                            borderRadius: "4px",
                          }}
                        />
                      </div>
                      <div
                        style={{
                          padding: "10px 0px",
                        }}
                      >
                        <Typography
                          style={{ fontSize: "12px", fontWeight: 500 }}
                        >
                          Repeat
                        </Typography>
                        <FormSelectField
                          name="reminderRepeat"
                          label={"Select"}
                          value={values.reminderRepeat}
                          options={reminderRepeatData}
                          style={{
                            marginTop: "0.5rem",
                            background: "white",
                            borderRadius: "4px",
                            width: "100%",
                          }}
                          type="patient"
                        />
                      </div>
                      <div
                        style={{
                          padding: "10px 0px",
                        }}
                      >
                        <Typography
                          style={{ fontSize: "12px", fontWeight: 500 }}
                        >
                          End Repeat
                        </Typography>
                        <FormDatePicker
                          name="reminderEndRepeat"
                          onChange={(value) =>
                            setFieldValue("reminderEndRepeat", value)
                          }
                          style={{
                            marginTop: "0.5rem",
                            background: "white",
                            borderRadius: "4px",
                          }}
                          maxDate={moment(endDate).format("MM/DD/YYYY")}
                        />
                      </div>
                    </>
                  )}
                </div>
                <FieldArray name="tasks">
                  {({ insert, remove, push }) => (
                    <>
                      {values.tasks.length > 0 &&
                        values.tasks.map((field, index) => {
                          return (
                            <>
                              <div key={index}>
                                <div
                                  style={{
                                    display: "flex",
                                    marginTop: index > 0 ? "10px" : "20px",
                                    marginBottom: "-10px",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Typography
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: 500,
                                    }}
                                  >
                                    Task {`${index + 1}`}
                                  </Typography>
                                  {index > 0 && (
                                    <Typography
                                      onClick={() => remove(index)}
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        color: "#FF5522",
                                        cursor: "pointer",
                                      }}
                                    >
                                      Remove
                                    </Typography>
                                  )}
                                </div>
                                <div
                                  style={{
                                    padding: "8px 0px",
                                    margin: "0.5rem 0rem",
                                  }}
                                >
                                  <input
                                    value={field.task}
                                    name={`tasks.${index}.task`}
                                    placeholder={`Task ${
                                      index + 1
                                    } for patient`}
                                    onChange={(e) =>
                                      setFieldValue(
                                        `tasks.${index}.task`,
                                        e.target.value
                                      )
                                    }
                                    style={{
                                      width: "90%",
                                      borderRadius: "4px",
                                      padding: "13px 14px",
                                      border: "1px solid #DADADA",
                                    }}
                                  />
                                  <FormSelectField
                                    name={`tasks.${index}.taskDataEntry`}
                                    value={field.taskDataEntry}
                                    options={taskEntryData}
                                    label={"Select"}
                                    style={{
                                      marginTop: "0.5rem",
                                      background: "white",
                                      borderRadius: "10px",
                                    }}
                                    type="patient"
                                  />
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  {index + 1 < 20 &&
                                  index + 1 === values.tasks.length ? (
                                    <CustomButton
                                      md_border
                                      onClick={() =>
                                        push({
                                          task: "",
                                          taskDataEntry: "",
                                        })
                                      }
                                      style={{
                                        backgroundColor: "white",
                                      }}
                                    >
                                      Add Task
                                    </CustomButton>
                                  ) : (
                                    <div />
                                  )}
                                </div>
                              </div>
                            </>
                          );
                        })}
                    </>
                  )}
                </FieldArray>
              </div>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default CreateNewModuleDrawer;
