/* eslint-disable react-hooks/exhaustive-deps */
import { Typography, makeStyles, Card, Grid } from "@material-ui/core";
import Button from "@components/atoms/Button";
import FormSelectField from "@components/molecules/FormSelectField";
import { Form, Formik } from "formik";
import { TextField } from "@material-ui/core";
import FormDatePicker from "@components/molecules/FormDatePicker";
import FormTextInput from "@components/molecules/FormTextInput/FormTextInput";
import { UpdateAppointmentAction } from "./actions/EditAppointmentActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Loader from "@components/atoms/Loader";
import moment from "moment";
import api from "utils/api";
import { useHistory } from "react-router";
import { useCallback, useEffect, useState } from "react";
import { notyf } from "utils/utility";
import { USER_ROLE } from "utils/constants";
import { TimePicker } from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "24px",
    fontWeight: "500",
    marginBottom: "24px",
  },
  card: {
    boxShadow: 1,
    padding: "16px",
    borderRadius: "8px",
    backgroundColor: "#FFFFFF",
    border: "1px solid #D9D9D9",
    marginBottom: "16px",
  },
  label: {
    fontSize: "16px",
    fontWeight: "normal",
    height: "19px",
    marginBottom: "12px",
  },
  required: {
    color: "#FF5522",
    fontWeight: "bold",
  },
  textField: {
    // margin: "auto",
    borderBottom: "1px solid #d9d9d9",
  },
}));

const EditAppointment = (props) => {
  const { loading, loadingPatient } = props;
  const appointment =
    props.history.location.state && props.history.location.state.data;
  const classes = useStyles();
  const { push } = useHistory();

  const [people, setPeople] = useState([]);

  const fetchClinicData = useCallback(async (val) => {
    try {
      const { data } = await api(`/v1/clinics/users/${val}`, {
        method: "get",
      });
      const staffData = data.data?.[0]?.users.filter(
        ({ role }) => role === USER_ROLE.DOCTOR || role === USER_ROLE.COACH
      );

      setPeople(
        staffData.map((d) => ({
          label: `${d.firstName} ${d.lastName || ""} [${
            d.role === USER_ROLE.DOCTOR ? "Doctor" : "Coach"
          }]`,
          value: d.id,
          role: d.role,
        }))
      );
    } catch (e) {}
  }, []);

  useEffect(() => {
    const clinicId = appointment?.clinic;
    if (clinicId) {
      fetchClinicData(clinicId);
    }
  }, [fetchClinicData, appointment]);

  const UpdateAppointment = async ({ doctor, ...values }) => {
    try {
      await api(`/v1/patients/appointments/${appointment.id}`, {
        method: "patch",
        data: {
          ...values,
          [people.find((p) => p.value === doctor)?.role]: doctor,
          [people.find((p) => p.value === doctor)?.role === USER_ROLE.COACH
            ? "doctor"
            : "coach"]: null,
        },
      });
      push(`/counselor/patient/${appointment.patient}`);
      notyf.success("Appointment updated successfully.");
    } catch (e) {
      notyf.error("Appointment updation failed.");
    }
  };

  return (
    <>
      {(loading || loadingPatient) && (
        <Loader open={true} loaderMessage="Fetching..." />
      )}

      <div style={{ margin: "16px" }}>
        <Typography className={classes.title}>Edit Appointment</Typography>
        <Formik
          initialValues={{
            appointmentDate: appointment
              ? moment(appointment.appointmentDate)
              : "",
            appointmentType: appointment ? appointment.appointmentType : "",
            consultationType: appointment ? appointment.consultationType : "",
            purpose: appointment ? appointment.purpose : "",
            doctor: appointment
              ? appointment?.doctor
                ? appointment.doctor?.id
                : appointment?.coach?.id || ""
              : "",
            appointmentStatus: appointment ? appointment.appointmentStatus : "",
          }}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              UpdateAppointment(values);
              setSubmitting(false);
            }, 400);
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Card className={classes.card}>
                <Grid container>
                  <Typography className={classes.label}>
                    Appointment with
                  </Typography>
                  <span className={classes.required}>*</span>
                  <FormSelectField
                    name="doctor"
                    label="Select"
                    options={people}
                  />
                </Grid>
              </Card>

              <Card className={classes.card}>
                <Grid container>
                  <Typography className={classes.label}>
                    Appointment date
                  </Typography>
                  <span className={classes.required}>*</span>
                  <FormDatePicker name="appointmentDate" />
                </Grid>
              </Card>

              <Card className={classes.card}>
                <Grid container justify="space-between">
                  <div style={{ display: "flex" }}>
                    <Typography className={classes.label}>
                      Appointment time
                    </Typography>
                    <span className={classes.required}>*</span>
                  </div>
                  {/* <TextField
                    name="time"
                    type="time"
                    defaultValue={values.time}
                    className={classes.textField}
                    fullWidth
                    inputProps={{
                      step: 500, // 1 min
                    }}
                  /> */}
                  <TimePicker
                    fullWidth
                    name="time"
                    mask="__:__"
                    openTo="hours"
                    inputFormat="hh:mm"
                    value={values.appointmentDate}
                    views={["hours", "minutes"]}
                    className={classes.textField}
                    onChange={(newValue) => {
                      setFieldValue("appointmentDate", newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Grid>
              </Card>

              <Card className={classes.card}>
                <Grid container>
                  <Typography className={classes.label}>
                    Appointment type
                  </Typography>
                  <span className={classes.required}>*</span>
                  <FormSelectField
                    name="appointmentType"
                    label="Select"
                    options={[
                      { value: "online", label: "Online" },
                      { value: "clinic", label: "Clinic" },
                    ]}
                  />
                </Grid>
              </Card>

              <Card className={classes.card}>
                <Grid container>
                  <Typography className={classes.label}>
                    Consultation type
                  </Typography>
                  <span className={classes.required}>*</span>
                  <FormSelectField
                    name="consultationType"
                    label="Select"
                    options={[
                      {
                        value: "firstconsultation",
                        label: "First Consultation",
                      },
                      {
                        label: "Scheduled Follow Up",
                        value: "scheduledfollowup",
                      },
                      {
                        label: "Emergency Follow Up",
                        value: "emergencyfollowup",
                      },
                    ]}
                  />
                </Grid>
              </Card>

              <Card className={classes.card}>
                <Grid container>
                  <Typography className={classes.label}>
                    Appointment reason
                  </Typography>
                  <span className={classes.required}>*</span>
                  <FormTextInput
                    name="purpose"
                    variant="standard"
                    placeholder="--"
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                </Grid>
              </Card>

              <Card className={classes.card}>
                <Grid container>
                  <Typography className={classes.label}>
                    Appointment status
                  </Typography>
                  <span className={classes.required}>*</span>
                  <FormSelectField
                    name="appointmentStatus"
                    label="Select"
                    options={[
                      { value: "booked", label: "Booked" },
                      { value: "cancelled", label: "Cancelled" },
                    ]}
                  />
                </Grid>
              </Card>
              <Button
                md
                type="submit"
                fullWidth
                style={{ marginBottom: "20px" }}
              >
                Save
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    loading: state.editAppointmentReducers.loading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        UpdateAppointmentAction,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAppointment);
