import { 
  END_POINT,
  API_METHODS
} from "network/constants";

import api from "utils/api";

const getSensorSummaryForCoach = async (props) => {
  try {
    const { id } = props;
    const url = END_POINT.getSensorSummaryForCoach(id);
    const options = {
      method: API_METHODS.GET
    }

    const response = await api(url, options);
    return response;
  }
  catch (err) {
    throw err;
  }
}

export default getSensorSummaryForCoach;
