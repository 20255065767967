const sumaryTabStyle = () => ({
  tableHeading: {
    fontSize: "16px",
    fontWeight: 700,
    margin: "16px 0",
  },
  checkboxHeading: {
    fontSize: "16px",
    fontWeight: 700,
    margin: "24px 0",
  },
  container: {
    margin: "0 0 24px 0",
  },
});

export default sumaryTabStyle;
