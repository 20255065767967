import React, { useEffect, useState } from "react";
import { Typography, Divider, withStyles } from "@material-ui/core";
import documentStyle from "assets/jss/pages/documentStyle";
import {
  PDF_FILE_TYPE,
  reportSequenceOrders,
  ReportType,
} from "../../../../utils/constants";
import {
  postReportsApi,
  uploadFile,
  fetchReportsApi,
} from "../../../../services/fileService";
import { FileCard } from "@components/molecules/FileCard";
import { showErrorMessage } from "../../../../utils/utility";
import Loader from "@components/atoms/Loader";
import lang from "assets/languages";

const Documents = ({ classes, patientId }) => {
  const [categorizedReports, setCategorizedReports] = useState([]);
  const [nonCategorizedReports, setNonCategorizedReports] = useState([]);
  const [loading, setLoading] = useState(false);

  const todaysDate = new Date();

  const getReports = async () => {
    const allReports = await fetchReportsApi(patientId);

    const finalReports = reportSequenceOrders.map((report, index) => {
      const reportInfo = allReports?.find(
        (details) => details.reportType === report.reportType
      );

      return { ...report, ...reportInfo };
    });

    const nonCategorizedReportTypes = JSON.parse(JSON.stringify(allReports));

    const filternonCategorizedReports = nonCategorizedReportTypes?.filter(
      (random, index) => {
        return random.reportType === ReportType.random;
      }
    );

    setCategorizedReports(finalReports);
    setNonCategorizedReports(filternonCategorizedReports);
  };

  const onChange = (event, key) => {
    setLoading(true);
    if (key === ReportType.random) {
      const fileUploaded = [...event.target.files];

      if (fileUploaded.length > 5) {
        showErrorMessage("Cannot select more than 5 images");
        setLoading(false);
      } else {
        fileUploaded.forEach((file) => {
          onFileUpload(file, key);
        });
      }
    } else {
      const files = event?.target?.files;
      const hasFiles = files && files.length > 0 ? files[0] : null;

      onFileUpload(hasFiles, key);
    }
  };

  useEffect(() => {
    getReports();
  }, []);

  const onFileUpload = async (selectedFile, key) => {
    const fileURL = await uploadFile(selectedFile);
    setLoading(false);
    if (fileURL === undefined) {
      return null;
    }
    const fileDetails = {
      reportType: key,
      fileURL: fileURL,
      fileName: selectedFile.name,
      uploadDate: todaysDate,
    };

    await postReportsApi(fileDetails, patientId);

    getReports();
  };

  return (
    <>
      {loading && <Loader open={true} loaderMessage="Fetching..." />}
      <div className={classes.topContainer}>
        {categorizedReports &&
          categorizedReports?.length > 0 &&
          categorizedReports?.map((report, index) => {
            return (
              <FileCard
                report={report}
                key={index}
                index={index}
                onChange={onChange}
                listOfReports={categorizedReports}
                classes={classes}
              />
            );
          })}

        <Divider style={{ height: "8px" }} />

        <div className={classes.container}>
          <Typography className={classes.text}>Reports</Typography>

          <Typography className={classes.sub_text3}>
            <label style={{ cursor: "pointer" }}>
              <input
                hidden
                type="file"
                multiple="multiple"
                accept={PDF_FILE_TYPE}
                onChange={(event) => onChange(event, ReportType.random)}
              />
              {lang.upload}
            </label>
          </Typography>
        </div>

        {nonCategorizedReports && nonCategorizedReports?.length > 0 && (
          <Divider />
        )}
        {nonCategorizedReports &&
          nonCategorizedReports?.length > 0 &&
          nonCategorizedReports?.map((report, index) => {
            return (
              <FileCard
                report={report}
                key={index}
                index={index}
                onChange={onChange}
                listOfReports={nonCategorizedReports}
                classes={classes}
              />
            );
          })}
      </div>
    </>
  );
};

export default withStyles(documentStyle)(Documents);
