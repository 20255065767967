import {
  GVTableKeyBasedHeading,
  GVTableKeyBasedImage,
  patientGVRowKeys,
  patientProgressRowKeys,
  storeAndProgressTableKeys,
  patientTIRRowKeys,
  progressTableKeyBasedHeading,
  TIRTableKeyBasedHeading,
  TIRTableKeyBasedImage,
} from "./constant";

export const getTextStyle = (val) => {
  const text = val?.toLowerCase();
  switch (text) {
    case "bad":
      return {
        color: "red",
        fontSize: "12px",
        fontWeight: "normal",
      };
    case "good":
      return {
        color: "green",
        fontSize: "12px",
        fontWeight: "normal",
      };
    default:
      return {
        color: "black",
        fontSize: "12px",
        marginTop: "4px",
      };
  }
};

//   {
//    "rowName":"sgdsg",
//    "rowKey":"gxgus",
//    "tablCells":[
//       {
//          "rowCellKey": '',
//          "rowCellValue": '',
//          "placeholder": '',
//          "isRequired": true/false,
//          "textColor": ''
//       },
//    ]
// }

// "timeInRange":{
//        "seventhDay":"20",
//        "fourteenthDay":"30",
//        "interpretation":"",
//        "diet":"",
//        "exercise":""
//     }

export const getPatientTIRTableData = (TIRData, rowKey) => {
  let tableRowData = [];

  if (!TIRData) {
    return TIRData;
  }

  for (const [rowCellKey, rowCellValue] of Object.entries(TIRData)) {
    const tableRow = { rowKey: "", rowName: "", tablCells: [], rowIcon: "" };

    tableRow.rowKey = rowKey;

    if (!Object.keys(patientTIRRowKeys).includes(rowCellKey)) {
      continue;
    }

    const rowHeading = TIRTableKeyBasedHeading[rowCellKey];

    tableRow.rowName = rowHeading;
    tableRow.rowIcon = TIRTableKeyBasedImage[rowCellKey];

    tableRow.tablCells.push({
      rowCellKey: rowCellKey,
      rowCellValue: rowCellValue,
    });

    tableRowData.push(tableRow);
  }

  return tableRowData;
};

// "gv":{
//    "value":"8.76",
//    "stress":"",
//    "sleep":""
// },
export const getWellnessTableData = (GVData, rowKey) => {
  let tableRowData = [];

  if (!GVData) {
    return GVData;
  }

  for (const [rowCellKey, rowCellValue] of Object.entries(GVData)) {
    if (!Object.keys(patientGVRowKeys).includes(rowCellKey)) {
      continue;
    }

    const tableRow = {
      rowKey: "",
      rowName: "",
      tablCells: [],
      rowIcon: "",
    };

    tableRow.rowKey = rowKey;

    const rowHeading = GVTableKeyBasedHeading[rowCellKey];

    tableRow.rowName = rowHeading;
    tableRow.rowIcon = GVTableKeyBasedImage[rowCellKey];

    tableRow.tablCells.push({
      rowCellKey: rowCellKey,
      rowCellValue: rowCellValue,
    });

    tableRowData.push(tableRow);
  }

  return tableRowData;
};

// "timeInRange":{
//    "seventhDay":"20",
//    "fourteenthDay":"30",
//    "interpretation":"",
//    "diet":"",
//    "exercise":""
// },

// "averageGlucose":{
//    "seventhDay":"155",
//    "fourteenthDay":"157",
//    "interpretation":""
// }

export const getPatientProgressTableData = (TIRandAverageData) => {
  let tableRowData = [];

  if (!TIRandAverageData) {
    return TIRandAverageData;
  }

  for (const [rowKey, rowValue] of Object.entries(TIRandAverageData)) {
    const tableRow = { rowKey: "", rowName: "", tablCells: [] };

    tableRow.rowKey = rowKey;

    for (const [rowCellKey, rowCellValue] of Object.entries(rowValue)) {
      if (!Object.keys(patientProgressRowKeys).includes(rowCellKey)) {
        continue;
      }

      const rowHeading = progressTableKeyBasedHeading[rowKey];

      tableRow.rowName = rowHeading;

      const metric =
        rowKey === storeAndProgressTableKeys.timeInRange &&
        (rowCellKey === patientProgressRowKeys.fourteenthDay ||
          rowCellKey === patientProgressRowKeys.seventhDay)
          ? "%"
          : rowKey === storeAndProgressTableKeys.averageGlucose &&
            (rowCellKey === patientProgressRowKeys.fourteenthDay ||
              rowCellKey === patientProgressRowKeys.seventhDay)
          ? "mg/dl"
          : "";

      const value =
        metric && rowCellValue && typeof rowCellValue === "number"
          ? Math.round(rowCellValue)
          : rowCellValue ?? "--";

      const style = metric ? { fontWeight: "700" } : {};

      tableRow.tablCells.push({
        rowCellKey: rowCellKey,
        rowCellValue: `${value} ${metric}`,
        style,
      });
    }

    tableRowData.push(tableRow);
  }

  return tableRowData;
};

export const getHealthyHabitsRiskScoreStyle = (score) => {
  const value = +score;
  if (value > 35) {
    return {
      color: "#F0647D",
      fontWeight: 700,
      fontSize: "16px",
    };
  } else if (value > 22) {
    return {
      color: "#FAAF23",
      fontWeight: 700,
      fontSize: "16px",
    };
  } else {
    return {
      color: "#2DBE41",
      fontWeight: 700,
      fontSize: "16px",
    };
  }
};

export const getGlycemicScoreStyle = (score) => {
  const value = +score;
  if (value > 90) {
    return {
      color: "#2DBE41",
      fontWeight: 700,
      fontSize: "18px",
    };
  } else if (value > 70) {
    return {
      color: "#FAAF23",
      fontWeight: 700,
      fontSize: "18px",
    };
  } else {
    return {
      color: "#F0647D",
      fontWeight: 700,
      fontSize: "18px",
    };
  }
};

export const getDistressScoreStyle = (score) => {
  const value = +score;
  if (value > 36) {
    return {
      color: "#F0647D",
      fontWeight: 700,
      fontSize: "18px",
    };
  } else if (value > 12) {
    return {
      color: "#FAAF23",
      fontWeight: 700,
      fontSize: "18px",
    };
  } else {
    return {
      color: "#2DBE41",
      fontWeight: 700,
      fontSize: "18px",
    };
  }
};
