import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  container: {
    marginTop: "16px",
    marginBottom: "20px",
  },
  table: {
    maxWidth: 762,
    backgroundColor: "white",
    border: "1px solid #D9D9D9",
  },
  editable: {
    backgroundColor: "white",
    border: "1px solid #D9D9D9",
    margin: 0,
    padding: 0,
  },
  wrapText: {
    whiteSpace: "normal",
    wordWrap: "break-word",
    width: "150px",
  },
  tableCellHeading: {
    borderRight: "1px solid #D9D9D9",
    width: "262px",
    justifyContent: "left",
    textAlign: "left",
    fontWeight: 700,
  },
  tableCellField: {
    padding: "10px",
    margin: 0,
    width: "40px",
  },
  tableUnitsField: {
    border: "none",
    padding: 4,
  },
  editableFields: {
    backgroundColor: "white",
    margin: 0,
    padding: 4,
    width: "120px",
    marginLeft: "10px",
    border: "1px solid #D9D9D9",
  },
  multiSelectField: {
    width: "262px",
    justifyContent: "left",
    textAlign: "left",
    padding: "10px",
  },
  tableHeadingTitle: {
    fontSize: "16px",
    fontWeight: 700,
    margin: "24px 0",
  },
}));
