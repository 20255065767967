//Admin
import AllPatients from "@pages/AllPatients";
import Patient from "@pages/Patient";
import CreateWeekPlan from "@pages/CreateWeekPlan";
import MetasenseReportTemplate from "@pages/MetasenseReport/MetasenseReportTemplate";
import CreateReport from "@pages/CreateReport";
import { CreateAutomatedReport } from "@pages/MetasenseReport/clinicAutomatedReport";

export const initRoutes = [
  {
    path: "/patients/:id",
    name: "Specific Patient",
    component: Patient,
    layout: "/coach",
  },
  {
    path: "/create-week-plan/:id",
    name: "Create WeekPlan",
    component: CreateWeekPlan,
    layout: "/coach",
  },
  {
    path: "/metasenseReport",
    name: "MetasenseReport",
    component: MetasenseReportTemplate,
    layout: "/coach",
  },
  {
    path: "/create-report/:id",
    name: "Create Report",
    component: CreateReport,
    layout: "/coach",
  },
  {
    path: "/create-automated-report/:reportId",
    name: "Create Automated Report",
    component: CreateAutomatedReport,
    layout: "/coach",
  },
];

export const coachRoutes = [
  {
    path: "/patients",
    name: "Patients",
    layout: "/coach",
    component: AllPatients,
  },
];
