import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { GraphHeading } from "@pages/MetasenseReport/Components/reportHeading";
import { useStyles } from "@pages/MetasenseReport/style";
import { idealGraph, header } from "@assets/icons";
import PatientInfoTable from "@pages/MetasenseReport/Components/patientInfoTable";
import { reportTypesId } from "@pages/CreateReport/constants";
import lang from "@assets/languages";
import { CheckBoxList } from "@components/molecules/checkBoxList";
import CgmGraph from "@pages/MetasenseReport/Components/cgmGraph";
import { getCheckboxOptions } from "@pages/CreateReport/components/SummaryTab/helpers";
import { TimeInRangeList } from "./timeInRangeList";
import { Box, Typography } from "@material-ui/core";
import { ScoreMetrics } from "./scoreMetrics";
import { CGMGraphLegends } from "./cgmGraphLegends";
import { GraphInsightMask } from "./graphInsightMask";
import { InsightCard } from "./graphInsightMask/insightCard";
import { toTitleCase } from "@utils/stringUtils";
import { TargetRangeLegends } from "./targetRangeLegends";
import { graphHeight } from "@pages/Patient/components/CgmProgressDrawer/SummaryModal";
import {
  graphYIntervals,
  insightImages,
  widthDueToInsightMaskBorders,
  xAxisBarSize,
  yAxisBarSize,
} from "./constants";

const getTimelyMaskData = (insights) => {
  if (!insights) {
    return null;
  }

  const data = [];

  Object.keys(insights).forEach((key) => {
    if (key !== "overall") {
      data.push({
        key: key.toLowerCase(),
        heading: toTitleCase(key),
        pictureSrc: insightImages[key],
        timeSpan: `${insights[key].startHour + ":00"} - ${
          insights[key].endHour + ":00"
        } ${insights[key].startHour >= 12 ? "PM" : "AM"}`,
        ...insights[key],
      });
    }
  });

  return data;
};

const ReportPage1 = (props) => {
  const {
    activeFileType,
    seventhDayGraphData,
    fourteenthDayGraphData,
    patientProgressData,
    summary,
    maxGlucoseValue,
    insights,
    fixedCgmGraphWidth,
  } = props;

  const classes = useStyles();

  const graphWidthRef = useRef();
  const [cgmGraphParentWidth, setCGMGraphParentWidth] = useState(
    window.screen.width
  );

  useEffect(() => {
    if (fixedCgmGraphWidth) {
      return;
    }

    const width = graphWidthRef?.current?.clientWidth;

    if (width) {
      setCGMGraphParentWidth(width);
    }
  }, []);

  const cgmGraphWidth = (fixedCgmGraphWidth || cgmGraphParentWidth) * 0.78;

  const medicalInsight = (
    <div>
      <GraphHeading
        headingText={lang.medicalInterventionsWillBeTakenCareByTheDoctor}
      />
      <div className={classes.medicalInsightContainer}>
        {lang.medicalInsight}
      </div>
    </div>
  );

  const renderSeventhDayGraph = (id) => (
    <CgmGraph
      graphId={`seventhDayGraph - ${id}`}
      graphData={seventhDayGraphData}
      graphHeight={graphHeight}
      graphWidth={cgmGraphWidth}
      showInLegend={false}
    />
  );

  const renderFourteenthDayGraph = (id) => (
    <CgmGraph
      graphId={`fourteenthDayGraph - ${id}`}
      graphData={fourteenthDayGraphData}
      graphHeight={graphHeight}
      graphWidth={cgmGraphWidth}
      showInLegend={false}
    />
  );

  const idealPlottedGraph = (
    <div>
      <GraphHeading headingText={lang.nonDiabeticHealthyReport} />
      <div className={classes.glycemicAndDistressContainer}>
        <img className={classes.idealGraphImage} src={idealGraph} alt="" />
      </div>
    </div>
  );

  const isSeventhDayReport = activeFileType === reportTypesId.seventhDayReport;

  const timlyMaskData = getTimelyMaskData(insights);

  const {
    averageGlucose,
    averageInterquartileRange,
    distressScore,
    timeInRange,
    timeAboveRange,
    timeBelowRange,
    sensor,
  } = patientProgressData;

  const maxGlucosePlotted = Math.max(maxGlucoseValue, sensor?.max || 140) + 40;

  const yAxisPixel = maxGlucosePlotted / graphHeight;

  const goal = sensor?.max;

  const renderCGMGraph = (
    <div
      style={{
        height: 2 * graphHeight + 40,
        display: "flex",
        flexDirection: "column",
      }}
      ref={graphWidthRef}
    >
      <Typography className={classes.cgmGrapHeading}>
        {lang.ambulatoryGlucoseProfile}
      </Typography>
      <Box
        className={classes.cgmGraphWithLegends}
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
        }}
      >
        {sensor && (
          <TargetRangeLegends
            yAxisPixel={yAxisPixel}
            minTargetValue={sensor?.min}
            maxTargetValue={sensor?.max}
            maxGlucosePlotted={maxGlucosePlotted}
            styles={{
              height: graphHeight - xAxisBarSize,
              margin: "3px 0 0 0",
            }}
          />
        )}
        <Box className={classes.cgmGraph}>
          {activeFileType === reportTypesId.seventhDayReport
            ? renderSeventhDayGraph("compare")
            : renderFourteenthDayGraph("compare")}
          {timlyMaskData && (
            <GraphInsightMask
              styles={{ position: "absolute", top: 0, left: yAxisBarSize }}
              data={timlyMaskData}
              grpahWidth={
                cgmGraphWidth - yAxisBarSize - widthDueToInsightMaskBorders
              }
            />
          )}
        </Box>
        <Box className={classes.legendsWithOverallCard}>
          <CGMGraphLegends />
          {insights?.overall && (
            <InsightCard
              styles={{
                width:
                  (cgmGraphWidth -
                    yAxisBarSize -
                    widthDueToInsightMaskBorders) /
                    4 -
                  10,
              }}
              pictureUrl={insights?.overall?.pictureUrl}
              heading={"Overall"}
              state={insights?.overall?.state}
              comment={insights?.overall?.comment}
            />
          )}
        </Box>
      </Box>
    </div>
  );

  const aiqr = isSeventhDayReport
    ? averageInterquartileRange?.seventhDay
    : averageInterquartileRange?.fourteenthDay;
  const gvp = distressScore?.value;

  const glucoseValue = isSeventhDayReport
    ? averageGlucose.sevenDay
    : averageGlucose.fourteenDay;

  const isGlucoseInRange =
    glucoseValue <= sensor?.max && glucoseValue >= sensor?.min;

  return (
    <>
      <img src={header} alt="" className={classes.reportHeader} />
      <Box className={classes.reportPageContent}>
        <div className={classes.patientInfoTableContainer}>
          <PatientInfoTable />
        </div>
        <Box className={classes.glucoseAndTIRContainer}>
          <Box className={classes.averageAndScoreContainer}>
            <Box>
              <Box
                className={`${classes.rowItem} ${classes.averageGlucoseContainer}`}
              >
                <Typography className={classes.averageGlucose}>
                  {lang.averageGlucose}
                </Typography>
                <Typography
                  className={`${classes.averageGlucoseValue} ${
                    isGlucoseInRange
                      ? classes.underTargetRange
                      : classes.aboveTargetRange
                  }`}
                >
                  {`${glucoseValue ?? "--"} ${lang.units["mg/dL"]}`}
                </Typography>
              </Box>
              <Typography className={classes.goal}>
                {`${lang.goal}: ${goal ?? "--"} ${lang.units["mg/dL"]}`}
              </Typography>
            </Box>
            <ScoreMetrics aiqr={aiqr} gvp={gvp} />
          </Box>
          {sensor && (
            <TimeInRangeList
              timeInRange={timeInRange}
              timeAboveRange={timeAboveRange}
              timeBelowRange={timeBelowRange}
              minTargetValue={sensor?.min}
              maxTargetValue={sensor?.max}
            />
          )}
        </Box>
        {renderCGMGraph}
        <Typography className={classes.checkBoxHeading}>
          {lang.cgmBasedInputsAdviceRecommendations}
        </Typography>
        {patientProgressData?.healthyHabitRoadmap && (
          <div className={classes.checkBoxContainer}>
            <CheckBoxList
              type="single"
              checkboxOptions={getCheckboxOptions(patientProgressData, true)}
              helperText="Please check atleast one option from above"
            />
          </div>
        )}
      </Box>
    </>
  );
};

function mapStateToProps(state) {
  return {
    summary: state.createReportReducer.summary,
    activeFileType: state.createReportReducer.activeFileType,
    seventhDayGraphData: state.createReportReducer.seventhDayGraphData,
    insights: state.createReportReducer.insights,
    maxGlucoseValue: state.createReportReducer.maxGlucoseValue,
    minGlucoseValue: state.createReportReducer.minGlucoseValue,
    patientProgressData: state.createReportReducer.patientProgress,
    fourteenthDayGraphData: state.createReportReducer.fourteenthDayGraphData,
  };
}
export default connect(mapStateToProps)(ReportPage1);
