import React from "react";
import { useField } from "formik";
import { FormControl, FormHelperText, TextField } from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import { useStyles } from "./FormAutoCompleteFieldStyle";

const FormAutoCompleteField = (props) => {
  const { name, options, label, value, type, typeOf, disabled = false } = props;
  const classes = useStyles();
  const [field, meta, { setValue }] = useField(name);

  const configSelect = {
    ...field,
    label,
    variant: "outlined",
    fullWidth: true,
    type,
    typeOf,
    onChange: (e, option) => {
      const value = option?.value;
      setValue(value === "" ? null : value);
      props?.onChange(option);
    },
  };

  if (meta && meta.touched && meta.error) {
    configSelect.error = true;
    configSelect.helperText = meta.error;
  }
  return (
    <>
      <FormControl error={configSelect.error} className={classes.formControl}>
        <Autocomplete
          {...field}
          {...configSelect}
          className={classes.root}
          options={options}
          value={value}
          renderInput={(params) => {
            return <TextField {...params} label={label} />;
          }}
          disabled={disabled}
          id="grouped-demo"
        />
        <FormHelperText>{configSelect.helperText}</FormHelperText>
      </FormControl>
    </>
  );
};

export default FormAutoCompleteField;
