import { Box, Typography } from "@material-ui/core";
import Chart from "@pages/Patient/components/CgmProgressDrawer/CgmChart";
import { connect } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { reportTypesId } from "@pages/CreateReport/constants";
import ProgressTable from "../Components/progressTable";
import { header } from "@assets/icons";
import { useStyles } from "../style";

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const ReportPage2 = ({ dailyCGMGraphData, activeFileType }) => {
  const classes = useStyles();

  const graphContainerWidth = useRef();

  const [dayWiseGraphWidth, setDayWiseGraphWidth] = useState(150);

  useEffect(() => {
    const width = graphContainerWidth?.current?.clientWidth;

    if (width) {
      setDayWiseGraphWidth(width / 4.6);
    }
  }, []);

  const week1Data = dailyCGMGraphData
    ? dailyCGMGraphData.slice(0, 7)
    : dailyCGMGraphData;
  const week2Data = dailyCGMGraphData
    ? dailyCGMGraphData.slice(7, 14)
    : dailyCGMGraphData;

  const showWeek2 = activeFileType === reportTypesId.fourteenthDayReport;

  const renderChart = (charData, index) => {
    return (
      <>
        <Box width={dayWiseGraphWidth} key={index}>
          <Box className={classes.dayCGMGraphDateContainer}>
            <Typography className={classes.graphHeading}>
              {charData && charData[0]
                ? days[new Date(charData[0]).getDay()]
                : "--"}
            </Typography>
            <Typography className={classes.graphHeading}>
              {charData && charData[0] ? charData[0] : "--"}
            </Typography>
          </Box>
          {charData && charData[1] && (
            <Chart
              graphData={charData[1] ? charData[1] : []}
              height={110}
              width={dayWiseGraphWidth}
              showXAxisTitle={false}
              axisFontSize={"8"}
              axisFontWeight={"500"}
            />
          )}
        </Box>
        <Box width={12} />
      </>
    );
  };

  const renderChartList = (heading, weekData) => {
    return (
      <>
        <Typography className={classes.weekHeading}>{heading}</Typography>
        <Box
          style={{
            display: "flex",
            flex: 1,
            width: "100%",
            flexWrap: "wrap",
            justifyContent: "flex-start",
            flexDirection: "row",
          }}
        >
          {weekData.map((chartData, i) => {
            return renderChart(chartData, i);
          })}
        </Box>
      </>
    );
  };

  return (
    <div ref={graphContainerWidth}>
      <img src={header} alt="" className={classes.reportHeader} />
      <Box className={classes.reportPageContent}>
        {week1Data && week1Data.length > 0 && (
          <>
            <Typography className={classes.dailyGlucoseProfileHeading}>
              {"DAILY GLUCOSE PROFILES"}
            </Typography>
            <Typography className={classes.dailyGlucoseProfileSubHeading}>
              {"Each Daily Profile Represents a midnight to midnight period"}
            </Typography>

            {renderChartList("Week 1", week1Data)}
          </>
        )}
        {showWeek2 &&
          week2Data &&
          week2Data.length > 0 &&
          renderChartList("Week 2", week2Data)}
        <Typography className={classes.patientProgressHeading}>
          {"PATIENT’S PROGRESS"}
        </Typography>
        <ProgressTable
          showPatientScore={false}
          showPatienProgressHeading={false}
        />
      </Box>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    activeFileType: state.createReportReducer.activeFileType,
    dailyCGMGraphData: state.createReportReducer.dayWiseCGMData,
    patientInfo: state.createReportReducer.patientInfo,
  };
}
export default connect(mapStateToProps)(ReportPage2);
