import { homeStyles } from "assets/jss/pages/homeStyles";
import lang from "assets/languages";
import { connect, useDispatch, useSelector } from "react-redux";
import { actionCreator, HomeActionTypes, searchStrings } from "./constants";
import MyOnboardingTab from "./MyOnboardingTab";
import UpsellingTab from "./UpsellingTab";
const {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} = require("@material-ui/core");

const Home = (props) => {
  const classes = homeStyles();
  const { searchType } = useSelector((state) => state.homeReducers);
  const dispatch = useDispatch();

  const handleToggle = (event) => {
    dispatch(
      actionCreator(HomeActionTypes.setSearchType.SUCCESS, event.target.value)
    );
  };

  return (
    <>
      <div className={classes.tabContainer}>
        <Typography className={classes.title}>{lang.searchPatient}</Typography>
        <FormControl className={classes.fileTypeForm}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onChange={handleToggle}
          >
            <FormControlLabel
              value={searchStrings.phoneNumber}
              control={
                <Radio
                  checked={searchType === searchStrings.phoneNumber}
                  color="primary"
                  size="small"
                />
              }
              classes={{ label: classes.radioLableText }}
              label="Phone Number"
            />
            <FormControlLabel
              value={searchStrings.patientName}
              control={
                <Radio
                  checked={searchType === searchStrings.patientName}
                  color="primary"
                  size="small"
                />
              }
              classes={{ label: classes.radioLableText }}
              label="Patient Name"
            />
          </RadioGroup>
        </FormControl>

        <>
          {searchType === searchStrings.phoneNumber ? (
            <UpsellingTab props={props} />
          ) : (
            <MyOnboardingTab props={props} />
          )}
        </>
      </div>
    </>
  );
};

export default connect()(Home);
