/**
 @desc
 * Centralized unique action types for Login actions and reducers.
 */

import {
  actionCreator,
  API_URL,
  checkHttpStatus,
  createRequestActionTypes,
  jsonApiHeader,
  showSuccessMessage,
  showErrorMessage,
} from "../../../utils/utility";

export {
  jsonApiHeader,
  actionCreator,
  checkHttpStatus,
  showSuccessMessage,
  showErrorMessage,
};

export const POST_LOGIN_API = `${API_URL}/v1/auth/login`;
export const POST_REFRESH_TOKEN_API = `${API_URL}/v1/auth/refresh`;

export const LoginActionTypes = {
  post_Login: createRequestActionTypes("POST_LOGIN"),
  post_RefreshTokenApi: createRequestActionTypes("POST_REFRESH_TOKEN_API"),
};
