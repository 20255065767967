import React from "react";

import lang from "assets/languages";

import { metricUnit } from "utils/constants";
import SectionInfo from "./SectionInfo";

import CanvasJSReact from "../../../../../canvasjs.react";

import {
  getAverageGlucoseGraphOptions,
  getStepsChartOptions,
  getTimeInRangeChartOptions,
} from "../utils";

const { CanvasJSChart } = CanvasJSReact;

const LeftPane = (props) => {
  const { classes, selectedSensor, sensorTimeline, overallSummary, sensorId } =
    props;

  const avgGlucoseOptions = getAverageGlucoseGraphOptions({
    list: overallSummary?.data?.averageGlucoseSummary?.dailyAverageGlucose,
    selectedSensor,
  });

  const avgTimeInRageOptions = getTimeInRangeChartOptions({
    list: overallSummary?.data?.TIRSummary?.dailyTIRSummary,
    selectedSensor,
  });

  const avgStepsOptions = getStepsChartOptions({
    list: overallSummary?.data?.stepsSummary?.dailySteps,
    selectedSensor,
  });

  const avgGlucoseData = {
    val: overallSummary?.data?.averageGlucoseSummary?.weeklyAverage || "--",
    unit: metricUnit.glucose,
  };

  const avgTimeInRange = {
    val: overallSummary?.data?.TIRSummary?.weeklyTIRInHours || "--",
    unit: metricUnit.tir,
  };

  const avgStepsData = {
    val: overallSummary?.data?.stepsSummary?.weeklyAverage || "--",
    unit: metricUnit.steps,
  };
  return (
    <>
      <div id={`avg-glucose-${sensorId}`} className={classes?.sectionWrapper}>
        <SectionInfo
          title={lang.avgGlucose}
          data={avgGlucoseData}
          timeline={sensorTimeline}
          classes={classes}
        />
        <CanvasJSChart options={avgGlucoseOptions} />
      </div>

      <div id={`avg-tir-${sensorId}`} className={classes?.sectionWrapper}>
        <SectionInfo
          title={lang.timeRange}
          data={avgTimeInRange}
          timeline={sensorTimeline}
          classes={classes}
        />
        <CanvasJSChart options={avgTimeInRageOptions} />
      </div>
      <div id={`avg-steps-${sensorId}`} className={classes?.sectionWrapper}>
        <SectionInfo
          title={lang.avgSteps}
          data={avgStepsData}
          timeline={sensorTimeline}
          classes={classes}
        />
        <CanvasJSChart options={avgStepsOptions} />
      </div>
    </>
  );
};

const arePropsEqual = (prevProps, nextProps) => {
  // As now `sensor id` is sent in the response, hence
  // using the `overall summary API` response's entity to memoize the component.
  // Things will not re-render/show the updates), provided there are multiple sensor with same timeline.
  // in that scenario, API implementation has to updated as well.

  const [prevItem] =
    prevProps?.overallSummary?.data?.averageGlucoseSummary
      ?.dailyAverageGlucose || [];
  const [nextItem] =
    nextProps?.overallSummary?.data?.averageGlucoseSummary
      ?.dailyAverageGlucose || [];

  return prevItem?.date === nextItem?.date;
};

const memoizedLeftPane = React.memo(LeftPane, arePropsEqual);

export default memoizedLeftPane;
