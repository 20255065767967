import { HomeActionTypes, searchStrings } from "../constants";

const initialState = {
  loading: false,
  allPatients: null,
  searchType: searchStrings.phoneNumber,
};

const homeReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case HomeActionTypes.get_AllPatients.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case HomeActionTypes.get_AllPatients.SUCCESS:
      return {
        ...state,
        loading: false,
        allPatients: payload,
      };
    case HomeActionTypes.get_AllPatients.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case HomeActionTypes.post_UploadAppointments.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case HomeActionTypes.post_UploadAppointments.SUCCESS:
      return {
        ...state,
        loading: false,
        allPatients: payload,
      };
    case HomeActionTypes.post_UploadAppointments.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case HomeActionTypes.setSearchType.SUCCESS:
      return {
        ...state,
        searchType: payload,
      };
    default:
      return state;
  }
};

export default homeReducers;
