/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useParams } from "react-router";
import { bindActionCreators } from "redux";
import { Form, Formik, Field } from "formik";
import {
  Drawer,
  Dialog,
  Divider,
  makeStyles,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";

import * as Yup from "yup";
import moment from "moment";
import api from "@utils/api";
import Loader from "@components/atoms/Loader";
import stopIcon from "assets/icons/stop.svg";
import keepIcon from "assets/icons/keep.svg";
import MyAppBar from "@components/organisms/AppBar";
import startIcon from "assets/icons/start.svg";
import { PDF_FILE_TYPE } from "@utils/constants";
import CustomButtons from "@components/atoms/Button";
import FormDatePicker from "@components/molecules/FormDatePicker";
import FormSelectField from "@components/molecules/FormSelectField";
import { drawerWidth, showErrorMessage } from "utils/utility";

import ModuleNewLibraryDrawer from "./components/ModuleNewLibraryDrawer/ModuleNewLibraryDrawer";

import CreateNewModuleDrawer from "./components/CreateNewModuleDrawer/CreateNewModuleDrawer";

import {
  GetAllLevelsAction,
  GetAllCategoriesAction,
  GetAllModulesAction,
  PostCreateWeekPlanAction,
  PatchUpdateWeekPlanAction,
  GetMasterTasksByModuleAction,
} from "./actions/CreateWeekPlanActions";
import {
  GetAllWeekPlansAction,
  GetModuleDetailsByWeekPlanAction,
  GetTaskDetailsByModuleAction,
} from "../Patient/actions/PatientActions";
import ConfirmDialog from "./components/ConfirmDialog/ConfirmDialog";
import { SelectedActivityCard } from "./components/SelectedActivityCard/SelectedActivityCard";
import { uploadFile } from "../../services/fileService";
import { getFileNameFromURL } from "../../utils/utility";
import { END_POINT, apiResponses } from "@network/constants";
import { UploadPlan } from "./components/uploadPlan";

const CreateWeekPlanSchema = Yup.object().shape({
  startDate: Yup.string().required("Required"),
  endDate: Yup.string().required("Required"),
});

const fetchTasksByModule = async (id) => {
  try {
    const { data } = await api(`/v1/weekplans/modules/tasks/${id}`, {
      method: "get",
    });
    return data.data;
  } catch (e) {}
  return [];
};

const fetchTasks = async (id) => {
  try {
    const { data } = await api(`/v1/modules/tasks/${id}`, { method: "get" });
    return data.data;
  } catch (e) {}
  return [];
};

const useStyles = makeStyles(() => ({
  mainPanelShift: {
    padding: "20px 170px",
    width: `calc(100% - ${drawerWidth + 40}px)`,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  viewContainer: {
    fontSize: "14px",
    paddingTop: "15px",
    marginLeft: "1rem",
    color: "#595959",
    display: "block",
  },
  viewText: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#2B377E",
    cursor: "pointer",
    textDecoration: "none",
  },
}));

const CreateWeekPlan = (props) => {
  const {
    allWeekPlans,
    loading,
    moduleDetailsByWeekPlan,
    masterTasksByModule,
    history,
  } = props;

  const editingWeekPlan = props.location.state;
  const editingWeekPlanData = props.location.state?.data;
  const isNewWeekPlan = props.location.state?.isNewWeekPlan;
  const isDuplicateWeekPlan = props.location.state?.isDuplicateWeekPlan;

  const selectedPatient = useSelector(
    (state) => state.allPatientsReducers?.selectedPatient
  );
  const patientName =
    selectedPatient?.firstName + " " + (selectedPatient?.lastName || "");

  const patientId = props.location.state?.patientId;

  const duplicateActivities = (editingWeekPlan, sks) => {
    const data =
      editingWeekPlan &&
      editingWeekPlan?.[sks] &&
      editingWeekPlan?.[sks]?.length > 0 &&
      editingWeekPlan?.[sks].map((activity) => {
        const sks = {
          SKS: activity.activityType,
          activityId: activity.activityId,
          category: activity.activityCategory,
          title: activity.activityTitle,
        };
        return sks;
      });
    return data;
  };
  const exerciseData =
    editingWeekPlan &&
    editingWeekPlan?.exercise &&
    editingWeekPlan?.exercise?.length > 0 &&
    editingWeekPlan?.exercise?.map(
      ({
        id,
        createdAt,
        createdBy,
        updatedAt,
        updatedBy,
        weekPlanExerciseId,
        weekplan,
        ...rest
      }) => ({
        ...rest,
        title: rest.videoName,
      })
    );

  const learnData =
    editingWeekPlan &&
    editingWeekPlan?.learn &&
    editingWeekPlan?.learn?.length > 0 &&
    editingWeekPlan?.learn?.map(
      ({
        id,
        createdAt,
        createdBy,
        updatedBy,
        weekPlanBlogId,
        weekplan,
        ...rest
      }) => {
        return {
          ...rest,
          activityId: rest.strapiBlogId,
          updatedAt: rest.updatedAt,
          strapiCategory: rest.category,
          banner: {
            formats: {
              medium: { url: rest.mediumImageURL },
              small: { url: rest.smallImageURL },
              large: { url: rest.largeImageURL },
              thumbnail: { url: rest.thumbnailURL },
            },
          },
        };
      }
    );

  const draftId =
    (history.location.state?.draftedWeekPlan &&
      history.location.state?.draftedWeekPlan?.id) ||
    (editingWeekPlan?.selectedWeekPlanid &&
      editingWeekPlan?.selectedWeekPlanid);

  const [open, setOpen] = useState(false);
  const [view, setView] = useState("module_library");
  const { id: assignedId } = useParams();
  //Master Table
  const [modules] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [activitiesBySks, setActivitiesBySks] = useState({
    start: isDuplicateWeekPlan
      ? duplicateActivities(editingWeekPlan, "start") || []
      : (editingWeekPlan && editingWeekPlan?.start) || [],
    keep: isDuplicateWeekPlan
      ? duplicateActivities(editingWeekPlan, "keep") || []
      : (editingWeekPlan && editingWeekPlan?.keep) || [],
    stop: isDuplicateWeekPlan
      ? duplicateActivities(editingWeekPlan, "stop") || []
      : (editingWeekPlan && editingWeekPlan?.stop) || [],
    learn: isDuplicateWeekPlan
      ? learnData || []
      : (editingWeekPlan && editingWeekPlan?.learn) || [],
    excercise: isDuplicateWeekPlan
      ? exerciseData || []
      : (editingWeekPlan && editingWeekPlan?.exercise) || [],
  });
  const [weekPlans, setWeekPlans] = useState([]);
  const [masterTasks, setMasterTasks] = useState([]);
  const [moduleId, setModuleId] = useState("");
  const [weekPlanModules, setWeekPlanModules] = useState([]);
  const [selectedDietEssentialValue, setSelectedDietEssentialValue] = useState(
    editingWeekPlanData?.recommendedDietEssentials?.[0]?.id
      ? Number(editingWeekPlanData?.recommendedDietEssentials?.[0]?.id)
      : null
  );
  const [dietEssentials, setDietEssentials] = useState(null);

  const classes = useStyles();

  const categoryData = [
    "Phase 1 - Lifestyle Insights",
    "Phase 2 - A Fruitful commitment",
    "Phase 3 - Accelerated recovery",
    "Phase 4 - Charting a better life",
    "Phase 5 - Keeping it going",
  ];

  const moduleData =
    (weekPlanModules.length > 0 &&
      weekPlanModules.find((draft) => draft.id === moduleId)) ||
    (modules.length > 0 && modules.find(({ id }) => id === moduleId));

  useEffect(() => {
    if (draftId) {
      props.GetModuleDetailsByWeekPlanAction(draftId);
    }
  }, [draftId]);

  useEffect(() => {
    const fetchDietEssentials = async () => {
      try {
        const res = await api(END_POINT.getDietEssentialOptions("weekplan"), {
          method: "get",
        });

        if (res.data.status === apiResponses.success) {
          setDietEssentials(res?.data?.data?.recommendedDietEssentials);
        }
      } catch (e) {}
    };

    fetchDietEssentials();
  }, []);

  useEffect(() => {
    props.GetAllWeekPlansAction(assignedId);
  }, []);

  useEffect(() => {
    if (draftId && moduleDetailsByWeekPlan) {
      let draftModules =
        moduleDetailsByWeekPlan?.map((draft) => ({
          id: draft.id,
          module: draft.module,
          category: draft.moduleInfo.category,
          type: draft.moduleInfo.type,
          level: draft.moduleInfo.level,
          snippet: draft.moduleInfo.snippet,
          title: draft.moduleInfo.title,
          reminder: draft.reminder,
          reminderTime: draft.reminderTime,
          reminderRepeat: draft.reminderRepeat,
          reminderEndRepeat: draft.reminderEndRepeat,
        })) || [];

      Promise.all(
        draftModules.map((draft) =>
          fetchTasksByModule(draft.id).then((data) => {
            draftModules = draftModules.map((draftModule) =>
              draftModule.id === draft.id
                ? { ...draftModule, tasks: data }
                : draftModule
            );
          })
        )
      ).then(() => {
        setWeekPlanModules(draftModules);
      });
    }
  }, [moduleDetailsByWeekPlan, draftId]);

  useEffect(() => {
    if (masterTasksByModule) {
      setMasterTasks(masterTasksByModule);
    }
  }, [masterTasksByModule]);

  useEffect(() => {
    if (allWeekPlans && patientId) {
      setWeekPlans(allWeekPlans);
    }
  }, [allWeekPlans, patientId]);

  const createWeekPlan = ({ isDraft, ...values }) => {
    let selectedDietEssential;

    if (selectedDietEssentialValue === null) {
      showErrorMessage("Please select a Diet Essential");

      return;
    }
    if (selectedDietEssentialValue) {
      selectedDietEssential = dietEssentials.find(
        (dietEssential) => dietEssential.id === selectedDietEssentialValue
      );
    }

    const fitnessPlanData =
      editingWeekPlanData?.fitnessPDFLink === values.fitnessPDFLink
        ? {}
        : { fitnessPDFLink: values.fitnessPDFLink };

    let postData = {
      from: values.startDate,
      to: values.endDate,
      patientId: values.patientId,
      isDraft: !!isDraft,
      consultationAttended: values.consultationAttended,
      dietPDFLink: values.dietPDFLink,
      basicRulesPDFLink: values.basicRulesPDFLink,
      phase: values.phase,
      activities: [
        ...(activitiesBySks["start"] || []),
        ...(activitiesBySks["keep"] || []),
        ...(activitiesBySks["stop"] || []),
      ],
      exercises: activitiesBySks["excercise"] || [],
      blogs: (activitiesBySks["learn"] || []).map((blog) => ({
        ...blog,
        strapiBlogId: blog.activityId,
        blogDate: blog.updatedAt,
        smallImageURL: blog?.banner?.formats?.small?.url,
        mediumImageURL: blog?.banner?.formats?.medium?.url,
        largeImageURL: blog?.banner?.formats?.large?.url,
        thumbnailURL: blog?.banner?.formats?.thumbnail?.url,
        category: blog.strapiCategory,
      })),
      recommendedDietEssentials: selectedDietEssential
        ? [selectedDietEssential]
        : [],
      ...fitnessPlanData,
    };

    if (
      weekPlans?.some((weekPlan) => {
        return (
          moment(weekPlan.from).isSame(values.startDate) ||
          moment(weekPlan.to).isSame(values.endDate) ||
          moment(weekPlan.from).isBetween(values.startDate, values.endDate) ||
          moment(weekPlan.to).isBetween(values.startDate, values.endDate)
        );
      })
    ) {
      return showErrorMessage(
        "Cannot overlap with existing weekplans. Please choose another date range."
      );
    }

    props.PostCreateWeekPlanAction(postData, () => {
      history.goBack();
    });
  };

  const updateDraftPlan = ({ isDraft, ...values }) => {
    const fitnessPlanData =
      editingWeekPlanData?.fitnessPDFLink === values.fitnessPDFLink
        ? {}
        : { fitnessPDFLink: values.fitnessPDFLink };

    let postData = {
      weekPlanId: draftId,
      from: values.startDate,
      to: values.endDate,
      patientId: values.patientId,
      isDraft: !!isDraft,
      consultationAttended: values.consultationAttended,
      dietPDFLink: values.dietPDFLink,
      basicRulesPDFLink: values.basicRulesPDFLink,
      phase: values.phase,
      activities: [
        ...(activitiesBySks["start"] || []),
        ...(activitiesBySks["keep"] || []),
        ...(activitiesBySks["stop"] || []),
      ],
      exercises: activitiesBySks["excercise"] || [],
      blogs: (activitiesBySks["learn"] || []).map((blog) => ({
        ...blog,
        strapiBlogId: blog.activityId,
        blogDate: blog.updatedAt,
        smallImageURL: blog?.banner?.formats?.small?.url,
        mediumImageURL: blog?.banner?.formats?.medium?.url,
        largeImageURL: blog?.banner?.formats?.large?.url,
        thumbnailURL: blog?.banner?.formats?.thumbnail?.url,
        category: blog.strapiCategory,
      })),
      ...fitnessPlanData,
    };

    props.PatchUpdateWeekPlanAction(postData, () => {
      history.goBack();
    });
  };

  const handleGoBackToAllPatients = () => {
    history.goBack();
  };

  const handleAddWeekPlanModule = async (value) => {
    const tasks = await fetchTasks(value.id);

    const tasksById = tasks?.map((item) => {
      return {
        task: item.task,
        taskDataEntry: item.taskDataEntry,
      };
    });

    let moduleObj = {
      id: value.id,
      category: value.category,
      type: value.type,
      level: value.level,
      title: value.title,
      reminder: value.reminder === "required" ? true : false,
      reminderTime:
        value.reminder === "required"
          ? moment(value.reminderTime, "hh:mm A").format("HH:mm:ss")
          : null,
      reminderRepeat:
        value.reminder === "required" ? value.reminderRepeat : null,
      reminderEndRepeat:
        value.reminder === "required"
          ? moment(value.reminderEndRepeat).format("YYYY-MM-DD")
          : null,
      tasks: tasksById,
    };

    const weekPlans = [...weekPlanModules, moduleObj];
    setWeekPlanModules(weekPlans);
  };

  const handleAddCustomWeekPlanModule = (value) => {
    let moduleObj = {
      id: value.id,
      category: value.category,
      type: value.type,
      level: value.level,
      title: value.title,
      reminder: value.reminder === "required" ? true : false,
      reminderTime:
        value.reminder === "required"
          ? moment(value.reminderTime, "hh:mm A").format("HH:mm:ss")
          : null,
      reminderRepeat:
        value.reminder === "required" ? value.reminderRepeat : null,
      reminderEndRepeat:
        value.reminder === "required"
          ? moment(value.reminderEndRepeat).format("YYYY-MM-DD")
          : null,
      tasks: value.tasks,
    };

    const weekPlans = [
      ...weekPlanModules.filter(
        (weekPlan) => weekPlan.module !== value.id && weekPlan.id !== value.id
      ),
      moduleObj,
    ];

    setWeekPlanModules(weekPlans);

    handleGoToModuleLibrary();
  };

  const handleRemoveWeekPlanModule = (value) => {
    const weekPlans = weekPlanModules.filter((item) => item.id !== value.id);
    setWeekPlanModules(weekPlans);
  };

  const handleGoToViewModule = (id) => {
    props.GetMasterTasksByModuleAction(id);
    setTasks(weekPlanModules.find(({ module }) => module === id)?.tasks || []);
    setModuleId(id);
    setView("add_module");
  };

  const handleGoToModuleLibrary = () => {
    setView("module_library");
  };

  const handleOpenConfirmDialog = () => {
    setOpen(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpen(false);
  };

  const onRemove = (activity, sks) => {
    setActivitiesBySks((prev) => ({
      ...prev,
      [sks]:
        prev[sks]?.filter((act) => {
          if (isNewWeekPlan) {
            if (sks === "excercise") {
              return act.videoURL !== activity.videoURL;
            } else if (sks === "start" || sks === "keep" || sks === "stop") {
              return +act.activityId !== +activity.activityId;
            }
            return +act.activityId !== +activity.id;
          } else {
            if (sks === "excercise") {
              if (act.weekplan && act.videoURL === activity.videoURL) {
                act.isActive = false;

                return true;
              }
              return act.videoURL !== activity.videoURL;
            } else if (sks === "learn") {
              if (act.weekplan && +act.strapiBlogId === +activity.id) {
                act.isActive = false;
                return true;
              }

              return act.strapiBlogId
                ? +act.strapiBlogId !== +activity.id
                : +act.activityId !== +activity.id;
            }
            return +act.activityId !== +activity.activityId;
          }
        }) || [],
    }));
  };

  const onSave = (activity, sks) => {
    setActivitiesBySks((prev) => {
      const previousActivity =
        prev?.[sks]?.length > 0 &&
        prev?.[sks].some(({ videoURL, activityId, strapiBlogId, isActive }) => {
          if (sks === "excercise" && videoURL === activity.videoURL) {
            if (isActive === false) {
              return false;
            }
            return true;
          } else if (sks === "start" || sks === "keep" || sks === "stop") {
            return +activityId === +activity.activityId;
          } else if (sks === "learn") {
            if (isNewWeekPlan) {
              return +activityId === +activity.id;
            } else {
              if (isActive === false) {
                return false;
              }
              return strapiBlogId
                ? +strapiBlogId === +activity.id
                : +activityId === +activity.id;
            }
          }
        });

      return {
        ...prev,
        [sks]: !previousActivity
          ? [...(prev?.[sks] || []), activity]
          : prev?.[sks].map((act) => {
              if (sks === "start" || sks === "keep" || sks === "stop") {
                if (+act.activityId === +activity.activityId) {
                  return {
                    ...act,
                    ...activity,
                  };
                } else {
                  return {
                    ...act,
                  };
                }
              } else if (sks === "excercise") {
                if (act.videoURL === activity.videoURL) {
                  return {
                    ...act,
                    ...activity,
                  };
                } else {
                  return {
                    ...act,
                  };
                }
              } else if (sks === "learn") {
                if (
                  +act.strapiBlogId
                    ? +act.strapiBlogId === +activity.id
                    : +act.activityId === +activity.id
                ) {
                  return {
                    ...act,
                    ...activity,
                  };
                } else {
                  return {
                    ...act,
                  };
                }
              }
            }) || [],
      };
    });
  };

  const handleDietEssentialChane = (event) => {
    setSelectedDietEssentialValue(Number(event.target.value));
  };

  const renderDietEssentialRadios = () => {
    if (!dietEssentials) {
      return null;
    }

    return (
      <FormControl>
        <FormLabel id="demo-row-radio-buttons-group-label">
          Select Diet Essential:
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={selectedDietEssentialValue}
          onChange={handleDietEssentialChane}
        >
          {dietEssentials.map((dietEssential) => {
            const { id, name, items } = dietEssential;

            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <FormControlLabel value={id} control={<Radio />} label={name} />
                {items &&
                  items.map((product) => {
                    const { name, quantity } = product;

                    return (
                      <Typography
                        id={id}
                        style={{
                          fontSize: "14px",
                          fontWeight: 700,
                          marginLeft: "30px",
                        }}
                      >
                        {`${name} X ${quantity}`}
                      </Typography>
                    );
                  })}
              </div>
            );
          })}
        </RadioGroup>
      </FormControl>
    );
  };

  return (
    <>
      {loading && <Loader open={true} loaderMessage="Fetching..." />}
      {open && (
        <Dialog open={open} onClose={handleCloseConfirmDialog}>
          <ConfirmDialog
            handleGoBackToAllPatients={handleGoBackToAllPatients}
            handleCloseConfirmDialog={handleCloseConfirmDialog}
          />
        </Dialog>
      )}
      <Formik
        initialValues={{
          startDate:
            isNewWeekPlan || isDuplicateWeekPlan
              ? new Date()
              : moment(editingWeekPlanData?.from),
          endDate:
            isNewWeekPlan || isDuplicateWeekPlan
              ? moment(new Date()).add(6, "days")
              : moment(editingWeekPlanData?.to),
          dietPDFLink: editingWeekPlanData?.dietPDFLink || "",
          fitnessPDFLink: editingWeekPlanData?.fitnessPDFLink || "",
          basicRulesPDFLink: editingWeekPlanData?.basicRulesPDFLink || "",
          basicRulesPDF:
            getFileNameFromURL(
              editingWeekPlanData && editingWeekPlanData?.basicRulesPDFLink
            ) || "",
          phase: editingWeekPlanData?.phase || "",
          consultationAttended: editingWeekPlanData?.consultationAttended || "",
          patientId: patientId,
        }}
        validationSchema={CreateWeekPlanSchema}
        onSubmit={
          draftId && !isDuplicateWeekPlan ? updateDraftPlan : createWeekPlan
        }
      >
        {({ handleSubmit, setFieldValue, values }) => {
          const onUploadPlanPdf = async (event, formKey) => {
            const files = event?.target?.files;
            const hasFiles = files && files.length > 0 ? files[0] : null;

            if (!hasFiles || !formKey) {
              return;
            }
            const fileURL = await uploadFile(hasFiles);
            setFieldValue(formKey, fileURL);
          };

          const handleChangeDosDonts = async (event) => {
            const files = event?.target?.files;
            const hasFiles = files && files.length > 0 ? files[0] : null;

            if (!hasFiles) {
              return;
            }
            const fileURL = await uploadFile(hasFiles);

            setFieldValue("basicRulesPDFLink", fileURL);
            setFieldValue("basicRulesPDF", hasFiles.name);
          };

          return (
            <Form className={classes.form}>
              {view === "module_library" && (
                <Drawer
                  open
                  anchor="right"
                  variant="persistent"
                  className={classes.drawer}
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                >
                  <ModuleNewLibraryDrawer
                    onRemove={onRemove}
                    onSave={onSave}
                    selectedActivities={activitiesBySks}
                    handleGoToViewModule={handleGoToViewModule}
                    handleAddWeekPlanModule={handleAddWeekPlanModule}
                    handleRemoveWeekPlanModule={handleRemoveWeekPlanModule}
                    isNewWeekPlan={isNewWeekPlan}
                    isDuplicateWeekPlan={isDuplicateWeekPlan}
                  />
                </Drawer>
              )}
              {view === "add_module" && (
                <Drawer
                  className={classes.drawer}
                  variant="persistent"
                  anchor="right"
                  open={true}
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                >
                  <CreateNewModuleDrawer
                    handleGoToModuleLibrary={handleGoToModuleLibrary}
                    handleAddCustomWeekPlanModule={
                      handleAddCustomWeekPlanModule
                    }
                    moduleData={moduleData}
                    taskDetailsByModule={tasks}
                    masterTasks={masterTasks}
                    endDate={values.endDate}
                  />
                </Drawer>
              )}
              <MyAppBar
                value={"create-week-plan"}
                handleSubmit={handleSubmit}
                handleSaveAsDraft={() =>
                  draftId && !isDuplicateWeekPlan
                    ? updateDraftPlan({ ...values, isDraft: true })
                    : createWeekPlan({ ...values, isDraft: true })
                }
                handleOpenConfirmDialog={handleOpenConfirmDialog}
                isNewWeekPlan={isNewWeekPlan}
                isDuplicateWeekPlan={isDuplicateWeekPlan}
              />

              <div className={classes.mainPanelShift}>
                <Typography style={{ fontSize: "24px", fontWeight: 700 }}>
                  WeekPlan for {patientName}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    alignItems: "start",
                    border: "1px solid #ccc",
                    padding: "16px",
                    backgroundColor: "white",
                    width: "564px",
                    marginTop: "1rem",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: 700,
                        width: "100px",
                      }}
                    >
                      From
                    </Typography>

                    <FormDatePicker
                      name="startDate"
                      minDate={
                        isNewWeekPlan || isDuplicateWeekPlan ? new Date() : ""
                      }
                      disabled={!isNewWeekPlan && !isDuplicateWeekPlan}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "0.5rem",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: 700,
                        width: "100px",
                      }}
                    >
                      To
                    </Typography>
                    <FormDatePicker
                      name="endDate"
                      minDate={
                        isNewWeekPlan || isDuplicateWeekPlan
                          ? moment(values.startDate).add(5, "days")
                          : ""
                      }
                      disabled={!isNewWeekPlan && !isDuplicateWeekPlan}
                    />
                  </div>
                </div>
                {/* Add forms here */}
                <div
                  style={{
                    display: "flex",
                    border: "1px solid #ccc",
                    padding: "16px",
                    backgroundColor: "white",
                    width: "564px",
                    marginTop: "1rem",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: 700,
                        width: "74px",
                      }}
                    >
                      Phase
                    </Typography>

                    <div style={{ width: "320px" }}>
                      <FormSelectField
                        name="phase"
                        label="Select"
                        options={categoryData}
                        typeOf="auth"
                        type="patient"
                        isNewWeekPlan={isNewWeekPlan}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    alignItems: "start",
                    border: "1px solid #ccc",
                    padding: "16px",
                    backgroundColor: "white",
                    width: "564px",
                    marginTop: "1rem",
                  }}
                >
                  <label style={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: 700,
                        width: "40px",
                      }}
                    >
                      <Field
                        type="checkbox"
                        name="consultationAttended"
                        disabled={
                          !isNewWeekPlan && !isDuplicateWeekPlan ? true : false
                        }
                      />
                    </Typography>

                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: 700,
                        width: "400px",
                      }}
                    >
                      You have marked the consultation done with the Patient for
                      this Week Plan
                    </Typography>
                  </label>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "1rem",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: 700,
                      width: "400px",
                      color: "#00A591",
                    }}
                  >
                    <img height={12} width={12} src={startIcon} alt="" /> START
                  </Typography>
                </div>
                {activitiesBySks["start"] &&
                activitiesBySks["start"]?.length > 0 ? (
                  <>
                    {activitiesBySks["start"]?.map((activity) => {
                      return (
                        <SelectedActivityCard
                          title={activity.title || activity.activityTitle}
                          key={activity.activityId}
                          category={
                            activity.category || activity.activityCategory
                          }
                          nonEditable={
                            activity.nonEditable && !isDuplicateWeekPlan
                          }
                        />
                      );
                    })}
                    <Typography
                      style={{
                        fontSize: "14px",
                        color: "#8C8C8C",
                        marginTop: "1rem",
                      }}
                    >
                      You can add more cards from the right panel.
                    </Typography>
                  </>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-evenly",
                      alignItems: "start",
                      border: "1px solid #ccc",
                      padding: "16px",
                      backgroundColor: "white",
                      width: "564px",
                      marginTop: ".5rem",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "14px",
                        color: "#8C8C8C",
                      }}
                    >
                      Select a card from the right panel.
                    </Typography>
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "1rem",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: 700,
                      width: "564px",
                      color: "#23AFD7",
                    }}
                  >
                    <img height={12} width={12} src={keepIcon} alt="" /> KEEP
                  </Typography>
                </div>
                {activitiesBySks["keep"] &&
                activitiesBySks["keep"]?.length > 0 ? (
                  <>
                    {activitiesBySks["keep"]?.map((activity) => (
                      <SelectedActivityCard
                        title={activity.title || activity.activityTitle}
                        key={activity.activityId}
                        category={
                          activity.category || activity.activityCategory
                        }
                        nonEditable={
                          activity.nonEditable && !isDuplicateWeekPlan
                        }
                      />
                    ))}
                    <Typography
                      style={{
                        fontSize: "14px",
                        color: "#8C8C8C",
                        marginTop: "1rem",
                      }}
                    >
                      You can add more cards from the right panel.
                    </Typography>
                  </>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-evenly",
                      alignItems: "start",
                      border: "1px solid #ccc",
                      padding: "16px",
                      backgroundColor: "white",
                      width: "564px",
                      marginTop: ".5rem",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "14px",
                        color: "#8C8C8C",
                      }}
                    >
                      Select a card from the right panel.
                    </Typography>
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "1rem",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: 700,
                      width: "564px",
                      color: "#F0647D",
                    }}
                  >
                    <img height={12} width={12} src={stopIcon} alt="" /> STOP
                  </Typography>
                </div>
                {activitiesBySks["stop"] &&
                activitiesBySks["stop"]?.length > 0 ? (
                  <>
                    {activitiesBySks["stop"]?.map((activity) => (
                      <SelectedActivityCard
                        title={activity.title || activity.activityTitle}
                        key={activity.activityId}
                        category={
                          activity.category || activity.activityCategory
                        }
                        nonEditable={
                          activity.nonEditable && !isDuplicateWeekPlan
                        }
                      />
                    ))}
                    <Typography
                      style={{
                        fontSize: "14px",
                        color: "#8C8C8C",
                        marginTop: "1rem",
                      }}
                    >
                      You can add more cards from the right panel.
                    </Typography>
                  </>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-evenly",
                      alignItems: "start",
                      border: "1px solid #ccc",
                      padding: "16px",
                      backgroundColor: "white",
                      width: "564px",
                      marginTop: ".5rem",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "14px",
                        color: "#8C8C8C",
                      }}
                    >
                      Select a card from the right panel.
                    </Typography>
                  </div>
                )}
                <UploadPlan
                  heading={"DIET PLAN"}
                  onRemove={() => {
                    setFieldValue("dietPDFLink", "");
                  }}
                  onUpload={(event) => onUploadPlanPdf(event, "dietPDFLink")}
                  pdfLink={values.dietPDFLink}
                  pdfName={getFileNameFromURL(values.dietPDFLink) || ""}
                />
                <UploadPlan
                  heading={"FITNESS PLAN"}
                  onRemove={() => {
                    setFieldValue("fitnessPDFLink", "");
                  }}
                  onUpload={(event) => onUploadPlanPdf(event, "fitnessPDFLink")}
                  pdfLink={values.fitnessPDFLink}
                  pdfName={getFileNameFromURL(values.fitnessPDFLink) || ""}
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "1rem",
                  }}
                >
                  {dietEssentials && renderDietEssentialRadios()}
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "1rem",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: 700,
                      width: "564px",
                    }}
                  >
                    LEARN
                  </Typography>
                </div>
                {activitiesBySks["learn"] &&
                activitiesBySks["learn"]?.length > 0 &&
                activitiesBySks["learn"]?.filter((activity) => {
                  return activity.isActive !== false;
                })?.length > 0 ? (
                  <>
                    {activitiesBySks["learn"]
                      ?.filter((activity) => {
                        return activity.isActive !== false;
                      })
                      ?.map((activity) => (
                        <SelectedActivityCard
                          title={activity.title}
                          key={activity.activityId}
                          category={activity.category}
                        />
                      ))}
                    <Typography
                      style={{
                        fontSize: "14px",
                        color: "#8C8C8C",
                        marginTop: "1rem",
                      }}
                    >
                      You can add more cards from the right panel.
                    </Typography>
                  </>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-evenly",
                      alignItems: "start",
                      border: "1px solid #ccc",
                      padding: "16px",
                      backgroundColor: "white",
                      width: "564px",
                      marginTop: ".5rem",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "14px",
                        color: "#8C8C8C",
                      }}
                    >
                      Select a card from the right panel.
                    </Typography>
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "1rem",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: 700,
                      width: "564px",
                    }}
                  >
                    EXERCISE
                  </Typography>
                </div>
                {activitiesBySks["excercise"] &&
                activitiesBySks["excercise"]?.length > 0 &&
                activitiesBySks["excercise"]?.filter((activity) => {
                  return activity.isActive !== false;
                })?.length > 0 ? (
                  <>
                    {activitiesBySks["excercise"]
                      ?.filter((activity) => {
                        return activity.isActive !== false;
                      })
                      ?.map((activity) => (
                        <div
                          key={activity.activityId}
                          style={{
                            border: "1px solid #ccc",
                            padding: "16px 6px 16px 16px",
                            backgroundColor: "white",
                            width: "574px",
                            marginTop: ".5rem",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <Typography
                              style={{
                                fontSize: "14px",
                                color: "#595959",
                                fontWeight: 700,
                              }}
                            >
                              {activity.videoName}
                            </Typography>
                            <Typography
                              style={{
                                fontSize: "12px",
                                color: "#595959",
                                marginTop: "8px",
                                width: "150px",
                              }}
                            >
                              {activity.videoSchedule}
                            </Typography>
                            <Typography
                              style={{
                                fontSize: "12px",
                                color: "#595959",
                                marginTop: "8px",
                                width: "150px",
                              }}
                            >
                              For {activity.duration}
                            </Typography>
                          </div>
                        </div>
                      ))}
                    <Typography
                      style={{
                        fontSize: "14px",
                        color: "#8C8C8C",
                        marginTop: "1rem",
                      }}
                    >
                      You can add more cards from the right panel.
                    </Typography>
                  </>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-evenly",
                      alignItems: "start",
                      border: "1px solid #ccc",
                      padding: "16px",
                      backgroundColor: "white",
                      width: "564px",
                      marginTop: ".5rem",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "14px",
                        color: "#8C8C8C",
                      }}
                    >
                      Select a card from the right panel.
                    </Typography>
                  </div>
                )}
                {/* Add forms here */}
                {weekPlanModules?.map((moduleItem, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        border: "1px solid #ccc",
                        backgroundColor: "white",
                        width: "604px",
                        marginTop: "1rem",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          cursor: "pointer",
                          padding: "16px",
                        }}
                        onClick={() => handleGoToViewModule(moduleItem.id)}
                      >
                        <div>
                          <Typography
                            style={{ fontSize: "14px", fontWeight: 700 }}
                          >
                            {moduleItem.title || "N/A"}
                          </Typography>
                          <Typography style={{ fontSize: "12px" }}>
                            {moduleItem.type || "N/A"} •{" "}
                            {moduleItem.category || "N/A"} •
                            {moduleItem.level || "N/A"}
                          </Typography>
                        </div>
                        <ChevronRight style={{ color: "#2B377E" }} />
                      </div>
                      <Divider />
                      <div style={{ display: "flex", padding: "16px" }}>
                        <Typography
                          style={{
                            fontSize: "14px",
                            color: "#2B377E",
                            fontWeight: 700,
                            cursor: "pointer",
                          }}
                          onClick={() => handleGoToViewModule(moduleItem.id)}
                        >
                          View Details
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "14px",
                            color: "#FF5522",
                            fontWeight: 700,
                            cursor: "pointer",
                            marginLeft: "1rem",
                          }}
                          onClick={() => handleRemoveWeekPlanModule(moduleItem)}
                        >
                          Remove
                        </Typography>
                      </div>
                    </div>
                  );
                })}
                <Typography
                  style={{
                    fontSize: "14px",
                    color: "#262626",
                    marginTop: "1rem",
                    marginBottom: ".5rem",
                    fontWeight: 700,
                  }}
                >
                  BASIC DOs and DON’Ts
                </Typography>
                {values.basicRulesPDFLink ? (
                  <div
                    style={{
                      width: "564px",
                      display: "flex",
                      marginTop: ".5rem",
                      alignItems: "start",
                    }}
                  >
                    <Typography>
                      <CustomButtons
                        md_border
                        style={{
                          padding: "25px 17px",
                          backgroundColor: "#fff",
                          fontWeight: 700,
                        }}
                      >
                        ✔ File uploaded
                      </CustomButtons>
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "14px",
                        paddingTop: "15px",
                        marginLeft: "1rem",
                        color: "#595959",
                        display: "block",
                      }}
                    >
                      {values.basicRulesPDF}
                    </Typography>
                    <Typography className={classes.viewContainer}>
                      <a
                        href={values.basicRulesPDFLink}
                        target="_blank"
                        rel="noreferrer"
                        className={classes.viewText}
                      >
                        View
                      </a>
                    </Typography>
                    <div
                      onClick={() => {
                        setFieldValue("basicRulesPDF", "");
                        setFieldValue("basicRulesPDFLink", "");
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "14px",
                          fontWeight: 700,
                          color: "#F0647D",
                          paddingTop: "15px",
                          marginLeft: "1rem",
                          cursor: "pointer",
                          display: "block",
                        }}
                      >
                        Remove
                      </Typography>
                    </div>
                  </div>
                ) : (
                  <label
                    style={{
                      fontWeight: 700,
                      padding: "17px",
                      fontSize: "14px",
                      color: "#2B377E",
                      cursor: "pointer",
                      marginTop: "10px",
                      marginBottom: "20px",
                      alignItems: "center",
                      display: "inline-grid",
                      backgroundColor: "#fff",
                      border: "1px solid #D9D9D9",
                    }}
                  >
                    <input
                      hidden
                      type="file"
                      accept={PDF_FILE_TYPE}
                      onChange={handleChangeDosDonts}
                    />
                    UPLOAD PDF
                  </label>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

function mapStateToProps(state) {
  return {
    loading: state.createWeekPlanReducers.loading,
    allLevels:
      state.createWeekPlanReducers.allLevels !== null &&
      state.createWeekPlanReducers.allLevels.data,
    allWeekPlans:
      state.patientReducers.allWeekPlans !== null &&
      state.patientReducers.allWeekPlans.data,
    moduleDetailsByWeekPlan:
      state.patientReducers.moduleDetailsByWeekPlan !== null &&
      state.patientReducers.moduleDetailsByWeekPlan.data,
    taskDetailsByModule:
      state.patientReducers.taskDetailsByModule !== null &&
      state.patientReducers.taskDetailsByModule.data,
    masterTasksByModule:
      state.createWeekPlanReducers.masterTasksByModule !== null &&
      state.createWeekPlanReducers.masterTasksByModule.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        GetAllWeekPlansAction,
        GetAllLevelsAction,
        GetAllCategoriesAction,
        GetAllModulesAction,
        PostCreateWeekPlanAction,
        GetModuleDetailsByWeekPlanAction,
        GetTaskDetailsByModuleAction,
        PatchUpdateWeekPlanAction,
        GetMasterTasksByModuleAction,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateWeekPlan);
