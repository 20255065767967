import {
  afternoonInsight,
  eveningInsight,
  morningInsight,
  nouctralInsight,
} from "@assets/icons";

export const widthDueToInsightMaskBorders = 10;
export const yAxisBarSize = 55;
export const xAxisBarSize = 42;
export const insightCardPadding = 20;
export const graphYIntervals = 40;

export const insightKeys = {
  overall: "overall",
  afternoon: "afternoon",
  evening: "evening",
  morning: "morning",
  nocturnal: "nocturnal",
};

export const insightImages = {
  [insightKeys.evening]: eveningInsight,
  [insightKeys.morning]: morningInsight,
  [insightKeys.afternoon]: afternoonInsight,
  [insightKeys.nocturnal]: nouctralInsight,
};
