import lang from "assets/languages";
import Button from "@components/atoms/Button";
import { discountTableColumns } from "pages/AdminTabs/constants";
import { useStyles } from "./style";

export const DiscountTable = ({ tableData, handleDelete }) => {
  const classes = useStyles();
  let columns = Object.keys(tableData[0]);
  columns.push(discountTableColumns.delete);
  columns = columns.filter((column) => {
    return (
      column === discountTableColumns.id ||
      column === discountTableColumns.amount ||
      column === discountTableColumns.delete
    );
  });

  const renderHeading = () => {
    return columns.map((data) => {
      return (
        <th className={classes.tableHeading} key={data}>
          {data?.toUpperCase()}
        </th>
      );
    });
  };

  const renderRow = () => {
    return tableData.map((data, index) => {
      return (
        <tr key={index}>
          {columns.map((columnName) => {
            return (
              <>
                {columnName === discountTableColumns.delete ? (
                  <td className={classes.tableRow} key={data[columnName]}>
                    <Button
                      onClick={() => {
                        handleDelete(data?.id);
                      }}
                    >
                      {lang.delete}
                    </Button>
                  </td>
                ) : (
                  <td className={classes.tableRow} key={data[columnName]}>
                    <div className={classes.tableCell}>{data[columnName]}</div>
                  </td>
                )}
              </>
            );
          })}
        </tr>
      );
    });
  };

  return (
    <div className={classes.tableContainer}>
      <table className={classes.table}>
        <thead>
          <tr>{renderHeading()}</tr>
        </thead>
        <tbody>{renderRow()}</tbody>
      </table>
    </div>
  );
};
