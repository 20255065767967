import React, { useMemo, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

import ActivityComponent from "@components/molecules/ActivityComponent";
import { makeStyles } from "@material-ui/core/styles";

function getRandomColor() {
  const colors = ["#23AFD7", "#00A591", "#FAAF23", "#1978C8", "#2DBE41"];
  return colors[Math.floor(Math.random() * colors.length)];
}

const useStyles = makeStyles({
  detailsContainer: {
    flexDirection: "column",
  },
});

export function Activities({ classes, progressCard, activeWeek }) {
  const [isExpanded, setIsExpanded] = useState(true);
  const preparedClasses = useStyles();

  const activities = useMemo(
    () =>
      progressCard?.activityScoresBreakdown?.map((item) => ({
        ...item,
        bg: getRandomColor(),
      })),
    [progressCard]
  );

  const totalActivityScore =
    progressCard?.maximumPointsForEachActivity *
    activeWeek?.activityType?.length;

  return (
    <Accordion
      expanded={isExpanded}
      onChange={(_, expanded) => setIsExpanded(expanded)}
    >
      <AccordionSummary>
        <Box className={classes.activity} style={{ width: "100%" }}>
          <Typography
            className={classes.activityText}
            style={{ display: "flex" }}
          >
            Activities &nbsp;&nbsp;
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Typography>
          <Typography className={classes.subTextMetric}>
            {progressCard?.totalActivityScore}/{totalActivityScore}
            {/* 100/{progressCard?.totalActivityScore} */}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails className={preparedClasses.detailsContainer}>
        {activities?.map((activity) => (
          <ActivityComponent
            classes={classes}
            bg={activity.bg}
            text={
              activity?.activityTitle ?? activity?.title ?? "Start Activity"
            }
            number={`${activity?.score ?? 0}/${
              progressCard?.maximumPointsForEachActivity
            }`}
            key={activity.id}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  );
}
