import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  table: {
    border: "1px solid #c3c3c3",
    borderSpacing: "0",
    borderRight: "0px",
    borderBottom: "0px",
    textAlign: "center",
    tableLayout: "fixed",
    width: "100%",
    overflow: "scroll",
  },
  tableHeading: {
    borderBottom: "1px solid #c3c3c3",
    padding: "0.5rem",
    borderRight: "1px solid #c3c3c3",
  },
  tableRow: {
    borderBottom: "1px solid #c3c3c3",
    padding: "0.5rem",
    borderRight: "1px solid #c3c3c3",
    whiteSpace: "normal",
  },
  tableContainer: { maxHeight: "300px", overflow: "scroll" },
  tableCell: { overflowWrap: "break-word" },
}));
