const confirmPopupStyle = (theme) => ({
  mainContainer: { width: "600px", height: "350px" },
  dialogTextBox: { padding: "20px", display: "flex", justifyContent: "center" },
  dialogText: { fontSize: "16px", fontWeight: 500 },
  buttonsBox: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px 20px",
    marginTop: "80px",
  },
  buttonYes: {
    marginLeft: "10px",
  },
  dropDownContainer: {
    padding: "20px",
    margin: "auto",
  },
  dropDown: {
    border: "1px solid black",
    marginLeft: "2px",
  },
});

export default confirmPopupStyle;
