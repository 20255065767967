const textInputFieldStyle = () => ({
  root: {
    minWidth: "50%",
    width: "20px",
    // minHeight: "54px",
    "& .MuiInputBase-root": {
      fontSize: "14px",
      color: "#000000",
      marginLeft: 0,
      marginRight: 0,
      borderRadius: 0,
      border: "1px solid #D9D9D9",
      "&.MuiInput-underline": {
        padding: "5px 2px",
        border: "0px",
        borderBottom: "1px solid #D9D9D9",
        transition: "border-color 0.2s",
      },
      "&.Mui-focused": {
        borderColor: "#333",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: `0px !important`,
      outline: "0 !important",
    },
    "& .Mui-disabled": {
      backgroundColor: "whitesmoke",
      color: "grey",
    },
  },
});

export default textInputFieldStyle;
