import { Box, Typography } from "@material-ui/core";
import { startIcon } from "assets/icons";
import React from "react";

export const BoxComponent = ({ classes, data, bg, icon }) => {
  return (
    <>
      <Box className={classes.box} style={{ background: bg }}>
        <img src={icon} alt="star" color="white" />
        <ul className={classes.content}>
          {data?.map((items) => (
            <li key={items.id} className={classes.contentText}>
              {items.text}
            </li>
          ))}
        </ul>
      </Box>
    </>
  );
};
