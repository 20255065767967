/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useMemo } from "react";
// import PropTypes from "prop-types";
import { TextField, withStyles } from "@material-ui/core";
import { useField } from "formik";
import textInputFieldStyle from "../../../assets/jss/components/textInputFieldStyle";
import { isMobile } from "utils/utility";

export const onlyNumbers = /[^0-9]+/g;
export const onlyDecimal = /[^.0-9]/g;

const FormTextInput = ({
  name,
  CHARACTER_LIMIT,
  restrict,
  type,
  typeOf,
  maxLength,
  multiline,
  ...otherProps
}) => {
  const [field, meta, { setValue }] = useField(name);

  const { classes } = otherProps;
  const configTextField = {
    ...field,
    variant: "outlined",
    ...otherProps,
    type,
    typeOf,
    maxLength,
    fullWidth: true,
    inputProps: {
      maxLength: CHARACTER_LIMIT,
    },
    helperText:
      CHARACTER_LIMIT &&
      `Characters left: ${CHARACTER_LIMIT - field.value.length}`,
  };

  if (meta && meta.touched && meta.error) {
    configTextField.error = true;
    configTextField.helperText = meta.error;
  }

  const restricRegex = useMemo(
    () =>
      restrict ||
      (type === "number"
        ? onlyNumbers
        : type === "decimal"
        ? onlyDecimal
        : undefined),
    [type, restrict]
  );

  const onChangeHander = useCallback(
    (e) => {
      let text = e.target.value;

      setValue(
        restricRegex
          ? text.replace(restricRegex, "")?.slice(0, maxLength)
          : e.target.value
      );
    },
    [restricRegex, maxLength]
  );

  return (
    <TextField
      className={classes.root}
      style={{ width: typeOf === "auth" ? "100%" : "20px" }}
      {...configTextField}
      onChange={(e) => {
        onChangeHander(e);
        otherProps?.onChange?.(e);
      }}
      type={!isMobile() && type === "number" ? "text" : type}
      pattern={type === "number" ? "[0-9]*" : undefined}
      multiline={multiline}
    />
  );
};

export default withStyles(textInputFieldStyle)(FormTextInput);
