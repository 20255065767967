import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@material-ui/core";
import lang from "assets/languages";
import { addOnProductNames } from "@pages/AdminTabs/constants";
import { addOnProductIds } from "@pages/AdminTabs/constants";
import { useEffect, useState } from "react";
import CustomButtons from "@components/atoms/Button";
import {
  addPatientForms,
  DELIVERY_MODES,
  etaTableHeading,
} from "@pages/AddPatient/constants";
import api from "@utils/api";
import { apiResponses, API_METHODS, END_POINT } from "@network/constants";
import { notyf } from "@utils/utility";
import Button from "@components/atoms/Button";
import moment from "moment";
import Loader from "@components/atoms/Loader";

const initalDeliveryDetails = {
  deliveryMode: null,
  deliveryETA: null,
  showETAError: false,
  showDeliveryModeError: false,
};

const NewAddonDialog = ({
  classes,
  handleCancelAddonDialog,
  handleSaveAddon,
  patientBasicDetails,
}) => {
  const [load, setLoad] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(
    addOnProductIds[addOnProductNames.libreOneSelfReading]
  );
  const handleToggle = (event) => {
    setSelectedProduct(+event?.target?.value);
  };

  const [deliveryModes, setDeliveryModes] = useState([]);

  const [delievryDetails, setDeliveryDetails] = useState(initalDeliveryDetails);

  const handleSubmit = () => {
    if (
      !delievryDetails?.deliveryETA &&
      delievryDetails?.deliveryMode?.name !== DELIVERY_MODES.clinic
    ) {
      notyf.error("Please check ETA");
      return;
    }
    handleSaveAddon(selectedProduct, delievryDetails);
  };

  useEffect(() => {
    getDeliveryModeDropDowns();
  }, []);

  const getDeliveryModeDropDowns = async () => {
    try {
      setLoad(true);
      const res = await api(
        END_POINT.getDeliveryModes(addPatientForms.createPatientOrder),
        {
          method: API_METHODS.GET,
        }
      );
      setLoad(false);
      if (res.data.status === apiResponses.success) {
        setDeliveryModes(res.data?.data?.deliveryModes || []);
      } else {
        notyf.error("Something went wrong");
      }
    } catch (e) {
      setLoad(false);
      notyf.error(e.message || "something went wrong");
    }
  };

  const handleDeliveryModeChange = (event) => {
    setDeliveryDetails({
      ...initalDeliveryDetails,
      deliveryMode: event.target.value,
    });
  };

  const handleCheckETA = async (deliveryModeId) => {
    try {
      setLoad(true);
      const { data: resData } = await api(
        END_POINT.checkDeliveryETA(
          patientBasicDetails?.patientAddress?.pincode,
          deliveryModeId
        ),
        {
          method: API_METHODS.GET,
        }
      );
      setLoad(false);
      if (resData.status === apiResponses.success) {
        notyf.success("ETA fetched successfully");
        setDeliveryDetails({
          ...delievryDetails,
          deliveryETA: resData?.data?.ETA,
        });
      } else {
        setDeliveryDetails({
          ...initalDeliveryDetails,
          deliveryETA: null,
        });
        notyf.error(resData.message);
      }
    } catch (e) {
      setDeliveryDetails({
        ...initalDeliveryDetails,
        deliveryMode: "",
      });
      setLoad(false);
      notyf.error(
        e.message ||
          "Something happened while updating patient address. Please try again!!"
      );
    }
  };

  return (
    <div className={classes.addOnAdditonContainer}>
      {load && <Loader open={true} loaderMessage="Fetching..." />}
      <div className={classes.addOnFormContainer}>
        <Typography className={classes.title}>
          {lang.selectCGMSensorType}
        </Typography>
        <FormControl className={classes.addOnRadioForm}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onChange={handleToggle}
          >
            <FormControlLabel
              value={addOnProductIds[addOnProductNames.libreOneSelfReading]}
              control={
                <Radio
                  checked={
                    selectedProduct ===
                    addOnProductIds[addOnProductNames.libreOneSelfReading]
                  }
                  color="primary"
                  size="small"
                />
              }
              label={addOnProductNames.libreOneSelfReading}
            />
            <FormControlLabel
              value={addOnProductIds[addOnProductNames.libreProSelfReading]}
              control={
                <Radio
                  checked={
                    selectedProduct ===
                    addOnProductIds[addOnProductNames.libreProSelfReading]
                  }
                  color="primary"
                  size="small"
                />
              }
              label={addOnProductNames.libreProSelfReading}
            />
          </RadioGroup>
        </FormControl>
        <div className={classes.noteContainer}>
          <Typography
            className={classes.noteLable}
          >{`${lang.note}:`}</Typography>
          <Typography className={classes.notesText}>
            {lang.freeCgmAddonNote}
          </Typography>
        </div>
      </div>
      <table className={classes.etaTable}>
        <thead>
          <tr>
            {etaTableHeading.map((heading) => {
              return <th className={classes.etaTableData}>{heading}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={classes.etaTableData}>
              <div className={classes.deliveryModeContainer}>
                <Select
                  fullWidth
                  labelId="select-label"
                  placeholder="Select"
                  displayEmpty
                  renderValue={() => {
                    return delievryDetails?.deliveryMode?.name || lang.select;
                  }}
                  onChange={handleDeliveryModeChange}
                  key={delievryDetails.deliveryMode?.id}
                >
                  <MenuItem value="">{lang.select}</MenuItem>
                  {deliveryModes?.map((mode) => {
                    return (
                      <MenuItem value={mode} key={mode.id}>
                        {mode.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
            </td>
            <td className={classes.etaTableData}>
              {patientBasicDetails?.patientAddress?.pincode || ""}
            </td>
            <td className={classes.etaTableData}>
              <Button
                onClick={() => {
                  setDeliveryDetails((prevState) => ({
                    ...prevState,
                    showETAError: false,
                  }));

                  if (!delievryDetails.deliveryMode?.name) {
                    notyf.error("please select a delivery mode");
                    setDeliveryDetails((prevState) => ({
                      ...prevState,
                      showDeliveryModeError: true,
                    }));
                    return;
                  }
                  handleCheckETA(delievryDetails.deliveryMode?.id);
                }}
                disabled={
                  delievryDetails.deliveryMode?.name === DELIVERY_MODES.clinic
                }
              >
                {lang.check}
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
      {delievryDetails.deliveryETA && (
        <div className={classes.etaContainer}>
          <Typography
            className={classes.etaLabel}
          >{`${lang.expectedDelivery} :`}</Typography>
          <Typography className={classes.label}>
            {` ${moment(delievryDetails.deliveryETA).format("DD/MM/YYYY")}`}
          </Typography>
        </div>
      )}

      {delievryDetails.showDeliveryModeError && (
        <FormHelperText className={classes.errorText}>
          {lang.deliveryModeError}
        </FormHelperText>
      )}
      {delievryDetails.showETAError && (
        <FormHelperText className={classes.errorText}>
          {lang.pleaseCheckETA}
        </FormHelperText>
      )}
      <div className={classes.yesCancelContainer}>
        <div>
          <CustomButtons
            md_border
            onClick={handleCancelAddonDialog}
            className={classes.dialogBoxButton}
          >
            {lang.cancel}
          </CustomButtons>
        </div>

        <div>
          <CustomButtons
            type="submit"
            md
            onClick={handleSubmit}
            disabled={!delievryDetails.deliveryMode}
          >
            {lang.submit}
          </CustomButtons>
        </div>
      </div>
    </div>
  );
};

export default NewAddonDialog;
