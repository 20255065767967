/**
 @desc
 * Centralized unique actions for All Patients Module.
 */

import {
  actionCreator,
  checkHttpStatus,
  jsonApiHeader,
  showErrorMessage,
  AllPatientsActionTypes,
  GET_ALL_PATIENTS,
  GET_FITNESS_COACH_ALL_PATIENTS,
} from "../constants";
import { TOKEN_EXPIRY_MESSAGE } from "../../../utils/utility";
import { userRoles } from "@utils/constants";

export const GetAllPatientsAction = (
  { offset, limit, search, role, searchBy },
  cb = () => {}
) => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.token;
    dispatch(actionCreator(AllPatientsActionTypes.get_AllPatients.REQUEST));

    const endPoint =
      role === userRoles.fitnessCoach ||
      role === userRoles.emotionalWellnessCoach
        ? `${GET_FITNESS_COACH_ALL_PATIENTS}?offset=${offset}&limit=${limit}&searchTerm=${search}&searchBy=${searchBy}`
        : GET_ALL_PATIENTS;

    fetch(endPoint, {
      method: "GET",
      headers: jsonApiHeader(token),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response?.status?.toUpperCase() === "FAILURE") {
          dispatch(
            actionCreator(
              AllPatientsActionTypes.get_AllPatients.FAILURE,
              response
            )
          );
          showErrorMessage(response.Message);
        } else if (response?.status?.toUpperCase() === "EXPIRED") {
          showErrorMessage(TOKEN_EXPIRY_MESSAGE);
          localStorage.clear();
          dispatch(
            actionCreator(
              AllPatientsActionTypes.get_AllPatients.FAILURE,
              response
            )
          );
          window.location.reload();
        } else {
          cb(response);

          dispatch(
            actionCreator(
              AllPatientsActionTypes.get_AllPatients.SUCCESS,
              response
            )
          );
        }
      })
      .catch(function (error) {
        dispatch(actionCreator(AllPatientsActionTypes.get_AllPatients.FAILURE));
        console.log("error get_AllPatients ..", error);
      });
  };
};

export const setSelectedPatientAction = (selectedPatient) => {
  return (dispatch) => {
    dispatch(
      actionCreator(AllPatientsActionTypes.setSelectedPatient, selectedPatient)
    );
  };
};
