import React from "react";
import { Typography, Divider, withStyles } from "@material-ui/core";
import weekPlanStyle from "../../../../assets/jss/pages/weekPlanStyle";
import clsx from "clsx";
import moment from "moment";
import { useHistory } from "react-router";

const WeekPlans = (props) => {
  const { classes, weekPlans, currentWeekPlanId, patientId } = props;
  const { push } = useHistory();

  const filteredWeekPlans =
    weekPlans &&
    weekPlans?.length > 0 &&
    weekPlans?.filter(
      (weekPlan) =>
        // weekPlan.isDraft !== true && patientData.id === weekPlan.patient
        patientId === weekPlan.patient
    );

  const filterActivityType = (activityType, type) =>
    activityType &&
    activityType?.length > 0 &&
    activityType?.filter((ele) => {
      return ele.activityType == type && ele.activityTitle;
    });

  const recentWeekPlan = weekPlans && weekPlans?.length > 0 && weekPlans[0];

  const getPreviousActivitiesData = (sks) => {
    const getPreviousData =
      sks &&
      sks?.length > 0 &&
      sks?.map((activity) => {
        return {
          ...activity,
          nonEditable: true,
          weekPlanActivityId: activity.id,
        };
      });
    return getPreviousData;
  };
  const getPreviousBlogsData = (sks) => {
    const getPreviousData =
      sks &&
      sks?.length > 0 &&
      sks?.map((activity) => {
        return { ...activity, weekPlanBlogId: activity.id };
      });
    return getPreviousData;
  };
  const getPreviousExercisesData = (sks) => {
    const getPreviousData =
      sks &&
      sks?.length > 0 &&
      sks?.map((activity) => {
        return { ...activity, weekPlanExerciseId: activity.id };
      });
    return getPreviousData;
  };
  return (
    <>
      <div className={classes.topContainer}>
        {filteredWeekPlans && filteredWeekPlans?.length > 0 ? (
          filteredWeekPlans?.map((weekPlan, index) => {
            const start = filterActivityType(weekPlan?.activityType, "start");
            const keep = filterActivityType(weekPlan?.activityType, "keep");
            const stop = filterActivityType(weekPlan?.activityType, "stop");

            const getPreviousStartData = getPreviousActivitiesData(start);
            const getPreviousKeepData = getPreviousActivitiesData(keep);
            const getPreviousStopData = getPreviousActivitiesData(stop);
            const getPreviousLearnData = getPreviousBlogsData(weekPlan?.blog);
            const getPreviousExerciseData = getPreviousExercisesData(
              weekPlan?.exercise
            );

            return (
              <React.Fragment key={index}>
                <div
                  className={clsx(classes.container, {
                    [classes.activeContainer]:
                      currentWeekPlanId === weekPlan.id,
                  })}
                >
                  <Typography className={classes.text}>
                    {moment(weekPlan.from).format("DD MMM")} -{" "}
                    {moment(weekPlan.to).format("DD MMM")}
                  </Typography>
                  <Typography className={classes.sub_text}>
                    {weekPlan.totalTasksDone > 0
                      ? `${weekPlan.totalTasksDone} tasks done out ${weekPlan.totalTasks} for this week`
                      : "New WeekPlan"}
                  </Typography>
                  <Typography
                    className={classes.sub_text1}
                    onClick={() =>
                      push({
                        pathname: `/coach/create-week-plan/${patientId}`,
                        state: {
                          data: weekPlan,
                          patientId: weekPlan?.patient,
                          selectedWeekPlanid: weekPlan?.id,
                          start: getPreviousStartData,
                          keep: getPreviousKeepData,
                          stop: getPreviousStopData,
                          learn: getPreviousLearnData,
                          exercise: getPreviousExerciseData,
                        },
                      })
                    }
                  >
                    {recentWeekPlan && recentWeekPlan?.id === weekPlan.id
                      ? "Edit"
                      : ""}
                  </Typography>
                  <Typography
                    className={classes.sub_text1}
                    onClick={() =>
                      push({
                        pathname: `/coach/create-week-plan/${patientId}`,
                        state: {
                          data: weekPlan,
                          patientId: weekPlan?.patient,
                          selectedWeekPlanid: weekPlan?.id,
                          start: start,
                          keep: keep,
                          stop: stop,
                          learn: getPreviousLearnData,
                          exercise: getPreviousExerciseData,
                          isDuplicateWeekPlan: true,
                        },
                      })
                    }
                  >
                    Duplicate
                  </Typography>
                  <Typography
                    className={classes.sub_text1}
                    onClick={() => props.handleGoToWeekPlan(weekPlan.id)}
                  >
                    View Detail
                  </Typography>
                  <Typography
                    className={classes.sub_text2}
                    onClick={() => props.handleDeleteWeekPlan(weekPlan.id)}
                  >
                    Delete
                  </Typography>
                </div>
                <Divider />
              </React.Fragment>
            );
          })
        ) : (
          <Typography
            style={{ fontSize: "16px", fontWeight: 600, padding: "10px" }}
          >
            No results to show
          </Typography>
        )}
      </div>
    </>
  );
};

export default withStyles(weekPlanStyle)(WeekPlans);
