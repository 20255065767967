/**
 @desc
 * Centralized unique actions for Patient Module.
 */

import {
  actionCreator,
  checkHttpStatus,
  jsonApiHeader,
  showErrorMessage,
  PatientActionTypes,
  GET_ALL_APPOINTMENTS,
  POST_SAVE_NOTE,
  GET_ALL_WEEKPLANS,
  GET_MODULE_DETAILS_BY_WEEKPLAN,
  GET_TASK_DETAILS_BY_MODULE,
  GET_TASK_DETAILS_BY_DATA_ENTRY,
  DELETE_WEEKPLAN_BY_ID,
  UPDATE_WEIGHT_DATA,
} from "../constants";
import { TOKEN_EXPIRY_MESSAGE } from "../../../utils/utility";

export const GetAllAppointmentsAction = (id) => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.token;
    dispatch(actionCreator(PatientActionTypes.get_AllAppointments.REQUEST));
    fetch(`${GET_ALL_APPOINTMENTS}/${id}`, {
      method: "GET",
      headers: jsonApiHeader(token),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(
            actionCreator(
              PatientActionTypes.get_AllAppointments.FAILURE,
              response
            )
          );
          showErrorMessage(response.message);
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage(TOKEN_EXPIRY_MESSAGE);
          localStorage.clear();
          dispatch(
            actionCreator(
              PatientActionTypes.get_AllAppointments.FAILURE,
              response
            )
          );
          window.location.reload();
        } else {
          dispatch(
            actionCreator(
              PatientActionTypes.get_AllAppointments.SUCCESS,
              response
            )
          );
        }
      })
      .catch(function (error) {
        dispatch(actionCreator(PatientActionTypes.get_AllAppointments.FAILURE));
        console.log("error get_AllAppointments ..", error);
      });
  };
};

export const SaveNoteAction = (postData) => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.token;
    dispatch(actionCreator(PatientActionTypes.post_SaveNote.REQUEST));
    fetch(`${POST_SAVE_NOTE}`, {
      method: "POST",
      body: JSON.stringify(postData),
      headers: jsonApiHeader(token),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status === "FAILURE") {
          dispatch(
            actionCreator(PatientActionTypes.post_SaveNote.FAILURE, response)
          );
          showErrorMessage(response.message);
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage(TOKEN_EXPIRY_MESSAGE);
          localStorage.clear();
          dispatch(
            actionCreator(PatientActionTypes.post_SaveNote.FAILURE, response)
          );
          window.location.reload();
        } else {
          dispatch(
            actionCreator(PatientActionTypes.post_SaveNote.SUCCESS, response)
          );
        }
      })
      .catch(function (error) {
        dispatch(actionCreator(PatientActionTypes.post_SaveNote.FAILURE));
        console.log("error post_SaveNote ..", error);
      });
  };
};

export const GetAllWeekPlansAction = (id) => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.token;
    dispatch(actionCreator(PatientActionTypes.get_AllWeekplans.REQUEST));
    fetch(`${GET_ALL_WEEKPLANS}/${id}`, {
      method: "GET",
      headers: jsonApiHeader(token),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(
            actionCreator(PatientActionTypes.get_AllWeekplans.FAILURE, response)
          );
          showErrorMessage(response.Message);
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage(TOKEN_EXPIRY_MESSAGE);
          localStorage.clear();
          dispatch(
            actionCreator(PatientActionTypes.get_AllWeekplans.FAILURE, response)
          );
          window.location.reload();
        } else {
          dispatch(
            actionCreator(PatientActionTypes.get_AllWeekplans.SUCCESS, response)
          );
        }
      })
      .catch(function (error) {
        dispatch(actionCreator(PatientActionTypes.get_AllWeekplans.FAILURE));
        console.log("error get_AllWeekplans ..", error);
      });
  };
};

export const GetModuleDetailsByWeekPlanAction = (id) => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.token;
    dispatch(
      actionCreator(PatientActionTypes.get_ModuleDetailsByWeekPlan.REQUEST)
    );
    fetch(`${GET_MODULE_DETAILS_BY_WEEKPLAN}/${id}`, {
      method: "GET",
      headers: jsonApiHeader(token),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(
            actionCreator(
              PatientActionTypes.get_ModuleDetailsByWeekPlan.FAILURE,
              response
            )
          );
          showErrorMessage(response.Message);
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage(TOKEN_EXPIRY_MESSAGE);
          localStorage.clear();
          dispatch(
            actionCreator(
              PatientActionTypes.get_ModuleDetailsByWeekPlan.FAILURE,
              response
            )
          );
          window.location.reload();
        } else {
          dispatch(
            actionCreator(
              PatientActionTypes.get_ModuleDetailsByWeekPlan.SUCCESS,
              response
            )
          );
        }
      })
      .catch(function (error) {
        dispatch(
          actionCreator(PatientActionTypes.get_ModuleDetailsByWeekPlan.FAILURE)
        );
        console.log("error get_ModuleDetailsByWeekPlan ..", error);
      });
  };
};

export const GetTaskDetailsByModuleAction = (id) => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.token;
    dispatch(actionCreator(PatientActionTypes.get_TaskDetailsByModule.REQUEST));
    fetch(`${GET_TASK_DETAILS_BY_MODULE}/${id}`, {
      method: "GET",
      headers: jsonApiHeader(token),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(
            actionCreator(
              PatientActionTypes.get_TaskDetailsByModule.FAILURE,
              response
            )
          );
          showErrorMessage(response.Message);
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage(TOKEN_EXPIRY_MESSAGE);
          localStorage.clear();
          dispatch(
            actionCreator(
              PatientActionTypes.get_TaskDetailsByModule.FAILURE,
              response
            )
          );
          window.location.reload();
        } else {
          dispatch(
            actionCreator(
              PatientActionTypes.get_TaskDetailsByModule.SUCCESS,
              response
            )
          );
        }
      })
      .catch(function (error) {
        dispatch(
          actionCreator(PatientActionTypes.get_TaskDetailsByModule.FAILURE)
        );
        console.log("error get_TaskDetailsByModule ..", error);
      });
  };
};

export const GetTaskDetailsByDataEntryAction = (id, type) => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.token;
    dispatch(
      actionCreator(PatientActionTypes.get_TaskDetailsByDataEntry.REQUEST)
    );
    fetch(`${GET_TASK_DETAILS_BY_DATA_ENTRY}?id=${id}&type=${type}`, {
      method: "GET",
      headers: jsonApiHeader(token),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(
            actionCreator(
              PatientActionTypes.get_TaskDetailsByDataEntry.FAILURE,
              response
            )
          );
          showErrorMessage(response.Message);
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage(TOKEN_EXPIRY_MESSAGE);
          localStorage.clear();
          dispatch(
            actionCreator(
              PatientActionTypes.get_TaskDetailsByDataEntry.FAILURE,
              response
            )
          );
          window.location.reload();
        } else {
          dispatch(
            actionCreator(
              PatientActionTypes.get_TaskDetailsByDataEntry.SUCCESS,
              response
            )
          );
        }
      })
      .catch(function (error) {
        dispatch(
          actionCreator(PatientActionTypes.get_TaskDetailsByDataEntry.FAILURE)
        );
        console.log("error get_TaskDetailsByDataEntry ..", error);
      });
  };
};

export const DeleteWeekPlanByIdAction = (id, callback = () => {}) => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.token;
    dispatch(actionCreator(PatientActionTypes.delete_WeekPlanById.REQUEST));
    fetch(`${DELETE_WEEKPLAN_BY_ID}/${id}`, {
      method: "PATCH",
      headers: jsonApiHeader(token),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status === "FAILURE") {
          dispatch(
            actionCreator(
              PatientActionTypes.delete_WeekPlanById.FAILURE,
              response
            )
          );
          showErrorMessage(response.Message);
        } else if (response.status === "EXPIRED") {
          showErrorMessage(TOKEN_EXPIRY_MESSAGE);
          localStorage.clear();
          dispatch(
            actionCreator(
              PatientActionTypes.delete_WeekPlanById.FAILURE,
              response
            )
          );
          window.location.reload();
        } else {
          setTimeout(() => {
            dispatch(
              actionCreator(
                PatientActionTypes.delete_WeekPlanById.SUCCESS,
                response
              )
            );
          }, 200);
          dispatch(GetAllWeekPlansAction());
        }
        callback();
      })

      .catch(function (error) {
        dispatch(actionCreator(PatientActionTypes.delete_WeekPlanById.FAILURE));
        console.log("error delete_WeekPlanById ..", error);
      });
  };
};

export const updateWeightAction = (postData, id, type) => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.token;
    dispatch(actionCreator(PatientActionTypes.update_Weight.REQUEST));
    fetch(`${UPDATE_WEIGHT_DATA}/${id}?type=${type}`, {
      method: "PATCH",
      headers: jsonApiHeader(token),
      body: JSON.stringify(postData),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status === "FAILURE") {
          dispatch(
            actionCreator(PatientActionTypes.update_Weight.FAILURE, response)
          );
          showErrorMessage(response.Message);
        } else if (response.status === "EXPIRED") {
          showErrorMessage(TOKEN_EXPIRY_MESSAGE);
          localStorage.clear();
          dispatch(
            actionCreator(PatientActionTypes.update_Weight.FAILURE, response)
          );
          window.location.reload();
        } else {
          setTimeout(() => {
            dispatch(
              actionCreator(PatientActionTypes.update_Weight.SUCCESS, response)
            );
          }, 200);
          dispatch(GetAllWeekPlansAction());
        }
      })
      .catch(function (error) {
        dispatch(actionCreator(PatientActionTypes.update_Weight.FAILURE));
        console.log("error update_Weight ..", error);
      });
  };
};
