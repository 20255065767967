/**
 @desc
 * Centralized unique action types for Login actions and reducers.
 */

import {
  actionCreator,
  API_URL,
  checkHttpStatus,
  createRequestActionTypes,
  jsonApiHeader,
  showSuccessMessage,
  showErrorMessage,
} from "../../../utils/utility";

export {
  jsonApiHeader,
  actionCreator,
  checkHttpStatus,
  showSuccessMessage,
  showErrorMessage,
};

export const GET_ALL_PATIENTS = `${API_URL}/v1/patients/coach`;
export const GET_FITNESS_COACH_ALL_PATIENTS = `${API_URL}/v1/users/patientsList`;

export const AllPatientsActionTypes = {
  get_AllPatients: createRequestActionTypes("GET_ALL_PATIENTS"),
  setSelectedPatient: "SET_SELECTED_PATIENT",
};
