import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import { Typography, withStyles } from "@material-ui/core";
import StepsProgressDrawerStyle from "../../../../assets/jss/pages/components/stepsProgressDrawerStyle";

const BpDetails = (props) => {

    const { classes } = props;

    return <>
    <div className={classes.headerContainer}>
      <Typography style={{ fontSize: "24px", fontWeight: 500 }}>
        Details
      </Typography>
      <CloseIcon className={classes.icon} onClick={() => props.handleCloseDrawer('bp_progress')} />
    </div>
  </>
}

export default withStyles(StepsProgressDrawerStyle)(BpDetails);
