import { Box, Typography } from "@material-ui/core";
import React from "react";

export const BlogCard = ({ classes, article, title, thumbnail, openBlog }) => {
  return (
    <>
      <Box className={classes.blogCardContainer}>
        <img alt="" src={thumbnail} width="200px" height="109px" />
        <Box className={classes.blogSubContainer}>
          <Typography className={classes.blogText}>
            {title}
          </Typography>
          <Typography
            onClick={() => openBlog("BLOG", null, "week_plan", undefined, undefined, article)}
            className={classes.blogRead}
          >
            Read
          </Typography>
        </Box>
      </Box>
    </>
  );
};
