export const tirHeading = ["7th day → 14th day", "Interpretation"];
export const tirValues = (patientProgress) => {
  return [
    {
      value: `${patientProgress?.timeInRange?.seventhDay || 0}% → ${
        patientProgress?.timeInRange?.fourteenthDay || 0
      }%`,
      isRequired: false,
      editable: false,
      tableName: "timeInRange",
      keyName: "interpretation",
    },
    {
      value: patientProgress.timeInRange.interpretation,
      isRequired: true,
      editable: true,
      tableName: "timeInRange",
      keyName: "interpretation",
    },
  ];
};

export const tirSubRows = (patientProgress) => {
  return [
    [
      {
        value: "DASDFSADFiet",
        isRequired: false,
        editable: false,
        tableName: "timeInRange",
        keyName: "diet",
      },
    ],
    [
      {
        value: "Diet",
        isRequired: false,
        editable: false,
        tableName: "timeInRange",
        keyName: "diet",
      },
      {
        value: patientProgress.timeInRange.diet,
        isRequired: false,
        editable: true,
        tableName: "timeInRange",
        keyName: "diet",
      },
    ],
    [
      {
        value: "Exercise",
        isRequired: false,
        editable: false,
        tableName: "timeInRange",
        keyName: "exercise",
      },
      {
        value: patientProgress.timeInRange.exercise,
        isRequired: false,
        editable: true,
        tableName: "timeInRange",
        keyName: "exercise",
      },
    ],
  ];
};

export const disstressSubRows = (patientProgress) => {
  return [
    [
      {
        value: "Stress",
        isRequired: false,
        editable: false,
        tableName: "distressScore",
        keyName: "stress",
      },
      {
        value: patientProgress.distressScore.stress,
        isRequired: false,
        editable: true,
        tableName: "distressScore",
        keyName: "stress",
      },
    ],
    [
      {
        value: "Sleep",
        isRequired: false,
        editable: false,
        tableName: "distressScore",
        keyName: "sleep",
      },
      {
        value: patientProgress.distressScore.exercise,
        isRequired: false,
        editable: true,
        tableName: "distressScore",
        keyName: "sleep",
      },
    ],
  ];
};

export const averageGlucoseHeading = ["7th day → 14th day", "Interpretation"];

export const averageGlucoseValues = (patientProgress) => {
  return [
    {
      value: `${
        patientProgress?.averageGlucose?.seventhDay
          ? patientProgress?.averageGlucose?.seventhDay
          : 0
      } mg/dl → ${
        patientProgress?.averageGlucose?.fourteenthDay
          ? patientProgress.averageGlucose.fourteenthDay
          : 0
      } mg/dl`,
      isRequired: false,
      editable: false,
      tableName: "averageGlucose",
      keyName: "interpretation",
    },
    {
      value: patientProgress.averageGlucose.value,
      isRequired: true,
      editable: true,
      tableName: "averageGlucose",
      keyName: "interpretation",
    },
  ];
};
