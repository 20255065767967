import { TextField, Typography } from "@material-ui/core";
import { discountUpdateStyle } from "assets/jss/components/discountUpdateStyle";
import lang from "assets/languages";
import Button from "@components/atoms/Button";
import Loader from "@components/atoms/Loader";
import { API_METHODS } from "network/constants";
import { apiResponses } from "network/constants";
import { END_POINT } from "network/constants";
import React, { useEffect, useState } from "react";
import api from "utils/api";
import { notyf } from "utils/utility";
import { DiscountTable } from "./components/DiscountTable";

const DiscountUpdate = () => {
  const classes = discountUpdateStyle();
  const [discountObj, setDiscountObj] = useState({
    allDiscounts: [],
    newDiscountValue: null,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchDiscounts();
  }, []);

  const addDiscount = async () => {
    try {
      setLoading(true);
      const res = await api(END_POINT.addDiscount, {
        method: API_METHODS.POST,
        data: {
          amount: Number(discountObj?.newDiscountValue),
        },
      });
      if (res.data.status === apiResponses.success) {
        fetchDiscounts();
        notyf.success("Discount added successfully");
      } else {
        setLoading(false);
        notyf.error("Something went wrong");
      }
    } catch (e) {
      setLoading(false);
      notyf.error(e.message);
    }
  };

  const removeDiscount = async (id) => {
    try {
      setLoading(true);
      const res = await api(END_POINT.removeDiscount(id), {
        method: API_METHODS.DELETE,
      });
      if (res.data.status === apiResponses.success) {
        fetchDiscounts();
        notyf.success("Discount removed successfully");
      } else {
        setLoading(false);
        notyf.error("Something went wrong");
      }
    } catch (e) {
      setLoading(false);
      notyf.error(e.message);
    }
  };

  const fetchDiscounts = async () => {
    try {
      setLoading(true);
      const res = await api(END_POINT.fetchDiscounts, {
        method: API_METHODS.GET,
      });
      if (res.data.status === apiResponses.success) {
        setDiscountObj({
          ...discountObj,
          allDiscounts: [...res?.data?.data],
        });
        setLoading(false);
        notyf.success("Discount fetched successfully");
      } else {
        setLoading(false);
        notyf.error("Something went wrong");
      }
    } catch (e) {
      setLoading(false);
      notyf.error(e.message);
    }
  };

  return (
    <>
      {loading && <Loader open={true} loaderMessage="Fetching..." />}
      <div>
        {discountObj?.allDiscounts?.length ? (
          <>
            <Typography className={classes.headingText}>
              {lang.currentDiscounts}
            </Typography>
            <DiscountTable
              tableData={discountObj?.allDiscounts}
              handleDelete={(discountId) => {
                removeDiscount(discountId);
              }}
            ></DiscountTable>
          </>
        ) : (
          <Typography className={classes.headingText}>
            {lang.discountsNotFound}
          </Typography>
        )}
      </div>
      <div>
        <Typography className={classes.headingText}>
          {lang.addNewDiscount}
        </Typography>
        <TextField
          onChange={(event) => {
            setDiscountObj({
              ...discountObj,
              newDiscountValue: event?.target?.value,
            });
          }}
          type="number"
          value={discountObj?.value}
          placeholder="Enter Discount value here"
          variant="outlined"
          fullWidth
        />
        <Button
          className={classes.submit}
          onClick={() => {
            addDiscount();
          }}
        >
          {lang.addNewDiscount}
        </Button>
      </div>
    </>
  );
};

export default React.memo(DiscountUpdate);
