import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Typography, Avatar, withStyles } from '@material-ui/core';
import Markdown from "markdown-to-jsx";
import moment from "moment";
import BrokenImageSharpIcon from '@material-ui/icons/BrokenImageSharp';
import learnMoreDetailsDrawerStyle from "../../../../assets/jss/pages/components/learnMoreDetailsDrawerStyle";

const LearnMoreDetailsDrawer = (props) => {
  const { classes, currentBlog } = props;

  return (
    <>
      <>
        <div className={classes.headerContainer}>
          <Typography className={classes.headerText} onClick={() => props.handleGoToLearnMore(currentBlog?.category?.name)}>Back to Learn More</Typography>
          <CloseIcon className={classes.icon} onClick={props.handleCloseDrawer} />
        </div>
        <div>
          {currentBlog?.banner?.url && <img src={currentBlog?.banner?.url} alt="img" height="200px" width="100%" />}
          {!currentBlog?.banner?.url &&
            <div style={{ height: "200px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor: "grey" }}>
              <BrokenImageSharpIcon />
              <Typography style={{ color: "#fff", fontSize: "12px", fontWeight: 500 }}>No image available</Typography>
            </div>
          }
          <div className={classes.padded}>
            <Typography className={classes.title}>{currentBlog?.title}</Typography>
            <Typography style={{ fontSize: "12px", color: "#8C8C8C" }}>Published on {moment(currentBlog?.published_at).format("Do MMMM, YYYY")}</Typography>
            <div className={classes.container}>
              <Avatar style={{ backgroundColor: "#5babcc" }} src={currentBlog?.author?.avatar?.url}>{currentBlog?.author?.name?.charAt(0)}</Avatar>
              <Typography className={classes.authorText}>{currentBlog?.author?.name}</Typography>
            </div>
            <Markdown
              options={{
                overrides: {
                  img: {
                    props: {
                      height: 200,
                      width: "100%"
                    },
                  },
                },
              }}
            >
              {currentBlog?.content}
            </Markdown>
          </div>
        </div>
      </>
    </>
  )
}

export default withStyles(learnMoreDetailsDrawerStyle)(LearnMoreDetailsDrawer);