import { Divider, Typography } from "@material-ui/core";
import lang from "assets/languages";
import CustomButtons from "@components/atoms/Button";

const DialogBox = ({
  handleCloseConfirmDialog,
  productAddon,
  classes,
  onClick,
  selectedProgram,
  heading = lang.addOnProductsSelected,
}) => {
  return (
    <div className={classes.dialogBoxContainer}>
      <div className={classes.dialogBoxContent}>
        <div className={classes.dialogBoxHeading}>
          <Typography className={classes.dialogBoxText}>{heading}</Typography>
        </div>
        {productAddon?.length > 0 && (
          <div className={classes.addonList}>
            <Typography className={classes.dialogBoxText}>
              {lang.addon}
            </Typography>
            {productAddon?.map((product) => (
              <div className={classes.addonName}>
                <p>{product.productName}</p>
                <p>{product.quantity}</p>
              </div>
            ))}
          </div>
        )}
        {selectedProgram && (
          <div className={classes.addonList}>
            <Typography className={classes.dialogBoxText}>
              {lang.program}
            </Typography>

            <div className={classes.addonName}>
              <p>{selectedProgram.name}</p>
            </div>
          </div>
        )}
      </div>
      <Divider />
      <div className={classes.yesCancelContainer}>
        <div>
          <CustomButtons
            md_border
            onClick={handleCloseConfirmDialog}
            className={classes.dialogBoxButton}
          >
            Cancel
          </CustomButtons>
        </div>

        <div>
          <CustomButtons type="submit" md onClick={onClick}>
            Yes
          </CustomButtons>
        </div>
      </div>
    </div>
  );
};

export default DialogBox;
