const healthProfileStyle = (theme) => ({
  topContainer: {
    maxHeight: "430px",
    overflow: "auto",
  },
  container: {
    display: "grid",
    padding: "16px",
    gridTemplateColumns: "300px auto",
  },
  text: {
    fontWeight: 500,
    fontSize: "14px",
  },
  sub_text: {
    fontSize: "14px",
  },
  active_text: {
    color: "#389E0D",
    fontSize: "14px",
    fontWeight: 500,
  },
  pending_text: {
    color: "#FF5522",
    fontSize: "14px",
    fontWeight: 500,
  },
  viewButton: {
    padding: "5px",
    marginLeft: "5px",
    textDecoration: "none",
    color: "#2B377E",
    left: 10,
  },
  reportLabel: {
    marginBottom: "5px",
  },
  reportsContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
});

export default healthProfileStyle;
