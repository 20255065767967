import React from "react";
import moment from "moment";
import { Typography, withStyles } from "@material-ui/core";
import doneIcon from "../../../../assets/icons/done.png";
import CloseIcon from "@material-ui/icons/Close";
import CancelSharpIcon from "@material-ui/icons/CancelSharp";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import weekPlanDetailsDrawerStyle from "../../../../assets/jss/pages/components/weekPlanDetailsDrawerStyle";

const WeekPlanDetailsDrawer = ({
  handleGoToWeekPlan,
  handleGoToLearnMore,
  handleCloseDrawer,
  handleGoToTaskDetails,
  ...props
}) => {
  const { classes, currentWeekPlan, taskDetailsByModule } = props;
  return (
    <>
      <div className={classes.headerContainer}>
        <Typography className={classes.headerText} onClick={handleGoToWeekPlan}>
          Back to WeekPlan
        </Typography>
        <CloseIcon style={{ cursor: "pointer" }} onClick={handleCloseDrawer} />
      </div>
      <div className={classes.subContainer}>
        <Typography className={classes.text}>
          {currentWeekPlan.moduleInfo?.type}
        </Typography>
        <Typography className={classes.description}>
          {currentWeekPlan.moduleInfo?.title}
        </Typography>
        <Typography className={classes.sub_text}>
          {currentWeekPlan.moduleInfo?.snippet}
        </Typography>
        {currentWeekPlan?.moduleInfo?.type === "Learn" &&
          <Typography className={classes.sub_text1} onClick={() => handleGoToLearnMore(currentWeekPlan?.moduleInfo?.category)}>
            Learn more
            </Typography>
        }
      </div>
      <div className={classes.container}>
        <Typography className={classes.sub_text2}>WEEKLY TASKS</Typography>
      </div>
      <div className={classes.bottomContainer}>
        {taskDetailsByModule.length > 0 &&
          taskDetailsByModule.map((task, index) => {
            if (task.isFinished) {
              return (
                <div
                  className={classes.taskContainer}
                  key={index}
                  onClick={task.task ==='I have understood this'?null:() =>
                    handleGoToTaskDetails(task.id, task.taskDataEntry)
                  }
                >
                  <div className={classes.taskSubContainer}>
                    <img
                      src={doneIcon}
                      height="16.34px"
                      width="16.34px"
                      alt="checked"
                    />
                    <div className={classes.content}>
                      <Typography className={classes.sub_text3}>
                        {task.task}
                      </Typography>
                      <Typography className={classes.sub_text4}>
                        Done on{" "}
                        {moment(task.dateFinished).format(
                          "dddd, MMM Do h:mm A"
                        )}
                      </Typography>
                    </div>
                  </div>
                  {!task.taskDataEntry === "nodataentry" && <ChevronRightIcon />}
                </div>
              );
            } else {
              return (
                <div
                  className={classes.taskContainer}
                  key={index}
                >
                  <div className={classes.taskSubContainer}>
                    <CancelSharpIcon style={{ color: "red" }} />
                    <div className={classes.content}>
                      <Typography className={classes.sub_text3}>
                        {task.task}
                      </Typography>
                      <Typography className={classes.sub_text4}>
                        Created{" "}
                        {moment(task.createdAt).format("dddd, MMM Do h:mm A")}
                      </Typography>
                    </div>
                  </div>
                </div>
              )
            }
          })}
      </div>
    </>
  );
};

export default withStyles(weekPlanDetailsDrawerStyle)(WeekPlanDetailsDrawer);
