import React, { useMemo } from "react";
import { Box, Typography } from "@material-ui/core";
import lang from "@assets/languages";
import { RangeItem } from "./rangeItem";
import { useStyles } from "../styles";

export const TimeInRangeList = ({
  timeInRange,
  timeAboveRange,
  timeBelowRange,
  minTargetValue,
  maxTargetValue,
}) => {
  const classes = useStyles();

  const tirList = useMemo(() => {
    const tirListData = [
      timeAboveRange && { ...timeAboveRange, key: "tar" },
      timeInRange && { ...timeInRange, key: "tir" },
      timeBelowRange && { ...timeBelowRange, key: "tbr" },
    ];

    return tirListData.map((tirData) => {
      const { key } = tirData;

      return (
        <RangeItem
          key={key}
          tirData={tirData}
          minTargetValue={minTargetValue}
          maxTargetValue={maxTargetValue}
        />
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeInRange, timeAboveRange, timeBelowRange]);

  return (
    <Box>
      <Typography className={classes.tirListHeading}>
        {lang.timeInRange}
      </Typography>
      {tirList}
    </Box>
  );
};
