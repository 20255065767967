/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory, useParams } from "react-router";
import {
  Card,
  Typography,
  Dialog,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Chip,
  TextField,
} from "@material-ui/core";
import { notyf } from "utils/utility";
import Loader from "@components/atoms/Loader";
import Button from "@components/atoms/Button";
import { USER_ROLE } from "utils/constants";
import FormTextInput from "@components/molecules/FormTextInput/FormTextInput";
import FormSelectField from "@components/molecules/FormSelectField";
import FormAutoCompleteField from "@components/molecules/FormAutoCompleteField";
import {
  GetAllClinicsAction,
  UpdatePatientAction,
  PostAddPatientAction,
  PostUploadClinicalDataAction,
  PostUploadGoalDataAction,
  GetDoctorAndCoachByClinicAction,
  PostUploadCoachAssessmentDataAction,
  GetAddonAndOffersData,
} from "./actions/AddPatientActions";
import lang from "assets/languages";
import { useStyles } from "./styles";
import axios from "axios";
import "react-phone-input-2/lib/material.css";
import PhoneInput from "react-phone-input-2";
import api from "utils/api";
import { END_POINT } from "network/constants";
import { API_METHODS } from "network/constants";
import { apiResponses } from "network/constants";
import DialogBox from "./DialogBox";
import { crossIcon } from "assets/icons";
import { DateSelector } from "@components/molecules/DateSelector";
import { Table } from "@components/molecules/Table";
import { dateIcon } from "assets/icons";
import moment from "moment";
import { Prompt } from "react-router";
import {
  addressErrors,
  alphaNumbericWithSpaceRegex,
  diagnosisLabels,
  etaTableHeading,
  addPatientForms,
  maxPincodeLength,
  minLastNameStringLength,
  minNameStringLength,
  patientFormFields,
  postalPincodeApiKeys,
  programMetaDetaKeys,
  showErrorMessage,
  DELIVERY_MODES,
  customDiscountOptionValue,
  customDisctounOptiomLabel,
} from "./constants";
import { ASSURE_PROGRAM_ID } from "utils/constants";
import AccordionContainer from "./components/AccordionContainer";
import { getChangedBasicDetails } from "./helpers";
import { TabCloseConfirmationPopup } from "./components/TabCloseConfirmationPopup";
import BasicDetailsConfirmPopup from "./components/BasicDetailsConfirmPopup";
import { Stack } from "@mui/material";
import {
  addOnProductNames,
  deliveryRequiredAddonIds,
} from "@pages/AdminTabs/constants";
import { addOnProductIds } from "@pages/AdminTabs/constants";
import { DeliveryModeCard } from "./components/DeliveryModeCard";

const genderData = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "Other", label: "Other" },
];

const basicDetailsSchema = () => {
  return Yup.object().shape({
    firstName: Yup.string()
      .required("First Name is required.")
      .min(2, "Too Short")
      .matches(alphaNumbericWithSpaceRegex, "Invalid format")
      .nullable(),
    lastName: Yup.string()
      .required("Last Name is required.")
      .min(1, "Too Short")
      .matches(alphaNumbericWithSpaceRegex, "Invalid format")
      .nullable(),
    gender: Yup.string().required("Gender is required.").nullable(),
    age: Yup.number()
      .required("Age is required.")
      .max(100, "Too high for an age")
      .nullable(),
    mobileNumber: Yup.string()
      .required("Mobile Number is required.")
      .min(4, "Please enter a valid mobile number")
      .max(12, "Please enter a valid mobile number")
      .nullable(),
    whatsappNumber: Yup.string()
      .required("WhatsApp Number is required.")
      .min(4, "Please enter a valid whatsappNumber number")
      .max(12, "Please enter a valid whatsappNumber number")
      .nullable(),
    clinicId: Yup.string().required("Clinic is required").nullable(),
    coach: Yup.string().nullable(),
    doctor: Yup.string().required("Doctor is required").nullable(),
  });
};

const subscriptionDetailsSchema = (
  patientData,
  selectedFields,
  selectedAddonProducts
) => {
  const isDeliveryRequired = !!selectedAddonProducts.filter((product) => {
    return deliveryRequiredAddonIds.includes(product?.id);
  }).length;

  return Yup.object().shape({
    products: Yup.array().nullable(),
    subscriptionPlan:
      selectedFields.selectedProgram || !patientData
        ? Yup.string().required("Please select a Subscription Plan").nullable()
        : Yup.string(),
    programType: patientData
      ? Yup.object().nullable()
      : Yup.object().required("Please select a Program Type").nullable(),
    discount: Yup.string().nullable(),
    remarks:
      selectedFields.selectedProgram ||
      selectedAddonProducts.length > 0 ||
      !patientData
        ? Yup.string().required("Please add a remark").nullable()
        : Yup.string(),
    deliveryMode: isDeliveryRequired
      ? Yup.object().required("Delivery mode is compulsory").nullable()
      : Yup.object().nullable(),
  });
};

const AddPatient = (props) => {
  const [addonDiscountState, setAddonDiscountState] = useState({
    addon: [],
    discount: null,
    allPrograms: null,
    programmeTypes: null,
  });

  const { addon, discount, allPrograms } = addonDiscountState;
  const { loading, allClinics, addOnAndOffer } = props;
  const classes = useStyles();
  const [clinics, setClinics] = React.useState({});
  const [productAddon, setProductAddon] = React.useState([]);
  const [selectedAddonProducts, setSelectedAddonProducts] = React.useState([]);

  const discountDebounce = useRef();
  const patientSubscriptionFormValuesRef = useRef();

  const [confirmationPopupState, setConfirmationPopupState] = useState({
    openProgramAddonPopup: false,
    openBasicDetailsPopup: false,
  });
  const [finalAmount, setFinalAmout] = useState(0);
  const pageLocation = props.history.location;
  const patientData = pageLocation?.state && pageLocation.state?.data;
  const isEditable = pageLocation?.state?.isEditable;
  const prefillMobileNumber = pageLocation?.state?.mobileNumber;
  const prefillCountryCode = pageLocation?.state?.countryCode;
  const whatsAppPatientId = pageLocation?.state?.whatsAppPatientId;

  const [startDate, setStartDate] = useState(new Date());
  const [paid, setPaid] = useState(false);
  const [programMetaData, setProgramMetaData] = useState(null);
  const [showMetaDetaError, setShowMetaDetaError] = useState(false);
  const [programAddonHistory, setProgramAddonHistory] = useState(null);
  const remarks = patientData?.remarks
    ? patientData?.remarks
        .split("\n")
        .map((remark) => remark.trim())
        .filter((remark) => remark.length > 0)
        .reverse()
    : null;
  const { push } = useHistory();
  const { id } = useParams();

  const [doctors, setDoctors] = useState([]);
  const [coachs, setCoachs] = useState([]);
  const [load, setLoad] = useState(false);
  const [addressState, setAddressState] = useState({
    showAddress: false,
    showAddressErr: false,
  });
  const [data, setData] = useState({
    dialCode: patientData
      ? patientData?.countryCode?.slice(1)
      : prefillCountryCode,
  });
  const [programBucketDatails, setProgramBucketDetails] = useState(null);

  let pincode = patientData?.address?.[0]?.pincode || "";
  let address = patientData?.address?.[0]?.address1 || "";
  let city = patientData?.address?.[0]?.city || "";
  let state = patientData?.address?.[0]?.state || "";
  let landmark = patientData?.address?.[0]?.landmark || "";

  const [subscriptionPlanOptions, setSubscriptionPlanOptions] = useState([]);
  const [selectedFields, setSelectedFileds] = useState({
    selectedProgram: null,
    selectedSubscritpionPlan: null,
    selectedDiscount: 0,
    sendInvoice: true,
    showCustomDiscount: false,
  });
  const [diagnosisList, setDiagnosisList] = useState([]);
  const [deliveryModes, setDeliveryModes] = useState([]);
  const showFinalAmountError = finalAmount !== 0 && !finalAmount;
  let addFieldValues;

  const handleOnChange = (data) => {
    setData(data);
  };

  const fetchClinicData = useCallback(async (val) => {
    //TODO: Shivam quick fix, should be removed
    if (val === 10 || val === 56) {
      setAddonDiscountState((prevState) => {
        return {
          ...prevState,
          ...(prevState?.programmeTypes
            ? { allPrograms: prevState.programmeTypes }
            : {}),
        };
      });
    } else {
      setAddonDiscountState((prevState) => {
        return {
          ...prevState,
          ...(prevState?.allPrograms
            ? {
                allPrograms: prevState?.allPrograms?.filter(
                  (program) => program.id !== 19
                ),
              }
            : {}),
        };
      });
    }

    try {
      const { data } = await api(END_POINT.getClinicUsers(val), {
        method: "get",
      });
      const doctorsData = data.data?.[0]?.users?.filter(
        ({ role }) => role === USER_ROLE.DOCTOR
      );
      const coachsData = data.data?.[0]?.users?.filter(
        ({ role }) => role === USER_ROLE.COACH
      );
      setDoctors(
        doctorsData.map((d) => ({
          label: d.firstName + " " + (d.lastName || ""),
          value: d.id,
        }))
      );
      setCoachs(
        coachsData.map((d) => ({
          label: d.firstName + " " + (d.lastName || ""),
          value: d.id,
        }))
      );
    } catch (e) {}
  }, []);

  const fetchAddOn = (addon) => {
    if (patientData?.subscriptionAddOnProduct && addon) {
      const addonProducts = [];
      const sendData = [];
      patientData?.subscriptionAddOnProduct.forEach((product) => {
        const { addOnProduct } = product;
        const item = addon.find((addons) => {
          return addons.id === addOnProduct;
        });
        if (item) {
          addonProducts.push(item.productName);
          sendData.push({ id: item.id });
        }
      });
    }
  };

  const clinicsData =
    clinics.length > 0 &&
    clinics.map((clinic) => {
      return { label: clinic.name, value: clinic.id };
    });

  const getAddonAndProgramDetails = async () => {
    try {
      const res = await api(END_POINT.getAddonAndProgramHistory(id, true), {
        method: API_METHODS.GET,
      });
      if (res.data.status === apiResponses.success) {
        let createProgramTableData = res.data.data.programHistory.map(
          (program) => {
            return {
              "Program Type": `${program.programType}`,
              Duration: program.duration,
              "Program Start Date": program.programStartDate,
            };
          }
        );

        let createAddOnTableData = res.data.data.addOnProductsHistory.map(
          (addOn) => {
            return {
              "AddOn Type": addOn.addOnType,
              Quantity: addOn.quantity,
              "Purchase Date": addOn.purchaseDate,
            };
          }
        );

        setProgramAddonHistory({
          programHistory: createProgramTableData,
          addOnProductsHistory: createAddOnTableData,
        });
      }
    } catch (e) {
      notyf.error(e.message || "something went wrong");
      console.log("error getAddonAndProgramDetails", e);
    }
  };

  const getDiagnosisList = async () => {
    try {
      const res = await api(END_POINT.getDiagnosisList, {
        method: API_METHODS.GET,
      });
      if (res.data.status === apiResponses.success) {
        setDiagnosisList(res.data?.data || []);
      } else {
        notyf.error("Something went wrong");
      }
    } catch (e) {
      notyf.error(e.message || "something went wrong");
    }
  };

  const getDeliveryModeDropDowns = async () => {
    try {
      const res = await api(
        END_POINT.getDeliveryModes(addPatientForms.createPatientOrder),
        {
          method: API_METHODS.GET,
        }
      );
      if (res.data.status === apiResponses.success) {
        setDeliveryModes(res.data?.data?.deliveryModes || []);
      } else {
        notyf.error("Something went wrong");
      }
    } catch (e) {
      notyf.error(e.message || "something went wrong");
    }
  };

  useEffect(() => {
    props.GetAllClinicsAction();
    props.GetAddonAndOffersData();
    patientData && getAddonAndProgramDetails();
    getDiagnosisList();
    getDeliveryModeDropDowns();
  }, []);

  useEffect(() => {
    if (allClinics) {
      setClinics(allClinics);
    }
  }, [allClinics]);

  useEffect(() => {
    const clinicId = patientData?.clinics?.[0]?.id;
    if (clinicId) {
      fetchClinicData(clinicId);
    }
  }, [fetchClinicData, patientData]);

  useEffect(() => {
    if (addOnAndOffer) {
      const { products, offers, allPrograms } = addOnAndOffer;
      fetchAddOn(products);
      const discount = offers?.map((offer) => {
        let label = offer;
        if (offer === 0) {
          label = "NA";
        }
        return { label: label.toString(), value: offer.toString() };
      });
      setAddonDiscountState({
        ...addonDiscountState,
        addon: products,
        discount: discount,
        allPrograms: allPrograms,
        programmeTypes: allPrograms,
      });
    }
  }, [addOnAndOffer]);

  const handleFinalAmount = async (discount) => {
    if (
      selectedAddonProducts.length === 0 &&
      selectedFields.selectedProgram === null
    ) {
      return;
    }
    try {
      setLoad(true);
      const responseData = await api(END_POINT.getFinalAmount, {
        method: API_METHODS.POST,
        data: {
          programId: selectedFields.selectedProgram?.id,
          discount,
          products: selectedAddonProducts,
          subscriptionPlan: selectedFields.selectedSubscritpionPlan,
        },
      });
      setLoad(false);
      if (responseData?.data?.status === apiResponses.success) {
        setFinalAmout(responseData.data.totalAmount);
      } else {
        setFinalAmout(0);
        notyf.error(responseData);
      }
    } catch (e) {
      setLoad(false);
      notyf.error(
        e.message ||
          "Something happened while updating the total amount. Please try again!!"
      );
      setFinalAmout(null);
    }
  };

  const handleAddon = (res) => {
    setSectionFormState({
      ...sectionFormState,
      showAddressError: false,
    });
    addFieldValues("products", "");
    if (!res) {
      return;
    }
    let updatedAddOn = addonDiscountState?.addon?.filter(
      (addOnItem) => addOnItem.id !== res.id
    );
    setAddonDiscountState({ ...addonDiscountState, addon: updatedAddOn });

    let payload = {
      quantity: 1,
      ...res,
    };
    let updatedSelectedAddon = [...selectedAddonProducts, payload];
    setSelectedAddonProducts(updatedSelectedAddon);
    setProductAddon(updatedSelectedAddon);
  };

  const handleCityAndState = async (pincode, setFieldValue) => {
    setLoad(true);
    await axios
      .get(END_POINT.getStateAndCityByPincode(pincode))
      .then((response) => {
        let postOfficeData =
          response?.data?.[0]?.[postalPincodeApiKeys.postOffice]?.[0];
        if (postOfficeData) {
          setFieldValue(
            patientFormFields.city,
            postOfficeData?.[postalPincodeApiKeys.district]
          );
          setFieldValue(
            patientFormFields.state,
            postOfficeData?.[postalPincodeApiKeys.state]
          );
          setLoad(false);
        } else {
          setLoad(false);
          showErrorMessage("Invalid pincode");
        }
      })
      .catch((error) => {
        setLoad(false);
        showErrorMessage("Something went wrong please try again later");
      });
  };

  const handleAddOnQuantity = (val, res, setFieldValue, addOnId) => {
    let updatedSelectedAddon = [];
    selectedAddonProducts.forEach((addon) => {
      if (addon.id === res.id) {
        addon.quantity + val !== 0
          ? updatedSelectedAddon.push({
              ...addon,
              quantity: addon.quantity + val,
            })
          : setAddonDiscountState({
              ...addonDiscountState,
              addon: [...addonDiscountState.addon, res],
            });
      } else {
        return updatedSelectedAddon.push(addon);
      }
    });
    setFieldValue({});
    setSelectedAddonProducts(updatedSelectedAddon);
    setProductAddon(updatedSelectedAddon);
  };

  const handleCloseConfirmDialog = () => {
    setConfirmationPopupState({
      ...confirmationPopupState,
      openProgramAddonPopup: false,
      openBasicDetailsPopup: false,
    });
  };

  const handleProgramTypeChange = (data) => {
    setProgramMetaData(null);
    //reseting field values when a program changes.
    addFieldValues("subscriptionPlan", "");
    if (!patientData) {
      addFieldValues("discount", 0);
    }

    setProductAddon([]);
    //resetting state values when a program changes.
    setAddonDiscountState({
      ...addonDiscountState,
      addon: [...addonDiscountState.addon, ...selectedAddonProducts],
    });
    setSelectedAddonProducts([]);
    setFinalAmout(0);
    if (data) {
      setSelectedFileds({
        ...selectedFields,
        selectedProgram: data,
        selectedSubscritpionPlan: null,
        selectedDiscount: 0,
      });
      setSubscriptionPlanOptions([...data?.subscriptionPlans]);
    } else {
      setSelectedFileds({
        ...selectedFields,
        selectedProgram: null,
        selectedSubscritpionPlan: null,
        selectedDiscount: 0,
      });
      setSubscriptionPlanOptions(null);
    }
  };

  const handleSubscriptionPlanChange = (data) => {
    setSelectedFileds({
      ...selectedFields,
      selectedSubscritpionPlan: data,
    });
    fetchProgramBucketDetails(data);
  };

  const handleDiscountChange = (discount, discountType) => {
    if (discount === customDiscountOptionValue) {
      //handle the cutom input in drowdown and set the text input visible
      setSelectedFileds({
        ...selectedFields,
        selectedDiscount: 0,
        showCustomDiscount: true,
      });

      handleFinalAmount(0);

      return;
    }

    if (discountType !== customDiscountOptionValue) {
      //handle pre defined discount from dropdown

      setSelectedFileds({
        ...selectedFields,
        selectedDiscount: discount,
        ...(selectedFields?.showCustomDiscount
          ? { showCustomDiscount: false }
          : {}),
      });

      handleFinalAmount(discount);

      return;
    }

    //below to handle text input case
    if (isNaN(discount)) {
      notyf.error("Please enter correct discount value.");

      return;
    }

    setSelectedFileds({
      ...selectedFields,
      selectedDiscount: discount,
    });

    if (discountDebounce.current) {
      clearTimeout(discountDebounce.current);
    }

    discountDebounce.current = setTimeout(() => {
      handleFinalAmount(discount);
    }, [600]);
  };

  const handleCheckbox = (checkBoxEvent) => {
    setSelectedFileds({
      ...selectedFields,
      sendInvoice: checkBoxEvent?.target?.checked,
    });
  };

  const handleProgramMetaDeta = (event, fieldName) => {
    const value = event?.target?.value;

    if (value?.length === 0) {
      delete programMetaData[fieldName];
      setProgramMetaData({ ...programMetaData });
    } else if (!Number(value)) {
      return;
    } else {
      setProgramMetaData({ ...programMetaData, [fieldName]: Number(value) });
    }
  };

  useEffect(() => {
    handleFinalAmount(selectedFields.selectedDiscount);
  }, [selectedFields.selectedSubscritpionPlan, selectedAddonProducts]);

  useEffect(() => {
    setProgramBucketDetails(null);
  }, [selectedFields?.selectedProgram?.id]);

  const fetchProgramBucketDetails = async (duration) => {
    try {
      const { data: resData } = await api(END_POINT.getProgramBucketDetails, {
        method: API_METHODS.GET,
        params: {
          duration,
          programId: selectedFields?.selectedProgram?.id,
        },
      });

      if (resData.status === apiResponses.success) {
        notyf.success("Programe bucket details fetched.");
        setProgramBucketDetails(resData?.data);
      } else {
        notyf.error(resData.message);
      }
    } catch (e) {
      notyf.error("Failte to fetch programe bucket details");
    }
  };

  const getClinicInitialState = () => {
    return patientData
      ? {
          label: patientData.clinics?.[0]?.name,
          id: patientData.clinics?.[0]?.id,
        }
      : null;
  };

  const [selectedClinic, setSelectedClinic] = useState(getClinicInitialState());

  const isNewPatient = !id;
  const isAddressAdded = !!pincode || addressState.showAddress;

  const [sectionFormState, setSectionFormState] = useState({
    isBasicDetailsFilled: false,
    isAdditionalDetailsFilled: false,
    isSubscritpionDetailsFilled: false,
    openBasicDetailsAccordion: whatsAppPatientId || isNewPatient,
    openAdditionalDetailsAccordion: false,
    openSubscriptionAccordion: !isNewPatient,
    patientId: whatsAppPatientId ? whatsAppPatientId : patientData?.id,
    addressId: patientData?.address?.[0]?.id || null,
    showAddressError: false,
    [patientFormFields.eta]: {},
    address: patientData?.address?.[0] || null,
    showETAError: {},
  });

  const basicDetailsIntitialValues = {
    firstName: patientData ? patientData?.firstName : "",
    lastName: patientData ? patientData?.lastName : "",
    gender: patientData ? patientData?.gender : "",
    age: patientData ? patientData?.age : "",
    mobileNumber: patientData
      ? patientData?.countryCode?.concat(patientData?.mobileNumber)?.slice(1)
      : prefillMobileNumber
      ? prefillMobileNumber
      : "",
    whatsappNumber: patientData
      ? patientData.countryCode.concat(patientData.whatsappNumber).slice(1)
      : prefillMobileNumber
      ? prefillMobileNumber
      : "",
    clinicId: patientData ? patientData?.clinics?.[0]?.id : "",
    doctor: patientData ? patientData?.doctor?.id : "",
    coach: patientData ? patientData?.coach?.id : "",
    diagnosis: patientData ? patientData?.diagnosis : [],
  };

  const additionalDetailsIntialValues = {
    [patientFormFields.hba1cReading]: patientData
      ? patientData?.hba1cReading?.[0]?.value
      : "",
    [patientFormFields.tsh]: patientData ? patientData?.tsh?.[0]?.value : "",
    [patientFormFields.pincode]: pincode,
    [patientFormFields.address]: address,
    [patientFormFields.city]: city,
    [patientFormFields.landmark]: landmark,
    [patientFormFields.state]: state,
  };

  useEffect(() => {
    const isDiabetesPresentInData = patientData?.diagnosis?.filter((diag) => {
      return (
        diagnosisLabels.diabetes === diag?.diagnosis ||
        diagnosisLabels.typeOneDiabetes === diag.diagnosis ||
        diagnosisLabels.typeTwoDiabetes === diag.diagnosis
      );
    });

    const isThryoidPresentInData = patientData?.diagnosis?.filter(
      (diagnosis) => {
        return diagnosisLabels.thyroid === diagnosis?.diagnosis;
      }
    );

    setDiagnosisSelectedState({
      ...diagnosisSelectedState,
      isDiabetesSelected: !!isDiabetesPresentInData?.length,
      isThyroidSelected: !!isThryoidPresentInData?.length,
    });
  }, [patientData?.diagnosis]);

  const [diagnosisSelectedState, setDiagnosisSelectedState] = useState({
    isDiabetesSelected: false,
    isThyroidSelected: false,
  });

  const isDeliveryRequired = !!selectedAddonProducts.filter(
    (product) => product.createDelivery
  ).length;

  const subscriptionInitialValues = {
    subscriptionPlan: "",
    clinicData: null,
    goalData: null,
    coachData: null,
    programType: "",
    products: "",
    discount: "0",
    remarks: "",
    [patientFormFields.pincode]: pincode,
    [patientFormFields.address]: address,
    [patientFormFields.city]: city,
    [patientFormFields.landmark]: landmark,
    [patientFormFields.state]: state,
    [patientFormFields.paymentReferenceNumber]:
      patientData?.paymentReferenceNumber || "",
    [patientFormFields.deliveryMode]: {},
  };

  const handleCheckETA = async (setFieldValue, deliveryModeId, addOnId) => {
    if (!sectionFormState.addressId) {
      notyf.error("Add address to proceed");
      setSectionFormState({
        ...sectionFormState,
        showAddressError: true,
      });
      return;
    }
    try {
      setLoad(true);
      const { data: resData } = await api(
        END_POINT.checkDeliveryETA(
          sectionFormState?.address?.pincode,
          deliveryModeId
        ),
        {
          method: API_METHODS.GET,
        }
      );
      setLoad(false);
      if (resData.status === apiResponses.success) {
        notyf.success("ETA fetched successfully");
        setSectionFormState({
          ...sectionFormState,
          [patientFormFields.eta]: {
            ...sectionFormState[patientFormFields.eta],
            [addOnId]: resData?.data?.ETA,
          },
          showETAError: { ...sectionFormState.showETAError, [addOnId]: false },
        });
      } else {
        setSectionFormState({
          ...sectionFormState[patientFormFields.eta],
          [addOnId]: null,
        });
        notyf.error(resData.message);
      }
    } catch (e) {
      setFieldValue({
        ...subscriptionInitialValues[patientFormFields.deliveryMode],
        [addOnId]: "",
      });
      setLoad(false);
      notyf.error(
        e.message ||
          "Something happened while updating patient address. Please try again!!"
      );
    }
  };

  const saveBasicDetails = async (values) => {
    setConfirmationPopupState({
      ...confirmationPopupState,
      openBasicDetailsPopup: false,
    });
    let formValues = { ...values };
    if (
      formValues?.firstName?.trim()?.length < minNameStringLength ||
      formValues?.lastName?.trim()?.length < minLastNameStringLength
    ) {
      showErrorMessage("Please enter a valid first name and last name");
      return;
    }

    const countryCode =
      data.dialCode?.charAt(0) === "+" ? data?.dialCode : `+${data?.dialCode}`;

    formValues.mobileNumber = formValues?.mobileNumber.slice(
      data?.dialCode.length
    );

    formValues.whatsappNumber = formValues?.whatsappNumber.slice(
      data?.dialCode.length
    );
    formValues.firstName = formValues?.firstName?.trim();
    formValues.lastName = formValues?.lastName?.trim();

    let newdiagnosisIds = values?.diagnosis.map((diagnosis) => {
      return diagnosis?.id;
    });

    const payload = !sectionFormState.patientId
      ? {
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          gender: formValues.gender,
          age: formValues.age,
          mobileNumber: formValues.mobileNumber,
          whatsappNumber: formValues.whatsappNumber,
          clinics: [
            {
              id: formValues?.clinicId,
            },
          ],
          doctor: formValues.doctor,
          coach: Number(values?.coach) > 0 ? values?.coach : null,
          remarks: formValues.remarks,
          countryCode: countryCode,
          diagnosis: newdiagnosisIds,
        }
      : getChangedBasicDetails(patientData, formValues);

    if (!Object?.values(payload)?.length) {
      setSectionFormState({
        ...sectionFormState,
        isBasicDetailsFilled: true,
        openBasicDetailsAccordion: false,
        openAdditionalDetailsAccordion: true,
      });
      notyf.error("No fields changed");
      return;
    }

    try {
      setLoad(true);
      const { data: resData } = await api(
        sectionFormState.patientId
          ? END_POINT.updatePatientDetails(sectionFormState.patientId)
          : END_POINT.createPatient,
        {
          method: sectionFormState.patientId
            ? API_METHODS.PATCH
            : API_METHODS.POST,
          data: payload,
        }
      );
      setLoad(false);

      if (resData?.status === apiResponses.success) {
        notyf.success(`Patient basic details updated successfully.`);
        setSectionFormState({
          ...sectionFormState,
          patientId: sectionFormState.patientId || resData?.data?.id,
          isBasicDetailsFilled: true,
          openBasicDetailsAccordion: false,
          openAdditionalDetailsAccordion: true,
        });
      } else {
        notyf.error(
          resData?.message ||
            "Something happened while adding/updating patient. Please try again!!"
        );
      }
    } catch (e) {
      setLoad(false);
      notyf.error(
        e?.message ||
          "Something happened while adding/updating patient. Please try again!!"
      );
    }
  };

  const saveAdditionalDetails = async (values) => {
    let formValues = { ...values };
    if (!sectionFormState.patientId) {
      notyf.error("Please fill basic details");
      setSectionFormState({
        ...sectionFormState,
        openBasicDetailsAccordion: true,
      });
      return;
    }

    if (!isNewPatient) {
      let isAddressEdited =
        formValues?.[patientFormFields.pincode] !== pincode ||
        formValues?.[patientFormFields.address] !== address ||
        formValues?.[patientFormFields.city] !== city ||
        formValues?.[patientFormFields.state] !== state;

      formValues.isAddressEdited = !!isAddressEdited;

      if (isAddressEdited) {
        if (
          !formValues?.[patientFormFields.pincode] ||
          formValues?.pincode?.length < maxPincodeLength
        ) {
          setAddressState({ ...addressState, showAddressErr: true });
          return;
        } else if (!formValues?.[patientFormFields.address]?.length) {
          setAddressState({ ...addressState, showAddressErr: true });
          return;
        } else if (!formValues?.[patientFormFields.city]?.length) {
          setAddressState({ ...addressState, showAddressErr: true });
          return;
        } else if (!formValues?.[patientFormFields.state]?.length) {
          setAddressState({ ...addressState, showAddressErr: true });
          return;
        }
      }

      if (isAddressEdited) {
        formValues.address = {
          id: sectionFormState.addressId,
          address1: formValues?.[patientFormFields.address],
          landmark: formValues?.[patientFormFields.landmark],
          city: formValues?.[patientFormFields.city],
          state: formValues?.[patientFormFields.state],
          pincode: formValues?.[patientFormFields.pincode],
        };
      }
    } else {
      let isAddressAdded =
        formValues?.[patientFormFields.pincode] ||
        formValues?.[patientFormFields.address] ||
        formValues?.[patientFormFields.city] ||
        formValues?.[patientFormFields.state];

      if (isAddressAdded) {
        if (
          !formValues?.[patientFormFields.pincode] ||
          formValues.pincode.length < maxPincodeLength
        ) {
          setAddressState({ ...addressState, showAddressErr: true });
          return;
        } else if (!formValues?.[patientFormFields.address]) {
          setAddressState({ ...addressState, showAddressErr: true });
          return;
        } else if (!formValues?.[patientFormFields.city]) {
          setAddressState({ ...addressState, showAddressErr: true });
          return;
        } else if (!formValues?.[patientFormFields.state]) {
          setAddressState({ ...addressState, showAddressErr: true });
          return;
        }
      }

      if (sectionFormState.addressId) {
        formValues.isAddressEdited = !!isAddressAdded;
      } else {
        formValues.isAddressAdded = !!isAddressAdded;
      }

      if (isAddressAdded) {
        formValues.address = {
          ...(sectionFormState.addressId && {
            id: sectionFormState.addressId,
          }),
          address1: formValues?.[patientFormFields.address],
          landmark: formValues?.[patientFormFields.landmark],
          city: formValues?.[patientFormFields.city],
          state: formValues?.[patientFormFields.state],
          pincode: formValues?.[patientFormFields.pincode],
        };
      }
    }

    delete formValues?.[patientFormFields.landmark];
    delete formValues?.[patientFormFields.city];
    delete formValues?.[patientFormFields.state];
    delete formValues?.[patientFormFields.pincode];

    if (!isNewPatient && !formValues?.isAddressEdited) {
      delete formValues?.address;
    }

    try {
      setLoad(true);
      const { data: resData } = await api(
        END_POINT.updatePatientDetails(sectionFormState.patientId),
        {
          method: API_METHODS.PATCH,
          data: { ...formValues },
        }
      );
      setLoad(false);
      if (resData.status === apiResponses.success) {
        notyf.success(`Patient address added successfully.`);

        setSectionFormState({
          ...sectionFormState,
          addressId: resData?.data?.address?.id,
          address: resData?.data?.address,
          isAdditionalDetailsFilled: true,
          openAdditionalDetailsAccordion: false,
          openSubscriptionAccordion: true,
          [patientFormFields.eta]: {},
        });
      } else {
        notyf.error(resData.message);
      }
    } catch (e) {
      setLoad(false);
      notyf.error(
        e.message ||
          "Something happened while updating patient address. Please try again!!"
      );
    }
  };

  const saveProgramAndAddonProducts = async (props) => {
    let {
      goalData,
      coachData,
      clinicData,
      subscriptionPlan,
      prescriptionData,
      ...values
    } = props;

    setLoad(true);

    const programId = props?.programType?.id;
    const isSent = selectedFields?.sendInvoice;
    const selectedProgramName = props?.programType?.name;

    try {
      const { data } = await api(
        END_POINT.postSelectedAddons(sectionFormState.patientId),
        {
          method: API_METHODS.POST,
          data: {
            products: selectedAddonProducts.map((addOn) => {
              if (addOn.createDelivery) {
                const isClinicDeliveryMode =
                  values?.[patientFormFields.deliveryMode]?.[addOn?.id]
                    ?.name === DELIVERY_MODES.clinic;

                return {
                  ...addOn,
                  ETA: isClinicDeliveryMode
                    ? moment().format("YYYY-MM-DD")
                    : sectionFormState?.[patientFormFields.eta][addOn?.id],
                  deliveryAddress: sectionFormState?.address,
                  deliveryModeId:
                    values?.[patientFormFields.deliveryMode]?.[addOn?.id]?.id,
                };
              }

              return addOn;
            }),
            programId: programId ? programId : null,
            programType: selectedProgramName ? selectedProgramName : null,
            programMetaData:
              !!programMetaData && Object.keys(programMetaData).length > 0
                ? programMetaData
                : null,
            programStartDate: selectedProgramName
              ? moment(new Date(startDate)).format("YYYY-MM-DD")
              : null,
            subscriptionPlan: props.subscriptionPlan,
            isSent,
            fullAmount: finalAmount,
            paid: paid,
            discount: selectedFields?.selectedDiscount,
            remarks: props?.remarks,
            paymentReferenceNumber: props?.paymentReferenceNumber,
          },
        }
      );

      setLoad(false);
      if (data?.status === apiResponses.success) {
        notyf.success("Subscription details updated successfully.");
        setSectionFormState({
          ...sectionFormState,
          isSubscritpionDetailsFilled: true,
        });
        push("/");
      } else {
        notyf.error(data?.message);
      }
    } catch (e) {
      setLoad(false);
      notyf.error(e?.message);
    }
  };

  const renderDiolog = (values) => {
    if (!sectionFormState.patientId) {
      notyf.error("Please fill basic details");
      setSectionFormState({
        ...sectionFormState,
        openBasicDetailsAccordion: true,
        openSubscriptionAccordion: false,
      });
      return;
    }

    if (
      selectedFields.selectedProgram?.id === ASSURE_PROGRAM_ID &&
      (!programMetaData?.startHbA1c || !programMetaData?.targetHbA1c)
    ) {
      showErrorMessage("Please fill all required fields");
      setConfirmationPopupState({
        ...confirmationPopupState,
        openProgramAddonPopup: false,
      });
      setShowMetaDetaError(true);
      return;
    }

    if (!sectionFormState.patientId) {
      notyf.error("Please fill basic details");
      setSectionFormState({
        ...sectionFormState,
        openBasicDetailsAccordion: true,
        openSubscriptionAccordion: false,
      });

      setConfirmationPopupState({
        ...confirmationPopupState,
        openProgramAddonPopup: false,
      });
      return;
    }

    if (
      selectedFields.selectedProgram?.id === ASSURE_PROGRAM_ID &&
      (!programMetaData?.startHbA1c || !programMetaData?.targetHbA1c)
    ) {
      showErrorMessage("Please fill all required fields");
      setConfirmationPopupState({
        ...confirmationPopupState,
        openProgramAddonPopup: false,
      });

      setConfirmationPopupState({
        ...confirmationPopupState,
        openProgramAddonPopup: false,
      });

      setShowMetaDetaError(true);
      return;
    }

    let addOnIdWithETAError = [];
    selectedAddonProducts.forEach((addOn) => {
      const addOnId = addOn?.id;

      if (
        addOn.createDelivery &&
        values?.[patientFormFields.deliveryMode]?.[addOnId]?.name !==
          DELIVERY_MODES.clinic &&
        !sectionFormState[patientFormFields.eta][addOnId]
      ) {
        addOnIdWithETAError.push(addOnId);
      }
    });

    if (isDeliveryRequired && addOnIdWithETAError.length > 0) {
      setConfirmationPopupState({
        ...confirmationPopupState,
        openProgramAddonPopup: false,
      });

      const errorState = {};
      addOnIdWithETAError.forEach((id) => (errorState[id] = true));

      setSectionFormState((prevState) => ({
        ...prevState,
        showETAError: { ...prevState.showETAError, ...errorState },
      }));

      notyf.error("Please Check ETA");

      return;
    }

    const isAddressPresent = !!sectionFormState?.addressId;

    if (isDeliveryRequired && !isAddressPresent) {
      notyf.error("Please add address");

      setConfirmationPopupState({
        ...confirmationPopupState,
        openProgramAddonPopup: false,
      });

      setSectionFormState((prevState) => ({
        ...prevState,
        showAddressError: true,
      }));

      return;
    }

    if (
      !paid &&
      !window.confirm(
        "You have not collected the payment. \n Please tick paid checkbox if payment is collected.\n\n Do you want to proceed without payment ?"
      )
    ) {
      setConfirmationPopupState({
        ...confirmationPopupState,
        openProgramAddonPopup: false,
      });

      return;
    }

    if (paid && !values?.paymentReferenceNumber) {
      showErrorMessage(
        "Please fill the payment reference number of the payment."
      );

      setConfirmationPopupState({
        ...confirmationPopupState,
        openProgramAddonPopup: false,
      });

      return;
    }

    return (
      <Dialog
        open={confirmationPopupState.openProgramAddonPopup}
        onClose={handleCloseConfirmDialog}
        className={classes.mainDialogBoxContainer}
      >
        <DialogBox
          productAddon={productAddon}
          selectedProgram={selectedFields.selectedProgram}
          handleCloseConfirmDialog={handleCloseConfirmDialog}
          classes={classes}
          onClick={() => {
            setConfirmationPopupState({
              ...confirmationPopupState,
              openProgramAddonPopup: false,
            });

            saveProgramAndAddonProducts(values);
          }}
        />
      </Dialog>
    );
  };
  const [openDiagnosis, setOpenDiagnosis] = useState(false);

  const patientBasicDetails = useMemo(() => {
    return (
      <div className={classes.sectionsContainer}>
        <Formik
          initialValues={basicDetailsIntitialValues}
          validationSchema={basicDetailsSchema}
          onSubmit={saveBasicDetails}
        >
          {(formProps) => {
            const { values, errors, setFieldValue, handleSubmit } = formProps;
            const hasErrors = Object.keys(errors).length > 0;
            addFieldValues = (field, value) => {
              setFieldValue(field, value);
            };

            const handleDiagnosisChange = (event) => {
              setOpenDiagnosis(false);
              let selectedDiagnosis = event?.target?.value;
              let isDiagnosisSelected = selectedDiagnosis?.filter(
                (diagnosis) => {
                  return (
                    diagnosisLabels.diabetes === diagnosis?.diagnosis ||
                    diagnosisLabels.typeOneDiabetes === diagnosis.diagnosis ||
                    diagnosisLabels.typeTwoDiabetes === diagnosis.diagnosis
                  );
                }
              );

              let isThyroidSelected = selectedDiagnosis?.filter((diagnosis) => {
                return diagnosisLabels.thyroid === diagnosis?.diagnosis;
              });

              setDiagnosisSelectedState({
                ...diagnosisSelectedState,
                isDiabetesSelected: !!isDiagnosisSelected?.length,
                isThyroidSelected: !!isThyroidSelected?.length,
              });

              setFieldValue("diagnosis", event?.target?.value);
            };

            const diagnosisArray = values?.diagnosis?.map((initialValue) =>
              diagnosisList.find(
                (diagnosis) => diagnosis?.id === initialValue?.id
              )
            );

            const handleDiagnosisRender = (selected) => {
              return (
                <Stack gap={1} direction="row" flexWrap="wrap">
                  {selected.map((value) => (
                    <Chip key={value?.id} label={value?.diagnosis} />
                  ))}
                </Stack>
              );
            };

            const handleOpenDiagnosis = () => {
              setOpenDiagnosis(true);
            };

            return (
              <Form>
                <Card className={classes.card}>
                  <div className={classes.flex}>
                    <Typography className={classes.label}>
                      First Name
                    </Typography>
                    <span className={classes.required}>*</span>
                  </div>
                  <FormTextInput
                    variant="standard"
                    name="firstName"
                    type="text"
                    placeholder="Enter first name here"
                    typeOf="auth"
                    disabled={!isEditable}
                  />
                </Card>
                <Card className={classes.card}>
                  <div className={classes.flex}>
                    <Typography className={classes.label}>Last Name</Typography>
                    <span className={classes.required}>*</span>
                  </div>
                  <FormTextInput
                    variant="standard"
                    name="lastName"
                    type="text"
                    placeholder="Enter last name here"
                    typeOf="auth"
                    disabled={!isEditable}
                  />
                </Card>
                <Card className={classes.card}>
                  <div className={classes.flex}>
                    <Typography className={classes.label}>Gender</Typography>
                    <span className={classes.required}>*</span>
                  </div>
                  <FormSelectField
                    name="gender"
                    label="Select"
                    options={genderData}
                    type="patient"
                    typeOf="auth"
                    disabled={!isEditable}
                  />
                </Card>
                <Card className={classes.card}>
                  <div className={classes.flex}>
                    <Typography className={classes.label}>Age</Typography>
                    <span className={classes.required}>*</span>
                  </div>
                  <FormTextInput
                    variant="standard"
                    name="age"
                    type="number"
                    maxLength={2}
                    placeholder="Enter age here"
                    typeOf="auth"
                    disabled={!isEditable}
                  />
                </Card>
                <Card className={classes.card}>
                  <div className={classes.flex}>
                    <Typography className={classes.label}>
                      Mobile Number
                    </Typography>
                    <span className={classes.required}>*</span>
                  </div>
                  <PhoneInput
                    country={"in"}
                    inputStyle={{ width: "100%" }}
                    name="mobileNumber"
                    value={values.mobileNumber}
                    onChange={(mobileNumberDetails, data) => {
                      setFieldValue("mobileNumber", mobileNumberDetails);
                      handleOnChange(data);
                    }}
                    inputProps={{
                      name: "mobileNumber",
                      required: true,
                      autoFocus: true,
                    }}
                    disabled={true}
                    isValid={(value, country) => {
                      return true;
                    }}
                  />
                  {errors?.mobileNumber && (
                    <FormHelperText className={classes.errorText}>
                      {errors?.mobileNumber}
                    </FormHelperText>
                  )}
                </Card>
                <Card className={classes.card}>
                  <div className={classes.flex}>
                    <Typography className={classes.label}>
                      WhatsApp Number
                    </Typography>
                    <span className={classes.required}>*</span>
                  </div>
                  <PhoneInput
                    country={"in"}
                    inputStyle={{ width: "100%" }}
                    name="whatsappNumber"
                    value={values.whatsappNumber}
                    onChange={(whatsappNumberDetails, data) => {
                      setFieldValue("whatsappNumber", whatsappNumberDetails);
                      handleOnChange(data);
                    }}
                    inputProps={{
                      name: "whatsappNumber",
                      required: true,
                      autoFocus: true,
                    }}
                    isValid={(value, country) => {
                      return true;
                    }}
                    disabled={true}
                  />
                  {errors?.whatsappNumber && (
                    <FormHelperText className={classes.errorText}>
                      {errors?.whatsappNumber}
                    </FormHelperText>
                  )}
                </Card>
                <Card className={classes.card}>
                  <div className={classes.flex}>
                    <Typography className={classes.label}>Diagnosis</Typography>
                  </div>
                  <div>
                    <FormControl fullWidth>
                      <InputLabel
                        id="demo-multiple-name-label"
                        className={classes.inputLabel}
                      >
                        {lang.select}
                      </InputLabel>
                      <Select
                        multiple
                        value={diagnosisArray}
                        onChange={handleDiagnosisChange}
                        input={<OutlinedInput label="Multiple Select" />}
                        renderValue={handleDiagnosisRender}
                        disabled={!isEditable}
                        open={openDiagnosis}
                        onOpen={handleOpenDiagnosis}
                      >
                        {diagnosisList.map((diagnosis, index) => (
                          <MenuItem key={diagnosis?.id} value={diagnosis}>
                            {diagnosis?.diagnosis}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <Typography className={classes.bottomLabel}>
                    {lang.youCanSelectMultipleDiagnosis}
                  </Typography>
                </Card>
                <Card className={classes.card}>
                  <div className={classes.flex}>
                    <Typography className={classes.label}>
                      Clinic Name
                    </Typography>
                    <span className={classes.required}>*</span>
                  </div>
                  <FormAutoCompleteField
                    name="clinicId"
                    options={clinicsData || []}
                    value={selectedClinic?.label}
                    onChange={(clinic) => {
                      setSelectedClinic({
                        label: clinic?.label,
                        id: clinic?.value,
                      });
                      fetchClinicData(clinic?.value);
                    }}
                    label="Please type the clinic name"
                    type="patient"
                    typeOf="auth"
                    disabled={!isEditable}
                  />
                </Card>
                {values.clinicId && (
                  <>
                    <Card className={classes.card}>
                      <div className={classes.flex}>
                        <Typography className={classes.label}>
                          Doctor Name
                        </Typography>
                        <span className={classes.required}>*</span>
                      </div>
                      <FormSelectField
                        name="doctor"
                        label="Select"
                        options={doctors}
                        typeOf="auth"
                        type="patient"
                        disabled={!isEditable}
                      />
                    </Card>
                    <Card className={classes.card}>
                      <div className={classes.flex}>
                        <Typography className={classes.label}>
                          Coach Name
                        </Typography>
                      </div>
                      <FormSelectField
                        name="coach"
                        label="Select"
                        options={coachs}
                        typeOf="auth"
                        type="patient"
                        disabled={!isEditable}
                      />
                    </Card>
                  </>
                )}
                <Button
                  md
                  onClick={async () => {
                    if (hasErrors) {
                      showErrorMessage(
                        "Please fill the reqired fields in Basic Details"
                      );
                      return;
                    }

                    setConfirmationPopupState({
                      ...confirmationPopupState,
                      openBasicDetailsPopup: true,
                    });
                  }}
                  fullWidth
                  className={classes.submitButton}
                >
                  {lang.save}
                </Button>
                {confirmationPopupState?.openBasicDetailsPopup && (
                  <Dialog
                    open={confirmationPopupState?.openBasicDetailsPopup}
                    onClose={handleCloseConfirmDialog}
                  >
                    <BasicDetailsConfirmPopup
                      handleYesConfirmDialog={handleSubmit}
                      handleCloseConfirmDialog={handleCloseConfirmDialog}
                      values={values}
                      clinicsData={clinicsData}
                      doctors={doctors}
                      patientName={values.firstName + " " + values?.lastName}
                    />
                  </Dialog>
                )}
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }, [
    patientData?.id,
    clinics,
    doctors,
    coachs,
    diagnosisList,
    addressState?.showAddressErr,
    confirmationPopupState?.openBasicDetailsPopup,
    openDiagnosis,
  ]);

  const patientAdditionalDetails = useMemo(() => {
    return (
      <div className={classes.sectionsContainer}>
        <Formik
          initialValues={additionalDetailsIntialValues}
          onSubmit={saveAdditionalDetails}
        >
          {(formProps) => {
            const { values, setFieldValue, handleSubmit } = formProps;

            addFieldValues = (field, value) => {
              setFieldValue(field, value);
            };

            return (
              <Form>
                {diagnosisSelectedState.isDiabetesSelected && (
                  <Card className={classes.card}>
                    <div className={classes.flex}>
                      <Typography className={classes.label}>
                        {lang.hba1c}
                      </Typography>
                    </div>
                    <FormTextInput
                      variant="standard"
                      name={patientFormFields.hba1cReading}
                      type="decimal"
                      placeholder="Enter hba1c"
                      typeOf="auth"
                    />
                  </Card>
                )}
                {diagnosisSelectedState.isThyroidSelected && (
                  <Card className={classes.card}>
                    <div className={classes.flex}>
                      <Typography className={classes.label}>
                        {lang.tsh}
                      </Typography>
                    </div>
                    <FormTextInput
                      variant="standard"
                      name={patientFormFields.tsh}
                      type="decimal"
                      placeholder="Enter TSH"
                      typeOf="auth"
                    />
                  </Card>
                )}
                {isAddressAdded ? (
                  <>
                    <Card className={classes.card}>
                      <div className={classes.flex}>
                        <Typography className={classes.label}>
                          {lang.pincode}
                        </Typography>
                      </div>
                      <FormTextInput
                        variant="standard"
                        name={patientFormFields.pincode}
                        type="number"
                        placeholder="Add Pincode"
                        typeOf="auth"
                        maxLength={maxPincodeLength}
                        multiline={true}
                        onChange={(e) => {
                          setSectionFormState({
                            ...sectionFormState,
                            showAddressError: false,
                          });
                          if (e.target.value?.length > maxPincodeLength) {
                            return;
                          }
                          if (e.target.value?.length === maxPincodeLength) {
                            handleCityAndState(e.target.value, setFieldValue);
                          }
                        }}
                      />
                      {!values?.pincode && addressState?.showAddressErr && (
                        <FormHelperText className={classes.errorText}>
                          {addressErrors?.invalidPincode}
                        </FormHelperText>
                      )}
                    </Card>
                    <Card className={classes.card}>
                      <div className={classes.flex}>
                        <Typography className={classes.label}>
                          {lang.address}
                        </Typography>
                      </div>
                      <FormTextInput
                        variant="standard"
                        name={patientFormFields.address}
                        type="text"
                        placeholder="Enter Address"
                        typeOf="auth"
                        multiline={true}
                      />
                      {!values?.address && addressState?.showAddressErr && (
                        <FormHelperText className={classes.errorText}>
                          {addressErrors?.addressIsReq}
                        </FormHelperText>
                      )}
                    </Card>
                    <Card className={classes.card}>
                      <div className={classes.flex}>
                        <Typography className={classes.label}>
                          {lang.landmark}
                        </Typography>
                      </div>
                      <FormTextInput
                        variant="standard"
                        name={patientFormFields.landmark}
                        type="text"
                        placeholder="Add Landmark"
                        typeOf="auth"
                        multiline={true}
                      />
                    </Card>
                    <Card className={classes.card}>
                      <div className={classes.flex}>
                        <Typography className={classes.label}>
                          {lang.city}
                        </Typography>
                      </div>
                      <FormTextInput
                        variant="standard"
                        name={patientFormFields.city}
                        type="text"
                        placeholder="Add City"
                        typeOf="auth"
                        multiline={true}
                      />
                      {!values?.city && addressState?.showAddressErr && (
                        <FormHelperText className={classes.errorText}>
                          {addressErrors?.cityIsReq}
                        </FormHelperText>
                      )}
                    </Card>

                    <Card className={classes.card}>
                      <div className={classes.flex}>
                        <Typography className={classes.label}>
                          {lang.state}
                        </Typography>
                      </div>
                      <FormTextInput
                        variant="standard"
                        name={patientFormFields.state}
                        type="text"
                        placeholder="Add State"
                        typeOf="auth"
                        multiline={true}
                      />
                      {!values?.state && addressState?.showAddressErr && (
                        <FormHelperText className={classes.errorText}>
                          {addressErrors?.stateIsReq}
                        </FormHelperText>
                      )}
                    </Card>
                  </>
                ) : (
                  <Card className={classes.card}>
                    <div className={classes.flex}>
                      <Typography className={classes.box1}>
                        {`${lang.address} :`}
                      </Typography>
                      <Button
                        md
                        onClick={() => {
                          setAddressState({
                            ...addressState,
                            showAddress: true,
                          });
                        }}
                        className={classes.addressBtn}
                      >
                        {lang.clickToAddAddress}
                      </Button>
                    </div>
                  </Card>
                )}
                <Button
                  md
                  onClick={() => {
                    handleSubmit();
                  }}
                  fullWidth
                  className={classes.submitButton}
                >
                  {lang.save}
                </Button>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }, [
    additionalDetailsIntialValues,
    patientData?.id,
    addressState,
    sectionFormState,
  ]);

  const patientSubscriptionDetails = useMemo(() => {
    const discountOptions = discount
      ? [
          ...discount,
          {
            label: customDisctounOptiomLabel,
            value: customDiscountOptionValue,
          },
        ]
      : [];

    const renderDeliveryModes = (
      values,
      errors,
      handleDeliveryModeChange,
      setFieldValue
    ) => {
      const deliverCards = [];

      selectedAddonProducts.forEach((addOn) => {
        if (addOn.createDelivery) {
          deliverCards.push(
            <DeliveryModeCard
              addOnId={addOn.id}
              productName={addOn.productName}
              values={values}
              errors={errors}
              handleDeliveryModeChange={(event) =>
                handleDeliveryModeChange(event, addOn.id)
              }
              deliveryModes={deliveryModes}
              sectionFormState={sectionFormState}
              setSectionFormState={setSectionFormState}
              handleCheckETA={handleCheckETA}
              setFieldValue={setFieldValue}
            />
          );
        }
      });

      return deliverCards;
    };

    return (
      <div className={classes.sectionsContainer}>
        <Formik
          initialValues={subscriptionInitialValues}
          validationSchema={subscriptionDetailsSchema(
            patientData,
            selectedFields,
            selectedAddonProducts
          )}
        >
          {(formProps) => {
            const { values, errors, setFieldValue, setErrors } = formProps;
            const hasErrors = Object.keys(errors).length > 0;
            addFieldValues = (field, value) => {
              setFieldValue(field, value);
            };

            patientSubscriptionFormValuesRef.current = values;

            let isAddressChanged =
              values?.[patientFormFields.pincode] !== pincode ||
              values?.[patientFormFields.address] !== address ||
              values?.[patientFormFields.landmark] !== landmark ||
              values?.[patientFormFields.city] !== city ||
              values?.[patientFormFields.state] !== state;

            const handleDeliveryModeChange = (event, addOnId) => {
              setSectionFormState((prevState) => ({
                ...prevState,
                showETAError: { ...prevState.showETAError, [addOnId]: false },
                [patientFormFields.eta]: {
                  ...prevState[patientFormFields.eta],
                  [addOnId]: null,
                },
              }));

              setFieldValue(patientFormFields.deliveryMode, {
                ...values[patientFormFields.deliveryMode],
                [addOnId]: event?.target?.value,
              });
            };

            return (
              <Form>
                {patientData &&
                  programAddonHistory?.programHistory?.length > 0 && (
                    <Card className={classes.card}>
                      <Typography className={classes.label}>
                        Program History
                        <span className={classes.required}>*</span>
                      </Typography>
                      <Table tableData={programAddonHistory?.programHistory} />
                    </Card>
                  )}
                <Card className={classes.card}>
                  <Typography className={classes.label}>
                    Program Type
                    {!patientData && (
                      <span className={classes.required}>*</span>
                    )}
                  </Typography>
                  <FormSelectField
                    name="programType"
                    label="Select"
                    options={allPrograms}
                    typeOf="auth"
                    type="patient"
                    onChange={handleProgramTypeChange}
                  />
                </Card>
                {(selectedFields.selectedProgram || !patientData) && (
                  <>
                    {selectedFields.selectedProgram?.programMetaData?.length >
                      0 && (
                      <>
                        {selectedFields.selectedProgram?.programMetaData.map(
                          (programMetaDt) => (
                            <Card className={classes.card}>
                              <div className={classes.flex}>
                                <Typography className={classes.label}>
                                  {programMetaDt.label}
                                </Typography>
                              </div>
                              <FormTextInput
                                variant="standard"
                                name={programMetaDt.keyName}
                                placeholder={`Enter ${programMetaDt.label} (in numbers)`}
                                typeOf="auth"
                                onChange={(event) => {
                                  handleProgramMetaDeta(
                                    event,
                                    programMetaDt.keyName
                                  );
                                }}
                              />
                              {!programMetaData?.[programMetaDt.keyName] &&
                                showMetaDetaError && (
                                  <FormHelperText className={classes.errorText}>
                                    {`${
                                      programMetaDetaKeys[
                                        programMetaDt?.keyName
                                      ]
                                    } is Required`}
                                  </FormHelperText>
                                )}
                            </Card>
                          )
                        )}
                      </>
                    )}
                    <Card className={classes.card}>
                      <Typography className={classes.label}>
                        Subscription Plan
                        <span className={classes.required}>*</span>
                      </Typography>
                      <FormSelectField
                        name="subscriptionPlan"
                        label="Select"
                        options={subscriptionPlanOptions}
                        typeOf="auth"
                        type="patient"
                        onChange={handleSubscriptionPlanChange}
                      />
                    </Card>
                    {programBucketDatails &&
                      programBucketDatails.length > 0 && (
                        <Card className={classes.card}>
                          <Typography className={classes.label}>
                            Program Details:
                          </Typography>
                          {programBucketDatails?.map((programService) => (
                            <div className={classes.flex}>
                              <div>{programService.productName}</div>
                            </div>
                          ))}
                        </Card>
                      )}
                    <Card className={classes.card}>
                      <div className={classes.startDateContainer}>
                        <div className={classes.startDate}>
                          <Typography className={classes.label}>
                            Program Start Date
                          </Typography>
                          <span className={classes.required}>*</span>
                        </div>
                        <div className={classes.flex}>
                          <span>
                            <DateSelector
                              selectedDate={startDate}
                              onDateChange={(date) => setStartDate(date)}
                            />
                          </span>
                          <span>
                            <img
                              src={dateIcon}
                              className={classes.calenderImage}
                              alt=""
                            />
                          </span>
                        </div>
                      </div>
                    </Card>
                  </>
                )}
                {patientData &&
                  programAddonHistory?.addOnProductsHistory?.length > 0 && (
                    <Card className={classes.card}>
                      <Typography className={classes.label}>
                        Add On History
                        <span className={classes.required}>*</span>
                      </Typography>
                      <Table
                        tableData={programAddonHistory?.addOnProductsHistory}
                      />
                    </Card>
                  )}
                <Card className={classes.card}>
                  <Typography className={classes.label}>Add On</Typography>
                  <div className={classes.addOnContainer}>
                    <div className={classes.selectAddOnComponent}>
                      <FormSelectField
                        name="products"
                        label="Select"
                        options={addon}
                        type="patient"
                        typeOf="auth"
                        onChange={handleAddon}
                      />
                    </div>
                    <div className={classes.quantityHeading}>Quantity</div>
                  </div>
                  {selectedAddonProducts?.map((AddonProduct) => (
                    <div className={classes.flex}>
                      <div className={classes.selectedAddOnTable}>
                        <div className={classes.selectedAddOnName}>
                          <img
                            className={classes.crossIcon}
                            src={crossIcon}
                            alt=""
                            onClick={() => {
                              handleAddOnQuantity(
                                -AddonProduct.quantity,
                                AddonProduct,
                                setFieldValue
                              );

                              setTimeout(() => {
                                const {
                                  [patientFormFields.deliveryMode]:
                                    deliveryModeError,
                                  ...rest
                                } = errors;

                                setErrors(rest);
                              }, 0);
                            }}
                          />
                          <div>{AddonProduct.productName}</div>
                        </div>
                      </div>
                      <div className={classes.quantityContainer}>
                        <div className={classes.addonIncrement}>
                          <div
                            onClick={() =>
                              handleAddOnQuantity(
                                1,
                                AddonProduct,
                                setFieldValue
                              )
                            }
                          >
                            ▲
                          </div>
                          <div
                            onClick={() =>
                              handleAddOnQuantity(
                                -1,
                                AddonProduct,
                                setFieldValue
                              )
                            }
                          >
                            ▼
                          </div>
                        </div>
                        <div className={classes.quantityPixel}>
                          {AddonProduct.quantity}
                        </div>
                      </div>
                    </div>
                  ))}
                </Card>
                {renderDeliveryModes(
                  values,
                  errors,
                  handleDeliveryModeChange,
                  setFieldValue
                )}
                {(selectedFields?.selectedProgram ||
                  selectedAddonProducts?.length > 0 ||
                  !patientData) && (
                  <>
                    <Card className={classes.card}>
                      <Typography className={classes.label}>
                        Discount
                        <span className={classes.required}>*</span>
                      </Typography>
                      <FormAutoCompleteField
                        name="discount"
                        options={discountOptions}
                        value={
                          selectedFields?.showCustomDiscount
                            ? customDisctounOptiomLabel
                            : selectedFields?.selectedDiscount
                        }
                        onChange={(searchDiscount) => {
                          if (
                            !searchDiscount &&
                            selectedFields?.selectedDiscount
                          ) {
                            setSelectedFileds((prevState) => ({
                              ...prevState,
                              selectedDiscount: "",
                              showCustomDiscount: false,
                            }));

                            handleFinalAmount(0);

                            return;
                          }

                          handleDiscountChange(
                            searchDiscount?.value,
                            searchDiscount?.value
                          );
                        }}
                        label="Please search the discount value:"
                        type="patient"
                        typeOf="auth"
                      />
                      {selectedFields?.showCustomDiscount && (
                        <div>
                          <Typography className={classes.finalAmount}>
                            Discount Amount:
                          </Typography>
                          <TextField
                            className={classes.box}
                            style={{ width: "100%" }}
                            value={
                              selectedFields?.selectedDiscount
                                ? selectedFields?.selectedDiscount
                                : ""
                            }
                            placeholder={"Please enter the discount"}
                            onChange={(e) => {
                              handleDiscountChange(
                                e.target.value,
                                customDiscountOptionValue
                              );
                            }}
                          />
                        </div>
                      )}
                    </Card>
                    {showFinalAmountError && (
                      <Typography className={classes.errorText}>
                        Final amount should be greater than or equal to zero
                      </Typography>
                    )}
                    <Card className={classes.cardOrFlex}>
                      <Typography className={classes.finalAmount}>
                        Final Amount --
                      </Typography>
                      <Typography className={classes.box}>
                        {showFinalAmountError ? `--` : `₹ ${finalAmount}`}
                      </Typography>
                    </Card>
                    <Card className={classes.card}>
                      <div className={classes.paymentStatus}>
                        <div className={classes.paymentStatusTitle}>
                          <Typography className={classes.label}>
                            Payment Status
                          </Typography>
                        </div>
                        <div>
                          <Typography className={classes.labelHeading}>
                            <Field
                              type="checkbox"
                              name="paid"
                              checked={paid}
                              onChange={() => {
                                setPaid((prev) => !prev);
                              }}
                            />
                            Paid
                          </Typography>
                        </div>
                      </div>
                    </Card>
                  </>
                )}
                <Card className={classes.card}>
                  <div className={classes.flex}>
                    <Typography className={classes.label}>
                      {lang.paymentReferenceNumber}
                    </Typography>
                  </div>
                  <FormTextInput
                    variant="standard"
                    name={patientFormFields.paymentReferenceNumber}
                    type="text"
                    placeholder="Enter payment reference number"
                    typeOf="auth"
                  />
                  <Typography className={classes.bottomLabel}>
                    {lang.enterLastFourDigitsOfReferenceNumber}
                  </Typography>
                </Card>
                {(selectedFields?.selectedProgram ||
                  selectedAddonProducts?.length > 0 ||
                  !patientData) && (
                  <>
                    <Card className={classes.cardOrFlex}>
                      <Typography className={classes.box1}>
                        <Field
                          type="checkbox"
                          name="consultationAttended"
                          checked={selectedFields.sendInvoice}
                          onChange={(checkBoxEvent) => {
                            handleCheckbox(checkBoxEvent);
                          }}
                        />
                      </Typography>

                      <Typography className={classes.box1}>
                        Send invoice to patient
                      </Typography>
                    </Card>
                  </>
                )}
                {!!patientData && remarks?.length > 0 && (
                  <Card className={classes.card}>
                    <Typography className={classes.box1}>
                      Previous Remarks
                    </Typography>
                    <div className={classes.remarksContainer}>
                      {remarks.map((remark, index) => (
                        <div className={classes.remarksRow}>
                          {`${index + 1}. ${remark}`}
                        </div>
                      ))}
                    </div>
                  </Card>
                )}
                {(selectedFields?.selectedProgram ||
                  selectedAddonProducts?.length > 0 ||
                  isAddressChanged ||
                  !patientData) && (
                  <>
                    {patientData && Object.keys(errors).length > 0 && (
                      <Typography className={classes.errorText}>
                        Please add a remark
                      </Typography>
                    )}
                    <Card className={classes.card}>
                      <div className={classes.flex}>
                        <Typography className={classes.label}>
                          Remarks
                        </Typography>
                        {(selectedFields?.selectedProgram ||
                          selectedAddonProducts?.length > 0 ||
                          isAddressChanged ||
                          !patientData) && (
                          <span className={classes.required}>*</span>
                        )}
                      </div>
                      <FormTextInput
                        variant="standard"
                        name="remarks"
                        type="text"
                        placeholder="Enter remark"
                        typeOf="auth"
                        multiline={true}
                      />
                    </Card>
                  </>
                )}
                <Button
                  md
                  onClick={() => {
                    if (
                      selectedAddonProducts?.length > 0 ||
                      selectedFields?.selectedProgram
                    ) {
                      if (hasErrors) {
                        notyf.error(
                          "Please fill the required fields in Subscription details"
                        );

                        setConfirmationPopupState({
                          ...confirmationPopupState,
                          openProgramAddonPopup: false,
                        });
                      } else {
                        setConfirmationPopupState({
                          ...confirmationPopupState,
                          openProgramAddonPopup: true,
                        });
                      }
                    } else {
                      setConfirmationPopupState({
                        ...confirmationPopupState,
                        openProgramAddonPopup: false,
                      });

                      notyf.error("Please select a program/add on to proceed");
                    }
                  }}
                  fullWidth
                  className={classes.submitButton}
                  disabled={!patientData && showFinalAmountError}
                >
                  Submit
                </Button>
              </Form>
            );
          }}
        </Formik>
        {(selectedAddonProducts?.length > 0 ||
          selectedFields?.selectedProgram) &&
          confirmationPopupState.openProgramAddonPopup &&
          renderDiolog(patientSubscriptionFormValuesRef.current)}
      </div>
    );
  }, [
    patientData?.id,
    showFinalAmountError,
    confirmationPopupState.openProgramAddonPopup,
    selectedFields,
    selectedAddonProducts,
    programAddonHistory,
    programMetaData,
    showMetaDetaError,
    remarks?.length,
    showFinalAmountError,
    finalAmount,
    allPrograms,
    addon,
    discount,
    paid,
    startDate,
    sectionFormState,
    programBucketDatails,
  ]);

  return (
    <>
      {loading && <Loader open={true} loaderMessage="Fetching..." />}
      {isNewPatient &&
        sectionFormState.isBasicDetailsFilled &&
        !sectionFormState.isSubscritpionDetailsFilled && (
          <Prompt message={lang.dropOffConfirmationMessage} />
        )}

      <TabCloseConfirmationPopup />
      <div className={classes.titleHeading}>
        <Typography className={classes.title}>
          {isNewPatient ? "Add New Patient" : "Edit Patient"}
        </Typography>

        <div className={classes.subContainer}>
          <AccordionContainer
            children={patientBasicDetails}
            title={lang.basicDetails}
            showCompletionIcon={
              isNewPatient ? sectionFormState.isBasicDetailsFilled : false
            }
            expandAccordion={sectionFormState.openBasicDetailsAccordion}
          />
        </div>
        <div className={classes.subContainer}>
          <AccordionContainer
            children={patientAdditionalDetails}
            title={lang.additionalDetails}
            showCompletionIcon={
              isNewPatient ? sectionFormState.isAdditionalDetailsFilled : false
            }
            expandAccordion={sectionFormState.openAdditionalDetailsAccordion}
          />
          {sectionFormState.showAddressError && (
            <Typography className={classes.accordinError}>
              {lang.addressIsRequired}
            </Typography>
          )}
        </div>
        <div>
          <AccordionContainer
            children={patientSubscriptionDetails}
            title={lang.subscriptionDetails}
            expandAccordion={sectionFormState.openSubscriptionAccordion}
          />
        </div>
        {load && <Loader open={true} loaderMessage="Loading..." />}
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    loading: state.addPatientReducers.loading,
    allClinics:
      state.addPatientReducers.allClinics !== null &&
      state.addPatientReducers.allClinics.data,
    doctorCoachDetailsByClinic:
      state.addPatientReducers.doctorCoachDetailsByClinic !== null &&
      state.addPatientReducers.doctorCoachDetailsByClinic.data,
    addOnAndOffer:
      state.addPatientReducers.addOnAndOffer !== null &&
      state.addPatientReducers.addOnAndOffer.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        PostAddPatientAction,
        UpdatePatientAction,
        GetAllClinicsAction,
        GetDoctorAndCoachByClinicAction,
        PostUploadCoachAssessmentDataAction,
        PostUploadClinicalDataAction,
        PostUploadGoalDataAction,
        GetAddonAndOffersData,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPatient);
