import React from "react";
import { Divider, Typography, withStyles } from "@material-ui/core";
import CustomButtons from "@components/atoms/Button";
import lang from "assets/languages";
import Dropdown from "@components/molecules/Dropdown";
import { deactivationReasons } from "../../constants";
import confirmPopupStyle from "./confirmPopupStyle";
import { dropDownOptions } from "@components/molecules/Dropdown/constants";

const ConfirmPopup = ({
  classes,
  handleYesConfirmDialog,
  handleCloseConfirmDialog,
  firstName,
  lastName,
  setDeactivationReason,
}) => {
  const handleDropDownOptions = (selectedOption) => {
    if (selectedOption.key === dropDownOptions.others) {
      setDeactivationReason(null);
    } else {
      setDeactivationReason(selectedOption.value);
    }
  };

  const handleCustomMessage = (value) => {
    setDeactivationReason(value);
  };

  return (
    <div className={classes.mainContainer}>
      <div className={classes.dialogTextBox}>
        <Typography className={classes.dialogText}>
          {`Do you want deactivate the sensor for ${
            firstName ? firstName : ""
          } ${lastName ? lastName : ""}?`}
        </Typography>
      </div>
      <div className={classes.dropDownContainer}>
        <Typography className={classes.selectLabel}>
          {lang.deactivationReasonSelectLable}
        </Typography>
      </div>
      <div className={classes.dropDown}>
        <Dropdown
          options={deactivationReasons}
          onOption={handleDropDownOptions}
          classes={classes}
          defaultTheme={false}
          onCustomMessage={handleCustomMessage}
        />
      </div>

      <Divider />
      <div className={classes.buttonsBox}>
        <CustomButtons md_border onClick={handleCloseConfirmDialog}>
          {lang.no}
        </CustomButtons>
        <CustomButtons
          md
          onClick={handleYesConfirmDialog}
          className={classes.buttonYes}
        >
          {lang.yes}
        </CustomButtons>
      </div>
    </div>
  );
};

export default withStyles(confirmPopupStyle)(ConfirmPopup);
