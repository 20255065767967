import { makeStyles } from "@material-ui/core";
import { graphHeight } from "pages/CreateReport/constants";

export const useStyles = makeStyles((is) => ({
  table: {
    border: "1px solid #8C8C8C",
    borderSpacing: "0",
    borderRight: "0px",
    borderBottom: "0px",
    textAlign: "center",
    tableLayout: "fixed",
    width: "100%",
    overflow: "scroll",
  },
  tableHeading: {
    borderBottom: "1px solid #8C8C8C",
    padding: "8px",
    borderRight: "1px solid #8C8C8C",
  },
  tableCell: { overflowWrap: "break-word", fontSize: "12px" },
  columnStyle: {
    borderBottom: "1px solid #8C8C8C",
    padding: "8px",
    borderRight: "1px solid #8C8C8C",
    textAlign: "left",
    fontSize: "12px",
    background: "#D8D3E7",
    color: "#262626",
    fontWeight: "bold",
    lineHeight: "16px",
    boxSizing: "border-box",
  },
  rowHeading: {
    fontSize: "14px",
    fontWeight: "700",
    borderBottom: "1px solid #8C8C8C",
    padding: "8px",
    borderRight: "1px solid #8C8C8C",
    whiteSpace: "normal",
    textAlign: "left",
    lineHeight: "16px",
  },
  rowStyle: {
    fontSize: "12px",
    borderBottom: "1px solid #8C8C8C",
    padding: "8px",
    borderRight: "1px solid #8C8C8C",
    whiteSpace: "normal",
    textAlign: "left",
    color: "#262626",
  },
  columnInfo: { fontSize: "10px" },
  tableCellStyle: {
    border: "1px solid #8C8C8C",
    padding: "8px",
    justifyContent: "flex-start",
  },
  reportTemplate: { background: "white", padding: "10px" },
  patientTableHeading: {
    margin: "16px 0",
    textAlign: "center",
    fontSize: "14px",
    fontWeight: "700",
  },
  patientScoreHeading: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#D8D3E7",
    lineHeight: "32px",
    fontWeight: "700",
    fontSize: "14px",
  },
  graphContainer: {
    marginBottom: "2px",
  },
  blockStyle: { display: "inline-block" },
  dailyPattenTableContainer: {
    margin: "auto",
    marginTop: "24px",
  },
  boldFontWeight: { fontWeight: "700" },
  glycemicTableHeading: { width: "50%", backgroundColor: " #D8D3E7" },
  glycemicTableCell: {
    width: "50%",
    backgroundColor: "#D8D3E7",
    border: "1px solid #8C8C8C",
    padding: "8px 4px",
  },
  glycemicTableContainer: { margin: "auto" },
  glycemicContainer: {
    fontSize: "14px",
    display: "flex",
    justifyContent: "center",
    marginTop: "24px",
    marginBottom: "16px",
  },
  glycemicTable: {
    border: "1px solid #8C8C8C",
    borderCollapse: "collapse",
  },
  patientInfoTable: {
    flex: 1,
    border: "1px solid #D9D9D9",
    borderCollapse: "collapse",
    padding: "10px 20px",
  },
  patientInfoTableCell: {
    padding: "2.5px 0",
    margin: "0 10px 0 0",
  },
  patientInfoHeading: { fontSize: "12px", display: "flex" },
  patientInfoCategory: {
    fontWeight: 700,
    width: "100px",
    fontSize: "",
  },
  reportHeadingContainer: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "16px",
    display: "flex",
    justifyContent: "center",
    margin: "16px 0px 16px 0px",
  },
  summaryTableHeading: {
    border: "1px solid #8C8C8C",
    padding: "8px",
    backgroundColor: "#D8D3E7",
    fontSize: "12px",
  },
  summaryTableContainer: { display: "flex", justifyContent: "center" },
  summaryTable: {
    width: "80%",
    border: "1px solid #8C8C8C",
    borderCollapse: "collapse",
  },
  summaryTableCellHeadingStyle: { fontSize: "15px", fontWeight: "700" },
  summaryTableCellSubHeadingStyle: { fontSize: "8px", color: "#595959" },
  summaryTableCell: {
    border: "1px solid #8C8C8C",
    padding: "8px",
    fontSize: "12px",
    maxWidth: "100px",
  },
  reportHeader: { width: "100%" },
  patientInfoTableContainer: { display: "flex", justifyContent: "center" },
  summaryText: {
    width: "80%",
    paddingTop: "8px",
    display: "flex",
    margin: "auto",
    textAlign: "left",
    fontSize: "15px",
  },
  summaryTextStyle: {
    marginTop: "4px",
    fontSize: "12px",
  },
  checkBoxContainer: {
    display: "flex",
    margin: "auto",
    textAlign: "left",
    fontSize: "8px",
    marginTop: "6px",
  },
  medicalInsightContainer: { width: "80%", margin: "auto", fontSize: "12px" },
  healthRiskBarContainer: {
    margin: "auto",
    display: "flex",
    border: "1px solid #D9D9D9",
    marginTop: "24px",
  },
  healthRiskBarImageContainer: {
    width: "70%",
    justifyContent: "space-between",
  },
  healthRiskBarImage: { width: "100%" },
  healthRiskBarValue: {
    padding: "12px",
    borderLeft: "1px solid #D9D9D9",
    fontWeight: "700",
  },
  idealGraphImage: { width: "100%", height: `${graphHeight}px` },
  healthRiskBarHeadingFont: { fontSize: "16px" },
  healthRiskBarValueFont: {
    fontSize: "16px",
    marginTop: "16px",
  },
  reportContainer: { backgroundColor: "white", padding: "0 0 20px 0" },
  glycemicAndDistressContainer: {
    margin: "auto",
    marginTop: "2px",
    border: "1px solid #D9D9D9",
    padding: "8px",
  },
  pagepageBreakStyle: {
    pageBreakAfter: "always",
  },
  tableDataText: {
    fontSize: "12px",
    overflowWrap: "break-word",
    textAlign: "center",
  },
  cgmGraph: {
    margin: "0 4px",
    position: "relative",
  },
  dayCGMGraphDateContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  graphHeading: {
    fontWeight: "600",
    fontSize: "10px",
    lineHeight: "12px",
  },
  reportPageContent: {
    padding: "30px",
  },
  glucoseAndTIRContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "19px",
  },
  rowItem: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  averageGlucoseContainer: {
    width: "50%",
  },
  goal: {
    fontSize: "10px",
    fontWeight: 500,
    lineHeight: "12px",
    color: "#626262",
    marginTop: "10px",
  },
  averageAndScoreContainer: {
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "60%",
  },
  underTargetRange: {
    color: "#14B8A6",
  },
  aboveTargetRange: {
    color: "#FF5A5F",
  },
  averageGlucoseValue: {
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "15px",
  },
  cgmGraphWithLegends: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    position: "relative",
  },
  averageGlucose: {
    color: "#27276B",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "16px",
  },
  cgmGrapHeading: {
    color: "#27276B",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "17px",
    margin: "17px 0 15px 0",
  },
  checkBoxHeading: {
    fontSize: "12px",
    lineHeight: "12px",
    fontWeight: 700,
    margin: "16px 0 0 0",
    color: "#27276B",
  },
  patientProgressHeading: {
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "14px",
    margin: "23px 0 9px 0",
  },
  legendsWithOverallCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
  dailyGlucoseProfileHeading: {
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "17px",
    color: "#27276B",
  },
  dailyGlucoseProfileSubHeading: {
    fontSize: "10px",
    fontWeight: 600,
    lineHeight: "12px",
    color: "#595959",
    margin: "7px 0 24px 0",
  },
  weekHeading: {
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "17px",
    color: "#27276B",
    margin: "10px 0",
  },
}));
