const learnMoreDetailsDrawerStyle = theme => ({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: "64px",
    maxHeight: "64px",
    borderBottom: "1px solid #ccc",
    padding: "0px 16px",
    position: "sticky",
    zIndex: 1000,
    top: 0,
    backgroundColor: "white"
  },
  icon: {
    cursor: "pointer"
  },
  headerText: {
    fontSize: "16px",
    fontWeight: 500,
    color: "#2B377E",
    cursor: "pointer"
  },
  imageContainer: {
    height: "200px",
    width: "100%",
    backgroundColor: "#C4C4C4"
  },
  title: {
    fontSize: "24px",
    fontWeight: 500,
    marginTop: "1rem"
  },
  container: {
    display: "flex",
    alignItems: "center",
    padding: "0.5rem 0"
  },
  authorText: {
    fontSize: "14px",
    fontWeight: 500,
    marginLeft: "0.5rem"
  },
  description: {
    fontSize: "14px",
    marginTop: "0.5rem"
  },
  padded: {
    padding: "12px"
  }
})

export default learnMoreDetailsDrawerStyle;