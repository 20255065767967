import React from 'react'
import { Typography } from '@material-ui/core';

import useStyles from './errorInfoModalStyles';

const ErrorItem = (props) => {

  const {
    heading,
    errors
  } = props;

  const classes = useStyles();

  return (
    <div className={classes?.errorItemContainer}>
      <Typography className={classes.errorItemHeading}>{heading}</Typography>
      <div className={classes.errorListContainer}>
        {
          (errors?.map((item, index) => {
            return (
              <Typography
                key={`${heading}-${index}`}
                className={classes.errorItemText}
              >
                ⚙️ {item}
              </Typography>
            )
          }))
        }
      </div>
    </div>
  )
}

export default ErrorItem
