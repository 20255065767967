import { TextField, Typography } from "@material-ui/core";
import lang from "assets/languages";
import { updateReportPatientProgress } from "pages/CreateReport/actions/CreateReportActions";
import { useDispatch, useSelector } from "react-redux";
import {
  averageGlucoseHeading,
  averageGlucoseValues,
  disstressSubRows,
  tirHeading,
  tirSubRows,
  tirValues,
} from "./constant";
import { useStyles } from "./patientProgressStyle";

export const PatientProgress = () => {
  const {
    createReportReducer: { patientProgress },
  } = useSelector((state) => state);

  const classes = useStyles();
  const dispatch = useDispatch();

  const renderHeading = (headingData) => {
    return headingData.map((data) => {
      return (
        <th className={classes.tableHeading} key={data}>
          {data}
        </th>
      );
    });
  };

  const renderRow = (rowData) => {
    const handleFieldChange = (tableName, keyName, value) => {
      dispatch(
        updateReportPatientProgress({
          ...patientProgress,
          [tableName]: { ...patientProgress[tableName], [keyName]: value },
        })
      );
    };

    return (
      <tr>
        {rowData.map((column, i) => {
          let cellValue = patientProgress?.[column.tableName]?.[column.keyName];
          return (
            <td className={classes.tableRow} key={`patientProgress-${i}`}>
              <div className={classes.textDiv}>
                {column?.editable ? (
                  <TextField
                    {...(column?.isRequired
                      ? { required: column?.isRequired }
                      : {})}
                    rowsMax={4}
                    multiline
                    value={cellValue}
                    onChange={(text) => {
                      handleFieldChange(
                        column.tableName,
                        column.keyName,
                        text.target.value
                      );
                    }}
                    placeholder={
                      column?.isRequired
                        ? `Type here ${column.keyName}`
                        : "Remarks (if any)"
                    }
                    className={classes.textField}
                    renderValue={() => {
                      return (
                        <div
                          className={
                            cellValue?.length > 20 ? classes.wrapText : ""
                          }
                        >
                          {cellValue}
                        </div>
                      );
                    }}
                  />
                ) : (
                  <div className={classes.nonEditableRow}>{column.value}</div>
                )}
              </div>
            </td>
          );
        })}
      </tr>
    );
  };

  return (
    <div className={classes.container}>
      <Typography className={classes.tableHeadingTitle}>
        {lang.glycemicScoreTIR}
      </Typography>
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead>
            <tr>{renderHeading(tirHeading)}</tr>
          </thead>
          <tbody>{renderRow(tirValues(patientProgress))}</tbody>
        </table>
      </div>
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <tbody>{renderRow(tirSubRows(patientProgress)[1])}</tbody>
          <tbody>{renderRow(tirSubRows(patientProgress)[2])}</tbody>
        </table>
      </div>
      <Typography className={classes.tableHeadingTitle}>
        {lang.distressScoreGv}
      </Typography>
      <div className={classes.tableContainer}>
        <Typography className={classes.rowOnly}>
          {`${patientProgress?.distressScore?.value}%`}
        </Typography>
        <table className={classes.table}>
          <tbody>{renderRow(disstressSubRows(patientProgress)[0])}</tbody>
          <tbody>{renderRow(disstressSubRows(patientProgress)[1])}</tbody>
        </table>
      </div>
      <Typography className={classes.tableHeadingTitle}>
        {lang.averageGlucose}
      </Typography>
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead>
            <tr>{renderHeading(averageGlucoseHeading)}</tr>
          </thead>
          <tbody>{renderRow(averageGlucoseValues(patientProgress))}</tbody>
        </table>
      </div>
    </div>
  );
};
