import lang from "@assets/languages";

export const tirIdealPercentages = {
  tar: {
    value: 25,
    comparator: "<",
    heading: "Above Range",
    idealValue: 25,
    getIdealDescription: (min, max) => `>${max} ${lang.units["mg/dL"]}`,
  },
  tir: {
    value: 70,
    comparator: ">",
    heading: "Target Range",
    idealValue: 70,
    getIdealDescription: (min, max) => `${min} - ${max} ${lang.units["mg/dL"]}`,
  },
  tbr: {
    value: 4,
    comparator: "<",
    heading: "Below Range",
    idealValue: 4,
    getIdealDescription: (min, max) => `<${min} ${lang.units["mg/dL"]}`,
  },
};
