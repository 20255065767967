import lang from "@assets/languages";

export const getScoreMetricesConfig = (aiqr, gvp) => [
  {
    heading: "AIQR",
    description: "Health Risk Score (Avg. Interquartile Range)",
    metric: lang.units["mg/dL"],
    lowRange: { min: 0, max: 22 },
    mediumRange: { min: 23, max: 35 },
    highRange: { min: 35 },
    value: aiqr,
  },
  {
    heading: "GVP",
    description: "Distress Score (Glycemic Variability)",
    metric: lang.units["%"],
    lowRange: { min: 0, max: 12 },
    mediumRange: { min: 13, max: 36 },
    highRange: { min: 36 },
    value: gvp,
  },
];
