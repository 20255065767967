import React from "react";
import { withStyles } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import checkedIcon from "assets/icons/checked.png";
import CloseIcon from "@material-ui/icons/Close";
import accordionStyle from "../../../../assets/jss/components/accordionStyle";

const MyAccordion = ({
  title,
  description,
  moduleDone,
  handleViewWeekPlanDetails,
  planId,
  plan,
  ...props
}) => {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.title}>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.content}>{description}</Typography>
        </AccordionDetails>
      </Accordion>
      <div className={classes.container}>
        <Typography
          onClick={handleViewWeekPlanDetails}
          className={classes.text}
        >
          View Details
        </Typography>
        {moduleDone ? (
          <div className={classes.subContainer}>
            <img src={checkedIcon} height="20px" width="20px" alt="checked" />
            <Typography className={classes.sub_text}>Done</Typography>
          </div>
        ) : (
          <div className={classes.subContainer}>
            <CloseIcon className={classes.icon} />
            <Typography className={classes.sub_text1}>Not done</Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default withStyles(accordionStyle)(MyAccordion);
