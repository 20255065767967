import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    background: {
      default: "#F7F8FC",
    },
    primary: {
      main: "#2B367D"
    }
  },
  overrides: {
    MuiInput: {
      inputTypeSearch: {
        color: "#000000",
        border: "1px solid #ccc",
        padding: "0.75rem 0.5rem",
      },
    },
  }
});

export default theme;
