const blogStyle = (theme) => ({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: "64px",
    maxHeight: "64px",
    borderBottom: "1px solid #ccc",
    padding: "0px 16px",
    position: "sticky",
    zIndex: 1000,
    top: 0,
    backgroundColor: "white",
  },

  icon: {
    cursor: "pointer",
  },
  container: {
    display: "flex",
    gap: "18px",
  },
  wrapper: {
    width: "100%",
    backgroundColor: "white",
  },
  text: {
    fontSize: "20px",
    fontWeight: 510,
    color: "#262626",
    marginTop: "16px",
    paddingLeft: "16px",
  },
  avatarContainer: {
    padding: "16px",
    display: "flex",
    alignItems: "center",
    gap: "12px",
  },
  author: {
    fontSize: "14px",
    fontWeight: 510,
    color: "#262626",
  },
  avatarWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  date: {
    fontSize: "12px",
    fontWeight: 510,
    color: "#595959",
    display: "flex",
    alignItems: "center",
    paddingRight: "16px",
  },
  para: {
    fontSize: "14px",
    padding: "0px 16px",
    fontFamily: "Roboto",
    fontWeight: "510,",
  },
});

export default blogStyle;
