import { columnHeadingWithoutTableName } from "pages/CreateReport/constants";
import { useCallback } from "react";
import { useStyles } from "./reportTableStyle";
import { SelectMolecules } from "./SelectMolecules";

export const ReportTable = ({
  tableName,
  tableType,
  rowHeadingKeys,
  rowHeadingTitles,
  columnHeading,
  assessmentData,
}) => {
  const classes = useStyles();

  const rowHeadingArray = Object.values(rowHeadingKeys);

  const renderColumnHeading = () => {
    return columnHeading.map((data) => {
      return (
        <th className={classes.tableHeading} key={data}>
          {data}
        </th>
      );
    });
  };

  const renderRow = useCallback(() => {
    return rowHeadingArray.map((data, index) => {
      return (
        <tr key={index}>
          <th className={classes.tableHeading}>{rowHeadingTitles[index]}</th>
          {columnHeadingWithoutTableName.map((columnName, i) => {
            return (
              <td className={classes.tableRow} key={columnName}>
                <SelectMolecules
                  classes={classes}
                  columnName={columnName}
                  rowIndex={index}
                  columnIndex={i}
                  tableName={tableName}
                  tableType={tableType}
                  rowHeadings={rowHeadingKeys}
                  assessmentData={
                    assessmentData?.[columnName]?.[rowHeadingKeys[index]] || []
                  }
                />
              </td>
            );
          })}
        </tr>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessmentData]);

  return (
    <div className={classes.tableContainer}>
      <table className={classes.table}>
        <thead>
          <tr>{renderColumnHeading()}</tr>
        </thead>
        <tbody>{renderRow()}</tbody>
      </table>
    </div>
  );
};
