const buttonStyle = (theme) => ({
  button: {
    background: "#2B367D",
    borderRadius: "4px",
    textTransform: "none",
    color: "white",
    "&:hover": {
      background: "#2B367D"
    },
  },
  large: {
    height: "54px",
  },
  medium: {
    height: "40px",
    padding: "10px 16px"
  },
  medium_secondary: {
    height: "40px",
    padding: "10px 16px",
    background: "transparent",
    color: "#262626",
    border: "1px solid #8C8C8C",
    "&:hover": {
      background: "transparent"
    },
  },
  medium_border: {
    height: "40px",
    padding: "10px 16px",
    background: "transparent",
    color: "#2B377E",
    border: "1px solid #D9D9D9",
    "&:hover": {
      background: "transparent"
    },
  },
  medium_inverse: {
    height: "40px",
    padding: "10px 16px",
    color: "#2B377E",
    backgroundColor: "#FFFFFF",
    border: "1px solid #D9D9D9",
    fontWeight: 700,
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
  },
  fullWidth: {
    width: '100%'
  }
});

export default buttonStyle;
