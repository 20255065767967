const { makeStyles } = require("@material-ui/core");

export const upsellingStyle = makeStyles((theme) => ({
  button: {
    marginBottom: "10px",
  },
  container: {
    margin: "10px",
  },
  selectField: {
    color: "#000",
  },
  search: {
    color: "#8C8C8C",
    background: "#fff",
    fontSize: "14px",
    fontWeight: "500px",
    borderRadius: "20px",
    marginBottom: "16px",
    width: "100%",
    "& .MuiOutlinedInput-root": {
      borderRadius: "20px",
    },
  },
  orField: {
    alignContent: "center",
    textAlign: "center",
    marginBottom: "10px",
  },
  selectLabel: {
    marginBottom: "10px",
  },
  searchBtn: {
    marginTop: "20px",
    width: "100px",
  },
  clearBtn: {
    marginLeft: "10px",
  },
  resultLabel: {
    color: "#262626",
    fontSize: "14px",
    fontWeight: "bold",
    marginTop: "16px",
  },
  errorText: {
    marginTop: "12px",
    marginBottom: "12px",
    fontSize: "14px",
    color: "#595959",
    fontWeight: "medium",
  },
  phoneErrorText: {
    color: "#FF5522",
  },
}));
