import { TextField, Typography, withStyles } from "@material-ui/core";
import React from "react";
import reportTableStyls from "./styles";

const CustomReportTable = ({
  onTableCellChange,
  tableColumnHeadings,
  rowsData,
  tableHeadingStyles = {},
  rowHeadingStyles = {},
  rowNameTextStyle = {},
  rowCellTextStyle = {},
  classes,
  colspan,
  customHeading = false,
}) => {
  const renderColumnHeading = () => {
    return tableColumnHeadings.map((tableHeading, i) => {
      return (
        <th
          key={i}
          {...(colspan ? { colSpan: colspan } : {})}
          className={classes.tableHeading}
        >
          {customHeading ? (
            tableHeading
          ) : (
            <Typography
              className={classes.tableDataText}
              style={{
                fontSize: "14px",
                fontWeight: 700,
                lineHeight: "32px",
                ...tableHeadingStyles,
              }}
            >
              {tableHeading}
            </Typography>
          )}
        </th>
      );
    });
  };

  //   {
  //    "rowName":"sgdsg",
  //    "rowKey":"gxgus",
  //    "tablCells":[
  //       {
  //          "rowCellKey": '',
  //          "rowCellValue": '',
  //          "placeholder": '',
  //          "isRequired": true/false,
  //          "textColor": ''
  //       },
  //    ]
  // }
  const renderRow = () => {
    return rowsData.map((row, index) => {
      const { rowName, rowKey, tablCells, rowStyle = {}, rowIcon } = row;

      return (
        <tr key={index}>
          <th
            style={{ ...rowHeadingStyles, ...rowStyle }}
            className={classes.tableHeading}
          >
            {rowIcon && (
              <img src={rowIcon} alt="" className={classes.rowImage} />
            )}
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: 400,
                ...rowNameTextStyle,
              }}
            >
              {rowName}
            </Typography>
          </th>
          {tablCells.map((tableCell, i) => {
            const {
              rowCellKey,
              rowCellValue,
              placeholder = "Type here...",
              isRequired,
              textColor = "#262626",
              editable = false,
              style = {},
            } = tableCell;

            return (
              <td className={classes.tableRow} key={rowCellKey}>
                {editable && onTableCellChange ? (
                  <TextField
                    onChange={(event) =>
                      onTableCellChange({
                        rowKey,
                        rowCellValue: event.target.value,
                        rowCellKey,
                      })
                    }
                    value={rowCellValue}
                    id={rowCellKey}
                    placeholder={placeholder}
                    multiline
                    rowsMax={4}
                    style={{ color: textColor, ...style }}
                    {...(isRequired
                      ? { required: isRequired, label: "required" }
                      : {})}
                  />
                ) : (
                  <Typography
                    className={classes.tableDataText}
                    style={{
                      fontSize: "14px",
                      fontWeight: 400,
                      color: textColor,
                      ...rowCellTextStyle,
                      ...style,
                    }}
                  >
                    {rowCellValue}
                  </Typography>
                )}
              </td>
            );
          })}
        </tr>
      );
    });
  };

  return (
    <table cellPadding="9" className={classes.table}>
      <thead>
        <tr>{renderColumnHeading()}</tr>
      </thead>
      <tbody>{renderRow()}</tbody>
    </table>
  );
};

const customReportTable = React.memo(
  withStyles(reportTableStyls)(CustomReportTable)
);
export { customReportTable as CustomReportTable };
