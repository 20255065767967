import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  legendContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "9px",
  },
  legendMarker: {
    marginRight: "3px",
    width: "14px",
    height: "14px",
    borderRadius: "2px",
  },
  legendName: {
    fontSize: "10px",
    lineHeight: "12px",
    width: "42",
    color: "#798292",
  },
});
