import { userRoles } from "utils/constants";
import { LoginActionTypes } from "../constants";

const initialState = {
  loading: false,
  loggedInUserDetails: null,
  isLoggedIn: false,
  passwordChanged: null,
};

const loginReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LoginActionTypes.post_Login.REQUEST:
      return {
        ...state,
        loading: true,
        loggedInUserDetails: null,
        isLoggedIn: false,
      };
    case LoginActionTypes.post_Login.SUCCESS:
      return {
        ...state,
        loading: false,
        loggedInUserDetails: payload,
        isLoggedIn: true,
      };
    case LoginActionTypes.post_Login.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
      };
    case LoginActionTypes.post_RefreshTokenApi.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case LoginActionTypes.post_RefreshTokenApi.SUCCESS:
      return {
        ...state,
        loading: false,
        loggedInUserDetails: payload,
        isLoggedIn: true,
      };
    case LoginActionTypes.post_RefreshTokenApi.FAILURE:
      return {
        ...state,
        loading: false,
        loggedInUserDetails: null,
        isLoggedIn: false,
      };
    default:
      return state;
  }
};

export default loginReducer;
