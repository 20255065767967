const { makeStyles } = require("@material-ui/core");

export const sensorDeactivationStyle = makeStyles((theme) => ({
  addPatientButtonContainer: {
    marginBottom: "16px",
    width: "100%",
    marginTop: "20px",
  },
  uploadAppointmentButton: { marginBottom: "20px" },
  button: {
    marginBottom: "10px",
  },
  title: {
    fontSize: "24px",
    fontWeight: "500",
    marginBottom: "8px",
  },
  selectField: {
    color: "#000",
  },
  search: {
    color: "#8C8C8C",
    background: "#fff",
    fontSize: "14px",
    fontWeight: "500px",
    borderRadius: "20px",
    marginBottom: "16px",
    width: "100%",
    "& .MuiOutlinedInput-root": {
      borderRadius: "20px",
    },
  },
  upload: {
    display: "none",
  },
  message: {
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "center",
    margin: "auto",
  },
  deactivationBtn: {
    width: "200px",
    marginBottom: "20px",
    marginTop: "30px",
  },
  addNewAddon: {
    width: "200px",
    marginLeft: "20px",
    marginBottom: "20px",
    marginTop: "30px",
  },
  selectLabel: {
    padding: "10px",
  },
  searchBtn: {
    width: "100px",
  },
  addOnAdditonContainer: {
    padding: "15px",
  },
  addOnRadioForm: {
    width: "350px",
  },
  yesCancelContainer: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px 20px",
    marginTop: "30px",
  },
  dialogBoxButton: {
    marginRight: "30px",
  },
  addOnFormContainer: {
    padding: "10px",
  },
  noteContainer: {
    flex: "row",
    marginTop: "10px",
  },
  noteLable: {
    fontWeight: "bold",
  },
  linkText: {
    padding: "5px",
    textDecoration: "none",
    color: "#2B377E",
    left: 10,
  },
  linkContainer: {
    marginBottom: "100px",
  },
  etaTable: {
    width: "100%",
    justifyContent: "center",
    textAlign: "center",
    borderCollapse: "collapse",
  },
  etaTableData: {
    textAlign: "center",
    border: "1px solid black",
    padding: "10px",
    height: "40px",
    width: "30%",
  },
  deliveryModeContainer: {
    height: "50px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    backgroundColor: "white",
    borderRadius: "0px 8px 8px 0px",
    flexWrap: "wrap",
  },
  etaContainer: {
    display: "flex",
    paddingTop: "5px",
  },
  etaLabel: {
    marginRight: "4px",
  },
  errorText: { color: "#FF5522" },
}));
