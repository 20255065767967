import { 
  API_METHODS,
  END_POINT
} from "network/constants";

import api from "utils/api";

const getSensorSummaryGraphForCoach = async (props) => {
  try {
    const { id } = props;
    const url = END_POINT.getSensorSummaryGraphForCoach(id);
    const options = {
      method: API_METHODS.GET
    }

    const response = await api(url, options);
    return response;
  }
  catch (err) {
    throw err;
  }
}

export default getSensorSummaryGraphForCoach
