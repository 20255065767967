import { PatientActionTypes } from "../constants";

const initialState = {
  loading: false,
  allAppointments: null,
  allWeekPlans: null,
  moduleDetailsByWeekPlan: null,
  taskDetailsByModule: null,
  taskDetailsByDataEntry: null,
  noteSuccess: null,
  updateSuccess: null,
};

const patientReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case PatientActionTypes.get_AllAppointments.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PatientActionTypes.get_AllAppointments.SUCCESS:
      return {
        ...state,
        loading: false,
        allAppointments: payload,
      };
    case PatientActionTypes.get_AllAppointments.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case PatientActionTypes.post_SaveNote.REQUEST:
      return {
        ...state,
        loading: true,
        noteSuccess: null,
      };
    case PatientActionTypes.post_SaveNote.SUCCESS:
      return {
        ...state,
        loading: false,
        noteSuccess: payload.status,
      };
    case PatientActionTypes.post_SaveNote.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case PatientActionTypes.get_AllWeekplans.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PatientActionTypes.get_AllWeekplans.SUCCESS:
      return {
        ...state,
        loading: false,
        allWeekPlans: payload,
      };
    case PatientActionTypes.get_AllWeekplans.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case PatientActionTypes.get_ModuleDetailsByWeekPlan.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PatientActionTypes.get_ModuleDetailsByWeekPlan.SUCCESS:
      return {
        ...state,
        loading: false,
        moduleDetailsByWeekPlan: payload,
      };
    case PatientActionTypes.get_ModuleDetailsByWeekPlan.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case PatientActionTypes.get_TaskDetailsByModule.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PatientActionTypes.get_TaskDetailsByModule.SUCCESS:
      return {
        ...state,
        loading: false,
        taskDetailsByModule: payload,
      };
    case PatientActionTypes.get_TaskDetailsByModule.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case PatientActionTypes.get_TaskDetailsByDataEntry.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PatientActionTypes.get_TaskDetailsByDataEntry.SUCCESS:
      return {
        ...state,
        loading: false,
        taskDetailsByDataEntry: payload,
      };
    case PatientActionTypes.get_TaskDetailsByDataEntry.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case PatientActionTypes.delete_WeekPlanById.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PatientActionTypes.delete_WeekPlanById.SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case PatientActionTypes.delete_WeekPlanById.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case PatientActionTypes.update_Weight.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PatientActionTypes.update_Weight.SUCCESS:
      return {
        ...state,
        updateSuccess: payload,
      };
    case PatientActionTypes.update_Weight.FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default patientReducers;
