const weekPlanProgressStyle = (theme) => ({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: "64px",
    maxHeight: "64px",
    borderBottom: "1px solid #ccc",
    padding: "0px 16px",
    position: "sticky",
    zIndex: 1000,
    top: 0,
    backgroundColor: "white",
  },

  icon: {
    cursor: "pointer",
  },
  container: {
    backgroundColor: "#FAFAFA",
    padding: "16px",
    height: "100vh",
    overflowX: "hidden",
  },
  mainCard: {
    width: "100%",
    backgroundColor: "white",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.05)",
  },
  dateContainer: {
    width: "100%",
    height: "52px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "6px",
  },
  dateText: {
    fontWeight: 590,
    fontSize: "14px",
    color: "#23AFD7",
  },
  borderBottom: {
    borderBottom: "1px solid #C4C4C4",
  },
  barContainer: {
    width: "100%",
    height: "70px",
    padding: "16px",
  },
  barText: {
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "22px",
    color: "#595959",
    letterSpacing: "0.3px",
  },
  barText2: {
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#595959",
  },
  barSubContainer: {
    width: "90%",
    display: "flex",
    justifyContent: "space-between",
  },
  ProgressBar: {
    width: "90%",
    marginTop: "8px",
  },
  barText3: {
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "17px",
    color: "#595959",
    marginTop: "8px",
  },
  metricContainer: {
    width: "100%",
    padding: "16px",
    height: "50px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  subTextMetric: {
    color: "#595959",
    fontWeight: 600,
    fontSize: "14px",
  },
  subTextMetric2: {
    fontSize: "12px",
    fontWeight: "normal",
    color: "#8C8C8C",
    marginTop: "8px",
  },
  borderBottom2: {
    borderBottom: "1px solid #C4C4C4",
    width: "90%",
    alignSelf: "center",
    marginLeft: "16px",
  },
  activity: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "16px",
    padding: "0px 16px",
  },
  activityText: {
    fontWeight: 600,
    fontSize: "14px",
    color: "#23AFD7",
  },
  activityContainer: {
    height: "35px",
    padding: "16px",
    width: "90%",
    display: "flex",
    gap: "8px",
  },
  activityWrapper: {
    display: "flex",
    width: "80%",
    justifyContent: "space-between",
  },

  dot: {
    height: "40px",
    width: "40px",
    borderRadius: "40px",
  },
  activitySubText: {
    color: "#595959",
    fontWeight: "normal",
    fontSize: "14px",
    width: "190px",
  },
  activityNumberText: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
});

export default weekPlanProgressStyle;
