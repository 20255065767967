const patientInfoStyle = (theme) => ({
  topContainer: {
    maxHeight: "430px",
    overflow: "auto",
  },
  container: {
    display: "grid",
    // justifyContent: "space-between",
    padding: "16px",
    // gridAutoFlow: 'column',
    gridTemplateColumns: "300px auto",
    // width: "70%"
  },
  text: {
    fontWeight: 500,
    fontSize: "14px",
  },
  sub_text: {
    fontSize: "14px",
  },
  active_text: {
    color: "#389E0D",
    fontSize: "14px",
    fontWeight: 500,
  },
  pending_text: {
    color: "#FF5522",
    fontSize: "14px",
    fontWeight: 500,
  },
});

export default patientInfoStyle;
