const { makeStyles } = require("@material-ui/core");

export const basicPatientDetails = makeStyles((theme) => ({
  box: {
    backgroundColor: "#FFFFFF",
    width: "auto",
    border: "1px solid #D9D9D9",
  },
  addonBox: {
    paddingTop: "16px",
    backgroundColor: "#FFFFFF",
    paddingLeft: "16px",
    fontSize: "16px",
    width: "auto",
    color: "rgba(0, 0, 0, 0.54)",
  },
  addonLabel: {
    fontSize: "16px",
  },
  textField: {
    margin: "16px",
    width: "100%",
  },
  addon: {
    width: "400px",
  },
  defaultValue: {
    fontSize: "16px",
    fontWeight: "500",
  },
  label: {
    color: "#000",
  },
  list: {
    height: "76px",
    width: "auto",
    border: "1px solid #D9D9D9;",
    display: "flex",
    justifyContent: "space-between",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "16px",
    alignItems: "center",
  },
  remarks: {
    margin: "16px",
    width: "95%",
  },

  card: {
    boxShadow: 1,
    padding: "16px",
    borderRadius: "8px",
    backgroundColor: "#FFFFFF",
    border: "1px solid #D9D9D9",
    marginBottom: "16px",
  },
  tableLabel: {
    fontSize: "16px",
    fontWeight: "normal",
    height: "19px",
    marginBottom: "12px",
  },
  labelHeading: {
    color: "#8C8C8C",
    fontSize: "12px",
    paddingLeft: "16px",
  },
  tableContainer: { padding: "20px" },
  boxBorder: { border: "1px solid #D9D9D9" },
  remarksContainer: { overflow: "scroll", maxHeight: "250px" },
  boxContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "16px",
  },
  nameBox: {
    margin: "16px 0px",
    backgroundColor: "#5BABCC",
    width: "100px",
    height: "100px",
    borderRadius: "50px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  firstNameIcon: {
    fontSize: "74px",
    color: "#2B377E",
  },
  fullNameContainer: { fontSize: "24px", fontWeight: "500" },
  marginBottom16: {
    marginBottom: "16px",
  },
}));
